/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useRef, useTransition } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { findNoticePosts } from 'apis/admin/Notice';
import { NoticeCondition, NoticePost } from 'models/admin/Notice';
import useSessionStore from 'stores/useSessionStore';

import { ICellRendererParams, CellClickedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';

import { BlueButton, GreyButton } from 'components/buttons/CustomButton';
import { CustomInputText } from 'components/inputs/CustomInput';
import { ContentGrid } from 'components/layouts/ContentGrid';
import {
  ContentSection,
  GridInfo,
  GridInfoSection,
  GridTop,
} from 'components/layouts/ContentSection';
import { Pagination, PaginationRef } from 'components/layouts/Pagination';
import { SearchBox, SearchButtons, SearchRows } from 'components/layouts/SearchBox';
import { ContainerLayout } from 'components/layouts/MainLayout';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';

interface NoticeEditProps {
  editType?: 'UPDATE' | 'CREATE';
  bbmNo?: string;
  userId?: string;
  bbmCtn?: string;
  atchFileGrId?: string;
}

const NoticeManagementPage = () => {
  const { t } = useTranslation();
  const { gridNoRowsTemplate } = useSessionStore();
  const navigate = useNavigate();

  const [postList, setpostList] = useState<NoticePost[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [title, setTitle] = useState<string>('');
  const [contents, setContents] = useState<string>('');
  const [pageSize, setPageSize] = useState<number>(10);
  const { userId } = useSessionStore();
  const paginationRef = useRef<PaginationRef>(null);

  const AtchFileExistCellRenderer = (props: ICellRendererParams) => (
    <>{props.value == true && <div>@</div>}</>
  );

  const [columnDefs] = useState([
    {
      field: 'rank',
      headerName: 'No.',
      width: 80,
      cellStyle: { textAlign: 'center' },
    },
    {
      field: 'bbsTpNm',
      headerName: t('notice.column.분류', '__분류'),
      width: 100,
      cellStyle: { textAlign: 'center' },
    },
    {
      field: 'bbmTitNm',
      headerName: t('notice.column.제목', '__제목'),
      flex: 1,
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <Link to="#" className="link">
            {params.value}
          </Link>
        );
      },
    },
    {
      field: 'atchFileGrId',
      headerName: '파일그룹아이디',
      hide: true,
    },
    {
      field: 'atchFileExist',
      headerName: t('notice.column.파일', '__파일'),
      width: 80,
      cellStyle: { textAlign: 'center' },
      cellRenderer: AtchFileExistCellRenderer,
    },
    {
      field: 'dataInsUserInfo',
      headerName: t('com.label.00058', '__작성자'),
      width: 280,
      resizable: true,
      cellStyle: { textAlign: 'left' },
    },
    {
      field: 'dataInsDtm',
      headerName: t('notice.column.작성일', '__작성일'),
      width: 180,
      cellStyle: { textAlign: 'center' },
    },
    {
      field: 'bbmVwct',
      headerName: t('notice.column.조회수', '__조회수'),
      width: 100,
      cellStyle: { textAlign: 'center' },
    },
  ]);

  const handleSearchClick = async () => {
    const noticeCondition = {
      bbmTitNm: title,
      bbmCtn: contents,
      pageSize: String(pageSize),
    } as NoticeCondition;
    const response = await findNoticePosts(noticeCondition);
    if (response) {
      const noticePosts = response.list || [];
      setpostList(noticePosts);
      setTotalCount(response.totalCount || 0);
      paginationRef.current?.setSelectNo(1);
    }
  };

  const handlePageNoClick = async (pageNo: number) => {
    const noticeCondition = {
      pageSize: String(pageSize),
      start: String(pageSize * (pageNo - 1)),
    };
    const response = await findNoticePosts(noticeCondition);
    if (response) {
      const noticePosts = response.list || [];
      setpostList(noticePosts);
    }
  };

  useEffect(() => {
    (async () => {
      const response = await findNoticePosts({
        pageSize: String(pageSize),
      });
      if (response) {
        const noticePosts = response.list || [];
        setpostList(noticePosts);
        setTotalCount(response.totalCount || 0);
      }
    })();
  }, []);

  return (
    <ContentSection>
      <SearchBox>
        <SearchRows className="contentStart">
          <div className="searchCol">
            <label>{t('notice.label.제목', '__제목')}</label>
            <CustomInputText
              value={title}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setTitle(e.target.value);
              }}
            />
          </div>
          <div className="searchCol">
            <label>{t('notice.label.내용', '__내용')}</label>
            <CustomInputText
              value={contents}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setContents(e.target.value);
              }}
            />
          </div>
        </SearchRows>
        <SearchButtons className="searchButtons">
          <GreyButton className="buttonSrch" onClick={handleSearchClick}>
            <label>{t('com.label.00116', '__조회')}</label>
          </GreyButton>
        </SearchButtons>
      </SearchBox>
      <GridInfoSection>
        <GridInfo>
          <span>{t('notice.label.공지사항 목록', '__공지사항 목록')}</span>
          <span className="primary">
            {totalCount}
            {t('com.label.case', '__건')}
          </span>
        </GridInfo>
      </GridInfoSection>
      <ContentGrid className="ag-theme-alpine pagination">
        <AgGridReact
          overlayNoRowsTemplate={gridNoRowsTemplate}
          rowData={postList}
          columnDefs={columnDefs}
          suppressPaginationPanel={true}
          onCellClicked={(e: CellClickedEvent) => {
            if (e.colDef.field === 'bbmTitNm') {
              navigate('/system/admin/sample/notice-edit', {
                state: {
                  editType: userId != e.data.dataInsUserId ? 'SELECT' : 'UPDATE',
                  bbmNo: e.data.bbmNo,
                  bbmCtn: e.data.bbmCtn,
                  atchFileGrId: e.data.atchFileGrId,
                },
              });
            }
          }}
        ></AgGridReact>
      </ContentGrid>
      <Pagination
        ref={paginationRef}
        onChangePageSize={(pageSize: number) => {
          setPageSize(pageSize);
        }}
        onClickPageNo={handlePageNoClick}
        totalCount={totalCount}
      />
      <GridInfoSection className="contentEnd">
        <BlueButton
          onClick={() => {
            navigate('/system/admin/sample/notice-edit', { state: { editType: 'CREATE' } });
          }}
        >
          {t('common.button.등록', '__등록')}
        </BlueButton>
      </GridInfoSection>
    </ContentSection>
  );
};

export default NoticeManagementPage;
