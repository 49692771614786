import { create } from 'zustand';

interface AuthState {
  accessCode: string;
  accessToken: string;
  setAccessToken: (accessToken: string) => void;
  setAccessCode: (accessCode: string) => void;
}

const useAuthStore = create<AuthState>((set, get) => {
  return {
    accessCode: '',
    accessToken: '',
    setAccessToken: (accessToken: string) => {
      set((prev) => ({ ...prev, accessToken: accessToken }));
    },
    setAccessCode: (accessCode: string) => {
      set((prev) => ({ ...prev, accessCode: accessCode }));
    },
  };
});

export default useAuthStore;
