import styled from '@emotion/styled';
import { GButton } from 'components/atom/button';
import { GButtonBox } from 'components/layouts/GLayoutBox';
import GButtonLayout from 'components/molecule/GbuttonLayout';
import SearchMolecule, {
  SearchItemWrap,
  SearchWrap,
  UnHiddenSearchWrap,
} from 'components/molecule/SearchMolecule';
import { object, string } from 'yup';
import Save from 'components/asset/images/Confirm.svg';
import useSessionStore from 'stores/useSessionStore';
import { getUserInfo2 } from 'apis/common/Employee';
import FileUploadBoxVars from '../../../components/vars/common/FileUploadBoxVars';
import CrossEditor, { CrossEditorProps } from '../../../components/vars/qna/CrossEditor';
import { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useCommonModal } from 'hooks/useCommonModal';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CSearchMolecule from 'components/vars/common/CSearchMolecule';
import { ValidationBox } from 'components/validation/ValidationBox';
import { FileSaveResponse, FileSaveResult } from 'models/admin/FileInfo';
import {
  editAdminFaqContentApi,
  getAdminFaqDetailApi,
  postAdminFaqContentApi,
} from 'apis/vars/faq/FaqAdminApi';
import { useLoading } from 'components/process/Loading';
import { useMessageBar } from 'components/process/MessageBar';
import avatarUser from 'components/asset/images/avatarUser.svg';
import { makeErrorMapFrom } from 'utils/ValidationUtil';

export interface FaqRegist {
  faqTitNm: string;
  faqCtn: string;
  atchFileGrId: string | null;
}

export interface FaqEdit {
  faqNo: number;
  faqTitNm: string;
  faqCtn: string;
  atchFileGrId: string | null;
}

export const InitFaqRegist: FaqRegist = {
  faqTitNm: '',
  faqCtn: '',
  atchFileGrId: '',
};

export interface FaqDetailRes {
  faqNo: number;
  faqTitNm: string;
  faqCtn: string;
  atchFileGrId: string;
  dataInsUserId: string;
}

const returnByteLength = (str: string) => {
  let byteLength = 0;

  for (let i = 0; i < str.length; ++i) {
    str.charCodeAt(i) > 127 ? (byteLength += 3) : byteLength++;
  }

  return byteLength;
};
export const QnaInfoContext = createContext<FaqRegist>(InitFaqRegist);

const FaqRegistPage = () => {
  const { t } = useTranslation();
  const t2 = t as (msgId: string, defVal: string) => string;
  const navigate = useNavigate();
  const { openLoading } = useLoading();
  const { openMessageBar } = useMessageBar();
  const { openCommonModal } = useCommonModal();

  const dropzoneRef = useRef<any>();
  const editorRef = useRef<CrossEditor>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [formData, setFormData] = useState<FaqRegist>(InitFaqRegist);
  const [validationErrMap, setValidationErrMap] = useState<Map<string, string>>(new Map());

  // const { faqNo } = useParams();
  const { faqNo }: { faqNo?: number } = useLocation()?.state || {};

  const { userId, langCd } = useSessionStore();
  const [userInfo, setUserInfo] = useState('');

  useEffect(() => {
    if (faqNo) {
      openLoading(true);
      getAdminFaqDetailApi(Number(faqNo)).then((res) => {
        setFormData({
          faqTitNm: res.faqTitNm,
          faqCtn: res.faqCtn,
          atchFileGrId: res.atchFileGrId,
        });
        openLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    const init = async () => {
      if (userId) {
        setUserInfo(await getUserInfo2());
      }
    };
    init();
  }, []);

  const schema = useMemo(() => {
    return object({
      faqTitNm: string()
        .required(t2('com.label.00057', '__제목을 입력하세요'))
        .test(
          'check-byte',
          t2('com.label.00469', '__제목은 300바이트를 초과할 수 없습니다.'),
          (value) => {
            return returnByteLength(value) <= 300;
          }
        ),
    });
  }, []);

  const handleTitleChange = useCallback((e) => {
    setFormData((prev) => {
      return { ...prev, faqTitNm: e.target.value };
    });
  }, []);

  const handleCancel = () => {
    openCommonModal({
      modalType: 'confirm',
      content: (
        <>
          {t('com.label.00167', '__작성하신 내용이 저장되지 않았습니다.')}
          <br />
          {t('com.label.00168', '__그래도 화면을 닫으시겠습니까?')}
        </>
      ),
      noCallback: () => {
        return;
      },
      yesCallback: () => {
        navigate('/faq/list');
      },
    });
  };

  const checkValidate = async (object) => {
    try {
      setValidationErrMap(new Map());
      await schema.validate(object, { abortEarly: false });
    } catch (e: any) {
      setValidationErrMap(makeErrorMapFrom(e));
      throw new Error();
    }
  };

  const getFileId = async () => {
    const fileSaveResponse: FileSaveResponse = await dropzoneRef.current.saveFiles();
    return fileSaveResponse.fileSaveResult == FileSaveResult.FAIL
      ? null
      : fileSaveResponse.atchFileGrId;
  };

  const handleSave = async () => {
    try {
      const content = await editorRef.current?.editorRef.GetBodyValue();
      await checkValidate({ ...formData, content: content });

      openLoading(true);
      const fileId = await getFileId();
      await setFormData((prev) => {
        return {
          ...prev,
          faqCtn: content,
          atchFileGrId: fileId,
        };
      });

      if (faqNo) {
        await editAdminFaqContentApi({
          faqNo: Number(faqNo),
          faqTitNm: formData.faqTitNm,
          atchFileGrId: fileId,
          faqCtn: content,
        }).then((res) => {
          openLoading(false);
          openMessageBar('success', t('com.msg.save', '__저장되었습니다.'));
          navigate('/faq/list');
        });
      } else {
        await postAdminFaqContentApi({
          ...formData,
          atchFileGrId: fileId,
          faqCtn: content,
        }).then((res) => {
          openLoading(false);
          openMessageBar('success', t('com.msg.save', '__저장되었습니다.'));
          navigate('/faq/list');
        });
      }
    } catch (e: any) {
      return;
    }
  };

  const currentUserLang = langCd === 'en' ? 'enu' : langCd === 'zhC' ? 'chs' : 'kor';

  const editorProps: CrossEditorProps = {
    name: 'editor',
    params: {
      Width: '100%',
      Height: 500,
      event: {
        // UploadProc: uploadProc,
      },
      Placeholder: t2('com.label.00149', '내용을 입력하세요.'),
      UserLang: currentUserLang,
    },
    value: formData.faqCtn,
    onLoaded: () => {
      setIsLoaded(true);
    },
  };

  useEffect(() => {
    if (formData.faqCtn && formData.faqCtn.length > 0) {
      isLoaded && editorRef.current?.editorRef.SetBodyValue(formData.faqCtn);
    }
  }, [isLoaded, formData.faqCtn]);

  return (
    <>
      <SearchWrap marginBottom="4px" style={{ padding: '12px' }}>
        <UnHiddenSearchWrap>
          <SearchItemWrap nonButton={true}>
            <CSearchMolecule
              type="input"
              searchWidth="100%"
              labelTitle={t('com.label.00048', '제목')}
              labelAlign="left"
              isRequired={true}
              placeholder={t2('com.label.00057', '제목을 입력하세요')}
              textInputValue={formData.faqTitNm}
              onChange={handleTitleChange}
              inputStatus={
                validationErrMap.get('faqTitNm') && formData.faqTitNm.trim() === ''
                  ? 'error'
                  : 'default'
              }
              helperText={
                validationErrMap.get('faqTitNm') && formData.faqTitNm.trim() === ''
                  ? validationErrMap.get('faqTitNm')
                  : undefined
              }
            />
            <SearchMolecule
              type="avatarChip"
              avaterType="user"
              avaterSize="medium"
              searchWidth="50%"
              labelTitle={t('com.label.00058', '작성자')}
              labelAlign="left"
              isRequired={true}
              avaterBackgroundBorder="lightGray"
              avaterProfileBackgroundColor="#554596"
              avaterProfileBorder="#554596"
              avaterProfileImg={avatarUser}
              avaterprofileItemColor="#fff"
              avaterNickname={userInfo}
            />
          </SearchItemWrap>
        </UnHiddenSearchWrap>
      </SearchWrap>

      <CrossEditor ref={editorRef} {...editorProps} />

      <SearchWrap style={{ marginTop: '2px', padding: '12px', marginBottom: '4px' }}>
        <UnHiddenSearchWrap>
          <SearchItemWrap nonButton={true}>
            <FileUploadBoxVars
              labelWidth="120px"
              type="fileUpload"
              searchWidth="100%"
              isRequired={false}
              labelTitle={t('com.label.00056', '파일첨부')}
              labelAlign="left"
              searchParams={formData}
              dropzoneRef={dropzoneRef}
            />
          </SearchItemWrap>
        </UnHiddenSearchWrap>
      </SearchWrap>

      <GButtonLayout paddingTop="0px">
        <GButtonBox>
          <GButton
            txt={t('com.label.00036', '취소')}
            sizes="large"
            btnstyled="outline"
            btnColor="normal"
            hasImg={false}
            hasTxt={true}
            onClick={handleCancel}
          ></GButton>
          <GButton
            chkImg={Save}
            txt={t('com.label.00055', '저장')}
            sizes="large"
            btnstyled="contained"
            btnColor="primary"
            hasImg={true}
            hasTxt={true}
            onClick={handleSave}
          ></GButton>
        </GButtonBox>
      </GButtonLayout>
    </>
  );
};

export default FaqRegistPage;
