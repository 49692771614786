import { t } from 'i18next';
import dayjs from 'dayjs';
import {
  GButtonBoxCum,
  SearchItemWrap,
  SearchWrap,
  UnHiddenSearchWrap,
} from 'components/molecule/SearchMolecule';
import Download from 'components/asset/images/Upload.svg';
import RecycleB from 'components/asset/images/RecycleB.svg';
import searchImage from 'components/asset/images/Search.svg';

import { AgGridReact } from 'ag-grid-react';
import { useLoading } from 'components/process/Loading';
import { useTranslation } from 'react-i18next';
import { useCommonModal } from 'hooks/useCommonModal';
import { useEffect, useRef, useState } from 'react';
import { useMessageBar } from 'components/process/MessageBar';

import GTabs from 'components/organism/GTabs';
import GButtonLayout from 'components/molecule/GbuttonLayout';
import SubTitleBox from 'components/molecule/SubTitleBox';
import SearchMolecule, { GInputArea, ItemWrap } from 'components/common/SearchMolecule';
import EmptyState from 'components/atom/emptyState';
import { GButton } from 'components/atom/button';
import { GContentGrid } from 'components/layouts/GContentGrid';
import { SubTitleBtnArea } from 'components/organism/SubTitleBtnArea';
import { TitleBtnBox } from 'components/molecule/ButtonArea';
import { VendorReqInfoVO } from 'models/vars/vendor/VendorReqInfoVO';
import { CellClickedEvent } from 'ag-grid-community';

import { useKPaymentAdColDefs } from './hooks/KPaymentListAdDefs';
import { useKPaymentDetailAdColDefs } from './hooks/KPaymentListDetailAdDefs';
import { useKNonPaymentAdColDefs } from './hooks/KNonPaymentAdDefs';
import { useKFrgnCurPaymentAdColDefs } from './hooks/KFrgnCurPaymentAdDefs';
import { useKFrgnCurPaymentDetailAdColDefs } from './hooks/KFrgnCurPaymentDetailAdDefs';
import {
  getKPaymentExcelDownloadAdApi,
  getKPaymentDetailExcelDownloadAdApi,
  getKNonPaymentExcelDownloadAdApi,
  getFrgnCurExcelDownloadAdApi,
  getFrgnCurDetailsExcelDownloadAdApi,
} from 'apis/vars/payment/PaymentExcelAdminApi';
import SubTitleBoxVars from 'components/vars/common/SubTitleBoxVars';
import GSelectMUIAtom from 'components/common/select/GSelectMUIAtom';
import { GLabelAtom } from 'components/atom/label';
import { CommonCode } from 'models/admin/CommonCode';
import { getSapCopCds } from 'apis/vars/member/Member';
import {
  getFrgnCurAdApi,
  getFrgnCurDetailAdApi,
  getKNonPaymentAdApi,
  getKPaymentDetailAdApi,
  getKPaymentListAdApi,
} from 'apis/vars/payment/PaymentAdminApi';
import {
  KFrgnCurDetail,
  KFrgnCurRes,
  KNonPaymentRes,
  KPaymentDetail,
  KPaymentRes,
} from '../payment/KPaymentSchedulePage';
import useSessionStore from 'stores/useSessionStore';

// 조회조건
export interface PaymentSearchParams {
  copCd: string; // 법인명
  dataInsDtmSt: string; // 조회기간 시작일자
  dataInsDtmEnd: string; // 조회기간 종료일자
  userId: string;
  downloadName: string;
}

// 상세요청 값
export interface DetailReq {
  accnNo: string;
  slipNo: string; // 0404 전표번호 추가
  payBankCd: string;
  payDt: string;
  userId: string;
  downloadName: string;
  copCd: string;
}

const KPaymentAdminPage = () => {
  const { t } = useTranslation();
  const gridApiRef = useRef<any>(null);
  const { openLoading } = useLoading();
  const { openMessageBar } = useMessageBar();
  const { openCommonModal } = useCommonModal();
  const [selectedTab, setSelectedTab] = useState<number>(1);
  const { roleCodes, copCd } = useSessionStore();

  // 법인명
  const [coCdList, setCoCdList] = useState<CommonCode[]>([]);

  const { defaultColDef, colDefs } = useKPaymentAdColDefs();
  const { defaultColDef1, colDefs1 } = useKPaymentDetailAdColDefs();
  const { defaultColDef2, colDefs2 } = useKNonPaymentAdColDefs();
  const { defaultColDef3, colDefs3 } = useKFrgnCurPaymentAdColDefs();
  const { defaultColDef4, colDefs4 } = useKFrgnCurPaymentDetailAdColDefs();

  const [paymentList, setPaymentList] = useState<KPaymentRes[]>([]);
  const [paymentDetails, setPaymentDetails] = useState<KPaymentDetail[]>([]);
  const [nonPaymentList, setNonPaymentList] = useState<KNonPaymentRes[]>([]);
  const [frgnCurList, setFrgnCurList] = useState<KFrgnCurRes[]>([]);
  const [frgnCurDetail, setFrgnCurDetail] = useState<KFrgnCurDetail[]>([]);

  const [detailReq, setDetailReq] = useState<DetailReq>({
    accnNo: '',
    slipNo: '',
    payBankCd: '',
    payDt: '',
    userId: '',
    downloadName: '',
    copCd: '',
  });

  const [searchParams, setSearchParams] = useState<PaymentSearchParams>({
    copCd: '',
    // 0329 조회기간 디폴트 3개월 재수정(현업요청)
    dataInsDtmSt: dayjs().subtract(3, 'month').format('YYYYMMDD'),
    dataInsDtmEnd: dayjs().format('YYYYMMDD'),
    userId: '',
    downloadName: '',
  });

  useEffect(() => {
    //법인명 가져오기
    getSapCopCds().then((result: CommonCode[]) => {
      setCoCdList(result);
    });

    // session에서 copCd
    // 한국 PTN C100
    // 외국 PTN
    // copCd === C100인 경우에만 {...detailReq, copCd : 'C100'}

    // 외국인인데, 국내법인CPA 인 경우는 없음 (국내 법인은 CPA 역할이 없음)
    // 한국인인데, 해외법인CPA 인 경우 => 국내조회는 안보여야됨 / 해외조회는 가능해야함 <- 정정 : 둘다 보여야함
    // if (copCd === 'C100' && roleCodes.every((role) => !role.includes('CPA'))) {
    // setDetailReq({ ...detailReq, copCd: 'C100' });
    // setSearchParams({ ...searchParams, copCd: 'C100' });
    // }

    // 외국인인데, 국내법인CPA 인 경우는 없음 (국내 법인은 CPA 역할이 없음)
    // 한국인인데, 해외법인CPA 인 경우는 둘다 보여줘야함
    setDetailReq({ ...detailReq, copCd: 'C100' });
    setSearchParams({ ...searchParams, copCd: 'C100' });
  }, []);

  // 그리드 행 선택 시 상세 api호출
  useEffect(() => {
    if (detailReq.accnNo) {
      openLoading(true);
      if (selectedTab === 1) {
        getKPaymentDetailAdApi(detailReq).then((res) => {
          setPaymentDetails(res);
          openLoading(false);
        });
      } else if (selectedTab === 2) {
        getFrgnCurDetailAdApi(detailReq).then((res) => {
          setFrgnCurDetail(res);
          openLoading(false);
        });
      }
    }
  }, [detailReq]);

  // Vendor ID 대문자로 입력받기
  const handleChangeVendorId = (e) => {
    const upperVendorId = e.target.value.trim().toUpperCase();
    setSearchParams((prev) => {
      return { ...prev, userId: upperVendorId };
    });
  };

  const handleCrtDateChange = (newDate, id) => {
    handleDateRangeChange(newDate, id, 'dataInsDtm', 'YYYY.MM.DD');
  };

  const handleDateRangeChange = (newDate, id, key, format) => {
    const newParams = {};
    if (id === 'end') {
      newParams[key + 'End'] = newDate.format(format);

      if (searchParams[key + 'St'] === '' || newDate.isBefore(dayjs(searchParams[key + 'St'])))
        newParams[key + 'St'] = newDate.format(format);

      setSearchParams((prev) => {
        return {
          ...prev,
          ...newParams,
        };
      });
    } else if (id === 'start') {
      newParams[key + 'St'] = newDate.format(format);

      if (searchParams[key + 'End'] === '' || newDate.isAfter(dayjs(searchParams[key + 'End'])))
        newParams[key + 'End'] = newDate.format(format);

      setSearchParams((prev) => {
        return {
          ...prev,
          ...newParams,
        };
      });
    }
  };

  const handleReset = () => {
    setSearchParams({
      copCd: searchParams.copCd,
      dataInsDtmSt: dayjs().subtract(3, 'month').format('YYYYMMDD'),
      dataInsDtmEnd: dayjs().format('YYYYMMDD'),
      userId: '',
      downloadName: '',
    });
  };

  // 탭별 API 호출 분기처리
  const getDataByTap = (value, searchParams) => {
    if (value === 1) {
      getKPaymentListAdApi(searchParams).then((res) => {
        setPaymentList(res);
        openLoading(false);
        if (!res) {
          setPaymentList([]);
        }
      });
    } else if (value === 2) {
      getFrgnCurAdApi(searchParams).then((res) => {
        setFrgnCurList(res);
        openLoading(false);
        if (!res) {
          setFrgnCurList([]);
        }
      });
    } else if (value === 3) {
      getKNonPaymentAdApi(searchParams).then((res) => {
        setNonPaymentList(res);
        openLoading(false);
        if (!res) {
          setNonPaymentList([]);
        }
      });
    }
    setPaymentDetails([]);
    setFrgnCurDetail([]);
  };

  const handleSearch = () => {
    if (searchParams.copCd.trim() === '') {
      return openCommonModal({
        content: t(
          'com.label.01725',
          '__본인이 소속된 법인이 아닌 거래처 지급내역은 조회할 수 없습니다.'
        ),
      });
    }
    if (searchParams.userId.trim() === '') {
      return openCommonModal({
        content: t('com.label.00016', '__Vendor ID를 입력하세요.'),
      });
    }
    if (
      searchParams.dataInsDtmSt === 'Invalid Date' ||
      searchParams.dataInsDtmEnd === 'Invalid Date'
    ) {
      return openCommonModal({
        content: t('com.label.00477', '__조회기간을 설정해주세요.'),
      });
    }

    openLoading(true);
    const setdataInsDtmSt = dayjs(searchParams.dataInsDtmSt).format('YYYYMMDD');
    const setdataInsDtmEnd = dayjs(searchParams.dataInsDtmEnd).format('YYYYMMDD');
    const formattedReq = {
      dataInsDtmSt: setdataInsDtmSt,
      dataInsDtmEnd: setdataInsDtmEnd,
      userId: searchParams.userId,
      copCd: searchParams.copCd,
    };
    getDataByTap(selectedTab, formattedReq);
  };

  const handleTabChange = (e, value) => {
    setSelectedTab(value);
    openLoading(true);
    getDataByTap(value, searchParams);
  };

  return (
    <>
      <SearchWrap>
        <UnHiddenSearchWrap>
          <SearchItemWrap>
            {/* Vendor ID 입력 */}
            <SearchMolecule
              labelTitle="Vendor ID"
              labelWidth="100px"
              searchWidth="22%"
              searchValueWidth="200px"
              type="input"
              isRequired
              textInputValue={searchParams.userId}
              placeholder={t('com.label.00016', '__Vendor ID를 입력하세요')}
              onChange={handleChangeVendorId}
              maxLength={12}
            />
            {/* 법인명(C100) 고정 */}
            <GInputArea searchWidth="33%">
              <GLabelAtom labelWidth="120px">{t('com.label.copCd', '법인코드')}</GLabelAtom>
              <ItemWrap searchValueWidth="380px">
                <GSelectMUIAtom
                  status="default"
                  // selectWidth="350px"
                  option={coCdList}
                  placeholder={t('com.label.00023', '__선택') as string}
                  codeKey="cmnCd"
                  codeName="cmnCdDesc"
                  value={searchParams.copCd}
                  disabled
                  readOnly
                  handleChange={(e) => console.log(e.target.value)}
                />
              </ItemWrap>
            </GInputArea>

            {/* 미지급내역 탭 : 조회일자(오늘날) 고정 */}
            {selectedTab === 3 ? (
              <SearchMolecule
                type="calendar"
                isRequired
                calendarReadonly
                labelTitle={t('com.label.00113', '__지급일자')}
                calendarDouble={false}
                calendarFormat="YYYY.MM.DD"
                calendarViews={['year', 'month', 'day']}
                calendarWidth="128px"
                date={dayjs()}
                // searchWidth="33%"
              />
            ) : (
              <SearchMolecule
                type="calendar"
                isRequired
                labelTitle={t('com.label.00113', '__지급일자')}
                calendarDouble={true}
                calendarFormat="YYYY.MM.DD"
                calendarViews={['year', 'month', 'day']}
                calendarWidth="128px"
                startDate={dayjs(searchParams.dataInsDtmSt)}
                endDate={dayjs(searchParams.dataInsDtmEnd)}
                onChange={handleCrtDateChange}
                mindate={'2001.01.01'}
                maxdate={`${String(Number(new Date().getFullYear()) + 20)}.01.01`}
                // searchWidth="33%"
              />
            )}
          </SearchItemWrap>
          <GButtonLayout marginBottom="0px" paddingTop="0px">
            <GButtonBoxCum>
              <GButton
                chkImg={RecycleB}
                sizes="medium"
                btnstyled="outline"
                btnColor="normal"
                hasImg={true}
                hasTxt={false}
                onClick={handleReset}
              ></GButton>
              <GButton
                chkImg={searchImage}
                txt={t('com.label.00116', '__조회')}
                sizes="medium"
                btnstyled="contained"
                btnColor="normal"
                hasImg={true}
                hasTxt={true}
                onClick={handleSearch}
              ></GButton>
            </GButtonBoxCum>
          </GButtonLayout>
        </UnHiddenSearchWrap>
      </SearchWrap>

      <GTabs
        flex={true}
        defaultValue={1}
        title={[
          t('com.label.00111', '__지급내역조회'),
          t('com.label.00115', '__외화지급조회'),
          t('com.label.00335', '__미지급내역'),
        ]}
        onChange={handleTabChange}
      >
        <>
          <SubTitleBtnArea>
            <SubTitleBoxVars
              showSubTitle={true}
              content={t('com.label.00648', '__지급내역목록')}
              isInfo={true}
              pTxt={
                t(
                  'com.tooltip.pay01',
                  `__※ 원화 지급 기준
- 月 거래기준 합계액이 1천만원 이하인 경우 현금 결제 / 1천만원 초과인 경우 전자어음 결제\r\n
* 대기업의 경우 거래 합계액 불문 전자어음 결제
  - 전자어음 결제시 중소기업의 경우 60일 어음 / 대기업의 경우 90일 어음
  - 현금 결제일 : 매월 10일 / 20일 / 末일
  - 전자어음 결제일 : 매월 10일(전월 16~전월 말일자 계산서 및 전표처리 완료 분까지) 
    / 매월 20일(당월 1일~당월 15일자 계산서 및 전표처리 완료 분까지)`
                ) ?? ''
              }
              pPlace="3"
            />
            <TitleBtnBox>
              <GButton
                txt={t('com.label.00666', '__다운로드')}
                chkImg={Download}
                btnstyled="outline"
                btnColor="normal"
                sizes="small"
                hasTxt={true}
                hasImg={true}
                disabled={paymentList?.length > 0 ? false : true}
                onClick={() => {
                  openLoading(true);
                  getKPaymentExcelDownloadAdApi({
                    ...searchParams,
                    downloadName: t('com.label.00111', '__지급내역조회'),
                  })
                    .then((res) => {
                      openLoading(false);
                    })
                    .catch((error) => {
                      console.error('다운로드 중 에러 발생:', error);
                      openLoading(false);
                    });
                }}
              />
            </TitleBtnBox>
          </SubTitleBtnArea>
          <GContentGrid
            type="none"
            className="ag-theme-alpine"
            style={{ flex: 1, marginBottom: '12px' }}
            isSortable={false}
          >
            <AgGridReact
              defaultColDef={defaultColDef}
              columnDefs={colDefs}
              rowData={paymentList}
              rowSelection={'single'}
              enableCellTextSelection
              onGridReady={(params) => (gridApiRef.current = params.api)}
              tooltipShowDelay={0}
              noRowsOverlayComponent={EmptyState}
              noRowsOverlayComponentParams={{ type: 'grid' }}
              onCellClicked={(e: CellClickedEvent) => {
                setDetailReq({
                  accnNo: e.data.accnNo,
                  slipNo: e.data.slipNo,
                  payBankCd: e.data.payBankCd,
                  payDt: e.data.payDt,
                  userId: searchParams.userId,
                  downloadName: '',
                  copCd: searchParams.copCd,
                });
              }}
            ></AgGridReact>
          </GContentGrid>
          <SubTitleBtnArea>
            <SubTitleBox showSubTitle={true} content={t('com.label.00120', '__지급내역상세')} />
            <TitleBtnBox>
              <GButton
                txt={t('com.label.00666', '__다운로드')}
                chkImg={Download}
                btnstyled="outline"
                btnColor="normal"
                sizes="small"
                hasTxt={true}
                hasImg={true}
                disabled={paymentDetails?.length > 0 ? false : true}
                onClick={() => {
                  openLoading(true);
                  getKPaymentDetailExcelDownloadAdApi({
                    ...detailReq,
                    downloadName: t('com.label.00120', '__지급내역상세'),
                  })
                    .then((res) => {
                      openLoading(false);
                    })
                    .catch((error) => {
                      console.error('다운로드 중 에러 발생:', error);
                      openLoading(false);
                    });
                }}
              />
            </TitleBtnBox>
          </SubTitleBtnArea>
          <GContentGrid
            type="none"
            className="ag-theme-alpine"
            style={{ flex: 1, marginBottom: '8px' }}
            isSortable={false}
          >
            <AgGridReact
              defaultColDef={defaultColDef1}
              columnDefs={colDefs1}
              rowData={paymentDetails}
              rowSelection={'single'}
              enableCellTextSelection
              onGridReady={(params) => (gridApiRef.current = params.api)}
              tooltipShowDelay={0}
              noRowsOverlayComponent={EmptyState}
              noRowsOverlayComponentParams={{ type: 'grid' }}
            ></AgGridReact>
          </GContentGrid>
        </>
        <>
          <SubTitleBtnArea>
            <SubTitleBox
              showSubTitle={true}
              content={t('com.label.00649', '__외화지급목록')}
              isVisibleInfo={true}
              infoContent={t(
                'com.tooltip.pay02',
                `__※ 외화 지급의 경우 지급 만기일이 도래하더라도 LG에너지솔루션 담당자의 송금 요청이 있어야 지급 됩니다.`
              )}
            />
            <TitleBtnBox>
              <GButton
                txt={t('com.label.00666', '__다운로드')}
                chkImg={Download}
                btnstyled="outline"
                btnColor="normal"
                sizes="small"
                hasTxt={true}
                hasImg={true}
                disabled={frgnCurList?.length > 0 ? false : true}
                onClick={() => {
                  openLoading(true);
                  getFrgnCurExcelDownloadAdApi({
                    ...searchParams,
                    downloadName: t('com.label.00649', '__외화지급목록'),
                  })
                    .then((res) => {
                      openLoading(false);
                    })
                    .catch((error) => {
                      console.error('다운로드 중 에러 발생:', error);
                      openLoading(false);
                    });
                }}
              />
            </TitleBtnBox>
          </SubTitleBtnArea>
          <GContentGrid
            type="none"
            className="ag-theme-alpine"
            style={{ flex: 1, marginBottom: '12px' }}
            isSortable={false}
          >
            <AgGridReact
              defaultColDef={defaultColDef3}
              columnDefs={colDefs3}
              rowData={frgnCurList}
              rowSelection={'single'}
              enableCellTextSelection
              onGridReady={(params) => (gridApiRef.current = params.api)}
              tooltipShowDelay={0}
              noRowsOverlayComponent={EmptyState}
              noRowsOverlayComponentParams={{ type: 'grid' }}
              onCellClicked={(e: CellClickedEvent) => {
                setDetailReq({
                  accnNo: e.data.accnNo,
                  slipNo: e.data.slipNo,
                  payBankCd: e.data.payBankCd,
                  payDt: e.data.payDt,
                  userId: searchParams.userId,
                  downloadName: '',
                  copCd: searchParams.copCd,
                });
              }}
            ></AgGridReact>
          </GContentGrid>
          <SubTitleBtnArea>
            <SubTitleBox showSubTitle={true} content={t('com.label.00650', '__외화지급상세')} />
            <TitleBtnBox>
              <GButton
                txt={t('com.label.00666', '__다운로드')}
                chkImg={Download}
                btnstyled="outline"
                btnColor="normal"
                sizes="small"
                hasTxt={true}
                hasImg={true}
                disabled={frgnCurDetail?.length > 0 ? false : true}
                onClick={() => {
                  openLoading(true);
                  getFrgnCurDetailsExcelDownloadAdApi({
                    ...detailReq,
                    downloadName: t('com.label.00650', '__외화지급상세'),
                  })
                    .then((res) => {
                      openLoading(false);
                    })
                    .catch((error) => {
                      console.error('다운로드 중 에러 발생:', error);
                      openLoading(false);
                    });
                }}
              />
            </TitleBtnBox>
          </SubTitleBtnArea>
          <GContentGrid
            type="none"
            className="ag-theme-alpine"
            style={{ flex: 1, marginBottom: '8px', height: '345px' }}
            isSortable={false}
          >
            <AgGridReact
              defaultColDef={defaultColDef4}
              columnDefs={colDefs4}
              rowData={frgnCurDetail}
              rowSelection={'single'}
              enableCellTextSelection
              onGridReady={(params) => (gridApiRef.current = params.api)}
              tooltipShowDelay={0}
              noRowsOverlayComponent={EmptyState}
              noRowsOverlayComponentParams={{ type: 'grid' }}
            ></AgGridReact>
          </GContentGrid>
        </>
        <>
          <SubTitleBtnArea>
            <SubTitleBox
              showSubTitle={false}
              content={t('com.label.00335', '미지급내역')}
              isVisibleInfo={true}
              infoContent={t(
                'com.tooltip.pay03',
                `__※ 위 내역은 ERP에 기표된 내역으로 지급계좌 등록 여부, 상계 등에 따라 금액 및 지급 예정일자가 달라질 수 있습니다.`
              )}
            />
            <TitleBtnBox>
              <GButton
                txt={t('com.label.00666', '__다운로드')}
                chkImg={Download}
                btnstyled="outline"
                btnColor="normal"
                sizes="small"
                hasTxt={true}
                hasImg={true}
                disabled={nonPaymentList?.length > 0 ? false : true}
                onClick={() => {
                  openLoading(true);
                  getKNonPaymentExcelDownloadAdApi({
                    ...searchParams,
                    downloadName: t('com.label.00335', '미지급내역'),
                  })
                    .then((res) => {
                      openLoading(false);
                    })
                    .catch((error) => {
                      console.error('다운로드 중 에러 발생:', error);
                      openLoading(false);
                    });
                }}
              />
            </TitleBtnBox>
          </SubTitleBtnArea>
          <GContentGrid
            type="none"
            className="ag-theme-alpine"
            style={{ flex: 1, marginBottom: '8px' }}
            isSortable={false}
          >
            <AgGridReact
              defaultColDef={defaultColDef2}
              columnDefs={colDefs2}
              rowData={nonPaymentList}
              rowSelection={'single'}
              enableCellTextSelection
              onGridReady={(params) => (gridApiRef.current = params.api)}
              tooltipShowDelay={0}
              noRowsOverlayComponent={EmptyState}
              noRowsOverlayComponentParams={{ type: 'grid' }}
            ></AgGridReact>
          </GContentGrid>
        </>
      </GTabs>
    </>
  );
};

export default KPaymentAdminPage;
