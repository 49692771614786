/** @jsxImportSource @emotion/react */
import { useCallback, MouseEvent, useMemo, useEffect, useState, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useMessageManagementStore } from 'stores/useMessageManagementStore';
import {
  ContentSection,
  GridButtons,
  GridInfo,
  GridInfoSection,
} from 'components/layouts/ContentSection';
import DoneIcon from '@mui/icons-material/Done';
import { AgGridReact } from 'ag-grid-react';
import {
  CellClickedEvent,
  CellValueChangedEvent,
  ColDef,
  EditableCallbackParams,
  GridApi,
  GridReadyEvent,
  GridSizeChangedEvent,
  ICellRendererParams,
  ISelectCellEditorParams,
  RowDataUpdatedEvent,
} from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import { MessageCondition, ShowingMessage } from 'models/admin/Message';
import { CrudCode } from 'models/common/Edit';
import { AgGridCommonConfig, crudKeyRowStyle } from 'utils/GridUtil';
import {
  IMessageCellRendererParams,
  MessageCellRenderer,
} from 'components/grids/MessageCellRenderer';
import {
  DropdownCellRenderer,
  DropdownItem,
  IDropdownCellRendererParams,
} from 'components/grids/DropdownCellRenderer';
import { BlueButton, GreyButton, GreyLineButton } from 'components/buttons/CustomButton';
import { useCommonModal } from 'hooks/useCommonModal';
import SelectCellRenderer from 'components/grids/SelectCellRenderer';

import { SearchBox, SearchButtons, SearchRows } from 'components/layouts/SearchBox';
import { CustomInputText } from 'components/inputs/CustomInput';
import { CommonCode } from 'models/admin/CommonCode';
import { CustomSelect } from 'components/selects/CustomSelect';
import _ from 'lodash';
import { ContentGrid } from 'components/layouts/ContentGrid';
import { deployTranslatedMessages } from 'apis/admin/TranslatedMessage';
import useLanguageStore from 'stores/useLanguageStore';
import { reloadMessages } from 'apis/admin/Message';
import { CommonYN } from 'models/common/Common';
import { useMessageModalStore } from 'stores/useMessageModalStore';
import useSessionStore from 'stores/useSessionStore';
import { replaceWithTimezone } from 'utils/TimeZoneUtil';
import searchImage from 'components/asset/images/Search.svg';
import { GButton } from 'components/atom/button';
import New from 'components/asset/images/Penw.svg';

export const MessageManagementPage = () => {
  const { t } = useTranslation();
  const {
    languageCodes,
    useYnCodes,
    showingMessages,
    findMessages,
    initMessageManagementPage,
    resetMessageManagementPage,
    setMessageManagementGridApi,
    findMessagesWithLastSearchedCondition,
    addMessage,
    removeMessages,
    saveMessages,
    messageManagementGridApi,
  } = useMessageManagementStore();
  const { refreshLanguage } = useLanguageStore();
  const { gridNoRowsTemplate } = useSessionStore();

  const { openCommonModal } = useCommonModal();
  const [msgCtn, setMsgCtn] = useState<string>('');
  const [msgTxtCtn, setMsgTxtCtn] = useState<string>('');
  const [selectedUseYn, setSelectedUseYn] = useState<string>('');
  const { setMessageModalStateWhenModalOpen } = useMessageModalStore();

  const columnDefs = useMemo<ColDef[]>(() => {
    const headColumnDefs: ColDef<ShowingMessage>[] = [
      {
        headerName: 'No.',
        valueGetter: 'node.rowIndex + 1',
        width: 80,
        cellStyle: { textAlign: 'center' },
      },
      // {
      //   headerName: '',
      //   headerCheckboxSelection: true,
      //   checkboxSelection: true,
      //   showDisabledCheckboxes: true,
      //   width: 60,
      //   onCellClicked: (e: CellClickedEvent) => {
      //     setMessageManagementGridApi(e.api);
      //   },
      // },
      {
        headerName: String(t('com.label.msgCd', '__메시지코드')),
        field: 'msgCtn',
        width: 200,
        editable: (params: EditableCallbackParams) => params.data['crudKey'] === CrudCode.CREATE,
        cellRenderer: MessageCellRenderer,
        cellRendererParams: (params: ICellRendererParams) => {
          return {
            ...params,
            showButton: () => {
              return params.data['crudKey'] !== CrudCode.CREATE;
            },
            yesCallback: () => {
              findMessagesWithLastSearchedCondition();
            },
          } as IMessageCellRendererParams;
        },
      },
    ];

    const langColumnDefs: ColDef<ShowingMessage>[] = languageCodes.map((languageCode, index) => {
      return {
        headerName: t(`${languageCode.cmnCd}`),
        field: `msgTxtCtn${index + 1}`,
        minWidth: 100,
        flex: 1,
      };
    }) as ColDef[];

    const tailColumnDefs: ColDef<ShowingMessage>[] = [
      {
        headerName: String(t('com.label.useYn', '__사용여부')),
        field: 'useYn',
        width: 110,
        cellStyle: { textAlign: 'center' },
        cellRenderer: SelectCellRenderer,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: [CommonYN.Y, CommonYN.N],
        } as ISelectCellEditorParams,
      },
      {
        headerName: String(t('com.label.00058', '__작성자')),
        field: 'dataInsUserId',
        width: 150,
      },
      {
        headerName: String(t('common.column.작성일시', '__작성일시')),
        field: 'strDataInsDtm',
        valueFormatter: (params: any) => {
          if (params.value !== null) {
            return replaceWithTimezone(params.value, 'YYYY-MM-DD HH:mm:ss');
          } else {
            return '';
          }
        },
        width: 200,
      },
      {
        headerName: String(t('com.label.upus', '__수정자')),
        field: 'dataUpdUserId',
        width: 100,
      },
      {
        headerName: String(t('com.label.updDtm', '__수정일시')),
        field: 'strDataUpdDtm',
        valueFormatter: (params: any) => {
          if (params.value !== null) {
            return replaceWithTimezone(params.value, 'YYYY-MM-DD HH:mm:ss');
          } else {
            return '';
          }
        },
        width: 200,
      },
    ];

    const hideColumnDefs: ColDef<ShowingMessage>[] = [
      { field: 'crudKey', hide: true },
      { field: 'uuid', hide: true },
    ];

    return [
      ...hideColumnDefs,
      ...headColumnDefs,
      ...langColumnDefs,
      ...tailColumnDefs,
    ] as ColDef<ShowingMessage>[];
  }, [languageCodes, setMessageManagementGridApi]);

  const onMessageRowDataUpdated = (e: RowDataUpdatedEvent) => {
    e.api.refreshCells();
  };

  const onMessageCellValueChange = useCallback(
    (e: CellValueChangedEvent) => {
      const { data, newValue, oldValue, api } = e;

      if (newValue === oldValue) {
        return;
      }

      const storedMessage = showingMessages.find(
        (showingMessage) => showingMessage.uuid === data['uuid']
      );

      if (storedMessage) {
        if (data.crudKey !== CrudCode.CREATE) {
          data.crudKey = CrudCode.UPDATE;
        }

        api.applyTransaction({
          update: [data],
        });

        api.refreshCells({
          force: true,
          suppressFlash: true,
        });
      }
    },
    [showingMessages, findMessagesWithLastSearchedCondition]
  );

  const handleMsgCtnInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setMsgCtn(e.target.value);
  }, []);

  const handleMsgTxtCtnInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setMsgTxtCtn(e.target.value);
  }, []);

  const handleUseYnSelected = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedUseYn(e.target.value);
  }, []);

  const handleSubmit = useCallback(
    (e: MouseEvent<HTMLButtonElement> | undefined) => {
      if (e) {
        e.preventDefault();
      }

      if (msgCtn == '' && msgTxtCtn == '') {
        openCommonModal({
          modalType: 'alert',
          content: t('com.label.mgt.1026', '__메세지코드나 메세지를 입력하세요'),
        });
        return;
      }
      findMessages({
        msgCtn: msgCtn,
        msgTxtCtn: msgTxtCtn,
        useYn: selectedUseYn,
      } as MessageCondition);
    },
    [msgCtn, msgTxtCtn, selectedUseYn]
  );

  const handleAddButtonClick = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    addMessage();
  }, []);

  const defaultColDef: ColDef = {
    resizable: true,
  };

  const handleRemoveButtonClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      if (_.isEmpty(messageManagementGridApi?.getSelectedNodes())) {
        openCommonModal({
          content: t('com.label.mgt.1027', '__삭제할 데이터를 선택해주세요'),
        });
        return;
      }
      openCommonModal({
        modalType: 'confirm',
        content: t('com.label.mgt.1028', '__삭제하시겠습니까? 기존 작업 내용이 사라집니다'),
        yesCallback: removeMessages,
        showCallbackResult: true,
      });
    },
    [openCommonModal, removeMessages, messageManagementGridApi]
  );

  const handleDeploy = async () => {
    openCommonModal({
      content: t('com.label.mgt.1023', '__다국어가 적용되기까지 수분이 소요됩니다.'),
    });
    await reloadMessages();
    if (await deployTranslatedMessages()) {
      refreshLanguage();
    }
  };

  const handleCreate = async () => {
    setMessageModalStateWhenModalOpen('', undefined, () => {
      if (msgCtn || msgTxtCtn) handleSubmit(undefined);
      openCommonModal({ content: t('com.msg.save', '__저장되었습니다.') });
    });
  };

  useEffect(() => {
    initMessageManagementPage();

    return () => {
      resetMessageManagementPage();
    };
  }, []);

  return (
    <>
      <SearchBox>
        <SearchRows>
          <div className="searchCol">
            <label>{t('com.label.msgCd', '__메시지코드')}</label>
            <CustomInputText
              id="msgCtn"
              name="msgCtn"
              type="text"
              value={msgCtn}
              onChange={handleMsgCtnInputChange}
            />
          </div>
          <div className="searchCol">
            <label>{t('com.label.mgt.1004', '__메시지')}</label>
            <CustomInputText
              id="msgTxtCtn"
              name="msgTxtCtn"
              type="text"
              value={msgTxtCtn}
              onChange={handleMsgTxtCtnInputChange}
            />
          </div>
          <div className="searchCol">
            <label>{t('com.label.useYn', '__사용여부')}</label>
            <CustomSelect id="useYn" value={selectedUseYn} onChange={handleUseYnSelected}>
              {[
                { cmnCdNm: t('com.label.00511', '__전체'), cmnCd: '' } as CommonCode,
                ...useYnCodes,
              ].map((useYnCode) => (
                <option key={useYnCode.cmnCd} value={useYnCode.cmnCd}>
                  {useYnCode.cmnCdNm}
                </option>
              ))}
            </CustomSelect>
          </div>
        </SearchRows>
        <SearchButtons>
          <GButton
            chkImg={searchImage}
            txt={t('com.label.00116', '__조회')}
            sizes="medium"
            btnstyled="contained"
            btnColor="normal"
            hasImg={true}
            hasTxt={true}
            onClick={handleSubmit}
          ></GButton>
        </SearchButtons>
      </SearchBox>
      <GridInfoSection>
        <GridInfo>
          <span>{t('com.label.mgt.1003', '__메시지목록')}</span>
          <span className="primary">
            {showingMessages.length}
            {t('com.label.case', '__건')}
          </span>
        </GridInfo>
        <GridButtons>
          <GreyLineButton className="small" onClick={handleDeploy}>
            <RefreshIcon></RefreshIcon>
            {t('com.label.mgt.1009', '__다국어 적용')}
          </GreyLineButton>
        </GridButtons>
      </GridInfoSection>
      <ContentGrid className="ag-theme-alpine pagination">
        <AgGridReact
          overlayNoRowsTemplate={gridNoRowsTemplate}
          rowData={showingMessages}
          columnDefs={columnDefs}
          rowSelection="multiple"
          suppressRowClickSelection={true}
          suppressDragLeaveHidesColumns={true}
          suppressMovableColumns={true}
          suppressColumnMoveAnimation={true}
          defaultColDef={defaultColDef}
          {...AgGridCommonConfig}
          getRowStyle={crudKeyRowStyle}
          onGridReady={(e: GridReadyEvent) => {
            // e.api.sizeColumnsToFit();
            setMessageManagementGridApi(e.api as GridApi);
          }}
          onRowDataUpdated={onMessageRowDataUpdated}
          onCellValueChanged={onMessageCellValueChange}
          onGridSizeChanged={(e: GridSizeChangedEvent) => {
            e.api.sizeColumnsToFit();
          }}
        />
      </ContentGrid>
      <GridInfoSection className="contentEnd">
        <GButton
          chkImg={New}
          txt={t('com.label.new', '__신규')}
          sizes="medium"
          btnstyled="contained"
          btnColor="primary"
          hasImg={true}
          hasTxt={true}
          onClick={handleCreate}
        ></GButton>
      </GridInfoSection>
    </>
  );
};
