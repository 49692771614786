import React, { InputHTMLAttributes } from 'react';
import styled from '@emotion/styled';

import AttatchIcon from 'components/asset/images/CommentAttatch.svg';
import ErrorIcon from '../../asset/images/error.svg';
import WarningIcon from '../../asset/images/warning.svg';
import ConfirmedIcon from '../../asset/images/confirmed.svg';
import Save from 'components/asset/images/Confirm.svg';
import InReplyArrow from 'components/asset/images/inReplyArrow.svg';
import { GlobalTokenColor } from 'ui/theme/Color';
import { GButton } from '../button';
import { HelperText } from '../input';

export interface GTextDivProps extends InputHTMLAttributes<HTMLDivElement> {
  status: 'default' | 'disabled' | 'readonly' | 'error' | 'warning' | 'confirmed' | undefined;

  width?: string;
  height?: string;

  // charlimit?: boolean;
  count?: string;

  borderColor?: string;
  flex?: boolean;

  isComment?: boolean; // 댓글
  reCommentIng?: boolean; // 대댓글 - @@님께 다는 중 텍스트 나오도록

  helperText?: string;
}

export interface GTextFieldProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  charlimit?: boolean;
  isComment?: boolean; // 댓글
  reComment?: boolean; // 대댓글, 수정 - 취소 버튼이 추가됨
  reCommentIng?: boolean; // 대댓글 - @@님께 다는 중 텍스트 나오도록
  nickName?: string; // 대댓글을 다는 대상 이름
}

const TextArea: React.FC<GTextDivProps & GTextFieldProps> = (props) => {
  return (
    <>
      <StyledTextArea {...props}>
        {props.reCommentIng && (
          <ReCommentStyle>
            <InArrow src={InReplyArrow} alt="Reply Arrow" />
            <Nickname>{props.nickName}</Nickname>님에게 답글 다는 중
          </ReCommentStyle>
        )}
        <StyledTextField {...props} />

        {props.charlimit && <CountStyle>{props.count}</CountStyle>}
        {props.isComment && (
          <CommentStyle>
            {props.count}
            <ButtonBox>
              <GButton
                chkImg={AttatchIcon}
                btnstyled="unfilled"
                sizes="medium"
                hasImg={true}
                hasTxt={false}
              />
              {props.reComment && (
                <GButton
                  txt="취소"
                  btnstyled="unfilled"
                  sizes="medium"
                  btnColor="normal"
                  hasImg={false}
                  hasTxt={true}
                ></GButton>
              )}
              <GButton
                chkImg={Save}
                txt="저장"
                sizes="medium"
                btnstyled="contained"
                btnColor="primary"
                hasImg={true}
                hasTxt={true}
                disabled
              ></GButton>
            </ButtonBox>
          </CommentStyle>
        )}
      </StyledTextArea>
      {props.helperText && <HelperText status={props.status}>{props.helperText}</HelperText>}
    </>
  );
};

const StyledTextArea = styled.div<GTextDivProps>`
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 6px 8px;
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || '100%'};
  border-radius: 2px;
  border: 1px solid ${(props) => props.borderColor || GlobalTokenColor.ESGrey400};
  background: ${GlobalTokenColor.ESGreyWHite};
  ${(props) => props.flex && 'flex : 1;'}

  &:focus-within {
    border-color: ${GlobalTokenColor.ESGrey700};
  }

  /* status prop */
  ${(props) =>
    props.status === 'disabled' &&
    `
    background: ${GlobalTokenColor.StatsuDone100};
    `}

  ${(props) =>
    props.status === 'readonly' &&
    `
    background: ${GlobalTokenColor.StatsuDone100};
    color: ${GlobalTokenColor.ESGrey900};
  `}

  ${(props) =>
    props.status === 'error' &&
    `
    border-color: ${GlobalTokenColor.StatusError300};

    padding-right: 28px;
    
    // error icon
    background: url(${ErrorIcon}) no-repeat calc(100% - 4px) 6px;
    
    `}
    
    ${(props) =>
    props.status === 'warning' &&
    `
    border-color: ${GlobalTokenColor.StatusWarningMajor300};
    
    padding-right: 28px;

    // warning icon
    background: url(${WarningIcon}) no-repeat calc(100% - 4px) 6px;
    
    `}
    
    ${(props) =>
    props.status === 'confirmed' &&
    `
    border-color: ${GlobalTokenColor.StatusConfirmed300};
    
    padding-right: 28px;
    
    // confirmed icon
    background: url(${ConfirmedIcon}) no-repeat calc(100% - 4px) 6px;
      
    `}
    
    ${(props) =>
    props.isComment &&
    `
      padding: 8px;
        
      `}
    ${(props) =>
    props.reCommentIng &&
    `
      height: auto;
        
      `}
`;

const StyledTextField = styled.textarea<GTextFieldProps>`
  border: none;
  background: none;
  width: 100%;
  flex: 1;
  resize: none;
  color: #5b5c5b;

  font-family: Spoqa Han Sans Neo;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  &::placeholder {
    font-family: 'Spoqa Han Sans Neo';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: ${GlobalTokenColor.ESGrey500};
  }

  &:focus {
    outline: none;
  }

  ${(props) =>
    props.charlimit &&
    `
    height: 75%;
    `}
  ${(props) =>
    props.isComment &&
    `
    height: 60px;
    margin-bottom: 8px;
    `}
  ${(props) =>
    props.reCommentIng &&
    `
      height: 60px;
      margin-bottom: 8px;
    `}
`;

const CountStyle = styled.p`
  color: ${GlobalTokenColor.ESGrey700};
  font-size: 12px;
  font-family: 'Spoqa Han Sans Neo';
  font-weight: 400;
  width: 100%;
  height: auto;
`;

const CommentStyle = styled.div<GTextFieldProps>`
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${GlobalTokenColor.ESGrey700};
`;

const Nickname = styled.span`
  color: #1f1f1f;
  font-feature-settings: 'clig' off, 'liga' off;
  margin-right: 2px;

  font-family: Spoqa Han Sans Neo;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`;

const InArrow = styled.img`
  margin-right: 4px;
`;

const ReCommentStyle = styled.div`
  color: #1f1f1f;
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: 'Spoqa Han Sans Neo';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  display: flex;
  align-items: center;
  height: auto;
  margin-bottom: 8px;
`;

const ButtonBox = styled.div`
  display: flex;
  gap: 8px;
`;

export default TextArea;
