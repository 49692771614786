/* eslint-disable */
import { DefaultCellTooltip } from 'components/grids/tooltip/DefaultCellTooltip';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import radioIcon from 'components/asset/RadioColumn.svg';
import { ButtonCellRenderer, IButtonCellRendererParams } from 'components/grids/ButtonCellRenderer';
import { AccnDtlPopupCellRenderer } from 'components/vars/account/grids/AccnDtlPopupCellRenderer';
import { RadioCellRendererVars } from 'components/vars/account/grids/RadioCellRendererVars';
import { ITagCellRendererParams, TagCellRenderer } from 'components/grids/TagCellRenderer';
import { useAccountRegist } from './useAccountRegist';
import { formatDate } from 'components/vars/common/FormatNumber';

type UseColumnDetailDefsProps = {
  callback?: () => void;
  vendorInfo?: any;
};

export type ICellRendererParamsVars = ICellRendererParams & UseColumnDetailDefsProps;

export const useColumnDetailDefs = (props: UseColumnDetailDefsProps) => {
  const { t } = useTranslation();
  const { getTagStatus } = useAccountRegist({});

  const defaultColDef = useMemo((): ColDef => {
    return {
      sortable: false,
      resizable: true,
      cellStyle: { textAlign: 'center' },
      tooltipComponent: DefaultCellTooltip,
    };
  }, []);

  const colDefs: any = [
    {
      headerName: 'check',
      headerComponentParams: {
        template: `<img src=${radioIcon} alt=''/>`,
      },
      width: '28px !important',
      height: '24px !important',
      padding: '2px 6px',
      resizable: false,
      headerCheckboxSelection: true,
      showDisabledCheckboxes: true,
      checkboxSelection: true,
      flex: 0,
      cellStyle: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    {
      headerName: t('No.', 'No.'),
      width: 56,
      field: 'column1',
      tooltipField: 'column1',
      cellStyle: { textAlign: 'center' },
      valueGetter: 'node.rowIndex + 1',
    },
    {
      headerName: t('com.label.00063', '계좌종류'),
      headerTooltip: t('com.label.00063', '계좌종류'),
      width: 100,
      field: 'accnTpCdNm',
      tooltipField: 'accnTpCdNm',
      cellStyle: { textAlign: 'center' },
    },
    {
      headerName: t('com.label.00065', '은행'),
      headerTooltip: t('com.label.00065', '은행'),
      flex: 1,
      minWidth: 250,
      field: 'bankNm',
      tooltipField: 'bankNm',
      cellStyle: { textAlign: 'left' },
    },
    {
      headerName: t('com.label.00671', '__주계좌'), //dftAccnYn
      headerTooltip: t('com.label.00671', '__주계좌'), //dftAccnYn
      // headerComponentParams: {
      //   template: `<p>주계좌</p>`,
      // },
      // width: '80px !important',
      // height: '24px !important',
      // padding: '2px 6px',
      // resizable: false,
      // headerCheckboxSelection: false,
      // showDisabledCheckboxes: true,
      // checkboxSelection: true,
      // flex: 0,
      // cellStyle: {
      //   display: 'flex',
      //   justifyContent: 'center',
      //   width: '80px !important',
      //   height: '24px',
      // },
      field: 'dftAccnYn',
      width: 80,
      minWidth: 80,
      cellRenderer: RadioCellRendererVars,
      cellRendererParams: (params) => {
        if (params.value == 'Y') {
          return {
            valueKey: 'value',
            labelKey: 'label',
            listData: [{ value: 'Y' }],
            ...params,
          };
        } else {
          return {};
        }
      },
    },
    {
      headerName: t('com.label.00067', '예금주'),
      headerTooltip: t('com.label.00067', '예금주'),
      width: 120,
      field: 'dpstNm',
      tooltipField: 'dpstNm',
      cellStyle: { textAlign: 'left' },
    },
    {
      headerName: t('com.label.00070', '계좌번호'),
      headerTooltip: t('com.label.00070', '계좌번호'),
      flex: 1,
      minWidth: 310,
      field: 'encAccnNo',
      tooltipField: 'encAccnNo',
      cellStyle: { textAlign: 'left' },
    },
    // {
    //   headerName: t('com.label.00101', '계좌인증여부'),
    //   headerTooltip: t('com.label.00101', '계좌인증여부'),
    //   width: 100,
    //   field: 'accnNoCertYn',
    //   tooltipField: 'accnNoCertYn',
    //   cellStyle: { textAlign: 'center' },
    // },
    {
      headerName: t('com.label.00071', '계좌용도'),
      headerTooltip: t('com.label.00071', '계좌용도'),
      flex: 1,
      minWidth: 150,
      field: 'accnUsegNm',
      tooltipField: 'accnUsegNm',
      cellStyle: { textAlign: 'left' },
    },
    {
      headerName: t('com.label.00076', '통화'),
      headerTooltip: t('com.label.00076', '통화'),
      width: 64,
      field: 'column10',
      tooltipField: 'column10',
      cellStyle: { textAlign: 'center' },
      valueGetter: (param) => 'KRW',
    },
    {
      headerName: t('com.label.00064', '진행상태'),
      headerTooltip: t('com.label.00064', '진행상태'),
      width: 140,
      field: 'accnRegProgStatCdNm',
      tooltipField: 'accnRegProgStatCdNm',
      cellStyle: { textAlign: 'center' },
      cellRenderer: TagCellRenderer,
      cellRendererParams: (params: ITagCellRendererParams) => {
        return {
          label: params.data.accnRegProgStatCdNm,
          status: getTagStatus(params.data.accnRegProgStatCd),
          ...params,
        } as ITagCellRendererParams;
      },
    },
    // {
    //   headerName: t('com.label.apprReqDate', '__결재요청일'),
    //   headerTooltip: t('com.label.apprReqDate', '__결재요청일'),
    //   width: 140,
    //   field: 'regReqDt',
    //   tooltipField: 'regReqDt',
    //   valueFormatter: (params) => formatDate(params),
    //   cellStyle: { textAlign: 'center' },
    // },
    {
      headerName: t('com.label.veiwDtls', '__상세보기'),
      headerTooltip: t('com.label.veiwDtls', '__상세보기'),
      width: 140,
      field: 'column13',
      tooltipField: 'column13',
      // cellRenderer: ButtonCellRenderer,
      // cellRendererParams: (params: IButtonCellRendererParams) => {
      //   return {
      //     label: '상세보기',
      //     btnStyled: 'outline',
      //     btnColor: 'normal',
      //     onClick: () => {
      //       alert('!');
      //     },
      //     ...params,
      //   } as IButtonCellRendererParams;
      // },
      cellRenderer: AccnDtlPopupCellRenderer,
      cellRendererParams: (params: ICellRendererParamsVars) => {
        params.callback = props.callback;
        params.vendorInfo = props.vendorInfo;
        return { ...params };
      },
    },
  ];

  const handleAprvDetailBtn = (data) => {
    console.log('화면 연결이 필요합니다.');
  };

  return { defaultColDef, colDefs };
};
