import styled from '@emotion/styled';
import { BgColor, BorderColor, FontColor, BasicColor } from 'ui/theme/Color';

export const SearchBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px 120px 20px 0;
  border: 1px solid ${BorderColor.Primary};
  background-color: ${BgColor.Gray50};
  margin-bottom: 25px;

  &.foldingBox {
    padding-right: 160px;
    transition: 0.5s;
    overflow: hidden;

    &.close {
      height: 120px;
      padding-top: 22px;

      & > div:not(.searchButtons):nth-child(n + 3) {
        display: none;
      }

      .searchButtons {
        bottom: 22px;
      }
    }

    &.closeNonReview {
      height: 64px;
      padding-top: 18px;

      .searchButtons {
        bottom: 12px;
      }
    }
    &.closeStat {
      height: 108px;
      padding-top: 18px;
      .searchButtons {
        bottom: 12px;
      }
    }
  }
`;

export const SearchRows = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.contentStart {
    justify-content: start;
  }

  &.halfWidth {
    .searchCol {
      flex-basis: 50%;
      label {
        flex-basis: 16.6%;
      }
    }
  }

  &.fullWidth {
    flex-basis: 100%;
    label {
      flex-basis: 8.3%;
    }
  }

  & + & {
    margin-top: 12px;
  }

  .flexBasis15p {
    flex-basis: 15% !important;
  }
  .flexBasis21p {
    flex-basis: 21% !important;
  }
  .flexBasis20p {
    flex-basis: 20% !important;
  }
  .flexBasis25p {
    flex-basis: 25% !important;
  }
  .flexBasis30p {
    flex-basis: 30% !important;
  }

  .searchCol {
    align-items: center;
    display: flex;
    flex: 0 0 33.3333%;

    &.mergeWidth {
      flex-basis: 66.6666%;
      label {
        flex-basis: 12.5%;
      }
    }

    &.fullWidth {
      flex-basis: 100%;
      label {
        flex-basis: 8.3%;
      }
    }

    label {
      display: inline-flex;
      padding-left: 24px;
      font-size: 13px;
      flex: 0 0 25%;
      font-weight: 500;
      color: ${FontColor.Default};
      line-height: 1.2;
    }

    .MuiFormControl-root {
      .MuiFormControlLabel-root {
        padding: 0;
        flex: 0 0 auto;
      }
    }

    label {
      position: relative;

      .required {
        position: absolute;
        top: -2px;
        left: 18px;
        width: 4px;
        height: 4px;
        line-height: 4px;
        color: ${FontColor.Primary};

        &:before {
          content: '';
          display: inline-block;
          width: 4px;
          height: 4px;
          border-radius: 2px;
          background-color: ${BasicColor.Red};
        }
      }
    }

    label + div:not('.MuiStack-root') {
      flex-basis: calc(70% - 12px);
    }
    label + .fullWidth,
    label + .MuiFormControl-root,
    label + .MuiStack-root {
      margin-left: 10px;
    }

    input,
    select {
      flex: 1 auto;
      width: auto;

      &::placeholder {
        color: ${BgColor.Gray200};
        font-size: 13px;
      }
      & + input,
      & + select {
        margin-left: 12px;
      }
    }

    input[type='checkbox'] {
      margin-left: 0;
      padding: 0;
      color: ${BorderColor.Primary};
      width: -webkit-fill-available;

      &.Mui-checked {
        color: #3ec2cf;
      }
    }

    .MuiCheckbox-root {
      margin-left: 10px;
    }

    .dash {
      margin: 0 5px;
    }
  }
`;

export const SearchButtons = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;

  .buttonSrch {
    svg {
      margin-right: 5px;
      font-size: 18px;
    }
  }
  .buttonFold {
    min-width: 36px;
    width: 36px;
    height: 36px;
    padding: 0;

    svg {
      transform: rotate(180deg);

      transition: 0.5s;
    }

    &.close {
      svg {
        transform: rotate(0deg);
      }
    }
  }
`;
