import { ColDef } from 'ag-grid-community';
import { DefaultCellTooltip } from 'components/grids/tooltip/DefaultCellTooltip';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ITagCellRendererParams, TagCellRenderer } from 'components/grids/TagCellRenderer';

export const usePaymentDetailsDefs = () => {
  const { t } = useTranslation();

  const defaultColDef1 = useMemo((): ColDef => {
    return {
      sortable: false,
      resizable: true,
      cellStyle: { textAlign: 'center' },
      tooltipComponent: DefaultCellTooltip,
    };
  }, []);

  const colDefs1 = useMemo((): any[] => {
    return [
      {
        headerName: t('컬럼1코드', 'No.'),
        field: 'column1',
        tooltipField: 'column1',
        cellStyle: { display: 'flex', justifyContent: 'center', width: '80px' },
        width: '80px',
        minWidth: 80,
      },
      {
        headerName: t('컬럼2코드', '상태'),
        field: 'column2',
        tooltipField: 'column2',
        cellStyle: { display: 'flex', justifyContent: 'center', width: '120px' },
        width: '120px',
        minWidth: 120,
        cellRenderer: TagCellRenderer,
        cellRendererParams: (params: ITagCellRendererParams) => {
          return {
            label: '지급완료',
            status: 'completion',
            ...params,
          } as ITagCellRendererParams;
          //   return {
          //     label: '지급예정',
          //     status: 'waiting',
          //     ...params,
          //   } as ITagCellRendererParams;
        },
      },
      {
        headerName: t('컬럼3코드', '지급일'),
        cellStyle: { display: 'flex', justifyContent: 'center', width: '160px' },
        field: 'column3',
        tooltipField: 'column3',
        width: '160px',
        minWidth: 160,
      },
      {
        headerName: t('컬럼4코드', '화폐'),
        field: 'column4',
        tooltipField: 'column4',
        cellStyle: { display: 'flex', justifyContent: 'center', width: '80px' },
        width: '80px',
        minWidth: 80,
      },
      {
        headerName: t('컬럼5코드', '지급금액'),
        field: 'column5',
        tooltipField: 'column5',
        cellStyle: { display: 'flex', justifyContent: 'right', width: '160px' },
        width: '160px',
        minWidth: 160,
      },
      {
        headerName: t('컬럼5코드', '지급은행'),
        field: 'column5',
        tooltipField: 'column5',
        cellStyle: { display: 'flex', justifyContent: 'left' },
        flex: 1,
        minWidth: 240,
      },
      {
        headerName: t('컬럼6코드', '계좌번호'),
        field: 'column6',
        tooltipField: 'column6',
        cellStyle: { display: 'flex', justifyContent: 'center', width: '176px' },
        width: '176px',
        minWidth: 176,
      },
      {
        headerName: t('컬럼7코드', '지급문서'),
        field: 'column8',
        tooltipField: 'column8',
        cellStyle: { display: 'flex', justifyContent: 'center', width: '160px' },
        width: '160px',
        minWidth: 160,
      },
      {
        headerName: t('컬럼8코드', '증빙일'),
        field: 'column8',
        tooltipField: 'column8',
        cellStyle: { display: 'flex', justifyContent: 'center', width: '120px' },
        width: '120px',
        minWidth: 120,
      },
      {
        headerName: t('컬럼9코드', ' '),
        // field: 'column8',
        // tooltipField: 'column8',
        cellStyle: { display: 'flex', justifyContent: 'right', width: '100px' },
        width: '100px',
        minWidth: 100,
      },
      {
        headerName: t('컬럼10코드', '적요'),
        field: 'column8',
        tooltipField: 'column8',
        cellStyle: { display: 'flex', justifyContent: 'left', width: '100px' },
        width: '100px',
        minWidth: 100,
      },
      {
        headerName: t('컬럼11코드', '문서번호'),
        field: 'column8',
        tooltipField: 'column8',
        cellStyle: { display: 'flex', justifyContent: 'center', width: '120px' },
        width: '120px',
        minWidth: 120,
      },
    ];
  }, []);

  const handleAprvDetailBtn = (data) => {
    console.log('화면 연결이 필요합니다.');
  };

  return { defaultColDef1, colDefs1 };
};
