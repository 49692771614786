import SearchMolecule, {
  SearchItemWrap,
  SearchWrap,
  UnHiddenSearchWrap,
} from 'components/molecule/SearchMolecule';

export default function RegistBox() {
  return (
    <SearchWrap style={{ padding: '12px' }}>
      <UnHiddenSearchWrap>
        <SearchItemWrap nonButton={true} style={{ gap: '12px 24px' }}>
          <SearchMolecule
            labelAlign="left"
            labelWidth="100px"
            type="input"
            searchWidth="100%"
            labelTitle="제목"
            isRequired={true}
            placeholder="제목을 입력해주세요."
          />
          <SearchMolecule
            labelAlign="left"
            type="select"
            labelWidth="100px"
            searchWidth="48%"
            labelTitle="법인"
            isRequired={true}
          />
          <SearchMolecule
            avaterSize="medium"
            labelAlign="left"
            type="avatarChip"
            searchWidth="48%"
            labelWidth="100px"
            labelTitle="등록자"
            isRequired={true}
          />
          <SearchMolecule
            labelAlign="left"
            type="chipSelect"
            labelWidth="100px"
            searchWidth="33.6%"
            labelTitle="  보험증권"
          />
          <SearchMolecule
            labelAlign="left"
            type="calendar"
            calendarWidth="100%"
            labelWidth="100px"
            calendarFormat="YYYY"
            searchWidth="24%"
            labelTitle="보험연도"
            calendarMaxWidth="96px"
            calendarMinWidth="96px"
          />
        </SearchItemWrap>
      </UnHiddenSearchWrap>
    </SearchWrap>
  );
}
