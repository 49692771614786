import React, { useEffect, useCallback, useMemo, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import styled from '@emotion/styled';
import { ICellRendererParams } from 'ag-grid-community';
import Chip from '@mui/material/Chip';
import _ from 'lodash';
import ModalContent from 'components/modals/common/ModalContent';

export interface IChipCellRendererParams extends ICellRendererParams {
  isEdit?: (() => boolean) | boolean;
  useModal: any;
  delCallBack?: ((delIdx: number) => void) | undefined;
}

export interface ChipData {
  key: number;
  label: string;
}

export const ChipCellRenderer = ({
  isEdit,
  useModal,
  delCallBack,
  ...params
}: IChipCellRendererParams) => {
  const computedParam = () => {
    const result = params.value.reduce((acc, curr, idx) => {
      acc.push({ key: idx, label: curr });
      return acc;
    }, []);
    return result;
  };

  const [chipData, setChipData] = React.useState<readonly ChipData[]>(computedParam());
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const enableButton = useMemo(() => {
    if (typeof isEdit === 'boolean') {
      return isEdit;
    }
    if (typeof isEdit === 'function') {
      return isEdit();
    }
    return false;
  }, [isEdit, params.data, params.value, params.valueFormatted, params.colDef]);

  const handleDelete = (chipToDelete: ChipData) => () => {
    setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
    delCallBack && delCallBack(chipToDelete.key);
  };

  const renderModal = () => {
    useModal.open = modalOpen;
    useModal.close = () => setModalOpen(false);
    return <ModalContent {...useModal}></ModalContent>;
  };

  useEffect(() => {
    setChipData(computedParam());
  }, [params.value]);

  return (
    <>
      <div>
        {enableButton && (
          <>
            {/* <GInputIconButton color="secondary" variant="outlined" size="small">
            <p
              className="search"
              onClick={(e) => {
                setModalOpen(true);
              }}
            ></p>
          </GInputIconButton> */}
            <SearchIcon
              onClick={(e) => {
                setModalOpen(true);
              }}
              style={{ cursor: 'pointer' }}
            ></SearchIcon>
            {modalOpen && renderModal()}
          </>
        )}

        {chipData.map((data) => {
          return (
            <React.Fragment key={data.key}>
              <Chip
                label={data.label}
                {...(enableButton ? { onDelete: handleDelete(data) } : {})}
                size="small"
                style={{ verticalAlign: 'top', margin: '0px 3px 0px 3px' }}
              />
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
};
