import styled from '@emotion/styled';
import { GlobalTokenColor } from 'ui/theme/Color';

interface StyledComponentProps {
  isBottomMgn?: boolean;
}

export const GbasicTableWrap = styled.div<StyledComponentProps>`
  border-radius: 2px;
  border: 1px solid ${GlobalTokenColor.ESGrey300} !important;
  margin-bottom: ${(props) => (props.isBottomMgn ? '12px' : '0px')};
`;

export const GbasicTable = styled.table`
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  // margin-top: 8px;

  th,
  td {
    height: 37px;
    vertical-align: middle;
    font-size: 13px;

    & > span {
      padding: 0 4px;
    }
  }

  th {
    background-color: ${GlobalTokenColor.StatsuDone100};
    padding: 2px 8px;
    vertical-align: top;
    text-align: left;
    font-weight: 700;
    & > div {
      padding: 7px 0;
    }
  }

  td {
    border-left: none;
    font-weight: 400;
    padding: 2px 8px;
    min-height: 39px;

    > div:has(a, img, span) {
      + button {
        margin-top: 4px;
      }
    }
    button {
      margin-top: 0;
    }
  }

  tr {
    border-bottom: 1px solid ${GlobalTokenColor.ESGrey300};
    &:nth-last-child(1) {
      border-bottom: none;
    }
  }

  &.rowHover tr:hover {
    background: ${GlobalTokenColor.StatsuDone100} !important;
  }
`;

export const GbasicTd = styled.td`
  text-align: right;
`;
