/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import { GButton } from 'components/atom/button';
import GButtonLayout from 'components/molecule/GbuttonLayout';
import ContentAccordion from 'components/organism/ContentAccordion';
import New from 'components/asset/images/Penw.svg';

const FaqList = () => {
  return (
    <>
      <Accordions>
        <ContentAccordion
          title="거래저 번호를 모를때는 어떻게 해야하나요?"
          contents="
            지급 계좌 등록 시스템 거래처 ID로 로그인 하시면 됩니다. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요."
        />
        <ContentAccordion
          title="지급 계좌 등록 시스템 등록 방법"
          contents="
            지급 계좌 등록 시스템 거래처 ID로 로그인 하시면 됩니다. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요."
        />
        <ContentAccordion
          title="거래저 번호를 모를때는 어떻게 해야하나요?"
          contents="
            지급 계좌 등록 시스템 거래처 ID로 로그인 하시면 됩니다. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요."
        />
        <ContentAccordion
          title="지급 계좌 등록 시스템 등록 방법"
          contents="
            지급 계좌 등록 시스템 거래처 ID로 로그인 하시면 됩니다. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요."
        />
        <ContentAccordion
          title="지급 계좌 등록 시스템 등록 방법"
          contents="
            지급 계좌 등록 시스템 거래처 ID로 로그인 하시면 됩니다. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요."
        />
        <ContentAccordion
          title="지급 계좌 등록 시스템 등록 방법"
          contents="
            지급 계좌 등록 시스템 거래처 ID로 로그인 하시면 됩니다. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요.
            텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요. 텍스트는 여기에 적어주세요."
        />
      </Accordions>
      <GButtonLayout marginBottom="8px">
        <GButton
          chkImg={New}
          txt="신규"
          sizes="large"
          btnstyled="contained"
          btnColor="primary"
          hasImg={true}
          hasTxt={true}
        ></GButton>
      </GButtonLayout>
    </>
  );
};

const Accordions = styled.div`
  margin-bottom: 4px;
  border-bottom: 1px solid #dde0df;
`;

export default FaqList;
