import React, { useEffect, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';

import { GLabelAtom } from 'components/atom/label';
import { GbasicTable, GbasicTableWrap } from 'components/tables/GTable';
import { GButton } from 'components/atom/button';
import edit from 'components/asset/images/Pen.svg';
import { GButtonBox } from 'components/layouts/GLayoutBox';
import SubTitleBox from 'components/molecule/SubTitleBox';
import GButtonLayout from 'components/molecule/GbuttonLayout';
import { useLoading } from 'components/process/Loading';
import { useMessageBar } from 'components/process/MessageBar';
import { SubTitleBtnArea } from 'components/organism/SubTitleBtnArea';
import GTabs from 'components/organism/GTabs';
import { AlignBox, AlignItem } from 'components/organism/AlignBox';
import { GContentGrid } from 'components/layouts/GContentGrid';
import confirm from 'components/asset/images/Confirm.svg';
import EmptyState from 'components/atom/emptyState';
import { personalNumber, phoneNumber } from 'components/vars/common/FormatNumber';

import DmsRegKrwAcntModal from './modal/DmsRegKrwAcntModal';
import DmsRegFrgnAcntModal from './modal/DmsRegFrgnAcntModal';

import { useCommonModal } from 'hooks/useCommonModal';
import { useCommonCert } from 'hooks/useCommonCert';
import { useColumnDefsForFrgnlList } from './hooks/OverseasColumnDefs';
import useMultiAccntPrint from './hooks/useMultiAccntPrint';
import { usePrintStore } from './hooks/usePrintStore';
import { useColumnDetailDefs } from './hooks/DomesticColumnDefs';
import { useColumnHistoryDefs } from './hooks/DomesticHistoryColumnDefs';
import { getAdminManagerListApi, getUserManagerListApi } from 'apis/common/ManagerSearchApi';

import { requestRegistForeignAccount } from 'apis/vars/account/ForeignAccount';
import {
  getDomesticAccounts,
  requestRegistDomesticAccount,
} from 'apis/vars/account/DomesticAccount';

//계좌정보등록이력
import { getDmstKrwAccnHistory, getOvKrwAccnHistory } from 'apis/vars/account/AccountHistoryApi';

import { VendorReqInfoVO } from 'models/vars/vendor/VendorReqInfoVO';
import { getVendorStatus } from 'apis/vars/account/CommonAccount';

interface PropsType {
  vendorInfo: VendorReqInfoVO;
  radioRender: () => void;
}

const DomesticRegistPage = (props: PropsType) => {
  const [vendorInfo, setVendorInfo] = useState<VendorReqInfoVO>({
    ...props.vendorInfo,
  } as VendorReqInfoVO);

  const [dmsRegKrwAcntModalOpen, setDmsRegKrwAcntModalOpen] = useState<boolean>(false);
  const [dmsRegFrgnAcntModalOpen, setDmsRegFrgnAcntModalOpen] = useState<boolean>(false);

  const { callCertModule } = useCommonCert();

  const [stdAccnCds, setStdAccnCds] = useState<any>({
    stdAccnTpCd: false,
    stdAccnRegDivsCd: '1',
    stdDftAccnYn: 'Y',
    stdRegDivCd: 'FG', //등록시 구분자 복수계좌만 : MM, 어음,복수 : GM, 현금,복수: FF, 전부 : FG
  }); // true 이면 현금만

  const { t, i18n } = useTranslation();
  const t2 = t as (msgId: string, defVal: string) => string;

  const [domAccounts, setDomAccounts] = useState<any[]>([]);
  const [domAccountsHistory, setDomAccountHistory] = useState<any[]>([]); //계좌정보등록이력
  const [domDftAccount, setDomDftAccount] = useState<any>();

  const [isAccnExist, setIsAccnExist] = React.useState('N'); // 디폴트 값이 있다면 1, 없다면 0 (0일때는 )

  const { handleClickMultiAcnt } = useMultiAccntPrint();

  const [vendorStatus, setVendorStatus] = useState<string>('-');

  /**
   * 계좌 목록 조회
   */
  const getDomAccns = async () => {
    openLoading(true);
    getDomesticAccounts(vendorInfo.vdcpCd)
      .then((resp) => {
        if (resp) {
          setDomAccounts(resp);
          if (resp.length > 0 && vendorInfo.dmstFrgDivsCd != 'DO') {
            setIsAccnExist('Y');
          }
        }
      })
      .finally(() => {
        openLoading(false);
      });
  };

  const gridApiRef = useRef<any>(null);

  const { defaultColDef, colDefs } = useColumnDetailDefs({
    callback: getDomAccns,
    vendorInfo: vendorInfo,
  });

  const { defaultFrgnColDef, colFrgnDefs } = useColumnDefsForFrgnlList({
    callback: getDomAccns,
    vendorInfo: vendorInfo,
    isKrw: true,
  });

  const { defaultColDef1, colDefs1 } = useColumnHistoryDefs();
  const { openLoading } = useLoading();
  const { openMessageBar } = useMessageBar();
  const { openCommonModal } = useCommonModal();

  useEffect(() => {
    (() => {
      if (vendorInfo.vdcpCd) {
        getDomAccns();
      }
      if (vendorInfo.vdcpCtryCd == 'KR' && vendorInfo.bzno) {
        // 사업자등록상태 조회
        getVendorStatus(vendorInfo.bzno).then((resp) => {
          if (resp) {
            if (resp.bsttCd == '01') {
              // resp.bsttCd
              // 01 : 계속사업자
              // 02 : 휴업자
              // 03 : 폐업자
              setVendorStatus('정상');
            } else {
              setVendorStatus(resp.bstt);
            }
          }
        });
      }
    })();
  }, [vendorInfo]);

  const handleClickPif = () => {
    openCommonModal({
      modalType: 'alert',
      content: t('개발중입니다.', '__개발중입니다'),
    });
  };

  /**
   * 국내 원화만 사용
   * @returns
   */
  const setStdParams = () => {
    let txt = '';

    // 기존계좌 존재
    //1. 현금이면 어음 or복수계좌
    //2. 어음이면 현금 or 복수계좌
    //3. 현금/어음 이면 복수계좌

    const fFlag = domAccounts.some((o) => o.accnTpCd == 'F'); //현금계좌 존재
    const gFlag = domAccounts.some((o) => o.accnTpCd == 'G'); //어음계좌 존재
    const dftDom = domAccounts.filter((o) => o.addAtrValCtn01 == 'F-10'); //기본계좌 존재
    const pulAccnRegYnFlag = vendorInfo.pulAccnRegYn == 'Y'; //복수계좌 등록 가능 벤더

    if (fFlag && gFlag) {
      // 현금 & 어음 존재
      if (pulAccnRegYnFlag) {
        txt = t('com.label.00672', '__복수계좌만 등록가능');
        setStdAccnCds({
          stdAccnTpCd: true,
          stdAccnRegDivsCd: '2',
          stdDftAccnYn: 'N',
          stdRegDivCd: 'MM',
        });
        setDomDftAccount((prev: any) => {
          return { ...prev, ...dftDom[0] };
        });
      } else {
        txt = t('com.label.00601', '__추가적인 계좌를 등록할 수 없습니다.');
      }
    } else {
      if (fFlag) {
        // 현금만 존재
        if (pulAccnRegYnFlag) {
          txt = t('com.label.00673', '__어음 또는 복수계좌 등록가능');
          setStdAccnCds({
            stdAccnTpCd: false,
            stdAccnRegDivsCd: '2',
            stdDftAccnYn: 'N',
            stdRegDivCd: 'GM',
          });
          setDomDftAccount((prev: any) => {
            return { ...prev, ...dftDom[0] };
          });
        } else {
          txt = t('com.label.00599', '__어음 계좌만 등록가능');
          setStdAccnCds({
            stdAccnTpCd: true,
            stdAccnRegDivsCd: '1',
            stdDftAccnYn: 'N',
            stdRegDivCd: 'GG',
          });
          setDomDftAccount((prev: any) => {
            return { ...prev, ...dftDom[0] };
          });
        }
      }
      if (gFlag) {
        // 어음만 존재 (어음은 1건만 존재하므로 무조건 현금만 가능)
        txt = t('com.msg.regCashAddAccn', '__현금계좌 등록가능');
        setStdAccnCds({
          stdAccnTpCd: true,
          stdAccnRegDivsCd: '1',
          stdDftAccnYn: 'Y',
          stdRegDivCd: 'FF',
        });
        // }
      }
    }

    if (!txt) {
      console.error('error');
    }

    return txt;
  };

  const checkRegistAvailable = () => {
    const fFlag = domAccounts.some((o) => o.accnTpCd == 'F');
    const gFlag = domAccounts.some((o) => o.accnTpCd == 'G');
    const pulAccnRegYnFlag = vendorInfo.pulAccnRegYn == 'Y';
    if (fFlag && gFlag) {
      // 현금, 어음 존재
      if (!pulAccnRegYnFlag) {
        return false;
      }
    }
    return true;
  };

  /**
   * 신규 버튼 클릭시
   */
  const handleNewAccntRegBtnClick = () => {
    if (vendorInfo.dmstFrgDivsCd == 'DO') {
      //기존계좌 등록여부
      if (domAccounts.length > 0) {
        if (checkRegistAvailable()) {
          openCommonModal({
            modalType: 'confirm',
            content: setStdParams(),
            yesCallback: () => {
              setDmsRegKrwAcntModalOpen(true);
            },
            noCallback: () => {
              return;
            },
          });
        } else {
          openCommonModal({
            modalType: 'alert',
            content: t2('com.label.00598', '추가로 계좌를 등록할 수 없습니다'),
          });
          return;
        }
      } else {
        setDmsRegKrwAcntModalOpen(true);
      }
    } else {
      //해외 원화 계좌 등록시 단 한건만 등록가능 >> 삭제
      // if (domAccounts.length > 0) {
      //   openCommonModal({
      //     modalType: 'alert',
      //     content: '추가로 계좌를 등록할 수 없습니다',
      //   });
      //   return;
      // } else {
      //   setDmsRegFrgnAcntModalOpen(true);
      // }
      setDmsRegFrgnAcntModalOpen(true);
    }
  };
  const handleValidManager = async () => {
    const soprMgrEmal = vendorInfo.soprMgrEmal; // 이메일 속성에 접근
    const response = await getUserManagerListApi(soprMgrEmal); // API 호출

    if (response?.length > 0) {
      // 유효한 매니저가 있으므로 등록 요청 로직 실행
      handleRegReqBtnClick(); // 등록 요청 함수 호출
    } else {
      openCommonModal({
        modalType: 'alert',
        content: t(
          'com.label.01022',
          '__LG에너지솔루션 담당자가 퇴사자로 되어 있어 계좌등록요청이 불가합니다. 마이페이지에서 변경 후 계좌등록요청 진행 부탁드립니다'
        ),
      });
      return false; // 등록 요청 중단
    }
  };
  /**
   * 등록요청
   * 현금, 어음 각 1건씩만 진행중 상태일수 있음. 0309
   * 복수 등록요청시 dftAccnYn이 "Y"가 없거나 "Y"인데 상태가 "C"가 아니면 요청못하게. 0309
   */
  const handleRegReqBtnClick = () => {
    const isCashAccnFlag = domAccounts.some((o) => o.accnRegProgStatCd == 'P' && o.accnTpCd == 'F');
    const isBillAccnFlag = domAccounts.some((o) => o.accnRegProgStatCd == 'P' && o.accnTpCd == 'G');

    if (isCashAccnFlag && isBillAccnFlag) {
      openCommonModal({
        modalType: 'alert',
        content: t('com.label.00465', '__결재 진행중인 계좌가 있습니다.'),
      });
      return;
    }

    //계좌등록정보 유무
    if (gridApiRef.current.getSelectedRows().length > 0) {
      // 선택정보 확인
      const stcd = gridApiRef.current.getSelectedRows()[0].accnRegProgStatCd;
      let resultTxt;
      if (stcd != 'T') {
        switch (stcd) {
          case 'C':
            resultTxt = t('com.label.00660', '__해당 계좌는 이미 등록 완료된 계좌입니다.');
            break;
          case 'P':
            resultTxt = t('com.label.00677', '__등록 진행중인 계좌입니다.');
            break;
          case 'R':
            resultTxt = t('com.label.00678', '__수정 후 요청이 가능합니다');
            break;
        }

        // 임시저장 상태만 요청 가능
        openCommonModal({
          modalType: 'alert',
          content: resultTxt,
        });
        return;
      }

      // 복수계좌 신청시
      if (gridApiRef.current.getSelectedRows()[0].accnRegDivsCd == '2') {
        //dftAccnYn이 "Y"가 없거나 "Y"인데 상태가 "C"가 아니면 요청못하게. 0309
        if (!domAccounts.some((o) => o.dftAccnYn == 'Y' && o.accnRegProgStatCd == 'C')) {
          openCommonModal({
            modalType: 'alert',
            content: t('com.msg.noReqAccn', '__이 계좌는 등록요청할 수 없습니다'),
          });
          return;
        }

        // 복수계좌 동시 등록 안됨
        const isMultiAccnProc = domAccounts.some(
          (o) => o.accnRegDivsCd == '2' && o.accnRegProgStatCd == 'P'
        );

        if (isMultiAccnProc) {
          openCommonModal({
            modalType: 'alert',
            content: t('com.label.00679', '__복수계좌는 한건의 요청만 가능합니다'),
          });
          return;
        }
      }

      if (vendorInfo.dmstFrgDivsCd == 'DO') {
        if (vendorInfo.vdcpCtryCd.toUpperCase().startsWith('KR')) {
          callCertModule({
            userId: vendorInfo.vdcpCd,
            bznoKey: vendorInfo.bznoKey,
            callbackFn: (resultObject) => {
              gridApiRef.current.getSelectedRows()[0].dgsVal = resultObject.signedData;
              openLoading(true);
              requestRegistDomesticAccount(gridApiRef.current.getSelectedRows()[0])
                .then((resp) => {
                  if (resp.data.result == 'S') {
                    openMessageBar('success', t('com.msg.save', '__저장되었습니다.'));
                    getDomAccns();
                    openLoading(false);
                  } else {
                    openLoading(false);
                    openCommonModal({
                      modalType: 'alert',
                      content: t('com.label.00466', '__등록요청에 실패했습니다.'),
                    });
                    return;
                  }
                })
                .catch((e) => {
                  openLoading(false);
                });
            },
          });
        } else {
          openLoading(true);
          requestRegistDomesticAccount(gridApiRef.current.getSelectedRows()[0])
            .then((resp) => {
              if (resp.data.result == 'S') {
                openMessageBar('success', t('com.msg.save', '__저장되었습니다.'));
                getDomAccns();
                openLoading(false);
              } else {
                openLoading(false);
                openCommonModal({
                  modalType: 'alert',
                  content: t('com.label.00466', '__등록요청에 실패했습니다.'),
                });
                return;
              }
            })
            .catch((e) => {
              openLoading(false);
            });
        }
      } else {
        if (vendorInfo.vdcpCtryCd.toUpperCase().startsWith('KR')) {
          callCertModule({
            userId: vendorInfo.vdcpCd,
            bznoKey: vendorInfo.bznoKey,
            callbackFn: (resultObject) => {
              gridApiRef.current.getSelectedRows()[0].dgsVal = resultObject.signedData;
              openLoading(true);
              requestRegistForeignAccount(gridApiRef.current.getSelectedRows()[0])
                .then((resp) => {
                  if (resp.data.result == 'S') {
                    openMessageBar('success', t('com.msg.save', '__저장되었습니다.'));
                    getDomAccns();
                    openLoading(false);
                  } else {
                    openLoading(false);
                    openCommonModal({
                      modalType: 'alert',
                      content: t('com.label.00466', '__등록요청에 실패했습니다.'),
                    });
                    return;
                  }
                })
                .catch((e) => {
                  openLoading(false);
                });
            },
          });
        } else {
          openLoading(true);
          requestRegistForeignAccount(gridApiRef.current.getSelectedRows()[0])
            .then((resp) => {
              if (resp.data.result == 'S') {
                openMessageBar('success', t('com.msg.save', '__저장되었습니다.'));
                getDomAccns();
                openLoading(false);
              } else {
                openLoading(false);
                openCommonModal({
                  modalType: 'alert',
                  content: t('com.label.00466', '__등록요청에 실패했습니다.'),
                });
                return;
              }
            })
            .catch((e) => {
              openLoading(false);
            });
        }
      }
    } else {
      openCommonModal({
        modalType: 'alert',
        content: t('com.msg.choRegReqAccn', '__등록 요청할 계좌를 선택하세요'),
      });
    }
  };

  // 복수계좌 신고서 출력 버튼
  const [isBtnVisible, setIsBtnVisible] = useState<boolean>(false);
  const { setAccnId } = usePrintStore();

  useEffect(() => {
    if (gridApiRef.current) {
      gridApiRef.current.addEventListener('selectionChanged', onSelectionChanged);
    }
  }, [gridApiRef.current]);

  const onGridReady = (params: any) => {
    gridApiRef.current = params.api;
    if (gridApiRef.current) {
      gridApiRef.current.addEventListener('selectionChanged', onSelectionChanged);
    }
  };

  // 그리드에서 선택된 값이 '복수계좌'이고 진행상태가 진행중, 반려, 임시저장일 경우에
  const onSelectionChanged = () => {
    const selectedRows = gridApiRef.current.getSelectedRows();
    const selectedAccnId = String(selectedRows[0]?.accnRegReqId);
    setIsBtnVisible(
      selectedRows.length > 0 &&
        selectedRows[0].accnRegDivsCd === '2' &&
        (selectedRows[0].accnRegProgStatCd === 'P' ||
          selectedRows[0].accnRegProgStatCd === 'R' ||
          selectedRows[0].accnRegProgStatCd === 'T') &&
        vendorInfo.soprMgrCopCd == 'C100'
    );
    setAccnId(selectedAccnId);
  };

  // 계좌정보등록이력
  const getAccnHist = async () => {
    openLoading(true);
    if (vendorInfo.dmstFrgDivsCd === 'DO') {
      getDmstKrwAccnHistory()
        .then((resp) => {
          if (resp) {
            setDomAccountHistory(resp);
          }
        })
        .finally(() => {
          openLoading(false);
        });
    } else {
      getOvKrwAccnHistory()
        .then((resp) => {
          if (resp) {
            setDomAccountHistory(resp);
          }
        })
        .finally(() => {
          openLoading(false);
        });
    }
  };

  // 국내 원화 계좌 등록화면 엔솔 담당자와 담당자 이메일 (퇴사자 표시를 위함)
  const [managerEmail, setManagerEmail] = useState(''); // 이메일 상태 저장
  const [managerName, setManagerName] = useState(''); // 담당자 이름 상태 저장
  const [isLoading, setIsLoading] = useState(true); // 로딩 상태 관리

  // API 호출 함수
  const fetchManagerEmail = async (email) => {
    try {
      const response = await getUserManagerListApi(email); // API 호출
      if (response?.length > 0) {
        setManagerEmail(email); // 이메일이 있으면 상태에 저장
        setManagerName(vendorInfo.soprMgrNm ?? (t('com.label.00807') || '')); // 담당자 이름 저장
      } else {
        setManagerEmail(t('com.label.00807') || ''); // 없으면 '퇴사자'로 상태 설정
        setManagerName(t('com.label.00807') || ''); // 담당자도 '퇴사자'로 설정
      }
    } catch (error) {
      console.error('API 호출 에러:', error);
      setManagerEmail(t('com.label.00807') || ''); // 오류 발생 시 '퇴사자'로 설정
      setManagerName(t('com.label.00807') || ''); // 담당자도 '퇴사자'로 설정
    } finally {
      setIsLoading(false); // 로딩 완료
    }
  };

  useEffect(() => {
    if (vendorInfo.soprMgrEmal) {
      fetchManagerEmail(vendorInfo.soprMgrEmal); // 이메일로 API 호출
    } else {
      setManagerEmail(t('com.label.00807') || ''); // 이메일 정보가 없으면 '퇴사자'로 표시
      setManagerName(t('com.label.00807') || ''); // 이메일 정보가 없으면 '퇴사자'로 표시
      setIsLoading(false);
    }
  }, [vendorInfo.soprMgrEmal]);

  // 언어 변경 시 컴포넌트 재렌더링
  useEffect(() => {
    const handleLanguageChange = () => {
      setManagerEmail(t('com.label.00807') || ''); // 언어 변경 시 이메일 재설정
      setManagerName(t('com.label.00807') || ''); // 언어 변경 시 이름 재설정
    };

    i18n.on('languageChanged', handleLanguageChange); // 언어 변경 이벤트 리스너 추가

    return () => {
      i18n.off('languageChanged', handleLanguageChange); // 언어 변경 이벤트 리스너 제거
    };
  }, [i18n, t]);

  return (
    <>
      {props.radioRender()}
      <SubTitleBtnArea>
        <SubTitleBox showSubTitle={true} content={t('com.label.00467', '__거래처 기본 정보')} />
        {isBtnVisible && (
          <GButton
            txt={t('com.label.00468', '__복수계좌 신고서 출력')}
            sizes="small"
            btnstyled="outline"
            btnColor="normal"
            hasImg={false}
            hasTxt={true}
            onClick={handleClickMultiAcnt}
          ></GButton>
        )}
      </SubTitleBtnArea>
      <GbasicTableWrap isBottomMgn={true}>
        <GbasicTable>
          <colgroup>
            <col style={{ width: '160px' }} />
            <col style={{ width: 'auto' }} />
            <col style={{ width: '160px' }} />
            <col style={{ width: 'auto' }} />
          </colgroup>
          <tbody>
            <tr>
              <th>
                <GLabelAtom align="left">{t('com.label.00080', '상호')}</GLabelAtom>
              </th>
              <td>{props.vendorInfo?.vdcpErpNm}</td>
              <th>
                <GLabelAtom align="left">{t('com.label.bizRegNum', '사업자번호')}</GLabelAtom>
              </th>
              <td>{vendorInfo.bzno}</td>
            </tr>
            <tr>
              <th>
                <GLabelAtom align="left">{t('com.label.00081', '대표자명')}</GLabelAtom>
              </th>
              <td>{vendorInfo.rpsnNm}</td>
              <th>
                <GLabelAtom align="left">{t('com.label.idNum', '주민번호')}</GLabelAtom>
              </th>
              <td>{personalNumber(vendorInfo.encRgno)}</td>
            </tr>
            <tr>
              <th>
                <GLabelAtom align="left">{t('com.label.00082', '법인번호')}</GLabelAtom>
              </th>
              <td>{vendorInfo.copRegNo}</td>
              <th>
                <GLabelAtom align="left">{t('com.label.00086', '거래처코드')}</GLabelAtom>
              </th>
              <td>{vendorInfo.vdcpCd}</td>
            </tr>
            <tr>
              <th>
                <GLabelAtom align="left">{t('com.label.00083', '__주소')}</GLabelAtom>
              </th>
              <td colSpan={3}>
                <AlignBox>
                  {vendorInfo.ort01}
                  {/* <AlignItem>{vendorInfo.stras}</AlignItem> */}
                </AlignBox>
              </td>
            </tr>
            <tr>
              <th>
                <GLabelAtom align="left">{t('com.label.00090', '이메일')}</GLabelAtom>
              </th>
              <td>{vendorInfo.vdcpMgrEmal}</td>
            </tr>
            <tr>
              <th>
                <GLabelAtom align="left">{t('com.label.00095', '담당자')}</GLabelAtom>
              </th>
              <td>{vendorInfo.vdcpMgrNm}</td>
              {vendorInfo.bzno && vendorInfo.vdcpCtryCd == 'KR' && (
                <>
                  <th>
                    <GLabelAtom align="left">{t('com.label.00087', '사업자등록상태')}</GLabelAtom>
                  </th>
                  <td>{vendorStatus}</td>
                </>
              )}
            </tr>
            <tr>
              <th>
                <GLabelAtom align="left">{t('com.label.00091', '__전화번호')}</GLabelAtom>
              </th>
              <td>{vendorInfo.vdcpMgrPhn}</td>
              <th>
                <GLabelAtom align="left">{t('com.label.00088', '__국가키')}</GLabelAtom>
              </th>
              <td>
                <AlignBox>{vendorInfo.land1}</AlignBox>
              </td>
            </tr>
          </tbody>
        </GbasicTable>
      </GbasicTableWrap>
      <SubTitleBtnArea>
        <SubTitleBox
          content={t('com.label.00015', '__LG에너지솔루션 담당자')}
          showSubTitle={true}
          isVisibleComment={false}
          isVisibleInfo={false}
        />
      </SubTitleBtnArea>
      <GbasicTableWrap isBottomMgn={true}>
        <GbasicTable>
          <colgroup>
            <col style={{ width: '160px' }} />
            <col style={{ width: 'auto' }} />
            <col style={{ width: '160px' }} />
            <col style={{ width: 'auto' }} />
          </colgroup>
          <tbody>
            <tr>
              <th>
                <GLabelAtom align="left">{t('com.label.00095', '__담당자')}</GLabelAtom>
              </th>
              {/* <td>{vendorInfo.soprMgrNm}</td> */}
              {/* 담당자 이름 조건부 렌더링 */}
              <td>{isLoading ? '로딩 중...' : managerName}</td>
              <th>
                <GLabelAtom align="left">{t('com.label.00090', '__이메일')}</GLabelAtom>
              </th>
              <td>
                {' '}
                {
                  isLoading
                    ? '로딩 중...' // API 응답이 오기 전에는 로딩 표시
                    : managerEmail // 이메일이 있으면 표시, 없으면 '퇴사자' 표시
                }
              </td>
            </tr>
          </tbody>
        </GbasicTable>
      </GbasicTableWrap>
      <GTabs
        flex={true}
        defaultValue={1}
        title={[t('com.label.00097', '__계좌정보'), t('com.label.00658', '__계좌정보등록이력')]}
        onChange={getAccnHist}
      >
        <>
          <GContentGrid
            type="radio"
            isSortable={false}
            className="ag-theme-alpine"
            style={{ flex: 1 }}
          >
            <AgGridReact
              defaultColDef={vendorInfo.dmstFrgDivsCd == 'DO' ? defaultColDef : defaultFrgnColDef}
              columnDefs={vendorInfo.dmstFrgDivsCd == 'DO' ? colDefs : colFrgnDefs}
              rowData={domAccounts}
              rowSelection={'single'}
              enableCellTextSelection
              // onGridReady={(params) => (gridApiRef.current = params.api)}
              onGridReady={onGridReady}
              tooltipShowDelay={0}
              noRowsOverlayComponent={EmptyState}
              noRowsOverlayComponentParams={{ type: 'grid' }}
            ></AgGridReact>
          </GContentGrid>
          <GButtonLayout marginBottom="8px">
            <GButtonBox>
              <GButton
                txt={t2('com.label.new', '__신규')}
                sizes="medium"
                btnstyled="outline"
                btnColor="normal"
                chkImg={edit}
                hasImg={true}
                hasTxt={true}
                onClick={handleNewAccntRegBtnClick}
              />
              <GButton
                txt={t2('com.label.00371', '__등록요청')}
                chkImg={confirm}
                sizes="medium"
                btnstyled="contained"
                btnColor="primary"
                hasImg={true}
                hasTxt={true}
                onClick={handleValidManager}
              ></GButton>
            </GButtonBox>
          </GButtonLayout>
        </>
        <>
          <GContentGrid isSortable={false} className="ag-theme-alpine" style={{ flex: 1 }}>
            <AgGridReact
              defaultColDef={defaultColDef1}
              columnDefs={colDefs1}
              rowData={domAccountsHistory}
              rowSelection={'multiple'}
              enableCellTextSelection
              onGridReady={(params) => (gridApiRef.current = params.api)}
              tooltipShowDelay={0}
              noRowsOverlayComponent={EmptyState}
              noRowsOverlayComponentParams={{ type: 'grid' }}
            ></AgGridReact>
          </GContentGrid>
          <div style={{ marginBottom: '8px' }} />
        </>
      </GTabs>
      {dmsRegKrwAcntModalOpen && (
        <DmsRegKrwAcntModal
          vendorInfo={vendorInfo}
          open={dmsRegKrwAcntModalOpen}
          stdAccnCds={stdAccnCds}
          domDftAccount={domDftAccount}
          accounts={domAccounts}
          close={(param?: boolean) => {
            setDmsRegKrwAcntModalOpen(false);
            if (param) {
              getDomAccns();
            }
          }}
          save={() => console.log()}
        />
      )}

      {dmsRegFrgnAcntModalOpen && (
        <DmsRegFrgnAcntModal
          vendorInfo={vendorInfo}
          open={dmsRegFrgnAcntModalOpen}
          frgnDftAccount={domDftAccount}
          isAccnExist={isAccnExist}
          close={(param?: boolean) => {
            setDmsRegFrgnAcntModalOpen(false);
            if (param) {
              getDomAccns();
            }
          }}
          isKrw={true}
          save={() => console.log()}
          accounts={domAccounts}
        />
      )}
    </>
  );
};

export default DomesticRegistPage;
