import styled from '@emotion/styled';
import { GIconButton } from 'components/buttons/GIconButton';
import { GInputIconButton } from 'components/buttons/GInputIconButton';
import React, { InputHTMLAttributes } from 'react';
import { GInput } from './GInput';

// --------------------------------------------------------------------------------------------
export const MsgArea = styled.div`
  & {
    display: block;
    font-size: 12px;
    // padding: 2px 0;
    margin-top: 4px;
  }

  &.normal {
    color: #1f1f1f;
  }
  &.confirm {
    color: #00806a;
  }
  &.warning {
    color: #ff9322;
  }
  &.error {
    color: #f94b50;
  }
`;

type MsgProps = {
  status: 'normal' | 'confirm' | 'warning' | 'error' | undefined;
  children: string | undefined;
};

export const InfoMsg: React.FC<MsgProps> = ({ status, children }) => {
  let className = 'infoMsg';

  switch (status) {
    case 'normal':
      className += ' normal';
      break;
    case 'confirm':
      className += ' confirm';
      break;
    case 'warning':
      className += ' warning';
      break;
    case 'error':
      className += ' error';
      break;
  }

  return <MsgArea className={className}>{children}</MsgArea>;
};

// ---------------------------------------------------------------------------------------
export const GInputBox = styled.div`
  & {
    display: inline-block;
    position: relative;
  }

  &.inputBox input.inputArea {
    border: 1px solid #b9bcbb;
    border-radius: 2px;
    height: 32px;
    background: ${({ theme }) => (theme.palette.mode == 'dark' ? '#000000' : '#ffffff')};
    color: #1f1f1f;
    padding: 0 8px;
    width: 100%;
  }

  &.inputBox input.inputArea:hover,
  &.inputBox input.inputArea:focus,
  &.inputBox input.inputArea:active {
    border: 1px solid #5b5c5b;
    outline: 0;
  }

  &.inputBox input.inputArea:disabled {
    background: #f1f4f3 !important;
    color: #979998;
  }

  &.inputBox input.inputArea:read-only {
    background: #f1f4f3 !important;
    background-color: red;
  }

  &.inputBox input.inputArea:disabled {
    background: #f1f4f3;
  }

  &.inputBox .stat {
    display: inline-block;
    position: absolute;
    right: 4px;
    top: 6px;
    width: 20px;
    height: 20px;
  }

  // normal ---------------------------------------------
  &.inputBox.normal input.inputArea {
    border: 1px solid #b9bcbb;
    background: ${({ theme }) => (theme.palette.mode == 'dark' ? '#000000' : '#ffffff')};
    color: ${({ theme }) => (theme.palette.mode == 'dark' ? '#ffffff' : '#000000')};
  }
  &.inputBox.normal .stat {
    display: none;
  }

  // confirnm ---------------------------------------------
  &.inputBox.confirm input.inputArea {
    border: 1px solid #56d8aa;
    background: ${({ theme }) => (theme.palette.mode == 'dark' ? '#000000' : '#ffffff')};
    padding-right: 24px;
  }
  &.inputBox.confirm .stat {
    background-position: 0px 0px;
  }

  // warning ---------------------------------------------
  &.inputBox.warning input.inputArea {
    border: 1px solid #ffcb7a;
    background: #ffffff;
    padding-right: 24px;
  }
  &.inputBox.warning .stat {
    background-position: 0px -20px;
  }

  // error ---------------------------------------------
  &.inputBox.error input.inputArea {
    border: 1px solid #fda293;
    background: #ffffff;
    padding-right: 24px;
  }
  &.inputBox.error .stat {
    background-position: 0px -40px;
  }

  // typeSearch ---------------------------------------------
  &.inputBox.typeSearch input.inputArea {
    padding-left: 28px;
    padding-right: 24px;
  }
  &.inputBox.typeSearch .searchBtn {
    position: absolute;
    left: 0px;
    top: 4px;
  }

  &.inputBox.typeSearch .delBtn {
    position: absolute;
    left: auto;
    right: 2px;
    top: 4px;
  }

  // typeNormal ---------------------------------------------
  &.inputBox.typeNormal .searchBtn {
    display: none;
  }

  &.inputBox.typeNormal .delBtn {
    display: none;
  }
`;

export interface InputBoxProps extends InputHTMLAttributes<HTMLInputElement> {
  status?: 'normal' | 'confirm' | 'warning' | 'error';
  align: 'left' | 'right' | 'center' | undefined;
  type: 'normal' | 'search' | undefined;
  disabled?: boolean | undefined;
  readonly?: boolean | undefined;
  infoMsg?: string | undefined;
  children?: string | undefined;
}

export const InputBox: React.FC<InputBoxProps> = ({
  status,
  align,
  type,
  infoMsg,
  children,
  disabled,
  readonly,
  style,
  ...props
}) => {
  let className = 'inputBox';

  switch (status) {
    case 'normal':
      className += ' normal';
      break;
    case 'confirm':
      className += ' confirm';
      break;
    case 'warning':
      className += ' warning';
      break;
    case 'error':
      className += ' error';
      break;
  }

  switch (align) {
    case 'left':
      className += ' left';
      break;
    case 'center':
      className += ' center';
      break;
    case 'right':
      className += ' right';
      break;
  }

  switch (type) {
    case 'normal':
      className += ' typeNormal';
      break;
    case 'search':
      className += ' typeSearch';
      break;
  }

  return (
    <GInputBox className={className} style={style}>
      <input className="inputArea" readOnly={readonly} disabled={disabled} {...props}></input>
      <div className="searchBtn">
        <GInputIconButton color="secondary" variant="outlined" size="small">
          <p className="search"></p>
        </GInputIconButton>
      </div>
      <span className="stat"></span>
      <div className="delBtn">
        <GInputIconButton color="secondary" variant="outlined" size="small">
          <p className="misuse"></p>
        </GInputIconButton>
      </div>
    </GInputBox>
  );
};
