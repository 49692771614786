import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import {
  DetailReq,
  KFrgnCurDetail,
  KFrgnCurRes,
  KNonPaymentRes,
  KPaymentDetail,
  KPaymentRes,
  PaymentSearchParams,
} from 'pages/vars/payment/KPaymentSchedulePage';
import { callJwtApi } from 'utils/JwtApiUtil';
import {
  NotePayable,
  NotePayableDetail,
  ODetailReq,
  OPaymentDetail,
  OPaymentRes,
  PaymentDue,
} from 'pages/vars/payment/OPaymentSchedulePage';
import { callApi } from 'utils/ApiUtil';
import { CommonCode } from 'models/admin/CommonCode';

// 지급내역조회(ADMIN)
export const getKPaymentListAdApi = async (params: PaymentSearchParams) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/payments`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({ ...params }),
  };
  const response: CommonResponse<KPaymentRes[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : []) as KPaymentRes[];
};

// 지급내역조회 상세(ADMIN)
export const getKPaymentDetailAdApi = async (detailReq: DetailReq) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/payments/details`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({ ...detailReq }),
  };
  const response: CommonResponse<KPaymentDetail[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : []) as KPaymentDetail[];
};

// 미지급 조회(ADMIN)
export const getKNonPaymentAdApi = async (params: PaymentSearchParams) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/payments/non-payments`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({ ...params }),
  };
  const response: CommonResponse<KNonPaymentRes[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : []) as KNonPaymentRes[];
};

// 외화지급내역 조회(ADMIN)
export const getFrgnCurAdApi = async (params: PaymentSearchParams) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/payments/foreign-payments`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({ ...params }),
  };
  const response: CommonResponse<KFrgnCurRes[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : []) as KFrgnCurRes[];
};

// 외화지급내역 상세(ADMIN)
export const getFrgnCurDetailAdApi = async (detailReq: DetailReq) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/payments/foreign-details`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({ ...detailReq }),
  };
  const response: CommonResponse<KFrgnCurDetail[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : []) as KFrgnCurDetail[];
};

// ---------------------------------------해외----------
// PaymentList(ADMIN)
export const getOPaymentListAdApi = async (params: PaymentSearchParams) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/payments/overseas`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({ ...params }),
  };
  const response: CommonResponse<OPaymentRes[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : []) as OPaymentRes[];
};

// PaymentList Detail(ADMIN)
export const getOPaymentDetailAdApi = async (detailReq: ODetailReq) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/payments/overseas/details`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({ ...detailReq }),
  };
  const response: CommonResponse<OPaymentDetail[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : []) as OPaymentDetail[];
};

// Note Payable(Issue)(ADMIN)
export const getONotePayableAdApi = async (params: PaymentSearchParams) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/payments/overseas/notepayable`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({ ...params }),
  };
  const response: CommonResponse<NotePayable[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : []) as NotePayable[];
};

// Note Payable(Issue) Detail(ADMIN)
export const getONotePayableDetailAdApi = async (detailReq: ODetailReq) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/payments/overseas/notepayable-details`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({ ...detailReq }),
  };
  const response: CommonResponse<NotePayableDetail[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : []) as NotePayableDetail[];
};

// PaymentDue(ADMIN)
export const getPaymentDueAdApi = async (params: PaymentSearchParams) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/payments/overseas/payment-due-list`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({ ...params }),
  };
  const response: CommonResponse<PaymentDue[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : []) as PaymentDue[];
};

/**
 * 법인코드 조회(C100 제외)
 * @returns
 */
export const getOverseasCopCds = async () => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/payments/overseas/cop-list`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
  };
  const response: CommonResponse<CommonCode[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as CommonCode[];
};
