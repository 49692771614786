import styled from '@emotion/styled';
import { GlobalTokenColor } from 'ui/theme/Color';
import { TextSize, TextWeight } from 'ui/theme/CustomTypography';
import info from '../asset/images/Info.svg';
import SubTitle from 'components/atom/subTitle';
import { GlobalGap } from 'ui/theme/Gap';
import GSelectMUIAtom from 'components/atom/select';
import { SelectChangeEvent } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface InfoProps {
  isVisibleComment?: boolean;
  isVisibleInfo?: boolean;
  content?: string | null;
  infoContent?: string | null;
  isVisbleSum?: boolean;
  isVisblePeople?: boolean;
  isVisbleSelect?: boolean;
  showSubTitle?: boolean | false;
  commentCount?: number;
  selectOption?: string[];
  currentColumnCount?: number;
  totalCoulmnCount?: number;
  defaultOption?: string;
  isSumColored?: boolean;
  isShowSumCount?: boolean;
  disabled?: boolean;
  commentPeople?: number;
  isVisibleStyled?: boolean;
  styledText?: string;
  styledUnit?: string;
  styledCount?: number;
  onChange?: (e: any) => void;
}

const SubTitleBox = ({
  content = 'subtitle',
  infoContent = 'info text',
  isVisibleComment,
  isVisblePeople,
  isVisibleInfo,
  isVisbleSum,
  isVisbleSelect,
  showSubTitle,
  commentCount,
  selectOption,
  currentColumnCount,
  totalCoulmnCount,
  defaultOption,
  isSumColored,
  isShowSumCount,
  disabled,
  commentPeople,
  isVisibleStyled,
  styledText,
  styledUnit,
  styledCount,
  onChange,
}: InfoProps) => {
  const { t } = useTranslation();

  const [option, setOption] = React.useState(defaultOption || '1');
  const handleChange = (event: SelectChangeEvent) => {
    setOption(event.target.value);
    if (selectOption && onChange) onChange(selectOption[Number(event.target.value) - 1]);
  };

  return (
    <Title2Box>
      {showSubTitle && <SubTitle title={content ? content : ''} />}
      <CommentBox isVisibleComment={isVisibleComment}>
        <CommentSub color={GlobalTokenColor.ESGrey900}>{t('com.label.tot', '총')}</CommentSub>
        <CommentSub color={GlobalTokenColor.Primary}>{currentColumnCount}</CommentSub>
        <Comment>/</Comment>
        <CommentSub color={GlobalTokenColor.ESGrey500}>{totalCoulmnCount}</CommentSub>
      </CommentBox>
      <CommentBox2 isVisbleSum={isVisbleSum} showSubTitle={showSubTitle}>
        <CommentSub2 color={GlobalTokenColor.ESGrey900}>{t('com.label.tot', '총')}</CommentSub2>
        <CommentSumCount isSumColored={isSumColored}>{commentCount}</CommentSumCount>
        <CommentSub2 color={GlobalTokenColor.ESGrey900}>{t('com.label.case', '건')}</CommentSub2>
      </CommentBox2>
      <CommentBox3
        isVisbleSum={isVisbleSum}
        showSubTitle={showSubTitle}
        isShowSumCount={isShowSumCount}
      >
        <CommentSumCount isSumColored={isSumColored}>{commentCount}</CommentSumCount>
      </CommentBox3>
      <CommentBox4 isVisblePeople={isVisblePeople} showSubTitle={showSubTitle}>
        <CommentSub2 color={GlobalTokenColor.ESGrey900}>{t('com.label.tot', '총')}</CommentSub2>
        <CommentSumPeople>{commentPeople}</CommentSumPeople>
        <CommentSub2 color={GlobalTokenColor.ESGrey900}>{t('com.label.head', '명')}</CommentSub2>
      </CommentBox4>
      <CommentBox5 isVisibleStyled={isVisibleStyled} showSubTitle={showSubTitle}>
        <CommentSub2 color={GlobalTokenColor.ESGrey900}>총</CommentSub2>
        <CommentStyledText>{styledText}</CommentStyledText>
        <CommentStyledCount>{styledCount}</CommentStyledCount>
        <CommentStyledUnit>{styledUnit}</CommentStyledUnit>
      </CommentBox5>
      <SelectBox isVisbleSelect={isVisbleSelect}>
        <GSelectMUIAtom
          selectWidth="60px"
          selectHeight="28px"
          option={selectOption || ['8', '16', '24']}
          value={option}
          disabled={disabled}
          handleChange={handleChange}
        />
        <CommentSub2 color={GlobalTokenColor.ESGrey900}>/page</CommentSub2>
      </SelectBox>
      <InfoBox isVisibleInfo={isVisibleInfo}>
        <InfoImg src={info} />
        <Infospan>{infoContent}</Infospan>
      </InfoBox>
    </Title2Box>
  );
};

const Title2Box = styled.div`
  display: flex;
  align-items: center;
`;

const CommentSub = styled.span`
  font-size: 12px;
  line-height: 24px;
  color: ${GlobalTokenColor.ESGrey900};
  color: ${(props) => props.color};
  &:first-child {
    margin-right: 2px;
  }
`;

const CommentSub2 = styled.span<InfoProps>`
  font-size: 13px;
  line-height: 24px;
  color: ${(props) => props.color};
`;

const CommentStyledText = styled.span<InfoProps>`
  font-size: 13px;
  line-height: 20px;
  color: #1f1f1f;
`;

const CommentStyledUnit = styled.span`
  font-size: 13px;
  line-height: 20px;
  color: #1f1f1f;
`;

const CommentBox3 = styled.span<InfoProps>`
  display: ${({ isShowSumCount }) => (isShowSumCount ? 'flex' : 'none')};
  margin-left: ${({ showSubTitle }) => (showSubTitle ? '8px' : '0px')};
  gap: ${GlobalGap.Px4};
  color: ${(props) => (props.isSumColored ? GlobalTokenColor.ESGrey500 : GlobalTokenColor.Primary)};
`;

const CommentSumCount = styled.span<InfoProps>`
  font-size: 13px;
  line-height: 24px;
  color: ${(props) => (props.isSumColored ? GlobalTokenColor.ESGrey500 : GlobalTokenColor.Primary)};
`;

const CommentSumPeople = styled.span`
  font-size: 13px;
  line-height: 24px;
  color: ${GlobalTokenColor.Primary};
`;

const CommentStyledCount = styled.span`
  font-size: 13px;
  line-height: 20px;
  color: ${GlobalTokenColor.Primary};
`;

const Comment = styled.span`
  margin: 0 2px;
  font-size: ${TextSize.Small};
  font-weight: 500;
  line-height: 24px;
  color: ${GlobalTokenColor.ESGrey500};
`;

const InfoBox = styled.div<InfoProps>`
  margin-left: 4px;
  display: flex;
  align-items: center;
  margin-left: 8px;
  gap: 4px;
  ${({ isVisibleInfo }) =>
    isVisibleInfo ? 'color: ${GlobalTokenColor.ESGrey500};' : 'display: none;'}
`;

const Infospan = styled.span`
  color: ${GlobalTokenColor.ESGrey500};
  font-size: ${TextSize.Small};
  font-weight: ${TextWeight.Normal};
  white-space: pre-wrap;
`;

const InfoImg = styled.img`
  width: 18px;
  height: 18px;
`;

const CommentBox = styled.div<InfoProps>`
  display: ${({ isVisibleComment }) => (isVisibleComment ? 'flex' : 'none')};
  margin-left: 8px;
`;

const CommentBox2 = styled.div<InfoProps>`
  display: ${({ isVisbleSum }) => (isVisbleSum ? 'flex' : 'none')};
  margin-left: ${({ showSubTitle }) => (showSubTitle ? '8px' : '0px')};
  gap: ${GlobalGap.Px4};
`;

const SelectBox = styled.div<InfoProps>`
  display: ${({ isVisbleSelect }) => (isVisbleSelect ? 'flex' : 'none')};
  margin-left: 8px;
  gap: ${GlobalGap.Px4};
`;

const CommentBox4 = styled.div<InfoProps>`
  display: ${({ isVisblePeople }) => (isVisblePeople ? 'flex' : 'none')};
  margin-left: ${({ showSubTitle }) => (showSubTitle ? '8px' : '0px')};
  gap: ${GlobalGap.Px4};
`;

const CommentBox5 = styled.div<InfoProps>`
  display: ${({ isVisibleStyled }) => (isVisibleStyled ? 'flex' : 'none')};
  margin-left: ${({ showSubTitle }) => (showSubTitle ? '8px' : '0px')};
  gap: ${GlobalGap.Px4};
  align-items: center;
`;

export default SubTitleBox;
