import { useCommonModal } from 'hooks/useCommonModal';
import { Department } from 'models/admin/Department';
import { useEffect, useRef, useState } from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { GPopupTitle, GButtonBox } from 'components/layouts/GLayoutBox';
import { DialogTitleItem } from 'components/layouts/GModalPopup';
import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import { GButton } from 'components/atom/button';
import Save from 'components/asset/images/Confirm.svg';
import SubTitleBox from 'components/molecule/SubTitleBox';
import { GLabelAtom } from 'components/atom/label';
import { GbasicTable, GbasicTableWrap } from 'components/tables/GTable';
import { SampleResponse } from 'models/sample/Sample';
import { useLoading } from 'components/process/Loading';
import { useMessageBar } from 'components/process/MessageBar';
import { getSamples } from 'apis/sample/SampleApi';
import ModalLayout from 'components/layouts/ModalLayout';
import Tag from 'components/atom/tag';
import FileDownload from 'components/molecule/FileDownload';

type DepartmentModalProps = {
  open: boolean;
  singleSelect?: boolean;
  close: () => void;
  save: () => void;
};

const InquiryOverseasAccount = (props: DepartmentModalProps) => {
  const { t } = useTranslation();
  const gridRef = useRef<AgGridReact<Department>>(null);
  const { openCommonModal } = useCommonModal();
  const [sampleList, setSampleList] = useState<SampleResponse[]>([]);
  const { openLoading } = useLoading();
  const { openMessageBar } = useMessageBar();

  const handleOnSave = () => {
    const selectedRows = gridRef.current?.api.getSelectedRows() || [];
    if (selectedRows.length === 0) {
      openCommonModal({
        modalType: 'alert',
        content: t(
          'department-choice-popup.alert.한 부서 이상 선택해야 합니다.',
          '__선택된 행이 없습니다.'
        ),
      });
      return;
    }
    props.close();
  };

  useEffect(() => {
    loadSampleList();
  }, []);

  const makeSearchParams = () => {
    return {
      start: '0',
      pageSize: 30,
    };
  };

  const handleError = (e: Error) => {
    openLoading(false);
    openMessageBar('error', e.message);
  };

  const loadSampleList = async () => {
    getSamples(makeSearchParams())
      .then((data) => {
        setSampleList(data.list);
      })
      .catch(handleError);
  };

  const handleClose = () => {
    props.close();
  };

  return (
    <ModalLayout open={props.open} onClose={handleClose} mMinWidth={1000} mMaxWidth={1000}>
      <DialogTitleItem id="customized-dialog-title" onClose={handleClose}>
        <GPopupTitle title2="계좌정보상세" />
      </DialogTitleItem>
      <DialogContent className="popupContent">
        <SubTitleBox content="계좌상세정보" showSubTitle={true} isVisbleSelect={false} />
        <GbasicTableWrap>
          <GbasicTable>
            <colgroup>
              <col style={{ width: '160px' }} />
              <col style={{ width: 'auto' }} />
              <col style={{ width: '160px' }} />
              <col style={{ width: 'auto' }} />
            </colgroup>
            <tbody>
              <tr>
                <th>
                  <GLabelAtom isRequired={true} align="left">
                    은행
                  </GLabelAtom>
                </th>
                <td>CITIBANK N.A</td>
                <th>
                  <GLabelAtom align="left">진행상태</GLabelAtom>
                </th>
                <td>
                  <Tag status="waiting" content="임시저장" />
                </td>
              </tr>
              <tr>
                <th>
                  <GLabelAtom align="left">SWIFT/BIC</GLabelAtom>
                </th>
                <td>CITIARBAXXX</td>
              </tr>
              <tr>
                <th>
                  <GLabelAtom align="left">은행키</GLabelAtom>
                </th>
                <td>503002480</td>
              </tr>
              <tr>
                <th>
                  <GLabelAtom align="left">예금주</GLabelAtom>
                </th>
                <td>김거래</td>
                <th>
                  <GLabelAtom align="left">추가 예금주</GLabelAtom>
                </th>
                <td>김추가</td>
              </tr>
              <tr>
                <th>
                  <GLabelAtom align="left">계좌번호</GLabelAtom>
                </th>
                <td>105-12345-123456478</td>
                <th>
                  <GLabelAtom align="left">통화</GLabelAtom>
                </th>
                <td>USD</td>
              </tr>
              <tr>
                <th>
                  <GLabelAtom align="left">계좌용도</GLabelAtom>
                </th>
                <td>외화 법인용</td>
              </tr>
              <tr>
                <th>
                  <GLabelAtom align="left">사업자등록증</GLabelAtom>
                </th>
                <td>
                  <FileDownload atchFileGrId="test" />
                </td>
              </tr>
              <tr>
                <th>
                  <GLabelAtom align="left">통장사본</GLabelAtom>
                </th>
                <td>
                  <FileDownload atchFileGrId="test" />
                </td>
              </tr>
              <tr>
                <th>
                  <GLabelAtom align="left">어음약정확인서</GLabelAtom>
                </th>
                <td>
                  <FileDownload atchFileGrId="test" />
                </td>
              </tr>
              {/* <tr>
                <th>
                  <GLabelAtom align="left">기타1</GLabelAtom>
                </th>
                <td>
                  <FileDownload atchFileGrId="test" />
                </td>
              </tr> */}
              <tr>
                <th>
                  <GLabelAtom align="left">기타</GLabelAtom>
                </th>
                <td>
                  <FileDownload atchFileGrId="test" />
                </td>
              </tr>
            </tbody>
          </GbasicTable>
        </GbasicTableWrap>
      </DialogContent>
      <DialogActions className="popupBottom">
        <GButtonBox>
          <GButton
            txt="수정 "
            sizes="medium"
            onClick={handleClose}
            btnstyled="outline"
            btnColor="normal"
            hasImg={false}
            hasTxt={true}
          />
          <GButton
            txt="전산등록요청"
            sizes="medium"
            onClick={handleOnSave}
            chkImg={Save}
            btnstyled="contained"
            btnColor="primary"
            hasImg={true}
            hasTxt={true}
          />
        </GButtonBox>
      </DialogActions>
    </ModalLayout>
  );
};

export default InquiryOverseasAccount;
