import styled from '@emotion/styled';
import { AgGridReact } from 'ag-grid-react';
import { GContentGrid } from 'components/layouts/GContentGrid';
import { GPagination, PaginationRef } from 'components/layouts/GPagination';
import RegistBox from 'components/organism/RegistBox';
import SubTitleBox from 'components/molecule/SubTitleBox';
import { SubTitleBtnArea } from 'components/organism/SubTitleBtnArea';
import { TitleBtnBox } from 'components/molecule/ButtonArea';
import { GButton } from 'components/atom/button';
import GButtonLayout from 'components/molecule/GbuttonLayout';
import { getSamples } from 'apis/sample/SampleApi';
import { useEffect, useRef, useState } from 'react';
import { useLoading } from 'components/process/Loading';
import { useMessageBar } from 'components/process/MessageBar';
import { useColumnDefsForApprovalList } from './ColumnDefs';
import { SampleResponse } from 'models/sample/Sample';

type SearchParams = {
  coCd: string;
  onduRegnCd?: string;
  jtiCd?: string;
  aprReqProgStatCd?: string;
  aprReqDtmSt?: string;
  aprReqDtmEd?: string;
  aprReqUserId?: string;
  aprReqUserNm?: string;
  stmtRegStat?: string;
  eaprDocNo?: string;
  aprReqTitNm?: string;
};

const SampleType2 = () => {
  // get grid data, defaultColDef
  const [sampleList, setSampleList] = useState<SampleResponse[]>([]);
  const gridApiRef = useRef<any>(null);
  const { defaultColDef, colDefs } = useColumnDefsForApprovalList();

  // pagination
  const [totalCount, setTotalCount] = useState<number>(0);
  const paginationRef = useRef<PaginationRef>(null);
  const [pageSize, setPageSize] = useState<number>(30);
  const [pageNo, setPageNo] = useState<number>(1);
  const { openLoading } = useLoading();
  const { openMessageBar } = useMessageBar();

  const [searchParams, setSearchParams] = useState<SearchParams>({
    coCd: '',
    onduRegnCd: '',
    jtiCd: '',
    aprReqProgStatCd: '',
    aprReqUserId: '',
    aprReqTitNm: '',
    eaprDocNo: '',
    stmtRegStat: '',
  });

  useEffect(() => {
    loadSampleList();
  }, [pageNo, pageSize]);

  const makeSearchParams = () => {
    return {
      ...searchParams,
      start: String(pageSize * (pageNo - 1)),
      pageSize: String(pageSize),
    };
  };

  const handleError = (e: Error) => {
    openLoading(false);
    openMessageBar('error', e.message);
  };

  const loadSampleList = async () => {
    getSamples(makeSearchParams())
      .then((data) => {
        setSampleList(data.list);
        setTotalCount(data?.totalCount || 0);
      })
      .catch(handleError);
  };

  const handlePageSizeChange = (size) => {
    paginationRef.current?.setPageSize(size);
    setPageNo(1);
    setPageSize(size);
  };

  const handlePageNoClick = (no: number) => {
    setPageNo(no);
  };

  return (
    <>
      {/* 가장 바깥에 감싸는 태그가 빈태그(<></>)라면 아무것도 적용하지 않음 */}
      {/* 만약 빈태그가 아니라면 display: flex; flex-direction: column; 적용*/}
      {/* SearchBox / RegistBox 에 따라서 직접 organism폴더의 파일에 가서 복붙 해오기 */}
      <RegistBox />

      {/* 서브 타이틀 */}
      <SubTitleBtnArea>
        <SubTitleBox
          content="서브 타이틀"
          isVisibleComment={false}
          isVisibleInfo={false}
          isVisbleSum={true}
          showSubTitle={true}
          commentCount={2}
        />
        <TitleBtnBox>
          <GButton
            txt="다운로드"
            btnstyled="outline"
            btnColor="normal"
            sizes="small"
            hasTxt={true}
          />
        </TitleBtnBox>
      </SubTitleBtnArea>

      {/* 그리드 - flex */}
      {/* GPagination이 없다면 GridWrap으로 감싸지 않음(type1에 예시 있음) */}
      <GridWrap>
        <GContentGrid
          type="none"
          isPagination={true}
          className="ag-theme-alpine"
          style={{ flex: 1 }}
        >
          <AgGridReact
            defaultColDef={defaultColDef}
            columnDefs={colDefs}
            rowData={sampleList}
            rowSelection={'single'}
            //suppressRowClickSelection
            //suppressMovableColumns
            enableCellTextSelection
            onGridReady={(params) => (gridApiRef.current = params.api)}
            tooltipShowDelay={0}
            //tooltipHideDelay={2000}
          ></AgGridReact>
        </GContentGrid>
        <GPagination
          ref={paginationRef}
          totalCount={totalCount}
          defaultPageNo={pageNo}
          defaultPageSize={pageSize}
          onChangePageSize={handlePageSizeChange}
          onClickPageNo={handlePageNoClick}
        />
      </GridWrap>

      {/* 버튼 (버튼이 여러개라면 <GButtonBox>를 감싸기, 가이드 참고하여 진행할 것!! ) */}
      <GButtonLayout marginBottom="8px">
        <GButton
          txt="신규"
          sizes="medium"
          btnstyled="outline"
          btnColor="normal"
          hasImg={false}
          hasTxt={true}
        ></GButton>
      </GButtonLayout>
    </>
  );
};

const GridWrap = styled.div`
  border: 1px solid #dde0df;
  margin-top: 4px;

  // flex일때만 아래의 속성을 넣음
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export default SampleType2;
