/** @jsxImportSource @emotion/react */

import { getDepartments } from 'apis/admin/Department';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import React, { useCallback, useRef, useState } from 'react';
import { Department } from 'models/admin/Department';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';

import {
  BlueButton,
  GreyButton,
  IconButton,
  GreyLineButton,
} from 'components/buttons/CustomButton';
import { CustomInputText } from 'components/inputs/CustomInput';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { ContentSection, GridInfoSection, GridInfo } from 'components/layouts/ContentSection';
import { ContentGrid } from 'components/layouts/ContentGrid';
import { SearchBox, SearchButtons, SearchRows } from 'components/layouts/SearchBox';
import { di } from 'components/layouts/Dialog';
import { useCommonModal } from 'hooks/useCommonModal';
import { CrudCode } from 'models/common/Edit';
import useSessionStore from 'stores/useSessionStore';
import { GButton } from 'components/buttons/GButton';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';

type DepartmentModalProps = {
  open: boolean;
  close: () => void;
  save: (item: Department) => void;
};

const emptyDepartment: Department = {
  deptCd: '',
  coCd: '',
  deptNm: '',
  deptEngNm: '',
  temLdrUserId: '',
  upprDeptCd: '',
  useYn: '',
  deptCngNm: '',
};

const DepartmentModal = (props: DepartmentModalProps) => {
  const { t } = useTranslation();
  const gridRef = useRef<AgGridReact<Department>>(null);
  const [departmentList, setDepartmentList] = useState<Department[]>([]);
  const [selectedDepartment, setSelectedDepartment] = useState<Department>(emptyDepartment);
  const [searchItem, setSearchItem] = useState<string>('');
  const [gridHeight, setGridHeight] = useState<string>('100%');
  const { openCommonModal } = useCommonModal();
  const { gridNoRowsTemplate } = useSessionStore();

  const columns: ColDef[] = [
    {
      headerName: 'No',
      width: 80,
      cellStyle: { textAlign: 'center' },
      cellRenderer: (params) => {
        return params.node.rowIndex + 1;
      },
    },
    {
      headerCheckboxSelection: false,
      width: 51,
      checkboxSelection: true,
      cellStyle: { display: 'flex', justifyContent: 'flex-end' },
    },
    {
      field: 'coCd',
      headerName: String(t('department-choice-popup.label.회사 코드', '__회사 코드')),
      width: 150,
      cellStyle: { textAlign: 'center' },
    },
    {
      field: 'deptCd',
      headerName: String(t('com.label.deptCd', '__부서코드')),
      width: 150,
      cellStyle: { textAlign: 'center' },
    },
    {
      field: 'deptNm',
      headerName: String(t('com.label.deptNm', '__부서명')),
      flex: 1,
    },
  ];

  const getDepartmentList = useCallback(async () => {
    const response = await getDepartments(searchItem);
    console.log(response.departmentList);
    setDepartmentList(response.departmentList);
  }, [searchItem]);

  const handleClose = () => {
    setDepartmentList([]);
    props.close();
  };
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchItem(e.target.value);
  };

  const handleOnSelectionChange = useCallback(() => {
    const selectedRows = gridRef.current?.api.getSelectedRows();
    if (selectedRows && selectedRows.length > 0) {
      setSelectedDepartment(selectedRows[0]);
    } else {
      setSelectedDepartment(emptyDepartment);
    }
  }, []);

  const handleSearch = useCallback(() => {
    getDepartmentList();
    setGridHeight('400px');
  }, [searchItem]);

  const handleOnSave = () => {
    const selectedRows = gridRef.current!.api.getSelectedRows();
    if (selectedRows.length === 0) {
      openCommonModal({
        modalType: 'alert',
        content: t(
          'department-choice-popup.alert.한 부서 이상 선택해야 합니다.',
          '__한 부서 이상 선택해야 합니다.'
        ),
      });
      return;
    }
    props.save(selectedDepartment);
    props.close();
    setDepartmentList([]);
  };

  return (
    <Dialog
      style={{ height: '1000px' }}
      open={props.open}
      onClose={handleClose}
      css={di.dialog}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle className="popupTitle">
        {t('department-choice-popup.title.부서목록 검색', '__부서목록 검색')}
        <IconButton className="buttonClose" onClick={handleClose}>
          <CloseIcon fontSize="large"></CloseIcon>
        </IconButton>
      </DialogTitle>

      <DialogContent className="popupContent">
        <SearchBox>
          <SearchRows>
            <div className="searchCol fullWidth">
              <label> {t('com.label.00697', '__검색어')}</label>
              <CustomInputText onChange={handleOnChange}></CustomInputText>
            </div>
          </SearchRows>
          <SearchButtons>
            <GreyButton className="buttonSrch" onClick={handleSearch}>
              <SearchIcon />
              {t('com.label.00698', '__조회')}
            </GreyButton>
          </SearchButtons>
        </SearchBox>
        <ContentSection className="marginT0">
          <GridInfoSection>
            <GridInfo>
              <span>{t('com.label.dept', '__부서')}</span>
              <span className="primary">
                {departmentList ? departmentList.length : 0} {t('common.label.건', '__건')}
              </span>
            </GridInfo>
          </GridInfoSection>
          <ContentGrid className="ag-theme-alpine" style={{ height: '372px' }}>
            <AgGridReact
              overlayNoRowsTemplate={gridNoRowsTemplate}
              ref={gridRef}
              rowData={departmentList}
              columnDefs={columns}
              suppressRowClickSelection={true}
              onSelectionChanged={handleOnSelectionChange}
            />
          </ContentGrid>
        </ContentSection>
      </DialogContent>
      <DialogActions className="popupBottom">
        <GButton onClick={handleClose} color="secondary" variant="outlined" size="medium">
          {t('btn.com.취소', '__취소')}
        </GButton>
        <GButton
          onClick={handleOnSave}
          color="primary"
          variant="contained"
          size="medium"
          startIcon={<DoneRoundedIcon />}
        >
          {t('btn.com.확인', '__확인')}
        </GButton>
      </DialogActions>
    </Dialog>
  );
};

export default DepartmentModal;
