import { ColDef } from 'ag-grid-community';
import { DefaultCellTooltip } from 'components/grids/tooltip/DefaultCellTooltip';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useColumnDefsForApprovalList = () => {
  const { t } = useTranslation();
  const defaultColDef = useMemo((): ColDef => {
    return {
      sortable: false,
      resizable: true,
      cellStyle: { textAlign: 'left' },
      tooltipComponent: DefaultCellTooltip,
    };
  }, []);

  const colDefs = useMemo((): any[] => {
    return [
      {
        headerName: t('컬럼1코드', '국적'),
        field: 'column1',
        tooltipField: 'column1',
        flex: 1,
        minWidth: 185,
      },
      {
        headerName: t('컬럼2코드', '은행키'),
        field: 'column2',
        tooltipField: 'column2',
        flex: 1,
        minWidth: 185,
      },
      {
        headerName: t('컬럼3코드', '은행'),
        field: 'column3',
        tooltipField: 'column3',
        flex: 1,
        minWidth: 185,
      },
      {
        headerName: t('컬럼4코드', '은행 지점'),
        field: 'column4',
        tooltipField: 'column4',
        flex: 1,
        minWidth: 185,
      },
      {
        headerName: t('컬럼5코드', '국적'),
        field: 'column5',
        tooltipField: 'column5',
        flex: 1,
        minWidth: 185,
      },
    ];
  }, []);

  const handleAprvDetailBtn = (data) => {
    console.log('화면 연결이 필요합니다.');
  };

  return { defaultColDef, colDefs };
};
