export enum TextSize {
  H1 = '24px',
  H2 = '18px',
  H3 = '16px',
  H4 = '15px',
  H5 = '14px',
  H6 = '13px',
  Xlarge = '15px',
  Large = '14px',
  Basic = '13px',
  Small = '12px',
  Xsmall = '11px',
}

export enum TextWeight {
  Light = 300,
  Normal = 400,
  Regualr = 400,
  Medium = 500,
  Bold = 700,
}

export enum TextLineHeight {
  Small = 1.25,
  Basic = 1.5,
  Large = 1.75,
}

export enum TextFamily {
  Basic = '맑은고딕, Apple SD Gothic Neo, Segoe UI, MS YaHei',
  Light = 'Spoqa Han Sans Neo Light',
  Regular = 'Spoqa Han Sans Neo Regular',
  Medium = 'Spoqa Han Sans Neo Medium',
  Bold = 'Spoqa Han Sans Neo Bold',
}
