import styled from '@emotion/styled';

interface ProfileProps {
  type?: '1' | '2';
  profileImg?: string;
  background?: string;
  border?: string;
  nickName?: string;
  isPosition?: boolean;
  position?: string;
  width?: string;
  textColor?: string;
}

const EditProfile = (props: ProfileProps) => {
  return (
    <Container type={props.type}>
      <Border background={props.background} border={props.border}>
        <ProfileImg src={props.profileImg} type={props.type} />
      </Border>
      <Nickname type={props.type}>{props.nickName}</Nickname>
      {props.isPosition ? <Position>{props.position}</Position> : <></>}
    </Container>
  );
};

const Container = styled.div<ProfileProps>`
  display: flex;
  align-items: center;
  gap: ${(props) => (props.type === '1' ? `8px` : '4px')};
  height: 100%;
`;

const Border = styled.span<ProfileProps>`
  position: relative;
  width: 20px;
  height: 20px;
  border: 1px solid ${(props) => props.border || '#554596'};
  border-radius: 50px;
  background: ${(props) => props.background || '#554596'};
`;

const ProfileImg = styled.img<ProfileProps>`
  position: absolute;
  width: 100%;
  height: 16px;
  border-radius: 50px;
`;

const TextStyle = styled.p<ProfileProps>`
  color: ${(props) => props.textColor || '#1f1f1f;'};
  font-size: ${(props) => (props.type === '1' ? `13px` : '12px')};
  font-weight: ${(props) => (props.type === '1' ? `500` : '700')};
  line-height: 150%;
`;

const Nickname = styled(TextStyle)``;

const Position = styled(TextStyle)`
  color: var(--Common-Text-Basic, #1f1f1f);
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Spoqa Han Sans Neo;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

export default EditProfile;
