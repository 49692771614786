import { ColDef } from 'ag-grid-community';
import { DefaultCellTooltip } from 'components/grids/tooltip/DefaultCellTooltip';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import checkIcon from 'components/asset/UnChecked.svg';

export const useColumnDefsForSearchChangePerson = () => {
  const { t } = useTranslation();
  const defaultColDef = useMemo((): ColDef => {
    return {
      sortable: false,
      resizable: true,
      cellStyle: { textAlign: 'center' },
      tooltipComponent: DefaultCellTooltip,
    };
  }, []);

  const colDefs = useMemo((): any[] => {
    return [
      {
        headerName: 'check',
        headerComponentParams: {
          template: `<img src=${checkIcon} alt=''/>`,
        },
        width: '28px',
        height: 24,
        padding: '2px 6px',
        resizable: false,
        headerCheckboxSelection: true,
        showDisabledCheckboxes: true,
        checkboxSelection: true,
        cellStyle: {
          display: 'flex',
          justifyContent: 'center',
          width: '28px',
          height: 24,
        },
      },
      {
        headerName: t('컬럼1코드', '이름'),
        field: 'column1',
        tooltipField: 'column1',
        flex: 1,
      },
      {
        headerName: t('컬럼2코드', '직책/직급'),
        field: 'column2',
        tooltipField: 'column2',
        flex: 1,
      },
      {
        headerName: t('컬럼3코드', '부서'),
        field: 'column3',
        tooltipField: 'column3',
        flex: 1,
      },
    ];
  }, []);

  const handleAprvDetailBtn = (data) => {
    console.log('화면 연결이 필요합니다.');
  };

  return { defaultColDef, colDefs };
};
