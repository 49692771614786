/** @jsxImportSource @emotion/react */
import React, { useCallback, useState } from 'react';

import RoleManagementGrid from 'pages/admin/rolemanagement/RoleManagementGrid';
import RoleEmpManagementGrid from 'pages/admin/rolemanagement/RoleEmpManagementGrid';

import { BasicColor, BorderColor, FontColor, BgColor, ButtonColor } from 'ui/theme/Color';
import { css } from '@emotion/react';
import { CustomInputText } from 'components/inputs/CustomInput';
import { GreyButton } from 'components/buttons/CustomButton';
import { ContainerLayout } from 'components/layouts/MainLayout';
import { SearchBox, SearchButtons, SearchRows } from 'components/layouts/SearchBox';
import { useTranslation } from 'react-i18next';
import { ContentFlex } from 'components/layouts/ContentFlex';
import { ContentSection } from 'components/layouts/ContentSection';
import searchImage from 'components/asset/images/Search.svg';
import { GButton } from 'components/atom/button';

const RoleEmployeeManagementPage: React.FC = () => {
  const { t } = useTranslation();
  const [textboxData, setTextboxData] = useState<string>('');
  const [searchParam, setSearchParam] = useState<string>('');
  const [searchEmpRoleCd, setSearchEmpRoleCd] = useState<string>('');

  const handleSearchParamData = (e: any) => {
    setTextboxData(e.target.value);
  };

  const btnSearch = () => {
    setSearchParam(textboxData);
  };

  const listner = useCallback((param: string) => {
    setSearchEmpRoleCd(param);
  }, []);

  return (
    <ContainerLayout>
      <SearchBox>
        <SearchRows>
          <div className="searchCol">
            <label>{t('com.label.roleNm', '__역할명')}</label>
            <CustomInputText onChange={handleSearchParamData}></CustomInputText>
          </div>
        </SearchRows>
        <SearchButtons className="searchButtons">
          <GButton
            chkImg={searchImage}
            txt={t('com.label.00116', '__조회')}
            sizes="medium"
            btnstyled="contained"
            btnColor="normal"
            hasImg={true}
            hasTxt={true}
            onClick={btnSearch}
          ></GButton>
        </SearchButtons>
      </SearchBox>
      <ContentFlex>
        <ContentSection className="section width40p marginT0">
          <RoleManagementGrid
            sRoleNm={searchParam}
            editable={false}
            callBackRoleCd={listner}
            width={100}
          />
        </ContentSection>
        <ContentSection className="section width60p">
          <RoleEmpManagementGrid sRoleCd={searchEmpRoleCd} editable={true} />
        </ContentSection>
      </ContentFlex>
    </ContainerLayout>
  );
};

export default RoleEmployeeManagementPage;

const style = {
  search: css`
    display: flex;
    flex-direction: column;
    padding: 12px 20px;
    border-top: 1px solid ${BorderColor.Primary};
    border-bottom: 1px solid ${BorderColor.Primary};
    background-color: ${BgColor.White50};
    margin-bottom: 60px;
    .searchRow {
      justify-content: space-between;
      display: flex;
      align-items: center;
      + .searchRow {
        margin-top: 12px;
      }
      .searchCol {
        label + input,
        label + select {
          margin-left: 12px;
        }
        min-width: 200px;

        &:last-child {
          text-align: right;
        }
      }
    }
  `,
  flex: css`
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 200px;

    .section {
      &.width40p {
        width: 40%;
      }
      &.width50p {
        width: 50%;
      }
      &.width60p {
        width: 60%;
      }
    }
    .section + .section {
      margin-top: 0;
      margin-left: 20px;
    }
  `,
  section: css`
    margin-top: 40px;
    width: 100%;
    height: 200px;

    &:first-child {
      margin-top: 0;
    }
    .gridTop {
      display: flex;
      align-items: flex-end;
      margin-bottom: 15px;
      height: 38px;

      &.contentBetween {
        justify-content: space-between;
      }

      &.contentEnd {
        justify-content: end;
      }

      .gridInfo {
        font-weight: 500;

        span {
          margin-right: 10px;
          &.primary {
            color: ${BasicColor.Primary};
          }
        }
      }
    }

    .noGridTop {
      margin-top: 53px;
    }

    .ag-theme-alpine {
      --ag-header-background-color: ${BgColor.Gray100};
      --ag-font-size: 16px;
      --ag-font-family: LGSmHaT;

      --ag-row-hover-color: ${BgColor.White50};
      --ag-selected-row-background-color: ${BgColor.White50};

      --ag-borders: none;
      --ag-row-border-color: ${BorderColor.Primary};
      --ag-input-focus-border-color: ${BorderColor.Primary};
      --ag-checkbox-background-color: transparent;
      --ag-checkbox-checked-color: ${ButtonColor.Primary};
      --ag-input-focus-box-shadow: 0;

      --ag-row-height: 50px !important;
    }

    .ag-header-cell-label {
      justify-content: center;
    }
    .ag-cell {
      text-align: center;
    }
    .ag-root {
      border-top: 1px solid ${BorderColor.Primary};
      border-bottom: 1px solid ${BorderColor.Primary};

      .ag-header-cell-label {
        justify-content: center;
      }
      .ag-header-cell-text {
        font-weight: 500;
      }

      .ag-row {
        height: 52px !important;
      }

      .ag-cell {
        text-align: center;
      }
    }
    .treeBox {
      padding: 10px;
      border: 1px solid ${BorderColor.Primary};
      .tree {
        margin-top: 5px;
        padding-left: 5px;

        label {
          display: inline-block;
          position: relative;
          padding-bottom: 15px;
          cursor: pointer;
          color: ${FontColor.Gray400};

          &:hover {
            color: ${FontColor.Primary};
          }

          &:before {
            content: '';
            width: 24px;
            height: 24px;
            margin-right: 5px;
            display: inline-block;
            vertical-align: -6px;
            color: ${FontColor.Primary};
            background: #fff url(/assets/icon/ic_tree_folder_close.svg) no-repeat center;
          }

          &.lastTree:before {
            background-image: url(/assets/icon/ic_tree_file.svg);
          }

          &.root:before {
            color: ${FontColor.Primary};
            background-image: url(/assets/icon/ic_tree_root_close.svg);
          }
        }

        ul {
          padding-left: 30px;
          display: none;

          li {
            position: relative;
            &:before {
              content: '';
              display: inline-block;
              width: 1px;
              height: 100%;
              position: absolute;
              top: 0;
              left: 10px;
              background: url(/assets/icon/dot_tree_line.svg) repeat-y;
            }

            &.lastList {
              :before {
                display: none;
              }
            }
          }
        }

        *:before {
          width: 15px;
          height: 15px;
          display: inline-block;
        }

        input[type='checkbox'] {
          display: none;

          &:checked ~ ul {
            display: block;
          }

          &:checked + label:before {
            background-image: url(/assets/icon/ic_tree_folder_open.svg);
          }

          &:checked + label.lastTree:before {
            background-image: url(/assets/icon/ic_tree_file.svg);
          }

          &:checked + label.root:before {
            background-image: url(/assets/icon/ic_tree_root_open.svg);
          }
        }
      }
    }
  `,
};
