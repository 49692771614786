import styled from '@emotion/styled';

interface PropsType {
  width?: string;
}
export const GridBox = styled.div`
  display: flex;
  gap: 24px;
`;

export const GridItem = styled.div<PropsType>`
  flex: ${(props) => (props.width ? '0 0 ' + props.width : '1')};
  height: 100%;
`;

// flex를 사용해야하는 GridBox와 GridItem을 위해 flex를 추가한 컴포넌트 추가
export const GridFlexBox = styled.div`
  display: flex;
  flex: 1;
  gap: 24px;
`;
export const GridFlexItem = styled.div<PropsType>`
  flex: ${(props) => (props.width ? '0 0 ' + props.width : '1')};
  display: flex;
  flex-direction: column;
  height: 100%;
`;
