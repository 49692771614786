import { ColDef } from 'ag-grid-community';
import { DefaultCellTooltip } from 'components/grids/tooltip/DefaultCellTooltip';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import checkIcon from 'components/asset/UnChecked.svg';

export const useManagerSearchColumnDefs = () => {
  const { t } = useTranslation();
  const defaultColDef = useMemo((): ColDef => {
    return {
      sortable: false,
      resizable: true,
      cellStyle: { textAlign: 'center' },
      tooltipComponent: DefaultCellTooltip,
    };
  }, []);

  const colDefs = useMemo((): any[] => {
    return [
      {
        headerName: 'check',
        headerComponentParams: {
          template: `<img src=${checkIcon} alt=''/>`,
        },
        width: '28px',
        height: 24,
        padding: '2px 6px',
        resizable: false,
        headerCheckboxSelection: true,
        showDisabledCheckboxes: true,
        checkboxSelection: true,
        cellStyle: {
          display: 'flex',
          justifyContent: 'center',
          width: '28px',
          height: 24,
        },
      },
      {
        headerName: t('com.label.00012', '__이름'),
        field: 'empNm',
        tooltipField: 'empNm',
        flex: 1,
      },
      {
        headerName: t('com.label.jpsOrJgd', '__직책/직급'),
        field: 'jtiNm',
        tooltipField: 'jtiNm',
        flex: 1,
      },
      {
        headerName: t('com.label.dept', '부서'),
        field: 'deptNm',
        tooltipField: 'deptNm',
        cellStyle: { textAlign: 'left' },
        flex: 1,
      },
    ];
  }, []);

  return { defaultColDef, colDefs };
};
