import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react';
import { CommonYN } from 'models/common/Common';
import { CrudCode } from 'models/common/Edit';
import { v4 as uuidv4 } from 'uuid';
import { FileInfo, FileSaveResponse, FileSaveResult } from 'models/admin/FileInfo';
import { findFiles, modifyFiles, uploadFiles } from 'apis/admin/FileUpload';
import { useCommonModal } from 'hooks/useCommonModal';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import styled from '@emotion/styled';
import uploadericon from '../asset/FileUploader.png';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import DelButton from '../atom/delButton';
import { BorderColor, GlobalTokenColor } from 'ui/theme/Color';

import txtIcon from 'components/asset/images/etcFile.svg';
import pdfIcon from 'components/asset/images/pdfFile.svg';
import jpgIcon from 'components/asset/images/imgFile.svg';
import excelIcon from 'components/asset/images/excelFile.svg';
import etcIcon from 'components/asset/images/etcFile.svg';
import imgIcon from 'components/asset/images/imgFile.svg';
import markdownIcon from 'components/asset/images/markdownFile.svg';
import pptIcon from 'components/asset/images/pptFile.svg';
import wordIcon from 'components/asset/images/wordFile.svg';
import zipIcon from 'components/asset/images/zipFile.svg';
import { useMessageBar } from 'components/process/MessageBar';

interface FileUploadZoneProps {
  atchFileGrIdInput?: string | string[];
  isMultipleFile: boolean;
  allowFileTypes: string[];
  atchFileTpCd?: string;
  fileList?: FileInfo[];
  maxCount?: number;
  getSelectedFile?: (files: any) => void;
  onChange?: (files: any) => void;
  width?: string;
  height?: string;
  maxSize?: number;
  maxSizeText?: string;
}

// eslint-disable-next-line react/display-name
const FileUploadZone = forwardRef(
  (
    {
      atchFileGrIdInput,
      isMultipleFile,
      allowFileTypes,
      atchFileTpCd = '',
      fileList,
      width,
      height,
      maxCount = 0,
      getSelectedFile,
      onChange,
      maxSize = 209715200,
      maxSizeText = '200MB',
    }: FileUploadZoneProps,
    ref: React.Ref<any>
  ) => {
    const { t } = useTranslation();
    const t2 = t as (msgId: string, defVal: string) => string;
    const { openCommonModal } = useCommonModal();
    const { openMessageBar } = useMessageBar();
    const [files, setFiles] = useState<FileInfo[]>(fileList || []);
    const [allFileSize, setAllFileSize] = useState(0);
    const [atchFileGrId, setAtchFileGrId] = useState(uuidv4());

    const fileCount = {};

    const fileInput = useRef<any>(null);

    useEffect(() => {
      getSelectedFile ? getSelectedFile(files) : null;

      let sum = 0;
      files.map((file, index) => {
        if (file.useYn == 'Y') {
          sum += file.atchFileSize;
        }
      });

      setAllFileSize(sum);

      onChange ? onChange(files) : null;

      fileInput.current.value = '';
    }, [files]);

    // 초기 파일들이 있을 경우 atchFileGrIdInput을 통해 받음
    useEffect(() => {
      if (fileList && fileList.length != 0) return;

      if (atchFileGrIdInput != null) {
        if (typeof atchFileGrIdInput === 'string') {
          findFiles(atchFileGrIdInput).then((result: FileInfo[]) => {
            if (result.length != 0) {
              result.map((files) => ({ ...files, newYn: false }));
              setFiles(result);
              setAtchFileGrId(atchFileGrIdInput);
            }
          });
        } else if (typeof atchFileGrIdInput === 'object' && atchFileGrIdInput.length > 0) {
          //object로 들어온 경우
          setFileList(atchFileGrIdInput);
        }
      }
    }, [atchFileGrIdInput]);

    //다중 초기 파일 세팅
    const setFileList = async (atchFileGrIdInputs) => {
      const fileList: FileInfo[] = [];

      for (const atchFileGrIdInput of atchFileGrIdInputs) {
        await findFiles(atchFileGrIdInput).then((filse: FileInfo[]) => {
          if (filse.length != 0) {
            filse.forEach((file) => {
              fileList.push({
                ...file,
                atchFileGrId: atchFileGrId,
                // atchFileId: '',
                newYn: true,
                crudKey: CrudCode.CREATE,
              });
            });
          }
        });
      }
      setFiles(fileList);
    };

    useImperativeHandle(ref, () => ({
      saveFiles: async () => {
        const fileSaveResponse: FileSaveResponse = await saveFiles();
        findFiles(fileSaveResponse.atchFileGrId).then((result: FileInfo[]) => {
          if (result.length != 0) {
            result.map((files) => ({ ...files, newYn: false }));
            setFiles(result);
            setAtchFileGrId(fileSaveResponse.atchFileGrId);
          }
        });

        return fileSaveResponse;
      },
      getFiles: () => files,
      isOverFileSize: () => (allFileSize > maxSize ? true : false),
    }));

    const fileDown = async (file) => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/v1/file/download?atchFileGrId=${file.atchFileGrId}&atchFileId=${file.atchFileId}`,
          {
            responseType: 'blob',
          }
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file.atchFileNm);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } catch (error) {
        console.log(error);
      }
    };

    const handleAtchFileAddClick = () => {
      if (!checkFileCount()) {
        return;
      }
      fileInput.current.click();
    };

    const duplicateCheckSave = (fileList) => {
      if (allowFileTypes.length == 0) {
        openCommonModal({
          modalType: 'alert',
          content: t('com.label.00158', '__첨부파일 유형을 선택하세요'),
        });

        return;
      }
      // 선택된 파일 리스트 files 에서 중복파일 dupleFileLst로 보냄
      files.map((file) => {
        for (let i = 0; i < fileList.length; i++) {
          if (
            file.crudKey == CrudCode.CREATE &&
            fileList[i].name === file.atchFileNm &&
            fileList[i].size == file.atchFileSize
          ) {
            fileList.splice(i, 1);
          } else if (
            fileList[i].name === file.atchFileNm &&
            fileList[i].size != file.atchFileSize
          ) {
            if (Object.keys(fileCount).includes(fileList[i].name)) {
              fileCount[fileList[i].name]++;
            } else {
              fileCount[fileList[i].name] = 1;
            }

            const oldFile = fileList[i];

            fileList.push(
              new File(
                [oldFile],
                `${oldFile.name.split('.')[0]}(${fileCount[oldFile.name]}).${
                  oldFile.name.split('.')[1]
                }`,
                { type: oldFile.type }
              )
            );
            fileList.splice(i, 1);
          }
        }
      });

      if (!checkFileCount(false, fileList)) return;
      // 중복되지 않은 파일 fileList 저장
      const newFileList: any[] = [];
      fileList.map((file) => {
        // 파일첨부 부분
        const newFile = {
          atchFileGrId: atchFileGrId,
          atchFileId: uuidv4(),
          atchFileNm: file.name,
          sortOrd: '',
          atchFileSaveLocDivsCd: '',
          atchFileSaveNm: file.name,
          atchFileSize: file.size,
          atchFileEfnmNm: '',
          atchFileSavePathCtn: '',
          atchFileTpCd: '',
          optValCtn1: '',
          optValCtn2: '',
          optValCtn3: '',
          optValCtn4: '',
          optValCtn5: '',
          useYn: CommonYN.Y,
          newYn: true,
          crudKey: CrudCode.CREATE,
          file: file,
        };
        newFileList.push(newFile);
      });
      setFiles((prevFile) => [...prevFile, ...newFileList]);

      if (!isMultipleFile) {
        files.map((item) => {
          (item.useYn = CommonYN.N), (item.crudKey = CrudCode.UPDATE);
        });
      }
    };

    const handleFileChange = (e) => {
      const fileList: any = Array.from(e.target.files);

      if (!checkFileCount(true, fileList)) {
        return;
      }

      // 파일 용량 및 형식 제한
      let allSize = allFileSize;
      for (let i = 0; i < fileList.length; i++) {
        allSize += fileList[i].size;
      }

      if (allSize > maxSize) {
        alert(t('com.label.00161', '__첨부파일 최대용량을 초과하였습니다.'));
        return;
      }

      for (let i = 0; i < fileList.length; i++) {
        // if (fileList[i].size > maxSize) {
        //   fileList.splice(i, 1);
        //   alert(
        //     t('com.label.00686', '__첨부파일 최대용량') +
        //       ` : ${maxSizeText}`
        //   );
        //   return;
        // }

        if (
          !allowFileTypes.includes(
            fileList[i].name.slice(fileList[i].name.lastIndexOf('.') + 1).toLowerCase()
          )
        ) {
          fileList.splice(i, 1);
          openCommonModal({
            modalType: 'alert',
            content: (
              <pre style={{ whiteSpace: 'pre-wrap' }}>{`${t(
                'com.label.00570',
                '__첨부할 수 없는 파일 형식입니다. '
              )}\n${t('com.label.00571', '__(첨부가능한 파일 형식 :')}${allowFileTypes.join(
                ', '
              )})`}</pre>
            ),
          });

          return;
        }
      }
      duplicateCheckSave(fileList);
    };

    const handleAtchFileDrop = (e) => {
      e.preventDefault();
      e.stopPropagation();

      const fileList: any = Array.from(e.dataTransfer.files);

      // 파일 용량 및 형식 제한
      for (let i = 0; i < fileList.length; i++) {
        if (fileList[i].size > maxSize) {
          fileList.splice(i, 1);
          openCommonModal({
            modalType: 'alert',
            content: t('com.label.00161', '__첨부파일 최대용량을 초과하였습니다.'),
          });

          return;
        }
        if (
          !allowFileTypes.includes(
            fileList[i].name.slice(fileList[i].name.lastIndexOf('.') + 1).toLowerCase()
          )
        ) {
          fileList.splice(i, 1);
          openCommonModal({
            modalType: 'alert',
            content: (
              <pre style={{ whiteSpace: 'pre-wrap' }}>{`${t(
                'com.label.00570',
                '__첨부할 수 없는 파일 형식입니다. '
              )}\n${t('com.label.00571', '__(첨부가능한 파일 형식 :')}${allowFileTypes.join(
                ', '
              )})`}</pre>
            ),
          });

          return;
        }
      }

      duplicateCheckSave(fileList);
    };

    const saveFiles = async () => {
      const response = {
        atchFileGrId: '',
        fileSaveResult: FileSaveResult.NONE,
      } as FileSaveResponse;

      if (files.length == 0) return response;

      const insertFormData = new FormData();
      const updateFiles: FileInfo[] = [];

      let strFileSortOrds = '';

      files.forEach((fileInfo) => {
        if (fileInfo.crudKey === CrudCode.CREATE && fileInfo.file) {
          insertFormData.append('files', fileInfo.file as Blob);
          strFileSortOrds += `${fileInfo.sortOrd},`;
        } else if (fileInfo.crudKey === CrudCode.UPDATE) {
          updateFiles.push(fileInfo);
        }
      });

      let result: any = null;

      if (insertFormData.has('files')) {
        insertFormData.append('atchFileGrId', atchFileGrId);
        insertFormData.set('fileSortOrds', strFileSortOrds.slice(0, -1));
        insertFormData.append('atchFileTpCd', atchFileTpCd);
        await uploadFiles(insertFormData);
      }

      if (updateFiles.length > 0) {
        result = await modifyFiles(updateFiles);
      }

      if (result || files.length > 0) {
        response.atchFileGrId = files[0].atchFileGrId;
        response.fileSaveResult = FileSaveResult.SUCCESS;
      } else {
        response.fileSaveResult = FileSaveResult.FAIL;
      }

      return response;
    };

    const cancelFile = (atchFileId: string) => {
      const tempFiles: FileInfo[] = [];
      files.map((file) => {
        if (file.atchFileId == atchFileId) {
          if (file.crudKey != CrudCode.CREATE) {
            tempFiles.push({ ...file, useYn: CommonYN.N, sortOrd: '', crudKey: CrudCode.UPDATE });
          }
        } else {
          tempFiles.push(file);
        }
      });
      setFiles(tempFiles);
    };

    const checkFileCount = (isBeforeAdd = true, newFile: any = []) => {
      const fileLength = files.filter((f) => f.useYn !== CommonYN.N).length;

      if (
        (isBeforeAdd && fileLength >= maxCount) ||
        (!isBeforeAdd && newFile.length + fileLength > maxCount)
      ) {
        openCommonModal({
          modalType: 'alert',
          content: t('com.label.00155', '__첨부파일 최대개수를 초과하였습니다.'),
        });

        return false;
      }
      return true;
    };

    const getFileIcon = (fileName: string): JSX.Element => {
      const extension = fileName.split('.').pop()?.toLowerCase();

      switch (extension) {
        case 'txt':
          return <img src={txtIcon} alt="txt icon" />;
        case 'jpg':
          return <img src={jpgIcon} alt="jpg icon" />;
        case 'jpeg':
          return <img src={jpgIcon} alt="jpg icon" />;
        case 'pdf':
          return <img src={pdfIcon} alt="pdf icon" />;
        case 'xls':
          return <img src={excelIcon} alt="excel icon" />;
        case 'xlsx':
          return <img src={excelIcon} alt="excel icon" />;
        case 'img':
          return <img src={imgIcon} alt="img icon" />;
        case 'png':
          return <img src={imgIcon} alt="img icon" />;
        case 'markdown':
          return <img src={markdownIcon} alt="markdown icon" />;
        case 'ppt':
          return <img src={pptIcon} alt="ppt icon" />;
        case 'pptx':
          return <img src={pptIcon} alt="ppt icon" />;
        case 'word':
          return <img src={wordIcon} alt="pdf icon" />;
        case 'zip':
          return <img src={zipIcon} alt="zip icon" />;
        default:
          return <img src={etcIcon} alt="etc icon" />;
      }
    };

    // 파일 개수에 따른 css 제어용도 className 지정
    const [fileCnt, setFileCnt] = useState<number>();

    useEffect(() => {
      setFileCnt(files.filter((file) => file.useYn === CommonYN.Y).length);
    }, [files]);

    const fileClass = fileCnt === 0 ? 'empty' : 'initFile';
    const fileLength =
      fileCnt === 1 ? 'single-file' : fileCnt === 2 ? 'two-files' : 'multiple-files';

    return (
      <Dragdrop
        className={fileClass}
        onDrop={handleAtchFileDrop}
        onDragOver={(e) => {
          e.preventDefault();
          const fileList: any = Array.from(e.dataTransfer.files);

          // if (!checkFileCount(fileList)) return;
        }}
        onClick={handleAtchFileAddClick}
        dragdropHeight={height}
      >
        <Filezone>
          <div style={{ display: 'flex' }}>
            <input
              multiple={isMultipleFile}
              type="file"
              onChange={handleFileChange}
              ref={fileInput}
              hidden
            />
            <Fileuploadericon></Fileuploadericon>
            <Filedropinfo>
              {t('com.label.00688', '__첨부할 파일을 선택하거나 여기에 drag & drop하세요')}
            </Filedropinfo>
            <Inputfile
              type="text"
              placeholder={
                t(
                  'com.label.00688',
                  '__첨부할 파일을 선택하거나 여기에 drag & drop하세요'
                ) as string
              }
              readOnly={true}
            />
          </div>
          <Uploadbite>
            <FileBite isOver={allFileSize > maxSize}>
              {allFileSize >= 1000
                ? `${Math.ceil((allFileSize / 1024) * 10) / 10}KB`
                : `${allFileSize}KB`}
            </FileBite>
            <Bite> / {maxSizeText}</Bite>
          </Uploadbite>
        </Filezone>
        <Dropzonbox>
          {files.map((file, index) => {
            const isLongFilename = file.atchFileNm.length > 15;
            if (!file.crudKey) {
              return (
                <Files
                  className={fileLength}
                  key={index}
                  width={width}
                  isMultipleFile={false}
                  allowFileTypes={[]}
                >
                  <Filenamegroup
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      fileDown(file);
                    }}
                    key={index}
                  >
                    {/* <Icon></Icon> */}
                    {getFileIcon(file.atchFileNm)}
                    {isLongFilename ? (
                      <Tooltip
                        title={file.atchFileNm}
                        placement="bottom"
                        slotProps={{
                          popper: {
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: '#3c3e3d',
                                color: '#fff',
                                maxWidth: 500,
                                fontSize: 12,
                                fontWeight: 300,
                                borderRadius: '2px',
                              },
                            },
                          },
                        }}
                      >
                        <Filename>{file.atchFileNm}</Filename>
                      </Tooltip>
                    ) : (
                      <Filename>{file.atchFileNm}</Filename>
                    )}
                  </Filenamegroup>
                  <Fileproperty>
                    <Filesize>
                      {file.atchFileSize >= 1000
                        ? Math.ceil((file.atchFileSize / 1000) * 10) / 10 + 'KB'
                        : file.atchFileSize + 'KB'}
                    </Filesize>
                    <DelButton
                      onClick={(e) => {
                        e.stopPropagation();
                        cancelFile(file.atchFileId);
                      }}
                      // color="secondary"
                      // variant="outlined"
                      // size="small"
                    />
                  </Fileproperty>
                </Files>
              );
            } else if (file.crudKey !== CrudCode.UPDATE) {
              return (
                <Files
                  className={fileLength}
                  key={index}
                  width={width}
                  isMultipleFile={false}
                  allowFileTypes={[]}
                >
                  <Filenamegroup>
                    {getFileIcon(file.atchFileNm)} {/* 파일 아이콘 */}
                    {isLongFilename ? (
                      <Tooltip
                        title={file.atchFileNm}
                        placement="bottom"
                        slotProps={{
                          popper: {
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: '#3c3e3d',
                                color: '#fff',
                                maxWidth: 500,
                                fontSize: 12,
                                fontWeight: 300,
                                borderRadius: '2px',
                              },
                            },
                          },
                        }}
                      >
                        <Filename>{file.atchFileNm}</Filename>
                      </Tooltip>
                    ) : (
                      <Filename>{file.atchFileNm}</Filename>
                    )}
                  </Filenamegroup>
                  <Fileproperty>
                    <Filesize>
                      {file.atchFileSize >= 1000
                        ? Math.ceil((file.atchFileSize / 1000) * 10) / 10 + 'KB'
                        : file.atchFileSize + 'bytes'}
                    </Filesize>
                    <DelButton
                      onClick={(e) => {
                        e.stopPropagation();
                        cancelFile(file.atchFileId);
                      }}
                    />
                  </Fileproperty>
                </Files>
              );
            }
          })}
        </Dropzonbox>
      </Dragdrop>
    );
  }
);

export default FileUploadZone;

const Dragdrop = styled.div<{ dragdropHeight: string | undefined; status?: string }>`
  /* 파일 있을 때 상태 */
  &.initFile {
    display: block;
    height: auto;
  }
  /* 파일 없을 때 상태 */
  &.empty {
    display: flex;
    align-items: center;
    > div:nth-child(2) {
      padding-right: 0;
    }
  }

  border: ${(props) =>
    props.status === 'error'
      ? `1px solid ${GlobalTokenColor.StatusError300}`
      : `1px dashed ${BorderColor.Form}`};
  padding: 8px;
  border-radius: 2px;
  width: 100%;
  height: ${(props) => props.dragdropHeight || '100%'};
  background-color: #fff;

  :hover {
    background: #f1f4f3;
    cursor: pointer;
  }

  :active {
    border: 1px solid #6e706f;
  }

  :focus {
    border: 1px solid #6e706f;
  }
`;
const Filezone = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 4px;
`;
const Fileuploadericon = styled.span`
  display: flex;
  width: 24px;
  height: 24px;
  background: url(${uploadericon}) no-repeat 0px 0px;
  vertical-align: middle;
  margin-right: 4px;
`;
const Filedropinfo = styled.span`
  display: inline-block;
  height: 24px;
  font-size: 13px;
  font-weight: 400;
  color: #979998;
  vertical-align: middle;
  line-height: 24px;
`;
const Inputfile = styled.input`
  display: none;
`;
const Uploadbite = styled.span`
  position: absolute;
  right: 0;
`;
const FileBite = styled.span<{ isOver: boolean }>`
  color: ${(prop) => (prop.isOver ? '#f94b50' : '#1f1f1f')};
`;

const Bite = styled.span`
  color: #5b5c5b;
`;
const Files = styled.div<FileUploadZoneProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 2px;
  padding: 8px 4px 8px 8px;
  border: 1px solid #dde0df;
  height: 36px;
  background: #ffffff;
  @media (max-width: 1919px) {
    :not(:first-child) {
      margin-top: 2px;
    }
    width: 100% !important;
    max-width: none !important;
  }
  /* 파일 1개일 때 */
  &.single-file {
    width: 100%;
  }
  &.two-files {
    flex: 0 1 49.5%;
  }
  /* 파일이 2개 이상일 때 */
  &.multiple-files {
    min-width: 400px;
    max-width: 700px;
    flex: 0 1 33%;
  }
`;
const Filenamegroup = styled.span`
  display: flex;
  white-space: nowrap;
  color: #1f1f1f;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
  gap: 4px;
`;
const Filesize = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: #979998;
`;
const Filename = styled.p`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 600px;
  @media (min-width: 1920px) {
    width: 300px;
  }
`;
const Fileproperty = styled.div`
  display: flex;
  white-space: nowrap;
  gap: 4px;
`;
const Dropzonbox = styled.div`
  @media (min-width: 1920px) {
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
    &:has(.single-file) {
      padding-right: 0;
    }
    padding-right: 90px;
  }
`;
