import styled from '@emotion/styled';
import { MenuContext } from 'App';
import BookMark from 'components/atom/bookMark';
// import BookMark from 'components/cims/common/bookMark';
import Title from 'components/atom/title';
import { GPopupTitle } from 'components/layouts/GLayoutBox';
import { MenuContextType } from 'models/admin/Menu';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

interface GroupProps {
  selectedShape: 'ball' | 'bookmark';
}

const TitlegroupVars = ({ selectedShape }: GroupProps) => {
  const menuContext = useContext<MenuContextType>(MenuContext);
  const { t } = useTranslation();
  const location = useLocation();
  return (
    <Group selectedShape={'ball'}>
      {selectedShape === 'ball' && <BallIconStyled />}
      {selectedShape === 'bookmark' && <BookMark />}
      <Title title={t(`${menuContext.currentPage.msgCtn}`, `${menuContext.currentPage.mnuNm}`)} />
    </Group>
  );
};

const Group = styled.div<GroupProps>`
  display: flex;
  align-items: center;
  // gap: 4px;
  margin-top: 12px;
  margin-bottom: 4px;
`;

const BallIconStyled = styled.div`
  top: -2px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #2d9bb2;
  margin-right: 4px;
  display: inline-block;
`;

export default TitlegroupVars;
