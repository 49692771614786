import * as React from 'react';
import styled from '@emotion/styled';
import { ReactComponent as ImgMinuse } from 'components/asset/images/imgMinuse.svg';
import UploadPdf from 'components/asset/images/UploadPdf.svg';

interface UploadProps {
  type: 'img' | 'file';
  uploadImg?: string;
}

const UploadImage = (props: UploadProps) => {
  return (
    <>
      <ContentsImg>
        <UploadImg type={props.type} uploadImg={props.uploadImg}>
          <StyledImgMinuse />
        </UploadImg>
        <UploadImg type={'file'} uploadImg={UploadPdf}>
          <StyledImgMinuse />
        </UploadImg>
      </ContentsImg>
    </>
  );
};

const ContentsImg = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;

  width: auto;
  margin: 16px 0;
  gap: 4px;
`;

const StyledImgMinuse = styled(ImgMinuse)`
  margin: 2px 2px 0 87.3%;
  z-index: 1;
`;

const UploadImg = styled.div<UploadProps>`
  position: relative;
  width: 144px;
  height: 96px;
  border-radius: 4px;

  ${(props) =>
    props.type === 'img' &&
    `
    border: 0;
    background: url(${props.uploadImg}) no-repeat;
  `}

  ${(props) =>
    props.type === 'file' &&
    `
    border: 1px solid #dde0df;
    background: url(${props.uploadImg}) no-repeat 56px 32px #f7f9f8;
  `}
`;

export default UploadImage;
