import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { frnrCurList } from 'pages/vars/accountregist/modal/FrgnCurrencyBankModal';
import { callApi } from 'utils/ApiUtil';
import { callJwtApi } from 'utils/JwtApiUtil';

// 외화거래은행 불러오기(ADMIN)
export const getAdminFrnrBankListApi = async (searchParams) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/api/sap-if/ws-sample-test`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({
      I_REQ_DIVS: 'BK',
      i_BANKA: searchParams.banka,
      i_SWIFT: searchParams.swift,
    }),
  };
  const response: CommonResponse<frnrCurList[]> = await callApi(request);
  console.log(response);
  return (response.successOrNot === 'Y' ? response.data : []) as frnrCurList[];
};

// 외화거래은행 불러오기(USER)
export const getUserFrnrBankListApi = async (searchParams) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/api/sec/common/std-info0237`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({
      I_REQ_DIVS: 'BK',
      i_BANKA: searchParams.banka,
      i_SWIFT: searchParams.swift,
      I_LAND: searchParams.iland ?? '',
    }),
  };
  const response: CommonResponse<frnrCurList[]> = await callJwtApi(request);
  console.log(response);
  return (response.successOrNot === 'Y' ? response.data : []) as frnrCurList[];
};
