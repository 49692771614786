/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { findFiles, modifyFiles } from 'apis/admin/FileUpload';
import { FileInfo } from 'models/admin/FileInfo';
import axios from 'axios';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { ContentGrid } from 'components/layouts/ContentGrid';
import { useCommonModal } from 'hooks/useCommonModal';
import { AgGridReact } from 'ag-grid-react';
import { FontColor } from 'ui/theme/Color';
import { css } from '@mui/material';
import { CustomInputText } from 'components/inputs/CustomInput';
import { GreyLineButton } from 'components/buttons/CustomButton';
import { CrudCode } from 'models/common/Edit';
import { Link } from 'react-router-dom';
import useSessionStore from 'stores/useSessionStore';
import { useMessageBar } from 'components/process/MessageBar';

interface FileGridProps {
  atchFileGrId: string;
  showAllDownload?: boolean;
  fileCount?: (count: number) => void;
}

const FileGrid = ({ atchFileGrId, showAllDownload, fileCount }: FileGridProps) => {
  const { t } = useTranslation();
  const { gridNoRowsTemplate } = useSessionStore();

  const { openCommonModal } = useCommonModal();
  const { openMessageBar } = useMessageBar();
  const [files, setFiles] = useState<FileInfo[]>([]);
  const [isLicense, setIsLicense] = useState<boolean>(false);

  const fileColumnDefs: ColDef[] = [
    {
      headerName: t('com.label.00689', '__정렬순번') ?? '',
      field: 'sortOrd',
      width: 120,
      cellRenderer: (params: ICellRendererParams) => (
        <CustomInputText
          id="sortOrd"
          className="fullWidth alignC"
          value={params.data.sortOrd || ''}
          onChange={(e) => {
            handleSortOrdChange(params.data.atchFileId, e.target.value);
          }}
        />
      ),
    },
    {
      headerName: t('com.label.00690', '__파일명') ?? '',
      field: 'ctrcPialDivsNm',
      flex: 1,
      cellRenderer: (params: ICellRendererParams) => (
        <Link
          to="#"
          className="link"
          onClick={async (e) => {
            const fileInfo = {
              atchFileGrId: params.data.atchFileGrId,
              atchFileId: params.data.atchFileId,
              atchFileNm: params.data.atchFileNm,
              optValCtn1: params.data?.optValCtn1 ?? '',
            } as FileInfo;
            handleDownloadFile(fileInfo);
            e.preventDefault();
          }}
        >
          {`${params.data.atchFileNm} [${Math.floor(
            params.data.atchFileSize / 1000
          ).toLocaleString()} KB]`}
        </Link>
      ),
    },
    {
      headerName: 'All Download',
      width: 150,
      headerComponent: () =>
        showAllDownload !== false && isLicense !== true ? (
          <GreyLineButton className="small" onClick={downloadAllFiles}>
            All Download
          </GreyLineButton>
        ) : (
          <></>
        ),
    },
    {
      headerName: 'Save',
      width: 105,
      headerComponent: () => (
        <GreyLineButton className="small" onClick={fileOrderSave}>
          Save
        </GreyLineButton>
      ),
    },
  ];

  const handleSortOrdChange = (key, newValue) => {
    newValue = newValue.replace(/[^-0-9]/g, '');
    setFiles((prev) =>
      prev.map((file) =>
        file.atchFileId === key
          ? {
              ...file,
              crudKey: file.crudKey == CrudCode.CREATE ? CrudCode.CREATE : CrudCode.UPDATE,
              sortOrd: newValue,
            }
          : file
      )
    );
  };

  const downloadAllFiles = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/v1/file/download/all?atchFileGrId=${atchFileGrId}`,
        {
          responseType: 'blob',
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', atchFileGrId + '.zip');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownloadFile = async (file: FileInfo) => {
    try {
      // optValCtn1 값이 있을 경우에만 optValCtn1 경로로 파일 다운로드
      let fileDownloadUrl = `${process.env.REACT_APP_API_BASE_URL}/v1/file/download?atchFileGrId=${file.atchFileGrId}&atchFileId=${file.atchFileId}`;
      if (file?.optValCtn1) {
        fileDownloadUrl = file.optValCtn1;
      }

      const response = await axios.get(fileDownloadUrl, {
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file.atchFileNm);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error);
    }
  };

  const fileOrderSave = async () => {
    const result = await modifyFiles(files);
    if (result) {
      openMessageBar('success', t('com.label.00176', '저장되었습니다.'));
    }
  };

  useEffect(() => {
    if (atchFileGrId) {
      findFiles(atchFileGrId).then((result: FileInfo[]) => {
        setFiles(result);
        fileCount && fileCount(result.length);
        if (null != result[0]?.optValCtn1) setIsLicense(true); // optValCtn1 값이 있으면 showAllDownload 안보이도록
      });
    } else {
      setFiles([]);
      fileCount && fileCount(0);
    }
  }, [atchFileGrId]);

  return (
    <ContentGrid className="ag-theme-alpine" style={{ height: '150px' }}>
      <AgGridReact
        overlayNoRowsTemplate={gridNoRowsTemplate}
        rowData={files || []}
        columnDefs={fileColumnDefs}
      ></AgGridReact>
    </ContentGrid>
  );
};

export default FileGrid;

const style = {
  fileName: () => css`
    &:hover,
    &:focus {
      color: ${FontColor.Primary};
      text-decoration: underline;
    }
  `,
};
