import { PaletteOptions } from '@mui/material';

// main color palette
export enum GlobalTokenColor {
  Primary = '#2d9bb2',
  Secondary = '#554596',
  ESGreyWHite = '#ffffff',
  ESGreyWHiteOp40 = 'rgba(255, 255, 255, 0.4)',
  ESGreyBlack = '#000000',
  Primary50 = '#ecfcf8',
  Primary100 = '#d9fcf4',
  Primary200 = '#8bf0e9',
  Primary300 = '#8bf0e9',
  Primary400 = '#6be1e2',
  Primary500 = '#3ec2cf',
  Primary600 = '#2d9bb2',
  Primary600Op40 = 'rgba(45, 155, 178, 0.4)',
  Primary700 = '#1f7795',
  Primary700Op40 = 'rgba(31, 119, 149, 0.4)',
  Primary800 = '#135678',
  Primary900 = '#0b3e63',
  //
  Secondary50 = '#eeeaf8',
  Secondary100 = '#e6def9',
  Secondary200 = '#cdbff4',
  Secondary300 = '#a897df',
  Secondary400 = '#8372c0',
  Secondary500 = '#554596',
  Secondary500Op40 = 'rgba(85, 69, 150, 0.4)',
  Secondary600 = '#403281',
  Secondary700 = '#2e226c',
  Secondary800 = '#1e1657',
  Secondary900 = '#140d48',
  //
  ESGrey50 = '#f7f9f8',
  ESGrey100 = '#f1f4f3',
  ESGrey200 = '#ebeeed',
  ESGrey300 = '#dde0df',
  ESGrey300Op40 = 'rgba(185, 188, 187, 0.4)',
  ESGrey350 = '#cacccb',
  ESGrey400 = '#b9bcbb',
  Grey400Op40 = 'e3e4e4',
  ESGrey500 = '#979998',
  ESGrey600 = '#6e706f',
  ESGrey700 = '#5b5c5b',
  ESGrey750 = '#464747',
  ESGrey800 = '#3c3e3d',
  ESGrey800Op40 = 'rgba(60, 62, 61, 0.4)',
  ESGrey850 = '#282929',
  ESGrey900 = '#1f1f1f',
  //
  StatusConfirmed50 = '#f1fdf7',
  StatusConfirmed100 = '#dcfbea',
  StatusConfirmed300 = '#56d8aa',
  StatusConfirmed500 = '#00806a',
  StatusConfirmed700 = '#01463a',
  StatusConfirmed900 = '#052923',
  //
  StatusWarningMinor50 = '#fffce9',
  StatusWarningMinor100 = '#fff8cd',
  StatusWarningMinor300 = '#ffe56a',
  StatusWarningMinor500 = '#ffc907',
  StatusWarningMinor700 = '#b78803',
  StatusWarningMinor900 = '#7a5501',
  //
  StatusWarningMajor50 = '#fff7eb',
  StatusWarningMajor100 = '#feefd6',
  StatusWarningMajor300 = '#ffcb7a',
  StatusWarningMajor500 = '#ff9322',
  StatusWarningMajor700 = '#b75511',
  StatusWarningMajor900 = '#7a2b06',
  //
  StatusError50 = '#fef1eb',
  StatusError100 = '#fee6db',
  StatusError300 = '#fda293',
  StatusError500 = '#f94b50',
  StatusError700 = '#b32542',
  StatusError900 = '#770e36',
  //
  StatsuDone50 = '#f7f9f8',
  StatsuDone100 = '#f1f4f3',
  StatsuDone300 = '#dde0df',
  StatsuDone500 = '#979998',
  StatsuDone700 = '#5b5c5b',
  StatsuDone900 = '#1f1f1f',
  //
  DeepBlue100 = '#d8d9f9',
  DeepBlue200 = '#b4b5f3',
  DeepBlue300 = '#8687d8',
  DeepBlue400 = '#5e5fb7',
  DeepBlue500 = '#303188',
  DeepBlue600 = '#232374',
  DeepBlue700 = '#181861',
  DeepBlue800 = '#0f0f4e',
  DeepBlue900 = '#090941',
  //
  Lime100 = '#f6fcd8',
  Lime200 = '#ecfab1',
  Lime300 = '#daf088',
  Lime400 = '#c4e167',
  Lime500 = '#a6ce39',
  Lime600 = '#89b129',
  Lime700 = '#6d941c',
  Lime800 = '#537712',
  Lime900 = '#41620a',
  //
  Pink100 = '#fdd4da',
  Pink200 = '#fca9bf',
  Pink300 = '#f67da9',
  Pink400 = '#ec5b9f',
  Pink500 = '#e12991',
  Pink600 = '#c11d8a',
  Pink700 = '#a21480',
  Pink800 = '#820d72',
  Pink900 = '#6c0768',
  //
  LightBlue100 = '#e3f0ff',
  LightBlue200 = '#c8e0ff',
  LightBlue300 = '#adceff',
  LightBlue400 = '#99bfff',
  LightBlue500 = '#77a5ff',
  LightBlue600 = '#567fdb',
  LightBlue700 = '#3b5db7',
  LightBlue800 = '#253f93',
  LightBlue900 = '#162a7a',
}

// background color
export enum ButtonContainedStyle {
  //
  PrimaryDefault = GlobalTokenColor.Primary700,
  PrimaryHover = GlobalTokenColor.Primary500,
  PrimaryActive = GlobalTokenColor.Primary800,
  PrimaryDisabled = GlobalTokenColor.Primary700Op40,
  //
  NormalDefault = GlobalTokenColor.ESGrey800,
  NormalHover = GlobalTokenColor.ESGrey700,
  NormalActive = GlobalTokenColor.ESGrey900,
  NormalDisabled = GlobalTokenColor.ESGrey800Op40,
}

// outline color
export enum ButtonOutlinedStyle {
  //
  PrimaryDefault = GlobalTokenColor.ESGreyWHite,
  PrimaryHover = GlobalTokenColor.Primary50,
  PrimaryActive = GlobalTokenColor.Primary100,
  PrimaryDisabled = GlobalTokenColor.ESGreyWHiteOp40,
  //
  NormalDefault = GlobalTokenColor.ESGreyWHite,
  NormalHover = GlobalTokenColor.ESGrey100,
  NormalActive = GlobalTokenColor.ESGrey300,
  NormalDisabled = GlobalTokenColor.ESGreyWHiteOp40,
}

export enum FontColor {
  Primary700 = '#0b3e63',
  Primary = '#2D9BB2',
  Default = '#1f1f1f',
  Gray600 = '#b1b1b1',
  Gray500 = '#CCCCCC',
  Gray400 = '#5b5c5b',
  Gray300 = '#6e706f',
  Gray200 = '#979998',
  Gray100 = '#A7A6A2',
  White = '#FFFFFF',
  HighLight = '#00B4CB',
}

// //////////// 이 아래부터는 사용하지 않음
////////////////////////////////////////////////////

export enum BasicColor {
  Primary = '#1f7795',
  Secondary = '#5B5C5B',
  Dark = '#1f1f1f',
  Red = '#f94b50',
  Yellow = '#FF9322',
  Green = '#00806A',
}

export enum BgColor {
  Primary700 = '#1f7795',
  White300 = '#F1EFEB',
  White200 = '#F5F5F5',
  White100 = '#F2F2F2',
  White50 = '#F8F8F8',
  White = '#FFFFFF',
  Gray700 = '#3c3e3d',
  Gray600 = '#D1D1D1',
  Gray300 = '#c1bfbc',
  Gray200 = '#999999',
  Gray100 = '#EEEEEE',
  Gray50 = '#F7F9F8',
  Secondary50 = '#EEEAf8',
  Secondary100 = '#a897df',
  InputBlue50 = '#E8F0FB',
}

export enum BorderColor {
  Primary = '#dde0df',
  Secondary = '#333333',
  Form = '#b9bcbb',
  Third = '#135678',
}

export enum LineColor {
  Primary = '#EAEAEA',
  Secondary = '#D4D3D3',
}

export enum ButtonColor {
  Primary = '#3ec2cf',
  Secondary = '#666666',
  Red = '#a40033',
}

// pivoter ***********************************************************************

export enum Color {
  White = '#FFFFFF',
  Black = '#000000',
  Primary50 = '#ECFCF8',
  Primary100 = '#D9FCF4',
  Primary200 = '#B4FAEF',
  Primary300 = '#8BF0E9',
  Primary300Op40 = '#bbefdd',
  Primary400 = '#6BE1E2',
  Primary500 = '#3EC2CF',
  Primary600 = '#2D9BB2',
  Primary700 = '#1F7795',
  Primary800 = '#135678',
  Primary900 = '#0B3E63',
  Primary700Op40 = 'rgba(31, 119, 149, 0.4)',
  // secondary
  Secondary50 = '#EEEAF8',
  Secondary100 = '#E6DEF9',
  Secondary200 = '#CDBFF4',
  Secondary300 = '#A897DF',
  Secondary400 = '#8372C0',
  Secondary500 = '#554596',
  Secondary600 = '#403281',
  Secondary700 = '#2E226C',
  Secondary800 = '#1E1657',
  Secondary900 = '#140D48',
  // /secondary
  Grey50 = '#F7F9F8',
  Grey100 = '#F1F4F3',
  Grey200 = '#EBEEED',
  Grey300 = '#DDE0DF',
  Grey400 = '#B9BCBB',
  Grey400Op40 = 'e3e4e4',
  Grey500 = '#979998',
  Grey600 = '#6E706F',
  Grey700 = '#5B5C5B',
  Grey800 = '#3C3E3D',
  Grey800Op40 = 'rgba(60, 62, 61, 0.4)',
  Grey900 = '#1F1F1F',
  DarkGrey50 = '#F4F4F4',
  DarkGrey100 = '#DDDDDD',
  DarkGrey200 = '#CACACA',
  DarkGrey300 = '#ABABAB',
  DarkGrey400 = '#939393',
  DarkGrey500 = '#565656',
  DarkGrey600 = '#424242',
  DarkGrey700 = '#353535',
  DarkGrey800 = '#282929',
  DarkGrey900 = '#1E1E1E',
  StatusConfirmed100 = '#DCFBEA',
  StatusConfirmed300 = '#56D8AA',
  StatusConfirmed500 = '#00806A',
  StatusConfirmed700 = '#01463A',
  StatusConfirmed900 = '#052923',
  StatusWarningMinor100 = '#FFF8CD',
  StatusWarningMinor300 = '#FFE56A',
  StatusWarningMinor500 = '#FFC907',
  StatusWarningMinor700 = '#B78803',
  StatusWarningMinor900 = '#7A5501',
  StatusWarningMajor100 = '#FEEFD6',
  StatusWarningMajor300 = '#FFCB7A',
  StatusWarningMajor500 = '#FF9322',
  StatusWarningMajor700 = '#B75511',
  StatusWarningMajor900 = '#7A2B06',
  StatusError100 = '#FEE6DB',
  StatusError300 = '#FDA293',
  StatusError500 = '#F94B50',
  StatusError700 = '#B32542',
  StatusError900 = '#770E36',
  StatusDone100 = '#F1F4F3',
  StatusDone300 = '#DDE0DF',
  StatusDone500 = '#979998',
  StatusDone700 = '#5B5C5B',
  StatusDone900 = '#1F1F1F',
}

// /pivoter ***********************************************************************

export const basicPalette: PaletteOptions = {
  primary: {
    main: BasicColor.Primary,
  },
  secondary: {
    main: BasicColor.Secondary,
  },
};
