import React from 'react';
import styled from '@emotion/styled';

const PrivacyZhc = () => {
  return (
    <PrivacyDiv>
      <div className="privacy">
        <div className="title_header">隐私政策</div>
        <div className="article_header">
          LG Energy Solution,
          Ltd.(以下简称“公司”、或“我们”)关注您的隐私。本隐私政策将解释当您使用我们的“网站”以下简称“服务”支持LG
          Energy解决方案产品时我们的组织如何使用从您那里收集的个人数据。
          <br />
          在处理个人数据时保护您的隐私对我们很重要。因此我们根据相关数据保护法规处理您的个人数据。
        </div>
        <div className="box">
          <div className="title">■ 收集的个人信息项目和处理目的、保留期</div>
          <div className="article">
            公司收集和使用个人信息仅限于指定用途。
            <br />
            公司遵守相关法律法规，获得个人同意后，仅在业务合作存续期限内处理和保留用户的个人信息。
          </div>
          <table>
            <thead>
              <tr>
                <th>类别</th>
                <th>用途</th>
                <th colSpan={2}>收集项目</th>
                <th>保留和使用期限</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>会员管理</td>
                <td>系统会员注册与注销</td>
                <th>所需信息</th>
                <td>
                  密码、地址 (如果是公司,则为公司地址)。 公司所在国家。
                  <br />
                  <br />□ 业务负责人: 姓名,邮箱,公司名称
                </td>
                <td rowSpan={2} style={{ color: 'blue', fontWeight: 'bold' }}>
                  持续至业务关系结束或提出特殊的删除请求
                </td>
              </tr>
              <tr>
                <td>个人供应商</td>
                <td>账户认证</td>
                <th>所需信息</th>
                <td>银行名称 账户名称 开户日期 账号</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="box">
          <div className="title">■ 向第三方提供个人信息</div>
          <div className="article">
            未经用户同意本公司不会向外部或第三方提供用户的个人信息。但是以下情况可能会在未经用户事先同意的情况下向第三方提供个人信息。
            <br />
            <br />
            -如果为了遵守特殊规定或法定义务而不可避免地提供信息。
            <br />
            -如果信息主体或其法人由于地址不明等原因无法表达自己的意图或相关的内容，为保护生命、身体和财产安全而不可避免的提供信息。
            <br />
            -如果法律或调查机构根据相关法律出于调查目的要求提供信息。
            <br />
            <br />
            如果您不居住在韩国（中国）则您的信息可能会传递到您所在的国家/地区以外。
          </div>
        </div>
        <div className="box">
          <div className="title">■ 个人信息处理委托</div>
          <div className="article">
            公司委托下面LG关联公司进行个人信息数据处理以保证业务顺利进行
          </div>
          <table>
            <thead>
              <tr>
                <th>受托方</th>
                <th>委托项目</th>
                <th>保留和使用期限</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="tl_center">
                  LG CNS
                  <br />
                  (二次委托:Biztech i)
                </td>
                <td>服务运营、系统运营、系统主页运营管理、系统维护等</td>
                <td>直到与公司的持续业务关系结束或直到委托合同结束</td>
              </tr>
              <tr>
                <td className="tl_center">
                  21家银行
                  <br />
                  (Woori, Kookmin, Shinhan, SC, KEB, Hana, Nonghyup, Citi, IBK, Kyongnam, Daegu,
                  <br />
                  Busan, Kwangju, Korea Development, National Federation of Fisheries, Korea
                  <br />
                  Eximbank, Jeju, Jeonbuk, Shinhyeop, Koreapost, Kakao, Deutsche)
                </td>
                <td>账户认证</td>
                <td>直到与公司的持续业务关系结束或直到委托合同结束</td>
              </tr>
            </tbody>
          </table>
          <div className="article">
            为确保受托方安全处理个人信息，公司签订委托合同，需明示相关内容：
            <br />
            ①除委托项目外，禁止受托方使用个人信息。
            <br />
            ②为禁止受托方再委托而制定的相关技术管理方案和保护措施。
            <br />
            ③ 依据相关法律法规，受托方由于处理个人信息不当而导致的赔偿等责任。
            <br />
            <br />
            如果委托公司或委托项目发生任何变化,公司将立即在公开平台上发布变更信息。
          </div>
        </div>
        <div className="box">
          <div className="title">■ 个人信息的销毁</div>
          <div className="article">
            业务合作终止后，公司应该立即销毁个人信息。但经过同意或按照法律法规必须保留的相关信息则可移动至单独的数据库或其他地方保存。
            <br />
            <br />
            公司个人信息销毁程序和方法如下：
            <br />
            ①销毁程序
            <br />
            用户的个人信息在使用完毕后立即删除。如上所述当需要保留个人信息时将其移动到单独的数据库或其他地方并在法定期限内存储,到期后立即销毁。
            <br />
            <br />
            ②销毁方法
            <br />
            电子存储：直接销毁确保无法复制。纸质文件：粉碎或焚烧
          </div>
        </div>
        <div className="box">
          <div className="title">■ Cookies和其他跟踪技术</div>
          <div className="article">公司储存使用者的使用信息，不使用随时可查询的cookie。</div>
        </div>
        <div className="box">
          <div className="title">■ 安全</div>
          <div className="article">
            我们遵循行业标准以保护您的个人信息免遭未经授权的访问或披露、更改或破坏。我们的保护措施包括合理的管理、物理和技术保障。
            <br />
            但我们不会收集或存储敏感的个人信息。电子邮件或其他互联网通信,短信或其他方式的信息传输方式并不安全，因为信息在传输时可能会被拦截。
            <br />
            1. 内部管理：定期员工进行专项培训。
            <br />
            2. 技术措施：差异化权限管理，访问控制。
            <br />
            3. 物理措施：机房、数据存储室等的访问控制。
          </div>
        </div>
        <div className="box">
          <div className="title">■ 用户和法定代表人的权利、义务和行使方法</div>
          <div className="article">
            用户及其法定代表人可就个人信息处理行使以下权利。
            <br />
            <br />
            ①提供个人信息的用户可以随时向公司行使以下权利
            <br />
            - 查看个人信息
            <br />
            - 更正错误信息
            <br />
            - 删除信息请求
            <br />
            - 使用停止请求
            <br />
            <br />
            ※14岁（中国：18岁）以下儿童的个人信息阅览等要求只能是法定代理人本人执行，14岁以上未成年人（中国：18岁）的个人信息通过本人或者法定代理人执行权利。
            <br />
            <br />
            ②可以通过书面、电话、电子邮件等方式向公司行使第①条下的权利，公司会立即执行。
            <br />
            <br />
            ③第①条规定的权利行使可以通过代理人完成。例如用户的法定代表或被委托的人，但在这种情况下您必须根据个人信息保护法执行规则的附录
            11 提交委托书。
            <br />
            <br />
            ④用户要求查看和处理个人信息的权利需遵守《个人信息保护法》等相关法规。
            <br />
            <br />
            ⑤如果个人信息在其他法律法规中被指定为收集目标则不能请求更正和删除个人信息。
            <br />
            <br />
            ⑥如果需要收集14岁（中国：18岁）以下儿童的个人信息需得到其法定代理人的同意。
          </div>
        </div>
        <div className="box">
          <div className="title">■ 联系我们</div>
          <div className="article">
            公司已指定对应安全部门全面负责个人信息保护，数据主体提出的关于个人信息的投诉和损害赔偿。
            <br />
            用户可以就使用公司服务时可能出现的个人信息保护、投诉处理或损害赔偿等事宜向相关部门和负责人提出咨询。
            <br />
            公司将根据法律要求回复用户的查询，解决用户的诉求。
          </div>
          <div className="flx">
            <table className="flx_30">
              <tr>
                <th colSpan={3}>客户查询服务部</th>
              </tr>
              <tr>
                <th rowSpan={2}>LG Energy Solution, LTD.</th>
                <th>部门</th>
                <td>자금팀</td>
              </tr>
              <tr>
                <th>电子邮件</th>
                <td>vars_hq@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>LG Energy Solution Australia Pty Ltd</th>
                <th>部门</th>
                <td>Admin Part</td>
              </tr>
              <tr>
                <th>电子邮件</th>
                <td>vars_au@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>NEXTSTAR ENERGY INC.</th>
                <th>部门</th>
                <td>Finance Team</td>
              </tr>
              <tr>
                <th>电子邮件</th>
                <td>vars_st@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>LG Energy Solution (Nanjing) Co., Ltd.</th>
                <th>部门</th>
                <td>LGESNJ.China Manage.SSC banking T.fund P</td>
              </tr>
              <tr>
                <th>电子邮件</th>
                <td>vars_nj_na@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>LG Energy Solution Technology (Nanjing) Co., Ltd.</th>
                <th>部门</th>
                <td>LGESNJ.China Manage.SSC banking T.fund P</td>
              </tr>
              <tr>
                <th>电子邮件</th>
                <td>vars_nb@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>LG Energy Solution Battery (Nanjing) Co., Ltd.</th>
                <th>部门</th>
                <td>LGESNJ.China Manage.SSC banking T.fund P</td>
              </tr>
              <tr>
                <th>电子邮件</th>
                <td>vars_nj_na@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>LG Energy Solution Europe GmbH</th>
                <th>部门</th>
                <td>Business Management Team</td>
              </tr>
              <tr>
                <th>电子邮件</th>
                <td>vars_eg@lgensol.com</td>
              </tr>
              <tr>
                {/* <th rowSpan={2}>PT. HKML Battery Indonesia</th> */}
                <th rowSpan={2}>PT HLI Green Power</th>
                <th>部门</th>
                <td>Treasury Team</td>
              </tr>
              <tr>
                <th>电子邮件</th>
                <td>vars_hm@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>LG ENERGY SOLUTION INDIA PRIVATE LIMITED</th>
                <th>部门</th>
                <td>Account Team</td>
              </tr>
              <tr>
                <th>电子邮件</th>
                <td>vars_il@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>LG Energy Solution Japan Co., Ltd.</th>
                <th>部门</th>
                <td>Management Part</td>
              </tr>
              <tr>
                <th>电子邮件</th>
                <td>vars_jp@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>LG Energy Solution Wroclaw sp. z o.o.</th>
                <th>部门</th>
                <td>Biz Mgt.Finance&Accounting Team</td>
              </tr>
              <tr>
                <th>电子邮件</th>
                <td>vars_wa@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>LG Energy Solution (Taiwan), Ltd</th>
                <th>部门</th>
                <td>Business Administration Part</td>
              </tr>
              <tr>
                <th>电子邮件</th>
                <td>vars_tw@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>Ultium holdings LLC</th>
                <th>部门</th>
                <td>Treasury Team</td>
              </tr>
              <tr>
                <th>电子邮件</th>
                <td>vars_gm@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>HL-GA Battery Company LLC</th>
                <th>部门</th>
                <td>Treasury Team</td>
              </tr>
              <tr>
                <th>电子邮件</th>
                <td>vars_hg@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>Ultium Cells LLC</th>
                <th>部门</th>
                <td>Treasury Team</td>
              </tr>
              <tr>
                <th>电子邮件</th>
                <td>vars_gm@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>LG ENERGY SOLUTION ARIZONA ESS, Inc.</th>
                <th>部门</th>
                <td>Treasury Team</td>
              </tr>
              <tr>
                <th>电子邮件</th>
                <td>vars_ae@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>LG Energy Solution Michigan, Inc.</th>
                <th>部门</th>
                <td>Admin Team.Treasury P</td>
              </tr>
              <tr>
                <th>电子邮件</th>
                <td>vars_mi@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>LG ENERGY SOLUTION ARIZONA, INC.</th>
                <th>部门</th>
                <td>Treasury Team</td>
              </tr>
              <tr>
                <th>电子邮件</th>
                <td>vars_az@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>LG Energy Solution Vertech, Inc</th>
                <th>部门</th>
                <td>Finance & Accounting</td>
              </tr>
              <tr>
                <th>电子邮件</th>
                <td>vars_vt@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>L-H Battery Company, Incorporated</th>
                <th>部门</th>
                <td>Finance Team</td>
              </tr>
              <tr>
                <th>电子邮件</th>
                <td>vars_hd@lgensol.com</td>
              </tr>
            </table>
            <table style={{ height: '80px' }}>
              <tr>
                <th colSpan={2}>个人信息保护管理部</th>
              </tr>
              <tr>
                <th>首席隐私官CPO</th>
                <td className="tl_center">Jongguk Lee</td>
              </tr>
              <tr>
                <th>信息保护管理部</th>
                <td className="tl_center">
                  战略安全Team
                  <br />
                  (privacy.es@lgensol.com)
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div className="box">
          <div className="title">■ 关于个人信息侵权</div>
          <div className="article">
            您可以联系以下机构就用户个人信息侵权进行报告、损害赔偿和咨询。
            <br />
            <br />
            -个人信息纠纷调解委员会 (http://www.privacy.go.kr, 1833-6972)
            <br />
            -个人信息违规报告中心 (http://privacy.kisa.or.kr 电话118)
            <br />
            -最高检察院网络调查部 (http://www.spo.go.kr 电话1301)
            <br />
            -电子网络犯罪报告和管理系统(https://ecrm.cyber.go.kr 电话182)
          </div>
        </div>
        <div className="box">
          <div className="title">■ 隐私政策的变更</div>
          <div className="article">
            公司定期审查其隐私政策并在本网页上发布任何更新。本隐私政策最后更新于[2024. 04. 15]
          </div>
        </div>
      </div>
    </PrivacyDiv>
  );
};

export default PrivacyZhc;

const PrivacyDiv = styled.div`
  body {
    box-sizing: border-box;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  /* privacy */
  .privacy {
    box-sizing: border-box;
    margin: 0;
    font-family: 'Spoqa Han Sans Neo', '맑은 고딕', 'malgun gothic', AppleGothicNeoSD,
      'Apple SD 산돌고딕 Neo', 'Microsoft NeoGothic', 'Droid sans', sans-serif;
    font-size: 13px;
    color: #1f1f1f;
    padding: 20px;
    line-height: 20px;
  }

  .privacy .tl_center {
    text-align: center;
  }
  .privacy .tl_left {
    text-align: left;
  }
  .privacy .tl_right {
    text-align: right;
  }

  .privacy .title_header {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    padding: 10px;
  }

  .privacy .article_header {
    padding: 10px;
  }

  .privacy .box {
    padding: 10px;
  }

  .privacy .box .title {
    font-size: 14px;
    font-weight: 600;
    padding: 10px 0;
  }

  .privacy .box .article {
    font-size: 13px;
    font-weight: 400;
  }

  .privacy .box table {
    border-collapse: collapse;
    margin: 10px 0px;
  }

  .privacy .box table th,
  .privacy .box table td {
    border: 1px solid #dde0df;
    padding: 5px 10px;
  }

  .privacy .box table th {
    background: #f1f4f3;
    font-weight: 600;
  }

  .privacy .box table td {
    vertical-align: top;
  }

  .privacy .flx {
    display: flex;
    justify-content: space-around;
    gap: 20px;
  }

  /* privacy_pop */
  .privacy_pop {
    box-sizing: border-box;
    margin: 0;
    font-family: 'Spoqa Han Sans Neo', '맑은 고딕', 'malgun gothic', AppleGothicNeoSD,
      'Apple SD 산돌고딕 Neo', 'Microsoft NeoGothic', 'Droid sans', sans-serif;
    font-size: 13px;
    color: #1f1f1f;
    padding: 20px;
    line-height: 20px;
  }
  .privacy_pop .title_header {
    font-size: 16px;
    font-weight: 600;
    padding: 10px;
  }

  .privacy_pop .article_header {
    padding: 10px;
  }

  .privacy_pop .scrollbox {
    height: 500px;
    border: 1px solid #dde0df;
    overflow-y: scroll;
  }

  .privacy_pop .tl_right {
    padding: 10px;
    text-align: right;
  }

  .privacy_pop .input {
    vertical-align: bottom;
  }
`;
