import { ColDef } from 'ag-grid-community';
import { DefaultCellTooltip } from 'components/grids/tooltip/DefaultCellTooltip';
import { addCommas } from 'components/vars/common/FormatNumber';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useKPaymentAdColDefs = () => {
  const { t } = useTranslation();
  const defaultColDef = useMemo((): ColDef => {
    return {
      sortable: false,
      resizable: true,
      cellStyle: { textAlign: 'center' },
      tooltipComponent: DefaultCellTooltip,
    };
  }, []);

  const colDefs: any = [
    {
      headerName: t('com.label.00117', '__구분'),
      headerTooltip: t('com.label.00117', '__구분'),
      field: 'payDivNm',
      tooltipField: 'payDivNm',
      maxWidth: 200,
    },
    {
      headerName: t('com.label.00113', '__지급일자'),
      headerTooltip: t('com.label.00113', '__지급일자'),
      field: 'payDt',
      tooltipField: 'payDt',
      flex: 1,
    },
    {
      headerName: t('com.label.00118', '__금액'),
      headerTooltip: t('com.label.00118', '__금액'),
      field: 'payAmt',
      tooltipField: 'payAmt',
      cellStyle: { textAlign: 'right' },
      valueFormatter: (params) => addCommas(params.value),
      maxWidth: 280,
    },
    {
      headerName: t('com.label.00119', '__지급은행'),
      headerTooltip: t('com.label.00119', '__지급은행'),
      field: 'payBankNm',
      tooltipField: 'payBankNm',
      cellStyle: { textAlign: 'center' },
      flex: 1,
    },
    {
      headerName: t('com.label.00070', '__계좌번호'),
      headerTooltip: t('com.label.00070', '__계좌번호'),
      field: 'accnNo',
      tooltipField: 'accnNo',
      flex: 1,
    },
    // {
    //   headerName: t('com.label.00120', '__지급내역상세'),
    //   headerTooltip: t('com.label.00120', '__지급내역상세'),
    //   // 해당 컬럼 확인 필요(데이터)
    //   field: 'column6',
    //   tooltipField: 'column6',
    //   cellStyle: { textAlign: 'left ' },
    //   maxWidth: 280,
    // },
  ];

  const handleAprvDetailBtn = (data) => {
    console.log('화면 연결이 필요합니다.');
  };

  return { defaultColDef, colDefs };
};
