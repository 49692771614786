import React, { useState } from 'react';
import readExcelFile from 'utils/ExcelUtil';

const ExcelReaderSample = () => {
  const [parsedData, setParsedData] = useState<any[][]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (!file) {
      return;
    }

    readExcelFile(file)
      .then((data) => {
        setParsedData(data);
        setErrorMessage('');
      })
      .catch((error) => {
        setParsedData([]);
        setErrorMessage(error);
      });
  };

  return (
    <div>
      <input type="file" onChange={handleFileInputChange} />
      {errorMessage && <p>{errorMessage}</p>}
      {parsedData.length > 0 && (
        <table>
          <thead>
            <tr>
              {parsedData[0].map((cell, index) => (
                <th key={index}>{cell}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {parsedData.slice(1).map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ExcelReaderSample;
