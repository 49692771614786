import { useEffect, useMemo, useState } from 'react';
import { array, bool, boolean, number, object, string } from 'yup';
import { useTranslation } from 'react-i18next';

const useMemberReset = () => {
  const { t } = useTranslation();

  //  userId: '', // VendorID
  //  taxId: '', // TAX ID
  //  accnNo: '', // 기존 계좌번호

  const regSchema = useMemo(() => {
    return object({
      userId: string().required(
        t('com.label.00405', '__Vendor ID는 필수 입력 항목입니다') as string
      ),
      cfrmUserId: boolean().isTrue(
        t('com.label.00425', '__Vendor ID 확인이 필요합니다.') as string
      ),
      taxId: string().required(t('com.label.00713', '__TAX ID는 필수 입력 항목입니다.') as string),
      accnNo: string().required(
        t('com.label.00714', '__기존 계좌번호는 필수 입력 항목입니다.') as string
      ),
    });
  }, []);

  return {
    regSchema,
  };
};

export default useMemberReset;
