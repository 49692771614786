import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import styled from '@emotion/styled';
import Attatch from 'components/asset/images/Attatch.svg';
import ProfileImg from 'components/asset/images/userIcon.svg';
import EditProfile from 'components/molecule/Profile';

interface Data {
  id: number;
  order: number;
  attach: boolean;
  title: string;
  registrant: string;
  date: string;
}

function createData(
  id: number,
  order: number,
  attach: boolean,
  title: string,
  registrant: string,
  date: string
): Data {
  return {
    id,
    order,
    attach,
    title,
    registrant,
    date,
  };
}

const rows = [
  createData(
    1,
    1,
    true,
    '보험관리시스템 사용 메뉴얼 공지보험관리시스템 사용 메뉴얼 공지보험관리시스템 사용 메뉴얼 공지보험관리시스템 사용 메뉴얼 공지보험관리시스템 사용 메뉴얼 공지보험관리시스템 사용 메뉴얼 공지보험관리시스템 사용 메뉴얼 공지보험관리시스템 사용 메뉴얼 공지',
    '홍헨솔',
    '2023.12.26'
  ),
  createData(2, 2, true, '보험관리시스템 사용 메뉴얼 공지', '홍헨솔', '2023.12.26'),
  createData(3, 3, true, '보험관리시스템 사용 메뉴얼 공지', '홍헨솔', '2023.12.26'),
  createData(4, 4, true, '보험관리시스템 사용 메뉴얼 공지', '홍헨솔', '2023.12.26'),
  createData(5, 5, false, 'How to solve GSCM Connection Failure ?', '홍헨솔', '2023.12.26'),
  createData(6, 6, false, 'test', '홍헨솔', '2023.12.26'),
  createData(7, 7, false, 'test', '홍헨솔', '2023.12.26'),
  createData(8, 8, false, 'test', '홍헨솔', '2023.12.26'),
  createData(9, 9, false, 'test', '홍헨솔', '2023.12.26'),
  createData(10, 10, true, 'test', '홍헨솔', '2023.12.26'),
  createData(11, 11, false, 'test', '홍헨솔', '2023.12.26'),
  createData(12, 12, false, 'test', '홍헨솔', '2023.12.26'),
  createData(13, 13, false, 'test', '홍헨솔', '2023.12.26'),
  createData(14, 14, true, 'test', '홍헨솔', '2023.12.26'),
  createData(15, 15, false, 'test', '홍헨솔', '2023.12.26'),
];

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'order',
    disablePadding: false,
    label: 'NO',
  },
  {
    id: 'attach',
    disablePadding: false,
    label: '첨부',
  },
  {
    id: 'title',
    disablePadding: false,
    label: '제목',
  },
  {
    id: 'registrant',
    disablePadding: false,
    label: '게시자',
  },
  {
    id: 'date',
    disablePadding: false,
    label: '게시일',
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, numSelected, rowCount } = props;

  return (
    <TableHead>
      <TableRow>
        <TableThCells padding="checkbox">
          <TableCheckBox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableThCells>
        {headCells.map((headCell) => (
          <TableThCells
            sx={{ width: '20%' }}
            key={headCell.id}
            align={'center'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {headCell.label}
          </TableThCells>
        ))}
      </TableRow>
    </TableHead>
  );
}

const QnaTable = () => {
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  return (
    <Box sx={{ width: '100%', height: '615px' }}>
      <TableContainer>
        <Table aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'}>
          <colgroup>
            <col style={{ width: '45px' }} />
            <col style={{ width: '64px' }} />
            <col style={{ width: '80px' }} />
            <col style={{ width: '70%' }} />
            <col style={{ width: '150px' }} />
            <col style={{ width: '120px' }} />
          </colgroup>
          <EnhancedTableHead
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={rows.length}
          />
          <TableBody>
            {rows.map((row, index) => {
              const isItemSelected = isSelected(Number(row.id));
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRows
                  hover
                  onClick={(event) => handleClick(event, Number(row.id))}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={Number(row.id)}
                  selected={isItemSelected}
                  sx={{ cursor: 'pointer' }}
                >
                  <TableTdCells padding="checkbox">
                    <TableCheckBox
                      color="primary"
                      checked={isItemSelected}
                      inputProps={{
                        'aria-labelledby': labelId,
                      }}
                    />
                  </TableTdCells>
                  <TableTdCells
                    align={'center'}
                    component="th"
                    id={labelId}
                    scope="row"
                    padding="none"
                  >
                    {row.order}
                  </TableTdCells>
                  <TableTdCells align={'center'}>
                    {row.attach ? <Img src={Attatch} /> : null}
                  </TableTdCells>
                  <TableTdCells align={'left'}>
                    <TitleText href="#">{row.title}</TitleText>
                  </TableTdCells>
                  <TableTdCells align={'center'}>
                    <EditProfile profileImg={ProfileImg} nickName="김엔솔" />
                  </TableTdCells>
                  <TableTdCells align={'center'}>{row.date}</TableTdCells>
                </TableRows>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const TableThCells = styled(TableCell)`
  font-size: 13px;
  background-color: rgba(241, 244, 243, 1);
  padding: 0 16px;
`;

const TableTdCells = styled(TableCell)`
  padding: 0 16px;
  border-bottom: 1px solid #f1f4f3;
  &.MuiTableCell-root {
    height: 36px;
    font-size: 13px;
  }
`;

const TableRows = styled(TableRow)`
  &.MuiTableRow-root {
    height: 36px;
  }

  &.MuiTableRow-hover:hover {
    background-color: #eeeaf8;
  }

  &.MuiTableRow-root.Mui-selected {
    background-color: #eeeaf8;
  }
`;

const TableCheckBox = styled(Checkbox)`
  &.MuiCheckbox-indeterminate,
  &.Mui-checked {
    color: #554596;
  }
`;

const Img = styled.img`
  width: 20px;
  height: 20px;
`;

const TitleText = styled.a`
  display: inline-block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default QnaTable;
