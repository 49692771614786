import { create } from 'zustand';
import styled from '@emotion/styled';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertConfirmed from 'components/asset/images/AlertConfirmed.svg';
import AlertError from 'components/asset/images/AlertError.svg';
import AlertClose from 'components/asset/images/AlertCloseButton.svg';

interface MessageBarStore extends SnackbarOrigin {
  isOpen: boolean;
  type: 'success' | 'error' | undefined;
  msg: string;
  openMessageBar: (t: 'success' | 'normal' | 'confirm' | 'warning' | 'error', msg: string) => void;
  closeMessageBar: () => void;
}

export const useMessageBar = create<MessageBarStore>((set, get) => {
  return {
    isOpen: false,
    vertical: 'bottom',
    horizontal: 'center',
    type: 'success',
    msg: '',
    openMessageBar: (type, msg) => {
      const messageBarType = type === 'warning' || type === 'error' ? 'error' : 'success';

      set({ isOpen: true, type: messageBarType, msg: msg });
    },
    closeMessageBar: () => {
      set({ isOpen: false });
    },
  };
});

export const MessageBar = () => {
  const { isOpen, vertical, horizontal, type, msg, closeMessageBar } = useMessageBar();

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={isOpen}
      onClose={() => {
        closeMessageBar();
      }}
      ClickAwayListenerProps={{ onClickAway: () => null }}
      autoHideDuration={4000}
    >
      <AlertBox
        severity={type}
        action={
          <Button
            onClick={() => {
              closeMessageBar();
            }}
          />
        }
        iconMapping={{
          success: <img src={AlertConfirmed} />,
          error: <img src={AlertError} />,
        }}
      >
        {msg}
      </AlertBox>
    </Snackbar>
  );
};

const AlertBox = styled(Alert)`
  padding: 10.5px 16px;
  display: inline-flex;

  & .MuiAlert-icon {
    padding: 0;
    margin-right: 8px;

    > img {
      width: 20px;
      height: 20px;
    }
  }

  &.MuiAlert-standardError {
    background-color: #fee6db;
  }

  &.MuiAlert-standardSuccess {
    background-color: #dcfbea;
  }

  & .MuiAlert-message {
    color: #1f1f1f;
    font-weight: 400;
    font-size: 13px;
    padding: 0;
    word-break: break-all;
    word-wrap: break-word;
    max-width: 450px;
  }

  & .MuiAlert-action {
    padding: 0;
    margin: 0 0 0 8px;
    height: 20px;
  }
`;

const Button = styled.button`
  background-color: transparent;
  width: 20px;
  height: 20px;

  ::before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url(${AlertClose}) center center no-repeat;
  }
`;
