import styled from '@emotion/styled';
import { Link, useNavigate } from 'react-router-dom';
import { MenuContextType } from 'models/admin/Menu';
import { MenuContext } from 'App';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import useSessionStore from 'stores/useSessionStore';
import { authLogOut } from 'apis/vars/login/Login';
import useAuthStore from 'stores/useAuthStore';
import { CommonAccessCode } from 'models/vars/common/Common';
const Logo = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const menuContext = useContext<MenuContextType>(MenuContext);
  const { accessCode } = useAuthStore();
  const { userId, langCd } = useSessionStore();

  const handleMenuBar = () => {
    menuContext.handleMenuChange({ ...menuContext, selectedHeaderMenu: '' });
    if (accessCode == CommonAccessCode.EXTERNAL) {
      //TODO 임시 로그아웃 처리
      authLogOut();
    }
    if (userId) {
      //TODO 임시 로그아웃 처리
      authLogOut();
    }

    navigate('');
  };

  return (
    <LogoWrap to={'#'}>
      <Title>VARS</Title>
      <SubTitleWrap>
        {langCd === 'ko' || !langCd ? (
          <>
            <KoSubTitle>{t('지급계좌등록시스템', '지급계좌등록시스템')}</KoSubTitle>
            <EnSubTitle>Vendor Account Registration System</EnSubTitle>
          </>
        ) : (
          <>
            <KoSubTitle>Vendor Account Registration System</KoSubTitle>
            <EnSubTitle>{t('지급계좌등록시스템', '지급계좌등록시스템')}</EnSubTitle>
          </>
        )}
      </SubTitleWrap>
    </LogoWrap>
  );
};

const LogoWrap = styled(Link)`
  height: 51px;
  margin: 0 12px;

  display: flex;
  align-items: center;

  color: #135678;
  cursor: pointer;
`;

const Title = styled.h1`
  padding-right: 10px;
  border-right: 1px solid #b9bcbb;

  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
`;

const SubTitleWrap = styled.div`
  max-width: 300px;
  padding-left: 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const KoSubTitle = styled.span`
  line-height: 18px;
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
`;

const EnSubTitle = styled(KoSubTitle)`
  color: #6e706f;
  font-size: 12px;
  font-weight: 300;
`;

export default Logo;
