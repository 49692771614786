import { GLabelAtom } from 'components/atom/label';
import SearchMolecule, { SearchWrap } from 'components/molecule/SearchMolecule';
import { GbasicTable, GbasicTableWrap } from 'components/tables/GTable';
import InputField from 'components/atom/input';
import { GButton } from 'components/atom/button';
import img from 'components/asset/images/Delete.svg';
import edit from 'components/asset/images/Pen.svg';
import { GButtonBox } from 'components/layouts/GLayoutBox';
import { CommonCode } from 'models/admin/CommonCode';
import { useEffect, useRef, useState } from 'react';
import ChipSelectBox from 'components/atom/chipSelect';
import SubTitleBox from 'components/molecule/SubTitleBox';
import GButtonLayout from 'components/molecule/GbuttonLayout';
import React from 'react';
import { getSamples } from 'apis/sample/SampleApi';
import { useColumnDetailDefs } from './gridDef/DomesticColumnDefs';
import { useColumnHistoryDefs } from './gridDef/DomesticHistoryColumnDefs';
import { SelectChangeEvent } from '@mui/material';
import { SampleResponse } from 'models/sample/Sample';
import { useLoading } from 'components/process/Loading';
import { useMessageBar } from 'components/process/MessageBar';
import { SubTitleBtnArea } from 'components/organism/SubTitleBtnArea';
import GTabs from 'components/organism/GTabs';
import { GContentGrid } from 'components/layouts/GContentGrid';
import confirm from 'components/asset/images/Confirm.svg';
import { AlignBox, AlignItem } from 'components/organism/AlignBox';
import { AgGridReact } from 'ag-grid-react';
import GSelectMUIAtom from 'components/atom/select';
import New from 'components/asset/images/Pen.svg';
import Save from 'components/asset/images/Confirm.svg';

type SearchParams = {
  coCd: string;
};
const Domestic = () => {
  const handleChangeCoCd = (e) => {
    setSearchParams({ coCd: e.target.value });
  };
  const [searchParams, setSearchParams] = useState<SearchParams>({
    coCd: '',
  });
  const dommy: CommonCode[] = [{ cmnCd: 'v1', cmnCdNm: '옵션1' }];
  const [chipClick, setChipClick] = useState(false);
  const onSearchClick = () => {
    setChipClick((pre) => !pre);
    alert('ex. 모달에서 clickValue 선택');
  };

  const [option, setOption] = React.useState('1'); // 디폴트 값이 있다면 1, 없다면 0 (0일때는 )
  const handleChange = (event: SelectChangeEvent) => {
    setOption(event.target.value);
  };
  const [sampleList, setSampleList] = useState<SampleResponse[]>([]);
  const gridApiRef = useRef<any>(null);
  const { defaultColDef, colDefs } = useColumnDetailDefs();
  const { defaultColDef1, colDefs1 } = useColumnHistoryDefs();
  const { openLoading } = useLoading();
  const { openMessageBar } = useMessageBar();

  useEffect(() => {
    loadSampleList();
  }, []);

  const makeSearchParams = () => {
    return {
      start: '0',
      pageSize: 30,
    };
  };

  const handleError = (e: Error) => {
    openLoading(false);
    openMessageBar('error', e.message);
  };

  const loadSampleList = async () => {
    getSamples(makeSearchParams())
      .then((data) => {
        setSampleList(data.list);
      })
      .catch(handleError);
  };
  return (
    <>
      <SearchWrap>
        <SearchMolecule
          type="radio"
          labelTitle="원화/외화 구분"
          isRequired={true}
          radioValue="radioTest2"
          radioOption={['원화', '외화']}
          labelPaddingTop="0px"
          alignItems="center"
        />
      </SearchWrap>
      <SubTitleBtnArea>
        <SubTitleBox
          content="거래처 기본 정보"
          showSubTitle={true}
          isVisibleComment={false}
          isVisibleInfo={false}
        />
      </SubTitleBtnArea>
      <GbasicTableWrap isBottomMgn={true}>
        <GbasicTable>
          <colgroup>
            <col style={{ width: '160px' }} />
            <col style={{ width: 'auto' }} />
            <col style={{ width: '160px' }} />
            <col style={{ width: 'auto' }} />
          </colgroup>
          <tbody>
            <tr>
              <th>
                <GLabelAtom align="left">상호</GLabelAtom>
              </th>
              <td>
                <InputField
                  status="readonly"
                  align="left"
                  width="240px"
                  value="한국배터리산업협회"
                  readOnly
                />
              </td>
              <th>
                <GLabelAtom align="left">사업자번호</GLabelAtom>
              </th>
              <td>
                <InputField
                  status="readonly"
                  align="left"
                  width="240px"
                  value="111011-2487052"
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <th>
                <GLabelAtom align="left">대표자명</GLabelAtom>
              </th>
              <td>
                <InputField status="readonly" align="left" width="240px" value="김대표" readOnly />
              </td>
              <th>
                <GLabelAtom align="left">주민번호</GLabelAtom>
              </th>
              <td>
                <InputField
                  status="readonly"
                  align="left"
                  width="240px"
                  value="851212-*******"
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <th>
                <GLabelAtom align="left">법인번호</GLabelAtom>
              </th>
              <td>
                <InputField
                  status="readonly"
                  align="left"
                  width="240px"
                  value="111011-2487050"
                  readOnly
                />
              </td>
              <th>
                <GLabelAtom align="left">거래처코드</GLabelAtom>
              </th>
              <td>
                <InputField
                  status="readonly"
                  align="left"
                  width="240px"
                  value="KR000211"
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <th>
                <GLabelAtom align="left">번지/상세주소</GLabelAtom>
              </th>
              <td colSpan={3}>
                <AlignBox>
                  <InputField
                    width="100%"
                    status="readonly"
                    align="left"
                    value="서울특별시 영등포구 여의대로 108"
                    readOnly
                  />
                  <AlignItem>
                    <InputField
                      status="disabled"
                      align="left"
                      width="100%"
                      placeholder="한국배터리 산업협회"
                      disabled
                    />
                  </AlignItem>
                  <GButton
                    hasImg={true}
                    chkImg={img}
                    hasTxt={false}
                    sizes="medium"
                    btnstyled="outline"
                    btnColor="normal"
                  />
                </AlignBox>
              </td>
            </tr>
            <tr>
              <th>
                <GLabelAtom align="left">이메일</GLabelAtom>
              </th>
              <td>
                <AlignBox>
                  <InputField
                    status="disabled"
                    align="left"
                    width="140px"
                    placeholder="kimelgee0123"
                    disabled
                  />
                  @
                  <GSelectMUIAtom
                    selectWidth="147.5px"
                    placeholder="placeholder"
                    option={['lgensol.com']}
                    value={option}
                    handleChange={handleChange}
                    disabled
                  />
                  <InputField
                    status="disabled"
                    align="left"
                    width="147.5px"
                    placeholder="직접입력"
                    disabled
                  />
                </AlignBox>
              </td>
              <th>
                <GLabelAtom align="left">이메일 추가</GLabelAtom>
              </th>
              <td>
                <AlignBox>
                  <InputField
                    status="disabled"
                    align="left"
                    width="140px"
                    placeholder="kimelgee0123"
                    disabled
                  />
                  @
                  <GSelectMUIAtom
                    selectWidth="147.5px"
                    placeholder="placeholder"
                    option={['lgensol.com']}
                    value={option}
                    handleChange={handleChange}
                    disabled
                  />
                  <InputField
                    status="disabled"
                    align="left"
                    width="147.5px"
                    placeholder="직접입력"
                    disabled
                  />
                </AlignBox>
              </td>
            </tr>
            <tr>
              <th>
                <GLabelAtom align="left">담당자</GLabelAtom>
              </th>
              <td>
                <InputField
                  width="240px"
                  status="disabled"
                  align="left"
                  placeholder="김거래"
                  disabled
                />
              </td>
              <th>
                <GLabelAtom align="left">사업자등록상태</GLabelAtom>
              </th>
              <td>
                <ChipSelectBox
                  type="1"
                  width="240px"
                  height="32px"
                  disabled
                  readonly
                  onClickFunction={onSearchClick}
                />
              </td>
            </tr>
            <tr>
              <th>
                <GLabelAtom align="left">연락처</GLabelAtom>
              </th>
              <td>
                <InputField width="240px" status="disabled" align="left" placeholder="12345678" />
              </td>
              <th>
                <GLabelAtom align="left">국가키</GLabelAtom>
              </th>
              <td>
                <AlignBox>
                  <InputField
                    width="80px"
                    status="disabled"
                    align="left"
                    placeholder="KR"
                    readOnly
                  />
                  <InputField
                    width="156px"
                    status="disabled"
                    align="left"
                    placeholder="한국"
                    readOnly
                  />
                </AlignBox>
              </td>
            </tr>
          </tbody>
        </GbasicTable>
      </GbasicTableWrap>
      <SubTitleBox
        content="LG에너지솔루션 담당"
        showSubTitle={true}
        isVisibleComment={false}
        isVisibleInfo={false}
      />
      <GbasicTableWrap isBottomMgn={false}>
        <GbasicTable>
          <colgroup>
            <col style={{ width: '160px' }} />
            <col style={{ width: 'auto' }} />
            <col style={{ width: '160px' }} />
            <col style={{ width: 'auto' }} />
          </colgroup>
          <tbody>
            <tr>
              <th>
                <GLabelAtom align="left">담당자</GLabelAtom>
              </th>
              <td>
                <ChipSelectBox
                  type="1"
                  width="240px"
                  format="placeholder"
                  onClickFunction={onSearchClick}
                />
              </td>
              <th>
                <GLabelAtom align="left">이메일</GLabelAtom>
              </th>
              <td>
                <AlignBox>
                  <InputField
                    status="disabled"
                    align="left"
                    width="140px"
                    placeholder="kimelgee0123"
                    disabled
                  />
                  @
                  <GSelectMUIAtom
                    selectWidth="147.5px"
                    placeholder="placeholder"
                    option={['lgensol.com']}
                    value={option}
                    handleChange={handleChange}
                    disabled
                  />
                  <InputField
                    status="disabled"
                    align="left"
                    width="147.5px"
                    placeholder="직접입력"
                    disabled
                  />
                </AlignBox>
              </td>
            </tr>
            <tr>
              <th>
                <GLabelAtom align="left">연락처</GLabelAtom>
              </th>
              <td>
                <InputField width="240px" status="disabled" align="left" placeholder="12345678" />
              </td>
            </tr>
          </tbody>
        </GbasicTable>
      </GbasicTableWrap>
      <GButtonLayout paddingTop="4px" marginBottom="12px">
        <GButtonBox>
          <GButton
            txt="취소"
            sizes="medium"
            btnstyled="outline"
            btnColor="normal"
            hasImg={false}
            hasTxt={true}
          />
          <GButton
            txt="저장"
            chkImg={confirm}
            sizes="medium"
            btnstyled="contained"
            btnColor="primary"
            hasImg={true}
            hasTxt={true}
          />
        </GButtonBox>
      </GButtonLayout>
      <GTabs flex={true} defaultValue={1} title={['계좌정보', '계좌등록이력']}>
        <>
          <SubTitleBtnArea>
            <SubTitleBox showSubTitle={true} content="계좌등록정보" />
          </SubTitleBtnArea>
          <GContentGrid
            type="radio"
            isSortable={false}
            className="ag-theme-alpine"
            style={{ flex: 1 }}
          >
            <AgGridReact
              defaultColDef={defaultColDef}
              columnDefs={colDefs}
              rowData={sampleList}
              rowSelection={'single'}
              enableCellTextSelection
              onGridReady={(params) => (gridApiRef.current = params.api)}
              tooltipShowDelay={0}
            ></AgGridReact>
          </GContentGrid>

          <GButtonLayout marginBottom="8px">
            <GButtonBox>
              <GButton
                chkImg={New}
                txt="신규"
                sizes="medium"
                btnstyled="outline"
                btnColor="normal"
                hasImg={true}
                hasTxt={true}
              />
              <GButton
                chkImg={Save}
                txt="전산등록요청"
                sizes="medium"
                btnstyled="contained"
                btnColor="primary"
                hasImg={true}
                hasTxt={true}
              />
            </GButtonBox>
          </GButtonLayout>
        </>
        <>
          <SubTitleBtnArea>
            <SubTitleBox showSubTitle={true} content="계좌등록이력" />
          </SubTitleBtnArea>
          <GContentGrid
            isSortable={false}
            className="ag-theme-alpine"
            style={{ flex: 1, marginBottom: '8px' }}
          >
            <AgGridReact
              defaultColDef={defaultColDef1}
              columnDefs={colDefs1}
              rowData={sampleList}
              rowSelection={'multiple'}
              enableCellTextSelection
              onGridReady={(params) => (gridApiRef.current = params.api)}
              tooltipShowDelay={0}
            ></AgGridReact>
          </GContentGrid>
        </>
      </GTabs>
    </>
  );
};

export default Domestic;
