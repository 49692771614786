import { DepartmentContext } from 'components/modals/common/EmployeeModal';
import { Department } from 'models/admin/Department';
import React, { useContext, useEffect, useState } from 'react';
import { Tree } from 'components/layouts/Tree';
import useSessionStore from 'stores/useSessionStore';
import { LanguageCode } from 'models/common/Session';

interface Props {
  item: Department | any;
  depth: number;
  userDept: string;
  defaultOpen?: () => void;
}

const DepartmentTreeItem = ({ item, depth = 0, userDept, defaultOpen }: Props) => {
  const { langCd } = useSessionStore();
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const contextValue = useContext(DepartmentContext);

  const getDeptNm = (department: Department) => {
    switch (langCd) {
      case LanguageCode.ko:
        return department.deptNm;
      case LanguageCode.en:
      case LanguageCode.pl:
        return department.deptEngNm;
      case LanguageCode.zhC:
      case LanguageCode.zhT:
        return department.deptCngNm;
      default:
        return department.deptEngNm;
    }
  };

  useEffect(() => {
    if (
      contextValue.departmentList.length > 0 &&
      !contextValue.multipleSelect &&
      item.deptCd === contextValue.userDepartment.upprDeptCd
    ) {
      setCollapsed(true);
    }
  }, [contextValue.departmentList]);

  useEffect(() => {
    if (collapsed && defaultOpen) {
      defaultOpen();
    }
  }, [collapsed]);

  const openParent = () => {
    setCollapsed(true);
  };

  const selectDepartment = (item: Department, e) => {
    if (!contextValue.multipleSelect) {
      contextValue.handleSelectDepartment({
        ...contextValue,
        selectedDepartment: [item],
      });
    } else {
      let newList = contextValue.selectedDepartment;
      if (e.target.checked) {
        newList.push(item);
      } else {
        newList = newList.filter((dept) => dept.deptCd != item.deptCd);
      }
      contextValue.handleSelectDepartment({
        ...contextValue,
        selectedDepartment: newList,
      });
    }
  };

  return (
    <>
      {item.children.length > 0 ? (
        <li>
          <input
            type="checkbox"
            className="hidden"
            id={item.deptCd}
            onChange={() => setCollapsed((prev) => !prev)}
            checked={collapsed}
            onClick={(e) => selectDepartment(item, e)}
          />
          <label
            htmlFor={item.deptCd}
            className={(depth === 0 ? 'root ' : '').concat(
              Array.isArray(contextValue.selectedDepartment) &&
                contextValue.selectedDepartment.includes(item)
                ? 'on'
                : ''
            )}
            datatype={item.useYn}
          >
            {getDeptNm(item)}
          </label>
          <ul>
            {item.children.map((child) => (
              <DepartmentTreeItem
                item={child}
                depth={depth + 1}
                key={child.deptCd}
                defaultOpen={openParent}
                userDept={userDept}
              />
            ))}
          </ul>
        </li>
      ) : (
        <li className="lastList">
          <input
            id={item.deptCd}
            type="checkbox"
            className={!contextValue.multipleSelect ? 'hidden' : ''}
            defaultChecked={
              Array.isArray(contextValue.selectedDepartment) &&
              contextValue.selectedDepartment.filter((dept) => dept.deptCd === item.deptCd).length >
                0
            }
            onClick={(e) => selectDepartment(item, e)}
          />
          <label
            className={'lastTree '.concat(
              Array.isArray(contextValue.selectedDepartment) &&
                contextValue.selectedDepartment.filter((dept) => dept.deptCd === item.deptCd)
                  .length > 0
                ? 'on'
                : ''
            )}
            htmlFor={item.deptCd}
          >
            {getDeptNm(item)}
          </label>
        </li>
      )}
    </>
  );
};

export default React.memo(DepartmentTreeItem);
