import { Employee } from 'models/admin/Employee';
import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { callApi } from 'utils/ApiUtil';
import { callJwtApi } from 'utils/JwtApiUtil';

// 담당자 리스트 불러오기 및 조회(ADMIN)
export const getAdminManagerListApi = async (searchEmail?: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/common/search-manager?searchEmail=${searchEmail}`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
  };
  const response: CommonResponse<Employee[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : []) as Employee[];
};

// 담당자 리스트 불러오기 및 조회(USER)
export const getUserManagerListApi = async (searchEmail?: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/api/common/search-manager?searchEmail=${searchEmail}`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
  };
  const response: CommonResponse<Employee[]> = await callJwtApi(request);
  return (response.successOrNot === 'Y' ? response.data : []) as Employee[];
};
