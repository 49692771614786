import { ColDef } from 'ag-grid-community';
import { DefaultCellTooltip } from 'components/grids/tooltip/DefaultCellTooltip';
import { addCommasFor } from 'components/vars/common/FormatNumber';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useONonPaymentColDefs = () => {
  const { t } = useTranslation();
  const defaultColDef3 = useMemo((): ColDef => {
    return {
      sortable: false,
      resizable: true,
      cellStyle: { textAlign: 'center' },
      tooltipComponent: DefaultCellTooltip,
    };
  }, []);

  const colDefs3: any = [
    {
      headerName: t('com.label.00621', '__Issue Date'),
      headerTooltip: t('com.label.00621', '__Issue Date'),
      field: 'payDt',
      tooltipField: 'payDt',
      width: 280,
    },
    {
      headerName: t('com.label.00622', '__Issue Amount'),
      headerTooltip: t('com.label.00622', '__Issue Amount'),
      field: 'payAmt',
      tooltipField: 'payAmt',
      cellStyle: { textAlign: 'right' },
      valueFormatter: (params) => addCommasFor(params.value),
      width: 280,
    },
    {
      headerName: t('com.label.00623', '__Vendor Bank Account Information'),
      headerTooltip: t('com.label.00623', '__Vendor Bank Account Information'),
      flex: 1,
      children: [
        {
          headerName: t('com.label.00624', '__Issue Bank'),
          headerTooltip: t('com.label.00624', '__Issue Bank'),
          field: 'payBankNm',
          tooltipField: 'payBankNm',
          flex: 1,
        },
        {
          headerName: t('com.label.00625', '__CNAPS Code'),
          headerTooltip: t('com.label.00625', '__CNAPS Code'),
          field: 'cnapsCd',
          tooltipField: 'cnapsCd',
          flex: 1,
        },
        {
          headerName: t('com.label.00626', '__Vender Bank Account #'),
          headerTooltip: t('com.label.00626', '__Vender Bank Account #'),
          field: 'accnNo',
          tooltipField: 'accnNo',
          flex: 1,
        },
      ],
    },
    {
      headerName: t('com.label.00627', '__[ECD] Draft Number'),
      headerTooltip: t('com.label.00627', '__[ECD] Draft Number'),
      field: 'drftNo',
      tooltipField: 'drftNo',
      width: 280,
    },
  ];

  return { defaultColDef3, colDefs3 };
};
