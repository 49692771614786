import styled from '@emotion/styled';
import useAuthStore from 'stores/useAuthStore';
import ReReplyTextArea from './ReReplyTextArea';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCommonModal } from 'hooks/useCommonModal';
import { FileSaveResponse, FileSaveResult } from 'models/admin/FileInfo';
import { editUserQnaReplyApi, postUserQnaReplyApi } from 'apis/vars/qna/QnaUserApi';
import { editAdminQnaReplyApi, postAdminQnaReplyApi } from 'apis/vars/qna/QnaAdminApi';
import { QnaReplies } from 'pages/vars/qna/QnaDetailPage';
import { useMessageBar } from 'components/process/MessageBar';

interface ReplyAreaProps {
  profileImg?: string;
  nickName?: string;
  position?: string;

  // @@님에게 답글 다는 중... 일때 사용하는 props
  reCommentIng?: boolean;
  reNickName?: string;

  // 추가
  id?: string;
  replyData: QnaReplies;
  openEditReReply?: any;
  setOpenReReply?: any; // '댓글쓰기'로 신규 대댓글 영역
  setOpenEditReReply?: any; // '수정'으로 대댓글 수정 영역
  getQnaDetailData: (uptVwct) => void;
}

const ReReplyArea: React.FC<ReplyAreaProps> = (props: ReplyAreaProps) => {
  const { t } = useTranslation();
  const reReplyzoneRef = useRef<any>();
  const { accessToken } = useAuthStore();
  const { openMessageBar } = useMessageBar();
  const { openCommonModal } = useCommonModal();
  const [rereplyArea, setRereplyArea] = useState<string | undefined>('');
  const { bbmNo, bbmReNo, bbmReCtn, atchFileGrId, atchFileCnt, upprBbmReNo } = props.replyData;

  useEffect(() => {
    if (props.openEditReReply) {
      setRereplyArea(bbmReCtn);
    } else {
      setRereplyArea('');
    }
  }, []);

  const changeWriteRereply = (e) => {
    setRereplyArea(e.target.value);
  };

  const getFileId = async () => {
    const fileSaveResponse: FileSaveResponse = await reReplyzoneRef.current.saveFiles();
    return fileSaveResponse.fileSaveResult == FileSaveResult.FAIL
      ? null
      : fileSaveResponse.atchFileGrId;
  };

  const handleSaveRereply = async () => {
    const uptVwct = 'N';
    const fileId = await getFileId();
    // 수정상태의 경우 수정 API
    if (props.openEditReReply) {
      if (accessToken) {
        editUserQnaReplyApi({
          bbmNo: Number(bbmNo),
          bbmReCtn: rereplyArea || '',
          atchFileGrId: fileId ? fileId : '',
          bbmReNo: Number(bbmReNo),
          upprBbmReNo: Number(upprBbmReNo),
        }).then((res) => {
          if (res === true) {
            props.getQnaDetailData(uptVwct);
            setRereplyArea('');
          }
        });
      } else {
        editAdminQnaReplyApi({
          bbmNo: Number(bbmNo),
          bbmReCtn: rereplyArea || '',
          atchFileGrId: fileId ? fileId : '',
          bbmReNo: Number(bbmReNo),
          upprBbmReNo: Number(upprBbmReNo),
        }).then((res) => {
          if (res === true) {
            props.getQnaDetailData(uptVwct);
            setRereplyArea('');
          }
        });
      }
    } else {
      // 신규 등록 상태의 경우 등록 API
      if (accessToken) {
        postUserQnaReplyApi({
          bbmNo: Number(bbmNo),
          bbmReCtn: rereplyArea || '',
          atchFileGrId: fileId ? fileId : '',
          bbmReNo: Number(bbmReNo),
          upprBbmReNo: Number(upprBbmReNo) > 0 ? Number(upprBbmReNo) : Number(bbmReNo),
        }).then((res) => {
          if (res === true) {
            props.getQnaDetailData(uptVwct);
            setRereplyArea('');
          }
        });
      } else {
        postAdminQnaReplyApi({
          bbmNo: Number(bbmNo),
          bbmReCtn: rereplyArea || '',
          atchFileGrId: fileId ? fileId : '',
          bbmReNo: Number(bbmReNo),
          upprBbmReNo: Number(upprBbmReNo) > 0 ? Number(upprBbmReNo) : Number(bbmReNo),
        }).then((res) => {
          if (res === true) {
            props.getQnaDetailData(uptVwct);
            setRereplyArea('');
          }
        });
      }
    }

    if (props.openEditReReply) {
      props.setOpenEditReReply(false);
    } else {
      props.setOpenReReply(false);
    }
  };

  return (
    <ReplyItem {...props}>
      {/* <WriterProfile src={props.profileImg} {...props} /> */}
      <span
        style={{
          width: '24px',
          height: '24px',
          backgroundColor: '#554596',
          borderRadius: '50px',
          margin: '6px 4px',
        }}
      >
        <WriterProfile
          src={props.profileImg}
          {...props}
          style={{ width: '20px', height: '20px', margin: '2px' }}
        />
      </span>
      <ContentWrap {...props}>
        <TopArea>
          <NameWrap {...props}>
            <Nickname>{props.nickName}</Nickname>
            <Position>{props.position}</Position>
          </NameWrap>
        </TopArea>
        <ReplyContentWrap>
          <ReReplyTextArea
            id={props.id}
            status="default"
            width="100%"
            height="auto"
            placeholder={t('com.label.00051', '댓글을 입력해 주세요.') as string}
            isComment={true}
            reComment={true}
            value={rereplyArea}
            count={rereplyArea ? rereplyArea.length + ' / 300' : 0 + ' / 300'}
            onChange={changeWriteRereply}
            handleReplySave={handleSaveRereply}
            setOpenReReply={props.setOpenReReply}
            openEditReReply={props.openEditReReply}
            setOpenEditReReply={props.setOpenEditReReply}
            atchFileCnt={atchFileCnt}
            atchFileGrId={atchFileGrId}
            reReplyzoneRef={reReplyzoneRef}
          />
        </ReplyContentWrap>
      </ContentWrap>
    </ReplyItem>
  );
};

const ReplyItem = styled.div<ReplyAreaProps>`
  display: flex;
  align-items: flex-start;
  padding: 12px 0;
  width: 100%;
  gap: 8px;
`;

const ContentWrap = styled.div<ReplyAreaProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const WriterProfile = styled.img<ReplyAreaProps>`
  width: 24px;
  height: 24px;
  border-radius: 50px;
  margin: 6px 4px;
`;

const TopArea = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 12px;
`;

const NameWrap = styled.div<ReplyAreaProps>`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Nickname = styled.text`
  color: var(--Common-Text-Basic, #1f1f1f);
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Spoqa Han Sans Neo;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`;

const Position = styled.text`
  color: var(--Common-Text-Basic, #1f1f1f);
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Spoqa Han Sans Neo;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

const ReplyContentWrap = styled.div`
  width: 100%;
  hegiht: auto;
`;

export default ReReplyArea;
