import { ColDef } from 'ag-grid-community';
import { DefaultCellTooltip } from 'components/grids/tooltip/DefaultCellTooltip';
import { addCommasFor } from 'components/vars/common/FormatNumber';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useKFrgnCurPaymentAdColDefs = () => {
  const { t } = useTranslation();
  const defaultColDef3 = useMemo((): ColDef => {
    return {
      sortable: false,
      resizable: true,
      cellStyle: { textAlign: 'center' },
      tooltipComponent: DefaultCellTooltip,
    };
  }, []);

  const colDefs3: any = [
    {
      headerName: t('com.label.00113', '__지급일자'),
      headerTooltip: t('com.label.00113', '__지급일자'),
      field: 'payDt',
      tooltipField: 'payDt',
      maxWidth: 200,
    },
    {
      headerName: t('com.label.00076', '__통화'),
      headerTooltip: t('com.label.00076', '__통화'),
      field: 'curr',
      tooltipField: 'curr',
      maxWidth: 120,
    },
    {
      headerName: t('com.label.00118', '__금액'),
      headerTooltip: t('com.label.00118', '__금액'),
      field: 'payAmt',
      tooltipField: 'payAmt',
      cellStyle: { textAlign: 'right' },
      valueFormatter: (params) => addCommasFor(params.value),
      flex: 1,
    },
    {
      headerName: t('com.label.00119', '__지급은행'),
      headerTooltip: t('com.label.00119', '__지급은행'),
      field: 'payBankNm',
      tooltipField: 'payBankNm',
      cellStyle: { textAlign: 'center' },
      flex: 1,
    },
    {
      headerName: t('Swift', 'Swift'),
      headerTooltip: t('Swift', 'Swift'),
      field: 'swift',
      tooltipField: 'swift',
      cellStyle: { textAlign: 'left' },
      maxWidth: 200,
    },
    {
      headerName: t('com.label.00070', '__계좌번호'),
      headerTooltip: t('com.label.00070', '__계좌번호'),
      field: 'accnNo',
      tooltipField: 'accnNo',
      flex: 1,
    },
    {
      headerName: t('com.label.00130', '__전문번호'),
      headerTooltip: t('com.label.00130', '__전문번호'),
      field: 'profNo',
      tooltipField: 'profNo',
      maxWidth: 200,
    },
  ];

  const handleAprvDetailBtn = (data) => {
    console.log('화면 연결이 필요합니다.');
  };

  return { defaultColDef3, colDefs3 };
};
