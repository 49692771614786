import { useCommonModal } from 'hooks/useCommonModal';
import { Department } from 'models/admin/Department';
import { useRef, useState } from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { GButtonBox, GPopupTitle } from 'components/layouts/GLayoutBox';
import { DialogTitleItem } from 'components/layouts/GModalPopup';
import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import { GButton } from 'components/atom/button';
import ModalLayout from 'components/layouts/ModalLayout';
import { GridBox, GridItem } from 'components/organism/GridBox';
import { GbasicTable, GbasicTableWrap } from 'components/tables/GTable';
import { AlignBox, AlignItem } from 'components/organism/AlignBox';
import InputField from 'components/atom/input';
import { SubTitleBtnArea } from 'components/organism/SubTitleBtnArea';
import SubTitleBox from 'components/molecule/SubTitleBox';
import { GLabelAtom } from 'components/atom/label';
import SingleChipSelect from 'components/atom/singleChipSelect';
import Tag from 'components/atom/tag';
import GSelectMUIAtom from 'components/atom/select';
import { SelectChangeEvent } from '@mui/material';
import FileUploadZone from 'components/molecule/FileUpload';
import Save from 'components/asset/images/Confirm.svg';
import FileDownload from 'components/molecule/FileDownload';

type DepartmentModalProps = {
  open: boolean;
  singleSelect?: boolean;
  close: () => void;
  save: () => void;
};

const ChangeOverseasAccount = (props: DepartmentModalProps) => {
  const { t } = useTranslation();
  const gridRef = useRef<AgGridReact<Department>>(null);
  const { openCommonModal } = useCommonModal();

  const handleClose = () => {
    props.close();
  };

  const handleOnSave = () => {
    const selectedRows = gridRef.current?.api.getSelectedRows() || [];
    if (selectedRows.length === 0) {
      openCommonModal({
        modalType: 'alert',
        content: t(
          'department-choice-popup.alert.한 부서 이상 선택해야 합니다.',
          '__선택된 행이 없습니다.'
        ),
      });
      return;
    }
    props.close();
  };

  // search box(chipSeelect) Function
  const [chipClick, setChipClick] = useState(false);
  const onSearchClick = () => {
    setChipClick((pre) => !pre);
    alert('ex. 모달에서 clickValue 선택');
  };

  const [option, setOption] = useState('1');
  const handleChange = (event: SelectChangeEvent) => {
    setOption(event.target.value);
  };

  return (
    <ModalLayout open={props.open} onClose={handleClose} mMinWidth={1600} mMaxWidth={1600}>
      <DialogTitleItem id="customized-dialog-title" onClose={handleClose}>
        <GPopupTitle title2={t('com.label.00546', '__계좌정보상세')} />
      </DialogTitleItem>
      <DialogContent className="popupContent">
        <GridBox>
          <GridItem>
            <SubTitleBtnArea>
              <SubTitleBox
                showSubTitle={true}
                content={t('com.label.00547', '__신규계좌상세정보')}
              />
            </SubTitleBtnArea>
            <GbasicTableWrap isBottomMgn={true}>
              <GbasicTable>
                <colgroup>
                  <col style={{ width: '120px' }} />
                  <col style={{ width: 'auto' }} />
                  <col style={{ width: '120px' }} />
                  <col style={{ width: 'auto' }} />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <GLabelAtom align="left" isRequired={true}>
                        은행
                      </GLabelAtom>
                    </th>
                    <td>
                      <SingleChipSelect
                        width="100%"
                        height="32px"
                        onClickFunction={onSearchClick}
                        isChip={chipClick}
                        chipType="default"
                      />
                    </td>
                    <th>
                      <GLabelAtom align="left">진행상태</GLabelAtom>
                    </th>
                    <td>
                      <Tag status="completion" content="등록완료" />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <GLabelAtom align="left">SWIFT/BIC</GLabelAtom>
                    </th>
                    <td colSpan={3}>
                      <InputField status="readonly" width="100%" readOnly />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <GLabelAtom align="left">은행키</GLabelAtom>
                    </th>
                    <td colSpan={3}>
                      <InputField status="readonly" width="100%" readOnly />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <GLabelAtom align="left" isRequired={true}>
                        예금주
                      </GLabelAtom>
                    </th>
                    <td>
                      <InputField status="default" width="100%" placeholder="입력하세요" />
                    </td>
                    <th>
                      <GLabelAtom align="left">추가 예금주</GLabelAtom>
                    </th>
                    <td>
                      <InputField status="default" width="100%" placeholder="입력하세요" />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <GLabelAtom align="left" isRequired={true}>
                        계좌번호
                      </GLabelAtom>
                    </th>
                    <td>
                      <AlignBox>
                        <AlignItem>
                          <InputField status="default" width="100%" placeholder="입력하세요" />
                        </AlignItem>
                        <GButton
                          txt="인증요청"
                          sizes="medium"
                          btnstyled="outline"
                          btnColor="normal"
                          hasImg={false}
                          hasTxt={true}
                        />
                      </AlignBox>
                    </td>
                    <th>
                      <GLabelAtom align="left" isRequired={true}>
                        통화
                      </GLabelAtom>
                    </th>
                    <td>
                      <GSelectMUIAtom
                        selectWidth="100px"
                        option={['USD', '옵션2', '옵션3']}
                        value={option}
                        handleChange={handleChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <GLabelAtom align="left">계좌용도</GLabelAtom>
                    </th>
                    <td colSpan={3}>
                      <InputField status="default" width="100%" placeholder="입력하세요" />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <GLabelAtom align="left">반려의견</GLabelAtom>
                    </th>
                    <td colSpan={3}></td>
                  </tr>
                  <tr>
                    <th>
                      <GLabelAtom align="left" isRequired={true}>
                        사업자등록증
                      </GLabelAtom>
                    </th>
                    <td colSpan={3}>
                      <FileUploadZone
                        height="40px"
                        ref={FileUploadZone}
                        atchFileGrIdInput="9cb7a782-f893-4e6b-b58c-20794684bbc6"
                        isMultipleFile={true}
                        allowFileTypes={['txt', 'jpg', 'png', 'xlsx']}
                        width="100%"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <GLabelAtom align="left" isRequired={true}>
                        통장사본
                      </GLabelAtom>
                    </th>
                    <td colSpan={3}>
                      <FileUploadZone
                        height="40px"
                        ref={FileUploadZone}
                        atchFileGrIdInput="9cb7a782-f893-4e6b-b58c-20794684bbc6"
                        isMultipleFile={true}
                        allowFileTypes={['txt', 'jpg', 'png', 'xlsx']}
                        width="100%"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <GLabelAtom align="left" isRequired={true}>
                        어음약정확인서
                      </GLabelAtom>
                    </th>
                    <td colSpan={3}>
                      <FileUploadZone
                        height="40px"
                        ref={FileUploadZone}
                        atchFileGrIdInput="9cb7a782-f893-4e6b-b58c-20794684bbc6"
                        isMultipleFile={true}
                        allowFileTypes={['txt', 'jpg', 'png', 'xlsx']}
                        width="100%"
                      />
                    </td>
                  </tr>
                  {/* <tr>
                    <th>
                      <GLabelAtom align="left">기타1</GLabelAtom>
                    </th>
                    <td colSpan={3}>
                      <FileUploadZone
                        height="40px"
                        ref={FileUploadZone}
                        atchFileGrIdInput="9cb7a782-f893-4e6b-b58c-20794684bbc6"
                        isMultipleFile={true}
                        allowFileTypes={['txt', 'jpg', 'png', 'xlsx']}
                        width="100%"
                      />
                    </td>
                  </tr> */}
                  <tr>
                    <th>
                      <GLabelAtom align="left">기타</GLabelAtom>
                    </th>
                    <td colSpan={3}>
                      <FileUploadZone
                        height="40px"
                        ref={FileUploadZone}
                        atchFileGrIdInput="9cb7a782-f893-4e6b-b58c-20794684bbc6"
                        isMultipleFile={true}
                        allowFileTypes={['txt', 'jpg', 'png', 'xlsx']}
                        width="100%"
                      />
                    </td>
                  </tr>
                </tbody>
              </GbasicTable>
            </GbasicTableWrap>
          </GridItem>
          <GridItem>
            <SubTitleBtnArea>
              <SubTitleBox showSubTitle={true} content="이전계좌상세정보" />
            </SubTitleBtnArea>
            <GbasicTableWrap isBottomMgn={true}>
              <GbasicTable>
                <colgroup>
                  <col style={{ width: '120px' }} />
                  <col style={{ width: 'auto' }} />
                  <col style={{ width: '120px' }} />
                  <col style={{ width: 'auto' }} />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <GLabelAtom align="left">은행</GLabelAtom>
                    </th>
                    <td>CITIBANK N.A</td>
                    <th>
                      <GLabelAtom align="left">진행상태</GLabelAtom>
                    </th>
                    <td>
                      <Tag status="completion" content="등록완료" />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <GLabelAtom align="left">SWIFT/BIC</GLabelAtom>
                    </th>
                    <td colSpan={3}>CITIARBAXXX</td>
                  </tr>
                  <tr>
                    <th>
                      <GLabelAtom align="left">은행키</GLabelAtom>
                    </th>
                    <td colSpan={3}>503002480</td>
                  </tr>
                  <tr>
                    <th>
                      <GLabelAtom align="left">예금주</GLabelAtom>
                    </th>
                    <td>김거래</td>
                    <th>
                      <GLabelAtom align="left">추가 예금주</GLabelAtom>
                    </th>
                    <td>김추가</td>
                  </tr>
                  <tr>
                    <th>
                      <GLabelAtom align="left">계좌번호</GLabelAtom>
                    </th>
                    <td>105-12345-123456478</td>
                    <th>
                      <GLabelAtom align="left">통화</GLabelAtom>
                    </th>
                    <td>USD</td>
                  </tr>
                  <tr>
                    <th>
                      <GLabelAtom align="left">계좌용도</GLabelAtom>
                    </th>
                    <td colSpan={3}>외화 법인용</td>
                  </tr>
                  <tr>
                    <th>
                      <GLabelAtom align="left">사업자등록증</GLabelAtom>
                    </th>
                    <td colSpan={3}>
                      <FileDownload atchFileGrId="test" />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <GLabelAtom align="left">통장사본</GLabelAtom>
                    </th>
                    <td colSpan={3}>
                      <FileDownload atchFileGrId="test" />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <GLabelAtom align="left">어음약정확인서</GLabelAtom>
                    </th>
                    <td colSpan={3}>
                      <FileDownload atchFileGrId="test" />
                    </td>
                  </tr>
                  {/* <tr>
                    <th>
                      <GLabelAtom align="left">기타1</GLabelAtom>
                    </th>
                    <td colSpan={3}>
                      <FileDownload atchFileGrId="test" />
                    </td>
                  </tr> */}
                  <tr>
                    <th>
                      <GLabelAtom align="left">기타</GLabelAtom>
                    </th>
                    <td colSpan={3}>
                      <FileDownload atchFileGrId="test" />
                    </td>
                  </tr>
                </tbody>
              </GbasicTable>
            </GbasicTableWrap>
          </GridItem>
        </GridBox>
      </DialogContent>
      <DialogActions className="popupBottom">
        <GButtonBox>
          <GButton
            txt="취소"
            sizes="medium"
            onClick={handleClose}
            btnColor="normal"
            btnstyled="outline"
            hasImg={false}
            hasTxt={true}
          />
          <GButton
            txt="임시저장"
            sizes="medium"
            btnColor="normal"
            btnstyled="outline"
            hasImg={false}
            hasTxt={true}
          />
          <GButton
            chkImg={Save}
            txt="확인"
            sizes="medium"
            onClick={handleOnSave}
            btnstyled="contained"
            btnColor="primary"
            hasImg={true}
            hasTxt={true}
          ></GButton>
        </GButtonBox>
      </DialogActions>
    </ModalLayout>
  );
};

export default ChangeOverseasAccount;
