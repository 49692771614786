import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { bankNumberList } from 'pages/vars/accountregist/modal/BankNumberModal';
import { callApi } from 'utils/ApiUtil';
import { callJwtApi } from 'utils/JwtApiUtil';

// 은행번호 불러오기(ADMIN)
export const getAdminBankNumberListApi = async (searchParams) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/api/sap-if/ws-sample-test`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({
      I_REQ_DIVS: 'BC',
      i_BANK_CODE: searchParams.bankCode,
      i_BANK_NAME: searchParams.bankName,
    }),
  };
  const response: CommonResponse<bankNumberList[]> = await callApi(request);
  console.log(response);
  return (response.successOrNot === 'Y' ? response.data : []) as bankNumberList[];
};

// 은행번호 불러오기(USER)
export const getUserBankNumberListApi = async (searchParams) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/api/sec/common/std-info0237`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({
      I_REQ_DIVS: 'BC',
      i_BANK_CODE: searchParams.bankCode,
      i_BANK_NAME: searchParams.bankName,
    }),
  };
  const response: CommonResponse<bankNumberList[]> = await callJwtApi(request);
  console.log(response);
  return (response.successOrNot === 'Y' ? response.data : []) as bankNumberList[];
};
