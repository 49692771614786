/* eslint-disable prettier/prettier */
import React from 'react';
import styled from '@emotion/styled';

const GuidemanualContent = () => {
  return (
    <HelpDeskWrap>
      <div className="help">
        <div className="comment_kor">
        1. 본사/국내업체/원화계좌 ( LGES HQ / Korean Vendor / KRW )<br />
        <a className='alink' href='/varsfiles/manual/01.VARS_사용자 계좌등록매뉴얼_LGES 본사_국내거래처_원화계좌.pdf'
           target='_blank' rel="noopener noreferrer"
        > 
          01.VARS_사용자 계좌등록매뉴얼_LGES 본사_국내거래처_원화계좌
        </a><br /><br />
        2. 본사/국내업체/외화계좌  ( LGES HQ / Korean Vendor / Non-KRW )<br />
        <a className='alink' href='/varsfiles/manual/02.VARS_사용자 계좌등록매뉴얼_LGES 본사_국내거래처_외화계좌.pdf'
           target='_blank' rel="noopener noreferrer"
        > 
        02.VARS_사용자 계좌등록매뉴얼_LGES 본사_국내거래처_외화계좌
        </a><br /><br />
        3. 본사/해외업체/외화계좌  ( LGES HQ / Non Korean Vendor / Non-KRW )<br />
        <a className='alink' href='/varsfiles/manual/03.VARS_User Manual_LGES HQ_For Non Korean Vendor.pdf'
           target='_blank' rel="noopener noreferrer"
        > 
        03.VARS_User Manual_LGES HQ_For Non Korean Vendor
        </a><br /><br />
        4. 해외법인/해외업체/원,외화계좌 ( LGES Subsidiaries / Non Korean Vendor )<br />
        <a className='alink' href='/varsfiles/manual/04.VARS_User Manual_LGES Subsidiaries_For Non Korean Vendor.pdf'
           target='_blank' rel="noopener noreferrer"
        > 
        04.VARS_User Manual_LGES Subsidiaries_For Non Korean Vendor
        </a><br /><br />
        5. 해외법인/국내업체/원,외화계좌 ( LGES Subsidiaries / Korean Vendor )<br />
        <a className='alink' href='/varsfiles/manual/05.VARS_User Manual_LGES Subsidiaries_For Korean Vendor.pdf'
           target='_blank' rel="noopener noreferrer"
        > 
        05.VARS_User Manual_LGES Subsidiaries_For Korean Vendor
        </a><br /><br />
        </div>
      </div>
    </HelpDeskWrap>
  );
};

const HelpDeskWrap = styled.div`
  .help {
    font-size: 13px;
  } 

  .help .comment_kor,
  .help .comment_en {
    padding: 5px;
  }

  .help table {
    border-collapse: collapse;
    margin: 10px 0px;
    width: 100%;
  }

  .help table th,
  .help table td {
    border: 1px solid #dde0df;
    padding: 5px 10px;
  }

  .help table th {
    background: #f1f4f3;
    font-weight: 600;
  }

  .alink {color: #3296ff;}
`;

export default GuidemanualContent;
