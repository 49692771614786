// 답글쓰기, 수정용 Reply입니다
import styled from '@emotion/styled';
import { GButton } from 'components/atom/button';
import New from 'components/asset/images/Pen.svg';
import Delete from 'components/asset/images/Delete.svg';
import TextArea from 'components/atom/textarea';

interface ReplyAreaProps {
  profileImg?: string;
  nickName?: string;
  position?: string;

  // @@님에게 답글 다는 중... 일때 사용하는 props
  reCommentIng?: boolean;
  reNickName?: string;
}

const ReplyArea: React.FC<ReplyAreaProps> = (props: ReplyAreaProps) => {
  return (
    <ReplyItem>
      <WriterProfile src={props.profileImg} />
      <ContentWrap>
        <TopArea>
          <NameWrap>
            <Nickname>{props.nickName}</Nickname>
            <Position>{props.position}</Position>
          </NameWrap>
        </TopArea>
        <ReplyContentWrap>
          <TextArea
            status="default"
            width="100%"
            height="auto"
            placeholder="댓글을 입력해 주세요."
            isComment={true}
            reComment={true}
            reCommentIng={props.reCommentIng}
            nickName={props.reNickName}
            count="120 / 500"
          />
        </ReplyContentWrap>
      </ContentWrap>
    </ReplyItem>
  );
};

const ReplyItem = styled.div<ReplyAreaProps>`
  display: flex;
  align-items: flex-start;
  padding: 12px 0;
  width: 100%;
  gap: 8px;
`;

const ContentWrap = styled.div<ReplyAreaProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const WriterProfile = styled.img<ReplyAreaProps>`
  width: 24px;
  height: 24px;
  border-radius: 50px;
  margin: 6px 4px;
`;

const TopArea = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 12px;
`;

const NameWrap = styled.div<ReplyAreaProps>`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Nickname = styled.text`
  color: var(--Common-Text-Basic, #1f1f1f);
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Spoqa Han Sans Neo;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`;

const Position = styled.text`
  color: var(--Common-Text-Basic, #1f1f1f);
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Spoqa Han Sans Neo;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

const ReplyContentWrap = styled.div`
  width: 100%;
  height: auto;
`;

export default ReplyArea;
