import styled from '@emotion/styled';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import Check from '../../asset/Check.svg';
import GroupCheck from '../../asset/CheckGroup.svg';
import bar from '../../asset/Bar.svg';
import { BorderColor } from 'ui/theme/Color';
import { GlobalTokenColor } from 'ui/theme/Color';
import { useState } from 'react';

interface PropsType {
  color?: 'primary' | 'secondary';
  text?: string;
  value?: string;

  sizes?: string;
  imgWidth?: string;
  imgHeight?: string;

  isDisabled?: boolean;
  isSelected?: boolean;
}

export function BpCheckbox({ color = 'primary', ...props }: PropsType & CheckboxProps) {
  return (
    <Checkbox
      sx={{
        '&:hover': { bgcolor: 'transparent' },
        padding: 0,
        marginRight: '4px',
      }}
      disableRipple
      checkedIcon={<BpCheckedIcon color={color} />}
      icon={<BpIcon color={color} />}
      inputProps={{ 'aria-label': 'Checkbox demo' }}
      {...props}
    />
  );
}

export function BpBarbox({ color = 'primary', ...props }: PropsType & CheckboxProps) {
  return (
    <Checkbox
      sx={{
        '&:hover': { bgcolor: 'transparent' },
        padding: 0,
        marginRight: '4px',
      }}
      disableRipple
      checkedIcon={<BpBarIcon color={color} />}
      icon={<BpIcon color={color} />}
      inputProps={{ 'aria-label': 'Checkbox demo' }}
      {...props}
    />
  );
}

export function CheckboxGroup(props: PropsType) {
  const [expanded, setExpanded] = useState(false);

  const BoxClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <CheckBtnGroup
        type="checkbox"
        id={props.value}
        name="checkboxGroup"
        onClick={BoxClick}
        disabled={props.isDisabled}
        defaultChecked={props.isSelected}
        sizes={props.sizes}
      />
      <GroupLabel htmlFor={props.value} sizes={props.sizes}>
        {props.text}
      </GroupLabel>
    </>
  );
}

const CheckBtnGroup = styled.input<PropsType>`
  display: none;

  :checked + label {
    padding-right: ${(props) => (props.sizes === 'small' ? '24px' : '28px')};
    position: relative;
    border: 1px solid ${GlobalTokenColor.Primary};
    color: ${GlobalTokenColor.Primary};
    &:after {
      content: url(${GroupCheck});
      position: absolute;
      height: 16px;
      right: 0;
      padding: ${(props) => (props.sizes === 'small' ? '0 4px' : '0 6px')};
      display: flex;
    }

    input:disabled ~ & {
      border: 1px solid ${GlobalTokenColor.Primary};
      opacity: 0.4;
      background: ${GlobalTokenColor.ESGreyWHite};
    }
  }
`;

const GroupLabel = styled.label<PropsType>`
  display: flex;
  align-items: center;
  padding: 4px 14px;
  color: #5b5c5b;
  background: ${GlobalTokenColor.ESGreyWHite};
  border: 1px solid ${BorderColor.Form};
  border-radius: 100px;
  text-align: center;
  cursor: pointer;

  // sizes에 따른 스타일
  ${(props) =>
    props.sizes === 'large' &&
    `
    height: 32px;
    font-size: 13px;
    padding: 0 16px;
    `}
  ${(props) =>
    props.sizes === 'medium' &&
    `
    height: 28px;
    font-size: 12px;
    `}
    ${(props) =>
    props.sizes === 'small' &&
    `
      height: 24px;
      font-size: 12px;
      padding: 0 10px;
    `}

  &:hover {
    border: 1px solid #979998;
    background: #f1f4f3;
  }

  input:disabled ~ & {
    opacity: 0.4;
    background: ${GlobalTokenColor.ESGreyWHite};
  }
`;

const BpIcon = styled.span<PropsType>`
  border-radius: 3px;
  border: 1px solid ${BorderColor.Form};
  width: 16px;
  height: 16px;
  padding: 0;

  input:hover ~ & {
    border: 1px solid
      ${(props) =>
        props.color === 'primary'
          ? `${GlobalTokenColor.Primary}`
          : `${GlobalTokenColor.Secondary}`};
  }

  input:disabled ~ & {
    border: 1px solid ${GlobalTokenColor.ESGrey300Op40};
    opacity: 0.5;
  }
`;

const BpCheckedIcon = styled(BpIcon)`
  background-color: ${(props) =>
    props.color === 'primary'
      ? `${GlobalTokenColor.Primary600}`
      : `${GlobalTokenColor.Secondary500}`};
  border: none;

  &:before {
    display: block;
    height: 16px;
    background: url(${Check}) no-repeat center 5px;
    content: '';
  }

  input:hover ~ & {
    background-color: ${(props) =>
      props.color === 'primary'
        ? `${GlobalTokenColor.Primary500}`
        : `${GlobalTokenColor.Secondary300}`};
    border: none;
  }

  input:active ~ & {
    background: none;
    border: 1px solid;
    ${(props) =>
      props.color === 'primary'
        ? `${GlobalTokenColor.Primary500}`
        : `${GlobalTokenColor.Secondary300}`};
  }

  input:disabled ~ & {
    background-color: ${(props) =>
      props.color === 'primary'
        ? `${GlobalTokenColor.Primary600Op40}`
        : `${GlobalTokenColor.Secondary500Op40}`};
    border: none;
  }
`;

const BpBarIcon = styled(BpIcon)`
  background-color: ${(props) =>
    props.color === 'primary'
      ? `${GlobalTokenColor.Primary600}`
      : `${GlobalTokenColor.Secondary500}`};
  border: none;

  &:before {
    display: block;
    height: 16px;
    background: url(${bar}) no-repeat center;
    content: '';
  }

  input:hover ~ & {
    background-color: ${(props) =>
      props.color === 'primary'
        ? `${GlobalTokenColor.Primary500}`
        : `${GlobalTokenColor.Secondary300}`};
    border: none;
  }

  input:active ~ & {
    background: none;
    border: 1px solid
      ${(props) =>
        props.color === 'primary'
          ? `${GlobalTokenColor.Primary500}`
          : `${GlobalTokenColor.Secondary300}`};
  }

  input:disabled ~ & {
    background-color: ${(props) =>
      props.color === 'primary'
        ? `${GlobalTokenColor.Primary600Op40}`
        : `${GlobalTokenColor.Secondary500Op40}`};
    border: none;
  }
`;
