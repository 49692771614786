import React from 'react';
import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { callApi } from 'utils/ApiUtil';
import { BmkResponse } from 'models/cims/Bmk';

export const getBookMarkMenu = async () => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/common/bookmark`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
  };
  const response: CommonResponse<BmkResponse[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as BmkResponse[];
};

export const registBookMarkMenu = async (data?: BmkResponse) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/common/bookmark`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    bodyParams: { ...data },
  };
  const response: CommonResponse<string> = await callApi(request);

  return response;
};

export const deleteBookMarkMenu = async (bmkId?: number) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/common/bookmark/${bmkId}`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
  };
  const response: CommonResponse<string> = await callApi(request);

  return response;
};
