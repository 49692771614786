import { useEffect, useMemo, useState } from 'react';
import { array, bool, boolean, number, object, string } from 'yup';
import { useTranslation } from 'react-i18next';

const useMemberDetail = () => {
  const { t } = useTranslation();

  const regSchema = useMemo(() => {
    return object({
      // soprMgrCopCd: string().required(
      //   t('com.label.00507', '__법인코드는 필수 입력 항목입니다.') as string
      // ),
      soprMgrNm: string().required(
        t('com.label.00508', '__담당자 필수 입력 항목입니다.') as string
      ),
      // 영업부서 담당자 이름, 전화번호, 이메일 필수
      vdcpMgrNm1: string().required(
        t('com.label.00522', '__영업부서 담당자 이름은 필수 입력 항목입니다.') as string
      ),
      vdcpMgrEncPhn: string().required(
        t('com.label.00523', '__영업부서 담당자 전화번호 필수 입력 항목입니다.') as string
      ),
      vdcpMgrEmal1: string().required(
        t('com.label.00524', '__영업부서 담당자 이메일 필수 입력 항목입니다.') as string
      ),

      // 자금부서 담당자 이름, 전화번호, 이메일 필수
      vdcpFundMgrNm: string().required(
        t('com.label.00525', '__자금부서 담당자 이름은 필수 입력 항목입니다.') as string
      ),
      vdcpFundMgrEncPhn: string().required(
        t('com.label.00526', '__자금부서 담당자 전화번호 필수 입력 항목입니다.') as string
      ),
      vdcpFundMgrEmal: string().required(
        t('com.label.00527', '__자금부서 담당자 이메일 필수 입력 항목입니다.') as string
      ),
    });
  }, []);

  const rejectSchema = useMemo(() => {
    return object({
      // 회원가입 반려시 반려사유
      reason: string().required(
        t('com.label.00657', '__반려사유는 필수 입력 항목입니다.') as string
      ),
    });
  }, []);

  return {
    regSchema,
    rejectSchema,
  };
};

export default useMemberDetail;
