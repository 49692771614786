import { useEffect, useState } from 'react';
import useSessionStore from 'stores/useSessionStore';
import { VendorInfoVO } from 'models/vars/vendor/VendorInfoVO';
import { VendorReqInfoVO } from 'models/vars/vendor/VendorReqInfoVO';
import { getMemberVendor } from 'apis/vars/account/CommonAccount';
import KPaymentSchedulePage from './KPaymentSchedulePage';
import OPaymentSchedulePage from './OPaymentSchedulePage';

// 지급내역조회 화면 분기처리
// 로그인한 법인코드가 국내(C100)일 경우 > KPaymentSchedulePage.tsx
// 로그인한 법인코드가 해외(C100 외)일 경우 > OPaymentSchedulePage.tsx
const PaymentSchedulePage = () => {
  const { userId } = useSessionStore();
  const [vendorInfo, setVendorInfo] = useState<VendorInfoVO | undefined>(undefined);
  const [copCdInfo, setCopCdInfo] = useState<string>('');

  useEffect(() => {
    if (userId) {
      (async () => {
        await getMemberVendor(userId).then((res) => {
          if (res) {
            setVendorInfo(res);
            setCopCdInfo(res.copCd);
          }
        });
      })();
    }
  }, []);

  return (
    <>
      {vendorInfo &&
        (copCdInfo === 'C100' ? (
          <KPaymentSchedulePage vendorInfo={vendorInfo as VendorReqInfoVO} />
        ) : (
          <OPaymentSchedulePage vendorInfo={vendorInfo as VendorReqInfoVO} />
        ))}
    </>
  );
};

export default PaymentSchedulePage;
