import styled from '@emotion/styled';
import { Dialog } from '@mui/material';
import { FontColor, GlobalTokenColor } from 'ui/theme/Color';

interface PropsType {
  children?: React.ReactNode;
  open: boolean;
  onClose: (e, reason) => void;
  mMinWidth: number;
  mMaxWidth: number;
}

const ModalLayout = ({ children, open, onClose, mMinWidth, mMaxWidth }: PropsType) => {
  return (
    <DialogItem
      open={open}
      onClose={(e, reason) => {
        if (reason && reason === 'backdropClick') {
          return;
        }
        onClose(e, reason);
      }}
      mMinWidth={mMinWidth}
      mMaxWidth={mMaxWidth}
    >
      {children}
    </DialogItem>
  );
};

const DialogItem = styled(Dialog)<PropsType>`
  font-family: 'Spoqa Han Sans Neo';

  .MuiDialog-paper {
    min-width: ${(props) => props.mMinWidth}px;
    max-width: ${(props) => props.mMaxWidth}px;
    box-sizing: content-box;
    box-shadow: none;
    border-radius: 3px;
    border: 1px solid ${GlobalTokenColor.ESGrey400};
    color: ${FontColor.Default};
    word-break: keep-all;
  }

  .popupContent {
    padding: 0 24px;
    font-size: 13px;
    line-height: 19px;

    .searchBox {
      margin-bottom: 40px;
    }
  }

  .popupBottom {
    padding: 4px 24px 24px;
  }

  .buttonsTop {
    text-align: right;
    & + .section {
      margin-top: 20px;
    }
  }

  .ag-body-vertical-scroll-viewport {
    width: 17px !important;
    max-width: 17px !important;
    min-width: 17px !important;
    position: absolute;
    top: -24px;
    left: 0;
    height: calc(100% + 24px);
  }
`;

export default ModalLayout;
