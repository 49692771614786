import styled from '@emotion/styled';
import { GlobalTokenColor } from 'ui/theme/Color';
// import styled from 'styled-components';

export const DeliveryAgreeEn = () => {
  return (
    <>
      <PrivacyDiv>
        <p>
          This Terms and Conditions for Payment (“<b>T&C</b>”) is a legal binding agreement made by
          and between company, whether personally or on behalf of an entity (“<b>Company</b>”) and
          LG Energy Solution, Ltd. (“<b>LGES</b>”) concerning the payment to be made by LGES to
          Company. (“LGES” and “Company” are hereinafter referred to individually as a “<b>Party</b>
          ” and collectively as the “<b>Parties</b>”)
          <br />
          <br />
          <b>ARTICLE 1 PURPOSE</b>
          <br />
          The purpose of this T&C is to settle terms and conditions for LGES’s payment to Company
          when such payment is made through Financial Institution (the “<b>Bank</b>”). For the
          purpose of this T&C, the “Bank” shall mean any financial institution(s) designated by
          prior agreement between LGES and Company.
          <br />
          <br />
          <b>ARTICLE 2 SCOPE</b>
          <br />
          2.1 If there is any conflict or inconsistency between the provisions of this T&C and an
          agreement executed between the Parties, the provisions of such agreement shall prevail.
          <br />
          2.2 This T&C constitutes the entire understanding of the Parties relating to the subject
          hereof and supersedes any other previous agreement, communications, representations and
          understanding, whether written or oral, with respect to the subject matter hereof.
          <br />
          2.3 This T&C shall apply to cases only where LGES make a payment to Vendor (i) by cash or
          check (collectively, “<b>Cash</b>”); or (ii) through the electronic payment system.
          <br />
          <br />
          <b>ARTICLE 3 BANK ACCOUNT REGISTRATION</b>
          <br />
          Company shall open a free deposit account or electronic payment account in Bank (the “
          <b>Payment Account</b>”) in order to receive a payment from LGES, and shall register such
          account at LGES’s account registration system (http://vars.lgensol.com) (the “
          <b>Vendor Account Registration System</b>”); provided, that if LGES request Company to
          submit original documents, Company shall submit such documents to LGES immediately upon
          LGES’s request.
          <br />
          <br />
          <b>ARTICLE 4 PAYMENT</b>
          <br />
          4.1 When the payment is made by Cash, LGES shall remit such payment to the Payment Account
          registered by Company pursuant to Article 3.
          <br />
          4.2 When the payment is made through electronic payment system(for draft), LGES shall
          cause the Bank to make such payment to Company in designated payment date by delivering
          the payment request to the Bank; provided, however, that if Company requests LGES to make
          such payment before designated payment date, such payment shall be paid after the
          deduction of paid commission fee or loan interest in accordance with terms and conditions
          for electronic payment between executed by LGES and Company.
          <br />
          <br />
          <b>ARTICLE 5 PAYMENT AND RETURN OF MISTAKEN REMITTANCE</b>
          <br />
          5.1 LGES will not receive separate payment slips from Company if the payment is made by
          Cash or through the electronic payment system.
          <br />
          5.2 If any remittance of the payment is made mistakenly, Company shall notify immediately
          such mistaken remittance to LGES and return the amount of such mistaken remittance to
          LGES.
          <br />
          <br />
          <b>ARTICLE 6 PAYMENT WITHHOLDING</b>
          <br />
          LGES may withhold the payment if (i) Company fails to comply with Article 3, (ii) any
          information provided by Company under Article 3 is proved to be different, or (iii)
          Company fails to return the amount of the mistaken remittance pursuant to Article 5.
          <br />
          <br />
          <b>ARTICLE 7 INDEMNIFICATION</b>
          <br />
          7.1 The Parties hereby agree and acknowledge that LGES’s payment obligation shall be
          deemed to be fulfilled (i) at the time when a wire transfer is made if the payment is made
          by Cash; or (ii) at the time when the payment request is delivered to the Bank if the
          payment is made through the electronic payment system. The Parties further agree that LGES
          shall not be responsible for any issue between Company and the Bank with respect to
          including, but not limited to, payment, collection after LGES’s payment obligation is
          deemed to be fulfilled under this Article 7.1.
          <br />
          7.2 Should there be the change (“<b>Change</b>”) in the information of the Payment Account
          registered pursuant to Article 3, Company shall immediately notify LGES of such Change,
          and register such Change at the Vendor Account Registration System; provided, that if LGES
          request Company to submit documents with respect to such Change, Company shall submit such
          documents to LGES immediately upon LGES’s request.
          <br />
          7.3 The Parties agree and acknowledge that the payment shall be deemed to be made if such
          payment is made hereunder before (i) the Change under Article 7.2 is registered at the
          Account Registration System, or (i) any document requested by LGES with respect to the
          Change under Article 7.2 is submitted to LGES.
          <br />
          7.4 Company shall be solely responsible for any damages or losses due to (i) the delay of
          registration under Article 7.2, or (ii) the delay or failure of submission of documents
          requested by LGES under Article 7.2.
          <br />
          <br />
          <b>ARTICLE 8 TERM</b>
          <br />
          This T&C shall be effective from the Effective Date, and remain effective so long as a
          business relationship between the Parties shall persist.
        </p>
      </PrivacyDiv>
    </>
  );
};
const PrivacyDiv = styled.div`
  box-sizing: border-box;
  // border: 1px solid #ebeeed;
  border: 1px solid ${GlobalTokenColor.ESGrey300};
  width: 100%;
  height: 270px;
  overflow-y: auto;
  color: #1f1f1f;
  font-size: 13px;
  line-height: 20px;
  padding: 15px;
`;

const PrivacyTable = styled.table`
  margin: 10px;

  th,
  td {
    padding: 8px;
    border: 1px solid #ebeeed;
  }

  th {
    background: #f1f4f3;
    font-weight: 600;
  }

  td {
    vertical-align: top;
  }
`;
export default DeliveryAgreeEn;
