import { ColDef, ValueFormatterParams } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';

export type MenuManagementTableColType = {
  roleByMenuCol: ColDef<any>[];
  employeeByMenuCol: ColDef<any>[];
  departmentByMenuCol: ColDef<any>[];
};

const MenumanagementTable: () => MenuManagementTableColType = () => {
  const { t } = useTranslation();

  const roleByMenuCol: ColDef[] = [
    {
      headerName: 'No.',
      width: 30,
      valueFormatter: (params: ValueFormatterParams) => {
        return `${parseInt(params.node!.id!) + 1}`;
      },
      editable: false,
      filter: false,
    },
    {
      headerName: String(t('com.label.roleCd', '__역할코드')),
      field: 'roleCd',
      width: 150,
    },
    {
      headerName: String(t('com.label.roleNm', '__역할명')),
      field: 'roleNm',
      width: 200,
    },
    {
      headerName: String(t('com.label.roleDesc', '__역할설명')),
      field: 'roleDesc',
      width: 200,
    },
  ];

  const employeeByMenuCol: ColDef[] = [
    {
      headerName: 'No.',
      width: 30,
      valueFormatter: (params: ValueFormatterParams) => {
        return `${parseInt(params.node!.id!) + 1}`;
      },
      editable: false,
      filter: false,
    },
    {
      headerName: String(t('com.label.00694', '__아이디')),
      field: 'userId',
      width: 100,
    },
    {
      headerName: String(t('com.label.00012', '__이름')),
      field: 'empNm',
      width: 120,
    },
    {
      headerName: String(t('com.label.deptNm', '__부서명')),
      field: 'deptNm',
      width: 140,
    },
    {
      headerName: String(t('com.label.00090', '__이메일')),
      field: 'emlSvrDmnIfoNm',
    },
  ];

  const departmentByMenuCol: ColDef[] = [
    {
      headerName: 'No.',
      width: 30,
      valueFormatter: (params: ValueFormatterParams) => {
        return `${parseInt(params.node!.id!) + 1}`;
      },
      editable: false,
      filter: false,
    },
    {
      headerName: String(t('com.label.deptCd', '__부서코드')),
      field: 'deptCd',
    },
    {
      headerName: String(t('com.label.00096', '__법인코드')),
      field: 'copCd',
      width: 150,
    },
    {
      headerName: String(t('com.label.deptNm', '__부서명')),
      field: 'deptNm',
    },
  ];

  return {
    roleByMenuCol,
    employeeByMenuCol,
    departmentByMenuCol,
  };
};

export default MenumanagementTable;
