import { ColDef } from 'ag-grid-community';
import { DefaultCellTooltip } from 'components/grids/tooltip/DefaultCellTooltip';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useAbnormalMonitorDefs = () => {
  const { t } = useTranslation();

  const defaultColDef = useMemo((): ColDef => {
    return {
      sortable: false,
      resizable: true,
      cellStyle: { textAlign: 'center' },
      tooltipComponent: DefaultCellTooltip,
    };
  }, []);

  const colDefs: any = [
    {
      headerName: t('No.', 'No.'),
      valueGetter: 'node.rowIndex + 1',
      width: '56px',
      minWidth: 56,
    },
    {
      headerName: t('com.label.00086', '__거래처코드'),
      headerTooltip: t('com.label.00086', '__거래처코드'),
      field: 'vdcpCd',
      tooltipField: 'vdcpCd',
      width: '140px',
      minWidth: 140,
      cellStyle: { textAlign: 'left' },
    },
    {
      headerName: t('com.label.chgDtm', '__변경일자'),
      headerTooltip: t('com.label.chgDtm', '__변경일자'),
      field: 'regReqDt',
      tooltipField: 'regReqDt',
      width: '140px',
      minWidth: 140,
    },
    {
      headerName: t('com.label.vendCtryKey', '__거래처국가키'),
      headerTooltip: t('com.label.vendCtryKey', '__거래처국가키'),
      field: 'vdcpCtryCd',
      tooltipField: 'vdcpCtryCd',
      width: '100px',
      minWidth: 100,
    },
    {
      headerName: t('com.label.bankCtryKey', '__은행국가키'),
      headerTooltip: t('com.label.bankCtryKey', '__은행국가키'),
      field: 'bankCtryCd',
      tooltipField: 'bankCtryCd',
      width: '100px',
      minWidth: 100,
    },
    {
      headerName: t('com.label.00076', '__통화'),
      headerTooltip: t('com.label.00076', '__통화'),
      field: 'currCd',
      tooltipField: 'currCd',
      width: '100px',
      minWidth: 100,
    },
    {
      headerName: t('com.label.00065', '__은행'),
      headerTooltip: t('com.label.00065', '__은행'),
      field: 'bankNm',
      tooltipField: 'bankNm',
      cellStyle: { textAlign: 'left' },
      flex: 1,
      minWidth: 300,
    },
    {
      headerName: t('com.label.00070', '__계좌번호'),
      headerTooltip: t('com.label.00070', '__계좌번호'),
      field: 'encAccnNo',
      tooltipField: 'encAccnNo',
      cellStyle: { textAlign: 'left' },
      flex: 1,
      minWidth: 160,
    },
    {
      headerName: t('Swift', 'Swift'),
      headerTooltip: t('Swift', 'Swift'),
      field: 'swiftCd',
      tooltipField: 'swiftCd',
      width: '160px',
      minWidth: 160,
      cellStyle: { textAlign: 'left' },
    },
    {
      headerName: t('com.label.00095', '__담당자'),
      headerTooltip: t('com.label.00095', '__담당자'),
      field: 'vdcpMgrNm1',
      tooltipField: 'vdcpMgrNm1',
      width: '120px',
      minWidth: 120,
    },
    {
      headerName: t('com.label.00090', '__E-mail'),
      headerTooltip: t('com.label.00090', '__E-mail'),
      field: 'vdcpMgrEmal1',
      tooltipField: 'vdcpMgrEmal1',
      cellStyle: { textAlign: 'left' },
      width: '250px',
      minWidth: 140,
    },
    {
      headerName: t('com.label.prchMng', '__구매담당자 '),
      headerTooltip: t('com.label.prchMng', '__구매담당자 '),
      field: 'soprMgrNm',
      tooltipField: 'soprMgrNm',
      width: '120px',
      minWidth: 120,
    },
  ];

  return { defaultColDef, colDefs };
};
