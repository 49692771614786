import { useEffect, useMemo, useState } from 'react';
import { array, bool, boolean, number, object, string } from 'yup';
import { useTranslation } from 'react-i18next';

const useDmsRegist = () => {
  const { t } = useTranslation();

  const fileSchema = useMemo(() => {
    return object({
      fileId1: string()
        .required(t('com.label.00479', '__사업자등록증은 필수 입력 항목입니다.') as string)
        .test('fileId', '으아아아아아' as string, (value, ctx) => {
          if (!value) {
            return false;
          }
          return true;
        }),
      fileId2: string().required(
        t('com.label.00480', '__통장사본은 필수 입력 항목입니다.') as string
      ),
      fileId3: string().required(
        t('com.label.00481', '__어음약정확인서는 필수 입력 항목입니다.') as string
      ),
    });
  }, []);

  const regSchema = useMemo(() => {
    return object({
      bankCd: string().required(t('com.label.00482', '__은행은 필수 입력 항목입니다.') as string),
      dpstNm: string().required(t('com.label.00609', '__예금주는 필수 입력 항목입니다.') as string),
      //   dpstNm2: string().required(
      //     t('추가예금주명은 필수 입력 항목입니다.', '__추가예금주명은 필수 입력 항목입니다.') as string
      //   ),
      encAccnNo: string().required(
        t('com.label.00704', '__계좌번호 필수 입력 항목입니다.') as string
      ),
      // accnNoCertYn: string().test(
      //   'accnNoCertYn',
      //   t('계좌를 인증하세요.', '__계좌를 인증하세요.') as string,
      //   (value, ctx) => {
      //     if (value == 'N') {
      //       return false;
      //     }
      //     return true;
      //   }
      // ),
      accnUsegNm: string().required(
        t('com.label.00705', '__계좌용도는 필수 입력 항목입니다.') as string
      ),
    });
  }, []);

  return {
    regSchema,
    fileSchema,
  };
};

export default useDmsRegist;
