import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { AccountMgtVO } from 'models/vars/account/AccountMgtVO';
import { DomesitcAccountVO } from 'models/vars/account/DomesitcAccountVO';
import { callApi } from 'utils/ApiUtil';
import { callJwtApi } from 'utils/JwtApiUtil';

const PREFIX_URI = '/api/sec/account/domestic';

export const getDomesticAccounts = async (userId: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `${PREFIX_URI}/${userId}`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
  };
  const response: CommonResponse<any> = await callJwtApi(request);
  return response.successOrNot === 'Y' ? response.data : null;
};

export const getDomesticAccount = async (userId: string, accnRegReqId: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `${PREFIX_URI}/detail/${userId}`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({ accnRegReqId }),
  };
  const response: CommonResponse<DomesitcAccountVO> = await callJwtApi(request);
  return response.successOrNot === 'Y' ? (response.data as DomesitcAccountVO) : null;
};

export const registDomesticAccount = async (param, files, dftData) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `${PREFIX_URI}/regist-account`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    bodyParams: { ...param, ...files, dftData: dftData },
  };
  const response: CommonResponse = await callJwtApi(request);

  return response;
};

export const modifyDomesticAccount = async (param, files, bfData) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `${PREFIX_URI}/modify-account`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    bodyParams: { ...param, ...files, bfData: bfData },
  };
  const response: CommonResponse = await callJwtApi(request);

  return response;
};

export const removeDomesticAccount = async (accnRegReqId) => {
  const request: CommonRequest = {
    method: Method.DELETE,
    url: `${PREFIX_URI}/remove-account`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({ accnRegReqId }),
  };
  const response: CommonResponse = await callJwtApi(request);

  return response;
};

export const requestRegistDomesticAccount = async (param) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `${PREFIX_URI}/request-regist-account`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    bodyParams: { ...param },
  };
  const response: CommonResponse = await callJwtApi(request);

  return response;
};

// 계좌인증요청
export const getAccntApprv = async (param) => {
  console.log('인증요청 api 타니?? :: ', param);
  const request: CommonRequest = {
    method: Method.GET,
    url: `/api/sec/common/accn-valid`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: { ...param },
  };
  const response: CommonResponse = await callJwtApi(request);
  console.log('response 타니??', response);
  return response.successOrNot === 'Y' ? true : false;
};
