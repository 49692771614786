import styled from '@emotion/styled';
import { Link, useLocation } from 'react-router-dom';
import { GlobalTokenColor } from 'ui/theme/Color';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useEffect, useState, useCallback, useContext } from 'react';
import { Menu, MenuContextType, MenuEnum } from 'models/admin/Menu';
import { useTranslation } from 'react-i18next';
import useSessionStore from 'stores/useSessionStore';
import { MenuContext } from 'App';
import { getMenuAtchFiles } from 'apis/admin/MenuAtchFileApi';
import ManualModal from 'components/modals/common/ManualModal';
import { MenuAtchFileDesc } from 'models/admin/MenuAtchFile';
import { findFiles } from 'apis/admin/FileUpload';
import Titlegroup from 'components/molecule/Titlegroup';
import { TextSize, TextWeight } from 'ui/theme/CustomTypography';
import { GButton } from 'components/atom/button';
import question from '../asset/QuestionL.png';
import { ReactComponent as BreadCrumbArrow } from 'components/asset/BreadCrumbArrow.svg';
import TitlegroupVars from 'components/vars/common/TitlegroupVars';
import useAuthStore from 'stores/useAuthStore';
import { useNavigate } from 'react-router-dom';

type PropType = {
  showMenu?: boolean;
  showBorder?: boolean;
};

interface StyledSpanProps {
  cursor?: string;
  fontWeight?: number;
  verticalAlign?: string;
}

const PageHeaderLayout = ({ showMenu, showBorder }: PropType) => {
  const [parentMenus, setParentMenus] = useState<Menu[]>([]);
  const [manualFile, setManualFile] = useState<MenuAtchFileDesc[]>([]);
  const [fetchedFile, setFetchedFile] = useState<any>([]);
  const [openManualModal, setOpenManualModal] = useState<boolean>(false);
  const { pathname } = useLocation();
  const { menus, langCd } = useSessionStore();
  const { accessToken } = useAuthStore();
  const navigate = useNavigate();

  const menuContext = useContext<MenuContextType>(MenuContext);
  const { t } = useTranslation();

  useEffect(() => {
    const menu = menuContext.currentMenu;
  }, [langCd]);

  useEffect(() => {
    const menu = menuContext.currentPage;

    if (!menu) return;
    setParentMenus(findParentMenu(menu) ?? []);
  }, [menuContext.currentPage]);

  const findParentMenu = useCallback(
    (menu: Menu) => {
      const newParentMenus: Menu[] = [];
      if (menu.mnuUrl === '') {
        menuContext.handleMenuChange({ ...menuContext, selectedHeaderMenu: '' });
        setParentMenus(newParentMenus);
        return;
      }
      let cur = menus.find((item) => item.mnuId === menu.upprMnuId) as Menu;
      if (cur) {
        while (cur.mnuId !== '000000') {
          newParentMenus.unshift(cur);
          cur = menus.find((item) => item.mnuId === cur.upprMnuId) as Menu;
          if (!cur) break;
        }
        if (newParentMenus.length) {
          menuContext.handleMenuChange({
            ...menuContext,
            selectedHeaderMenu: newParentMenus[0].mnuId,
          });
        }
        return newParentMenus;
      }
    },
    [menuContext]
  );
  const linkHandler = (e, item) => {
    if (!item.mnuUrl) {
      if (accessToken) {
        menuContext.handleMenuChange({ ...menuContext, selectedHeaderMenu: '000139' });
        navigate('/account-regist', { replace: true });
      } else {
        navigate('/system/admin/member/member-list', { replace: true });
      }
      e.preventDefault();
    }
  };
  return (
    <>
      {menuContext.currentMenu && pathname != '/' && pathname != '/business/login' && (
        <>
          <HeaderSection showBorder={showBorder}>
            <TitlegroupVars selectedShape="ball" />

            {/* 0401 3차검수 : 회원가입 화면 BreadCrumbs 제거 */}
            {menuContext.currentPage.mnuId !== '000147' && (
              <BreadCrumbs>
                {menuContext.currentMenu.mnuNm !== MenuEnum.home && (
                  <>
                    <Link to="" onClick={(e) => linkHandler(e, '')}>
                      {MenuEnum.home}
                    </Link>
                    <BreadCrumbArrow />
                  </>
                )}
                {menuContext.currentPage.mnuId !== '000151' && (
                  <>
                    {parentMenus &&
                      parentMenus.map((item) => {
                        if (item.mnuUrl && item.mnuUrl != 'system/admin') {
                          return (
                            <span key={item.mnuId}>
                              <Link to={item.mnuUrl ?? ''} onClick={(e) => linkHandler(e, item)}>
                                {t(`${item.msgCtn}`, `${item.mnuNm}`)}
                              </Link>
                              <BreadCrumbArrow />
                            </span>
                          );
                        }
                        return (
                          <StyledSpan key={item.mnuId} cursor="default">
                            {t(`${item.msgCtn}`, `${item.mnuNm}`)}
                            <BreadCrumbArrow />
                          </StyledSpan>
                        );
                      })}
                  </>
                )}

                <StyledSpan cursor="default" fontWeight={600} verticalAlign="middle">
                  {t(`${menuContext.currentPage.msgCtn}`, `${menuContext.currentPage.mnuNm}`)}
                </StyledSpan>
              </BreadCrumbs>
            )}
          </HeaderSection>
        </>
      )}
    </>
  );
};

const HeaderSection = styled.div<PropType>`
  border-bottom: ${(props) =>
    props.showBorder ? `1px solid ${GlobalTokenColor.StatsuDone300}` : 'none'};
  justify-content: space-between;
  align-items: center;
  display: flex;
  min-height: 48px;
  background-color: ${GlobalTokenColor.ESGreyWHite};
`;

const BreadCrumbs = styled.div`
  display: inline-block;
  float: right;
  height: 32px;
  font-size: ${TextSize.Small};
  font-weight: ${TextWeight.Normal};
  line-height: 28px;
  color: ${GlobalTokenColor.Primary600};
  svg {
    margin: 0 2px;
    vertical-align: middle;
    fill: ${GlobalTokenColor.ESGrey700};
    font-size: ${TextSize.Large};
  }
  margin-top: 12px;
  margin-bottom: 4px;
`;

const StyledSpan = styled.span<StyledSpanProps>`
  cursor: ${(props) => props.cursor || 'default'};
  font-weight: ${(props) => props.fontWeight || 400};
  vertical-align: ${(props) => props.verticalAlign || 'middle'};
`;

export default PageHeaderLayout;
