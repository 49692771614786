import styled from '@emotion/styled';
import { GlobalTokenColor } from 'ui/theme/Color';
// import styled from 'styled-components';

export const DeliveryAgreeKo = () => {
  return (
    <>
      <PrivacyDiv>
        <p>
          주식회사 LG에너지솔루션(이하 “LG에너지솔루션”이라 한다)과 귀사(이하 “협력사”라 한다)는
          “협력사”가 “LG에너지솔루션”에게 납품한 물품 등의 대금지급에 관하여 다음과 같이 약정한다.
          <br />
          <br />
          - 다 음 -
          <br />
          <br />
          <b>제 1 조 (목 적)</b>
          <br />
          본 약정은 “LG에너지솔루션”이 “협력사”에 대한 대금을 제3의 금융기관(이하 “은행”이라 한다)을
          통해 지급함에 있어서 “LG에너지솔루션”과 “협력사” 간 필요한 사항을 정하는데 그 목적이 있다.
          <br />
          여기서 “은행”은 “LG에너지솔루션”과 “협력사”가 사전 협의하여 지정한 금융기관을 말하며
          복수로 지정할 수 있다.
          <br />
          <br />
          <b>제 2 조 (적용범위)</b>
          <br />
          ① “LG에너지솔루션”과 “협력사” 간 체결한 개별 계약에서의 지급조건이 본 약정의 내용과 다른
          경우, 개별계약이 우선한다.
          <br />
          ② 본 약정은 이미 체결한 기존 ‘납품대급 지급에 관한 약정서’ 보다 우선하여 적용한다.
          <br />
          ③ 본 약정은 “LG에너지솔루션”이 “협력사”에게 대금을 지급할 때, 현금이나 당좌수표(이하
          “현금”이라 한다)에 의한 지급과 전자결제제도를 이용한 지급인 경우에 한하여 적용한다.
          <br />
          <br />
          <b>제 3 조 (계좌개설 및 등록)</b>
          <br />
          “협력사”는 “LG에너지솔루션”으로부터 대금을 수령하기 위하여 “은행”에 자유 입출금식
          예금계좌, 또는 전자결제계좌(이하 “결제계좌”라 한다)를 개설하고, “LG에너지솔루션”의
          계좌등록시스템(http://vars.lgensol.com, 이하 “계좌등록시스템”)을 통해 위 수금용 “결제계좌”
          정보를 등록한다.
          <br />
          다만 “LG에너지솔루션”이 원본서류를 요청하는 경우, “협력사”는 요청사항에 따른 서류를 갖추어
          “LG에너지솔루션”에게 즉시 제출하여야 한다.
          <br />
          <br />
          <b>제 4 조 (대금 지급)</b>
          <br />
          ① 대금을 현금으로 지급할 경우. “LG에너지솔루션”은 제3조에 따라 “협력사”가 등록한
          “결제계좌”에 입금하는 방법으로 대금을 지급한다.
          <br />
          ② 대금을 전자결제제도를 이용하여 지급하는 경우, “LG에너지솔루션”은 “은행”에게
          대금지급명세를 통보하여, 지정된 결제일에 “은행”이 “협력사”에게 대금을 지급하도록 한다.
          <br />
          다만 “협력사”가 지정된 결제일 이전에 선지급 받기를 원하는 경우, “협력사”와 “은행”사이에
          체결된 ‘전자결제약정서’에 따라 선지급수수료 또는 대출이자를 공제하고 지급하는 것으로 한다.
          <br />
          <br />
          <b>제 5 조 (입금 및 착오입금 반환)</b>
          <br />
          ① “LG에너지솔루션”은 현금과 전자결제제도에 따른 대급 지급의 경우 “협력사”로부터 별도로
          입금표를 수취하지 않는다.
          <br />
          ② 본 약정에 따른 대금지급 시 “협력사”의 “결제계좌”에 착오입금분이 발생하였을 경우,
          “협력사”는 “LG에너지솔루션”에 이를 즉시 통보하고 착오입금분을 반환하여야 한다.
          <br />
          <br />
          <b>제 6 조 (지급보류)</b>
          <br />
          “LG에너지솔루션”은 “협력사”가 제3조에서 정한 사항을 이행하지 않거나, 이행한 내용이 사실과
          다른 경우, 또는 제5조에서 정한 착오입금분의 반환이 즉시 이루어지지 않는 경우, “협력사”에
          대한 대금의 지급을 보류할 수 있다.
          <br />
          <br />
          <b>제 7 조 (면 책)</b>
          <br />
          ① “LG에너지솔루션”이 제4조에 의한 대금지급 시, 현금 지급의 경우 입금이 완료된 시점,
          전자결제제도의 경우에는 “은행”에게 대금지급명세를 인도한 시점에 “협력사”에 대한 대금
          지급의무를 전부 이행한 것으로 보며, 그 시점 이후 “협력사”와 “은행” 간의 보관, 지불, 추심
          등에 관한 모든 사항에 대하여 “LG에너지솔루션”은 책임을 지지 않는다.
          <br />
          ② “협력사”는 제3조에 의하여 등록한 ”결제계좌” 정보에 변경(이하 ‘변경사항’)이 필요할 경우
          즉시 “LG에너지솔루션”에게 변경사항을 통보하여야 하며, 해당 변경사항을 “계좌등록시스템”을
          통하여 등록하여야 한다. 또한 “협력사”는 “LG에너지솔루션”이 변경사항에 관련된 필요서류를
          요청하는 경우, 해당 서류를 즉시 제출하여야 한다.
          <br />
          ③ 변경사항이 “계좌등록시스템”에 등록 완료되기 전 또는 위 필요서류를 “LG에너지솔루션”이
          수취하기 전에 “LG에너지솔루션”이 변경사항 이전의 방법으로 대금을 지급한 것은 정당한
          지급으로 본다.
          <br />
          ④ “협력사”가 변경사항 등록 또는 필요서류 제출의 지연이나 불이행으로 인해 발생한 모든
          불이익은 “협력사”가 단독으로 부담한다.
          <br />
          <br />
          <b>제 8 조 (약정기간)</b>
          <br />본 약정의 기간은 본 약정의 체결일로부터 “LG에너지솔루션”과 “협력사” 간 거래가
          지속되는 동안 유효한 것으로 한다.
        </p>
      </PrivacyDiv>
    </>
  );
};
const PrivacyDiv = styled.div`
  box-sizing: border-box;
  // border: 1px solid #ebeeed;
  border: 1px solid ${GlobalTokenColor.ESGrey300};
  width: 100%;
  height: 270px;
  overflow-y: auto;
  color: #1f1f1f;
  font-size: 13px;
  line-height: 20px;
  padding: 15px;
`;

const PrivacyTable = styled.table`
  margin: 10px;

  th,
  td {
    padding: 8px;
    border: 1px solid #ebeeed;
  }

  th {
    background: #f1f4f3;
    font-weight: 600;
  }

  td {
    vertical-align: top;
  }
`;
export default DeliveryAgreeKo;
