import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FontColor, BorderColor, BgColor } from 'ui/theme/Color';

export const CustomSelect = styled.select`
  width: 100%;
  min-width: 80px;
  height: 32px;
  padding-left: 6px;
  border-radius: 3px;
  border: solid 1px ${BorderColor.Form};
  background-color: #fff;
  font-size: 13px;
  font-family: 'Spoqa Han Sans Neo', '맑은 고딕', 'malgun gothic', 'AppleGothicNeoSD',
    'Apple SD 산돌고딕 Neo', 'Microsoft NeoGothic', 'Droid sans', sans-serif;
  color: ${FontColor.Gray400};

  &.widthAuto {
    width: auto;
    min-width: 50px;
  }

  & + &,
  input + & {
    margin-left: 5px;
  }

  &:focus {
    outline: 0;
  }

  &.width120 {
    min-width: 120px;
    width: 120px;
  }

  &.importwidth120 {
    min-width: 120px;
    width: 120px !important;
  }

  &.width280 {
    min-width: 280px;
    width: 280px;
  }

  &.width30p {
    min-width: 30%;
    width: 30%;
  }

  &.width50p {
    min-width: 50%;
    width: 50%;
  }

  & + .unit {
    margin: 0 15px 0 5px;
  }

  label + & {
    margin-left: 10px;
  }

  & + label {
    margin-left: 20px;
  }

  &.marginL0 {
    margin-left: 0;
  }
  & + .inline-flex {
    margin: 0 5px;
  }
`;

export const sel = {
  select: css`
    .MuiSelect-root {
      border: 0;
    }

    .MuiSelect-select {
      height: 32px;
      border-radius: 0;
      padding: 4px 10px 2px;
      border-radius: 3px;
      border: solid 1px #b9bcbb;
      font-size: 13px;
      font-family: 'Spoqa Han Sans Neo', '맑은 고딕', 'malgun gothic', 'AppleGothicNeoSD',
        'Apple SD 산돌고딕 Neo', 'Microsoft NeoGothic', 'Droid sans', sans-serif;
      color: #1f1f1f;
      background-color: ${BgColor.White};
      box-sizing: border-box;

      &:focus {
        border-radius: 3px;
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border: 0;
      top: 1px;
      bottom: 1px;
      left: 1px;
      right: 1px;
    }
  `,
};
