import { DefaultCellTooltip } from 'components/grids/tooltip/DefaultCellTooltip';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ColDef } from 'ag-grid-community';
import radioIcon from 'components/asset/RadioColumn.svg';
import { ButtonCellRenderer, IButtonCellRendererParams } from 'components/grids/ButtonCellRenderer';

export const useColumnDetailDefs = () => {
  const { t } = useTranslation();

  const defaultColDef = useMemo((): ColDef => {
    return {
      sortable: false,
      resizable: true,
      cellStyle: { textAlign: 'center' },
      tooltipComponent: DefaultCellTooltip,
    };
  }, []);

  const colDefs = useMemo((): any[] => {
    return [
      {
        headerName: 'check',
        headerComponentParams: {
          template: `<img src=${radioIcon} alt=''/>`,
        },
        width: '28px !important',
        height: '24px !important',
        padding: '2px 6px',
        resizable: false,
        headerCheckboxSelection: true,
        showDisabledCheckboxes: true,
        checkboxSelection: true,
        flex: 0,
        cellStyle: {
          display: 'flex',
          justifyContent: 'center',
        },
      },
      {
        headerName: t('컬럼1코드', 'No.'),
        width: 56,
        field: 'column1',
        tooltipField: 'column1',
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: t('컬럼2코드', '계좌종류'),
        width: 100,
        field: 'column2',
        tooltipField: 'column2',
        cellStyle: { textAlign: 'left' },
      },
      {
        headerName: t('컬럼3코드', '은행'),
        flex: 1,
        minWidth: 310,
        field: 'column3',
        tooltipField: 'column3',
        cellStyle: { textAlign: 'left' },
      },
      {
        headerName: '주계좌',
        headerComponentParams: {
          template: `<p>주계좌</p>`,
        },
        width: '80px !important',
        height: '24px !important',
        padding: '2px 6px',
        resizable: false,
        headerCheckboxSelection: false,
        showDisabledCheckboxes: true,
        checkboxSelection: true,
        flex: 0,
        cellStyle: {
          display: 'flex',
          justifyContent: 'center',
          width: '80px !important',
          height: '24px',
        },
      },
      {
        headerName: t('컬럼5코드', '예금주'),
        width: 100,
        field: 'column5',
        tooltipField: 'column5',
        cellStyle: { textAlign: 'left' },
      },
      {
        headerName: t('컬럼6코드', '계좌번호'),
        flex: 1,
        minWidth: 310,
        field: 'column6',
        tooltipField: 'column6',
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: t('컬럼8코드', '계좌인증여부'),
        width: 100,
        field: 'column7',
        tooltipField: 'column7',
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: t('컬럼9코드', '계좌용도'),
        flex: 1,
        minWidth: 310,
        field: 'column9',
        tooltipField: 'column9',
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: t('컬럼10코드', '통화'),
        width: 64,
        field: 'column10',
        tooltipField: 'column10',
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: t('컬럼11코드', '진행상태'),
        width: 160,
        field: 'column11',
        tooltipField: 'column11',
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: t('컬럼12코드', '결재요청일시'),
        width: 120,
        field: 'column10',
        tooltipField: 'column10',
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: t('컬럼13코드', '첨부파일'),
        width: 80,
        field: 'column13',
        tooltipField: 'column13',
        cellRenderer: ButtonCellRenderer,
        cellRendererParams: (params: IButtonCellRendererParams) => {
          return {
            label: '상세보기',
            btnStyled: 'outline',
            btnColor: 'normal',
            ...params,
          } as IButtonCellRendererParams;
        },
      },
    ];
  }, []);

  const handleAprvDetailBtn = (data) => {
    console.log('화면 연결이 필요합니다.');
  };

  return { defaultColDef, colDefs };
};
