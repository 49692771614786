import { ButtonProps } from '@mui/material/Button/Button';
import { Button } from '@mui/material';

export const GInputIconButton = (props: ButtonProps) => {
  let classNames = 'btn iconBtn noBorder';

  switch (props.color) {
    case 'primary':
      switch (props.variant) {
        case 'contained':
          switch (props.size) {
            case 'large':
              classNames += ' btnPrimaryContainedLarge';
              break;
            case 'medium':
              classNames += ' btnPrimaryContainedMedium';
              break;
            case 'small':
              classNames += ' btnPrimaryContainedSmall sm';
              break;
          }
          break;
        case 'outlined':
          switch (props.size) {
            case 'large':
              classNames += ' btnPrimaryOutlinedLarge';
              break;
            case 'medium':
              classNames += ' btnPrimaryOutlinedMedium';
              break;
            case 'small':
              classNames += ' btnPrimaryOutlinedSmall sm';
              break;
          }
          break;
      }
      break;
    case 'secondary':
      switch (props.variant) {
        case 'contained':
          switch (props.size) {
            case 'large':
              classNames += ' btnSecondaryContainedLarge';
              break;
            case 'medium':
              classNames += ' btnSecondaryContainedMedium';
              break;
            case 'small':
              classNames += ' btnSecondaryContainedSmall sm';
              break;
          }
          break;
        case 'outlined':
          switch (props.size) {
            case 'large':
              classNames += ' btnSecondaryOutlinedLarge';
              break;
            case 'medium':
              classNames += ' btnSecondaryOutlinedMedium';
              break;
            case 'small':
              classNames += ' btnSecondaryOutlinedSmall sm';
              break;
          }
          break;
      }
      break;
    default:
      break;
  }

  return <Button {...props} disableRipple className={classNames} />;
};
