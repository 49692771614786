import { Department } from '../../models/admin/Department';
import { CommonRequest, CommonResponse, Method, ServiceName } from '../../models/common/RestApi';
import { callApi } from 'utils/ApiUtil';

export const getRoleDepartment = async (roleCdParam: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/role/departments`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({ roleCd: roleCdParam }),
  };
  const response: CommonResponse<Department[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as Department[];
};

export const insertRoleDepartment = async (roleCdParam: string, deptCdParam: string[]) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/role/departments`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    bodyParams: { roleCd: roleCdParam, deptCdList: deptCdParam },
  };
  const response: CommonResponse<any> = await callApi(request);

  return response.successOrNot === 'Y' ? response.data : null;
};

export const deleteRoleDepartment = async (roleCdParam: string, deptCdParam: string[]) => {
  const queryParams = new URLSearchParams();
  queryParams.append('roleCd', roleCdParam);
  deptCdParam.forEach((deptCd) => {
    queryParams.append('deptCdList', deptCd);
  });

  const request: CommonRequest = {
    method: Method.DELETE,
    url: `/v1/role/departments`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: queryParams,
  };

  const response: CommonResponse<any> = await callApi(request);

  return response.successOrNot === 'Y' ? response.data : null;
};
