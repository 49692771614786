import styled from '@emotion/styled';
import { fontWeight } from 'html2canvas/dist/types/css/property-descriptors/font-weight';
import { GlobalTokenColor } from 'ui/theme/Color';
// import styled from 'styled-components';

export const PrivacyAgreeKo = () => {
  return (
    <>
      <PrivacyDiv>
        <p>
          LG에너지솔루션(이하 &#39;회사&#39;)은 지급계좌등록 서비스(이하 &#39;서비스&#39;)를
          이용하는 경우 아래와 같이 개인정보를 수집·이용(및 제공)합니다.
          <br />
          회사는 이용자들의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 누출, 변조 및 훼손되지
          않도록 다양한 기술적 보호조치를 강구하고 있습니다. 상세한 사항은 홈페이지에 게시된
          &quot;개인정보 처리방침&quot;을 참조하시기 바랍니다.
        </p>
        <PrivacyTable>
          <thead>
            <tr>
              <th>구분</th>
              <th>수집 · 이용 목적</th>
              <th colSpan={2}>수집 · 이용 항목</th>
              <th>보유 · 이용 기간</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>회원 관리</td>
              <td>시스템 사용을 위한 회원 가입 및 탈퇴 처리</td>
              <th>필수</th>
              <td>
                비밀번호, 주소(법인의 경우 회사 소재지), 법인 소재지 국가
                <br />
                <br />□ 담당자에 한함 : 성명, 이메일, 회사명
              </td>
              <td rowSpan={2} style={{ color: 'blue', fontWeight: 'bold' }}>
                당사와의 계속적인 거래관계 종결시까지 또는 별도의 삭제 요청이 있을시까지
              </td>
            </tr>
            <tr>
              <td>계좌 관리</td>
              <td>계좌 인증</td>
              <th>필수</th>
              <td>개인 Vendor에 한함 : 은행명, 예금주, 계좌번호, 생년월일</td>
            </tr>
          </tbody>
        </PrivacyTable>
        <p>
          귀하께서는 개인정보 수집∙이용에 대한 동의를 거부하실 수 있으나, 필수항목의 경우 서비스
          제공에 필수적으로 필요한 정보이므로, 동의를 거부하실 경우 회원가입, 서비스 이용 등을 하실
          수 없습니다.
        </p>
      </PrivacyDiv>
    </>
  );
};
const PrivacyDiv = styled.div`
  box-sizing: border-box;
  // border: 1px solid #ebeeed;
  border: 1px solid ${GlobalTokenColor.ESGrey300};
  width: 100%;
  height: 270px;
  overflow-y: auto;
  color: #1f1f1f;
  font-size: 13px;
  line-height: 20px;
  padding: 15px;
`;

const PrivacyTable = styled.table`
  margin: 10px;

  th,
  td {
    padding: 8px;
    border: 1px solid #ebeeed;
  }

  th {
    background: #f1f4f3;
    font-weight: 600;
  }

  td {
    vertical-align: top;
  }
`;
export default PrivacyAgreeKo;
