import FobiddenPage from 'pages/common/FobiddenPage';
import { useLocation, Outlet } from 'react-router-dom';
import useSessionStore from 'stores/useSessionStore';

const ProtectRoute = () => {
  const { menus } = useSessionStore();

  const { pathname } = useLocation();
  const isAccess = menus.map((menu) => menu.mnuUrl).includes(pathname);
  if (isAccess) {
    return <Outlet />;
  } else {
    return <FobiddenPage />;
  }
};

export default ProtectRoute;
