import { GButtonBox, GPopupTitle } from 'components/layouts/GLayoutBox';
import { DialogTitleItem } from 'components/layouts/GModalPopup';
import ModalLayout from 'components/layouts/ModalLayout';
import { DialogActions, DialogContent } from '@mui/material';
import { GButton } from 'components/atom/button';
import Save from 'components/asset/images/Confirm.svg';
import { GbasicTable, GbasicTableWrap } from 'components/tables/GTable';
import { GLabelAtom } from 'components/atom/label';
import SubTitleBox from 'components/molecule/SubTitleBox';
import { SubTitleBtnArea } from 'components/organism/SubTitleBtnArea';
import Tag from 'components/atom/tag';
import FileDownload from 'components/molecule/FileDownload';

type DepartmentModalProps = {
  open: boolean;
  singleSelect?: boolean;
  close: () => void;
  save: () => void;
};

const InquiryKrwAccount = (props: DepartmentModalProps) => {
  const handleClose = () => {
    props.close();
  };

  return (
    <ModalLayout open={props.open} onClose={handleClose} mMinWidth={1000} mMaxWidth={1000}>
      <DialogTitleItem id="customized-dialog-title" onClose={handleClose}>
        <GPopupTitle title2="계좌정보상세" />
      </DialogTitleItem>
      <DialogContent className="popupContent">
        <SubTitleBtnArea>
          <SubTitleBox content="계좌상세정보" showSubTitle={true} isVisbleSelect={false} />
        </SubTitleBtnArea>
        <GbasicTableWrap isBottomMgn={false}>
          <GbasicTable>
            <colgroup>
              <col style={{ width: '160px' }} />
              <col style={{ width: 'auto' }} />
              <col style={{ width: '160px' }} />
              <col style={{ width: 'auto' }} />
            </colgroup>
            <tbody>
              <tr>
                <th>
                  <GLabelAtom align="left">계좌종류</GLabelAtom>
                </th>
                <td>현금계좌</td>
                <th>
                  <GLabelAtom align="left">진행상태</GLabelAtom>
                </th>
                <td>
                  <Tag content="임시저장" status="waiting" />
                </td>
              </tr>
              <tr>
                <th>
                  <GLabelAtom align="left">은행</GLabelAtom>
                </th>
                <td colSpan={3}>우리은행</td>
              </tr>
              <tr>
                <th>
                  <GLabelAtom align="left">예금주</GLabelAtom>
                </th>
                <td colSpan={3}>김금주</td>
              </tr>
              <tr>
                <th>
                  <GLabelAtom align="left">계좌번호</GLabelAtom>
                </th>
                <td>105-12345-123456478</td>
                <th>
                  <GLabelAtom align="left">통화</GLabelAtom>
                </th>
                <td>KRW</td>
              </tr>
              <tr>
                <th>
                  <GLabelAtom align="left">계좌용도</GLabelAtom>
                </th>
                <td colSpan={3}>법인용</td>
              </tr>
              <tr>
                <th>
                  <GLabelAtom align="left">사업자등록증</GLabelAtom>
                </th>
                <td colSpan={3}>
                  <FileDownload atchFileGrId="test" />
                </td>
              </tr>
              <tr>
                <th>
                  <GLabelAtom align="left">통장사본</GLabelAtom>
                </th>
                <td colSpan={3}></td>
              </tr>
              <tr>
                <th>
                  <GLabelAtom align="left">어음약정확인서</GLabelAtom>
                </th>
                <td colSpan={3}></td>
              </tr>
              {/* <tr>
                <th>
                  <GLabelAtom align="left">기타1</GLabelAtom>
                </th>
                <td colSpan={3}></td>
              </tr> */}
              <tr>
                <th>
                  <GLabelAtom align="left">기타</GLabelAtom>
                </th>
                <td colSpan={3}></td>
              </tr>
            </tbody>
          </GbasicTable>
        </GbasicTableWrap>
      </DialogContent>
      <DialogActions className="popupBottom">
        <GButtonBox>
          <GButton
            txt="수정"
            sizes="medium"
            onClick={handleClose}
            btnstyled="outline"
            btnColor="normal"
            hasImg={false}
            hasTxt={true}
          />
          <GButton
            txt="전산등록요청"
            sizes="medium"
            chkImg={Save}
            btnstyled="contained"
            btnColor="primary"
            hasImg={true}
            hasTxt={true}
          />
        </GButtonBox>
      </DialogActions>
    </ModalLayout>
  );
};

export default InquiryKrwAccount;
