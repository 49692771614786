import { ColDef } from 'ag-grid-community';
import { DefaultCellTooltip } from 'components/grids/tooltip/DefaultCellTooltip';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ITagCellRendererParams, TagCellRenderer } from 'components/grids/TagCellRenderer';

export const usePaymentScheduledDetailsDefs = () => {
  const { t } = useTranslation();

  const defaultColDef = useMemo((): ColDef => {
    return {
      sortable: false,
      resizable: true,
      cellStyle: { textAlign: 'center' },
      tooltipComponent: DefaultCellTooltip,
    };
  }, []);

  const colDefs = useMemo((): any[] => {
    return [
      {
        headerName: t('컬럼1코드', '증빙일'),
        field: 'column1',
        tooltipField: 'column1',
        cellStyle: { display: 'flex', justifyContent: 'center' },
        flex: 1,
      },
      {
        headerName: t('컬럼2코드', '적요'),
        field: 'column2',
        tooltipField: 'column2',
        cellStyle: { display: 'flex', justifyContent: 'left' },
        flex: 1,
      },
      {
        headerName: t('컬럼3코드', '상태'),
        cellStyle: { display: 'flex', justifyContent: 'center' },
        flex: 1,
        cellRenderer: TagCellRenderer,
        cellRendererParams: (params: ITagCellRendererParams) => {
          return {
            label: '지급보류',
            status: 'end',
            ...params,
          } as ITagCellRendererParams;
          //   return {
          //     label: '지급예정',
          //     status: 'waiting',
          //     ...params,
          //   } as ITagCellRendererParams;
        },
      },
      {
        headerName: t('컬럼4코드', 'PO'),
        field: 'column4',
        tooltipField: 'column4',
        cellStyle: { display: 'flex', justifyContent: 'center' },
        flex: 1,
      },
      {
        headerName: t('컬럼5코드', '구매담당자'),
        field: 'column5',
        tooltipField: 'column5',
        cellStyle: { display: 'flex', justifyContent: 'center' },
        flex: 1,
      },
      {
        headerName: t('컬럼6코드', 'AP DOC'),
        field: 'column6',
        tooltipField: 'column6',
        cellStyle: { display: 'flex', justifyContent: 'right' },
        flex: 1,
      },
      {
        headerName: t('컬럼7코드', '지급조건'),
        field: 'column7',
        tooltipField: 'column7',
        cellStyle: { display: 'flex', justifyContent: 'center' },
        flex: 1,
      },
    ];
  }, []);

  const handleAprvDetailBtn = (data) => {
    console.log('화면 연결이 필요합니다.');
  };

  return { defaultColDef, colDefs };
};
