import styled from '@emotion/styled';
import useAuthStore from 'stores/useAuthStore';
import New from 'components/asset/images/Penw.svg';
import Delete from 'components/asset/images/Delete.svg';
import { GButton } from 'components/atom/button';
import { GPagination, PaginationRef } from 'components/layouts/GPagination';
import GButtonLayout from 'components/molecule/GbuttonLayout';
import SubTitleBox from 'components/molecule/SubTitleBox';
import { SubTitleBtnArea } from 'components/organism/SubTitleBtnArea';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import { useQnaGridColumnDef } from './hooks/QnaListGridColumnDef';
import { deleteAdminQnaListApi, getAdminQnaListApi } from 'apis/vars/qna/QnaAdminApi';
import { GButtonBox } from 'components/layouts/GLayoutBox';
import { useCommonModal } from 'hooks/useCommonModal';
import { useTranslation } from 'react-i18next';
import { getCommonCodes } from 'apis/admin/CommonCode';
import { CommonCode } from 'models/admin/CommonCode';
import { getUserQnaListApi } from 'apis/vars/qna/QnaUserApi';
import { getJwtCommonCodes } from 'apis/vars/common/Common';
import { CellClickedEvent } from 'ag-grid-community';
import { useLoading } from 'components/process/Loading';
import EmptyState from 'components/atom/emptyState';
import { GContentGridQna } from 'components/vars/qna/GContentGridQna';
import { useMessageBar } from 'components/process/MessageBar';
import { GridFlexWrap } from 'components/common/pagination/GPaginationNew';
import useSessionStore from 'stores/useSessionStore';

export type QnaSearchParams = {
  pageSize?: number;
  start?: number;
};

export interface QnaSearchRes {
  totalCount: number;
  list: QnaList[];
}

export interface QnaList {
  no: number;
  bbmNo: number; // qna번호(original)
  bbmTitNm: string; // 제목
  dataInsUserId: string; // 게시자id
  dataInsUserNm: string; // 게시자nm
  vdcpNm: string; // 업체명(일반사용자)
  atchFileExist: string; // 첨부파일 여부
  dataInsDtm: string; // 게시일
  replyCnt: string; // 댓글 수
}

const QnaListPage = () => {
  const { t } = useTranslation();
  const { accessToken } = useAuthStore();
  const { openLoading } = useLoading();
  const { openCommonModal } = useCommonModal();
  const { openMessageBar } = useMessageBar();
  const { roleCodes } = useSessionStore();

  const navigate = useNavigate();
  const gridApiRef = useRef<any>(null);
  const [gridData, setGridData] = useState<QnaList[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const { defaultColDef, colDefs } = useQnaGridColumnDef();

  const paginationRef = useRef<PaginationRef>(null);
  const [pageNo, setPageNo] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(15);
  const [pageSizeList, setPageSizeList] = useState<any>([]);

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectBbmNoList, setSelectBbmNoList] = useState<number[]>([]);

  const getPageSizeData = useCallback(() => {
    if (accessToken) {
      getJwtCommonCodes('PG_ROW_CNT_CD').then((result: CommonCode[]) => {
        const pSizeList = result.map((item) => item.cmnCdNm).filter(Boolean);
        setPageSizeList(pSizeList);
      });
    } else {
      getCommonCodes('PG_ROW_CNT_CD').then((result: CommonCode[]) => {
        const pSizeList = result.map((item) => item.cmnCdNm).filter(Boolean);
        setPageSizeList(pSizeList);
      });
    }
  }, []);

  const getSearchParams = (start?: number, pageSizeParam?: number) => {
    return {
      start: start !== undefined ? start : (pageNo - 1) * pageSize,
      pageSize: pageSizeParam !== undefined ? pageSizeParam : pageSize,
    };
  };

  const getQnaListData = (start?: number, pageSizeParam?: number) => {
    if (accessToken) {
      getUserQnaListApi(getSearchParams(start, pageSizeParam)).then((res) => {
        setGridData(res.list);
        setTotalCount(res.totalCount);
      });
    } else {
      getAdminQnaListApi(getSearchParams(start, pageSizeParam)).then((res) => {
        setGridData(res.list);
        setTotalCount(res.totalCount);
      });
    }
  };

  useEffect(() => {
    getPageSizeData();
    getQnaListData();
  }, []);

  const handlePageNoClick = (no: number) => {
    setPageNo(no);
    getQnaListData(Number((no - 1) * pageSize), Number(pageSize));
  };

  const handlePageSize = (value: number) => {
    paginationRef.current?.setPageSize(value);
    setPageSize(value);
    setPageNo(1);
    getQnaListData(0, Number(value));
  };

  const handleChangePage = () => {
    navigate('/qna/regist');
  };

  const handleDelete = () => {
    if (selectedRows.length == 0) {
      openMessageBar('warning', t('com.label.00185', '삭제할 게시글을 선택해주세요.'));
    } else {
      openCommonModal({
        modalType: 'confirm',
        content: t('com.label.00188', '__선택한 Q&A 게시글을 삭제하시겠습니까?'),
        noCallback: () => {
          return;
        },
        yesCallback: async () => {
          openLoading(true);
          return deleteAdminQnaListApi(selectBbmNoList)
            .then((res) => {
              if (res === true) {
                getQnaListData();
                openLoading(false);
                openMessageBar('success', t('com.label.00179', '삭제되었습니다.'));
              }
            })
            .catch((error) => {
              openLoading(false);
              console.error('Error deleting row:', error);
            });
        },
      });
    }
  };

  const onSelectionChanged = (event) => {
    const selectedNodes = event.api.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data);
    const selectedBbmNoList = selectedNodes.map((node) => node.data.bbmNo);
    setSelectBbmNoList(selectedBbmNoList);
    setSelectedRows(selectedData);
  };

  const gridOptions = {
    columnDefs: colDefs,
    suppressCellBorders: true,
    suppressRowBorders: true,
    onSelectionChanged: onSelectionChanged,
  };

  return (
    <>
      <SubTitleBtnArea>
        <SubTitleBox
          isVisibleComment={false}
          isVisibleInfo={false}
          isVisbleSum={true}
          isVisbleSelect={true}
          showSubTitle={false}
          commentCount={totalCount}
          selectOption={pageSizeList}
          defaultOption="1"
          onChange={handlePageSize}
        />
      </SubTitleBtnArea>

      <Wrap>
        <GridFlexWrap autoheight={pageSize > 20 ? false : true}>
          <GContentGridQna
            type="none"
            isPagination={true}
            isSortable={false}
            className="ag-theme-alpine"
            headerHeight="37"
            rowHeight="37"
            scrollHeight="48px"
            style={pageSize > 20 ? { flex: 1 } : {}}
            // style={pageSize > 20 ? { flex: 1 } : gridData?.length == 0 ? { height: '730px' } : {}}
            isBorad={true}
          >
            <AgGridReact
              defaultColDef={defaultColDef}
              columnDefs={colDefs}
              rowData={gridData}
              gridOptions={gridOptions}
              rowSelection={'multiple'}
              stopEditingWhenCellsLoseFocus={true}
              suppressRowClickSelection={true}
              rowHeight={37}
              headerHeight={37}
              suppressMovableColumns
              enableCellTextSelection
              onGridReady={(params) => (gridApiRef.current = params.api)}
              tooltipShowDelay={0}
              tooltipHideDelay={2000}
              onCellClicked={(e: CellClickedEvent) => {
                navigate('/qna/detail', {
                  state: { bbmNo: e.data.bbmNo },
                });
              }}
              noRowsOverlayComponent={EmptyState}
              noRowsOverlayComponentParams={{ type: 'grid' }}
              domLayout={pageSize > 20 ? 'normal' : 'autoHeight'}
            ></AgGridReact>
          </GContentGridQna>
          {gridData && gridData.length !== 0 && (
            <GPagination
              ref={paginationRef}
              totalCount={totalCount}
              defaultPageNo={pageNo}
              defaultPageSize={pageSize}
              onChangePageSize={handlePageSize}
              onClickPageNo={handlePageNoClick}
            />
          )}
        </GridFlexWrap>
        {!roleCodes.every((o) => o == 'PTN') && (
          <GButtonLayout marginBottom="8px">
            <GButtonBox>
              {accessToken ? (
                <GButton
                  chkImg={New}
                  txt={t('com.label.new', '__신규')}
                  sizes="large"
                  btnstyled="contained"
                  btnColor="primary"
                  hasImg={true}
                  hasTxt={true}
                  onClick={handleChangePage}
                ></GButton>
              ) : (
                <GButton
                  chkImg={Delete}
                  txt={t('com.label.00054', '삭제')}
                  sizes="large"
                  btnstyled="outline"
                  btnColor="normal"
                  hasImg={true}
                  hasTxt={true}
                  onClick={handleDelete}
                ></GButton>
              )}
            </GButtonBox>
          </GButtonLayout>
        )}
      </Wrap>
    </>
  );
};

export const GridWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Wrap = styled.div`
  margin-bottom: 4px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export default QnaListPage;
