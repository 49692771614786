import styled from '@emotion/styled';
import { GButton } from 'components/atom/button';
import New from 'components/asset/images/Pen.svg';
import Delete from 'components/asset/images/Delete.svg';
import UploadImage from 'components/atom/uploadImage';
import BackgoundEx from 'components/asset/images/backgroundEx.png';

interface ReplyProps {
  profileImg?: string;
  nickName?: string;
  position?: string;
  contentText?: string;
  date?: string;

  imgUpload?: boolean;
  reReply?: boolean;
}

const Reply: React.FC<ReplyProps> = (props: ReplyProps) => {
  return (
    <ReplyItem>
      <WriterProfile src={props.profileImg} />
      <ContentWrap>
        <TopArea>
          <Left>
            <NameWrap>
              <Nickname>{props.nickName}</Nickname>
              <Position>{props.position}</Position>
            </NameWrap>
          </Left>

          <GButtonWrap>
            <GButton
              chkImg={New}
              sizes="small"
              btnstyled="outline"
              btnColor="none"
              hasImg={true}
              hasTxt={false}
            ></GButton>
            <GButton
              chkImg={Delete}
              sizes="small"
              btnstyled="outline"
              btnColor="none"
              hasImg={true}
              hasTxt={false}
            ></GButton>
          </GButtonWrap>
        </TopArea>
        <ReplyContentWrap>
          {props.contentText}
          {props.imgUpload && <UploadImage type={'img'} uploadImg={BackgoundEx} />}
        </ReplyContentWrap>
        <BottomWrap>
          <Date>{props.date}</Date>
          <GButton
            txt="댓글쓰기"
            sizes="xs"
            btnstyled="outline"
            btnColor="none"
            hasImg={false}
            hasTxt={true}
          ></GButton>
        </BottomWrap>
      </ContentWrap>
    </ReplyItem>
  );
};

const ReplyItem = styled.div<ReplyProps>`
  display: flex;
  align-items: flex-start;
  padding: 12px 0;
  width: 100%;
  gap: 8px;

  &:hover > div > div:first-child > div:last-child {
    opacity: 1;
  }
`;

const ContentWrap = styled.div<ReplyProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const WriterProfile = styled.img<ReplyProps>`
  width: 24px;
  height: 24px;
  border-radius: 50px;
  margin: 6px 4px;
`;

const TopArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 4px;
`;

const NameWrap = styled.div<ReplyProps>`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Nickname = styled.text`
  color: var(--Common-Text-Basic, #1f1f1f);
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Spoqa Han Sans Neo;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`;

const Position = styled.text`
  color: var(--Common-Text-Basic, #1f1f1f);
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Spoqa Han Sans Neo;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

const ReplyContentWrap = styled.div`
  width: 100%;
  height: auto;
`;

const GButtonWrap = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 4px;
  opacity: 0;
`;

const BottomWrap = styled.div`
  width: 100%;
  height: 24px;
  display: flex;
  align-items: center;
  gap: 24px;
`;

const Left = styled.div`
  width: auto;
`;

const Date = styled.span`
  color: var(--Common-Text-Light, #5b5c5b);
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Spoqa Han Sans Neo;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

export default Reply;
