import styled from '@emotion/styled';
import { TextSize, TextWeight } from 'ui/theme/CustomTypography';
import { FontColor } from 'ui/theme/Color';

interface TitleProps {
  title: string;
}

const Title = (props: TitleProps) => {
  return <MainTitle>{props.title}</MainTitle>;
};

const MainTitle = styled.h2`
  color: ${FontColor.Default};
  font-family: Spoqa Han Sans Neo;
  font-size: ${TextSize.H2};
  font-style: normal;
  font-weight: ${TextWeight.Bold};
  line-height: 24px;
`;

export default Title;
