import Box from '@mui/material/Box';
// ag-grid + pagination
import { AgGridReact } from 'ag-grid-react';
import { GContentGrid } from 'components/layouts/GContentGrid';
import { useColumnDefsForApprovalList } from './type2/ColumnDefs';
import { getSamples } from 'apis/sample/SampleApi';
import { SampleResponse } from 'models/sample/Sample';
import { GPagination, PaginationRef } from 'components/layouts/GPagination';
import { GButton } from 'components/atom/button';
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  FormGroup,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import { GGuideTitle } from 'components/layouts/GLayoutBox';
import { GInputLabel, GInputLabelDisabled } from 'components/inputs/GInput';
import GDatePicker from 'components/inputs/GDatePicker';
import Time from 'components/atom/time';
import React, { useState, useRef, useEffect } from 'react';
import { useLoading } from 'components/process/Loading';
import { useMessageBar } from 'components/process/MessageBar';
import Img from 'components/asset/images/ConfirmB.svg';
import Plus from 'components/asset/images/Plus.svg';
import Minus from 'components/asset/images/Minus.svg';
import RecycleB from 'components/asset/images/RecycleB.svg';
import New from 'components/asset/images/Pen.svg';
import Copy from 'components/asset/images/Copy.svg';
import Delete from 'components/asset/images/Delete.svg';
import Save from 'components/asset/images/Confirm.svg';
import Refresh from 'components/asset/Refresh.svg';
import Search from 'components/asset/images/Search.svg';
import Down from 'components/asset/images/Down.svg';
import Upload from 'components/asset/images/Upload.svg';
import { GLabelAtom } from 'components/atom/label';
import InputField from 'components/atom/input';
import GSelectMUIAtom from 'components/atom/select';
import { GCodeSelect } from 'components/selects/GCodeSelect';
import { CommonCode } from 'models/admin/CommonCode';
import PageHeaderLayout from 'components/layouts/PageHeaderLayout';
import FileUploadZone from 'components/molecule/FileUpload';
import GButtonLayout, { GButtonBox } from 'components/molecule/GbuttonLayout';
import SubTitleBox from 'components/molecule/SubTitleBox';
import SearchMolecule from 'components/molecule/SearchMolecule';
import SearchBox from 'components/organism/SearchBox';
import { BpBarbox, BpCheckbox, CheckboxGroup } from 'components/atom/checkbox';
import { GRadio, GRadioButton } from 'components/atom/radio';
import { GbasicTable, GbasicTableWrap } from 'components/tables/GTable';
import useSessionStore from 'stores/useSessionStore';
import { useTranslation } from 'react-i18next';
import RegistBox from 'components/organism/RegistBox';
import Download from 'components/asset/images/Download.svg';
import List from 'components/asset/images/List.svg';
import { AlignBox, AlignItem } from 'components/organism/AlignBox';
import CloseNone from 'components/asset/images/CloseNone.svg';
import ChipSelectBox from 'components/atom/chipSelect';
import MessageAlert from 'components/atom/alert';

import { CommonModal } from 'components/modals/common/CommonModal';

import { Link } from 'react-router-dom';
import TextArea from 'components/atom/textarea';
import SelectMolecule from 'components/molecule/SelectMolecule';

import { SubTitleBtnArea } from 'components/organism/SubTitleBtnArea';
import { TitleBtnBox } from 'components/molecule/ButtonArea';
import AvatarChip from 'components/atom/avatarChip';
import Avatar from 'components/asset/images/Avatar.png';

import SwitchButton from 'components/atom/switchButton';

import EyeCount from 'components/atom/eyeCount';
import Profile from 'components/atom/profile';
import SingleChipSelect from 'components/atom/singleChipSelect';
import GridRadioButton from 'components/molecule/GridRadioButton';
import GridCheckbox from 'components/molecule/GridCheckbox';
import GridRadio from 'components/molecule/GridRadio';
import EditProfile from 'components/molecule/Profile';
import ProfileImg from 'components/asset/images/userIcon.svg';
import ProfileImgP from 'components/asset/images/userIconPurple.svg';
import RightChevron from 'components/asset/images/RightChevron.svg';
import LeftChevron from 'components/asset/images/LeftChevron.svg';
import NoticeBox, { NoticeList, Item, NoticeText, LinkText } from 'components/atom/noticeBox';
import GTabs from 'components/organism/GTabs';
import avatarUser from 'components/asset/images/avatarUser.svg';
import chipUser from 'components/asset/images/chipUser.svg';
import ThumbsDown from 'components/atom/thumbsDownButton';
import ThumbsUp from 'components/atom/thumbsUpButton';
import Tag from 'components/atom/tag';
import { SideMenuBar } from 'components/organism/menu/SideMenuBar';

// 트리뷰
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { ReactComponent as ExpandMenu } from 'components/asset/ExpandMenu.svg';
import { ReactComponent as ExpandedMenu } from 'components/asset/ExpandedMenu.svg';

export type SearchParams = {
  coCd: string;
};

const TestPage = () => {
  // geconst [sampleList, setSampleList] = useState<SampleResponse[]>([]);t grid data, defaultColDef
  const [sampleList, setSampleList] = useState<SampleResponse[]>([]);
  const gridApiRef = useRef<any>(null);
  const { defaultColDef, colDefs } = useColumnDefsForApprovalList();

  const { openLoading } = useLoading();
  const { openMessageBar } = useMessageBar();

  // pagination
  const [totalCount, setTotalCount] = useState<number>(0);
  const paginationRef = useRef<PaginationRef>(null);
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageNo, setPageNo] = useState<number>(1);
  const [initialized, setInitialized] = useState<boolean>(false);

  const makeSearchParams = () => {
    return {
      ...searchParams,
      start: String(pageSize * (pageNo - 1)),
      pageSize: String(pageSize),
    };
  };

  const loadSampleList = async () => {
    getSamples(makeSearchParams())
      .then((data) => {
        setSampleList(data.list);
        setTotalCount(data?.totalCount || 0);
      })
      .catch(handleError);
  };

  const handleError = (e: Error) => {
    openLoading(false);
    openMessageBar('error', e.message);
  };

  useEffect(() => {
    loadSampleList();
  }, [initialized, pageNo, pageSize]);

  const handleSearch = () => {
    pageNo === 1 ? loadSampleList() : setPageNo(1);
  };

  const handlePageSizeChange = (size) => {
    paginationRef.current?.setPageSize(size);
    setPageNo(1);
    setPageSize(size);
  };

  const handlePageNoClick = (no: number) => {
    setPageNo(no);
  };

  // 자신이 사용하는 컴포넌트 확인하고 가져가기!!!!
  // [GCodeSelect] select의 option값을 위한 더미데이터
  const dommy: CommonCode[] = [{ cmnCd: 'v1', cmnCdNm: '옵션1' }];
  const dommy2: CommonCode[] = [
    { cmnCd: 'v1', cmnCdNm: '옵션1' },
    { cmnCd: 'v2', cmnCdNm: '옵션2' },
    { cmnCd: 'v3', cmnCdNm: '옵션3' },
    { cmnCd: 'v4', cmnCdNm: '옵션4' },
    { cmnCd: 'v5', cmnCdNm: '옵션5' },
    { cmnCd: 'v6', cmnCdNm: '옵션6' },
    { cmnCd: 'v7', cmnCdNm: '옵션7' },
    { cmnCd: 'v8', cmnCdNm: '옵션8' },
    { cmnCd: 'v9', cmnCdNm: '옵션9' },
    { cmnCd: 'v10', cmnCdNm: '옵션10' },
    { cmnCd: 'v11', cmnCdNm: '옵션11' },
    { cmnCd: 'v12', cmnCdNm: '옵션12' },
    { cmnCd: 'v13', cmnCdNm: '옵션13' },
    { cmnCd: 'v14', cmnCdNm: '옵션14' },
    { cmnCd: 'v15', cmnCdNm: '옵션15' },
    { cmnCd: 'v16', cmnCdNm: '옵션16' },
    { cmnCd: 'v17', cmnCdNm: '옵션17' },
    { cmnCd: 'v18', cmnCdNm: '옵션18' },
    { cmnCd: 'v19', cmnCdNm: '옵션19' },
    { cmnCd: 'v20', cmnCdNm: '옵션20' },
    { cmnCd: 'v21', cmnCdNm: '옵션21' },
  ];
  const [searchParams, setSearchParams] = useState<SearchParams>({
    coCd: '',
  });
  const handleChangeCoCd = (e) => {
    setSearchParams({ coCd: e.target.value });
  };

  // [GSelectMUIAtom] select의 option값을 위한 더미데이터
  const [option, setOption] = React.useState('0');
  const handleChange = (event: SelectChangeEvent) => {
    setOption(event.target.value);
  };

  /*Table 안에 쓰고 있는 함수들 */
  type BasicFormData = {
    lbl1: string | undefined;
    lbl2: string | undefined;
    lbl3: string | undefined;
    lbl4: string | undefined;
    lbl5: number | undefined;
    lbl6: string | undefined;
    lbl7: string | undefined;
    lbl8: string | undefined;
    lbl9: string | undefined;
    lbl10: string | undefined;
    lbl11: string | undefined;
    atchFileGrId: string;
  };

  const { t } = useTranslation();
  const { userNm, deptNm, jtiNm } = useSessionStore();
  const defaultValuesForBasicForm: BasicFormData = {
    lbl1: userNm,
    lbl2: deptNm,
    lbl3: jtiNm,
    lbl4: '',
    lbl5: 0,
    lbl6: '',
    lbl7: '',
    lbl8: '',
    lbl9: '',
    lbl10: '',
    lbl11: '',
    atchFileGrId: '',
  };

  const [basicFormData, setBasicFormData] = useState<BasicFormData>(defaultValuesForBasicForm);

  const handleChangeForBasicForm = (e) => {
    setBasicFormData((prev) => {
      return { ...prev, [e.target.id]: e.target.value };
    });
  };

  const resetBasicForm = (listId: string[]) => {
    const newParams: BasicFormData = { ...basicFormData };
    listId.forEach((id) => (newParams[id] = ''));
    setBasicFormData(() => {
      return { ...newParams };
    });
  };

  // search box(chipSeelect) Function
  const [chipClick, setChipClick] = useState(false);
  const onSearchClick = () => {
    setChipClick((pre) => !pre);
    alert('ex. 모달에서 clickValue 선택');
  };

  return (
    <>
      <Box sx={{ marginTop: '30px' }}>
        <span>팝업</span> <hr />
        <br />
        <Link
          to="/sample/modal"
          style={{
            width: '200px',
            height: '30px',
            padding: '10px',
            borderRadius: '5px',
            cursor: 'pointer',
            backgroundColor: '#1f7795',
            color: '#FFF',
          }}
        >
          팝업 리스트 (보험, 주주)
        </Link>
        <br />
        <br />
        <CommonModal />
        <span>GButton</span> <hr />
        <GButtonBox>
          <GButton
            txt="button"
            sizes="large"
            btnstyled="contained"
            btnColor="primary"
            hasImg={false}
            hasTxt={true}
          ></GButton>
          <GButton
            chkImg={Save}
            txt="button"
            sizes="large"
            btnstyled="contained"
            btnColor="primary"
            hasImg={true}
            hasTxt={true}
          ></GButton>
          <GButton
            chkImg={Save}
            txt="button"
            sizes="large"
            btnstyled="contained"
            btnColor="primary"
            imgloc="right"
            hasImg={true}
            hasTxt={true}
          ></GButton>
          <GButton
            chkImg={Save}
            txt="button"
            sizes="large"
            btnstyled="contained"
            btnColor="primary"
            hasImg={true}
            hasTxt={true}
            disabled
          ></GButton>
          <GButton
            chkImg={Save}
            sizes="large"
            btnstyled="contained"
            btnColor="primary"
            hasImg={true}
            hasTxt={false}
          ></GButton>
        </GButtonBox>
        <br />
        <GButtonBox>
          <GButton
            txt="button"
            sizes="large"
            btnstyled="outline"
            btnColor="primary"
            hasImg={false}
            hasTxt={true}
          ></GButton>
          <GButton
            chkImg={Img}
            txt="button"
            sizes="large"
            btnstyled="outline"
            btnColor="primary"
            hasImg={true}
            hasTxt={true}
          ></GButton>
          <GButton
            chkImg={Img}
            txt="button"
            sizes="large"
            btnstyled="outline"
            btnColor="primary"
            imgloc="right"
            hasImg={true}
            hasTxt={true}
          ></GButton>
          <GButton
            chkImg={Img}
            txt="button"
            sizes="large"
            btnstyled="outline"
            btnColor="primary"
            hasImg={true}
            hasTxt={true}
            disabled
          ></GButton>
          <GButton
            chkImg={Img}
            sizes="large"
            btnstyled="outline"
            btnColor="primary"
            hasImg={true}
            hasTxt={false}
          ></GButton>
        </GButtonBox>
        <br />
        <GButtonBox>
          <GButton
            txt="button"
            sizes="large"
            btnstyled="contained"
            btnColor="normal"
            hasImg={false}
            hasTxt={true}
          ></GButton>
          <GButton
            chkImg={Save}
            txt="button"
            sizes="large"
            btnstyled="contained"
            btnColor="normal"
            hasImg={true}
            hasTxt={true}
          ></GButton>
          <GButton
            chkImg={Save}
            txt="button"
            sizes="large"
            btnstyled="contained"
            btnColor="normal"
            imgloc="right"
            hasImg={true}
            hasTxt={true}
          ></GButton>
          <GButton
            chkImg={Save}
            txt="button"
            sizes="large"
            btnstyled="contained"
            btnColor="normal"
            hasImg={true}
            hasTxt={true}
            disabled
          ></GButton>
          <GButton
            chkImg={Save}
            sizes="large"
            btnstyled="contained"
            btnColor="normal"
            hasImg={true}
            hasTxt={false}
          ></GButton>
        </GButtonBox>
        <br />
        <GButtonBox>
          <GButton
            txt="button"
            sizes="large"
            btnstyled="outline"
            btnColor="normal"
            hasImg={false}
            hasTxt={true}
          ></GButton>
          <GButton
            chkImg={Img}
            txt="button"
            sizes="large"
            btnstyled="outline"
            btnColor="normal"
            hasImg={true}
            hasTxt={true}
          ></GButton>
          <GButton
            chkImg={Img}
            txt="button"
            sizes="large"
            btnstyled="outline"
            btnColor="normal"
            imgloc="right"
            hasImg={true}
            hasTxt={true}
          ></GButton>
          <GButton
            chkImg={Img}
            txt="button"
            sizes="large"
            btnstyled="outline"
            btnColor="normal"
            hasImg={true}
            hasTxt={true}
            disabled
          ></GButton>
          <GButton
            chkImg={Img}
            sizes="large"
            btnstyled="outline"
            btnColor="normal"
            hasImg={true}
            hasTxt={false}
          ></GButton>
        </GButtonBox>
        <br />
        {/* */}
        <GButtonBox>
          <GButton
            txt="button"
            sizes="medium"
            btnstyled="contained"
            btnColor="primary"
            hasImg={false}
            hasTxt={true}
          ></GButton>
          <GButton
            chkImg={Save}
            txt="button"
            sizes="medium"
            btnstyled="contained"
            btnColor="primary"
            hasImg={true}
            hasTxt={true}
          ></GButton>
          <GButton
            chkImg={Save}
            txt="button"
            sizes="medium"
            btnstyled="contained"
            btnColor="primary"
            imgloc="right"
            hasImg={true}
            hasTxt={true}
          ></GButton>
          <GButton
            chkImg={Save}
            txt="button"
            sizes="medium"
            btnstyled="contained"
            btnColor="primary"
            hasImg={true}
            hasTxt={true}
            disabled
          ></GButton>
          <GButton
            chkImg={Save}
            sizes="medium"
            btnstyled="contained"
            btnColor="primary"
            hasImg={true}
            hasTxt={false}
          ></GButton>
        </GButtonBox>
        <br />
        <GButtonBox>
          <GButton
            txt="button"
            sizes="medium"
            btnstyled="outline"
            btnColor="primary"
            hasImg={false}
            hasTxt={true}
          ></GButton>
          <GButton
            chkImg={Img}
            txt="button"
            sizes="medium"
            btnstyled="outline"
            btnColor="primary"
            hasImg={true}
            hasTxt={true}
          ></GButton>
          <GButton
            chkImg={Img}
            txt="button"
            sizes="medium"
            btnstyled="outline"
            btnColor="primary"
            imgloc="right"
            hasImg={true}
            hasTxt={true}
          ></GButton>
          <GButton
            chkImg={Img}
            txt="button"
            sizes="medium"
            btnstyled="outline"
            btnColor="primary"
            hasImg={true}
            hasTxt={true}
            disabled
          ></GButton>
          <GButton
            chkImg={Img}
            sizes="medium"
            btnstyled="outline"
            btnColor="primary"
            hasImg={true}
            hasTxt={false}
          ></GButton>
        </GButtonBox>
        <br />
        <GButtonBox>
          <GButton
            txt="button"
            sizes="medium"
            btnstyled="contained"
            btnColor="normal"
            hasImg={false}
            hasTxt={true}
          ></GButton>
          <GButton
            chkImg={Save}
            txt="button"
            sizes="medium"
            btnstyled="contained"
            btnColor="normal"
            hasImg={true}
            hasTxt={true}
          ></GButton>
          <GButton
            chkImg={Save}
            txt="button"
            sizes="medium"
            btnstyled="contained"
            btnColor="normal"
            imgloc="right"
            hasImg={true}
            hasTxt={true}
          ></GButton>
          <GButton
            chkImg={Save}
            txt="button"
            sizes="medium"
            btnstyled="contained"
            btnColor="normal"
            hasImg={true}
            hasTxt={true}
            disabled
          ></GButton>
          <GButton
            chkImg={Save}
            sizes="medium"
            btnstyled="contained"
            btnColor="normal"
            hasImg={true}
            hasTxt={false}
          ></GButton>
        </GButtonBox>
        <br />
        <GButtonBox>
          <GButton
            txt="button"
            sizes="medium"
            btnstyled="outline"
            btnColor="normal"
            hasImg={false}
            hasTxt={true}
          ></GButton>
          <GButton
            chkImg={Img}
            txt="button"
            sizes="medium"
            btnstyled="outline"
            btnColor="normal"
            hasImg={true}
            hasTxt={true}
          ></GButton>
          <GButton
            chkImg={Img}
            txt="button"
            sizes="medium"
            btnstyled="outline"
            btnColor="normal"
            imgloc="right"
            hasImg={true}
            hasTxt={true}
          ></GButton>
          <GButton
            chkImg={Img}
            txt="button"
            sizes="medium"
            btnstyled="outline"
            btnColor="normal"
            hasImg={true}
            hasTxt={true}
            disabled
          ></GButton>
          <GButton
            chkImg={Img}
            sizes="medium"
            btnstyled="outline"
            btnColor="normal"
            hasImg={true}
            hasTxt={false}
          ></GButton>
        </GButtonBox>
        {/*  */}
        <br />
        <GButtonBox>
          <GButton
            txt="button"
            sizes="small"
            btnstyled="contained"
            btnColor="primary"
            hasImg={false}
            hasTxt={true}
          ></GButton>
          <GButton
            chkImg={Save}
            txt="button"
            sizes="small"
            btnstyled="contained"
            btnColor="primary"
            hasImg={true}
            hasTxt={true}
          ></GButton>
          <GButton
            chkImg={Save}
            txt="button"
            sizes="small"
            btnstyled="contained"
            btnColor="primary"
            imgloc="right"
            hasImg={true}
            hasTxt={true}
          ></GButton>
          <GButton
            chkImg={Save}
            txt="button"
            sizes="small"
            btnstyled="contained"
            btnColor="primary"
            hasImg={true}
            hasTxt={true}
            disabled
          ></GButton>
          <GButton
            chkImg={Save}
            sizes="small"
            btnstyled="contained"
            btnColor="primary"
            hasImg={true}
            hasTxt={false}
          ></GButton>
        </GButtonBox>
        <br />
        <GButtonBox>
          <GButton
            txt="button"
            sizes="small"
            btnstyled="outline"
            btnColor="primary"
            hasImg={false}
            hasTxt={true}
          ></GButton>
          <GButton
            chkImg={Img}
            txt="button"
            sizes="small"
            btnstyled="outline"
            btnColor="primary"
            hasImg={true}
            hasTxt={true}
          ></GButton>
          <GButton
            chkImg={Img}
            txt="button"
            sizes="small"
            btnstyled="outline"
            btnColor="primary"
            imgloc="right"
            hasImg={true}
            hasTxt={true}
          ></GButton>
          <GButton
            chkImg={Img}
            txt="button"
            sizes="small"
            btnstyled="outline"
            btnColor="primary"
            hasImg={true}
            hasTxt={true}
            disabled
          ></GButton>
          <GButton
            chkImg={Img}
            sizes="small"
            btnstyled="outline"
            btnColor="primary"
            hasImg={true}
            hasTxt={false}
          ></GButton>
        </GButtonBox>
        <br />
        <GButtonBox>
          <GButton
            txt="button"
            sizes="small"
            btnstyled="contained"
            btnColor="normal"
            hasImg={false}
            hasTxt={true}
          ></GButton>
          <GButton
            chkImg={Save}
            txt="button"
            sizes="small"
            btnstyled="contained"
            btnColor="normal"
            hasImg={true}
            hasTxt={true}
          ></GButton>
          <GButton
            chkImg={Save}
            txt="button"
            sizes="small"
            btnstyled="contained"
            btnColor="normal"
            imgloc="right"
            hasImg={true}
            hasTxt={true}
          ></GButton>
          <GButton
            chkImg={Save}
            txt="button"
            sizes="small"
            btnstyled="contained"
            btnColor="normal"
            hasImg={true}
            hasTxt={true}
            disabled
          ></GButton>
          <GButton
            chkImg={Save}
            sizes="small"
            btnstyled="contained"
            btnColor="normal"
            hasImg={true}
            hasTxt={false}
          ></GButton>
        </GButtonBox>
        <br />
        <GButtonBox>
          <GButton
            txt="button"
            sizes="small"
            btnstyled="outline"
            btnColor="normal"
            hasImg={false}
            hasTxt={true}
          ></GButton>
          <GButton
            chkImg={Img}
            txt="button"
            sizes="small"
            btnstyled="outline"
            btnColor="normal"
            hasImg={true}
            hasTxt={true}
          ></GButton>
          <GButton
            chkImg={Img}
            txt="button"
            sizes="small"
            btnstyled="outline"
            btnColor="normal"
            imgloc="right"
            hasImg={true}
            hasTxt={true}
          ></GButton>
          <GButton
            chkImg={Img}
            txt="button"
            sizes="small"
            btnstyled="outline"
            btnColor="normal"
            hasImg={true}
            hasTxt={true}
            disabled
          ></GButton>
          <GButton
            chkImg={Img}
            sizes="small"
            btnstyled="outline"
            btnColor="normal"
            hasImg={true}
            hasTxt={false}
          ></GButton>
        </GButtonBox>
        <br />
        <GButtonBox>
          <GButton
            txt="업로드"
            sizes="xs"
            btnstyled="outline"
            btnColor="normal"
            hasImg={false}
            hasTxt={true}
          ></GButton>
          <GButton
            txt="인증요청"
            sizes="xs"
            btnstyled="outline"
            btnColor="normal"
            hasImg={false}
            hasTxt={true}
          ></GButton>
        </GButtonBox>
        <br />
        <GButtonBox>
          <GButton
            chkImg={New}
            txt="신규"
            sizes="medium"
            btnstyled="outline"
            btnColor="normal"
            hasImg={true}
            hasTxt={true}
          ></GButton>
          <GButton
            chkImg={Copy}
            txt="복사"
            sizes="medium"
            btnstyled="outline"
            btnColor="normal"
            hasImg={true}
            hasTxt={true}
          ></GButton>
          <GButton
            chkImg={Delete}
            txt="삭제"
            sizes="medium"
            btnstyled="outline"
            btnColor="normal"
            hasImg={true}
            hasTxt={true}
          ></GButton>
        </GButtonBox>
        <br />
        <GButtonBox>
          <GButton
            chkImg={Refresh}
            sizes="medium"
            btnstyled="outline"
            btnColor="normal"
            hasImg={true}
            hasTxt={false}
          ></GButton>
          <GButton
            chkImg={Search}
            txt="조회"
            sizes="medium"
            btnstyled="contained"
            btnColor="normal"
            hasImg={true}
            hasTxt={true}
          ></GButton>
          <GButton
            chkImg={Down}
            sizes="medium"
            btnstyled="contained"
            btnColor="normal"
            hasImg={true}
            hasTxt={false}
          ></GButton>
        </GButtonBox>
        <br />
        <GButton
          chkImg={RecycleB}
          sizes="medium"
          btnstyled="outline"
          btnColor="normal"
          hasImg={true}
          hasTxt={false}
        ></GButton>
        <br /> <br />
        <GButton
          chkImg={Download}
          txt="전체 다운로드"
          sizes="medium"
          btnstyled="outline"
          btnColor="normal"
          hasImg={true}
          hasTxt={true}
        ></GButton>
        <br /> <br />
        <GButtonBox>
          <GButton
            chkImg={RightChevron}
            sizes="large"
            btnstyled="outline"
            btnColor="normal"
            hasImg={true}
            hasTxt={false}
          ></GButton>
          <GButton
            chkImg={LeftChevron}
            sizes="large"
            btnstyled="outline"
            btnColor="normal"
            hasImg={true}
            hasTxt={false}
          ></GButton>
        </GButtonBox>
        <br />
        <GButtonLayout>
          <GButtonBox>
            <GButton
              chkImg={List}
              txt="목록"
              sizes="small"
              btnstyled="outline"
              btnColor="normal"
              hasImg={true}
              hasTxt={true}
            ></GButton>
            <GButton
              chkImg={New}
              txt="수정"
              sizes="small"
              btnstyled="outline"
              btnColor="normal"
              hasImg={true}
              hasTxt={true}
            ></GButton>
            <GButton
              chkImg={Delete}
              txt="삭제"
              sizes="small"
              btnstyled="outline"
              btnColor="normal"
              hasImg={true}
              hasTxt={true}
            ></GButton>
          </GButtonBox>
        </GButtonLayout>
        <br />
        <span>모달 버튼(사용시 onClick 넣고 GButtonLayout 사용해서 정렬)</span> <hr />
        <GButton
          chkImg={CloseNone}
          sizes="medium"
          btnstyled="outline"
          btnColor="none"
          hasImg={true}
          hasTxt={false}
        ></GButton>
        <br />
        <span>버튼 btnColor none</span> <hr />
        <GButtonBox>
          <GButton
            txt="button"
            sizes="medium"
            btnstyled="outline"
            btnColor="none"
            hasImg={false}
            hasTxt={true}
          ></GButton>
          <GButton
            txt="button"
            sizes="medium"
            btnstyled="contained"
            btnColor="none"
            hasImg={false}
            hasTxt={true}
          ></GButton>
        </GButtonBox>
        <br />
        <span>버튼 sizes none (padding 없는 버튼) </span> <hr />
        <GButtonBox>
          <GButton
            txt="button"
            sizes="none"
            btnstyled="outline"
            btnColor="none"
            hasImg={false}
            hasTxt={true}
          ></GButton>
          <GButton
            chkImg={Img}
            sizes="none"
            btnstyled="outline"
            btnColor="none"
            hasImg={true}
            hasTxt={false}
          ></GButton>
        </GButtonBox>
        <br />
        <span>좋아요 버튼</span> <hr />
        <ThumbsDown count={120} />
        <ThumbsUp count={10} />
        <br />
        <span>버튼 정렬 기본(오른쪽)</span> <hr />
        <GButtonLayout>
          <GButtonBox>
            <GButton
              chkImg={Plus}
              txt="행추가"
              sizes="small"
              btnstyled="outline"
              btnColor="normal"
              hasImg={true}
              hasTxt={true}
            ></GButton>
            <GButton
              chkImg={Minus}
              txt="행삭제"
              sizes="small"
              btnstyled="outline"
              btnColor="normal"
              hasImg={true}
              hasTxt={true}
            ></GButton>
            <GButton
              chkImg={Upload}
              txt="업로드"
              sizes="small"
              btnstyled="outline"
              btnColor="normal"
              hasImg={true}
              hasTxt={true}
            ></GButton>
          </GButtonBox>
        </GButtonLayout>
        <br />
        <span>버튼 정렬 왼쪽</span> <hr />
        <GButtonLayout layoutLoc="start">
          <GButtonBox>
            <GButton
              chkImg={Plus}
              txt="행추가"
              sizes="small"
              btnstyled="outline"
              btnColor="normal"
              hasImg={true}
              hasTxt={true}
            ></GButton>
            <GButton
              chkImg={Minus}
              txt="행삭제"
              sizes="small"
              btnstyled="outline"
              btnColor="normal"
              hasImg={true}
              hasTxt={true}
            ></GButton>
            <GButton
              chkImg={Upload}
              txt="업로드"
              sizes="small"
              btnstyled="outline"
              btnColor="normal"
              hasImg={true}
              hasTxt={true}
            ></GButton>
          </GButtonBox>
        </GButtonLayout>
        <br />
        <br />
        <span>버튼 정렬 중앙</span> <hr />
        <GButtonLayout layoutLoc="space-between">
          <GButtonBox>
            <GButton
              chkImg={Plus}
              txt="행추가"
              sizes="small"
              btnstyled="outline"
              btnColor="normal"
              hasImg={true}
              hasTxt={true}
            ></GButton>
            <GButton
              chkImg={Minus}
              txt="행삭제"
              sizes="small"
              btnstyled="outline"
              btnColor="normal"
              hasImg={true}
              hasTxt={true}
            ></GButton>
            <GButton
              chkImg={Upload}
              txt="업로드"
              sizes="small"
              btnstyled="outline"
              btnColor="normal"
              hasImg={true}
              hasTxt={true}
            ></GButton>
          </GButtonBox>
          <GButtonBox>
            <GButton
              chkImg={Plus}
              txt="행추가"
              sizes="small"
              btnstyled="outline"
              btnColor="normal"
              hasImg={true}
              hasTxt={true}
            ></GButton>
            <GButton
              chkImg={Minus}
              txt="행삭제"
              sizes="small"
              btnstyled="outline"
              btnColor="normal"
              hasImg={true}
              hasTxt={true}
            ></GButton>
            <GButton
              chkImg={Upload}
              txt="업로드"
              sizes="small"
              btnstyled="outline"
              btnColor="normal"
              hasImg={true}
              hasTxt={true}
            ></GButton>
          </GButtonBox>
        </GButtonLayout>
        <br />
        {/* 라디오랑 체크박스는 index.css를 주석처리 하고 나서 디자인에 맞게 규격을 맞췄습니다 .
          그래서 row 없앴을 때 정렬이 안 맞아도 index.css를 지우고 봐보시면 잘 맞습니다.
        */}
        {/* radio */}
        <Box sx={{ marginTop: '30px' }}>
          <GGuideTitle>Primary Radio (GPRadio) --- Grid 외에 사용</GGuideTitle>
          {/* 라디오 molecule로 구현되어 있음, f라이도 정렬 기본으로 row */}
          <GridRadio row={false} value="radioGroup2" option={['radio1', 'radio2', 'radio3']} />
          <br />
          <GridRadio value="radioGroup2" option={['radio1', 'radio2', 'radio3']} />
          <br />
          <FormControl>
            <RadioGroup
              sx={{ gap: '10px' }}
              defaultValue="female"
              aria-labelledby="demo-customized-radios"
              name="primary-radios"
            >
              <FormControlLabel
                value="female"
                control={<GRadio />}
                label={<GInputLabel>female</GInputLabel>}
              />
              <FormControlLabel
                value="male"
                control={<GRadio />}
                label={<GInputLabel>male</GInputLabel>}
              />
              <FormControlLabel
                value="other"
                control={<GRadio />}
                label={<GInputLabel>other</GInputLabel>}
              />
            </RadioGroup>
            <br />
            <RadioGroup
              row
              sx={{ gap: '12px' }}
              defaultValue="female"
              aria-labelledby="demo-customized-radios"
              name="primary-radios"
            >
              <FormControlLabel
                checked
                value="disabled"
                disabled
                control={<GRadio />}
                label={<GInputLabelDisabled>disabled option</GInputLabelDisabled>}
              />
              <FormControlLabel
                value="disabled"
                disabled
                control={<GRadio />}
                label={<GInputLabelDisabled>disabled option</GInputLabelDisabled>}
              />
            </RadioGroup>
          </FormControl>
          <GGuideTitle>Secondary Radio (GSRadio) --- Grid에만 사용</GGuideTitle>
          {/*아래 구현되어 있는 라디오는 일반 라디오랑 기능이 다름 확인하고 사용할것*/}
          <FormControl>
            <RadioGroup
              row
              sx={{ gap: '12px' }}
              defaultValue="female"
              aria-labelledby="demo-customized-radios"
              name="secondary-radios"
            >
              <FormControlLabel
                value="female"
                control={<GRadio color={'secondary'} />}
                label={<GInputLabel>female</GInputLabel>}
              />
              <FormControlLabel
                value="male"
                control={<GRadio color={'secondary'} />}
                label={<GInputLabel>male</GInputLabel>}
              />
              <FormControlLabel
                value="other"
                control={<GRadio color={'secondary'} />}
                label={<GInputLabel>other</GInputLabel>}
              />
            </RadioGroup>
            <br />
            <RadioGroup
              row
              sx={{ gap: '12px' }}
              defaultValue="female"
              aria-labelledby="demo-customized-radios"
              name="secondary-radios"
            >
              <FormControlLabel
                checked
                value="disabled"
                disabled
                control={<GRadio color={'secondary'} />}
                label={<GInputLabelDisabled>disabled option</GInputLabelDisabled>}
              />
              <FormControlLabel
                value="disabled"
                disabled
                control={<GRadio color={'secondary'} />}
                label={<GInputLabelDisabled>disabled option</GInputLabelDisabled>}
              />
            </RadioGroup>
          </FormControl>
          <GGuideTitle>Grid Radio</GGuideTitle>
          <FormGroup row sx={{ gap: '12px' }}>
            <FormControlLabel
              control={<GRadio defaultChecked />}
              label={<GInputLabel>option1</GInputLabel>}
            />
            <FormControlLabel control={<GRadio />} label={<GInputLabel>option2</GInputLabel>} />
            <FormControlLabel control={<GRadio />} label={<GInputLabel>option3</GInputLabel>} />
          </FormGroup>
          <GGuideTitle>Grid RadioButton</GGuideTitle>
          {/* 라디오 버튼 molecule로 구현되어 있음 */}
          <GridRadioButton value="radioGroup1" option={['radio1', 'radio2', 'radio3']} />
          {/*라디오는 무조건  radioGroup, value 꼭 작성해 주세요 (radioGroup는 밑에처럼 하나의 이름으로 통합해서 사용해 주세요)*/}
          <FormGroup>
            <RadioGroup
              row
              sx={{ gap: '10px' }}
              defaultValue="radio1"
              aria-labelledby="demo-customized-radios"
              name="primary-radios"
            >
              <GRadioButton radioGroup="radioGroup" value="radio1" text="1분기 말" />
              <GRadioButton radioGroup="radioGroup" value="radio2" text="2분기 말" />
              <GRadioButton radioGroup="radioGroup" value="radio3" text="3분기 말" />
              <GRadioButton radioGroup="radioGroup" value="radio4" text="연말" />
            </RadioGroup>
          </FormGroup>
          <FormGroup>
            <RadioGroup
              row
              sx={{ gap: '10px' }}
              aria-labelledby="demo-customized-radios"
              name="primary-radios"
            >
              <GRadioButton
                radioGroup="radioBtnGroup"
                value="radiobtn1"
                text="small"
                size="small"
              />
              <GRadioButton radioGroup="radioBtnGroup" value="radiobtn2" text="medium" />
              <GRadioButton
                size="large"
                radioGroup="radioBtnGroup"
                value="radiobtn3"
                text="large"
                isSelected={true}
              />
            </RadioGroup>
          </FormGroup>
          <FormGroup>
            <RadioGroup
              row
              sx={{ gap: '10px' }}
              aria-labelledby="demo-customized-radios"
              name="primary-radios"
            >
              <GRadioButton
                isSelected={true}
                isDisabled={true}
                radioGroup="radiobtnTest"
                value="radiobtnTest1"
                text="isSelected"
              />
              <GRadioButton
                isDisabled={true}
                radioGroup="radiobtnTest"
                value="radiobtnTest2"
                text="isDisabled"
              />
            </RadioGroup>
          </FormGroup>
        </Box>
        <br />
        {/* checkbox */}
        <Box sx={{ marginTop: '30px' }}>
          <GGuideTitle>Primary Checkbox (PCheckbox) --- Grid외에 사용</GGuideTitle>
          <FormGroup sx={{ gap: '10px' }}>
            <FormControlLabel
              disabled
              control={<BpBarbox defaultChecked />}
              label={<GInputLabel>optionInter</GInputLabel>}
            />
            <FormControlLabel
              control={<BpCheckbox defaultChecked />}
              label={<GInputLabel>option1</GInputLabel>}
            />
            <FormControlLabel control={<BpCheckbox />} label={<GInputLabel>option2</GInputLabel>} />
            <FormControlLabel control={<BpCheckbox />} label={<GInputLabel>option2</GInputLabel>} />
          </FormGroup>
          <br />
          <FormGroup row sx={{ gap: '12px' }}>
            <FormControlLabel
              checked
              disabled
              control={<BpCheckbox />}
              label={<GInputLabelDisabled>disabled checkbox</GInputLabelDisabled>}
            />
            <FormControlLabel
              disabled
              control={<BpCheckbox />}
              label={<GInputLabelDisabled>disabled checkbox</GInputLabelDisabled>}
            />
          </FormGroup>
          <GGuideTitle>Secondary Checkbox(GSCheckbox) --- Grid에만 사용</GGuideTitle>
          <FormGroup row sx={{ gap: '12px' }}>
            <FormControlLabel
              control={<BpBarbox color={'secondary'} defaultChecked />}
              label={<GInputLabel>optionInter</GInputLabel>}
            />
            <FormControlLabel
              disabled
              control={<BpBarbox color={'secondary'} defaultChecked />}
              label={<GInputLabel>optionInter</GInputLabel>}
            />
          </FormGroup>
          <br />
          <FormGroup row sx={{ gap: '12px' }}>
            <FormControlLabel
              control={<BpCheckbox defaultChecked color={'secondary'} />}
              label={<GInputLabel>option1</GInputLabel>}
            />
            <FormControlLabel
              control={<BpCheckbox color={'secondary'} />}
              label={<GInputLabel>option2</GInputLabel>}
            />
            <FormControlLabel
              control={<BpCheckbox color={'secondary'} />}
              label={<GInputLabel>option3</GInputLabel>}
            />
          </FormGroup>
          <br />
          <FormGroup row sx={{ gap: '12px' }}>
            <FormControlLabel
              checked
              disabled
              control={<BpCheckbox color={'secondary'} />}
              label={<GInputLabelDisabled>disabled checkbox</GInputLabelDisabled>}
            />
            <FormControlLabel
              disabled
              control={<BpCheckbox color={'secondary'} />}
              label={<GInputLabelDisabled>disabled checkbox</GInputLabelDisabled>}
            />
          </FormGroup>
          <br />
          {/* checkbox 버튼 molecule로 구현되어 있음 */}
          <GridCheckbox value="checkbox1" option={['checkbox1', 'checkbox2', 'checkbox3']} />

          <FormGroup row sx={{ margin: '12px 0', gap: '4px' }}>
            <CheckboxGroup text="checkbox1" value="test1" />
            <CheckboxGroup text="checkbox2" value="test2" isSelected={true} />
            <CheckboxGroup text="checkbox3" value="test3" isDisabled={true} />
            <CheckboxGroup text="checkbox4" value="test4" isDisabled={true} isSelected={true} />
          </FormGroup>

          <FormGroup row sx={{ margin: '12px 0', gap: '4px', alignItems: 'center' }}>
            <CheckboxGroup text="large" value="test5" sizes="large" />
            <CheckboxGroup text="medium" value="test6" sizes="medium" />
            <CheckboxGroup text="small" value="test7" sizes="small" />
          </FormGroup>
        </Box>
        {/* GLabel */}
        <br />
        <br />
        <span>타이틀</span> <hr />
        <PageHeaderLayout showMenu={true} showBorder={true} />
        <br />
        <br />
        <span>서브타이틀(버튼)</span> <hr />
        <SubTitleBtnArea>
          <SubTitleBox showSubTitle={true} isVisbleSelect={true} />
          <TitleBtnBox>
            <GButton
              chkImg={List}
              txt="목록"
              sizes="small"
              btnstyled="outline"
              btnColor="normal"
              hasImg={true}
              hasTxt={true}
            ></GButton>
            <GButton
              chkImg={New}
              txt="수정"
              sizes="small"
              btnstyled="outline"
              btnColor="normal"
              hasImg={true}
              hasTxt={true}
            ></GButton>
            <GButton
              chkImg={Delete}
              txt="삭제"
              sizes="small"
              btnstyled="outline"
              btnColor="normal"
              hasImg={true}
              hasTxt={true}
            ></GButton>
          </TitleBtnBox>
        </SubTitleBtnArea>
        <br />
        <br />
        <span>서브타이틀</span> <hr />
        <SubTitleBtnArea>
          <SubTitleBox showSubTitle={true} isVisibleComment={true} isVisibleInfo={true} />
        </SubTitleBtnArea>
        <br />
        <span>서브타이틀 props</span> <hr />
        {/*
          content: subtitle text 변경
          isVisibleComment : 총 current/total
          isVisibleInfo: 도움말
          infoContent: info text 변경
          isVisbleSum: 총 건
          isVisbleSelect: select 추가
          showSubTitle: subtitle 보이는 여부
          commentCount: isVisbleSum안에 Total 값
          selectOption: select안에 값 변경
        */}
        <SubTitleBtnArea>
          <SubTitleBox
            showSubTitle={true}
            content="서브타이틀 props"
            isVisbleSum={true}
            commentCount={30}
            isVisibleComment={true}
            isVisbleSelect={true}
            selectOption={['10', '20', '30']}
            isVisibleInfo={true}
            infoContent="info수정"
          />
        </SubTitleBtnArea>
        <br />
        <span>파일첨부</span>
        <FileUploadZone
          ref={FileUploadZone}
          atchFileGrIdInput="9cb7a782-f893-4e6b-b58c-20794684bbc6"
          isMultipleFile={true}
          allowFileTypes={['txt', 'jpg', 'png', 'xlsx']}
        />
        <span>파일첨부 반 사이즈 버전 width 추가하기</span>
        <FileUploadZone
          ref={FileUploadZone}
          atchFileGrIdInput="9cb7a782-f893-4e6b-b58c-20794684bbc6"
          isMultipleFile={true}
          allowFileTypes={['txt', 'jpg', 'png', 'xlsx']}
          width="100%"
        />
        <br />
        <br />
        <br />
        <br />
        {/* ------------------------GLable------------------------ */}
        <span>label</span> <hr />
        <GLabelAtom align="left" isRequired={true} pTxt={undefined}>
          GLabel
        </GLabelAtom>
        <br />
        <GLabelAtom align="left" isInfo={true} pTxt="설명입니다.">
          GLabel
        </GLabelAtom>
        <br />
        <GLabelAtom align="left" isRequired={true} isInfo={true} pTxt="설명입니다.">
          GLabel
        </GLabelAtom>
        <br />
        <br />
        {/* ------------------------input------------------------ */}
        <span>input</span> <hr />
        {/* default */}
        <InputField
          status="default"
          align="left"
          width="210px"
          placeholder="Placeholder"
          helperText="default test"
        />
        <br />
        <br />
        {/* default - password */}
        <InputField
          status="default"
          isPassword={true}
          align="left"
          width="210px"
          placeholder="Placeholder"
          helperText="password를 입력하세요."
        />
        <br />
        <br />
        {/* default - right */}
        <InputField status="default" align="right" width="210px" placeholder="Placeholder" />
        <br />
        <br />
        {/* disabled */}
        <InputField
          status="disabled"
          align="left"
          width="210px"
          placeholder="Placeholder"
          disabled
        />
        <br />
        <br />
        {/* readonly */}
        <InputField
          status="readonly"
          align="left"
          width="210px"
          placeholder="Placeholder"
          value="Text"
          readOnly
        />
        <br />
        <br />
        {/* error */}
        <InputField
          status="error"
          align="left"
          width="210px"
          placeholder="Placeholder"
          helperText="error test"
        />
        <br />
        <br />
        {/* warning */}
        <InputField
          status="warning"
          align="left"
          width="210px"
          placeholder="Placeholder"
          helperText="warning test"
        />
        <br />
        <br />
        {/* confirmed */}
        <InputField
          status="confirmed"
          align="left"
          width="210px"
          placeholder="Placeholder"
          helperText="confirmed test"
        />
        <br />
        <br />
        {/* search */}
        <InputField status="search" align="left" width="210px" placeholder="Placeholder" />
        <br />
        <br />
        <br />
        <br />
        {/* ------------------------textarea------------------------ */}
        <span>textarea</span>
        <hr />
        {/* default */}
        <TextArea status="default" width="214px" height="72px" placeholder="Placeholder" />
        <br />
        <br />
        {/* disabled */}
        <TextArea
          status="disabled"
          width="214px"
          height="72px"
          placeholder="Placeholder"
          disabled
        />
        <br />
        <br />
        {/* readonly */}
        <TextArea status="readonly" width="214px" height="72px" value="TextArea" readOnly />
        <br />
        <br />
        {/* error */}
        <TextArea status="error" width="214px" height="72px" placeholder="Placeholder" />
        <br />
        <br />
        {/* warning */}
        <TextArea status="warning" width="214px" height="72px" placeholder="Placeholder" />
        <br />
        <br />
        {/* confirmed */}
        <TextArea status="confirmed" width="214px" height="72px" placeholder="Placeholder" />
        <br />
        <br />
        {/* 글자수제한 표시 */}
        <TextArea
          status="default"
          width="214px"
          height="87px"
          placeholder="Placeholder"
          charlimit={true}
          count="120 / 500"
        />
        <br />
        <br />
        {/* 댓글 */}
        <TextArea
          status="default"
          width="616px"
          height="auto"
          placeholder="댓글을 입력해 주세요."
          isComment={true}
          count="120 / 500"
        />
        <br />
        <br />
        {/* 댓글수정용~ */}
        <TextArea
          status="default"
          width="616px"
          height="auto"
          placeholder="댓글을 입력해 주세요."
          isComment={true}
          reComment={true}
          count="120 / 500"
        />
        <br />
        <br />
        {/* 대댓글용~ */}
        <TextArea
          status="default"
          width="616px"
          height="auto"
          placeholder="댓글을 입력해 주세요."
          isComment={true}
          reComment={true}
          reCommentIng={true}
          nickName="김엔솔"
          count="120 / 500"
        />
        <br />
        <br />
        <br />
        <br />
        {/* ------------------------select------------------------ */}
        {/* select의 State를 같이 해서 같이 변합니다. State를 따로 구현한다면 문제는 없습니다. */}
        <span>select</span>
        <hr />
        {/* default */}
        <GSelectMUIAtom
          selectWidth="210px"
          placeholder="placeholder"
          option={['옵션1', '옵션2', '옵션3']}
          readOnly={false}
          value={option}
          handleChange={handleChange}
        />
        <br />
        <br />
        {/* placeholder 없음 */}
        <GSelectMUIAtom
          selectWidth="210px"
          option={['옵션1', '옵션2', '옵션3']}
          readOnly={false}
          value={option}
          handleChange={handleChange}
        />
        <br />
        <br />
        {/* readOnly={true} */}
        <GSelectMUIAtom
          selectWidth="210px"
          placeholder="readOnly={true}"
          option={['옵션1', '옵션2', '옵션3']}
          readOnly={true}
          value={option}
          handleChange={handleChange}
        />
        <br />
        <br />
        {/* disabled={true} */}
        <GSelectMUIAtom
          selectWidth="210px"
          placeholder="disabled={true}"
          option={['옵션1', '옵션2', '옵션3']}
          disabled={true}
          value={option}
          handleChange={handleChange}
        />
        <br />
        <br />
        <br />
        <br />
        {/* default */}
        <GCodeSelect
          id={'cmnCd'}
          labelKey={'cmnCdNm'}
          emptyLabel="전체"
          listData={dommy}
          value={searchParams.coCd}
          onChange={handleChangeCoCd}
          width="210px"
        />
        <br />
        {/* input의 Placeholder O + option 20개 이상 */}
        <GCodeSelect
          id={'cmnCd'}
          labelKey={'cmnCdNm'}
          emptyLabel="전체"
          listData={dommy2}
          value={searchParams.coCd}
          onChange={handleChangeCoCd}
          width="210px"
          inputPlaceholder="Placeholder"
        />
        <br />
        {/* input만 readonly => 검색 불가 */}
        <GCodeSelect
          id={'cmnCd'}
          labelKey={'cmnCdNm'}
          emptyLabel="전체"
          listData={dommy}
          value={searchParams.coCd}
          onChange={handleChangeCoCd}
          width="210px"
          readonlyInput={true}
        />
        <br />
        {/* 전체 readonly */}
        <GCodeSelect
          id={'cmnCd'}
          labelKey={'cmnCdNm'}
          emptyLabel="전체"
          listData={dommy}
          value={searchParams.coCd}
          onChange={handleChangeCoCd}
          width="210px"
          readonly={true}
          readonlyInput={true}
        />
        <br />
        {/* 전체 disabled */}
        <GCodeSelect
          id={'cmnCd'}
          labelKey={'cmnCdNm'}
          emptyLabel="전체"
          listData={dommy}
          value={searchParams.coCd}
          onChange={handleChangeCoCd}
          width="210px"
          disabled={true}
        />
        <br />
        <br />
        <br />
        <br />
        {/* ------------------------searchMoleule------------------------ */}
        <span>searchMoleule</span>
        <hr />
        {/* label + input */}
        <SearchMolecule type="input" labelTitle="input" />
        <br />
        {/* label( isRequired = {true} ) + input */}
        <SearchMolecule type="input" labelTitle="input" isRequired={true} />
        <br />
        {/* label( isRequired = {true} ) + input */}
        <SearchMolecule type="input" labelTitle="input" isInfo={true} pTxt="설명글 입니다." />
        <br />
        {/* label + input(placeholder)*/}
        <SearchMolecule type="input" labelTitle="input" placeholder="placeholder" />
        <br />
        {/* label + select */}
        <SearchMolecule
          type="select"
          labelTitle="select"
          placeholder="placeholder"
          selectOption={['옵션1', '옵션2']}
        />
        <br />
        {/* label + select(ReadOnly) */}
        <SearchMolecule
          type="select"
          labelTitle="select"
          placeholder="placeholder"
          selectOption={['옵션1', '옵션2']}
          selectReadOnly={true}
        />
        <br />
        {/* label + checkBox */}
        <SearchMolecule
          type="checkBox"
          labelTitle="checkBox"
          checkboxValue="checkboxTest"
          checkboxOption={['1', '2', '3']}
        />
        <br />
        {/* label + radioButton */}
        <SearchMolecule
          type="radioButton"
          labelTitle="radioButton"
          radioValue="radioTest"
          radioOption={['1', '2', '3']}
        />
        <br />
        {/* label + radio */}
        <SearchMolecule
          type="radio"
          labelTitle="radio"
          radioValue="radioTest2"
          radioOption={['1', '2', '3']}
        />
        <br />
        <br />
        <br />
        <br />
        {/* ------------------------SelectMolecule------------------------ */}
        <span>selectMolecule (select + select/radio)</span>
        <hr />
        <br />
        <br />
        <SelectMolecule
          selectOption={['옵션1', '옵션2']}
          radioValue="select"
          radioOption={['1', '2', '3', '4']}
          valueType="select"
          valueSelectOption={['1', '2']}
        />
        <br />
        <br />
        <SelectMolecule
          selectOption={['옵션1', '옵션2']}
          selectIsRequired={true}
          valueType="select"
          valueSelectOption={['1', '2']}
          radioValue="select2"
          radioOption={['1', '2']}
        />
        <br />
        <br />
        <br />
        <br />
        {/* ------------------------searchBox------------------------ */}
        <span>searchBox</span>
        <hr />
        <SearchBox />
        <br />
        <br />
        <br />
        <br />
        {/* Table (샘플타입2보시면됩니다!) */}
        <span>GbasicTableWrap isBottomMgn = true margin-bottom 간격 12px로 지정</span>
        <br />
        <span>오른쪽 정렬이 필요한 td일시, GbasicTd로 대신 사용</span>
        <span>Table</span>
        <hr />
        <FormControl fullWidth>
          <GbasicTableWrap>
            <GbasicTable>
              <colgroup>
                <col style={{ width: '160px' }} />
                <col style={{ width: 'auto' }} />
                <col style={{ width: '160px' }} />
                <col style={{ width: 'auto' }} />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    <GLabelAtom isRequired={true} align="left">
                      법인
                    </GLabelAtom>
                  </th>
                  <td colSpan={3}>
                    <InputField status="default" width="100%" placeholder="입력하세요" />
                  </td>
                </tr>
                <tr>
                  <th>
                    <GLabelAtom align="left">보험종류</GLabelAtom>
                  </th>
                  <td>
                    <AlignBox>
                      <AlignItem width="120px">
                        <GCodeSelect
                          id={'cmnCd'}
                          labelKey={'cmnCdNm'}
                          emptyLabel="전체"
                          listData={dommy}
                          value={searchParams.coCd}
                          onChange={handleChangeCoCd}
                        />
                      </AlignItem>
                      <AlignItem width="90px">
                        <GCodeSelect
                          id={'cmnCd'}
                          labelKey={'cmnCdNm'}
                          emptyLabel="전체"
                          listData={dommy}
                          value={searchParams.coCd}
                          onChange={handleChangeCoCd}
                        />
                      </AlignItem>
                      <AlignItem>
                        <InputField
                          status="default"
                          width="100%"
                          height="auto"
                          placeholder="입력하세요"
                        />
                      </AlignItem>
                    </AlignBox>
                  </td>
                  <th>
                    <GLabelAtom align="left">보험연도</GLabelAtom>
                  </th>
                  <td>
                    <label>{basicFormData?.lbl3}</label>
                  </td>
                </tr>
                <tr>
                  <th>
                    <GLabelAtom align="left">보험기간</GLabelAtom>
                  </th>
                  <td>
                    <GCodeSelect
                      id={'cmnCd'}
                      labelKey={'cmnCdNm'}
                      emptyLabel="전체"
                      listData={dommy}
                      value={searchParams.coCd}
                      onChange={handleChangeCoCd}
                    />
                  </td>
                  <th>
                    <GLabelAtom align="left">증권번호</GLabelAtom>
                  </th>
                  <td>
                    <label>{basicFormData?.lbl3}</label>
                  </td>
                </tr>
              </tbody>
            </GbasicTable>
          </GbasicTableWrap>
          <br />
          <p>AlignBox입니다</p>
          <br />
          <AlignBox>
            <AlignItem width={`120px`}>
              <GCodeSelect
                id={'cmnCd'}
                labelKey={'cmnCdNm'}
                emptyLabel="전체"
                listData={dommy}
                value={searchParams.coCd}
                onChange={handleChangeCoCd}
              />
            </AlignItem>
            <AlignItem width={`90px`}>
              <GCodeSelect
                id={'cmnCd'}
                labelKey={'cmnCdNm'}
                emptyLabel="전체"
                listData={dommy}
                value={searchParams.coCd}
                onChange={handleChangeCoCd}
              />
            </AlignItem>
            <AlignItem>
              <InputField status="default" width="100%" height="auto" placeholder="입력하세요" />
            </AlignItem>
          </AlignBox>
          <br />
          <AlignBox>
            <AlignItem>
              <GCodeSelect
                id={'cmnCd'}
                labelKey={'cmnCdNm'}
                emptyLabel="전체"
                listData={dommy}
                value={searchParams.coCd}
                onChange={handleChangeCoCd}
              />
            </AlignItem>
            <AlignItem>
              <GCodeSelect
                id={'cmnCd'}
                labelKey={'cmnCdNm'}
                emptyLabel="전체"
                listData={dommy}
                value={searchParams.coCd}
                onChange={handleChangeCoCd}
              />
            </AlignItem>
            <AlignItem>
              <InputField status="default" width="100%" height="auto" placeholder="입력하세요" />
            </AlignItem>
          </AlignBox>
        </FormControl>
        <br />
        <br />
        <br />
        <br />
        <span>Date Picker</span> <hr />
        <br />
        <GDatePicker isEssential views={['year', 'month', 'day']} width="128px" maxWidth="100%" />
        <br />
        <br />
        <GDatePicker views={['year', 'month', 'day']} width="128px" maxWidth="100%" />
        <br />
        <br />
        <GDatePicker
          disabled
          readonly
          views={['year', 'month', 'day']}
          width="128px"
          maxWidth="100%"
        />
        <br />
        <br />
        <GDatePicker readonly width="128px" views={['year', 'month', 'day']} />
        <br />
        <br />
        <GDatePicker width="128px" status="error" views={['year', 'month', 'day']} />
        <br />
        <br />
        <GDatePicker width="128px" status="warning" views={['year', 'month', 'day']} />
        <br />
        <br />
        <GDatePicker width="128px" status="confirm" views={['year', 'month', 'day']} />
        <br />
        <br />
        <GDatePicker width="96px" format="YYYY" views={['year']} />
        <br />
        <br />
        <GDatePicker width="96px" format="YYYY" views={['year']} double={true} />
        <br />
        <GDatePicker
          width="128px"
          format="YYYY.MM.DD"
          views={['year', 'month', 'day']}
          double={true}
        />
        <br />
        <GDatePicker readonly width="96px" format="YYYY" views={['year']} />
        <br />
        <br />
        <GDatePicker readonly width="96px" format="YYYY" views={['year']} double={true} />
        <br />
        <GDatePicker
          readonly
          width="128px"
          format="YYYY.MM.DD"
          views={['year', 'month', 'day']}
          double={true}
        />
        <br />
        <br />
        <br />
        <br />
        <span>Time</span> <hr />
        <br />
        <Time defaultStatus />
        <br />
        <Time disabled />
        <br />
        <Time readOnly />
        <br />
        <Time error={true} />
        <br />
        <Time warning={true} />
        <br />
        <Time confirmed={true} />
        <br />
        <br />
        <br />
        <br />
        <span>search box (single chip)</span> <hr />
        <br />
        <p>보험증권ID 개발 시 사용하는 search box 입니다.</p>
        <br />
        <p>chip type 1</p>
        <br />
        <SingleChipSelect
          width="240px"
          height="32px"
          onClickFunction={onSearchClick}
          isChip={chipClick}
          chipType="default"
        />
        <br />
        <br />
        <p>chip type 2</p>
        <br />
        <SingleChipSelect
          width="240px"
          height="32px"
          onClickFunction={onSearchClick}
          isChip={chipClick}
          chipType="user"
          chipImg={chipUser}
        />
        <br />
        <br />
        <SingleChipSelect
          width="240px"
          height="32px"
          onClickFunction={onSearchClick}
          isChip={chipClick}
        />
        <br />
        <br />
        <SingleChipSelect
          width="240px"
          format="placeholder"
          onClickFunction={onSearchClick}
          isChip={chipClick}
        />
        <br />
        <br />
        <SingleChipSelect
          status="error"
          width="240px"
          format="placeholder"
          onClickFunction={onSearchClick}
          isChip={chipClick}
        />
        <br />
        <br />
        <SingleChipSelect
          status="warning"
          width="240px"
          format="placeholder"
          onClickFunction={onSearchClick}
          isChip={chipClick}
        />
        <br />
        <br />
        <SingleChipSelect
          status="confirm"
          width="240px"
          format="placeholder"
          onClickFunction={onSearchClick}
          isChip={chipClick}
        />
        <br />
        <br />
        <br />
        <br />
        <span>multiple chip select(type 1)</span> <hr />
        <br />
        <p>chip type 1(default)</p>
        <br />
        <ChipSelectBox
          chipType="default"
          type="1"
          width="400px"
          height="32px"
          onClickFunction={onSearchClick}
        />
        <br />
        <p>chip type 2(user)</p>
        <br />
        <ChipSelectBox
          chipType="user"
          chipImg={chipUser}
          type="1"
          width="400px"
          height="32px"
          onClickFunction={onSearchClick}
        />
        <br />
        <p>CheckBox</p>
        <br />
        <ChipSelectBox
          isCheck
          type="1"
          width="400px"
          height="32px"
          onClickFunction={onSearchClick}
        />
        <br />
        <br />
        <ChipSelectBox type="1" width="400px" height="32px" onClickFunction={onSearchClick} />
        <br />
        <br />
        <ChipSelectBox
          type="1"
          width="400px"
          format="placeholder"
          onClickFunction={onSearchClick}
        />
        <br />
        <br />
        <ChipSelectBox
          disabled
          readonly
          type="1"
          width="400px"
          format="placeholder"
          onClickFunction={onSearchClick}
        />
        <br />
        <br />
        <ChipSelectBox
          readonly
          type="1"
          width="400px"
          format="placeholder"
          onClickFunction={onSearchClick}
        />
        <br />
        <br />
        <ChipSelectBox
          type="1"
          width="400px"
          status="error"
          format="placeholder"
          onClickFunction={onSearchClick}
        />
        <br />
        <br />
        <ChipSelectBox
          type="1"
          width="400px"
          status="warning"
          format="placeholder"
          onClickFunction={onSearchClick}
        />
        <br />
        <br />
        <ChipSelectBox
          type="1"
          width="400px"
          status="confirm"
          format="placeholder"
          onClickFunction={onSearchClick}
        />
        <br />
        <br />
        <br />
        <br />
        <span>chip select(type 2)</span> <hr />
        <br />
        <br />
        <ChipSelectBox type="2" width="240px" height="32px" status="default" />
        <br />
        <br />
        <ChipSelectBox type="2" width="240px" format="placeholder" />
        <br />
        <br />
        <ChipSelectBox disabled readonly type="2" width="240px" format="placeholder" />
        <br />
        <br />
        <ChipSelectBox readonly type="2" width="240px" format="placeholder" />
        <br />
        <br />
        <ChipSelectBox type="2" width="240px" status="error" format="placeholder" />
        <br />
        <br />
        <ChipSelectBox type="2" width="240px" status="warning" format="placeholder" />
        <br />
        <br />
        <ChipSelectBox type="2" width="240px" status="confirm" format="placeholder" />
        <br />
        <br />
        <br />
        <br />
        <span>AvatarChip</span> <hr />
        <br />
        <br />
        <Box sx={{ display: 'flex', gap: '4px' }}>
          <AvatarChip
            type="text"
            size="largest"
            backgroundBorder="lightGray"
            profileBackgroundColor="#fff"
            profileBorder="#fff"
            profileItemColor="#554596"
          />
          <AvatarChip
            type="text"
            size="largest"
            backgroundBorder="lightGray"
            profileBackgroundColor="#554596"
            profileBorder="#554596"
            profileItemColor="#fff"
          />
          <AvatarChip
            type="user"
            size="largest"
            backgroundBorder="lightGray"
            profileBackgroundColor="#554596"
            profileBorder="#554596"
            profileImg={avatarUser}
          />
          <AvatarChip
            type="custom"
            size="largest"
            backgroundBorder="lightGray"
            profileImg={Avatar}
          />
        </Box>
        <br />
        <br />
        <Box sx={{ display: 'flex', gap: '4px' }}>
          <AvatarChip
            type="custom"
            size="medium"
            backgroundBorder="lightGray"
            nickName="김엔솔"
            profileImg={Avatar}
          />
          <AvatarChip
            type="custom"
            size="medium"
            backgroundBorder="defaultGray"
            nickName="김엔솔"
            profileImg={Avatar}
          />
          <AvatarChip
            type="custom"
            size="medium"
            backgroundBorder="darkGray"
            backgroundColor="darkGray"
            nickName="김엔솔"
            nickNameColor="white"
            profileImg={Avatar}
          />
          <AvatarChip
            disabled
            type="custom"
            size="medium"
            backgroundBorder="lightGray"
            nickName="김엔솔"
            profileImg={Avatar}
          />
        </Box>
        <br />
        <br />
        <Box sx={{ display: 'flex', gap: '4px' }}>
          <AvatarChip
            type="custom"
            size="small"
            backgroundBorder="lightGray"
            backgroundColor="white"
            nickName="김엔솔"
            profileImg={Avatar}
          />
          <AvatarChip
            type="custom"
            size="small"
            backgroundBorder="defaultGray"
            backgroundColor="lightGray"
            nickName="김엔솔"
            profileImg={Avatar}
          />
          <AvatarChip
            type="custom"
            size="small"
            backgroundBorder="blue"
            backgroundColor="white"
            nickName="김엔솔"
            nickNameColor="blue"
            profileImg={Avatar}
          />
          <AvatarChip
            disabled
            type="custom"
            size="small"
            backgroundBorder="lightGray"
            backgroundColor="white"
            nickName="김엔솔"
            profileImg={Avatar}
          />
          <AvatarChip
            disabled
            type="custom"
            size="small"
            backgroundBorder="blue"
            backgroundColor="white"
            nickName="김엔솔"
            nickNameColor="blue"
            profileImg={Avatar}
          />
        </Box>
        <br />
        <br />
        <br />
        <br />
        <span>Profile</span> <hr />
        <br />
        <br />
        <Profile />
        <br />
        <br />
        <Profile type="1" profileImg={Avatar} nickName="커스텀" />
        <br />
        <br />
        <Profile type="2" profileImg={Avatar} nickName="커스텀" />
        <br />
        <br />
        <Profile type="2" profileImg={Avatar} nickName="커스텀" isPosition position="책임" />
        <br />
        <br />
        <br />
        <span>EditProfile</span> <hr />
        <br />
        <br />
        {/* EditProfile */}
        <EditProfile profileImg={ProfileImg} nickName="김엔솔" />
        <br />
        <br />
        <EditProfile profileImg={ProfileImgP} nickName="이엔솔" background="#ffffff" />
        <br />
        <br />
        <span>등록 폼</span> <hr />
        <br />
        <br />
        <RegistBox />
        <br />
        <br />
        <br />
        <br />
        <span>GTag(수정 현재파일명 Tag)</span> <hr />
        <br />
        <Box sx={{ display: 'flex', gap: '4px' }}>
          <Tag status="completion" content="추가" />
          <Tag status="ongoing" content="진행중" />
          <Tag status="waiting" content="작성대기" />
          <Tag status="waitingCompletion" content="완료후 대기중" />
          <Tag status="emergency" content="에러" />
          <Tag status="end" content="기간만료" />
          <Tag status="possibility" content="가능" />
        </Box>
        <br />
        <br />
        <br />
        <br />
        <span>Data Grid (ag-grid)</span> <hr />
        <br />
        <GContentGrid type="check" className="ag-theme-alpine" style={{ height: '355px' }}>
          <AgGridReact
            defaultColDef={defaultColDef}
            columnDefs={colDefs}
            rowData={sampleList}
            rowSelection={'single'}
            suppressMovableColumns
            enableCellTextSelection
            onGridReady={(params) => (gridApiRef.current = params.api)}
            tooltipShowDelay={0}
            tooltipHideDelay={2000}
          ></AgGridReact>
        </GContentGrid>
        <br />
        <br />
        <br />
        <span>Data Grid Pagination</span> <hr />
        <GPagination
          ref={paginationRef}
          totalCount={totalCount}
          defaultPageNo={pageNo}
          defaultPageSize={pageSize}
          onChangePageSize={handlePageSizeChange}
          onClickPageNo={handlePageNoClick}
        />
        <br />
        <br />
        <br />
        <br />
        <span>alert</span> <hr />
        <br />
        <MessageAlert type="success" message="주주명(기준정보)가 확인되었습니다." />
        <br />
        <br />
        <MessageAlert
          type="success"
          message="주주명(기준정보)가 확인되었습니다. 주주명(기준정보)가 확인되었습니다. 주주명(기준정보)가 확인되었습니다. "
        />
        <br />
        <br />
        <MessageAlert type="error" message="주주명(기준정보)가 일치하지 않습니다." />
        <br />
        <br />
        <MessageAlert
          type="error"
          message="주주명(기준정보)가 일치하지 않습니다. 주주명(기준정보)가 일치하지 않습니다. 주주명(기준정보)가 일치하지 않습니다."
        />
        <br />
        <br />
        <br />
        <span>23/12/21 추가 컴포넌트</span>
        <hr />
        <span>SwitchButton</span>
        <br />
        <FormGroup>
          <FormControlLabel control={<SwitchButton />} label="" />
          <FormControlLabel control={<SwitchButton color={'secondary'} />} label="" />
          <FormControlLabel disabled control={<SwitchButton />} label="" />
          <FormControlLabel disabled checked control={<SwitchButton />} label="" />
          <FormControlLabel
            disabled
            checked
            control={<SwitchButton color={'secondary'} />}
            label=""
          />
        </FormGroup>
        <span>라벨 스위치</span>
        <FormGroup>
          <FormControlLabel control={<SwitchButton isLabel={true} />} label="" />
          <FormControlLabel
            control={<SwitchButton color={'secondary'} isLabel={true} />}
            label=""
          />
        </FormGroup>
        <br />
        <br />
        <br />
        <br />
        <span>23/12/26 추가 컴포넌트</span>
        <hr />
        <span>EyeCount</span>
        <br />
        {/* EyeCount */}
        <EyeCount count={0} />
        <EyeCount count={26} />
        <br />
        <br />
        <br />
        <br />
        <span>NoticeBox(01/02 추가)</span> <hr />
        <br />
        <NoticeBox type="error">
          <NoticeText>모든 정합성이 정상입니다.</NoticeText>
        </NoticeBox>
        <br />
        <br />
        <NoticeBox type={'success'}>
          <NoticeList>
            <Item>
              모든 정합성이 정상입니다. <LinkText href="#">자세히 알아보기</LinkText>
            </Item>
            <Item>총회를 종료 할 수 있습니다.</Item>
          </NoticeList>
        </NoticeBox>
        <br />
        <br />
        <br />
      </Box>
      <br />
      <br />
      <hr />
      <p>탭</p>
      <br />
      <GTabs defaultValue={1} title={['계좌관리', '해외원화']}>
        <>
          <span>첫번째 화면</span>
        </>
        <>
          <span>두번째 화면</span>
        </>
      </GTabs>
      <br />
      <br />
      <br />
      <br />
      <span>side bar</span> <hr />
      <br />
      <br />
      <SideMenuBar width="240px" />
      <br />
      <br />
      <div style={{ width: '240px', border: '1px solid #dde0df' }}>
        <TreeView defaultCollapseIcon={<ExpandedMenu />} defaultExpandIcon={<ExpandMenu />}>
          <TreeItem nodeId="1" label="LG엔솔">
            <TreeItem nodeId="2" label="LG">
              <TreeItem nodeId="3" label="Life is good" />
              <TreeItem nodeId="4" label="럭키금성">
                <TreeItem nodeId="4" label="백두산" />
              </TreeItem>
            </TreeItem>
          </TreeItem>
          <TreeItem nodeId="7" label="LG화학">
            <TreeItem nodeId="8" label="굿" />
            <TreeItem nodeId="9" label="보험재산">
              <TreeItem nodeId="10" label="보험적하" />
              <TreeItem nodeId="11" label="밥밥디라라" />
              <TreeItem nodeId="12" label="LG" />
            </TreeItem>
          </TreeItem>
        </TreeView>
      </div>
      <br />
      <br />
      <br />
    </>
  );
};

export default TestPage;
