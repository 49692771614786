import { ColDef, ICellRendererParams, RowNode } from 'ag-grid-community';
import { DefaultCellTooltip } from 'components/grids/tooltip/DefaultCellTooltip';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

// 상: 체크박스 하:라디오
import radioIcon from 'components/asset/RadioColumn.svg';

export const useColumnDefsForApprovalList = () => {
  const { t } = useTranslation();
  const selectedRowRef = useRef<RowNode | null>(null);
  const defaultColDef = useMemo((): ColDef => {
    return {
      sortable: true,
      resizable: true,
      cellStyle: { textAlign: 'center' },
      flex: 1,
      tooltipComponent: DefaultCellTooltip,
    };
  }, []);

  const colDefs = useMemo((): any[] => {
    return [
      {
        headerName: 'radio',
        headerComponentParams: {
          template: `<img src=${radioIcon} alt=''/>`,
        },
        width: '28px !important',
        height: '24px !important',
        padding: '2px 6px',
        resizable: false,
        headerCheckboxSelection: true,
        showDisabledCheckboxes: true,
        checkboxSelection: true,
        flex: 0,
        cellStyle: {
          display: 'flex',
          justifyContent: 'center',
          width: '28px !important',
          height: '24px',
        },
      },
      {
        headerName: t('컬럼1코드', '__보험증권 ID'),
        flex: 1,
        field: 'column1',
        tooltipField: 'IP000000',
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: t('컬럼2코드', '__법인'),
        minWidth: 120,
        field: 'column2',
        tooltipField: 'column2',
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: t('컬럼3코드', '__보험종류'),
        minWidth: 223,
        field: 'column3',
        tooltipField: 'column3',
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: t('컬럼4코드', '__보험연도'),
        minWidth: 120,
        field: 'column4',
        tooltipField: 'column4',
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: t('컬럼5코드', '__증권번호'),
        minWidth: 120,
        field: 'column5',
        tooltipField: 'column5',
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: t('컬럼6코드', '__보험기간'),
        minWidth: 223,
        field: 'column6',
        tooltipField: 'column6',
        cellStyle: { textAlign: 'center' },
      },
    ];
  }, []);

  const handleAprvDetailBtn = (data) => {
    console.log('화면 연결이 필요합니다.');
  };

  return { defaultColDef, colDefs };
};
