import { ColDef } from 'ag-grid-community';
import { DefaultCellTooltip } from 'components/grids/tooltip/DefaultCellTooltip';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import checkIcon from 'components/asset/UnChecked.svg';

// 인물검색(복수) - 선택한 목록 grid
export const UseColumnDefsForSearchPersonSelect = () => {
  const { t } = useTranslation();
  const sDefaultColDef = useMemo((): ColDef => {
    return {
      sortable: false,
      resizable: true,
      cellStyle: { textAlign: 'center' },
      // flex: 1,
      tooltipComponent: DefaultCellTooltip,
    };
  }, []);

  const sColDefs = useMemo((): any[] => {
    return [
      {
        headerName: 'check',
        headerComponentParams: {
          template: `<img src=${checkIcon} alt=''/>`,
        },
        width: '28px',
        height: 24,
        padding: '2px 6px',
        resizable: false,
        headerCheckboxSelection: true,
        showDisabledCheckboxes: true,
        checkboxSelection: true,
        cellStyle: {
          display: 'flex',
          justifyContent: 'center',
          width: '28px',
          height: 24,
        },
      },
      {
        headerName: t('이름', '__이름'),
        field: 'empNm',
        tooltipField: 'empNm',
        width: '120px',
        minWidth: 120,
      },
      {
        headerName: t('이메일', '__이메일'),
        field: 'emlSvrDmnIfoNm',
        tooltipField: 'emlSvrDmnIfoNm',
        width: '120px !important',
        minWidth: 120,
        flex: 1,
      },
    ];
  }, []);

  const handleAprvDetailBtn = (data) => {
    console.log('화면 연결이 필요합니다.');
  };

  return { sDefaultColDef, sColDefs };
};
