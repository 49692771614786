import styled from '@emotion/styled';
import { GlobalTokenColor } from 'ui/theme/Color';

interface TextProps {
  content?: string;
  height?: string;
  color?: 'black';
  fontSize?: string;
  fontWeight?: string;
  lineHeight?: string;
}

export default function Text({
  content = '',
  height = '100%',
  color = 'black',
  fontSize = '13px',
  fontWeight = '400',
  lineHeight = '150%',
}: TextProps) {
  return (
    <TextAtom
      height={height}
      color={color}
      fontSize={fontSize}
      fontWeight={fontWeight}
      lineHeight={lineHeight}
    >
      {content}
    </TextAtom>
  );
}

const TextAtom = styled.p<TextProps>`
  height: ${(props) => props.height};
  color: ${(props) => (props.color === 'black' ? `${GlobalTokenColor.ESGrey900}` : ``)};
  font-size: ${(props) => props.color};
  font-weight: ${(props) => props.color};
  line-height: ${(props) => props.color};
  display: flex;
  align-items: center;
`;
