import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import styled from '@emotion/styled';

import { Link } from 'react-router-dom';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Color } from 'ui/theme/Color';
import { FirstEndIcon, PrevArrowIcon, LastEndIcon, NextArrowIcon } from 'components/icons/Icons';

interface PaginationProps {
  totalCount: number;
  defaultPageNo?: number;
  defaultPageSize?: number;
  onChangePageSize: (param: any) => void;
  onClickPageNo: (param: number) => void;
}
export interface PaginationRef {
  setSelectNo: (no: number) => void;
  setPageSize: (pageSize: number) => void;
}

// eslint-disable-next-line react/display-name
export const GPagination = forwardRef((props: PaginationProps, ref?: React.Ref<PaginationRef>) => {
  const defalutPageLength = 10;
  const [pageLength, setPageLength] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(props.defaultPageSize ?? 10);
  const [pageNoList, setPageNoList] = useState<number[]>([]);
  const [selectedNo, setSelectedNo] = useState<number>(props.defaultPageNo ?? 1);
  const [lastPageNo, setLastPageNo] = useState<number>(10);

  useEffect(() => {
    setSelectedNo(props.defaultPageNo ?? 1);
  }, [props.defaultPageNo]);

  useImperativeHandle(ref, () => ({
    setSelectNo: (no: number) => {
      setSelectedNo(no);
      const index = Math.floor((no - 1) / pageSize);
      const firstNoOfRange = index * pageSize + 1;

      if (lastPageNo < defalutPageLength) {
        setPageLength(lastPageNo);
        setPageNoList(getRange(firstNoOfRange, lastPageNo));
      } else if (firstNoOfRange > defalutPageLength && lastPageNo % defalutPageLength != 0) {
        setPageLength(lastPageNo % defalutPageLength);
        setPageNoList(getRange(firstNoOfRange, lastPageNo % defalutPageLength));
      } else {
        setPageLength(defalutPageLength);
        setPageNoList(getRange(firstNoOfRange, defalutPageLength));
      }
    },
    setPageSize: (pageSize: number) => {
      setPageSize(pageSize);
      const lastPageNo = Math.floor(props.totalCount / pageSize) + 1;
      setLastPageNo(lastPageNo);
      setSelectedNo(1);
    },
  }));

  const getRange = (start: number, length: number) => {
    return Array.from({ length: length }, (_, i) => start + i);
  };

  const handlePageNoClick = (no: number) => {
    setSelectedNo(no);
    props.onClickPageNo(Number(no));
  };

  const handleFirstPageClick = () => {
    setSelectedNo(1);
    props.onClickPageNo(1);
    if (lastPageNo < defalutPageLength) {
      setPageNoList(getRange(1, pageLength));
    } else {
      setPageLength(defalutPageLength);
      setPageNoList(getRange(1, defalutPageLength));
    }
  };

  const handleDoubleArrowLeftClick = () => {
    if (selectedNo - pageLength < 1) {
      handleFirstPageClick();
    } else {
      if (lastPageNo < defalutPageLength) {
        setPageNoList(getRange(pageNoList[0] - pageLength, pageLength));
      } else {
        setPageLength(defalutPageLength);
        setPageNoList(getRange(pageNoList[0] - defalutPageLength, defalutPageLength));
      }
      props.onClickPageNo(selectedNo - pageLength);
      setSelectedNo((prevNo) => prevNo - pageLength);
    }
  };

  const handleArrowLeftClick = () => {
    if (selectedNo == 1) return false;
    if (selectedNo == pageNoList[0]) {
      if (lastPageNo < defalutPageLength) {
        setPageNoList(getRange(selectedNo - pageLength, pageLength));
      } else {
        setPageLength(defalutPageLength);
        setPageNoList(getRange(selectedNo - defalutPageLength, defalutPageLength));
      }
    }
    props.onClickPageNo(selectedNo - 1);
    setSelectedNo((prevNo) => prevNo - 1);
  };

  const handleArrowRightClick = () => {
    if (selectedNo == lastPageNo) return false;
    if (selectedNo == pageNoList[pageLength - 1]) {
      if (selectedNo + pageLength > lastPageNo) {
        setPageNoList(
          getRange(Math.floor(lastPageNo / pageLength) * pageLength + 1, lastPageNo % pageLength)
        );
      } else {
        setPageNoList(getRange(selectedNo + 1, pageLength));
      }
    }
    props.onClickPageNo(selectedNo + 1);
    setSelectedNo((prevNo: number) => prevNo + 1);
  };

  const handleDoubleArrowRightClick = () => {
    if (selectedNo + pageLength > lastPageNo) {
      handleLastPageClick();
    } else if (
      Math.floor((selectedNo + pageLength) / pageLength) == Math.floor(lastPageNo / pageLength)
    ) {
      setPageNoList(
        getRange(Math.floor(lastPageNo / pageLength) * pageLength + 1, lastPageNo % pageLength)
      );
      props.onClickPageNo(selectedNo + pageLength);
      setSelectedNo((prevNo) => prevNo + pageLength);
    } else {
      setPageNoList(getRange(pageNoList[0] + pageLength, pageLength));
      props.onClickPageNo(selectedNo + pageLength);
      setSelectedNo((prevNo) => prevNo + pageLength);
    }
  };

  const handleLastPageClick = () => {
    props.onClickPageNo(lastPageNo);
    setSelectedNo(lastPageNo);
    if (lastPageNo > defalutPageLength) {
      setPageNoList(
        getRange(Math.floor(lastPageNo / pageLength) * pageLength + 1, lastPageNo % pageLength)
      );
    }
  };

  useEffect(() => {
    const index = Math.floor((selectedNo - 1) / pageSize);
    const firstNoOfRange = index * pageSize + 1;

    if (lastPageNo < defalutPageLength) {
      setPageLength(lastPageNo);
      setPageNoList(getRange(firstNoOfRange, lastPageNo));
    } else if (firstNoOfRange > defalutPageLength && lastPageNo % defalutPageLength != 0) {
      setPageLength(lastPageNo % defalutPageLength);
      setPageNoList(getRange(firstNoOfRange, lastPageNo % defalutPageLength));
    } else {
      setPageLength(defalutPageLength);
      setPageNoList(getRange(firstNoOfRange, defalutPageLength));
    }
  }, [lastPageNo]);

  useEffect(() => {
    const lastPageNo =
      props.totalCount % pageSize == 0
        ? props.totalCount / pageSize
        : Math.floor(props.totalCount / pageSize) + 1;
    setLastPageNo(lastPageNo);
  }, [props.totalCount]);

  return (
    <Page>
      <Paging>
        {/* <Link to="#" className="arrow" onClick={handleFirstPageClick}>
          <FirstPageIcon></FirstPageIcon>
        </Link> */}
        <Link to="#" className="prevend" onClick={handleDoubleArrowLeftClick}>
          <FirstEndIcon />
        </Link>
        <Link to="#" className="prev" onClick={handleArrowLeftClick}>
          <PrevArrowIcon />
        </Link>
        {pageNoList &&
          pageNoList.map((no) => (
            <Link
              key={no}
              to="#"
              className={no === selectedNo ? 'current num' : 'num'}
              onClick={() => handlePageNoClick(no)}
            >
              {no}
            </Link>
          ))}
        <Link to="#" className="next" onClick={handleArrowRightClick}>
          <NextArrowIcon />
        </Link>
        <Link to="#" className="nextend" onClick={handleDoubleArrowRightClick}>
          <LastEndIcon />
        </Link>
        {/* <Link to="#" className="arrow" onClick={handleLastPageClick}>
          <LastPageIcon></LastPageIcon>
        </Link> */}
      </Paging>
    </Page>
  );
});

const Page = styled.div`
  position: relative;
  // margin-top: 45px;
  // padding-bottom: 50px;
  text-align: center;
  height: 32px;
  margin: 8px 0 4px 0;
`;

const Paging = styled.div`
  display: inline-block;

  a {
    display: inline-block;
    font-size: 12px;
    font-weight: 700;
    height: 28px;
    width: 28px;
    border-radius: 2px;
    line-height: 18px;
    padding: 5px 7px;
    color: ${Color.Grey600};

    &:hover {
      background-color: ${Color.Grey100};
    }
    &:active {
      background-color: #dde0df;
    }

    &.arrow {
      // padding: 5px;
    }

    &.prevend {
      margin-right: 4px;
      padding: 6px;
    }
    &.prevend svg {
      font-size: 16px;
    }

    &.prev {
      margin-right: 3px;
      padding: 6px;
    }
    &.prev svg {
      font-size: 16px;
    }

    &.next {
      margin-left: 3px;
      padding: 6px;
    }
    &.next svg {
      font-size: 16px;
    }
    &.nextend {
      margin-left: 4px;
      padding: 6px;
    }
    &.nextend svg {
      font-size: 16px;
    }

    &.current {
      color: ${Color.White};
      background-color: ${Color.Grey900};
      font-weight: 500;
    }

    &.num {
      margin: 0 1px;
    }
  }

  svg {
    font-size: 20px;
  }
`;

export const GridWrap = styled.div`
  border: 1px solid #dde0df;
`;

export const GridFlexWrap = styled(GridWrap)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
