import { useEffect, useMemo, useState } from 'react';
import { array, bool, boolean, number, object, string } from 'yup';
import { useTranslation } from 'react-i18next';

const useMemberRegist = () => {
  const { t } = useTranslation();

  //   vdcpCd: '', //공급업체코드_로그인ID
  //     dmstFrgDivsCd: 'INTERNAL', //국내해외구분
  //     vdcpMgrNm: '', //이름
  //     soprMgrCopCd: '', //엔솔법인코드
  //     soprMgrNm: '', //엔솔담당자
  //     vdcpNm: '', //업체명_직접입력
  //     vdcpErpNm: '', //업체명_ERP
  //     pwd: '', //패스워드
  //     vdcpMgrPhn: '', //전화번호
  //     vdcpMgrEmal: '', //이메일
  //     idvlIfoClctCncCd: 'N', //개인정보의 수집 및 이용에 대한안내 동의
  //     dlvPayAgmnCncCd: 'N', //납품대금지급에관한약정서 동의
  //     chtrIdnClctCncCd: 'N', //고유식별정보와 수집 및 이용동의
  //     crudKey: CrudCode.CREATE,

  const krRegSchema = useMemo(() => {
    return object({
      vdcpMgrNm: string().required(
        t('com.label.00516', '__회원 가입자 이름은 필수 입력 항목입니다.') as string
      ),
      vdcpCd: string().required(
        t('com.label.00405', '__Vendor ID는 필수 입력 항목입니다.') as string
      ),
      cfrmVdcpCd: boolean().isTrue(
        t('com.label.00559', '__Vendor ID 확인이 필요합니다.') as string
      ),
      vdcpNm: string().required(t('com.label.00518', '__업체명 필수 입력 항목입니다.') as string),
      vdcpMgrPhn: string().required(
        t('com.label.00521', '__전화번호 필수 입력 항목입니다.') as string
      ),
      vdcpMgrEmal: string().required(
        t('com.label.00597', '__이메일 필수 입력 항목입니다.') as string
      ),
      soprMgrCopCd: string().required(
        t('com.label.00507', '__법인코드는 필수 입력 항목입니다.') as string
      ),
      soprMgrNm: string().test(
        'soprMgrNm',
        t('com.label.00508', '__담당자 필수 입력 항목입니다.') as string,
        (value) => {
          console.log('담당자 ::', value);
          if (value) {
            return true;
          } else {
            return false;
          }
        }
      ),
      // 영업부서 담당자 이름, 전화번호, 이메일 필수
      vdcpMgrNm1: string().required(
        t('com.label.00522', '__영업부서 담당자 이름은 필수 입력 항목입니다.') as string
      ),
      vdcpMgrEncPhn: string().required(
        t('com.label.00523', '__영업부서 담당자 전화번호 필수 입력 항목입니다.') as string
      ),
      vdcpMgrEmal1: string().required(
        t('com.label.00524', '__영업부서 담당자 이메일 필수 입력 항목입니다.') as string
      ),

      // 자금부서 담당자 이름, 전화번호, 이메일 필수
      vdcpFundMgrNm: string().required(
        t('com.label.00525', '__자금부서 담당자 이름은 필수 입력 항목입니다.') as string
      ),
      vdcpFundMgrEncPhn: string().required(
        t('com.label.00526', '__자금부서 담당자 전화번호 필수 입력 항목입니다.') as string
      ),
      vdcpFundMgrEmal: string().required(
        t('com.label.00527', '__자금부서 담당자 이메일 필수 입력 항목입니다.') as string
      ),

      // 개인정보 수집 동의
      idvlIfoClctCncCd: string().test(
        'idvlIfoClctCncCd',
        t('com.label.00528', '__개인정보 수집 안내에 동의해주세요.') as string,
        (value, ctx) => {
          if (value == 'N') {
            return false;
          }
          return true;
        }
      ),
      dlvPayAgmnCncCd: string().test(
        'dlvPayAgmnCncCd',
        t('com.label.00529', '__납품대금 지급에 관한 약정서에 동의해주세요.') as string,
        (value, ctx) => {
          if (value == 'N') {
            return false;
          }
          return true;
        }
      ),
      chtrIdnClctCncCd: string().test(
        'chtrIdnClctCncCd',
        t('com.label.00530', '__고유식별정보의 수집 및 이용에 동의해주세요.') as string,
        (value, ctx) => {
          if (value == 'N') {
            return false;
          }
          return true;
        }
      ),
    });
  }, []);

  const ovRegSchema = useMemo(() => {
    return object({
      vdcpMgrNm: string().required(
        t('com.label.00516', '__회원 가입자 이름은 필수 입력 항목입니다.') as string
      ),
      vdcpCd: string().required(
        t('com.label.00405', '__Vendor ID는 필수 입력 항목입니다.') as string
      ),
      cfrmVdcpCd: boolean().isTrue(
        t('com.label.00559', '__Vendor ID 확인이 필요합니다.') as string
      ),
      vdcpNm: string().required(t('com.label.00518', '__업체명 필수 입력 항목입니다.') as string),
      vdcpMgrPhn: string().required(
        t('com.label.00521', '__전화번호 필수 입력 항목입니다.') as string
      ),
      vdcpMgrEmal: string().required(
        t('com.label.00597', '__이메일 필수 입력 항목입니다.') as string
      ),
      pwd: string().required(t('com.label.00519', '__패스워드 필수 입력 항목입니다.') as string),
      pwdValid: boolean().isTrue(
        t(
          'com.label.00662',
          '__패스워드는 최소 10자, 최대 15자로 영문자 및 숫자로 구성되어야 합니다.'
        ) as string
      ),
      cfrmPwd: string().required(
        t('com.label.00520', '__패스워드확인 필수 입력 항목입니다.') as string
      ),
      cfrmPwdAll: boolean().isTrue(
        t('com.label.00656', '__패스워드가 일치하지 않습니다.') as string
      ),
      soprMgrCopCd: string().required(
        t('com.label.00507', '__법인코드는 필수 입력 항목입니다.') as string
      ),
      soprMgrNm: string().test(
        'soprMgrNm',
        t('com.label.00508', '__담당자 필수 입력 항목입니다.') as string,
        (value) => {
          console.log('담당자 ::', value);
          if (value) {
            return true;
          } else {
            return false;
          }
        }
      ),
      // 영업부서 담당자 이름, 전화번호, 이메일 필수
      vdcpMgrNm1: string().required(
        t('com.label.00522', '__영업부서 담당자 이름은 필수 입력 항목입니다.') as string
      ),
      vdcpMgrEncPhn: string().required(
        t('com.label.00523', '__영업부서 담당자 전화번호 필수 입력 항목입니다.') as string
      ),
      vdcpMgrEmal1: string().required(
        t('com.label.00524', '__영업부서 담당자 이메일 필수 입력 항목입니다.') as string
      ),

      // 자금부서 담당자 이름, 전화번호, 이메일 필수
      vdcpFundMgrNm: string().required(
        t('com.label.00525', '__자금부서 담당자 이름은 필수 입력 항목입니다.') as string
      ),
      vdcpFundMgrEncPhn: string().required(
        t('com.label.00526', '__자금부서 담당자 전화번호 필수 입력 항목입니다.') as string
      ),
      vdcpFundMgrEmal: string().required(
        t('com.label.00527', '__자금부서 담당자 이메일 필수 입력 항목입니다.') as string
      ),

      // 개인정보 수집 동의
      idvlIfoClctCncCd: string().test(
        'idvlIfoClctCncCd',
        t('com.label.00528', '__개인정보 수집 안내에 동의해주세요.') as string,
        (value, ctx) => {
          if (value == 'N') {
            return false;
          }
          return true;
        }
      ),
      dlvPayAgmnCncCd: string().test(
        'dlvPayAgmnCncCd',
        t('com.label.00529', '__납품대금 지급에 관한 약정서에 동의해주세요.') as string,
        (value, ctx) => {
          if (value == 'N') {
            return false;
          }
          return true;
        }
      ),
      chtrIdnClctCncCd: string().test(
        'chtrIdnClctCncCd',
        t('com.label.00530', '__고유식별정보의 수집 및 이용에 동의해주세요.') as string,
        (value, ctx) => {
          if (value == 'N') {
            return false;
          }
          return true;
        }
      ),
    });
  }, []);

  return {
    krRegSchema,
    ovRegSchema,
  };
};

export default useMemberRegist;
