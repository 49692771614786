import React, { useState } from 'react';
import styled from '@emotion/styled';
import { GlobalTokenColor } from 'ui/theme/Color';
import bg from 'components/asset/images/backgroundImg.svg';
import GridRadio from 'components/molecule/GridRadio';
import { GButton } from 'components/atom/button';
import file from 'components/asset/images/documentFile.svg';
import LoginInput from 'components/atom/loginInput';
import PassWordInput from 'components/atom/passWordInput';
import GSelectMUIAtom from 'components/atom/select';
import { FormGroup, RadioGroup, SelectChangeEvent } from '@mui/material';
import { GRadioButton } from 'components/atom/radio';

interface Props {
  gap?: string;
}

const Login = () => {
  const [showVerification, setShowVerification] = useState(false);
  const handleConfirmationButtonClick = () => {
    setShowVerification(true);
  };
  const [option, setOption] = useState('1');
  const handleChange = (event: SelectChangeEvent) => {
    setOption(event.target.value);
  };

  return (
    <Wrap>
      <Container>
        <TextBox>
          <p>
            국내외 지급 계좌 관리에
            <br />
            간편하고 효율적인
          </p>
          <EngBox>
            <p>LGES VARS</p>
            <p>Various Account Registration System</p>
          </EngBox>
          <br />
          <p>국내계좌를 VARS로 관리해보세요.</p>
        </TextBox>
        <LoginBox>
          <LogoWrap>
            <Title>VARS</Title>
            <SubTitleWrap>
              <KoSubTitle>지급계좌등록</KoSubTitle>
              <EnSubTitle>Corporate Insurance Management System</EnSubTitle>
            </SubTitleWrap>
          </LogoWrap>
          <FlexBox gap="24px">
            <FormGroup>
              <RadioGroup
                row
                sx={{ gap: '4px' }}
                defaultValue="radio1"
                aria-labelledby="demo-customized-radios"
                name="primary-radios"
              >
                <GRadioButton radioGroup="radioGroup" value="radio1" text="옵션A" />
                <GRadioButton radioGroup="radioGroup" value="radio2" text="옵션B" />
              </RadioGroup>
            </FormGroup>
            <FlexBox gap="16px">
              <GridRadio value="radioGroup2" option={['옵션1', '옵션2']} />
              <LoginInput type="text" label="Vendor Code ID">
                <GButton
                  txt="확인"
                  sizes="medium"
                  btnstyled="outline"
                  btnColor="normal"
                  hasImg={false}
                  hasTxt={true}
                  onClick={handleConfirmationButtonClick}
                  style={{ marginTop: '10px', marginLeft: '10px' }}
                />
              </LoginInput>
              {/* <!-- S : [Dev] 확인 버튼 누를 시 인증번호, password 입력창 나타내기 --><!-- E : [Dev]  --> */}
              {showVerification && (
                <>
                  <LoginInput type="text" label="인증번호" time={true}>
                    <ProoveNum>
                      <span
                        style={{
                          marginTop: '20px',
                          marginRight: '4px',
                          color: '#979998',
                          fontFamily: 'Spoqa Han Sans Neo',
                          fontSize: '13px',
                        }}
                      >
                        00:00
                      </span>
                      <GButton
                        txt="인증"
                        sizes="medium"
                        btnstyled="outline"
                        btnColor="normal"
                        hasImg={false}
                        hasTxt={true}
                        style={{ marginTop: '10px' }}
                      />
                    </ProoveNum>
                  </LoginInput>
                  <PassWordInput type="password" label="password" />
                </>
              )}
            </FlexBox>
            <div style={{ width: '100%', display: 'flex', gap: '8px' }}>
              <GSelectMUIAtom
                selectWidth="166px"
                selectHeight="48px"
                value={option}
                handleChange={handleChange}
                option={['오창 에너지플렌트', '오창 에너지플렌트', '오창 에너지플렌트']}
              />
              <GSelectMUIAtom
                selectWidth="166px"
                selectHeight="48px"
                value={option}
                handleChange={handleChange}
                option={['Korean', 'English', 'Chinese']}
              />
            </div>

            <FlexBox gap="8px">
              <GButton
                txt="로그인"
                sizes="login"
                btnstyled="contained"
                btnColor="normal"
                hasImg={false}
                hasTxt={true}
                disabled
                style={{ height: '54px' }}
              />
              <Linked>
                <GSelectMUIAtom
                  selectWidth="135px"
                  selectHeight="28px"
                  isRequired={true}
                  value={option}
                  handleChange={handleChange}
                  option={['Password 변경', '아이디 변경', '비밀번호 변경']}
                  nonColor={true}
                />
                <a
                  href="#"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    color: '#5B5C5B',
                    fontWeight: '500',
                  }}
                >
                  회원가입
                </a>
              </Linked>
            </FlexBox>
            <hr style={{ border: '1px solid #DDE0DF' }} />
            <FlexBox gap="8px">
              <Button>
                <ButtonText>
                  <img src={file} alt="Document File" />
                  <span>등록 절차 안내 매뉴얼</span>
                </ButtonText>
              </Button>
              <Linked>
                <a href="#" style={{ color: '#979998' }}>
                  개인정보처리방침
                </a>
                <a href="#" style={{ color: '#979998' }}>
                  Help Desk 0000-0000
                </a>
              </Linked>
            </FlexBox>
          </FlexBox>
        </LoginBox>
      </Container>
    </Wrap>
  );
};

const Wrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  background-size: 488px 388px;
  background: ${GlobalTokenColor.ESGrey100} url(${bg}) no-repeat center center;
  justify-content: center;
  min-height: 60vh;
  overflow-y: auto;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 253px;
  align-items: center;
`;

const TextBox = styled.div`
  width: 580px;
  height: 300px;
  margin-left: 280px;
  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    font-family: Spoqa Han Sans Neo;
  }
  p:nth-of-type(1) {
    font-size: 40px;
    font-weight: 400;
    line-height: 60px;
  }
`;

const LoginBox = styled.div`
  width: 468px;
  height: auto;
  background: ${GlobalTokenColor.ESGreyWHite};
  border-radius: 2px;
  padding: 64px;
  img {
    width: 340px;
    height: 68px;
  }
  gap: 32px;
  display: flex;
  flex-direction: column;
`;

const LogoWrap = styled.div`
  width: 340px;
  height: 68px;
  padding: 16px, 0px, 16px, 0px;

  display: flex;
  align-items: center;

  color: #135678;
  gap: 12px;
`;

const Title = styled.span`
  padding-right: 10px;
  border-right: 1px solid #b9bcbb;

  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
`;

const EngBox = styled.div`
  display: flex;
  gap: 15px;
  p:first-child {
    font-size: 40px;
    font-weight: 700;
    line-height: 60px;
  }
  p:last-child {
    font-weight: 400;
    font-size: 20px;
    line-height: 60px;
    color: #5b5c5b;
    margin-top: 6px;
  }
`;

const SubTitleWrap = styled.div`
  max-width: 240px;
  height: 33px;
  padding-left: 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const KoSubTitle = styled.span`
  line-height: 18px;
  display: inline-block;
  font-weight: 400;
  font-size: 14px;
`;

const EnSubTitle = styled(KoSubTitle)`
  color: #6e706f;
  font-size: 12px;
  font-weight: 300;
`;

const Linked = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: Spoqa Han Sans Neo;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
`;

const Button = styled.button`
  display: flex;
  width: 100%;
  height: 36px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
`;

const ButtonText = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;

  span {
    margin-right: 8px;
    color: #5b5c5b;
    font-family: Spoqa Han Sans Neo;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
  }

  img {
    width: 20px;
    height: 20px;
  }
`;

const ProoveNum = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const FlexBox = styled.div<Props>`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${(props) => props.gap && `gap: ${props.gap};`}
`;

export default Login;
