import { GButton } from 'components/atom/button';
import SearchMolecule, {
  GButtonBoxCum,
  SearchItemWrap,
  SearchWrap,
  UnHiddenSearchWrap,
} from 'components/molecule/SearchMolecule';
import searchImage from 'components/asset/images/Search.svg';
import RecycleB from 'components/asset/images/RecycleB.svg';
import GButtonLayout from 'components/molecule/GbuttonLayout';
import { SubTitleBtnArea } from 'components/organism/SubTitleBtnArea';
import SubTitleBox from 'components/molecule/SubTitleBox';
import { GContentGrid } from 'components/layouts/GContentGrid';
import { AgGridReact } from 'ag-grid-react';
import { useEffect, useRef, useState } from 'react';
import { useColumnDefsForApprovalList } from './gridDef/ColumnDefs';
import { useLoading } from 'components/process/Loading';
import { useMessageBar } from 'components/process/MessageBar';
import { SampleResponse } from 'models/sample/Sample';
import { getSamples } from 'apis/sample/SampleApi';

const AdminList = () => {
  const [sampleList, setSampleList] = useState<SampleResponse[]>([]);
  const gridApiRef = useRef<any>(null);
  const { defaultColDef, colDefs } = useColumnDefsForApprovalList();
  const { openLoading } = useLoading();
  const { openMessageBar } = useMessageBar();

  useEffect(() => {
    loadSampleList();
  }, []);

  const makeSearchParams = () => {
    return {
      start: '0',
      pageSize: 10,
    };
  };

  const handleError = (e: Error) => {
    openLoading(false);
    openMessageBar('error', e.message);
  };

  const loadSampleList = async () => {
    getSamples(makeSearchParams())
      .then((data) => {
        setSampleList(data.list);
      })
      .catch(handleError);
  };

  return (
    <>
      <SearchWrap>
        <UnHiddenSearchWrap>
          <SearchItemWrap>
            <SearchMolecule
              labelTitle="처리상태"
              searchWidth="50%"
              searchValueWidth="100px"
              type="select"
              selectOption={['전체', '옵션1']}
              defaultOption="1"
            />
            <SearchMolecule
              labelTitle="엔솔 담당자"
              searchWidth="50%"
              searchValueWidth="300px"
              type="input"
              placeholder="담당자를 입력하세요"
            />
            <SearchMolecule
              labelTitle="업체명(ERP)"
              searchWidth="50%"
              searchValueWidth="300px"
              type="input"
              placeholder="업체명(ERP)를 입력하세요"
            />
            <SearchMolecule
              labelTitle="업체명(거래처)"
              searchWidth="50%"
              searchValueWidth="300px"
              type="input"
              placeholder="업체명(거래처)를 입력하세요"
            />
          </SearchItemWrap>
          <GButtonLayout>
            <GButtonBoxCum>
              <GButton
                chkImg={RecycleB}
                sizes="medium"
                btnstyled="outline"
                btnColor="normal"
                hasImg={true}
                hasTxt={false}
              ></GButton>
              <GButton
                chkImg={searchImage}
                txt="조회"
                sizes="medium"
                btnstyled="contained"
                btnColor="normal"
                hasImg={true}
                hasTxt={true}
              ></GButton>
            </GButtonBoxCum>
          </GButtonLayout>
        </UnHiddenSearchWrap>
      </SearchWrap>

      <SubTitleBtnArea>
        <SubTitleBox
          content="고유식별정보의 수집 및 이용"
          showSubTitle={true}
          isVisibleComment={false}
          isVisibleInfo={false}
        />
      </SubTitleBtnArea>
      <GContentGrid type="none" style={{ height: '74px' }}>
        <AgGridReact
          defaultColDef={defaultColDef}
          columnDefs={colDefs}
          rowData={sampleList}
          rowSelection={'single'}
          enableCellTextSelection
          onGridReady={(params) => (gridApiRef.current = params.api)}
          tooltipShowDelay={0}
        ></AgGridReact>
      </GContentGrid>
    </>
  );
};

export default AdminList;
