import React from 'react';
import styled from '@emotion/styled';

const PrivacyKo = () => {
  return (
    <PrivacyDiv>
      <div className="privacy">
        <div className="title_header">개인정보 처리방침</div>
        <div className="article_header">
          LG에너지솔루션(이하 “회사”)』 은(는) 정보주체의 자유와 권리 보호를 위해 「개인정보
          보호법」 및 관계 법령이 정한 바를 준수하여, 적법하게 개인정보를 처리하고 안전하게 관리하고
          있습니다.
          <br />
          이에 「개인정보 보호법」 제30조에 따라 정보주체에게 개인정보 처리에 관한 절차 및 기준을
          안내하고, 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이
          개인정보 처리방침을 수립·공개합니다.
        </div>
        <div className="box">
          <div className="title">■ 개인정보의 처리목적, 수집 항목, 보유 및 이용기간</div>
          <div className="article">
            회사는 다음과 같이 이용자의 개인정보를 처리하며, 이용자로부터 개인정보를 수집하는 경우
            서비스 이용기간 동안 개인정보를 이용∙보관함을 원칙으로 합니다.
            <br />
            다만, 회사는 관계 법령의 규정 및 회사 내부 방침에 의하여 개인정보를 보존할 필요가 있는
            경우 일정한 기간 동안 해당 개인 정보를 보관합니다.
          </div>
          <table>
            <thead>
              <tr>
                <th>구분</th>
                <th>수집 · 이용 목적</th>
                <th colSpan={2}>수집 · 이용 항목</th>
                <th>보유 · 이용 기간</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>회원 관리</td>
                <td>시스템 사용을 위한 회원 가입 및 탈퇴 처리</td>
                <th>필수</th>
                <td>
                  비밀번호, 주소(법인의 경우 회사 소재지), 법인 소재지 국가
                  <br />
                  <br />□ 담당자에 한함 : 성명, 이메일, 회사명
                </td>
                <td rowSpan={2} style={{ color: 'blue', fontWeight: 'bold' }}>
                  당사와의 계속적인 거래관계 종결시까지 또는 별도의 삭제 요청이 있을시까지
                </td>
              </tr>
              <tr>
                <td>계좌 관리</td>
                <td>계좌 인증</td>
                <th>필수</th>
                <td>개인 Vendor에 한함 : 은행명, 예금주, 계좌번호, 생년월일</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="box">
          <div className="title">■ 개인정보의 제3자 제공</div>
          <div className="article">
            회사는 이용자의 개인정보를 개인정보의 처리 목적에서 명시한 범위 내에서만 처리하며,
            이용자의 동의, 법률의 특별한 규정 등에 해당하는 경우에만 개인정보를 제3자에게 제공하고
            그 이외에는 이용자의 개인정보를 제3자에게 제공하지 않습니다.
          </div>
        </div>
        <div className="box">
          <div className="title">■ 개인정보 처리의 위탁</div>
          <div className="article">
            ① 회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고
            있습니다.
          </div>
          <table>
            <thead>
              <tr>
                <th>위탁 받는 자(수탁자)</th>
                <th>위탁업무</th>
                <th>보유 및 이용기간</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="tl_center">
                  ㈜LG CNS
                  <br />
                  (재수탁사 : 비즈테크아이 )
                </td>
                <td>서비스 운영 , 시스템 운영 , 서비스 홈페이지 운영관리 , 시스템 유지보수 등</td>
                <td>당사와의 계속적인 거래관계 종결시까지 또는 위탁 계약 종료시까지</td>
              </tr>
              <tr>
                <td className="tl_center">
                  총 21 개 은행
                  <br />
                  (우리, 국민, 신한, SC제일, KEB, 하나, 농협 ,한국씨티, 기업, 경남, 대구, 부산,
                  <br />
                  광주, 산업, 수협 ,수출입, 제주, 전북, 신협, 우체국, 카카오, 도이치)
                </td>
                <td>계좌 인증</td>
                <td>당사와의 계속적인 거래관계 종결시까지 또는 위탁 계약 종료시까지</td>
              </tr>
            </tbody>
          </table>
          <div className="article">
            ② 회사는 위탁계약 체결 시 법령에 따라 위탁업무 수행목적 외 개인정보 처리금지,
            기술적·관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리·감독, 손해배상 등 책임에 관한
            사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고
            있습니다.
            <br />
            <br />③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을
            통하여 공개하도록 하겠습니다.
          </div>
        </div>
        <div className="box">
          <div className="title">■ 개인정보의 파기절차 및 파기 방법</div>
          <div className="article">
            회사는 이용자의 개인정보를 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다.
            <br />
            다만, 이용자의 동의를 받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도
            불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를
            별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
            <br />
            <br />
            회사의 개인정보 파기절차 및 방법은 다음과 같습니다.
            <br />
            ① 파기절차
            <br />
            이용자의 개인정보는 사용이 끝나면 바로 삭제됩니다. 위에서 언급한 것과 같이 개인정보를
            보존해야 할 경우에는 별도의 데이터베이스(DB) 또는 다른 장소에 옮겨서 법정 기간 동안
            보관하며, 기간이 지나면 바로 삭제합니다.
            <br />② 파기방법
            <br />
            회사는 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이
            문서에 기록·저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.
          </div>
        </div>
        <div className="box">
          <div className="title">■ 개인정보 자동 수집 장치의 설치, 운영, 거부에 관한 사항</div>
          <div className="article">
            회사는 이용자의 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용하지 않습니다.
          </div>
        </div>
        <div className="box">
          <div className="title">■ 개인정보의 안전성 확보 조치에 관한 사항</div>
          <div className="article">
            회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
            <br />
            1. 관리적 조치 : 내부관리계획 수립·시행, 전담조직 운영, 정기적 직원 교육
            <br />
            2. 기술적 조치 : 개인정보처리시스템 등의 접근 인원 최소화 및 차등적 접근권한 부여,
            접근통제시스템 설치, 주요 개인정보의 암호화, 서버 접근통제 시스템 및 접속기록 위변조
            방지 장치를 운영하여 비인가자의 접근을 차단하고 해킹 등 외부침입에 대응 , 보안프로그램
            설치 및 갱신
            <br />
            3. 물리적 조치 : 전산실, 자료보관실 등의 접근통제
          </div>
        </div>
        <div className="box">
          <div className="title">■ 이용자와 법정대리인의 권리·의무 및 그 행사방법에 관한 사항</div>
          <div className="article">
            이용자 및 법정대리인은 개인정보 처리와 관련하여 이하의 권리를 행사할 수 있습니다.
            <br />
            <br />
            ① 이용자는 회사에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할
            수 있습니다.
            <br />
            ※ 만 14세 미만 아동에 관한 개인정보의 열람 등 요구는 법정대리인이 직접 해야 하며, 만
            14세 이상의 미성년자인 정보주체는 정보주체의 개인정보에 관하여 미성년자 본인이 권리를
            행사하거나 법정대리인을 통하여 권리를 행사할 수도 있습니다.
            <br />
            <br />
            ② 권리 행사는 회사의 개인정보보호책임자 및 담당자에게 서면, 전자우편, 모사전송(FAX) 등을
            통하여 하실 수 있으며, 회사는 이에 대해 지체없이 조치하겠습니다.
            <br />
            <br />
            ③ 권리 행사는 이용자의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수도
            있습니다. 이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른
            위임장을 제출하셔야 합니다.
            <br />
            <br />
            ④ 개인정보 열람 및 처리정지 요구는 법령에 의하여 이용자의 권리가 제한 될 수 있습니다.
            <br />
            <br />
            ⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는
            경우에는 그 삭제를 요구할 수 없으며, 개인정보를 정정∙삭제하는 경우 일부 또는 전부의
            서비스 이용이 불가능할 수 있습니다.
            <br />
            <br />⑥ 회사는 이용자 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람
            등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.
          </div>
        </div>
        <div className="box">
          <div className="title">■ 개인정보 보호책임자 및 개인정보 열람청구</div>
          <div className="article">
            회사는 개인정보를 보호하고 개인정보와 관련된 불편사항을 개선하기 위해 고객지원부서 및
            개인정보 보호책임자를 지정하고 있습니다.
            <br />
            이용자는 법령에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. 회사는
            이용자의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
          </div>
          <div className="flx">
            <table className="flx_30">
              <tr>
                <th colSpan={3}>고객 지원 및 개인정보 열람 청구 부서</th>
              </tr>
              <tr>
                <th rowSpan={2}>(주)엘지에너지솔루션</th>
                <th>고객 지원 부서</th>
                <td>자금팀</td>
              </tr>
              <tr>
                <th>메일</th>
                <td>vars_hq@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>LG Energy Solution Australia Pty Ltd</th>
                <th>고객 지원 부서</th>
                <td>Admin Part</td>
              </tr>
              <tr>
                <th>메일</th>
                <td>vars_au@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>NEXTSTAR ENERGY INC.</th>
                <th>고객 지원 부서</th>
                <td>Finance Team</td>
              </tr>
              <tr>
                <th>메일</th>
                <td>vars_st@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>LG Energy Solution (Nanjing) Co., Ltd.</th>
                <th>고객 지원 부서</th>
                <td>LGESNJ.China Manage.SSC banking T.fund P</td>
              </tr>
              <tr>
                <th>메일</th>
                <td>vars_nj_na@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>LG Energy Solution Technology (Nanjing) Co., Ltd.</th>
                <th>고객 지원 부서</th>
                <td>LGESNJ.China Manage.SSC banking T.fund P</td>
              </tr>
              <tr>
                <th>메일</th>
                <td>vars_nb@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>LG Energy Solution Battery (Nanjing) Co., Ltd.</th>
                <th>고객 지원 부서</th>
                <td>LGESNJ.China Manage.SSC banking T.fund P</td>
              </tr>
              <tr>
                <th>메일</th>
                <td>vars_nj_na@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>LG Energy Solution Europe GmbH</th>
                <th>고객 지원 부서</th>
                <td>Business Management Team</td>
              </tr>
              <tr>
                <th>메일</th>
                <td>vars_eg@lgensol.com</td>
              </tr>
              <tr>
                {/* <th rowSpan={2}>PT. HKML Battery Indonesia</th> */}
                <th rowSpan={2}>PT HLI Green Power</th>
                <th>고객 지원 부서</th>
                <td>Treasury Team</td>
              </tr>
              <tr>
                <th>메일</th>
                <td>vars_hm@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>LG ENERGY SOLUTION INDIA PRIVATE LIMITED</th>
                <th>고객 지원 부서</th>
                <td>Account Team</td>
              </tr>
              <tr>
                <th>메일</th>
                <td>vars_il@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>LG Energy Solution Japan Co., Ltd.</th>
                <th>고객 지원 부서</th>
                <td>Management Part</td>
              </tr>
              <tr>
                <th>메일</th>
                <td>vars_jp@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>LG Energy Solution Wroclaw sp. z o.o.</th>
                <th>고객 지원 부서</th>
                <td>Biz Mgt.Finance&Accounting Team</td>
              </tr>
              <tr>
                <th>메일</th>
                <td>vars_wa@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>LG Energy Solution (Taiwan), Ltd</th>
                <th>고객 지원 부서</th>
                <td>Business Administration Part</td>
              </tr>
              <tr>
                <th>메일</th>
                <td>vars_tw@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>Ultium holdings LLC</th>
                <th>고객 지원 부서</th>
                <td>Treasury Team</td>
              </tr>
              <tr>
                <th>메일</th>
                <td>vars_gm@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>HL-GA Battery Company LLC</th>
                <th>고객 지원 부서</th>
                <td>Treasury Team</td>
              </tr>
              <tr>
                <th>메일</th>
                <td>vars_hg@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>Ultium Cells LLC</th>
                <th>고객 지원 부서</th>
                <td>Treasury Team</td>
              </tr>
              <tr>
                <th>메일</th>
                <td>vars_gm@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>LG ENERGY SOLUTION ARIZONA ESS, Inc.</th>
                <th>고객 지원 부서</th>
                <td>Treasury Team</td>
              </tr>
              <tr>
                <th>메일</th>
                <td>vars_ae@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>LG Energy Solution Michigan, Inc.</th>
                <th>고객 지원 부서</th>
                <td>Admin Team.Treasury P</td>
              </tr>
              <tr>
                <th>메일</th>
                <td>vars_mi@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>LG ENERGY SOLUTION ARIZONA, INC.</th>
                <th>고객 지원 부서</th>
                <td>Treasury Team</td>
              </tr>
              <tr>
                <th>메일</th>
                <td>vars_az@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>LG Energy Solution Vertech, Inc</th>
                <th>고객 지원 부서</th>
                <td>Finance & Accounting</td>
              </tr>
              <tr>
                <th>메일</th>
                <td>vars_vt@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>L-H Battery Company, Incorporated</th>
                <th>고객 지원 부서</th>
                <td>Finance Team</td>
              </tr>
              <tr>
                <th>메일</th>
                <td>vars_hd@lgensol.com</td>
              </tr>
            </table>
            <table style={{ height: '80px' }}>
              <tr>
                <th colSpan={2}>개인정보 보호책임자 및 개인정보 보호 담당부서</th>
              </tr>
              <tr>
                <th>개인정보 보호책임자</th>
                <td className="tl_center">이종국</td>
              </tr>
              <tr>
                <th>개인정보 보호 담당부서</th>
                <td className="tl_center">보안전략팀</td>
              </tr>
              <tr>
                <th>전화</th>
                <td className="tl_center">02-3773-3340</td>
              </tr>
              <tr>
                <th>메일</th>
                <td className="tl_center">privacy.es@lgensol.com</td>
              </tr>
            </table>
          </div>
        </div>
        <div className="box">
          <div className="title">■ 권익침해 구제방법</div>
          <div className="article">
            이용자는 아래의 기관에 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다.
            <br />
            아래의 기관은 회사와는 별개의 기관으로서, 회사의 자체적인 개인정보 불만처리, 피해구제
            결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다.
            <br />
            - 개인정보분쟁조정위원회 (http://www.privacy.go.kr / 국번없이 1833-6972)
            <br />
            - 개인정보침해신고센터 (http://privacy.kisa.or.kr / 국번없이 118)
            <br />
            - 대검찰청 사이버수사과 (http://www.spo.go.kr / 국번없이 1301)
            <br /> - 경찰청 사이버수사국 (https://ecrm.cyber.go.kr / 국번없이 182)
          </div>
        </div>
        <div className="box">
          <div className="title">■ 개인정보처리방침 변경에 관한 사항</div>
          <div className="article">
            회사는 관련 법령, 회사 내부정책 또는 보안 기술의 변경에 따라 본 개인정보 처리방침의
            추가∙삭제 또는 수정이 있을 경우 아래와 같은 방법으로 사전 공지하도록 하겠습니다.
            <br />
            - 인터넷 홈페이지 및 모바일 어플리케이션 공지사항 란 또는 별도의 창을 통하여 수정내용
            공지
            <br />
            - 이메일 또는 모바일 어플리케이션 PUSH 메시지를 통하여 수정내용 공지
            <br />
            <br />본 개인정보 처리방침은 2024년 4월 15일부터 적용됩니다.
          </div>
        </div>
      </div>
    </PrivacyDiv>
  );
};

export default PrivacyKo;

const PrivacyDiv = styled.div`
  body {
    box-sizing: border-box;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  /* privacy */
  .privacy {
    box-sizing: border-box;
    margin: 0;
    font-family: 'Spoqa Han Sans Neo', '맑은 고딕', 'malgun gothic', AppleGothicNeoSD,
      'Apple SD 산돌고딕 Neo', 'Microsoft NeoGothic', 'Droid sans', sans-serif;
    font-size: 13px;
    color: #1f1f1f;
    padding: 20px;
    line-height: 20px;
  }

  .privacy .tl_center {
    text-align: center;
  }
  .privacy .tl_left {
    text-align: left;
  }
  .privacy .tl_right {
    text-align: right;
  }

  .privacy .title_header {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    padding: 10px;
  }

  .privacy .article_header {
    padding: 10px;
  }

  .privacy .box {
    padding: 10px;
  }

  .privacy .box .title {
    font-size: 14px;
    font-weight: 600;
    padding: 10px 0;
  }

  .privacy .box .article {
    font-size: 13px;
    font-weight: 400;
  }

  .privacy .box table {
    border-collapse: collapse;
    margin: 10px 0px;
  }

  .privacy .box table th,
  .privacy .box table td {
    border: 1px solid #dde0df;
    padding: 5px 10px;
  }

  .privacy .box table th {
    background: #f1f4f3;
    font-weight: 600;
  }

  .privacy .box table td {
    vertical-align: top;
  }

  .privacy .flx {
    display: flex;
    justify-content: space-around;
    gap: 20px;
  }

  /* privacy_pop */
  .privacy_pop {
    box-sizing: border-box;
    margin: 0;
    font-family: 'Spoqa Han Sans Neo', '맑은 고딕', 'malgun gothic', AppleGothicNeoSD,
      'Apple SD 산돌고딕 Neo', 'Microsoft NeoGothic', 'Droid sans', sans-serif;
    font-size: 13px;
    color: #1f1f1f;
    padding: 20px;
    line-height: 20px;
  }
  .privacy_pop .title_header {
    font-size: 16px;
    font-weight: 600;
    padding: 10px;
  }

  .privacy_pop .article_header {
    padding: 10px;
  }

  .privacy_pop .scrollbox {
    height: 500px;
    border: 1px solid #dde0df;
    overflow-y: scroll;
  }

  .privacy_pop .tl_right {
    padding: 10px;
    text-align: right;
  }

  .privacy_pop .input {
    vertical-align: bottom;
  }
`;
