import { useMemo, useState } from 'react';
import { array, bool, boolean, number, object, string } from 'yup';
import { useTranslation } from 'react-i18next';

const useLogin = () => {
  const { t } = useTranslation();

  //   userId: string;
  //   password?: string;
  //   publCertKey?: string;
  //   emlAddr?: string;
  //   langCd: string;
  //   emlAuthCd?: string;
  //   signUpYn?: string;
  //   sapValidYn?: string;

  const vendorIdSchema = useMemo(() => {
    return object({
      userId: string().required(t('com.label.00654', '__Vendor ID를 입력해주세요.') as string),
      emlAuthCd: string().required(t('com.label.00655', '__인증번호를 입력해주세요.') as string),
    });
  }, [t]);

  const loginSchema = useMemo(() => {
    return object({
      userId: string().required(t('com.label.00654', '__Vendor ID를 입력해주세요.') as string),
      emlAuthCd: string().required(t('com.label.00655', '__인증번호를 입력해주세요.') as string),
      //   cfrmVdcpCd: boolean()
      //     .isTrue(t('com.label.00425', '__VendorId 확인이 필요합니다.') as string)
      //     .required(
      //       t(
      //         'com.label.00405',
      //         '__Vendor ID는 필수 입력 항목입니다..'
      //       ) as string
      //     ),
    });
  }, [t]);

  return {
    vendorIdSchema,
    loginSchema,
  };
};

export default useLogin;
