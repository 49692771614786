import SearchMolecule, {
  GButtonBoxCum,
  SearchItemWrap,
  SearchWrap,
  UnHiddenSearchWrap,
} from 'components/molecule/SearchMolecule';
import { GButton } from 'components/atom/button';
import SubTitleBox from 'components/molecule/SubTitleBox';
import searchImage from 'components/asset/images/Search.svg';
import { useEffect, useRef, useState } from 'react';
import { SampleResponse } from 'models/sample/Sample';
import RecycleB from 'components/asset/images/RecycleB.svg';
import { useColumnDefsRegistrationDefs } from './gridDef/RegistrationDefs';
import { useLoading } from 'components/process/Loading';
import { useMessageBar } from 'components/process/MessageBar';
import { getSamples } from 'apis/sample/SampleApi';
import { SubTitleBtnArea } from 'components/organism/SubTitleBtnArea';
import { GContentGrid } from 'components/layouts/GContentGrid';
import { AgGridReact } from 'ag-grid-react';
import GButtonLayout from 'components/molecule/GbuttonLayout';

const Abnormal = () => {
  // get grid data, defaultColDef
  const [sampleList, setSampleList] = useState<SampleResponse[]>([]);
  const gridApiRef = useRef<any>(null);
  const { defaultColDef, colDefs } = useColumnDefsRegistrationDefs();

  const { openLoading } = useLoading();
  const { openMessageBar } = useMessageBar();

  useEffect(() => {
    loadSampleList();
  }, []);

  const makeSearchParams = () => {
    return {
      start: '0',
      pageSize: 10,
    };
  };

  const handleError = (e: Error) => {
    openLoading(false);
    openMessageBar('error', e.message);
  };

  const loadSampleList = async () => {
    getSamples(makeSearchParams())
      .then((data) => {
        setSampleList(data.list);
      })
      .catch(handleError);
  };
  return (
    <>
      <SearchWrap>
        <UnHiddenSearchWrap>
          <SearchItemWrap>
            <SearchMolecule
              type="calendar"
              searchWidth="100%"
              labelTitle="조회기간"
              calendarDouble={true}
              isRequired={true}
              calendarFormat="YYYY.MM.DD"
              calendarViews={['year', 'month', 'day']}
              calendarWidth="128px"
            />
          </SearchItemWrap>
          <GButtonLayout marginBottom="0px" paddingTop="0px">
            <GButtonBoxCum>
              <GButton
                chkImg={RecycleB}
                sizes="medium"
                btnstyled="outline"
                btnColor="normal"
                hasImg={true}
                hasTxt={false}
              ></GButton>
              <GButton
                chkImg={searchImage}
                txt="조회"
                sizes="medium"
                btnstyled="contained"
                btnColor="normal"
                hasImg={true}
                hasTxt={true}
              ></GButton>
            </GButtonBoxCum>
          </GButtonLayout>
        </UnHiddenSearchWrap>
      </SearchWrap>
      <SubTitleBtnArea>
        <SubTitleBox showSubTitle={true} content="역외계좌(구매처소재국가와계좌개설국가상이)" />
      </SubTitleBtnArea>
      <GContentGrid
        type="none"
        className="ag-theme-alpine"
        style={{ flex: 1, marginBottom: '8px' }}
        isSortable={false}
      >
        <AgGridReact
          defaultColDef={defaultColDef}
          columnDefs={colDefs}
          rowData={sampleList}
          rowSelection={'single'}
          enableCellTextSelection
          onGridReady={(params) => (gridApiRef.current = params.api)}
          tooltipShowDelay={0}
        ></AgGridReact>
      </GContentGrid>
    </>
  );
};
export default Abnormal;
