import { GlobalTokenColor } from 'ui/theme/Color';

import GridEmpty from 'components/asset/EmptyGrid.svg';
import DataorMenuEmpty from 'components/asset/DataorMenuEmpty.svg';

// empty text
import { useTranslation } from 'react-i18next';

// grid : 조회 가능한 데이터가 없습니다.
// menu : 메뉴를 선택하세요.
// data : 데이터를 선택하세요.
// role : 역할을 선택하세요
// msg : 메시지코드나 메시지를 입력하여 조회하세요.
interface emptyTypeProp {
  type?: 'grid' | 'menu' | 'data' | 'role' | 'msg';
}

const EmptyState = (props: emptyTypeProp) => {
  const { t } = useTranslation();
  let emptyText = '';
  let emptyImg = DataorMenuEmpty;
  let emptyColor = GlobalTokenColor.ESGreyWHite;

  switch (props.type) {
    case 'grid':
      emptyImg = GridEmpty;
      emptyColor = GlobalTokenColor.ESGrey50;
      emptyText = t('com.msg.noData', '조회 가능한 데이터가 없습니다.');
      break;
    case 'data':
      emptyText = t('com.msg.choData', '데이터를 선택하세요.');
      break;
    case 'menu':
      emptyText = t('com.msg.choMnu', '메뉴를 선택하세요.');
      break;
    case 'role':
      emptyText = t('com.msg.choRole', '역할을 선택하세요.');
      break;
    case 'msg':
      emptyText = t('com.msg.insMsgCdMsgVw', '메시지코드나 메시지를 입력하여 조회하세요.');
      break;
  }

  return (
    <span
      className={props.type}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        paddingTop: '65px',
        backgroundColor: `${emptyColor}`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: `center calc(50% - 8px)`,
        backgroundImage: `url(${emptyImg})`,
        color: `${GlobalTokenColor.ESGrey500}`,
        fontSize: '15px',
        fontWeight: '500',
        textAlign: 'center',
      }}
    >
      {emptyText}
    </span>
  );
};

export default EmptyState;
