import { FormGroup } from '@mui/material';
import { CheckboxGroup } from 'components/atom/checkbox';

interface Props {
  option: string[];
  value: string;
  height?: string;
}

const GridCheckbox = (props: Props) => {
  return (
    <FormGroup row sx={{ gap: '4px', alignItems: 'center', minHeight: `${props.height}` }}>
      {props.option.map((it, index) => {
        return <CheckboxGroup key={index} text={it} value={props.value + index} />;
      })}
    </FormGroup>
  );
};

export default GridCheckbox;
