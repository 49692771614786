import styled from '@emotion/styled';
import { BasicColor, BorderColor, FontColor } from 'ui/theme/Color';
import { TextSize, TextWeight } from 'ui/theme/CustomTypography';
import { GlobalGap } from 'ui/theme/Gap';

export const ContentSection = styled.div`
  /* SerachBox의 Wrap에서 margin-bottom: 14를 줘서 겹침 */
  margin-top: ${GlobalGap.Px12};
  line-height: 1.2;

  &.selfCenter {
    align-self: center;

    .buttons {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;

      button + button {
        margin-top: 10px;
      }
    }
  }

  &.marginT0 {
    margin-top: 0 !important;
  }

  &.marginT35 {
    margin-top: 35px !important;
  }

  &.marginT38 {
    margin-top: 38px !important;
  }

  .noGridTop {
    margin-top: 53px;
  }

  &.noSearchBox {
    margin-top: 84px;
  }

  label {
    position: relative;
    padding-left: 5px;

    .required {
      position: absolute;
      top: -2px;
      left: 0;
      width: 4px;
      height: 4px;
      line-height: 4px;
      color: ${FontColor.Primary};

      &:before {
        content: '';
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 2px;
        background-color: ${BasicColor.Red};
      }
    }
  }

  a {
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
  .link {
    color: ${BasicColor.Secondary};
    &:hover {
      text-decoration: underline;
    }

    svg {
      vertical-align: middle;
    }

    & + button {
      margin-left: 10px;
    }
  }

  .marginT10 {
    margin-top: 10px;
  }
`;

export const SectionTop = styled.div`
  display: flex;
  align-items: flex-end;
  padding-bottom: 15px;
  border-bottom: 1px solid ${BorderColor.Secondary};
  margin-bottom: 15px;
  height: 38px;
  font-size: 24px;
  font-weight: 700;
  justify-content: space-between;

  &.contentEnd {
    justify-content: flex-end;
  }
`;

//TODO : 삭제
export const GridTop = styled.div`
  margin-bottom: 15px;
  height: 38px;
  &.contentBetween {
    justify-content: space-between;
  }

  &.contentEnd {
    justify-content: end;
  }
`;

export const GridInfoSection = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  div + & {
    margin-top: 10px;
  }

  &.contentEnd {
    justify-content: flex-end;
  }

  &.alignFlexStart {
    align-items: flex-start;
  }

  &.marginT20 {
    margin-top: 20px;
  }

  &.minHeight28 {
    min-height: 28px;
  }
  &.minHeight15 {
    min-height: 15px;
  }
  &.minHeight36 {
    min-height: 36px;
  }

  &.IconButton {
    align-items: center;
  }
`;

export const GridInfo = styled.div`
  height: 28px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;

  > span {
    margin-right: 2px;
    &.primary {
      color: ${FontColor.Primary};
    }
    > &:nth-child(1) {
      font-weight: ${TextWeight.Bold};
      font-size: ${TextSize.H3};
      margin-right: 8px;
    }
    > &:nth-child(2) {
      font-weight: ${TextWeight.Normal};
      font-size: ${TextSize.H6};
    }
    > &:nth-child(3) {
      font-weight: ${TextWeight.Normal};
      font-size: ${TextSize.H6};
      color: ${FontColor.Primary};
    }
    > &:nth-child(n + 4) {
      font-size: ${TextSize.H6};
      color: ${FontColor.Gray200};
    }
  }
`;
export const GridButtons = styled.div`
  display: flex;
  align-items: end;
`;

export const SelRows = styled.div`
  display: inline-block;
  margin-left: 5px;
  select {
    width: 60px;
    height: 28px;
    min-width: auto;
  }
  span {
    color: ${FontColor.Gray400};
    font-size: 13px;
  }
`;

export const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    flex: 0 0 auto;
  }

  & + & {
    margin-top: 5px;
  }
  height: 28px;
`;
