import { useCommonModal } from 'hooks/useCommonModal';
import { useEffect, useRef, useState, useCallback } from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { GButtonBox, GPopupTitle } from 'components/layouts/GLayoutBox';
import { DialogTitleItem } from 'components/layouts/GModalPopup';
import { useTranslation } from 'react-i18next';
import { GButton } from 'components/atom/button';
import Save from 'components/asset/images/Confirm.svg';
import ModalLayout from 'components/layouts/ModalLayout';
import { GbasicTable, GbasicTableWrap } from 'components/tables/GTable';
import Tag from 'components/atom/tag';
import { GLabelAtom } from 'components/atom/label';
import { SubTitleBtnArea } from 'components/organism/SubTitleBtnArea';
import SubTitleBox from 'components/molecule/SubTitleBox';
import styled from '@emotion/styled';
import InputField from 'components/atom/input';
import { CommonCode } from 'models/admin/CommonCode';
import GSelectMUIAtom from 'components/common/select/GSelectMUIAtom';
import BankNumberModal from 'pages/vars/accountregist/modal/BankNumberModal';
import FileDownloadVars from 'components/vars/common/FileDownloadVars';
import FileUploadVars from 'components/vars/common/FileUploadVars';
import { useAccountRegist } from '../hooks/useAccountRegist';
import { DomesitcAccountVO } from 'models/vars/account/DomesitcAccountVO';
import { getJwtCommonCodes } from 'apis/vars/common/Common';
import ChipInputBox from 'components/common/input/ChipInputBoxCopy';
import { FileSaveResponse, FileSaveResult } from 'models/admin/FileInfo';
import useDmsRegist from './hooks/useDmsRegist';
import { useLoading } from 'components/process/Loading';
import { useMessageBar } from 'components/process/MessageBar';
import { getDomesticAccount, modifyDomesticAccount } from 'apis/vars/account/DomesticAccount';
import { AccountValidVO } from 'models/vars/account/AccountValidVO';
import { getAccnValid0237, getAccnValidInfo } from 'apis/vars/account/CommonAccount';
import { GLabelAtomVars } from 'components/vars/common/GLabelAtomVars';

type DmsChgKrwAcntModalProps = {
  open: boolean;
  close: (param?: boolean) => void;
  save: (param?: boolean) => void;
  vendorInfo?: any;
  domAccount?: any;
  stdAccnCds?: any;
  node: any;
};

interface AccnChgVO extends DomesitcAccountVO {
  currCd?: string; //미사용
}

interface AccnFiles {
  fileId1?: string;
  fileId2?: string;
  fileId3?: string;
  fileId4?: string;
}

const DmsChgKrwAcntModal = (props: DmsChgKrwAcntModalProps) => {
  const [bankNumberModalOpen, setBankNumberModalOpen] = useState<boolean>(false);
  const [accnTpCdList, setAccnTpCdList] = useState<CommonCode[]>([]);
  const { t } = useTranslation();
  const t2 = t as (msgId: string, defVal: string) => string;
  const { getTagStatus } = useAccountRegist({});
  const { openCommonModal } = useCommonModal();
  const { openMessageBar } = useMessageBar();

  const [dtlDomAccount, setDtlDomAccount] = useState<DomesitcAccountVO>();

  const { openLoading } = useLoading();
  const { regSchema, fileSchema } = useDmsRegist();
  const files = {} as AccnFiles;

  const [chgAccnInfo, setChgAccnInfo] = useState<AccnChgVO>({
    ...dtlDomAccount,
    // vdcpCd: props.domAccount.vdcpCd,
    // userId: props.domAccount.vdcpCd,
    // ...props.domAccount,
    accnRegDivsCd: props.domAccount.accnRegDivsCd,
    //aprRqrEmpNo: 'FP501318',
    accnTpCd: props.domAccount.accnTpCd,
    accnNoCertYn: 'N',
    addAtrValCtn06: 'N',
  } as AccnChgVO);

  const [certFlag, setCertFlag] = useState<boolean>(false);

  const fileDropzoneRef1 = useRef<any>();
  const fileDropzoneRef2 = useRef<any>();
  const fileDropzoneRef3 = useRef<any>();
  const fileDropzoneRef4 = useRef<any>();

  const [chipClick, setChipClick] = useState(false);

  const onSearchClick = () => {
    setChipClick((pre) => !pre);
    setBankNumberModalOpen(true);
  };

  const handleClose = (result?: boolean) => {
    props.close(result);
  };

  const handleValidAccnClick = async () => {
    if (!chgAccnInfo.bankCd) {
      openCommonModal({
        modalType: 'alert',
        content: t('ccom.label.00538', '__은행을 선택하세요.'),
      });
      return;
    }

    if (!chgAccnInfo.encAccnNo) {
      openCommonModal({
        modalType: 'alert',
        content: t('com.label.00539', '__계좌번호를 입력하세요.'),
      });
      return;
    }

    // if (!chgAccnInfo.dpstNm) {
    //   alert(t('com.label.00540', '__예금주를 입력하세요.'));
    //   return;
    // }

    const accnValidParam = {
      ZBNKY: chgAccnInfo.bankCd,
      ZBNKN: chgAccnInfo.encAccnNo,
      ZNME1: chgAccnInfo.dpstNm ?? '',
      WAERS: 'KRW',
    } as AccountValidVO;

    openLoading(true);
    await getAccnValidInfo(accnValidParam)
      .then((resp) => {
        openLoading(false);
        if (resp.result == 'S') {
          setChgAccnInfo((prev) => {
            return {
              ...prev,
              addAtrValCtn06: 'Y', //예금주 확인 여부
              addAtrValCtn08: resp.result, //예금주 인증처리 결과코드
              addAtrValCtn09: resp.e_ZNME1, //예금주 인증처리 결과내용
              dpstNm: resp.e_ZNME1,
            };
          });
          setCertFlag(true);
          openMessageBar('success', t('com.label.00040', '__인증에 성공하였습니다.'));
        } else {
          const appEnv = `${process.env.REACT_APP_NODE_ENV}`;

          if (appEnv != 'prd') {
            //운영 제외하고 패스
            setChgAccnInfo((prev) => {
              return {
                ...prev,
                // accnNoCertYn: 'Y',
                addAtrValCtn06: 'Y', //예금주 확인 여부
                addAtrValCtn08: resp.result, //예금주 인증처리 결과코드
                addAtrValCtn09: resp.e_ZNME1 ?? '', //예금주 인증처리 결과내용
                dpstNm: 'tester',
              };
            });
            openMessageBar('success', t('com.label.00040', '__인증에 성공하였습니다.'));
          } else {
            openCommonModal({
              modalType: 'alert',
              content: resp.error_INFO,
            });
            return;
          }
        }
      })
      .catch((e) => {
        console.error('error', e);
        openLoading(false);
      });
  };

  /**
   *  동일 은행 중복계좌 번호 체크 로직
   */
  const validDuplAccn = () => {
    const accounts = props.node.parent.allLeafChildren.filter(
      (o) => o.rowIndex != props.node.rowIndex
    );

    const duplAccn = accounts.filter(
      (o) =>
        o.data.encAccnNo == chgAccnInfo.encAccnNo?.trim() &&
        o.data.bankCd == chgAccnInfo.bankCd &&
        o.data.accnTpCd == chgAccnInfo.accnTpCd
    );

    if (duplAccn.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleOnSave = () => {
    if (validDuplAccn()) {
      openCommonModal({
        modalType: 'alert',
        content: t('com.label.00542', '__중복된 계좌를 등록할 수 없습니다.'),
      });
      return;
    }

    let fileRefArr;
    let fileNameArr;
    if (chgAccnInfo.accnTpCd == 'G') {
      fileRefArr = [fileDropzoneRef1, fileDropzoneRef2, fileDropzoneRef3];
      fileNameArr = [
        t('com.label.00072', '__사업자등록증'),
        t('com.code.ATCH_FILE_KRW_CD.10', '__통장사본'),
        t('com.label.00074', '__어음약정확인서'),
      ];
    } else {
      fileRefArr = [fileDropzoneRef1, fileDropzoneRef2];
      fileNameArr = [
        t('com.label.00072', '__사업자등록증'),
        t('com.code.ATCH_FILE_KRW_CD.10', '__통장사본'),
      ];
    }

    if (!validFiles(fileRefArr, fileNameArr)) {
      return;
    }

    // if (!validInput(chgAccnInfo)) return;
    // if (chgAccnInfo.accnNoCertYn != 'Y') {
    //   openCommonModal({
    //     modalType: 'alert',
    //     content: '계좌를 인증해주세요',
    //   });
    //   return;
    // }

    //TODO 예금주 인증
    if (chgAccnInfo.addAtrValCtn06 != 'Y') {
      openCommonModal({
        modalType: 'alert',
        content: t('com.label.00727', '__계좌를 인증해주세요'),
      });
      return;
    }

    regSchema
      .validate(chgAccnInfo)
      .then(async () => {
        // 2차 검수 피드백 : 저장노티 띄우지 않고 바로 '저장되었습니다' 메세지 노출
        // 수정자 : 양지원
        try {
          openLoading(true);

          // 0327 수정 상태가 신규인 경우 대응
          const resultValid = await getAccnValid0237({
            I_REQ_DIVS: 'KRW', // 원화 외화 구분
            I_LIFNR: props.vendorInfo.vdcpCd, //구매처코드
            I_ACCT_TYPE: chgAccnInfo.accnTpCd, //계좌구분
            I_MODE:
              chgAccnInfo.accnRegDivsCd == '2'
                ? 'A'
                : dtlDomAccount?.addAtrValCtn03 == 'N'
                ? 'N'
                : 'U', //변경유형 0318 복수인경우에 A로 변경 // addAtrValCtn03 == N 이면 N
            I_BANKN_N: chgAccnInfo.encAccnNo, //신규_은행계좌번호
            I_BKONT_N: chgAccnInfo.bankCd, //신규_은행코드(원화)
            I_BANKL_O:
              dtlDomAccount?.addAtrValCtn03 == 'N'
                ? ''
                : dtlDomAccount?.accnRegProgStatCd == 'C'
                ? dtlDomAccount?.bankKeyCd
                : dtlDomAccount?.bfBankKeyCd ?? dtlDomAccount?.bankKeyCd ?? '', //기존_은행 키	FOR KRW(수정일떄만 필수)
            I_BANKN_O:
              dtlDomAccount?.addAtrValCtn03 == 'N'
                ? ''
                : dtlDomAccount?.accnRegProgStatCd == 'C'
                ? dtlDomAccount?.encAccnNo
                : dtlDomAccount?.bfEncAccnNo ?? dtlDomAccount?.encAccnNo ?? '', //기존_은행계좌번호	FOR KRW(수정일떄만 필수)
            I_BKONT_O:
              dtlDomAccount?.addAtrValCtn03 == 'N'
                ? ''
                : dtlDomAccount?.accnRegProgStatCd == 'C'
                ? dtlDomAccount?.bankCd
                : dtlDomAccount?.bfBankCd ?? dtlDomAccount?.bankCd ?? '', //	기존_은행코드(원화)	KRW(수정일떄만 필수)
          });

          if (resultValid.stat == 'S') {
            setChgAccnInfo((prev) => {
              return {
                ...prev,
                accnNoCertYn: 'Y', // 계좌인증여부
              };
            });
            setCertFlag(true);
          } else {
            openCommonModal({
              modalType: 'alert',
              content: resultValid.msg,
            });
            return;
          }
          await getAccountFileIds();
          await modifyDomesticAccount(
            { ...chgAccnInfo, accnNoCertYn: 'Y' },
            files,
            dtlDomAccount
          ).then((resp) => {
            if (resp.successOrNot === 'Y') {
              openMessageBar('success', t('com.msg.save', '__저장되었습니다.'));
              props.save(true);
            }
          });
        } catch (e) {
          console.log('e', e);
          return;
        } finally {
          openLoading(false);
        }
      })
      .catch((e) => {
        console.log('error : ', e);
        openCommonModal({
          modalType: 'alert',
          content: e.message,
        });
        return;
      });
  };

  /**
   * 첨부파일 필수여부 체크
   */
  const validFiles = (arrRef, arrMsg) => {
    let flag = true;
    for (let index = 0; index < arrRef.length; index++) {
      const o = arrRef[index];
      if (!o.current.getFiles().filter((o) => o.useYn == 'Y').length) {
        flag = false;
        openCommonModal({
          modalType: 'alert',
          content: `${arrMsg[index]} ${t('com.label.00545', '__첨부파일을 추가하세요')}`,
        });
        break;
      }
    }
    return flag;
  };

  const validInput = async (regAccnInfo) => {
    let infoFlag = false;
    regSchema
      .validate(regAccnInfo)
      .then(() => {
        infoFlag = true;
        return infoFlag;
      })
      .catch((e) => {
        console.log('error : ', e);
        openCommonModal({
          modalType: 'alert',
          content: e.message,
        });
        return infoFlag;
      });
  };

  const getFileId = useCallback(async (dropzoneRef) => {
    const fileSaveResponse: FileSaveResponse = await dropzoneRef.current.saveFiles();
    return fileSaveResponse.fileSaveResult == FileSaveResult.FAIL
      ? null
      : fileSaveResponse.atchFileGrId;
  }, []);

  const getAccountFileIds = async () => {
    if (fileDropzoneRef1.current.getFiles().length) {
      await getFileId(fileDropzoneRef1).then((respFileId) => {
        if (respFileId) {
          files.fileId1 = respFileId;
          setChgAccnInfo((prev) => {
            return { ...prev, fileId1: respFileId };
          });
        }
      });
    }
    if (fileDropzoneRef2.current.getFiles().length) {
      await getFileId(fileDropzoneRef2).then((respFileId) => {
        if (respFileId) {
          files.fileId2 = respFileId;
          setChgAccnInfo((prev) => {
            return { ...prev, fileId2: respFileId };
          });
        }
      });
    }
    if (fileDropzoneRef3.current?.getFiles().length) {
      await getFileId(fileDropzoneRef3).then((respFileId) => {
        if (respFileId) {
          files.fileId3 = respFileId;
          setChgAccnInfo((prev) => {
            return { ...prev, fileId3: respFileId };
          });
        }
      });
    }
    if (fileDropzoneRef4.current.getFiles().length) {
      await getFileId(fileDropzoneRef4).then((respFileId) => {
        if (respFileId) {
          files.fileId4 = respFileId;
          setChgAccnInfo((prev) => {
            return { ...prev, fileId4: respFileId };
          });
        }
      });
    }
  };

  /**
   * 인풋 컴포넌트 체인지 이벤트
   * @param e
   */
  const handleChangeForChgAccn = (e) => {
    if (e.target.id == 'encAccnNo') {
      e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, '', '');
      setChgAccnInfo((prev) => {
        return { ...prev, addAtrValCtn06: 'N', accnNoCertYn: 'N' };
      });
      setCertFlag(false);
    }

    setChgAccnInfo((prev) => {
      return { ...prev, [e.target.id]: e.target.value };
    });
  };

  useEffect(() => {
    (async () => {
      await getJwtCommonCodes('ACCN_TP_CD').then((resp) => {
        setAccnTpCdList(resp);
      });
      if (props.domAccount.accnRegReqId) {
        getDomesticAccount(props.domAccount.vdcpCd, props.domAccount.accnRegReqId).then((resp) => {
          if (resp) {
            setDtlDomAccount(resp);
            if (resp.accnRegProgStatCd == 'C') {
              setChgAccnInfo({
                accnRegDivsCd: resp.accnRegDivsCd,
                accnTpCd: resp.accnTpCd,
                vdcpCd: resp.vdcpCd,
                addAtrValCtn03: resp.addAtrValCtn03,
                bankAccnId: resp.bankAccnId,
                bfBankKeyCd: resp.bfBankKeyCd,
                bfBankCd: resp.bfBankCd,
                bfBankNm: resp.bfBankNm,
                bfEncAccnNo: resp.bfEncAccnNo,
                bfDpstNm: resp.bfDpstNm,
                bfAccnUsegNm: resp.bfAccnUsegNm,
                bfBankAccnId: resp.bfBankAccnId,
                addAtrValCtn06: 'N',
                accnNoCertYn: 'N',
              });
            } else {
              setChgAccnInfo({ ...resp, addAtrValCtn06: 'N', accnNoCertYn: 'N' });
            }
          }
        });
      } else {
        setDtlDomAccount({
          ...props.domAccount,
          bfBankNm: props.domAccount.bankNm,
          bfEncAccnNo: props.domAccount.encAccnNo,
          bfDpstNm: props.domAccount.dpstNm,
          bfAccnUsegNm: props.domAccount.accnUsegNm,
          bfBankCd: props.domAccount.bankCd,
          // aprRqrEmpNo: 'FP501318',
        });
        if (props.domAccount.accnRegProgStatCd == 'C') {
          setChgAccnInfo({
            accnRegDivsCd: props.domAccount.accnRegDivsCd,
            accnTpCd: props.domAccount.accnTpCd,
            vdcpCd: props.domAccount.vdcpCd,
            addAtrValCtn03: props.domAccount.addAtrValCtn03,
            bankAccnId: props.domAccount.bankAccnId,
            bfBankNm: props.domAccount.bankNm,
            bfEncAccnNo: props.domAccount.encAccnNo,
            bfDpstNm: props.domAccount.dpstNm,
            bfAccnUsegNm: props.domAccount.accnUsegNm,
            bfBankKeyCd: props.domAccount.bankKeyCd,
            bfBankCd: props.domAccount.bankCd,
            addAtrValCtn06: 'N',
            accnNoCertYn: 'N',
          });
        } else {
          setChgAccnInfo({
            ...props.domAccount,
            bfBankNm: props.domAccount.bankNm,
            bfEncAccnNo: props.domAccount.encAccnNo,
            bfDpstNm: props.domAccount.dpstNm,
            bfAccnUsegNm: props.domAccount.accnUsegNm,
            bfBankKeyCd: props.domAccount.bankKeyCd,
            bfBankCd: props.domAccount.bankCd,
            addAtrValCtn06: 'N',
            accnNoCertYn: 'N',
          });
        }
      }
    })();
  }, []);

  useEffect(() => {
    setChgAccnInfo((prev) => {
      return {
        ...prev,
        accnNoCertYn: 'Y', // 계좌인증여부
      };
    });
  }, [certFlag]);

  const getLeftTitle = () => {
    const stcd = props.domAccount.accnRegProgStatCd;
    let result;

    switch (stcd) {
      case 'C':
        result = t('com.code.preAccnDtls', '__기등록계좌상세정보');
        break;
      case 'T':
        result = t('com.code.tempStgAccnDtls', '__임시저장계좌 상세정보');
        break;
      case 'R':
        result = t('com.code.rjctAccnDtls', '__반려계좌 상세정보');
        break;
    }

    return result;
  };

  return (
    <>
      <ModalLayout open={props.open} onClose={handleClose} mMinWidth={1600} mMaxWidth={1600}>
        <DialogTitleItem id="customized-dialog-title" onClose={handleClose}>
          <GPopupTitle title2={t('com.label.00546', '__계좌정보상세')} />
        </DialogTitleItem>
        <DialogContent className="popupContent">
          <AreaAlignWrap>
            <div>
              <SubTitleBtnArea>
                <SubTitleBox showSubTitle={true} content={getLeftTitle()} />
              </SubTitleBtnArea>
              <GbasicTableWrap>
                <GbasicTable>
                  <colgroup>
                    <col style={{ width: '120px' }} />
                    <col style={{ width: 'auto' }} />
                    <col style={{ width: '120px' }} />
                    <col style={{ width: 'auto' }} />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>
                        <GLabelAtom align="left">{t('com.label.00063', '__계좌종류')}</GLabelAtom>
                      </th>
                      <td>{dtlDomAccount?.accnTpCdNm}</td>
                      <th>
                        <GLabelAtom align="left">{t('com.label.00064', '__진행상태')}</GLabelAtom>
                      </th>
                      <td>
                        <Tag
                          content={dtlDomAccount?.accnRegProgStatCdNm}
                          status={getTagStatus(dtlDomAccount?.accnRegProgStatCd ?? '')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <GLabelAtom align="left">{t('com.label.00065', '__은행')}</GLabelAtom>
                      </th>
                      <td colSpan={3}>
                        {dtlDomAccount?.accnRegProgStatCd == 'R' ||
                        dtlDomAccount?.accnRegProgStatCd == 'C'
                          ? dtlDomAccount?.bankNm
                          : dtlDomAccount?.bfBankNm ?? dtlDomAccount?.bankNm}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <GLabelAtom align="left">{t('com.label.00067', '__예금주')}</GLabelAtom>
                      </th>
                      <td>
                        {dtlDomAccount?.accnRegProgStatCd == 'R' ||
                        dtlDomAccount?.accnRegProgStatCd == 'C'
                          ? dtlDomAccount?.dpstNm
                          : dtlDomAccount?.bfDpstNm ?? dtlDomAccount?.dpstNm}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <GLabelAtom align="left">{t('com.label.00070', '__계좌번호')}</GLabelAtom>
                      </th>
                      <td>
                        {dtlDomAccount?.accnRegProgStatCd == 'R' ||
                        dtlDomAccount?.accnRegProgStatCd == 'C'
                          ? dtlDomAccount?.encAccnNo
                          : dtlDomAccount?.bfEncAccnNo ?? dtlDomAccount?.encAccnNo}
                      </td>
                      <th>
                        <GLabelAtom align="left">{t('com.label.00076', '__통화')}</GLabelAtom>
                      </th>
                      <td>KRW</td>
                    </tr>
                    <tr>
                      <th>
                        <GLabelAtom align="left">{t('com.label.00071', '__계좌용도')}</GLabelAtom>
                      </th>
                      <td colSpan={3}>
                        {dtlDomAccount?.accnRegProgStatCd == 'R' ||
                        dtlDomAccount?.accnRegProgStatCd == 'C'
                          ? dtlDomAccount?.accnUsegNm
                          : dtlDomAccount?.bfAccnUsegNm ?? dtlDomAccount?.accnUsegNm}
                      </td>
                    </tr>

                    {dtlDomAccount?.isErpOnly == 'N' && (
                      <>
                        <tr>
                          <th>
                            <GLabelAtom align="left">
                              {t('com.label.00072', '__사업자등록증')}
                            </GLabelAtom>
                          </th>
                          <td colSpan={3} style={{ verticalAlign: 'top' }}>
                            <FileDownloadVars atchFileGrId={dtlDomAccount?.fileId1 ?? ''} />
                          </td>
                        </tr>
                        <tr>
                          <th>
                            <GLabelAtom align="left">
                              {t('com.code.ATCH_FILE_KRW_CD.10', '__통장사본')}
                            </GLabelAtom>
                          </th>
                          <td colSpan={3} style={{ verticalAlign: 'top' }}>
                            <FileDownloadVars atchFileGrId={dtlDomAccount.fileId2 ?? ''} />
                          </td>
                        </tr>
                        {dtlDomAccount.accnTpCd == 'G' && (
                          <tr>
                            <th>
                              <GLabelAtom align="left">
                                {t('com.label.00074', '__어음약정확인서')}
                              </GLabelAtom>
                            </th>
                            <td colSpan={3} style={{ verticalAlign: 'top' }}>
                              <FileDownloadVars atchFileGrId={dtlDomAccount.fileId3 ?? ''} />
                            </td>
                          </tr>
                        )}
                        <tr>
                          <th>
                            <GLabelAtom align="left">{t('com.label.00075', '__기타')}</GLabelAtom>
                          </th>
                          <td colSpan={3}>
                            <FileDownloadVars atchFileGrId={dtlDomAccount.fileId4 ?? ''} />
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </GbasicTable>
              </GbasicTableWrap>
            </div>
            <div>
              <SubTitleBtnArea>
                <SubTitleBox
                  showSubTitle={true}
                  content={t('com.label.00547', '__신규계좌상세정보')}
                />
              </SubTitleBtnArea>
              <GbasicTableWrap>
                <GbasicTable>
                  <colgroup>
                    <col style={{ width: '120px' }} />
                    <col style={{ width: 'auto' }} />
                    <col style={{ width: '120px' }} />
                    <col style={{ width: 'auto' }} />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>
                        <GLabelAtom align="left" isRequired>
                          {t('com.label.00063', '__계좌종류')}
                        </GLabelAtom>
                      </th>
                      <td>
                        <GSelectMUIAtom
                          selectWidth="100%"
                          value={chgAccnInfo.accnTpCd}
                          handleChange={(e) => {
                            setChgAccnInfo((prev) => {
                              return {
                                ...prev,
                                accnTpCd: e.target.value,
                                addAtrValCtn06: 'N',
                                accnNoCertYn: 'N',
                              };
                            });
                            setCertFlag(false);
                          }}
                          readOnly={props.domAccount.accnTpCd ? true : false}
                          codeKey="cmnCd"
                          codeName="cmnCdNm"
                          option={accnTpCdList}
                        />
                      </td>
                      <th>
                        <GLabelAtom align="left">{t('com.label.00064', '__진행상태')}</GLabelAtom>
                      </th>
                      <td></td>
                    </tr>
                    <tr>
                      <th>
                        <GLabelAtom align="left" isRequired={true}>
                          {t('com.label.00065', '__은행')}
                        </GLabelAtom>
                      </th>
                      <td colSpan={3}>
                        <ChipInputBox
                          width="100%"
                          status="default"
                          type="singleChip"
                          value={chgAccnInfo.bankNm}
                          setValue={(value) => {
                            if (!value) {
                              setChgAccnInfo((prev) => {
                                return { ...prev, bankCd: '', bankNm: '' };
                              });
                            }
                            setCertFlag(false);
                          }}
                          onClickFunction={onSearchClick}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <GLabelAtom align="left" isRequired>
                          {t('com.label.00067', '__예금주')}
                        </GLabelAtom>
                      </th>
                      <td>
                        <InputField
                          id="dpstNm"
                          status="readonly"
                          readOnly={true}
                          align="left"
                          width="100%"
                          maxLength={60}
                          // placeholder={t2('com.label.00068', '__입력하세요')}
                          value={chgAccnInfo.dpstNm}
                          onChange={handleChangeForChgAccn}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <GLabelAtom align="left" isRequired>
                          {t('com.label.00070', '__계좌번호')}
                        </GLabelAtom>
                      </th>
                      <td colSpan={3}>
                        <ItemAlignWrap>
                          <InputField
                            id="encAccnNo"
                            status="default"
                            align="left"
                            width="240px"
                            value={chgAccnInfo.encAccnNo}
                            placeholder={t2('com.label.00377', `__'-'없이 입력하세요`)}
                            onChange={handleChangeForChgAccn}
                            maxLength={100}
                          />
                          <GButton
                            txt={t(`com.label.00548`, `__인증요청`)}
                            sizes="medium"
                            btnColor="normal"
                            btnstyled="outline"
                            hasImg={false}
                            hasTxt={true}
                            onClick={() => {
                              handleValidAccnClick();
                            }}
                          />
                        </ItemAlignWrap>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <GLabelAtom align="left" isRequired={true}>
                          {t('com.label.00071', '__계좌용도')}
                        </GLabelAtom>
                      </th>
                      <td colSpan={3}>
                        <InputField
                          id="accnUsegNm"
                          status="default"
                          align="left"
                          width="100%"
                          value={chgAccnInfo.accnUsegNm}
                          // placeholder={t2('com.label.00068', '__입력하세요')}
                          onChange={handleChangeForChgAccn}
                          maxLength={25}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <GLabelAtomVars
                          align="left"
                          isRequired
                          isInfo={true}
                          pTxt={
                            t(
                              'com.tooltip.accn.reg01',
                              `__사업자인 경우 현재 유효한 사업자등록증을 첨부해주세요\r\n일반 개인인 경우 신분증을 첨부해주세요(주민등록번호 뒷자리 마스킹) `
                            ) ?? ''
                          }
                          pPlace="3"
                        >
                          {t('com.label.00072', '__사업자등록증')}
                        </GLabelAtomVars>
                      </th>
                      <td colSpan={3}>
                        <FileUploadVars
                          ref={fileDropzoneRef1}
                          atchFileGrIdInput={dtlDomAccount?.fileId1 ?? ''}
                          isMultipleFile={false}
                          maxCount={1}
                          allowFileTypes={['jpg', 'png', 'pdf']}
                          height="40px"
                          width="100%"
                          getSelectedFile={(value) => {
                            if (value.length == 0) {
                              files.fileId1 = '';
                            }
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <GLabelAtomVars
                          align="left"
                          isRequired
                          isInfo={true}
                          pTxt={
                            t(
                              'com.tooltip.accn.reg02',
                              `__사업용 계좌를 첨부해주세요\r\n- 계좌주명이 '대표자'일 경우, 대표자명+(상호명) 이거나 사업용 통장이어야 합니다`
                            ) ?? ''
                          }
                          pPlace="3"
                        >
                          {t('com.code.ATCH_FILE_KRW_CD.10', '__통장사본')}
                        </GLabelAtomVars>
                      </th>
                      <td colSpan={3}>
                        <FileUploadVars
                          ref={fileDropzoneRef2}
                          atchFileGrIdInput={dtlDomAccount?.fileId2 ?? ''}
                          isMultipleFile={false}
                          maxCount={1}
                          allowFileTypes={['jpg', 'png', 'pdf']}
                          height="40px"
                          width="100%"
                          getSelectedFile={(value) => {
                            if (value.length == 0) {
                              files.fileId2 = '';
                            }
                          }}
                        />
                      </td>
                    </tr>
                    {chgAccnInfo.accnTpCd == 'G' && (
                      <tr>
                        <th>
                          <GLabelAtomVars
                            align="left"
                            isRequired
                            isInfo={true}
                            pTxt={
                              t(
                                'com.tooltip.accn.reg03',
                                `__은행을 통해 전자어음약정을 체결한 후 어음약정 확인서를 첨부해주세요\r\n- 하나은행 어음 약정 시, 금융거래 정보제공 동의서(금융거래 정보제공 동의 업무 상세 조회)를 필수서류로 제출하여야 합니다`
                              ) ?? ''
                            }
                            pPlace="3"
                          >
                            {t('com.label.00074', '__어음약정확인서')}
                          </GLabelAtomVars>
                        </th>
                        <td colSpan={3}>
                          <FileUploadVars
                            ref={fileDropzoneRef3}
                            atchFileGrIdInput={dtlDomAccount?.fileId3 ?? ''}
                            isMultipleFile={true}
                            maxCount={2}
                            allowFileTypes={['jpg', 'png', 'pdf']}
                            height="40px"
                            width="100%"
                            getSelectedFile={(value) => {
                              if (value.length == 0) {
                                files.fileId3 = '';
                              }
                            }}
                          />
                        </td>
                      </tr>
                    )}

                    <tr>
                      <th>
                        <GLabelAtomVars align="left">
                          {t('com.label.00075', '__기타')}
                        </GLabelAtomVars>
                      </th>
                      <td colSpan={3}>
                        <FileUploadVars
                          ref={fileDropzoneRef4}
                          atchFileGrIdInput={dtlDomAccount?.fileId4 ?? ''}
                          isMultipleFile={true}
                          maxCount={5}
                          allowFileTypes={['jpg', 'png', 'xlsx', 'pdf']}
                          height="40px"
                          width="100%"
                          getSelectedFile={(value) => {
                            if (value.length == 0) {
                              files.fileId4 = '';
                            }
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </GbasicTable>
              </GbasicTableWrap>
            </div>
          </AreaAlignWrap>
        </DialogContent>
        <DialogActions className="popupBottom">
          <GButtonBox>
            <GButton
              txt={t('com.label.00036', '취소')}
              sizes="medium"
              onClick={handleClose}
              btnColor="normal"
              btnstyled="outline"
              hasImg={false}
              hasTxt={true}
            />
            <GButton
              txt={t('com.label.00055', '저장')}
              chkImg={Save}
              sizes="medium"
              onClick={handleOnSave}
              btnstyled="contained"
              btnColor="primary"
              hasImg={true}
              hasTxt={true}
            ></GButton>
          </GButtonBox>
        </DialogActions>
      </ModalLayout>
      <BankNumberModal
        open={bankNumberModalOpen}
        close={() => setBankNumberModalOpen(false)}
        save={(data) => {
          setChgAccnInfo((prev) => {
            return {
              ...prev,
              bankCd: data.bankCode,
              bankNm: data.bankName,
              bankCtryCd: data.bankCtryCd,
              addAtrValCtn06: 'N',
              accnNoCertYn: 'N',
            };
          });
          setCertFlag(false);
          // regAccnInfo.bankCd = data.bankCode;
        }}
        vendorInfo={props.vendorInfo}
        accnTpCd={chgAccnInfo.accnTpCd}
      />
    </>
  );
};

const AreaAlignWrap = styled.div`
  width: 100%;
  display: flex;
  gap: 24px;
`;

const ItemAlignWrap = styled.div`
  display: flex;
  gap: 4px;
`;

export default DmsChgKrwAcntModal;
