import styled from '@emotion/styled';
import { ButtonProps } from '@mui/material/Button/Button';
import { Button } from '@mui/material';
import {
  GlobalTokenColor,
  ButtonContainedStyle,
  ButtonOutlinedStyle,
  FontColor,
} from 'ui/theme/Color';
import ActiveImg from 'components/asset/images/ActiveConfirmB.svg';

interface GButtonProps extends ButtonProps {
  chkImg?: string;
  txt?: string | null;
  hasImg?: boolean;
  hasTxt?: boolean;
  sizes?: string;
  btnstyled?: string;
  btnColor?: string;
  imgWidth?: string;
  imgHeight?: string;
  imgloc?: 'right' | 'left';
  onClick?: (e) => void;
}

const getButtonStyles = (props: GButtonProps) => {
  if (props.btnstyled === 'contained') {
    if (props.btnColor === 'primary') {
      return `
        background-color: ${ButtonContainedStyle.PrimaryDefault};
        color: ${FontColor.White};
        border: none;

        &:hover {
          background-color: ${ButtonContainedStyle.PrimaryHover};
        }

        &:active {
          background-color: ${ButtonContainedStyle.PrimaryActive};
        }

        &:disabled {
          background-color: ${ButtonContainedStyle.PrimaryDisabled};
          color: ${FontColor.White};
        }
      `;
    } else if (props.btnColor === 'normal') {
      return `
        background-color: ${ButtonContainedStyle.NormalDefault};
        color: ${FontColor.White};
        border: none;

        &:hover {
          background-color: ${ButtonContainedStyle.NormalHover};
        }

        &:active {
          background-color: ${ButtonContainedStyle.NormalActive};
        }
        &:disabled {
          background-color: ${ButtonContainedStyle.NormalDisabled};
          color: ${GlobalTokenColor.ESGreyWHite};
        }
      `;
    } else if (props.btnColor === 'none') {
      return `
        background-color: transparent;
        border: none;
        color: ${GlobalTokenColor.Primary600};
        &:hover {
          background-color: ${GlobalTokenColor.ESGrey100};
        }
        &:active {
          background-color: ${GlobalTokenColor.ESGrey300};
        }
      `;
    }
  } else if (props.btnstyled === 'outline') {
    if (props.btnColor === 'primary') {
      return `
        background-color: ${ButtonOutlinedStyle.PrimaryDefault};
        border: 1px solid ${GlobalTokenColor.Primary300};
        color: ${GlobalTokenColor.Primary600};

        &:hover {
          background-color: ${ButtonOutlinedStyle.PrimaryHover};
          color: ${GlobalTokenColor.Primary600};
          border: 1px solid ${GlobalTokenColor.Primary500};
        }

        &:active {
          background-color: ${ButtonOutlinedStyle.PrimaryActive};
          border: 1px solid ${GlobalTokenColor.Primary600};
          color: ${GlobalTokenColor.Primary900};
          ${props.hasImg ? `& img { content: url(${ActiveImg}); }` : ''}
        }

        &:disabled {
          background-color: ${ButtonOutlinedStyle.PrimaryDisabled};
          color: ${GlobalTokenColor.Primary600};
          opacity: 0.4;
        }
      `;
    } else if (props.btnColor === 'normal') {
      return `
        background-color: ${ButtonOutlinedStyle.NormalDefault};
        border: 1px solid ${GlobalTokenColor.ESGrey300};
        color: ${GlobalTokenColor.ESGrey700};

        &:hover {
          background-color: ${ButtonOutlinedStyle.NormalHover};
          color: ${GlobalTokenColor.ESGrey700};
          border: 1px solid ${GlobalTokenColor.ESGrey500};
        }

        &:active {
          background-color: ${ButtonOutlinedStyle.NormalActive};
          border: 1px solid ${GlobalTokenColor.ESGrey600};
          color: ${GlobalTokenColor.ESGrey900};
        }

        &:disabled {
          background-color: ${ButtonOutlinedStyle.NormalDisabled};
          opacity: 0.4;
        }
      `;
    } else if (props.btnColor === 'none') {
      return `
        background-color:transparent;
        border: none;
        color: ${GlobalTokenColor.ESGrey700};
        &:hover {
          background-color:transparent;
        }
        &:active {
          background-color:transparent;
        }
      `;
    }
  } else if (props.btnstyled === 'unfilled') {
    if (props.btnColor === 'primary') {
      return `
        background-color: ${ButtonOutlinedStyle.NormalDefault};
        border: none;
        color: ${GlobalTokenColor.Primary600};
  
        &:hover {
          background-color: ${GlobalTokenColor.Primary50};
          color: ${GlobalTokenColor.Primary600};
        }
  
        &:active {
          background-color: ${GlobalTokenColor.Primary100};
          color: ${GlobalTokenColor.Primary900};
        }
  
        &:disabled {
          background-color: ${GlobalTokenColor.Primary};
          opacity: 0.4;
        }
      `;
    } else if (props.btnColor === 'normal') {
      return `
        background-color: ${ButtonOutlinedStyle.NormalDefault};
        border: none;
        color: ${GlobalTokenColor.ESGrey700};
  
        &:hover {
          background-color: ${ButtonOutlinedStyle.NormalHover};
          color: ${GlobalTokenColor.ESGrey700};
        }
  
        &:active {
          background-color: ${ButtonOutlinedStyle.NormalActive};
          color: ${GlobalTokenColor.ESGrey900};
        }
  
        &:disabled {
          background-color: ${ButtonOutlinedStyle.NormalDisabled};
          opacity: 0.4;
        }
      `;
    }
  }

  return ''; // 기본 스타일은 빈 문자열로 지정
};

const GButtonStyled = styled(Button)<GButtonProps>`
  min-width: auto;
  border-radius: 3px;
  ${(props) => getButtonStyles(props)}

  ${(props) =>
    props.hasImg === true &&
    props.hasTxt === false &&
    props.sizes === 'large' &&
    `
      padding: 8px !important;
    `}

  // sizes에 따른 스타일
  ${(props) =>
    props.sizes === 'large' &&
    `
      padding: 0 12px;
      height: 36px;
      border-radius: 3px;
      span {
        font-weight: 400;
        line-height: 150%;
        text-transform: none;
        font-size: 13px;
        padding: 0 4px;
      };
    `}

  ${(props) =>
    props.sizes === 'medium' &&
    `
      padding: 6px;
      height: 32px;
      border-radius: 2px;
      span {
        font-weight: 400;
        line-height: 150%;
        text-transform: none;
        font-size: 13px;
        text-wrap: nowrap;
        white-space:nowrap; //Edge 에서 text-wrap 적용안됨. 0307
        padding: 0 2px;
      };
      `}

${(props) =>
    props.sizes === 'small' &&
    `
    padding: 4px 6px;
      height: 28px;
      border-radius: 2px;
      span {
        font-size: 12px;
        font-weight: 400;
        line-height: 150%;
        text-transform: none;
        text-wrap: nowrap;
        padding: 0 2px;
      };
    `}

    ${(props) =>
    props.sizes === 'xs' &&
    `
      padding: 0 4px;
      height: 20px;
      border-radius: 2px;
      span {
        font-size: 12px;
        font-weight: 700;
        line-height: 18px;
        text-transform: none;
        text-wrap: nowrap;
        padding: 0 2px;
      };
    `}
    ${(props) =>
    props.sizes === 'none' &&
    `
    padding: 0;
      span {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        text-transform: none;
        text-wrap: nowrap;
      };
    `}
    ${(props) =>
    props.sizes === 'popup' &&
    `
      padding: 8px 12px;
        span {
          font-size: 13px;
          font-weight: 700;
          line-height: 19.5px;
          text-transform: none;
          text-wrap: nowrap;
          padding: 0 4px;
        };
      `} 
    ${(props) =>
    props.sizes === 'login' &&
    `
      padding: 0;
        span {
          font-size: 18px;
          font-weight: 400;
          line-height: 25px;
          text-transform: none;
          text-wrap: nowrap;
        };
      `} 
      /* E : [Dev] */
  
  ${(props) =>
    (props.sizes === 'large' || props.sizes === 'medium') &&
    `
      img {
        width: ${props.imgWidth || '20px'};
        height: ${props.imgHeight || '20px'};
      }
    `}

  ${(props) =>
    props.sizes === 'small' &&
    `
      img {
        width: ${props.imgWidth || '16px'};
        height: ${props.imgHeight || '16px'};
        padding: 2 0 px;
      }
    `}

  &:active {
    svg {
      fill: currentColor;
    }
  }

  ${(props) =>
    props.imgloc === 'right' &&
    `
      flex-direction: row;
    `}

  img {
    order: ${(props) => (props.imgloc === 'right' ? 1 : -1)};
  }
`;

export const GButton = (props: GButtonProps) => {
  return (
    <GButtonStyled {...props} onClick={props.onClick} disableRipple>
      {props.hasImg && <img src={props.chkImg} />}
      {props.hasTxt && <Span>{props.txt}</Span>}
    </GButtonStyled>
  );
};
const Span = styled.span`
  padding: 0 2px;
`;
