/* eslint-disable prettier/prettier */
import React from 'react';
import styled from '@emotion/styled';

const HelpDeskContent = () => (
  <HelpDeskWrap>
    <div className="help">
      <div className="comment_kor">
        <a
          className="alink"
          href="https://raadmin.crosscert.com/customer/lgensol_vars/index.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          ※공동인증서 발급 사이트 이동
        </a>
        <br />
        <br />
        문의사항은 하기 법인별 Help Desk를 통해 연락 부탁드립니다.
        <br />
        업체 ID와 캡처한 화면을 제공해주시면, 보다 정확한 확인이 가능합니다.
      </div>
      <div className="comment_en">
        If you have any questions, please contact us through the Help Desk by corporations below.
        <br />
        When sending an email, providing your vendor ID and a screenshot of the issue will allow for
        accurate verification.
      </div>
      <table>
        <colgroup>
          <col width="10%" />
          <col width="60%" />
          <col width="30%" />
        </colgroup>
        <thead>
          <tr>
            <th>No.</th>
            <th>법인명 (Name of corporation)</th>
            <th>이메일 (E-mail)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>LG Energy Solution</td>
            <td>vars_hq@lgensol.com</td>
          </tr>
          <tr>
            <td>2</td>
            <td>LG Energy Solution Australia Pty Ltd</td>
            <td>vars_au@lgensol.com</td>
          </tr>
          <tr>
            <td>3</td>
            <td>NEXTSTAR ENERGY INC.</td>
            <td>vars_st@lgensol.com</td>
          </tr>
          <tr>
            <td>4</td>
            <td>LG Energy Solution (Nanjing) Co., Ltd.</td>
            <td>vars_nj_na@lgensol.com</td>
          </tr>
          <tr>
            <td>5</td>
            <td>LG Energy Solution Technology (Nanjing) Co., Ltd.</td>
            <td>vars_nb@lgensol.com</td>
          </tr>
          <tr>
            <td>6</td>
            <td>LG Energy Solution Battery (Nanjing) Co., Ltd.</td>
            <td>vars_nj_na@lgensol.com</td>
          </tr>
          <tr>
            <td>7</td>
            <td>LG Energy Solution Europe GmbH</td>
            <td>vars_eg@lgensol.com</td>
          </tr>
          <tr>
            <td>8</td>
            {/* <td>PT. HKML Battery Indonesia</td> */}
            <td>PT HLI Green Power</td>
            <td>vars_hm@lgensol.com</td>
          </tr>
          <tr>
            <td>9</td>
            <td>LG ENERGY SOLUTION INDIA PRIVATE LIMITED</td>
            <td>vars_il@lgensol.com</td>
          </tr>
          <tr>
            <td>10</td>
            <td>LG Energy Solution Japan Co., Ltd.</td>
            <td>vars_jp@lgensol.com</td>
          </tr>
          <tr>
            <td>11</td>
            <td>LG Energy Solution Wroclaw sp. z o.o.</td>
            <td>vars_wa@lgensol.com</td>
          </tr>
          <tr>
            <td>12</td>
            <td>LG Energy Solution (Taiwan), Ltd</td>
            <td>vars_tw@lgensol.com</td>
          </tr>
          <tr>
            <td>13</td>
            <td>Ultium holdings LLC</td>
            <td>vars_gm@lgensol.com</td>
          </tr>
          <tr>
            <td>14</td>
            <td>HL-GA Battery Company LLC</td>
            <td>vars_hg@lgensol.com</td>
          </tr>
          <tr>
            <td>15</td>
            <td>Ultium Cells LLC</td>
            <td>vars_gm@lgensol.com</td>
          </tr>
          <tr>
            <td>16</td>
            <td>LG ENERGY SOLUTION ARIZONA ESS, Inc.</td>
            <td>vars_ae@lgensol.com</td>
          </tr>
          <tr>
            <td>17</td>
            <td>LG Energy Solution Michigan, Inc.</td>
            <td>vars_mi@lgensol.com</td>
          </tr>
          <tr>
            <td>18</td>
            <td>LG ENERGY SOLUTION ARIZONA, INC.</td>
            <td>vars_az@lgensol.com</td>
          </tr>
          <tr>
            <td>19</td>
            <td>LG Energy Solution Vertech, Inc</td>
            <td>vars_vt@lgensol.com</td>
          </tr>
          <tr>
            <td>20</td>
            <td>L-H Battery Company, Incorporated</td>
            <td>vars_hd@lgensol.com</td>
          </tr>
        </tbody>
      </table>
    </div>
  </HelpDeskWrap>
);

const HelpDeskWrap = styled.div`
  .help {
    font-size: 13px;
  }

  .help .comment_kor,
  .help .comment_en {
    padding: 5px;
  }

  .help table {
    border-collapse: collapse;
    margin: 10px 0px;
    width: 100%;
  }

  .help table th,
  .help table td {
    border: 1px solid #dde0df;
    padding: 5px 10px;
  }

  .help table th {
    background: #f1f4f3;
    font-weight: 600;
  }

  .alink {
    color: #3296ff;
  }
`;

export default HelpDeskContent;
