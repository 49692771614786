import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { DefaultCellTooltip } from 'components/grids/tooltip/DefaultCellTooltip';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FileCellRenderer, IButtonCellRendererParams } from 'components/grids/FileCellRenderer';
import File from 'components/asset/FileUploader.png';
import ProfileImg from 'components/asset/images/userIcon.svg';
import checkIcon from 'components/asset/UnChecked.svg';
import useAuthStore from 'stores/useAuthStore';
import EditProfile from 'components/vars/qna/ProfileQna';
import useSessionStore from 'stores/useSessionStore';

export const useQnaGridColumnDef = () => {
  const { t } = useTranslation();
  const { accessToken } = useAuthStore();
  const { roleCodes } = useSessionStore();

  const defaultColDef = useMemo((): ColDef => {
    return {
      sortable: false,
      resizable: true,
      cellStyle: { textAlign: 'center' },
      tooltipComponent: DefaultCellTooltip,
    };
  }, []);

  const titleValueFormatter = (params) => {
    const replyCnt = params.data.replyCnt || 0;
    if (replyCnt > 0) {
      const replyStyle = {
        color: '#554596',
      };
      return (
        <>
          {params.data.bbmTitNm}
          {'\u00A0\u00A0'}
          <span style={replyStyle}>[{params.data.replyCnt}]</span>
        </>
      );
    } else {
      return <>{params.data.bbmTitNm}</>;
    }
  };

  const colDefs: any = [
    {
      headerName: 'check',
      headerComponentParams: {
        template: `<img src=${checkIcon} alt=''/>`,
      },
      width: '37px !important',
      height: '37px !important',
      padding: '2px 4px',
      resizable: false,
      headerCheckboxSelection: true,
      showDisabledCheckboxes: true,
      checkboxSelection: true,
      flex: 0,
      cellStyle: {
        display: 'flex',
        justifyContent: 'center',
        width: '35px !important',
        height: '37px',
        padding: '2px 4px',
      },
    },
    {
      headerName: t('No.', 'No.'),
      field: 'no',
      tooltipField: 'no',
      cellStyle: { textAlign: 'center' },
      width: 80,
    },
    {
      headerName: t('com.label.00047', '첨부'),
      headerTooltip: t('com.label.00047', '첨부'),
      field: 'atchFileExist',
      tooltipField: 'atchFileExist',
      cellRenderer: FileCellRenderer,
      cellRendererParams: (params: ICellRendererParams) => {
        return {
          img: `${File}`,
          ...params,
          isDisplayNone: params.data.atchFileExist === '0' ? true : false,
          style: {
            cursor: 'default',
          },
        } as IButtonCellRendererParams;
      },
      width: 90,
    },
    {
      headerName: t('com.label.00048', '제목'),
      headerTooltip: t('com.label.00048', '제목'),
      field: 'bbmTitNm',
      tooltipField: 'bbmTitNm',
      cellRenderer: titleValueFormatter,
      cellStyle: { textAlign: 'left' },
      flex: 1,
    },
    {
      headerName: t('com.label.00049', '게시자'),
      headerTooltip: t('com.label.00049', '게시자'),
      field: 'vdcpNm',
      tooltipField: 'vdcpNm',
      cellStyle: { textAlign: 'left', display: 'flex', justifyContent: 'left' },
      cellRenderer: EditProfile,
      cellRendererParams: (params: ICellRendererParams) => {
        return {
          profileImg: `${ProfileImg}`,
          nickName: params.data.vdcpNm,
          ...params,
        } as IButtonCellRendererParams;
      },
      width: 350,
    },
    {
      headerName: t('com.label.00050', '게시일'),
      headerTooltip: t('com.label.00050', '게시일'),
      field: 'dataInsDtm',
      tooltipField: 'dataInsDtm',
      cellStyle: { textAlign: 'center' },
      width: 160,
    },
  ];

  if (accessToken || roleCodes.every((o) => o == 'PTN')) {
    colDefs[0].hide = true;
  } else {
    colDefs[0].hide = false;
  }

  return { defaultColDef, colDefs };
};
