import styled from '@emotion/styled';
import { MenuContext } from 'App';
import { MenuContextType } from 'models/admin/Menu';
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import useSessionStore from 'stores/useSessionStore';
import useAuthStore from 'stores/useAuthStore';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { SelectChangeEvent } from '@mui/material';
import { authLogOut } from 'apis/vars/login/Login';
import { useTranslation } from 'react-i18next';

interface ProfileProps {
  type?: '1' | '2';
  profileImg?: string;
  background?: string;
  border?: string;
  nickName?: string;
  isPosition?: boolean;
  position?: string;
  width?: string;
  textColor?: string;
  userId?: string;
}

const EditProfile = (props: ProfileProps) => {
  const { t } = useTranslation();

  const { userId, setSession, menus, accessLevelInfo } = useSessionStore();
  const navigate = useNavigate();
  const menuContext = useContext<MenuContextType>(MenuContext);
  const { accessToken } = useAuthStore();
  const [option, setOption] = React.useState('1');
  const [profileMnu, setProfileMnu] = useState<HTMLElement | null>(null);
  const open = Boolean(profileMnu);

  const handleProfileClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setProfileMnu(event.currentTarget);
  };
  const handleProfileClose = () => {
    setProfileMnu(null);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setOption(event.target.value);
    // changeLanguage(langType[parseInt(event.target.value) - 1 + '']);
    if (menuContext.currentPage.mnuId === '000147') {
      navigate('/', { replace: true });
    } else if (accessToken) {
      menuContext.handleMenuChange({ ...menuContext, selectedHeaderMenu: '000139' });
      navigate('/account-regist', { replace: true });
    } else {
      navigate('/system/admin/member/member-list', { replace: true });
    }
  };

  const handleMypageMnu = () => {
    const myPageMnu = menus.filter((o) => o.mnuId == '000150')[0];

    menuContext.handleMenuChange({
      ...menuContext,
      currentMenu: myPageMnu,
      currentPage: myPageMnu,
      selectedHeaderMenu: '000151',
      clickedByHeaderMenu: true,
    });

    navigate('/mypage');

    setProfileMnu(null);
  };
  const handleLogout = () => {
    authLogOut();

    setProfileMnu(null);
  };

  const handleClickChip = (e) => {
    if (props.userId && accessToken) {
      setProfileMnu(e.currentTarget);

      // popupCenter({
      //   url: `http://gportalapp.lgensol.com/support/profile/getProfile.do?targetUserId=${props.userId}`,
      //   title: '',
      //   w: 1102,
      //   h: 870,
      // });
    }
  };

  const popupCenter = ({ url, title, w, h }) => {
    // Fixes dual-screen position                             Most browsers      Firefox
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width;
    const height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft;
    const top = (height - h) / 2 / systemZoom + dualScreenTop;

    const newWindow = window.open(
      url,
      title,
      `
      scrollbars=yes,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
    );

    if (newWindow) newWindow.focus();
  };

  return (
    <Container type={props.type}>
      <Border background={props.background} border={props.border}>
        <ProfileImg src={props.profileImg} type={props.type} />
      </Border>
      <Nickname type={props.type} userId={props.userId} onClick={handleClickChip}>
        {props.nickName}
      </Nickname>
      <Menu
        id="basic-menu"
        anchorEl={profileMnu}
        open={open}
        onClose={handleProfileClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleMypageMnu}>{t('com.label.00725', '__마이페이지')}</MenuItem>
        <MenuItem onClick={handleLogout}>{t('com.label.00728', '__로그아웃')}</MenuItem>
      </Menu>
      {props.isPosition ? <Position>{props.position}</Position> : <></>}
    </Container>
  );
};

const Container = styled.div<ProfileProps>`
  display: flex;
  align-items: center;
  gap: ${(props) => (props.type === '1' ? `8px` : '4px')};
`;

const Border = styled.span<ProfileProps>`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${(props) => props.border || '#554596'};
  border-radius: 50px;
  background: ${(props) => props.background || '#554596'};
`;

const ProfileImg = styled.img<ProfileProps>`
  width: ${(props) => (props.type === '1' ? `18px` : '100%')};
  height: ${(props) => (props.type === '1' ? `18px` : '100%')};
  border-radius: 50px;
`;

const TextStyle = styled.p<ProfileProps>`
  color: ${(props) => props.textColor || '#1f1f1f;'};
  font-size: ${(props) => (props.type === '1' ? `13px` : '12px')};
  font-weight: ${(props) => (props.type === '1' ? `500` : '700')};
  line-height: 150%;
`;

const Nickname = styled(TextStyle)`
  cursor: ${(props) => (props.userId ? 'pointer' : 'default')};
`;

const Position = styled(TextStyle)``;

export default EditProfile;
