import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { MultiAccntRes } from 'pages/vars/accountregist/hooks/useMultiAccntPrint';
import { PifRes } from 'pages/vars/accountregist/hooks/usePifPrint';
import { callJwtApi } from 'utils/JwtApiUtil';

// Pif 출력폼 데이터 불러오기(USER)
export const getPifDataApi = async () => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/api/sec/account/foreign/foreign-pif`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
  };
  const response: CommonResponse<any> = await callJwtApi(request);
  console.log('response :::', response);
  return (response.successOrNot === 'Y' ? response.data : []) as any;
};

// 복수계좌 출력폼 데이터 불러오기(USER)
export const getMultiAccntDataApi = async (userId: string, accnRegReqId: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/api/sec/account/domestic/joint-account`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({
      userId: userId,
      accnRegReqId: accnRegReqId,
    }),
  };
  const response: CommonResponse<MultiAccntRes> = await callJwtApi(request);
  return (response.successOrNot === 'Y' ? response.data : []) as MultiAccntRes;
};
