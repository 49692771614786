import styled from '@emotion/styled';
import { GTooltipOverCust } from 'components/etc/GTooltipOverCust';
import { CSSProperties, ReactNode } from 'react';
import info from '../../asset/images/Info.svg';
import { GlobalTokenColor } from 'ui/theme/Color';

interface LabelProps {
  children?: ReactNode;
  pTxt?: string | undefined;
  isRequired?: boolean;
  isInfo?: boolean;
  style?: CSSProperties;
  labelWidth?: string;
  align?: 'left' | 'right' | undefined;
  paddingTop?: string;
  paddingBottom?: string;
  alignItems?: string;
}

export const GLabelAtom = (props: LabelProps) => {
  return (
    <LabelClass
      align={props.align}
      labelWidth={props.labelWidth}
      paddingTop={props.paddingTop}
      paddingBottom={props.paddingBottom}
      alignItems={props.alignItems}
    >
      <Text isRequired={props.isRequired}>{props.children}</Text>
      <GTooltipOverCust pTxt={props.pTxt} pPlace="3" isInfo={true}>
        {props.isInfo && <Info />}
      </GTooltipOverCust>
    </LabelClass>
  );
};

const LabelClass = styled.div<{
  labelWidth: string | undefined;
  align: 'left' | 'right' | undefined;
  paddingTop: string | undefined;
  paddingBottom: string | undefined;
  alignItems: string | undefined;
}>`
  display: flex;
  justify-content: ${(props) => (props.align == 'left' ? 'start' : 'end')};
  align-items: ${(props) => props.alignItems || 'flex-start'};
  padding-top: ${(props) => props.paddingTop || '7px'};
  padding-bottom: ${(props) => props.paddingBottom || '0px'};
  min-width: ${(props) => props.labelWidth || '100%'};
`;

const Info = styled.button`
  background: url(${info}) no-repeat center center !important;
  display: inline-block;
  border: none;
  background: none;
  vertical-align: -2px;
  outline: none;
  cursor: pointer;
  width: 18px;
  height: 18px;
  padding: 2px;
  margin: 1px 2px;
`;

const Text = styled.span<{ isRequired: boolean | undefined }>`
  font-weight: 700;
  font-size: 13px;
  color: ${GlobalTokenColor.ESGrey900};
  line-height: 18px;
  position: relative;
  white-space: pre-line;

  &:before {
    ${(props) => (props.isRequired ? `content : ""` : ``)};
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: ${GlobalTokenColor.StatusError500};
    position: absolute;
    left: -5px;
    top: 4px;
  }
`;
