import styled from '@emotion/styled';
import { GlobalGap } from 'ui/theme/Gap';

interface BtnProps {
  marginBottom?: string;
  layoutLoc?: string;
  children?: any;
  paddingTop?: string;
}

const GButtonLayout = ({
  layoutLoc = 'flex-end',
  children,
  marginBottom,
  paddingTop,
}: BtnProps) => {
  return (
    <Layout marginBottom={marginBottom} layoutLoc={layoutLoc} paddingTop={paddingTop}>
      {children}
    </Layout>
  );
};

const Layout = styled.div<{
  layoutLoc: string;
  marginBottom: string | undefined;
  paddingTop: string | undefined;
}>`
  display: flex;
  justify-content: ${(props) => props.layoutLoc};
  /* margin-top: 12px; */
  margin-bottom: ${(props) => props.marginBottom || `${GlobalGap.Px4}`};
  padding-top: ${(props) => props.paddingTop || `${GlobalGap.Px4}`};
`;

export const GButtonBox = styled.div`
  & > button + button {
    margin-left: 4px;
  }
`;

export default GButtonLayout;
