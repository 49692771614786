import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { DefaultCellTooltip } from 'components/grids/tooltip/DefaultCellTooltip';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import radioIcon from 'components/asset/RadioColumn.svg';
import { TagCellRenderer, ITagCellRendererParams } from 'components/grids/TagCellRenderer';
import { useAccountRegist } from './useAccountRegist';
import { formatDate } from 'components/vars/common/FormatNumber';

export const useColumnHistoryDefs = () => {
  const { t } = useTranslation();
  const { getTagStatus } = useAccountRegist({});

  const defaultFrgnColDef1 = useMemo((): ColDef => {
    return {
      sortable: false,
      resizable: true,
      cellStyle: { textAlign: 'center' },
      tooltipComponent: DefaultCellTooltip,
    };
  }, []);

  const colFrgnDefs1: any = [
    {
      headerName: t('No.', 'No.'),
      width: 56,
      field: 'column1',
      tooltipField: 'column1',
      valueGetter: 'node.rowIndex + 1',
    },
    {
      headerName: t('com.label.00065', '은행'),
      headerTooltip: t('com.label.00065', '은행'),
      width: 200,
      cellStyle: { textAlign: 'start' },
      field: 'bankNm',
      tooltipField: 'bankNm',
    },
    {
      headerName: t('SWIFT/BIC', 'SWIFT/BIC'),
      headerTooltip: t('SWIFT/BIC', 'SWIFT/BIC'),
      width: 140,
      field: 'swftCd',
      tooltipField: 'swftCd',
    },
    {
      headerName: t('IBAN', 'IBAN'),
      headerTooltip: t('IBAN', 'IBAN'),
      minWidth: 180,
      flex: 1,
      field: 'ibanCd',
      tooltipField: 'ibanCd',
    },
    {
      headerName: 'Branch(CNAPS)',
      headerTooltip: 'Branch(CNAPS)',
      width: 150,
      field: 'bankBrchCd',
      tooltipField: 'bankBrchCd',
    },
    {
      headerName: t('com.label.00067', '예금주'),
      headerTooltip: t('com.label.00067', '예금주'),
      width: 120,
      field: 'dpstNm',
      tooltipField: 'dpstNm',
    },
    {
      headerName: t('com.label.00070', '계좌번호'),
      headerTooltip: t('com.label.00070', '계좌번호'),
      width: 220,
      cellStyle: { textAlign: 'start' },
      field: 'encAccnNo',
      tooltipField: 'encAccnNo',
    },
    {
      headerName: t('com.label.00101', '계좌인증여부'),
      headerTooltip: t('com.label.00101', '계좌인증여부'),
      width: 100,
      minWidth: 100,
      field: 'accnNoCertYn',
      tooltipField: 'accnNoCertYn',
    },
    {
      headerName: t('com.label.00071', '계좌용도'),
      headerTooltip: t('com.label.00071', '계좌용도'),
      flex: 1,
      minWidth: 150,
      cellStyle: { textAlign: 'start' },
      field: 'usePurpCtn',
      tooltipField: 'usePurpCtn',
    },
    {
      headerName: t('com.label.00076', '통화'),
      headerTooltip: t('com.label.00076', '통화'),
      width: 64,
      field: 'currCd',
      tooltipField: 'currCd',
    },
    {
      headerName: t('com.label.00064', '진행상태'),
      headerTooltip: t('com.label.00064', '진행상태'),
      width: 140,
      field: 'accnRegProgStatCd',
      tooltipField: 'accnRegProgStatCd',
      cellStyle: { textAlign: 'center', display: 'flex', justifyContent: 'center' },
      cellRenderer: TagCellRenderer,
      cellRendererParams: (params: ITagCellRendererParams) => {
        return {
          label: params.data.accnRegProgStatCdNm,
          status: getTagStatus(params.data.accnRegProgStatCd),
          ...params,
        } as ITagCellRendererParams;
      },
    },
    {
      headerName: t('com.label.apprReqDate', '__결재요청일'),
      headerTooltip: t('com.label.apprReqDate', '__결재요청일'),
      width: 140,
      field: 'regReqDt',
      tooltipField: 'regReqDt',
      valueFormatter: (params) => formatDate(params),
      cellStyle: { textAlign: 'center' },
    },
    {
      headerName: t('com.label.approvDtm', '__결재완료일'),
      headerTooltip: t('com.label.approvDtm', '__결재완료일'),
      width: 140,
      field: 'dataInsDtm',
      tooltipField: 'dataInsDtm',
      valueFormatter: (params) => formatDate(params),
      cellStyle: { textAlign: 'center' },
    },
  ];

  return { defaultFrgnColDef1, colFrgnDefs1 };
};
