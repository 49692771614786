/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { findFiles } from 'apis/admin/FileUpload';
import { FileInfo } from 'models/admin/FileInfo';
import axios from 'axios';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { ContentGrid } from 'components/layouts/ContentGrid';
import { AgGridReact } from 'ag-grid-react';
import { GreyLineButton } from 'components/buttons/CustomButton';
import { FontColor } from 'ui/theme/Color';
import { css } from '@mui/material';
import { Link } from 'react-router-dom';
import useSessionStore from 'stores/useSessionStore';

interface FileGridProps {
  atchFileGrId: string;
  showAllDownload?: boolean;
  height?: string;
}

const FileGrid = ({ atchFileGrId, showAllDownload, height }: FileGridProps) => {
  const { t } = useTranslation();
  const { gridNoRowsTemplate } = useSessionStore();

  const [files, setFiles] = useState<FileInfo[]>([]);

  const fileColumnDefs: ColDef[] = [
    {
      headerName: t('com.label.00690', '__파일명') ?? '',
      field: 'ctrcPialDivsNm',
      flex: 1,
      cellRenderer: (params: ICellRendererParams) => (
        <Link
          to="#"
          className="link"
          onClick={async (e) => {
            const fileInfo = {
              atchFileGrId: params.data.atchFileGrId,
              atchFileId: params.data.atchFileId,
              atchFileNm: params.data.atchFileNm,
            } as FileInfo;
            handleDownloadFile(fileInfo);
            e.preventDefault();
          }}
        >
          {`${params.data.atchFileNm} [${Math.floor(
            params.data.atchFileSize
          ).toLocaleString()} KB]`}
        </Link>
      ),
    },
    {
      headerName: 'All Download',
      width: 130,
      headerComponent: () =>
        showAllDownload !== false && files.length > 0 ? (
          <GreyLineButton className="small" onClick={downloadAllFiles}>
            All Download
          </GreyLineButton>
        ) : (
          <></>
        ),
    },
  ];

  const downloadAllFiles = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/v1/file/download/all?atchFileGrId=${atchFileGrId}`,
        {
          responseType: 'blob',
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', atchFileGrId + '.zip');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownloadFile = async (file: FileInfo) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/v1/file/download?atchFileGrId=${file.atchFileGrId}&atchFileId=${file.atchFileId}`,
        {
          responseType: 'blob',
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file.atchFileNm);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (atchFileGrId) {
      findFiles(atchFileGrId).then((result: FileInfo[]) => {
        setFiles(result);
      });
    } else {
      setFiles([]);
    }
  }, [atchFileGrId]);

  return (
    <>
      {files.length === 0 ? (
        <></>
      ) : (
        <ContentGrid className="ag-theme-alpine" style={{ height: height ?? '150px' }}>
          <AgGridReact
            rowData={files || []}
            columnDefs={fileColumnDefs}
            overlayNoRowsTemplate={gridNoRowsTemplate}
          ></AgGridReact>
        </ContentGrid>
      )}
    </>
  );
};

export default FileGrid;

const style = {
  fileName: () => css`
    &:hover,
    &:focus {
      color: ${FontColor.Primary};
      text-decoration: underline;
    }
  `,
};
