/*
  AssetRegister.tsx의 단위 항목 콤보박스 보고 만들었습니다.

  사용 방법
  <GCmnCodeSearchSelect
    //style={{ width: '100%' }}
    cmnCd={'ASST_QTY_UNIT_CD'}
    id={'asstQtyUnitCd'}
    value={basicFormData.asstQtyUnitCd}  => 컨트롤 할 value
    onChange={handleChangeForBasicForm}  => onChange 이벤트 e 항목에 e.target.id, e.target.value만 들어있습니다.
    selectFirstItem  => 옵션 리스트 중 첫번째 값을 default로 가지도록 할 때 prop
    placeholder="단위를 선택해주세요"  => 콤보박스의 placeholder
  />

*/
import React, { SelectHTMLAttributes, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import useCommonCode from 'hooks/useCommonCode';

export interface GCmnCodeSearchProps {
  isSelectDisabled?: boolean; // 콤보박스 disabled 속성
  isSelectReadonly?: boolean; //
  isInputReadOnly?: boolean;
  id?: string;
  value: string;
  placeholder?: string;
  cmnCd: string;
  upperCmnCd?: string;
  emptyLabel?: string;
  onChange?: (e: any) => void;
  selectFirstItem?: boolean;
  defaultValue?: string;
  style?: any;
}

export interface optionList {
  cmnCd?: string;
  cmnCdNm?: string;
  cmnCdDesc?: string;
  upprCmnCd?: string;
  copCd?: string;
}

/**
 * 공통코드를 가져와서 공통 콤보박스를 생성
 * @param isSelectDisabled: default: false 콤보박스 disabled 속성
 * @param isSelectReadonly: default: false 콤보박스 readonly 속성
 * @param isInputReadOnly: default: false 콤보박스의 key in 설정 속성 (true면 input 박스에 입력 불가)
 * @param placeholder: default: '' 콤보박스 input 박스의 placeholder 내용
 * @param cmnCd,
 * @param upperCmnCd,
 * @param emptyLabel,
 * @param onChange,
 * @param selectFirstItem: default: false 콤보박스 input 박스의 기본 값을 첫 아이템으로 설정
 * @param defaultValue: default: undefined 콤보박스 input 박스의 기본 값 설정
 */
const GCmnCodeSearchSelect = ({
  isSelectDisabled = false,
  isSelectReadonly = false,
  isInputReadOnly = false,
  placeholder = '',
  cmnCd,
  upperCmnCd,
  emptyLabel,
  onChange,
  selectFirstItem,
  defaultValue,
  style,
  ...rest
}: GCmnCodeSearchProps) => {
  const codes = useCommonCode(cmnCd);
  const emptyValue: optionList = {
    cmnCd: '',
    cmnCdNm: '',
    cmnCdDesc: '',
    upprCmnCd: '',
    copCd: '',
  };
  const filteredCodes: optionList[] = useMemo(() => {
    return upperCmnCd ? codes.filter((code) => code.upprCmnCd === upperCmnCd) : codes;
  }, [codes, upperCmnCd]);

  useEffect(() => {
    if (codes.length > 0) emitOnChangeEvent();
  }, [codes]);

  //기본 값 설정
  const emitOnChangeEvent = () => {
    if ((defaultValue || selectFirstItem) && !rest.value && onChange) {
      let index = -1;

      if (defaultValue) {
        index = codes.findIndex((code) => {
          return code.cmnCd === defaultValue;
        });
      }

      if (selectFirstItem && index === -1) {
        index = emptyLabel ? 1 : 0;
      }
      if (index !== -1) {
        const e: any = {
          target: {
            id: rest.id,
            value: codes[index].cmnCd,
          },
        };
        onChange(e);
      }
    }
  };

  const handleChange = (e, newValue) => {
    // e.target.id = rest.id;
    // e.target.value = newValue.cmnCd;
    onChange && onChange({ ...e, target: { id: rest.id, value: newValue ? newValue.cmnCd : '' } });
  };

  const { t } = useTranslation();
  //const [selectedValue, setSelectedValue] = useState<list | null>(null);

  // const handleChange = (event: any, newValue: list) => {
  //   console.log('handleChange', event, newValue);
  //   setSelectedValue(newValue);
  // };

  return (
    <Autocomplete
      id="size-small-outlined"
      size="small"
      sx={{ m: 1, minWidth: 120, margin: 0, height: 28, ...style }}
      options={filteredCodes}
      className={isSelectReadonly ? 'Mui-readOnly' : isSelectDisabled ? 'Mui-disabled' : ''}
      disabled={isSelectDisabled}
      readOnly={isSelectReadonly}
      onChange={handleChange}
      value={{
        ...emptyValue,
        cmnCd: rest.value,
        cmnCdNm: filteredCodes.find((filteredCode) => filteredCode.cmnCd === rest.value)?.cmnCdNm,
      }}
      //getOptionLabel : select의 input 박스 컴포넌트에 보여주는 input 값
      getOptionLabel={(option: optionList) => {
        //console.log('라벨 보여주러 왔나?', option, option.cmnCdNm);

        return option.cmnCdNm ? option.cmnCdNm : '';
      }}
      //isOptionEqualToValue : option이 객체인 경우 option과 value의 값이 같다는 것을 확인하는 함수 (없으면 에러는 안나지만 콘솔에 찍힘)
      isOptionEqualToValue={(option, value) => {
        //console.log('비교 하러 들왔나', option.cmnCd, value.cmnCd);
        if (!value || !value.cmnCd) return false;
        return option.cmnCd === value.cmnCd;
      }}
      //renderOption : 옵션 컴포넌트
      renderOption={(props, option) => (
        <Box component="li" {...props} key={option.cmnCd}>
          {option.cmnCdNm}
        </Box>
      )}
      //renderInput : select의 input 박스 컴포넌트
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          inputProps={{
            ...params.inputProps,
            title: '' + params.inputProps.value,
            readOnly: isSelectReadonly || isInputReadOnly,
          }}
        />
      )}
    />
  );
};

export default GCmnCodeSearchSelect;
