import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Color } from '../../ui/theme/Color';
import { ReactNode, useEffect, useState } from 'react';

export interface TooltipCust {
  pTxt: ReactNode;
  pPlace?: string | undefined;
  // children: JSX.Element;
  children?: JSX.Element | any;
  isTextOverflow?: boolean;
  isInfo?: boolean;
}

export const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    color: `${Color.White}`,
    backgroundColor: `${Color.Grey800}`,
    padding: '4px 8px',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '18px',
  },
  [`& .${tooltipClasses.tooltipPlacementTop}`]: {
    marginBottom: '5px !important',
  },
  [`& .${tooltipClasses.tooltipPlacementBottom}`]: {
    marginTop: '5px !important',
  },
  [`& .${tooltipClasses.tooltipPlacementLeft}`]: {
    marginRight: '5px !important',
  },
  [`& .${tooltipClasses.tooltipPlacementRight}`]: {
    marginLeft: '5px !important',
  },
}));

const setToolTipPosition = (
  placement?: string
):
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top'
  | undefined => {
  let result:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
    | undefined;

  switch (placement) {
    case '1':
      result = 'top-end';
      break;
    case '2':
      result = 'right-start';
      break;
    case '3':
      result = 'right';
      break;
    case '4':
      result = 'right-end';
      break;
    case '5':
      result = 'bottom-end';
      break;
    case '6':
      result = 'bottom';
      break;
    case '7':
      result = 'bottom-start';
      break;
    case '8':
      result = 'left-end';
      break;
    case '9':
      result = 'left';
      break;
    case '10':
      result = 'left-start';
      break;
    case '11':
      result = 'top-start';
      break;
    case '12':
      result = 'top';
      break;
    default:
      result = 'top';
      break;
  }
  return result;
};

export const GTooltipOverCust = (props: TooltipCust) => {
  const [isOver, setIsOver] = useState<boolean>(false);
  // console.log(props.pTxt);
  const txt = props.pTxt?.toString().length as number;
  // let dis = '';
  // if (txt > 26) {
  //   dis = '';
  // } else {
  //   dis = 'none';
  // }

  // useEffect(() => {}, []);

  const checkIsOverFlow = (e) => {
    e.stopPropagation();
    const clWidth = e.target.clientWidth || 0;
    const scWidth = e.target.scrollWidth || 0;
    setIsOver(clWidth < scWidth || clWidth === 0 || (props.isInfo ? true : false));
  };

  return (
    <CustomWidthTooltip
      title={props.pTxt} // {t(`${it.msgCtn}`, `${it.mnuNm}`)}
      placement="bottom-start"
      sx={{ display: isOver ? '' : 'none' }}
      onMouseOver={checkIsOverFlow}
    >
      {props.children}
    </CustomWidthTooltip>
  );
};
