export enum CommonYN {
  Y = 'Y',
  N = 'N',
}

export const commonYNarr: Option[] = [
  { optionName: 'Y', optionValue: 'Y' },
  { optionName: 'N', optionValue: 'N' },
];

export interface Option {
  optionName: string;
  optionValue: string;
}
