/** @jsxImportSource @emotion/react */
import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { CommonYN } from 'models/common/Common';
import CodeDetailGrid from 'pages/admin/codemanagement/CodeDetailGrid';
import { GreyButton } from 'components/buttons/CustomButton';
import CodeGroupGrid from 'pages/admin/codemanagement/CodeGroupGrid';
import { CustomInputText } from 'components/inputs/CustomInput';
import { CustomSelect } from 'components/selects/CustomSelect';
import SearchIcon from '@mui/icons-material/Search';
import { SearchBox, SearchButtons, SearchRows } from 'components/layouts/SearchBox';
import { ContentSection } from 'components/layouts/ContentSection';
import { ContainerLayout } from 'components/layouts/MainLayout';
import searchImage from 'components/asset/images/Search.svg';
import { GButton } from 'components/atom/button';

const CodeManagementPage: React.FC = () => {
  const { t } = useTranslation();

  const [textboxGrCd, setTextboxGrCd] = useState<string>('');
  const [textboxUseYn, setTextboxUseYn] = useState<string>('');
  const [textboxCode, setTextboxCode] = useState<string>('');

  const [searchGrCd, setSearchGrCd] = useState<string>('');
  const [searchUseYn, setSearchUseYn] = useState<string>('');
  const [searchCode, setSearchCode] = useState<string>('');

  const [searchDetailCd, setSearchDetailCd] = useState<string>('');

  const handleTextboxGrCd = (e: any) => {
    setTextboxGrCd(e.target.value);
  };
  const handleTextboxUseYn = (e: any) => {
    setTextboxUseYn(e.target.value);
  };
  const handleTextboxCode = (e: any) => {
    setTextboxCode(e.target.value);
  };

  const btnSearch = () => {
    setSearchGrCd(textboxGrCd);
    setSearchUseYn(textboxUseYn);
    setSearchCode(textboxCode);
    setSearchDetailCd('');
  };

  const listner = useCallback((param: string) => {
    setSearchDetailCd(param);
  }, []);

  return (
    <ContainerLayout>
      <SearchBox>
        <SearchRows>
          <div className="searchCol">
            <label>{t('com.label.grCdOrNm', '__그룹코드/명')}</label>
            <CustomInputText onChange={handleTextboxGrCd}></CustomInputText>
          </div>
          <div className="searchCol">
            <label>{t('com.label.useYn', '__사용여부')}</label>
            <CustomSelect onChange={handleTextboxUseYn}>
              <option value="">{t('com.label.00511', '__전체')}</option>
              <option value={CommonYN.Y}>{t('common.option.사용', '__사용')}</option>
              <option value={CommonYN.N}>{t('common.option.사용안함', '__사용안함')}</option>
            </CustomSelect>
          </div>
          <div className="searchCol">
            <label>{t('com.label.cmnCdOrNm', '__공통코드/명')}</label>
            <CustomInputText className="width300" onChange={handleTextboxCode}></CustomInputText>
          </div>
        </SearchRows>
        <SearchButtons>
          <GButton
            chkImg={searchImage}
            txt={t('com.label.00116', '__조회')}
            sizes="medium"
            btnstyled="contained"
            btnColor="normal"
            hasImg={true}
            hasTxt={true}
            onClick={btnSearch}
          ></GButton>
        </SearchButtons>
      </SearchBox>

      <ContentSection>
        <CodeGroupGrid
          sGrCd={searchGrCd}
          sUseYn={searchUseYn}
          sCode={searchCode}
          editable={true}
          callBack={listner}
        />
      </ContentSection>
      <ContentSection>
        <CodeDetailGrid sCmnGrCd={searchDetailCd} editable={true} />
      </ContentSection>
    </ContainerLayout>
  );
};

export default CodeManagementPage;
