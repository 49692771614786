import React, { InputHTMLAttributes } from 'react';
import { Color } from 'ui/theme/Color';
import { InputProps } from '@mui/material/Input/Input';
import { hover } from '@testing-library/user-event/dist/hover';
import styled from '@emotion/styled';
import { Checkbox, CheckboxProps, Radio, RadioProps, Select } from '@mui/material';
import CheckLineIcon from 'components/asset/CheckLine.png';
import { GInputIconButton } from 'components/buttons/GInputIconButton';

export interface GInputProps extends InputHTMLAttributes<HTMLInputElement> {
  status: 'normal' | 'error' | 'warning' | 'confirmed' | undefined;
  align: 'left' | 'right' | 'center' | undefined;
}

export const GInput: React.FC<GInputProps> = ({ status = 'normal', align = 'left', ...props }) => {
  let classNames = 'inputStatusDefault';

  switch (status) {
    case 'normal':
      classNames += ' inputStatusNormal';
      break;
    case 'error':
      classNames += ' inputStatusError';
      break;
    case 'warning':
      classNames += ' inputStatusWarning';
      break;
    case 'confirmed':
      classNames += ' inputStatusConfirmed';
      break;
    default:
      break;
  }

  switch (align) {
    case 'left':
      classNames += ' alignLeft';
      break;
    case 'right':
      classNames += ' alignRight';
      break;
    case 'center':
      classNames += ' alignCenter';
      break;
    default:
      break;
  }

  return <input {...props} className={classNames} />;
};

// Radio
const PradioIcon = styled.span`
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-color: ${Color.White};
  border: 1px solid ${Color.Grey400};
  // margin-left: 3px;
  input:active ~ & {
    background-color: ${Color.White};
    border: 4px solid ${Color.Primary600};
  }
  input:disabled ~ & {
    border: 1px solid ${Color.Grey300};
  }
`;

const PradioCheckedIcon = styled(PradioIcon)`
  border: 4px solid ${Color.Primary600};
  input:disabled ~ & {
    border: 4px solid #abd7e0;
  }
`;

// -------------
export function GPRadio(props: RadioProps) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<PradioCheckedIcon />}
      icon={<PradioIcon />}
      {...props}
    />
  );
}

const SradioIcon = styled.span`
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-color: ${Color.White};
  border: 1px solid ${Color.Grey400};
  // margin-left: 3px;
  input:active ~ & {
    background-color: ${Color.White};
    border: 4px solid ${Color.Secondary500};
  }
  input:disabled ~ & {
    border: 1px solid ${Color.Grey300};
  }
`;

const SradioCheckedIcon = styled(SradioIcon)`
  border: 4px solid ${Color.Secondary500};
  input:disabled ~ & {
    border: 4px solid #bbb5d5;
  }
`;

export function GSRadio(props: RadioProps) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<SradioCheckedIcon />}
      icon={<SradioIcon />}
      {...props}
    />
  );
}

export const GInputLabel = styled.div`
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  color: ${({ theme }) => (theme.palette.mode == 'dark' ? Color.White : Color.Grey900)};
  font-family: 'Spoqa Han Sans Neo', '맑은 고딕', 'malgun gothic', 'AppleGothicNeoSD',
    'Apple SD 산돌고딕 Neo', 'Microsoft NeoGothic', 'Droid sans', sans-serif;
`;

export const GInputLabelDisabled = styled.div`
  font-size: 13px;
  font-weight: normal;
  color: #979998;
  font-family: 'Spoqa Han Sans Neo', '맑은 고딕', 'malgun gothic', 'AppleGothicNeoSD',
    'Apple SD 산돌고딕 Neo', 'Microsoft NeoGothic', 'Droid sans', sans-serif;
`;

// checkbox    //////////////////////////////////////////////////////////////////////////////

const PcheckboxIcon = styled('span')(({ theme }) => ({
  borderRadius: 2,
  width: 16,
  height: 16,
  // marginLeft: '3px',
  backgroundColor: Color.White,
  border: '1px solid #b9bcbb',
  'input:hover ~ &': {
    border: '1px solid #2d9bb2',
  },
  'input:active ~ &': {
    backgroundColor: Color.White,
    border: '1px solid #2d9bb2',
  },
  'input:disabled ~ &': {
    border: '1px solid #dde0df',
  },
}));

const PcheckboxCheckedIcon = styled(PcheckboxIcon)({
  backgroundColor: '#2d9bb2',
  border: '1px solid #2d9bb2',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='1 1 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    border: '1px solid #2d9bb2',
  },
  'input:active ~ &': {
    backgroundColor: Color.White,
    border: '1px solid #2d9bb2',
  },
  'input:disabled ~ &': {
    border: '1px solid #abd7e0',
    background: '#abd7e0',
  },
});

export function GPCheckbox(props: CheckboxProps) {
  return (
    <Checkbox
      sx={{
        '&:hover': { bgcolor: 'transparent' },
      }}
      disableRipple
      color="default"
      checkedIcon={<PcheckboxCheckedIcon />}
      icon={<PcheckboxIcon />}
      inputProps={{ 'aria-label': 'Checkbox demo' }}
      {...props}
    />
  );
}

// ---------------------start

const PIntermediateIcon = styled(PcheckboxIcon)({
  backgroundColor: '#2d9bb2',
  border: '1px solid #2d9bb2',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-3 -6 16 16'%3E%3Crect" +
      " width='8' height='2' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    border: '1px solid #2d9bb2',
  },
  'input:active ~ &': {
    backgroundColor: Color.White,
    border: '1px solid #2d9bb2',
  },
  'input:disabled ~ &': {
    border: '1px solid #abd7e0',
    background: '#abd7e0',
  },
});

export function GPIntermediateCheckbox(props: CheckboxProps) {
  return (
    <Checkbox
      sx={{
        '&:hover': { bgcolor: 'transparent' },
      }}
      disableRipple
      color="default"
      checkedIcon={<PIntermediateIcon />}
      icon={<PcheckboxIcon />}
      inputProps={{ 'aria-label': 'Checkbox demo' }}
      {...props}
    />
  );
}

// ---------------------end
const ScheckboxIcon = styled('span')(({ theme }) => ({
  borderRadius: 2,
  width: 16,
  height: 16,
  backgroundColor: Color.White,
  // marginLeft: '3px',
  border: '1px solid #b9bcbb',
  'input:hover ~ &': {
    border: '1px solid #554596',
  },
  'input:active ~ &': {
    backgroundColor: Color.White,
    border: '1px solid #554596',
  },
  'input:disabled ~ &': {
    border: '1px solid #dde0df',
  },
}));

const ScheckboxCheckedIcon = styled(ScheckboxIcon)({
  backgroundColor: '#554596',
  border: '1px solid #554596',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='1 1 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    border: '1px solid #554596',
  },
  'input:active ~ &': {
    backgroundColor: Color.White,
    border: '1px solid #554596',
  },
  'input:disabled ~ &': {
    border: '1px solid #bbb5d5',
    background: '#bbb5d5',
  },
});

export function GSCheckbox(props: CheckboxProps) {
  return (
    <Checkbox
      sx={{
        '&:hover': { bgcolor: 'transparent' },
      }}
      disableRipple
      color="default"
      checkedIcon={<ScheckboxCheckedIcon />}
      icon={<ScheckboxIcon />}
      inputProps={{ 'aria-label': 'Checkbox demo' }}
      {...props}
    />
  );
}

// grid radio
export function GridRadio(props: CheckboxProps) {
  return (
    <Checkbox
      sx={{
        '&:hover': { bgcolor: 'transparent' },
      }}
      disableRipple
      color="default"
      checkedIcon={<SradioCheckedIcon />}
      icon={<SradioIcon />}
      inputProps={{ 'aria-label': 'Checkbox demo' }}
      {...props}
    />
  );
}

// ---------------------start

const SIntermediateIcon = styled(PcheckboxIcon)({
  backgroundColor: '#554596',
  border: '1px solid #554596',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-3 -6 16 16'%3E%3Crect" +
      " width='8' height='2' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    border: '1px solid #554596',
  },
  'input:active ~ &': {
    backgroundColor: Color.White,
    border: '1px solid #554596',
  },
  'input:disabled ~ &': {
    border: '1px solid #bbb5d5',
    background: '#bbb5d5',
  },
});

export function GSIntermediateCheckbox(props: CheckboxProps) {
  return (
    <Checkbox
      sx={{
        '&:hover': { bgcolor: 'transparent' },
      }}
      disableRipple
      color="default"
      checkedIcon={<SIntermediateIcon />}
      icon={<ScheckboxIcon />}
      inputProps={{ 'aria-label': 'Checkbox demo' }}
      {...props}
    />
  );
}

// ---------------------end

// GSearchInput ///////////////////////////////////////////////////
export const GSearchInput = styled.div`
  /* display: inline-block; */
  font-size: 13px;
  font-weight: normal;
  position: relative;

  display: flex;
  align-items: center;
  width: 100%;
  height: 32px;
  padding: 4px 24px;
  border: 1px solid #b9bcbb;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  border-radius: 2px;

  /* input {
    // position: relative;
    padding-left: 24px;
  } */

  button {
    display: inline-block;
    border: none;
    background: transparent;
    position: absolute;
    left: 0;
    top: 4px;
    min-width: 0px;
  }
  button:hover {
    border: none;
    background: transparent;
  }
  & input:read-only {
    background: #f1f4f3;
    color: #1f1f1f;
  }
`;

// GSearchInputDel ///////////////////////////////////////////////////
export const GSearchInputDel = styled.div`
  display: inline-block;
  font-size: 13px;
  font-weight: normal;
  position: relative;
  height: 32px;

  input {
    // position: relative;
    padding-left: 24px;
    padding-right: 24px;
  }

  button {
    display: inline-block;
    border: none;
    background: transparent;
    position: absolute;
    left: 0;
    top: 4px;
    min-width: 0px;
  }
  &.delBtn {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: none;
    background: transparent;
    position: absolute;
    left: auto;
    right: 0;
    top: 4px;
    min-width: 0px;
  }

  button:hover {
    border: none;
    background: transparent;
  }

  &.readOnly input,
  & input:read-only {
    padding-right: 4px;
    background: #f1f4f3;
    color: #1f1f1f;
  }
  &.readOnly .delBtn {
    display: none;
  }
`;

export const GSearchUserInput = styled.div`
  diplay: flex;
  button + input {
    margin-left: 4px;
  }
  input + button {
    margin-left: 4px;
  }
`;

// Select ////////////////////////////////////////////////////////////////

export const GSelectMUI = styled(Select)`
  height: 32px;
  border-radius: 2px;
  font-size: 13px;
  color: 1px solid ${Color.Grey900};
  background: ${Color.White};
  & .MuiSelect-select {
    border: none;
    margin: 0;
    padding: 0 0 0 10px;
    height: 28px;
    font-size: 13px;
    font-weight: normal;
    line-height: 28px;
    color: ${Color.Grey900};
  }
  .MuiButtonBase-root.MuiMenuItem-root {
    font-size: 13px;
  }
  fieldset {
    border-radius: 2px;
    border: 1px solid ${Color.Grey400};
  }
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid #b9bcbb !important;
  }
  &:hover .MuiOutlinedInput-notchedOutline,
  &:active .MuiOutlinedInput-notchedOutline {
    border: 1px solid #6e706f !important;
  }
  & .MuiInputBase-readOnly {
    background: #f1f4f3;
  }
`;

export const GSelect = styled.select`
  height: 32px;
  padding-left: 6px;
  border-radius: 2px;
  border: solid 1px ${Color.Grey400};
  background-color: #fff;
  font-size: 13px;
  color: ${Color.Grey900};
  padding-right: 10px;
  outline: 0;
  vertical-align: middle;

  & + &,
  input + & {
    margin-left: 8px;
  }
  & + button {
    margin-left: 8px;
  }
`;

export const GSelectPage = styled.select`
  height: 28px;
  padding-left: 6px;
  border-radius: 2px;
  border: solid 1px ${Color.Grey400};
  background-color: #fff;
  font-size: 13px;
  color: ${Color.Grey900};
  padding-right: 10px;
  outline: 0;
  vertical-align: middle;

  & + &,
  input + & {
    margin-left: 8px;
  }
  & + button {
    margin-left: 8px;
  }
`;
