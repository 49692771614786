import styled from '@emotion/styled';
import Profile from 'components/atom/profile';

interface LabelProps {
  text: string;
  date: string;
}

const DateInfo = (props: LabelProps) => {
  return (
    <>
      <DateInfoBox>
        <Label>{props.text}</Label>
        <Date>{props.date}</Date>
      </DateInfoBox>
    </>
  );
};

const DateInfoBox = styled.div`
  display: flex;
  gap: 4px;
  margin-left: 24px;
`;
const Label = styled.label`
  color: var(--Common-Text-Light, #5b5c5b);
  font-feature-settings: 'clig' off, 'liga' off;
  /* Body/Small-Bold */
  font-family: Spoqa Han Sans Neo;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`;
const Date = styled.span`
  color: var(--Common-Text-Light, #5b5c5b);
  font-feature-settings: 'clig' off, 'liga' off;

  /* Body/Small */
  font-family: Spoqa Han Sans Neo;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
`;

export default DateInfo;
