import styled from '@emotion/styled';
import { useState } from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useTranslation } from 'react-i18next';

interface SidebarButtonProps {
  onClick: () => void;
  isOpened: boolean;
  show: boolean;
  top: string;
}

const SidebarToggleButton = ({ onClick, isOpened, show, top }: SidebarButtonProps) => {
  const { t } = useTranslation();
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  const handleToggleClick = () => {
    handleTooltipOpen();
    onClick();
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          open={!show && tooltipOpen} // isOpened가 true일 때 툴팁 닫기
          onClose={handleTooltipClose}
          title={
            t(
              'com.label.00693',
              '__화면 내 좌측 메뉴가 있을 경우에만 폴딩 기능이 실행됩니다.'
            ) as string
          }
          placement="bottom"
          slotProps={{
            popper: {
              sx: {
                [`& .${tooltipClasses.tooltip}`]: {
                  backgroundColor: '#3c3e3d',
                  color: '#fff',
                  maxWidth: 500,
                  fontSize: 12,
                  fontWeight: 300,
                  marginRight: 10,
                  borderRadius: '2px',
                },
              },
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [-20, -20],
                  },
                },
              ],
            },
          }}
        >
          <ToggleButton onClick={handleToggleClick} top={top}>
            <ToggleImg src="/assets/icon/ic-menu.svg" alt="" />
          </ToggleButton>
        </Tooltip>
      </ClickAwayListener>
    </>
  );
};

const ToggleButton = styled.button<{ top: string }>`
  cursor: pointer;

  position: absolute;
  top: ${(props) => props.top};
  left: 0;

  width: 52px;
  height: 52px;
  padding: 14px;

  text-align: center;
  color: #fff;

  border: 0;
  outline: 0;

  background-color: #2d9bb2;
  &:hover {
    background-color: #3ab3bf;
  }
  &:active {
    background-color: #135678;
  }
`;

const ToggleImg = styled.img`
  width: 24px;
  height: 24px;
`;

export default SidebarToggleButton;
