/** @jsxImportSource @emotion/react */
import useSessionStore from 'stores/useSessionStore';
import React, { useState, useRef, useEffect, useMemo, useCallback, memo } from 'react';
import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {
  ColDef,
  ValueFormatterParams,
  CellValueChangedEvent,
  EditableCallbackParams,
  ICellRendererParams,
} from 'ag-grid-community';
import { RoleEmployee } from 'models/admin/Role';
import { CrudCode } from 'models/common/Edit';
import { getRoleEmps, setRoleEmps, deleteRoleEmps } from 'apis/admin/RoleEmployee';

import {
  BlueButton,
  BlueLineButton,
  GreyLineButton,
  IconButton,
} from 'components/buttons/CustomButton';
import { useCommonModal } from 'hooks/useCommonModal';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import SearchIcon from '@mui/icons-material/Search';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { GridButtons, GridInfo, GridInfoSection } from 'components/layouts/ContentSection';
import { ContentGrid } from 'components/layouts/ContentGrid';
import { CustomTag } from 'components/buttons/CustomTag';
import { CustomInputText } from 'components/inputs/CustomInput';
import { Employee } from 'models/admin/Employee';
import EmployeeModal from 'components/modals/common/EmployeeModal';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import Save from 'components/asset/images/Confirm.svg';
import { GButton } from 'components/atom/button';

interface SearchParamData {
  sRoleCd: string;
  editable: boolean;
  callBack?: any;
  width?: number;
  height?: number;
}

const RoleEmpManagementGrid = (props: SearchParamData) => {
  const { t } = useTranslation();
  const { gridNoRowsTemplate } = useSessionStore();

  const gridRef = useRef<AgGridReact<RoleEmployee>>(null);
  const [rowData, setRowData] = useState<RoleEmployee[]>([]);
  const { openCommonModal } = useCommonModal();
  const [rowIndex, setRowIndex] = useState<string>('');
  const [employeeModalOpen, setEmployeeModalOpen] = useState<boolean>(false);

  const defaultColDef: ColDef = useMemo(
    () => ({
      sortable: true,
      cellStyle: { textAlign: 'center' },
      filter: false,
      lockPosition: true,
      editable: false,
    }),
    []
  );

  const [columnDefs, setColumnDefs] = useState<ColDef[]>([
    {
      width: 50,
      checkboxSelection: props.editable,
      headerCheckboxSelection: props.editable,
      hide: !props.editable,
    },
    {
      headerName: String(t('com.label.stat', '__상태')),
      field: 'crudKey',
      width: 80,
      cellStyle: { textAlign: 'center' },
      cellRenderer: function (params: any) {
        if (params.data.crudKey === CrudCode.DELETE) {
          return <CustomTag className="red">{t('com.label.00179', '__삭제됨')}</CustomTag>;
        } else if (params.data.crudKey === CrudCode.UPDATE) {
          return (
            <CustomTag className="yellow">{t('com.label.00173', '__수정되었습니다.')}</CustomTag>
          );
        } else if (params.data.crudKey === CrudCode.CREATE) {
          return <CustomTag>{t('com.label.mgt.1014', '__추가되었습니다.')}</CustomTag>;
        } else {
          return;
        }
      },
    },
    {
      headerName: 'No.',
      width: 60,
      filter: false,

      valueFormatter: (params: ValueFormatterParams) => {
        return `${parseInt(params.node!.id!) + 1}`;
      },
    },
    {
      headerName: String(t('com.label.00694', '__아이디')),
      field: 'userId',
      editable: (params: EditableCallbackParams) => {
        return params.node!.data.crudKey === CrudCode.CREATE;
      },
      width: 140,
    },
    {
      headerName: String(t('com.label.00012', '__이름')),
      field: 'empNm',
      width: 150,
      cellStyle: { textAlign: 'center' },
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <div>
            <CustomInputWithSearch
              value={params.value ?? ''}
              readOnly
              onSearchClick={() => {
                setRowIndex(params.node.rowIndex + '');
                setEmployeeModalOpen(true);
              }}
              deleteButton={false}
            />
          </div>
        );
      },
    },
    {
      headerName: String(t('com.label.deptNm', '__부서명')),
      field: 'deptNm',
      resizable: true,
      width: 200,
    },
    {
      headerName: String(t('com.label.coPhn', '__회사전화번호')),
      field: 'officeNumber',
      width: 180,
    },
    {
      headerName: String(t('com.label.00090', '__이메일')),
      field: 'emlSvrDmnIfoNm',
      flex: 1,
    },
  ]);

  const fnSearchRoleEmp = useCallback((params: string) => {
    if (params !== '' && params !== null) {
      getRoleEmps(params).then((result: RoleEmployee[]) => {
        setRowData(result);
      });
    }
  }, []);

  const fnSelectUser = (emp: Employee[]) => {
    const selectedRow = gridRef.current?.api.getRowNode(rowIndex);
    selectedRow!.setDataValue('empNm', emp[0].empNm);
    selectedRow!.setDataValue('userId', emp[0].userId);
    selectedRow!.setDataValue('deptNm', emp[0].deptNm);
    selectedRow!.setDataValue('officeNumber', emp[0].ofcPhn);
    selectedRow!.setDataValue('emlSvrDmnIfoNm', emp[0].emlSvrDmnIfoNm);

    setEmployeeModalOpen(false);
  };

  const btnAddRow = useCallback(() => {
    if (!rowData) {
      openCommonModal({
        content: t('common.alert.역할을 선택해주세요.', '__역할을 선택해주세요.'),
      });
      return;
    }
    const newRow = {
      crudKey: CrudCode.CREATE,
    } as RoleEmployee;

    setRowIndex('0');
    setEmployeeModalOpen(true);
    setRowData([newRow, ...rowData]);
  }, [rowData]);

  const btnDelRow = useCallback(() => {
    const selectedRowNodes = gridRef.current?.api.getSelectedNodes();
    if (!selectedRowNodes) return;
    const selectedIds = selectedRowNodes
      .map((rowNode) => {
        return parseInt(rowNode.id!);
      })
      .reverse();
    selectedIds.forEach((item) => {
      if (rowData[item].crudKey === CrudCode.CREATE) {
        delete rowData[item];
      } else {
        rowData[item].crudKey = CrudCode.DELETE;
      }
    });
    const filteredData = rowData.filter((element) => element !== undefined);
    setRowData(filteredData);
  }, [rowData, gridRef]);

  const btnSave = async () => {
    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.00170', '__저장하시겠습니까?'),
      noCallback: () => {
        return false;
      },
    });
    const roleCd = props.sRoleCd;

    const valid = rowData
      .map((rowNode, index) => {
        if (rowNode.userId == null || rowNode.userId === '')
          return `\nNo.${index + 1}:` + t('com.label.mgt.1041', '__사용자를 선택해 주세요.');
      })
      .filter((element) => element !== undefined);
    if (valid.length) {
      openCommonModal({ content: valid.toString() });
      return false;
    }

    const saveIds = rowData
      .map((rowNode) => {
        if (rowNode.crudKey === CrudCode.CREATE) {
          return rowNode.userId;
        }
      })
      .filter((element) => element !== undefined) as string[];

    const deleteIds = rowData
      .map((rowNode) => {
        if (rowNode.crudKey === CrudCode.DELETE) {
          return rowNode.userId;
        }
      })
      .filter((element) => element !== undefined) as string[];

    if (saveIds.length + deleteIds.length === 0) {
      return openCommonModal({
        content: t('com.label.mgt.1040', '__변경된 행이 없습니다.'),
      });
    }

    const inserted =
      saveIds.length &&
      (await setRoleEmps(roleCd, saveIds).then((result) => {
        if (!result) {
          openCommonModal({
            content: t('com.label.mgt.1022', '__저장에 실패하였습니다.'),
          });
          return false;
        } else return result.insertedRows;
      }));
    if (inserted === false) return false;

    const deleted =
      deleteIds.length &&
      (await deleteRoleEmps(roleCd, deleteIds.toString()).then((result) => {
        if (!result) {
          openCommonModal({
            content: t('com.label.mgt.1022', '__저장에 실패하였습니다.'),
          });
          return false;
        } else return result.deletedRows;
      }));
    if (deleted === false) return false;
    /**
    TODO: insert성공 후 delete 실패시 insert까지 롤백해야하나?
    그렇다면 insert/delete api를 하나로 통합해야 함
    */

    openCommonModal({
      content: t('com.msg.save', '__저장되었습니다.'),
    });
    fnSearchRoleEmp(props.sRoleCd);
  };

  useEffect(() => {
    fnSearchRoleEmp(props.sRoleCd);
  }, [props]);

  const onModelUpdated = (e) => {
    // e.api.sizeColumnsToFit();
  };

  const onCellValueChanged = useCallback((e: CellValueChangedEvent) => {
    !e.data.crudKey && e.node.setDataValue('crudKey', CrudCode.UPDATE);
  }, []);

  return (
    <>
      <GridInfoSection>
        <GridInfo>
          <span>{t('com.label.00699', '__사용자')}</span>
          <span className="primary">
            {!rowData ? 0 : rowData.length}
            {t('com.label.case', '__건')}
          </span>
        </GridInfo>
        {props.editable && (
          <GridButtons>
            <GreyLineButton className="small" onClick={btnAddRow}>
              <AddIcon></AddIcon>
              {t('com.btn.addRow', '__행추가')}
            </GreyLineButton>
            <GreyLineButton className="small" onClick={btnDelRow}>
              <RemoveIcon></RemoveIcon>
              {t('com.btn.delRow', '__행삭제')}
            </GreyLineButton>
          </GridButtons>
        )}
      </GridInfoSection>
      <ContentGrid className="ag-theme-alpine" style={{ height: `${props.height ?? 500}px` }}>
        <AgGridReact<RoleEmployee>
          overlayNoRowsTemplate={gridNoRowsTemplate}
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={true}
          suppressRowClickSelection={true}
          stopEditingWhenCellsLoseFocus={true}
          singleClickEdit={true}
          rowSelection="multiple"
          onCellValueChanged={onCellValueChanged}
          onModelUpdated={onModelUpdated}
        />
      </ContentGrid>
      <GridInfoSection className="contentEnd">
        {props.editable && (
          <GridButtons>
            <GButton
              chkImg={Save}
              txt={t('com.label.00055', '__저장')}
              sizes="medium"
              btnstyled="contained"
              btnColor="primary"
              hasImg={true}
              hasTxt={true}
              onClick={btnSave}
            />
          </GridButtons>
        )}
      </GridInfoSection>
      <EmployeeModal
        open={employeeModalOpen}
        close={() => {
          setEmployeeModalOpen(false);
        }}
        save={(emp: Employee[]) => fnSelectUser(emp)}
        singleSelect={true}
      />
    </>
  );
};

export default memo(RoleEmpManagementGrid);
