import useSessionStore from 'stores/useSessionStore';
import React, { useState, useRef, useEffect, useMemo, useCallback, memo } from 'react';
import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {
  ColDef,
  ValueFormatterParams,
  ISelectCellEditorParams,
  IsRowSelectable,
  IRowNode,
  CellValueChangedEvent,
  EditableCallbackParams,
  ICellRendererParams,
} from 'ag-grid-community';
import SelectCellRenderer from 'components/grids/SelectCellRenderer';
import { getCommonCodeHeader, getCommonCodes, setCommonCodes } from 'apis/admin/CommonCode';
import { CommonCode, CommonCodeHeader } from 'models/admin/CommonCode';
import { CommonYN } from 'models/common/Common';
import { BlueButton, GreyLineButton } from 'components/buttons/CustomButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DoneIcon from '@mui/icons-material/Done';
import { useCommonModal } from 'hooks/useCommonModal';
import {
  MessageCellRenderer,
  IMessageCellRendererParams,
} from 'components/grids/MessageCellRenderer';
import { useTranslation } from 'react-i18next';
import { CrudCode } from 'models/common/Edit';
import { GridButtons, GridInfo, GridInfoSection } from 'components/layouts/ContentSection';
import { ContentGrid } from 'components/layouts/ContentGrid';
import Save from 'components/asset/images/Confirm.svg';
import { GButton } from 'components/atom/button';

interface SearchParamData {
  sCmnGrCd: string;
  editable: boolean;
  callBackRoleCd?: any;
}

const CodeDetailGrid = (props: SearchParamData) => {
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const gridRef = useRef<AgGridReact<CommonCode>>(null);
  const [rowData, setRowData] = useState<CommonCode[]>([]);
  const [header, setHeader] = useState<CommonCodeHeader>();
  const { openCommonModal } = useCommonModal();
  const { t } = useTranslation();
  const { gridNoRowsTemplate } = useSessionStore();

  const defaultColDef: ColDef = useMemo(
    () => ({
      editable: props.editable,
      sortable: props.editable,
      resizable: props.editable,
      filter: props.editable,
      minWidth: 50,
      width: 150,
      cellStyle: { textAlign: 'center' },
    }),
    []
  );

  const columnDefs = useMemo<ColDef[]>(() => {
    return [
      {
        headerName: '#',
        field: 'crudKey',
        width: 70,
        editable: false,
        filter: false,
        valueFormatter: (params: ValueFormatterParams) => {
          return `${parseInt(params.node!.id!) + 1}`;
        },
      },
      {
        width: 50,
        editable: false,
        checkboxSelection: props.editable,
        headerCheckboxSelection: props.editable,
        hide: !props.editable,
      },
      {
        headerName: String(t('code.column.공통코드', '__공통코드')),
        field: 'cmnCd',
        editable: (params: EditableCallbackParams) => {
          return params.node!.data.crudKey === CrudCode.CREATE;
        },
        cellRenderer: MessageCellRenderer,
        cellRendererParams: (params: ICellRendererParams<CommonCode>) => {
          return {
            msgCtn: params.data?.msgCtn,
            koMessage: params.data?.cmnCdNm,
            showButton: () => {
              return params.data?.crudKey !== CrudCode.CREATE;
            },
            yesCallback: (item?: string) => {
              if (item && params.data) {
                setCommonCodes([{ ...params.data, cmnCdNm: item, crudKey: CrudCode.UPDATE }]).then(
                  (result) => {
                    result && fnSearchCode(props.sCmnGrCd);
                  }
                );
              }
              openCommonModal({ content: t('com.msg.save', '__저장되었습니다.') });
            },
          } as IMessageCellRendererParams;
        },
      },
      {
        headerName: String(t('com.label.cdNm', '__코드명')),
        field: 'cmnCdNm',
        editable: true,
      },
      {
        headerName: String(t('com.label.desc', '__설명')),
        field: 'cmnCdDesc',
      },
      {
        headerName: String(t('com.label.msgCd', '__메시지코드')),
        field: 'msgCtn',
      },
      {
        headerName: String(t('com.label.sortOrd', '__정렬순서')),
        field: 'sortOrd',
        valueParser: (params) => Number(params.newValue) || '',
        width: 50,
      },
      {
        headerName: String(t('com.label.useYn', '__사용여부')),
        field: 'useYn',
        width: 50,
        cellRenderer: SelectCellRenderer,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: [CommonYN.Y, CommonYN.N],
        } as ISelectCellEditorParams,
      },
      {
        headerName: header?.optValNm1 || String(t('com.label.mgt.1010', '__옵션') + 1),
        field: 'optValCtn1',
      },
      {
        headerName: header?.optValNm2 || String(t('com.label.mgt.1010', '__옵션') + 2),
        field: 'optValCtn2',
      },
      {
        headerName: header?.optValNm3 || String(t('com.label.mgt.1010', '__옵션') + 3),
        field: 'optValCtn3',
      },
      {
        headerName: header?.optValNm4 || String(t('com.label.mgt.1010', '__옵션') + 4),
        field: 'optValCtn4',
      },
      {
        headerName: header?.optValNm5 || String(t('com.label.mgt.1010', '__옵션') + 5),
        field: 'optValCtn5',
      },
      {
        headerName: header?.optValNm6 || String(t('com.label.mgt.1010', '__옵션') + 6),
        field: 'optValCtn6',
      },
      {
        headerName: header?.optValNm7 || String(t('com.label.mgt.1010', '__옵션') + 7),
        field: 'optValCtn7',
      },
      {
        headerName: header?.optValNm8 || String(t('com.label.mgt.1010', '__옵션') + 8),
        field: 'optValCtn8',
      },
      {
        headerName: header?.optValNm9 || String(t('com.label.mgt.1010', '__옵션') + 9),
        field: 'optValCtn9',
      },
      {
        headerName: header?.optValNm10 || String(t('com.label.mgt.1010', '__옵션') + 10),
        field: 'optValCtn10',
      },
      {
        headerName: header?.optValNm11 || String(t('com.label.mgt.1010', '__옵션') + 11),
        field: 'optValCtn11',
      },
      {
        headerName: header?.optValNm12 || String(t('com.label.mgt.1010', '__옵션') + 12),
        field: 'optValCtn12',
      },
      {
        headerName: header?.optValNm13 || String(t('com.label.mgt.1010', '__옵션') + 13),
        field: 'optValCtn13',
      },
      {
        headerName: header?.optValNm14 || String(t('com.label.mgt.1010', '__옵션') + 14),
        field: 'optValCtn14',
      },
      {
        headerName: header?.optValNm15 || String(t('com.label.mgt.1010', '__옵션') + 15),
        field: 'optValCtn15',
      },
      {
        headerName: String(t('com.label.rmk', '__비고')),
        field: 'rmk',
      },
    ];
  }, [props, header]);

  const fnSearchHeader = useCallback((params: string) => {
    getCommonCodeHeader(params).then((result: CommonCodeHeader) => {
      setHeader(result);
    });
  }, []);

  const fnSearchCode = useCallback((params: string) => {
    getCommonCodes(params).then((result: CommonCode[]) => {
      setRowData(result);
    });
  }, []);

  const btnAddRow = useCallback(() => {
    const newRow = {
      crudKey: CrudCode.CREATE,
      useYn: CommonYN.Y,
      cmnGrCd: props.sCmnGrCd,
    } as CommonCode;

    setRowData([newRow, ...rowData]);
  }, [rowData]);

  const btnDelRow = useCallback(() => {
    const selectedRowNodes = gridRef.current!.api.getSelectedNodes();

    const selectedIds = selectedRowNodes
      .map((rowNode) => {
        return parseInt(rowNode.id!);
      })
      .reverse();

    selectedIds.forEach((item) => {
      delete rowData[item];
    });

    const filteredData = rowData.filter((element) => element !== undefined);
    setRowData(filteredData);
  }, [rowData, gridRef]);

  const btnSave = async () => {
    const valid = rowData
      .map((rowNode, index) => {
        if (rowNode.cmnCd == null || rowNode.cmnCd == '') return `${index + 1} :Null Cmn Cd\n`;
        if (rowNode.cmnCdNm == null || rowNode.cmnCdNm == '')
          return `${index + 1} :Null Cmn Cd Nm\n`;
        if (rowNode.sortOrd == null || rowNode.sortOrd == '') rowNode.sortOrd = '0';
      })
      .filter((element) => element !== undefined);
    if (valid.length) {
      openCommonModal({ content: valid.toString() });
      return false;
    }

    const saveRows = rowData
      .map((rowNode) => {
        return rowNode.crudKey ? rowNode : null;
      })
      .filter((element) => element !== null) as CommonCode[];

    if (saveRows.length == 0) {
      openCommonModal({
        content: t('com.label.mgt.1024', '__수정된 행이 없습니다.'),
      });
      return true;
    }

    setCommonCodes(saveRows).then((result) => {
      if (!result) {
        openCommonModal({
          content: t('com.label.mgt.1025', '__저장 중 오류가 발생했습니다.'),
        });
        return false;
      }
      openCommonModal({
        content: t('com.msg.save', '__저장되었습니다.'),
      });
      fnSearchCode(props.sCmnGrCd);
    });
  };

  useEffect(() => {
    if (props.sCmnGrCd && props.sCmnGrCd !== '') {
      fnSearchHeader(props.sCmnGrCd);
      fnSearchCode(props.sCmnGrCd);
    }
  }, [props]);

  const isRowSelectable = useMemo<IsRowSelectable>(() => {
    return (node: IRowNode) => {
      return !!node.data && node.data.crudKey === CrudCode.CREATE;
    };
  }, []);

  const onCellValueChanged = useCallback((e: CellValueChangedEvent) => {
    !e.data.crudKey && e.node.setDataValue('crudKey', CrudCode.UPDATE);
  }, []);

  return (
    <>
      <GridInfoSection>
        <GridInfo>
          <span>{t('com.label.mgt.1002', '__코드목록')}</span>
          <span className="primary">
            {rowData?.length ?? 0}
            {t('com.label.case', '__건')}
          </span>
        </GridInfo>
        <GridButtons>
          {props.editable && (
            <GreyLineButton className="small" onClick={btnAddRow}>
              <AddIcon></AddIcon>
              {t('com.btn.addRow', '__행추가')}
            </GreyLineButton>
          )}
          {props.editable && (
            <GreyLineButton className="small" onClick={btnDelRow}>
              <RemoveIcon></RemoveIcon>
              {t('com.btn.delRow', '__행삭제')}
            </GreyLineButton>
          )}
        </GridButtons>
      </GridInfoSection>

      <ContentGrid
        className="ag-theme-alpine"
        style={{
          height: '300px',
        }}
      >
        <AgGridReact<CommonCode>
          overlayNoRowsTemplate={gridNoRowsTemplate}
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={true}
          suppressRowClickSelection={true}
          isRowSelectable={isRowSelectable}
          stopEditingWhenCellsLoseFocus={true}
          singleClickEdit={true}
          rowSelection="multiple"
          onCellValueChanged={onCellValueChanged}
        />
      </ContentGrid>
      <GridInfoSection className="contentEnd">
        <GridButtons>
          {props.editable && (
            <GButton
              chkImg={Save}
              txt={t('com.label.00055', '__저장')}
              sizes="medium"
              btnstyled="contained"
              btnColor="primary"
              hasImg={true}
              hasTxt={true}
              onClick={btnSave}
            />
          )}
        </GridButtons>
      </GridInfoSection>
    </>
  );
};

export default memo(CodeDetailGrid);
