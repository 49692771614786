import styled from '@emotion/styled';
import DateInfo from 'components/atom/dateInfo';
import ProfileImg from 'components/asset/images/userIcon.svg';
import EditProfile from 'components/molecule/Profile';
import { useTranslation } from 'react-i18next';

// interface DateProps {
//   DInumber?: string;
// }
// const WriteInfo = () => {
//   return (
//     <>
//       <InfoBox>
//         <EditProfile profileImg={ProfileImg} nickName="김엔솔" />
//         <DateInfo text="등록일시" date="2023.22.22" />
//         <DateInfo text="수정일시" date="2023.22.22" />
//       </InfoBox>
//     </>
//   );
// };

interface Props {
  copNm?: string;
  crtId?: string;
  crtName?: string;
  crtDate?: string;
  updtDate?: string;
  rfrmId?: string | number;
}
// 제어가능하도록 수정중
const WriteInfo = (props: Props) => {
  // const dateInfoCount = parseInt(props.DInumber || '0', 10);
  const { t } = useTranslation();
  const dateInfoTexts = ['등록일시', '수정일시', '게시일시', '게시종료일시'];

  return (
    <>
      <InfoBox>
        <EditProfile
          type="1"
          profileImg={ProfileImg}
          nickName={props.crtName}
          userId={props.crtId}
        />
        <DateInfo text={t('com.label.cop', '__법인')} date={props.copNm || ''} />
        {props.rfrmId && (
          <DateInfo text={t('com.label.datId', '__자료 ID')} date={props.rfrmId + ''} />
        )}
        <DateInfo text={t('com.label.regDtm', '__등록일시')} date={props.crtDate || ''} />
        {props.updtDate && (
          <DateInfo text={t('com.label.updDtm', '__수정일시')} date={props.updtDate || ''} />
        )}
      </InfoBox>
    </>
  );
};

const InfoBox = styled.div`
  height: auto;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  margin-top: 8px;
`;

export default WriteInfo;
