import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { GButton } from '../atom/button';
import CloseIcon from '../asset/Close.svg';
import {
  GButtonBox,
  GLabel,
  GSearchBox,
  GSearchTable,
  GTitleBox,
  GGroupBoxTop,
} from 'components/layouts/GLayoutBox';
import { GPopupTitle } from 'components/layouts/GLayoutBox';
import { GSelectMUI } from 'components/inputs/GInput';
import { GbasicTable } from 'components/tables/GTable';
import GDatePicker from 'components/inputs/GDatePicker';
import { MenuItem } from '@mui/material';
import { FontColor } from 'ui/theme/Color';
import { GlobalGap } from 'ui/theme/Gap';

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root.MuiDialog-paper': {
    minWidth: '1000px',
    width: '1000px',
  },
  '&..MuiDialog-paper': {
    padding: '25px',
    boxShadow: 'none',
    borderRadius: 0,
    color: FontColor.Default,
  },
  '& .MuiDialogContent-root': {
    padding: '0 24px',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },

  h2: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px',
  },

  '.popupBottom': {
    marginTop: GlobalGap.Px4,
    padding: '0 24px 12px 24px',
  },
}));

export function DialogTitleItem(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 4,
        padding: '24px 18px 24px 24px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <GButton
          onClick={onClose}
          hasImg={true}
          chkImg={CloseIcon}
          sizes="medium"
          btnColor="normal"
          btnstyled="unfilled"
        />
      ) : null}
    </DialogTitle>
  );
}

export default function GModalPopup() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <GButton color="secondary" variant="outlined" size="large" onClick={handleClickOpen}>
        <span>GModalPopup</span>
      </GButton>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="gmodal"
        fullWidth
        maxWidth="xl"
      >
        <DialogTitleItem id="customized-dialog-title" onClose={handleClose}>
          <GPopupTitle title2="타이틀입니다." />
        </DialogTitleItem>
        <DialogContent>
          <GSearchBox className="searchBox">
            <GSearchTable style={{ minWidth: '550px' }}>
              <tbody>
                <tr>
                  <th style={{ width: '120px' }} className="pl0">
                    <GLabel isRequired={true}>Label Search</GLabel>
                  </th>
                  <td>
                    <GDatePicker />
                    <span>test</span>
                  </td>
                </tr>
                <tr>
                  <th className="pl0">
                    <GLabel isRequired={true}>Label Search</GLabel>
                  </th>
                  <td>
                    <GSelectMUI
                      // value={0}
                      //onChange={(e) => console.log('GSelectMUI', e)}
                      // displayEmpty
                      sx={{ m: 1, minWidth: 120, margin: 0 }}
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      <MenuItem value={0}>
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={10}>Ten</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                      <MenuItem value={30}>Thirty</MenuItem>
                    </GSelectMUI>
                    <span>test</span>
                  </td>
                </tr>
              </tbody>
            </GSearchTable>
            <GButtonBox className="rightBottom">
              <GButton color="secondary" variant="contained" size="large">
                <p className="search"></p>
                <span>조회</span>
              </GButton>
            </GButtonBox>
          </GSearchBox>
          <GGroupBoxTop>
            <GTitleBox>
              <span className="title">Table Row</span>
              <span className="commentSub">총</span>
              <span className="comment">112</span>
              <span className="commentSub">건</span>
            </GTitleBox>
          </GGroupBoxTop>
          <GbasicTable className="lineFill rowHover">
            <thead>
              <tr>
                <th>
                  <GLabel>title1</GLabel>
                </th>
                <th>
                  <GLabel>title2</GLabel>
                </th>
                <th>
                  <GLabel>title3</GLabel>
                </th>
                <th>
                  <GLabel>title4</GLabel>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="borderL">content</td>
                <td>content</td>
                <td>content</td>
                <td className="borderR">content</td>
              </tr>
              <tr>
                <td className="borderL">content</td>
                <td>content</td>
                <td>content</td>
                <td className="borderR">content</td>
              </tr>
              <tr>
                <td className="borderL borderB">content</td>
                <td className="borderB">content</td>
                <td className="borderB">content</td>
                <td className="borderR borderB">content</td>
              </tr>
            </tbody>
          </GbasicTable>
        </DialogContent>
        <DialogActions>
          <GButtonBox>
            <GButton onClick={handleClose} color="secondary" variant="outlined" size="large">
              <span>취소</span>
            </GButton>
            <GButton
              color="primary"
              variant="contained"
              size="large"
              autoFocus
              onClick={handleClose}
            >
              <p className="stroke"></p>
              <span>선택</span>
            </GButton>
          </GButtonBox>
          {/* <Button autoFocus onClick={handleClose}>
            Save changes
          </Button> */}
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
