import { ColDef } from 'ag-grid-community';
import { DefaultCellTooltip } from 'components/grids/tooltip/DefaultCellTooltip';
import { addCommas } from 'components/vars/common/FormatNumber';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useKPaymentDetailColDefs = () => {
  const { t } = useTranslation();
  const defaultColDef1 = useMemo((): ColDef => {
    return {
      sortable: false,
      resizable: true,
      cellStyle: { textAlign: 'center' },
      tooltipComponent: DefaultCellTooltip,
    };
  }, []);

  const colDefs1: any = [
    {
      headerName: t('com.label.00122', '__전표번호'),
      headerTooltip: t('com.label.00122', '__전표번호'),
      field: 'slipNo',
      tooltipField: 'slipNo',
      flex: 1,
    },
    {
      headerName: t('com.label.00123', '__증빙일자'),
      headerTooltip: t('com.label.00123', '__증빙일자'),
      field: 'prfDt',
      tooltipField: 'prfDt',
      maxWidth: 200,
    },
    // {
    //   headerName: t('com.label.00124', '__만기일자'),
    //   headerTooltip: t('com.label.00124', '__만기일자'),
    //   field: 'expDt',
    //   tooltipField: 'expDt',
    //   maxWidth: 200,
    // },
    {
      headerName: t('com.label.00118', '__금액'),
      headerTooltip: t('com.label.00118', '__금액'),
      field: 'payAmt',
      tooltipField: 'payAmt',
      cellStyle: { textAlign: 'right' },
      valueFormatter: (params) => addCommas(params.value),
      flex: 1,
    },
    {
      headerName: t('com.label.00125', '__담당부서'),
      headerTooltip: t('com.label.00125', '__담당부서'),
      field: 'mgrDeptNm',
      tooltipField: 'mgrDeptNm',
      cellStyle: { textAlign: 'left' },
      maxWidth: 200,
    },
    {
      headerName: t('com.label.00126', '__세금계산서번호'),
      headerTooltip: t('com.label.00126', '__세금계산서번호'),
      field: 'taxInvcNo',
      tooltipField: 'taxInvcNo',
      flex: 1,
    },
    {
      headerName: t('com.label.00127', '__구매오더'),
      headerTooltip: t('com.label.00127', '__구매오더'),
      field: 'purcOrd',
      tooltipField: 'purcOrd',
      maxWidth: 200,
    },
  ];

  const handleAprvDetailBtn = (data) => {
    console.log('화면 연결이 필요합니다.');
  };

  return { defaultColDef1, colDefs1 };
};
