import styled from '@emotion/styled';
import { useCommonModal } from 'hooks/useCommonModal';
import { Department } from 'models/admin/Department';
import { createContext, useRef, useState } from 'react';
// Dialog Mui
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { GPopupTitle } from 'components/layouts/GLayoutBox';
import { DialogTitleItem } from 'components/layouts/GModalPopup';
import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import { FontColor, GlobalTokenColor } from 'ui/theme/Color';
import { GButton } from 'components/atom/button';
import Save from 'components/asset/images/Confirm.svg';

type DepartmentModalProps = {
  open: boolean;
  singleSelect?: boolean;
  close: () => void;
  save: (item: Department) => void;
};

const emptyDepartment: Department = {
  //
  deptCd: '',
  coCd: '',
  deptNm: '',
  deptEngNm: '',
  temLdrUserId: '',
  upprDeptCd: '',
  useYn: '',
  deptCngNm: '',
};

export const defaultContext = {
  userDeptCd: '',
  userDepartment: emptyDepartment,
  departmentList: [] as Department[],
  selectedInsurance: [] as Department[],
  multipleSelect: false,
  handleSelectInsurance: (item: any) => {
    return;
  },
  handleGetAllInsurance: (item: any) => {
    return;
  },
};

export const InsuranceContext = createContext(defaultContext);

const SearchChargePerson = (props: DepartmentModalProps) => {
  const { t } = useTranslation();
  const gridRef = useRef<AgGridReact<Department>>(null);
  const [departmentList, setDepartmentList] = useState<Department[]>([]);
  const [selectedDepartment, setSelectedDepartment] = useState<Department>(emptyDepartment);
  const { openCommonModal } = useCommonModal();

  const handleClose = () => {
    setDepartmentList([]);
    props.close();
  };

  const handleOnSave = () => {
    const selectedRows = gridRef.current?.api.getSelectedRows() || [];
    if (selectedRows.length === 0) {
      openCommonModal({
        modalType: 'alert',
        content: t(
          'department-choice-popup.alert.한 부서 이상 선택해야 합니다.',
          '__선택된 행이 없습니다.'
        ),
      });
      return;
    }
    props.save(selectedDepartment);
    props.close();
    setDepartmentList([]);
  };

  return (
    <DialogItem open={props.open} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogTitleItem id="customized-dialog-title" onClose={handleClose}>
        <GPopupTitle title2="제목 넣으세요" />
      </DialogTitleItem>
      <DialogContent className="popupContent">SearchChargePerson</DialogContent>
      <DialogActions className="popupBottom">
        <GButton
          txt="취소"
          sizes="medium"
          onClick={handleClose}
          btnstyled="outline"
          btnColor="normal"
          hasImg={false}
          hasTxt={true}
        />
        <GButton
          txt="저장"
          sizes="medium"
          onClick={handleOnSave}
          chkImg={Save}
          btnstyled="contained"
          btnColor="primary"
          hasImg={true}
          hasTxt={true}
        />
      </DialogActions>
    </DialogItem>
  );
};

const DialogItem = styled(Dialog)`
  font-family: 'Spoqa Han Sans Neo';

  .MuiDialog-paper {
    min-width: 900px;
    max-width: 1000px;
    box-sizing: content-box;
    box-shadow: none;
    border-radius: 3px;
    border: 1px solid ${GlobalTokenColor.ESGrey400};
    color: ${FontColor.Default};
    word-break: keep-all;
  }

  .popupContent {
    padding: 0 24px;
    font-size: 13px;
    line-height: 19px;

    .searchBox {
      margin-bottom: 40px;
    }
  }

  .popupBottom {
    padding: 4px 24px 24px;
  }

  .buttonsTop {
    text-align: right;
    & + .section {
      margin-top: 20px;
    }
  }

  .ag-body-vertical-scroll-viewport {
    width: 17px !important;
    max-width: 17px !important;
    min-width: 17px !important;
    position: absolute;
    top: -24px;
    left: 0;
    height: calc(100% + 24px);
  }
`;

export default SearchChargePerson;
