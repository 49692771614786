import { css } from '@emotion/react';
import { ButtonProps } from '@mui/material/Button/Button';
import { IconButtonProps } from '@mui/material/IconButton';
import IconButton from '@mui/material/IconButton';
import styled from '@emotion/styled';
import { Button } from '@mui/material';
import { Color } from 'ui/theme/Color';

// GButton ************************************************************************************************
export const GButton = (props: ButtonProps) => {
  // let classNames = '';

  let styleString = `
  min-width: 0px;
  font-size: 13px;
  `;

  // contained
  let fontColor;
  let bgColor;
  let bgHoverColor;
  let bgActiveColor;
  let borderColor;
  let borderHoverColor;
  let borderActiveColor;
  let disabledFontColor;
  let disabledBgColor;
  let disabledBorderColor;

  // outlined
  let outlinefontColor;
  let outlinebgColor;
  let outlinebgHoverColor;
  let outlinebgActiveColor;
  let outlineborderColor;
  let outlineborderHoverColor;
  let outlineborderActiveColor;
  let outlinedisabledFontColor;
  let outlinedisabledBgColor;
  let outlinedisabledBorderColor;

  switch (props.color) {
    case 'primary':
      // contained
      fontColor = Color.White;
      bgColor = Color.Primary700;
      bgHoverColor = Color.Primary500;
      bgActiveColor = Color.Primary800;
      borderColor = Color.Primary700;
      borderHoverColor = Color.Primary500;
      borderActiveColor = Color.Primary800;
      disabledFontColor = Color.White;
      disabledBgColor = Color.Primary700Op40;
      disabledBorderColor = Color.Primary300Op40;

      // outlined
      outlinefontColor = Color.Primary600;
      outlinebgColor = Color.White;
      outlinebgHoverColor = Color.White;
      outlinebgActiveColor = Color.Primary50;
      outlineborderColor = Color.Primary300;
      outlineborderHoverColor = Color.Primary500;
      outlineborderActiveColor = Color.Primary600;
      outlinedisabledFontColor = Color.Primary700Op40;
      outlinedisabledBgColor = Color.White;
      outlinedisabledBorderColor = Color.Primary300Op40;

      styleString += `
        color: fontColor;
        background-color: ${bgColor};
        &:hover {
          background-color: ${bgHoverColor};
        }
        &:active {
          background-color: ${bgActiveColor};
        }
        &:disabled {
          background-color: ${disabledBgColor};
        }
      `;
      break;
    case 'secondary':
      // contained
      fontColor = Color.White;
      bgColor = Color.Grey800;
      bgHoverColor = Color.Grey700;
      bgActiveColor = Color.Grey900;
      borderColor = Color.Grey800;
      borderHoverColor = Color.Grey700;
      borderActiveColor = Color.Grey900;
      disabledFontColor = Color.White;
      disabledBgColor = Color.Grey800Op40;
      disabledBorderColor = Color.Grey400Op40;

      // outlined
      outlinefontColor = Color.Grey700;
      outlinebgColor = Color.White;
      outlinebgHoverColor = Color.Grey100;
      outlinebgActiveColor = Color.Grey200;
      outlineborderColor = Color.Grey400;
      outlineborderHoverColor = Color.Grey500;
      outlineborderActiveColor = Color.Grey600;
      outlinedisabledFontColor = Color.Grey800Op40;
      outlinedisabledBgColor = Color.White;
      outlinedisabledBorderColor = Color.Grey400Op40;

      styleString += `
        background-color: ${bgColor};
        &:hover {
          background-color: ${bgHoverColor};
        }
        &:active {
          background-color: ${bgActiveColor};
        }
        &:disabled {
          background-color: ${disabledBgColor};
        }
      `;
      break;
    default:
      break;
  }

  switch (props.variant) {
    case 'contained':
      styleString += `
        background-color: ${bgColor};
        &:hover {
          background-color: ${bgHoverColor};
        }
        &:active {
          background-color: ${bgActiveColor};
        }
        &:disabled {
          color: ${disabledFontColor};
          background-color: ${disabledBgColor};
        }
      `;
      break;
    case 'outlined':
      styleString += `
        color: ${outlinefontColor};
        background-color: ${outlinebgColor};
        border: 1px solid ${outlineborderColor};
        &:hover {
          background-color: ${outlinebgHoverColor};
          border: 1px solid ${outlineborderHoverColor};
        }
        &:active {
          background-color: ${outlinebgActiveColor};
          border: 1px solid ${outlineborderActiveColor};
        }
        &:disabled {
          color: ${outlinedisabledFontColor};
          border-color: ${outlinedisabledBorderColor};
          background-color: ${outlinedisabledBgColor};
        }
      `;
      break;
    default:
      break;
  }

  switch (props.size) {
    case 'large':
      styleString += `
        padding: 8px;
        height: 36px;
        border-radius: 3px;
      `;
      break;
    case 'medium':
      styleString += `
        padding: 6px;
        height: 32px;
        border-radius: 2px;
      `;
      break;
    case 'small':
      styleString += `
        padding: 4px 6px;
        height: 28px;
        border-radius: 2px;
        min-width: 38px;
        & .MuiButton-startIcon {
          margin-right: 2px;
        }
      `;
      break;
    default:
      break;
  }

  const MyButton = styled(Button)`
    ${styleString}
  `;

  return <MyButton {...props} disableRipple />;
};
