import { PaginationResponse } from 'models/common/Pagination';
import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { SampleRequest, SampleResponse } from 'models/sample/Sample';
import { callApi } from 'utils/ApiUtil';

export const getSamples = async (searchParams: any) => {
  let sampleData: SampleResponse[] = [];

  for (let index = 0; index < 100; index++) {
    const tmpObj: SampleResponse = {
      column1: `col1_${index}`,
      column2: `col2_${index}`,
      column3: `col3_${index}`,
      column4: `col4_${index}`,
      column5: `col5_${index}`,
      column6: `col6_${index}`,
      column7: `col7_${index}`,
      column8: `col8_${index}`,
      regId: `regId_${index}`,
      regDate: '20231101',
    };
    sampleData.push(tmpObj);
  }

  sampleData = sampleData.slice(
    searchParams.start,
    Number(searchParams.start) + Number(searchParams.pageSize)
  );

  const pageData: PaginationResponse<SampleResponse> = { totalCount: 100, list: sampleData };

  return pageData;
};

interface Params {
  startRow: string;
  pageSize: string;
}

export const getSampleData = async (apiUrlCondition: Params) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `http://localhost:3000/v1/sample2`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({ ...apiUrlCondition }),
  };
  const response: CommonResponse<any> = await callApi(request);
  return response;
};

export const getSampleData2 = async (apiUrlCondition: any | undefined) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `http://localhost:3000/v1/sample3`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({ ...apiUrlCondition }),
  };
  const response: CommonResponse<any> = await callApi(request);
  return response;
};
