import { useEffect, useRef, useState } from 'react';

import { SearchWrap } from 'components/molecule/SearchMolecule';
import { useLoading } from 'components/process/Loading';
import SearchMoleculeVars from 'components/vars/common/SearchMoleculeVars';

import DomesticRegistPage from './DomesticRegistPage';
import OverseasRegistPage from './OverseasRegistPage';

import useSessionStore from 'stores/useSessionStore';

import { VendorInfoVO } from 'models/vars/vendor/VendorInfoVO';
import { VendorReqInfoVO } from 'models/vars/vendor/VendorReqInfoVO';

import { getMemberVendor } from 'apis/vars/account/CommonAccount';
import { useTranslation } from 'react-i18next';

const AccountRegistPage = () => {
  const { t } = useTranslation();
  const { userId } = useSessionStore();
  const [vendorInfo, setVendorInfo] = useState<VendorInfoVO | undefined>(undefined);

  const [crncCd, setCrncCd] = useState<string>();

  const { openLoading } = useLoading();

  useEffect(() => {
    if (userId) {
      (async () => {
        openLoading(true);
        await getMemberVendor(userId)
          .then((resp) => {
            if (resp) {
              setVendorInfo(resp);
              setCrncCd(resp.dmstFrgDivsCd == 'DO' ? 'K' : 'F' || 'K');
            }
          })
          .finally(() => {
            openLoading(false);
          });
      })();
    }
  }, []);

  const KrwDivsRadio = () => {
    return (
      <SearchWrap style={{ padding: '12px' }}>
        <SearchMoleculeVars
          searchWidth="50%"
          radio2Id="krwFcurCd"
          type="radio2"
          labelTitle={t('com.label.00117', '__구분')}
          isRequired={true}
          radio2Value={crncCd || ''}
          radio2ValueKey="cmnCd"
          radio2LabelKey="cmnCdNm"
          radio2DefaultValue={crncCd}
          radio2Option={[
            { cmnCd: 'K', cmnCdNm: t('com.code.KRW_FCUR_CD.W', '__원화') },
            { cmnCd: 'F', cmnCdNm: t('com.code.KRW_FCUR_CD.F', '__외화') },
          ]}
          onChange={(e) => {
            setCrncCd(e.target.value);
          }}
        />
      </SearchWrap>
    );
  };

  return (
    <>
      {vendorInfo &&
        (crncCd == 'K' ? (
          <DomesticRegistPage
            vendorInfo={vendorInfo as VendorReqInfoVO}
            radioRender={KrwDivsRadio}
          />
        ) : (
          <OverseasRegistPage
            vendorInfo={vendorInfo as VendorReqInfoVO}
            radioRender={KrwDivsRadio}
          />
        ))}
    </>
  );
};

export default AccountRegistPage;
