import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { LoginInfo } from 'models/vars/login/LoginInfo';
import { callJwtApi } from 'utils/JwtApiUtil';

const PREFIX_URI = '/api/login';

/**
 * 로그인시 벤더 아이디 유효성 체크
 * @param userId
 * @returns
 */
export const validVenderId = async (userId: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `${PREFIX_URI}/valid-vendorid`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({
      userId: userId,
    }),
  };
  const response: CommonResponse<LoginInfo> = await callJwtApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as LoginInfo;
};

/**
 * 로그인
 * @param userId
 * @param publCertKey
 * @param emlAddr
 * @returns
 */
export const authLogin = async (param: LoginInfo) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `${PREFIX_URI}/login`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    bodyParams: { ...param },
  };
  const response: CommonResponse = await callJwtApi(request);

  return response;
};

/**
 * dev로그인
 */
export const authLoginDev = async (param: LoginInfo) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `${PREFIX_URI}/login-dev`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    bodyParams: { ...param },
  };
  const response: CommonResponse = await callJwtApi(request);

  return response;
};

/**
 * 로그아웃
 * @returns
 */
export const authLogOut = async () => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `${PREFIX_URI}/logout`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
  };
  const response: CommonResponse = await callJwtApi(request);

  return response;
};

/**
 * 난수 메일 발송
 * @param userId
 * @param publCertKey
 * @param emlAddr
 * @returns
 */
export const sendMailAuthCode = async (userId: string) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `${PREFIX_URI}/send-auth-mail`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    bodyParams: { userId: userId },
  };
  const response: CommonResponse = await callJwtApi(request);

  return response;
};

/**
 * 난수 메일 유효성 검증
 * @param userId
 * @param publCertKey
 * @param emlAddr
 * @returns
 */
export const sendMailConfirm = async (param: LoginInfo) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `${PREFIX_URI}/valid-auth-mail`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    bodyParams: { ...param },
  };
  const response: CommonResponse = await callJwtApi(request);

  return response;
};

export const validJointCertificate = async (searchParams: LoginInfo) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `${PREFIX_URI}/valid-joint-certificate`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({ ...searchParams }),
  };
  const response: CommonResponse<boolean> = await callJwtApi(request);
  return response.successOrNot === 'Y' ? (response.data as boolean) : null;
};

// export const validVenderIdCheck = async (userId: string) => {
//   const request: CommonRequest = {
//     method: Method.POST,
//     url: `${PREFIX_URI}/valid-vendorid-check`,
//     serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
//     bodyParams: { userId: userId },
//   };
//   const response: CommonResponse = await callJwtApi(request);

//   return response;
// };

// vendorID로 메일 불러오기
export const getVendorIdMailApi = async (userId: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `${PREFIX_URI}/send-auth-mail/toaddress`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({
      userId: userId,
    }),
  };

  const response: CommonResponse = await callJwtApi(request);
  return response.successOrNot === 'Y' ? response.data : null;
};
