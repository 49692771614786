import { css } from '@emotion/react';
import { BorderColor, FontColor } from 'ui/theme/Color';

export const di = {
  dialog: css`
    .popupContent {
      padding: 20px 0;

      .section {
        line-height: 1.4;
      }
      .searchBox {
        margin-bottom: 40px;
      }

      table {
        margin-bottom: 0;
      }
    }

    .MuiDialogTitle-root + .MuiDialogContent-root {
      padding-top: 20px;
    }

    .buttonClose {
      position: absolute;
      top: 5px;
      right: 0;
      width: 22px;
      height: 22px;
      min-width: 20px;
      svg {
        fill: ${FontColor.Gray400};
        font-size: 1.5rem;
      }
    }

    .buttonsTop {
      text-align: right;
      & + .section {
        margin-top: 20px;
      }
    }

    .popupBottom {
      padding: 0 0 10px;
    }
  `,
};
