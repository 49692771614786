import styled from '@emotion/styled';
import { SelectChangeEvent } from '@mui/material';
import AvatarChip from 'components/atom/avatarChip';
import ChipSelectBox from 'components/atom/chipSelect';
import InputField from 'components/atom/input';
import { GLabelAtom } from 'components/atom/label';
import GSelectMUIAtom from 'components/atom/select';
import GDatePicker from 'components/inputs/GDatePicker';
import React from 'react';
import GridRadio from 'components/molecule/GridRadio';
import GridCheckbox from 'components/molecule/GridCheckbox';
import FileUploadZone from 'components/molecule/FileUpload';
import { GButtonBox } from 'components/molecule/GbuttonLayout';
import GridRadioButton from 'components/molecule/GridRadioButton';
import Text from 'components/atom/text';
import { textSpanContainsPosition } from 'typescript';
import GridRadioVars from './GridRadioVars';
import GridRadioVars2 from './GridRadioVars2';

interface Props {
  type:
    | 'input'
    | 'select'
    | 'chipSelect'
    | 'calendar'
    | 'avatarChip'
    | 'radioButton'
    | 'radio'
    | 'radio2'
    | 'checkBox'
    | 'fileUpload'
    | 'text'
    | undefined;
  searchWidth?: string | '100%'; //라벨을 포함한 width
  searchValueWidth?: string | '100%'; //atom 요소의 width

  // Label
  labelWidth?: string;
  labelTitle: string;
  pTxt?: string | undefined;
  isRequired?: boolean;
  isInfo?: boolean;
  labelAlign?: 'left' | 'right' | undefined;

  // Input
  inputStatus?:
    | 'default'
    | 'disabled'
    | 'readonly'
    | 'error'
    | 'warning'
    | 'confirmed'
    | 'search'
    | undefined;
  inputAlign?: 'left' | 'right' | undefined;
  inputReadOnly?: boolean;
  helperText?: string;

  // Select
  selectOption?: string[];
  selectReadOnly?: boolean;
  defaultOption?: string;

  // Select, Input
  placeholder?: string;

  //GDatePicker
  calendarReadonly?: boolean;
  calendarViews?: any | ['year', 'month', 'day'];
  calendarFormat?: string | 'YYYY.MM.DD';
  calendarMaxWidth?: string | '100%';
  calendarStatus?: 'default' | 'error' | 'warning' | 'confirm' | undefined;
  calendarDouble?: boolean | false;
  calendarWidth?: string | '100%';
  calendarMinWidth?: string;

  //ChipBox
  ChipDisabled?: boolean | undefined;
  ChipReadonly?: boolean | undefined;
  ChipWidth?: string;
  ChipHeight?: string | '100%';
  ChipStatus?: 'default' | 'error' | 'warning' | 'confirm' | undefined;
  ChipType?: '1' | '2' | undefined; //selectChip의 type
  ChipFormat?: string | '';
  SelectChipType?: 'default' | 'user'; //selectChip의 "chip" type
  SelectChipImg?: string;
  isCheck?: boolean;

  // RadioButton / Radio
  radioOption?: string[];
  radioValue?: string[];

  // Checkbox
  checkboxOption?: string[];
  checkboxValue?: string;

  // FileUpload
  fileUploadHeight?: string;

  //AvaterChip
  avaterType?: 'text' | 'user' | 'custom';
  avaterSize?: 'largest' | 'medium' | 'small';
  avaterBackgroundBorder?: 'white' | 'blue' | 'lightGray' | 'defaultGray' | 'darkGray';
  avaterbackgroundColor?: 'white' | 'blue' | 'lightGray' | 'darkGray';
  avaterProfileBackgroundColor?: string;
  avaterProfileBorder?: string;
  avaterProfileImg?: string;
  avaterNickname?: string;
  avaterprofileItemColor?: string;
  avaterprofileText?: string;
  avaternickNameColor?: 'white' | 'gray' | 'blue';

  //Text
  textContent?: string;
  textHeight?: string;
  textColor?: 'black';
  textFontSize?: string;
  textFontWeight?: string;
  textLineHeight?: string;

  //add
  onChange?: (e: any, value?: any) => void;
  radioDefaultValue?: string;
  radio2Option: any;
  radio2Value: string;
  radio2DefaultValue?: string;
  radio2ValueKey: string;
  radio2LabelKey: string;
  radio2Id: string;
}

const SearchMoleculeVars = (props: Props) => {
  // 임시로 SearchMolecule안에 handleChange와 option state를 넣음.
  const [option, setOption] = React.useState(props.defaultOption || '0');
  const handleChange = (event: SelectChangeEvent, value?: any) => {
    // setOption(event.target.value);
    props.onChange && props.onChange(event, value);
  };

  return (
    <GInputArea searchWidth={props.searchWidth}>
      <GLabelAtom
        align={props.labelAlign}
        labelWidth={props.labelWidth || '120px'}
        pTxt={props.pTxt}
        isRequired={props.isRequired}
        isInfo={props.isInfo}
      >
        {props.labelTitle}
      </GLabelAtom>
      <ItemWrap searchValueWidth={props.searchValueWidth}>
        {props.type == 'input' ? (
          <InputField
            status={props.inputStatus || 'default'}
            align={props.inputAlign || 'left'}
            placeholder={props.placeholder}
            readOnly={props.inputReadOnly}
            helperText={props.helperText}
          />
        ) : props.type == 'select' ? (
          <GSelectMUIAtom
            value={option}
            handleChange={handleChange}
            placeholder={props.placeholder}
            option={props.selectOption || []}
            readOnly={props.selectReadOnly}
          />
        ) : props.type == 'calendar' ? (
          <GDatePicker
            readonly={props.calendarReadonly}
            width={props.calendarWidth}
            maxWidth={props.calendarMaxWidth}
            minWidth={props.calendarMinWidth}
            status={props.calendarStatus}
            views={props.calendarViews}
            format={props.calendarFormat}
            double={props.calendarDouble}
          />
        ) : props.type == 'avatarChip' ? (
          <AvatarChip
            type={props.avaterType}
            size={props.avaterSize}
            backgroundBorder={props.avaterBackgroundBorder}
            profileBackgroundColor={props.avaterProfileBackgroundColor}
            profileBorder={props.avaterProfileBorder}
            profileImg={props.avaterProfileImg}
            nickName={props.avaterNickname}
            backgroundColor={props.avaterbackgroundColor}
            profileItemColor={props.avaterprofileItemColor}
            profileText={props.avaterprofileText}
            nickNameColor={props.avaternickNameColor}
          />
        ) : props.type == 'chipSelect' ? (
          <ChipSelectBox
            disabled={props.ChipDisabled}
            readonly={props.ChipReadonly}
            width={props.ChipWidth || '100%'}
            height={props.ChipHeight}
            isCheck={props.isCheck}
            status={props.ChipStatus}
            type={props.ChipType}
            format={props.ChipFormat}
            chipType={props.SelectChipType}
            chipImg={props.SelectChipImg}
          />
        ) : props.type == 'checkBox' ? (
          <GridCheckbox
            value={props.checkboxValue || ''}
            option={props.checkboxOption || []}
            height="32px"
          />
        ) : props.type == 'radioButton' ? (
          <GridRadioButton value={''} option={props.radioOption || []} height="32px" />
        ) : props.type == 'radio' ? (
          <GridRadioVars
            value={props.radioValue || []}
            option={props.radioOption || []}
            height="32px"
            defaultValue={props.radioDefaultValue || ''}
            onChange={props.onChange}
          />
        ) : props.type == 'radio2' ? (
          <GridRadioVars2
            id={props.radio2Id}
            value={props.radio2Value}
            option={props.radio2Option || []}
            valueKey={props.radio2ValueKey}
            labelKey={props.radio2LabelKey}
            height="32px"
            defaultValue={props.radio2DefaultValue || ''}
            onChange={props.onChange}
          />
        ) : props.type == 'fileUpload' ? (
          <FileUploadZone
            ref={FileUploadZone}
            atchFileGrIdInput="9cb7a782-f893-4e6b-b58c-20794684bbc6"
            isMultipleFile={true}
            allowFileTypes={['txt', 'jpg', 'png', 'xlsx']}
            height={props.fileUploadHeight}
          />
        ) : props.type === 'text' ? (
          <Text
            content={props.textContent}
            height={props.textHeight}
            color={props.textColor}
            fontSize={props.textFontSize}
            fontWeight={props.textFontWeight}
            lineHeight={props.textLineHeight}
          />
        ) : (
          <></>
        )}
      </ItemWrap>
    </GInputArea>
  );
};

const GInputArea = styled.div<{ searchWidth: string | undefined }>`
  display: flex;
  width: ${(props) => props.searchWidth || '33%'};
`;

export const ItemWrap = styled.div<{
  searchValueWidth: string | undefined;
}>`
  ${(props) => (props.searchValueWidth ? `width : ${props.searchValueWidth}` : `flex : 1`)};
  margin-left: 8px;
  display: flex;
  align-items: flex-start;
  min-height: 32px;

  &::placeholder {
    font-family: 'Spoqa Han Sas Neo';
  }
`;

// searchBox.tsx
export const SearchWrap = styled.div<{ marginBottom?: string }>`
  display: flex;
  flex-direction: column;
  padding: 8px 12px;

  background-color: #f7f9f8;
  border: 1px solid #dde0df;
  border-radius: 2px;
  margin-bottom: ${(props) => props.marginBottom || '12px'};
`;

export const GButtonBoxCum = styled(GButtonBox)`
  align-self: end;
`;

export const UnHiddenSearchWrap = styled.div`
  display: flex;
`;

export const SearchItemWrap = styled.div<{ nonButton?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  gap: 8px 0;
  margin-right: ${(props) => (props.nonButton ? '0px' : '24px')};
`;

export const SearchHr = styled.hr`
  margin: 8px 0;
  border: none;
  border-top: 1px solid #dde0df;
`;

export const HiddenSearchWrap = styled(SearchItemWrap)`
  width: calc(100% - 156px);
`;

export default SearchMoleculeVars;
