import { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';

// insurance 데이터가 없는 관계로 department에 연결
// import { Insurance } from 'models/admin/Insurance';
import { Department } from 'models/admin/Department';

// ag-grid + sample 가져오기
import { useColumnDefsForApprovalList } from './gridDef/ColumnDefs';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useMessageBar } from 'components/process/MessageBar';
import { useLoading } from 'components/process/Loading';
import { SampleResponse } from 'models/sample/Sample';
import { getSamples } from 'apis/sample/SampleApi';

// modal title
import { DialogTitleItem } from 'components/layouts/GModalPopup';
import { GPopupTitle } from 'components/layouts/GLayoutBox';

// Dialog Mui
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

// Search Box
import SearchMolecule from 'components/molecule/SearchMolecule';
import { GButton } from 'components/atom/button';
import searchImage from 'components/asset/images/Search.svg';
import RecycleB from 'components/asset/images/RecycleB.svg';

// GDatePicker
import GDatePicker from 'components/inputs/GDatePicker';
import { GLabelAtom } from 'components/atom/label';

import SubTitleBox from 'components/molecule/SubTitleBox';

import { ContentSection, GridInfo, GridInfoSection } from 'components/layouts/ContentSection';
import { GContentGrid } from 'components/layouts/GContentGrid';
import { useCommonModal } from 'hooks/useCommonModal';
import { useTranslation } from 'react-i18next';
import Save from 'components/asset/images/Confirm.svg';

// Global theme
import { SubTitleBtnArea } from 'components/organism/SubTitleBtnArea';
import ModalLayout from 'components/layouts/ModalLayout';

type DepartmentModalProps = {
  open: boolean;
  singleSelect?: boolean;
  close: () => void;
  save: () => void;
};

const InsuranceModal = (props: DepartmentModalProps) => {
  const { t } = useTranslation();
  const gridRef = useRef<AgGridReact<Department>>(null);
  const { openCommonModal } = useCommonModal();
  const gridApiRef = useRef<any>(null);
  const { defaultColDef, colDefs } = useColumnDefsForApprovalList();
  const [sampleList, setSampleList] = useState<SampleResponse[]>([]);
  const { openLoading } = useLoading();
  const { openMessageBar } = useMessageBar();

  useEffect(() => {
    loadSampleList();
  }, []);

  const makeSearchParams = () => {
    return {
      start: '0',
      pageSize: 30,
    };
  };

  const handleError = (e: Error) => {
    openLoading(false);
    openMessageBar('error', e.message);
  };

  const loadSampleList = async () => {
    getSamples(makeSearchParams())
      .then((data) => {
        setSampleList(data.list);
      })
      .catch(handleError);
  };

  const handleClose = () => {
    props.close();
  };

  const handleOnSave = () => {
    const selectedRows = gridRef.current?.api.getSelectedRows() || [];
    if (selectedRows.length === 0) {
      openCommonModal({
        modalType: 'alert',
        content: t(
          'department-choice-popup.alert.한 부서 이상 선택해야 합니다.',
          '__선택된 행이 없습니다.'
        ),
      });
      return;
    }
    props.close();
  };

  return (
    <ModalLayout open={props.open} onClose={handleClose} mMinWidth={1000} mMaxWidth={1000}>
      <DialogTitleItem id="customized-dialog-title" onClose={handleClose}>
        <GPopupTitle title2="보험증권 검색" />
      </DialogTitleItem>
      <DialogContent className="popupContent">
        <Wrap>
          <UnHiddenSearchWrap>
            <SearchWrap>
              <SearchMolecule
                type="select"
                searchWidth="48%"
                labelTitle="법인"
                placeholder="ESHQ"
                isRequired={true}
                selectReadOnly={true}
              />
              <SearchMolecule
                type="select"
                searchWidth="48%"
                labelTitle="보험종류"
                isRequired={true}
                placeholder="전체"
                selectOption={['전체', '옵션1', '옵션2']}
              />
              <InputWrap>
                <GLabelAtom labelWidth="36%" align="right" isRequired={true} pTxt={undefined}>
                  보험연도
                </GLabelAtom>
                <GDatePicker
                  width="100%"
                  format="YYYY"
                  views={['year']}
                  double={true}
                  doubleWidth="100%"
                />
              </InputWrap>
              <SearchMolecule
                type="input"
                searchWidth="48%"
                labelTitle="증권번호"
                placeholder="증권번호를 입력하세요."
              />
            </SearchWrap>
            <ButtonWrap>
              <GButton
                chkImg={RecycleB}
                sizes="medium"
                btnstyled="outline"
                btnColor="normal"
                hasImg={true}
                hasTxt={false}
              ></GButton>
              <GButton
                chkImg={searchImage}
                txt="조회"
                sizes="medium"
                btnstyled="contained"
                btnColor="normal"
                hasImg={true}
                hasTxt={true}
              ></GButton>
            </ButtonWrap>
          </UnHiddenSearchWrap>
        </Wrap>
        <ContentSection>
          <GridInfoSection>
            <GridInfo>
              <SubTitleBtnArea>
                <SubTitleBox
                  content="보험증권 목록"
                  showSubTitle={true}
                  isVisibleComment={true}
                  currentColumnCount={40}
                  totalCoulmnCount={50}
                />
              </SubTitleBtnArea>
            </GridInfo>
          </GridInfoSection>
          <GContentGrid type="radio" className="ag-theme-alpine" style={{ height: '256px' }}>
            <AgGridReact
              defaultColDef={defaultColDef}
              columnDefs={colDefs}
              rowData={sampleList}
              rowSelection={'single'}
              onGridReady={(params) => (gridApiRef.current = params.api)}
              tooltipShowDelay={0}
              tooltipHideDelay={2000}
            ></AgGridReact>
          </GContentGrid>
        </ContentSection>
      </DialogContent>
      <DialogActions className="popupBottom">
        <GButton
          txt="취소"
          sizes="medium"
          onClick={handleClose}
          btnstyled="outline"
          btnColor="normal"
          hasImg={false}
          hasTxt={true}
        />
        <GButton
          txt="선택"
          sizes="medium"
          onClick={handleOnSave}
          chkImg={Save}
          btnstyled="contained"
          btnColor="primary"
          hasImg={true}
          hasTxt={true}
        />
      </DialogActions>
    </ModalLayout>
  );
};

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 16px;

  background-color: #f7f9f8;
  border: 1px solid #dde0df;
  border-radius: 2px;
  margin-bottom: 14px;

  box-sizing: border-box !important;
`;

const ButtonWrap = styled.div`
  margin-left: 24px;
  align-self: end;

  & button + button {
    margin-left: 4px;
  }
`;

const UnHiddenSearchWrap = styled.div`
  display: flex;
`;

const SearchWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  gap: 8px 24px;
`;

const InputWrap = styled.div`
  display: flex;
  gap: 8px;
  width: 48%;

  > div:nth-child(1) {
    min-width: 31%;
  }

  > div:nth-child(2) {
    width: 54%;
  }
`;

export default InsuranceModal;
