import { Dayjs } from 'dayjs';

export const formatRate = (rate: number | string, fixed?: number) => {
  if (typeof rate === 'string') {
    rate = parseFloat(rate);
  }

  if (rate) {
    const fixedRate = Number(rate.toFixed(fixed || 0));
    return fixedRate;
  }
  return 0;
};

export const formatAmount = (amount: number | string, fixed?: number) => {
  if (typeof amount === 'string') {
    amount = parseFloat(amount);
  }

  if (amount) {
    const formatted = Number(amount.toFixed(fixed || 0));
    return formatted;
  }
  return 0;
};

export const formatCurrency = (currency: number | string, sign: string, fixed?: number) => {
  if (typeof currency === 'string') {
    currency = parseFloat(currency);
  }

  const result = currency
    ? currency.toLocaleString(undefined, { minimumFractionDigits: fixed || 0 })
    : '0';
  return sign + result;
};

export const formatDateString = (dateString: string) => {
  return dateString && dateString.length == 8
    ? dateString.replace(/(\d{4})(\d{2})(\d{2})/g, '$1.$2.$3')
    : dateString;
};

export const formatDateStringPeriod = (dateString: string) => {
  return dateString && dateString.length == 8
    ? dateString.replace(/(\d{4})(\d{2})(\d{2})/g, '$1.$2.$3')
    : dateString;
};

export const formatMonthString = (dateString: string) => {
  if (!dateString || dateString === '000000') {
    return '';
  }
  return dateString && dateString.length == 6
    ? dateString.replace(/(\d{4})(\d{2})/g, '$1.$2')
    : dateString;
};

export const formatDateTimeString = (dateTimeString: string) => {
  return dateTimeString && dateTimeString.length == 14
    ? dateTimeString.replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/g, '$1.$2.$3 $4:$5:$6')
    : dateTimeString;
};

export const formatCdNm = (cd?: string, nm?: string, onlyShowName?: boolean): string => {
  return onlyShowName ? `${nm}` : cd && nm ? `[${cd}] ${nm}` : nm ? `${nm}` : '';
};

export const dayjsToDateString = (date: Dayjs) => {
  let dateString = '';
  if (date) {
    dateString = date.format('YYYYMMDD');
  }
  return dateString === 'Invalid Date' ? '' : dateString;
};
