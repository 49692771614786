import { create } from 'zustand';

import React from 'react';
import styled from '@emotion/styled';
import GProgress from 'components/status/GProgress';

interface LoadingStore {
  isLoading: boolean;
  openLoading: (b: boolean) => void;
}

export const useLoading = create<LoadingStore>((set, get) => {
  return {
    isLoading: false,
    openLoading: (e) => {
      set({ isLoading: e });
    },
  };
});

export const Loading = () => {
  const { isLoading } = useLoading();

  return (
    <>
      {
        <StyledContainer hidden={!isLoading}>
          <GProgress />
        </StyledContainer>
      }
    </>
  );
};

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10000;
  text-align: center;
`;
