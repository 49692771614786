import styled from '@emotion/styled';
import { Color, BgColor, FontColor } from 'ui/theme/Color';
import React, { CSSProperties, ReactNode } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { InfoIcon } from 'components/icons/Icons';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { GTooltipOverCust, TooltipCust } from 'components/etc/GTooltipOverCust';
import { GInputIconButton } from 'components/buttons/GInputIconButton';

import { useTranslation } from 'react-i18next';

export const GGuideTitle = styled.div`
  padding: 10px;
  margin: 30px 0px 10px;
  font-size: 13px;
  color: ${({ theme }) => (theme.palette.mode == 'dark' ? 'red' : Color.Grey600)};
  border-bottom: 1px solid ${Color.Grey100};
`;

export const GDevComment = styled.div`
  display: inline-block;
  padding: 10px;
  margin: 10px;
  font-size: 12px;
  line-height: 18px;
  color: #ff6600;
  background: #fff3e9;
  border: 1px solid #ff6600;
  border-radius: 10px;
  & span {
    display: inline-block;
    color: #ffffff;
    border-radius: 20px;
    background: #ff6600;
    font-weight: 700;
    padding: 5px 10px;
    margin-bottom: 10px;
  }
`;

export const GDesignComment = styled.div`
  display: inline-block;
  padding: 10px;
  margin: 10px;
  font-size: 12px;
  line-height: 18px;
  color: #1f1f1f;
  background: #f4fbfd;
  border: 1px solid #1f1f1f;
  border-radius: 10px;
  & span {
    display: inline-block;
    color: #ffffff;
    border-radius: 20px;
    background: #1f1f1f;
    font-weight: 700;
    padding: 5px 10px;
    margin-bottom: 10px;
  }
`;

export const Red = styled.span`
  color: red;
  padding: 0 5px;
  font-size: 13px;
  font-weight: 500;
`;

export const GGroupBoxTop = styled.div`
  display: flex;
  justify-content: space-between;
  height: 28px;
  margin-top: 12px;
  margin-bottom: 4px;
`;

export const GGroupBoxBottom = styled.div`
  display: flex;
  justify-content: end;
  height: 32px;
  margin-top: 4px;
`;

export const GTitleBox = styled.div`
  font-family: 'Spoqa Han Sans Neo';
  // & {
  //   padding-top: 12px;
  //   padding-bottom: 4px;
  // }
  & .title {
    display: inline-block;
    height: 28px;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => (theme.palette.mode == 'dark' ? Color.White : Color.Grey900)};
    margin-top: auto;
    vertical-align: middle;
    // font-family: 'Spoqa Han Sans Neo';
    font-weight: 700;
    padding-right: 8px;
  }
  & .comment {
    height: 28px;
    font-size: 13px;
    color: ${Color.Primary600};
  }
  & .commentSub {
    font-weight: 400;
    font-size: 13px;
    color: #1f1f1f;
  }
  & select {
    margin-left: 8px;
  }
  & .commentSub.page {
    margin-left: 4px;
  }
  & .commentEnd {
    font-weight: 400;
    font-size: 13px;
    color: #979998;
  }
`;

export const GButtonTopBox = styled.div`
  & > button + button {
    margin-left: 4px;
  }
`;

export const GButtonBox = styled.div`
  & > button + button {
    margin-left: 4px;
  }
`;

export const GSearchBox = styled.div`
  position: relative;
  font-size: 13px;
  font-weight: bold;
  color: ${({ theme }) => (theme.palette.mode == 'dark' ? Color.White : Color.Grey900)};
  position: relative;
  background: ${({ theme }) => (theme.palette.mode == 'dark' ? BgColor.Gray700 : Color.Grey50)};
  border: 1px solid ${Color.Grey300};
  border-radius: 2px;
  & table + table {
    margin-top: 8px;
  }
`;

export const GSearchBoxDetail = styled.div`
  position: relative;
  font-size: 13px;
  font-weight: bold;
  color: ${Color.Grey900};
  position: relative;
  background: ${Color.Grey50};
  border: 1px solid ${Color.Grey300};
  border-radius: 2px;
  // padding: 0px 144px 0px 12px;
  min-height: 77px;
  & table + table {
    margin-top: 7px;
  }
`;

export const GDivider = styled.div`
  position: absolute;
  background: ${Color.Grey300};
  height: 1px;
  left: 0px;
  top: 90px;
  margin: 0 12px;
  width: calc(100% - 24px);
`;

export const GSearchTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 8px;
  // table-layout: fixed;

  th {
    vertical-align: middle;
    color: ${Color.Grey900};
    text-align: right;
    font-weight: 700;
    font-size: 13px;
    height: 32px;
  }

  td {
    color: ${Color.Grey900};
    vertical-align: middle;
    font-size: 13px;
    text-align: left;
    font-weight: 400;
    padding: 0px 0px 0px 8px;
    vertical-align: middle;
    height: 32px;

    input {
      width: 100%;
    }
    select {
      width: 100%;
    }

    & > span {
      padding: 0 4px;
    }
  }

  &.lineFill tr:nth-of-type(2n) {
    background-color: ${Color.Grey50};
  }

  input + button,
  div + button,
  input + input {
    margin-left: 8px;
  }

  div {
    input + button {
      margin-left: 0;
    }
  }
`;

// ------------------------------------------------------------------------
const LabelClass = styled.div`
  & {
    display: inline-block;
    font-weight: 700;
    font-size: 13px;
    color: ${({ theme }) => (theme.palette.mode == 'dark' ? Color.White : '#1f1f1f')};
    position: relative;
    line-height: 18px;
  }
  & div {
    display: inline-block;
  }

  & .req {
    display: inline-block;
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    left: -5px;
    background-color: #f94b50;
  }

  & .labelIcon {
    display: inline-block;
    border: none;
    background: none;
    vertical-align: middle;
    outline: none;
    cursor: pointer;
    padding: 0px !important;
    margin-top: -3px;
    margin-left: 1px;
    width: 16px !important;
    height: 16px !important;
  }

  // & .labelIcon .info {
  //   width: 16px;
  //   height: 16px;
  //   margin: 0;
  //   // margin-right: 20px;
  // }
`;

// ------------------
interface LabelProps {
  children?: ReactNode;
  pTxt?: string | undefined;
  pPlace?: string | undefined;
  isRequired?: boolean;
  isInfo?: boolean;
  style?: CSSProperties;
}

export const GLabel = (props: LabelProps) => {
  return (
    <LabelClass className="label">
      {props.isRequired && <span className="req"></span>}
      {props.children}
      <GTooltipOverCust pTxt={props.pTxt} pPlace={props.pPlace}>
        {props.isInfo && <button className="labelIcon"></button>}
      </GTooltipOverCust>
    </LabelClass>
  );
};
// ------------------

interface LabelWithToolTipProps {
  children?: JSX.Element | string | undefined;
  pTxt: string | undefined;
  pPlace?: string | undefined;
  isRequired?: boolean;
}

export const GLabelInfoWithToolTip = (props: LabelWithToolTipProps) => {
  return (
    <LabelClass>
      <div style={{ marginRight: '30px' }}>
        {props.isRequired && <span></span>}
        {props.children}
        <GTooltipOverCust pTxt={props.pTxt} pPlace={props.pPlace}>
          <button>
            <InfoIcon />
          </button>
        </GTooltipOverCust>
      </div>
    </LabelClass>
  );
};

// ----------------------------------------------------------------------------

interface TitleProps {
  // 1은 useTranslation을 쓰는 경우
  // 2는 일반적인 Title인 경우
  // 사용할 props만 값을 할당해주면 됩니다.
  title1?: string | null;
  title2?: string | null;
  isNotTitle?: boolean;
}

export const GPopupTitle = (props: TitleProps) => {
  const { t } = useTranslation();
  return (
    <GPopupTit>
      {props.title1 && <span>{t(`label.com.${props.title1}`, `${props.title1}`)}</span>}
      {props.title2 && <span>{props.title2}</span>}
      {props.isNotTitle && <span></span>}
    </GPopupTit>
  );
};

export const GPopupTit = styled.div`
  display: flex;
  align-content: center;
  height: 32px;
  line-height: 32px;

  span {
    font-size: 18px;
    color: ${FontColor.Primary700};
    font-weight: 700;
    font-family: 'Spoqa Han Sans Neo';
  }

  span::before {
    content: '';
    display: inline-block;
    vertical-align: 3px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${FontColor.Primary};
    margin-right: 8px;
  }

  .comment {
    margin-left: 8px;
    font-size: 12px;
    line-height: 28px;
    color: ${Color.Primary600};
  }
  .commentSub {
    font-size: 12px;
    line-height: 28px;
    color: #1f1f1f;
    margin-left: 4px;
  }

  .close {
    color: ${Color.Grey700};
    display: inline-block;
    position: relative;
    margin-top: 4px;
  }
`;

export function GPagination() {
  return (
    <Stack spacing={2}>
      <Pagination
        count={10}
        showFirstButton
        showLastButton
        sx={{
          button: {
            fontSize: '13px',
            '&.MuiButtonBase-root.MuiPaginationItem-root.Mui-selected': {
              backgroundColor: '#1f1f1f',
              color: '#FFFFFF',
            },
          },
        }}
      />
    </Stack>
  );
}

// empty state
export const GEmptyState = styled.div`
  height: 100%;
  text-align: center;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  .empty {
    width: 56px;
    height: 56px;
    margin: 0 auto;
  }
  .infoTitle {
    padding: 8px 0;
    font-size: 15px;
    font-weight: 700;
    color: #979998;
  }
  .infoSub {
    font-size: 13px;
    font-weight: 400;
    color: #979998;
    line-height: 19.5px;
  }
`;

// Nodata state
export const GNodataState = styled.div`
  width: 100%;
  height: 100%;
  background: #f7f9f8;
  text-align: center;
  vertical-align: middle;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  .nodata {
  }
  .infoTitle {
    padding: 8px 0;
    font-size: 15px;
    font-weight: 700;
    color: #979998;
  }
`;
// DataSelInfo
export const DataSelInfo = styled.div`
  width: 100%;
  height: 100%;
  background: #f7f9f8;
  text-align: center;
  vertical-align: middle;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  .dataSelInfoImg {
  }
  .infoTitle {
    padding: 8px 0;
    font-size: 15px;
    font-weight: 700;
    color: #979998;
  }
`;
