import styled from '@emotion/styled';
import { css } from '@mui/material';
import { FontColor, BorderColor, BgColor, BasicColor } from 'ui/theme/Color';

export const CustomInputText = styled.input`
  height: 32px;
  padding: 0 10px;
  border-radius: 3px;
  border: solid 1px ${BorderColor.Form};
  background-color: ${BgColor.White};
  font-size: 13px;
  font-family: 'Spoqa Han Sans Neo', '맑은 고딕', 'malgun gothic', 'AppleGothicNeoSD',
    'Apple SD 산돌고딕 Neo', 'Microsoft NeoGothic', 'Droid sans', sans-serif;
  color: ${FontColor.Default};
  vertical-align: middle;

  &:focus,
  &:hover,
  &:active {
    outline: 0;
  }

  &:disabled {
    background-color: ${BgColor.Gray50};
    color: ${FontColor.Gray400};
  }
  &::placeholder {
    color: ${FontColor.Gray200};
  }

  &.fullWidth {
    width: 100% !important;
  }
  &.halfWidth {
    width: 43.777777% !important;
  }
  &.fullWidthCell {
    width: 100% !important;
    height: 28px !important;
    horizontal-align: middle;
  }

  &.widhButton {
    width: calc(100% - 30px);
  }

  &:read-only {
    background-color: ${BgColor.Gray50};
  }

  &.width280 {
    min-width: 280px;
    width: 280px;
  }

  &.width200 {
    min-width: 200px;
    width: 200px;
  }

  &.width120 {
    min-width: 120px;
    width: 120px;
  }

  &.Width110 {
    min-width: 110px;
    width: 110px;
  }

  &.width30p {
    min-width: 30%;
    width: 30%;
  }

  &.marginL0 {
    margin-left: 0;
  }

  &.inline-flex {
    display: inline-flex;
  }

  & + button {
    vertical-align: middle;
  }

  label + & {
    margin-left: 10px;
  }
`;

export const CustomTextarea = styled.textarea`
  min-height: 80px;
  width: 100%;
  padding: 10px;
  border-radius: 3px;
  border: solid 1px ${BorderColor.Form};
  background-color: ${BgColor.White};
  font-size: 13px;
  font-family: 'Spoqa Han Sans Neo', '맑은 고딕', 'malgun gothic', 'AppleGothicNeoSD',
    'Apple SD 산돌고딕 Neo', 'Microsoft NeoGothic', 'Droid sans', sans-serif;
  color: ${FontColor.Default};
  resize: none;
  line-height: 1.4;

  &:focus,
  &:hover,
  &:active {
    outline: 0;
  }
  &:disabled {
    background-color: ${BgColor.Gray50};
    color: ${FontColor.Gray400};
  }
  &::placeholder {
    color: ${FontColor.Gray200};
  }
  &.height200 {
    height: 200px;
  }
`;

export const CustomInputWrap = styled.div`
  position: relative;
  width: fit-content;

  input {
    width: 100% !important;
    padding: 0 30px;
    margin-left: 0 !important;

    &.widthAuto {
      width: auto !important;
    }
  }

  button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &.buttonFind {
      left: 0;

      &.right0 {
        right: 0px;
      }
      &.left {
        left: 5px;
        right: auto;
      }
    }
    &.buttonDel {
      right: 0px;
    }

    svg {
      fill: ${FontColor.Default};
    }
  }

  &.withButton {
    display: inline-flex;
    width: calc(100% - 80px);
  }

  &.inline-flex {
    display: inline-flex;
  }

  &.fullWidth {
    width: 100% !important;
  }

  &.width30p {
    width: 30% !important;
  }

  &.flexWidth {
    width: 30% !important;
  }

  &.width280 {
    min-width: 280px;
    width: 280px;
  }
`;

export const st = {
  checkbox: css`
    margin-right: 5px;
    padding: 0;
    color: ${BorderColor.Form};

    .MuiSvgIcon-root {
      width: 20px;
      height: 20px;
    }

    &.Mui-checked {
      color: #2d9bb2;
    }
  `,
  radio: css`
    padding-right: 3px;
    color: ${BorderColor.Form};
    &.Mui-checked {
      color: #2d9bb2;
    }

    &.purple.Mui-checked {
      color: ${BasicColor.Secondary};
    }
  `,
  label: css`
    height: 32px;

    .MuiFormControlLabel-label {
      font-size: 13px;
      font-weight: 400;
    }

    &.displayFlex {
      display: flex;
      flex: 1 0 100%;
    }

    &.marginR0 {
      margin-right: 0;
    }

    &.marginL0 {
      margin-left: 0;
    }

    & + .buttonHelp {
      margin-right: 16px;
    }
  `,
  popover: css`
    .MuiPopover-paper {
      padding: 15px;
      background-color: #000;
      opacity: 0.9 !important;
      max-width: 300px;
    }

    .popoverCont {
      color: #fff;
      font-size: 13px;
      line-height: 1.4;
    }
  `,
};

export const SearchInputWrap = styled.div`
  position: relative;
  width: 480px;
  height: 48px;
  margin: 0 auto 12px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08), 0px 0px 1px 0px rgba(0, 0, 0, 0.11);

  input {
    width: 100%;
    height: 100%;
    padding: 0 50px 0 16px;
    border: 0;
    font-size: 18px;

    &:focus {
      border: 1px solid #6e706f;

      & + .buttonDel {
        display: block;
      }
    }
  }

  button {
    position: absolute;
    top: 50%;
    margin: 0;
    transform: translateY(-50%);

    &.buttonFind {
      width: 36px;
      height: 36px;
      right: 14px;

      &:hover {
        background-color: #ecfcf8;
      }
      &:focus {
        background-color: #d9fcf4;
      }
    }
    &.buttonDel {
      display: none;
      line-height: 1;
      right: 55px;
    }
  }
`;
