import { IGetRowsParams } from 'ag-grid-community';
import { useLoading } from 'components/process/Loading';
import { PaginationResponse } from 'models/common/Pagination';
import { useMemo } from 'react';

type FetchFn<T> = (searchParams: any) => Promise<PaginationResponse<T>>;

const pageSize = 50;

export const useInfiniteDataSource = <T>(
  searchParams: any,
  fetchFn: FetchFn<T>,
  recvCallback?: (totalCount: number, list: T[]) => void
) => {
  const dataSource = useMemo(() => {
    const makeSearchCondition = (searchParams: any, startRow: number) => {
      return { ...searchParams, start: `${startRow}`, pageSize: `${pageSize}` };
    };

    return {
      getRows: (params: IGetRowsParams) => {
        console.log(params);
        console.log(searchParams);
        fetchFn(makeSearchCondition(searchParams, params.startRow))
          .then((data) => {
            console.log(data.list);
            const list: T[] = data.list;
            const totalCount = data?.totalCount || 0;
            if (list) {
              const lastRow = params.endRow >= totalCount ? totalCount : -1;
              params.successCallback(list, lastRow);
              recvCallback && recvCallback(totalCount, list);
            } else {
              params.failCallback();
            }
          })
          .catch((err) => {
            console.log(err);
            params.failCallback();
          });
      },
    };
  }, [searchParams, pageSize, recvCallback]);
  return { dataSource, pageSize };
};
