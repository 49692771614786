/** @jsxImportSource @emotion/react */
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { getCommonCodes } from 'apis/admin/CommonCode';
import { findMessagesMsgCtn, saveMessages } from 'apis/admin/Message';
import { useMessageModalStore } from 'stores/useMessageModalStore';
import { Message, MessageInfo } from 'models/admin/Message';
import { CommonYN } from 'models/common/Common';
import { useCommonModal } from 'hooks/useCommonModal';
import { CrudCode } from 'models/common/Edit';
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  DialogActions,
} from '@mui/material';
import styled from '@emotion/styled';
import { FontColor, GlobalTokenColor } from 'ui/theme/Color';
import { DialogTitleItem } from 'components/layouts/GModalPopup';
import { GPopupTitle } from 'components/layouts/GLayoutBox';
import { GbasicTable, GbasicTableWrap } from 'components/tables/GTable';
import { GLabelAtom } from 'components/atom/label';
import InputField from 'components/atom/input';
import { useMessageManagementStore } from 'stores/useMessageManagementStore';
import TextArea from 'components/atom/textarea';
import GSelectMUIAtom from 'components/common/select/GSelectMUIAtom';
import { GButtonBoxCum } from 'components/common/SearchMolecule';
import { GButton } from 'components/atom/button';
import Img from 'components/asset/images/Confirm.svg';
import { useMessageBar } from 'components/process/MessageBar';

const getEmptyMessageInfo = () => {
  return {
    msgCtn: '',
    msgTxtCtn1: '',
    msgTxtCtn2: '',
    msgTxtCtn3: '',
    msgTxtCtn4: '',
    msgTxtCtn5: '',
    rmk: '',
    useYn: CommonYN.Y,
  } as MessageInfo;
};

export const MessageModal = () => {
  const { t } = useTranslation();
  const { openCommonModal } = useCommonModal();
  const {
    isOpen,
    msgCtn,
    animation,
    koMessage,
    yesCallback,
    noCallback,
    setMessageModalStateWhenModalClose,
  } = useMessageModalStore();
  const { findMessagesWithLastSearchedCondition } = useMessageManagementStore();

  // const { languageCodes } = useMessageManagementStore();
  const { openMessageBar } = useMessageBar();
  const [languageCodes, setLanguageCodes] = useState<any[]>([]);
  const [messages, setMessages] = useState<Message[]>([]);
  const [msgInfo, setMsgInfo] = useState<MessageInfo>(getEmptyMessageInfo());

  useEffect(() => {
    if (isOpen) {
      if (_.isUndefined(msgCtn)) {
        openCommonModal({
          content: t('com.label.00700', '__다시 시도해주세요.'),
        });
        setMessageModalStateWhenModalClose();
        return;
      }

      (async () => {
        // const languageCodesApiResult = [
        //   ...((await (await getCmnCdsByGrCds([CmnGrCd.LANG_CD])).data.LANG_CD) ?? []),
        // ];
        const languageCodesApiResult = [
          ...((await (await getCommonCodes('LANG_CD')).filter((item) => item.useYn === 'Y')) ?? []),
        ];
        const messagesApiResult = [...((await findMessagesMsgCtn({ msgCtn: msgCtn })) ?? [])];

        setLanguageCodes([...languageCodesApiResult]);
        setMessages([...messagesApiResult]);

        if (msgCtn && (_.isEmpty(languageCodesApiResult) || _.isEmpty(messagesApiResult))) {
          openCommonModal({
            modalType: 'confirm',
            content: t('com.msg.failMsgCd', '__해당하는 메시지코드가 없습니다. 추가하시겠습니까?'),
            noCallback: () => {
              setMessageModalStateWhenModalClose();
              return;
            },
          });
        }

        setMsgInfo(() => {
          const msgInfoResult = {
            msgCtn: msgCtn ?? '',
            useYn: messagesApiResult[0]?.useYn ?? CommonYN.Y,
            rmk: messagesApiResult[0]?.rmk ?? '',
          } as MessageInfo;

          for (let i = 0; i < languageCodesApiResult.length; i++) {
            const languageCode = languageCodesApiResult[i].cmnCd ?? '';
            const findMessages = messagesApiResult.filter(
              (message) => message.langCd === languageCode
            );

            if (findMessages.length !== 0) {
              msgInfoResult[`msgTxtCtn${i + 1}`] = findMessages[0].msgTxtCtn;
            } else {
              msgInfoResult[`msgTxtCtn${i + 1}`] = '';
            }
          }
          if (koMessage && koMessage !== '') {
            msgInfoResult['msgTxtCtn1'] = koMessage;
          }
          return msgInfoResult;
        });
      })();

      document.body.style.top = `-${window.pageYOffset}px`;
      document.body.style.position = 'fixed';
      document.body.style.overflow = 'hidden';
      document.body.style.width = '100%';
    } else {
      setMsgInfo(getEmptyMessageInfo());
    }

    return () => {
      const heightScroll = parseInt(document.body.style.top || '0', 10) * -1;
      document.body.style.removeProperty('position');
      document.body.style.removeProperty('overflow');
      document.body.style.removeProperty('width');
      document.body.style.removeProperty('top');
      window.scrollTo(0, heightScroll);
    };
  }, [isOpen]);

  const handleNoButtonClick = useCallback(async () => {
    setMessageModalStateWhenModalClose();
    if (noCallback) {
      noCallback();
    }
  }, [noCallback, setMessageModalStateWhenModalClose]);

  const handleYesButtonClick = useCallback(async () => {
    const { rmk, useYn, msgCtn, ...restOfMsgTxtCtn } = msgInfo;

    const messagesToSave = [] as Message[];
    openCommonModal({
      modalType: 'confirm',
      content: t('com.msg.ynSave', '__저장하시겠습니까?'),
      noCallback: () => {
        return false;
      },
      yesCallback: async () => {
        if (msgCtn === '') {
          openMessageBar('error', t('com.msg.mndMsgCd', '__메시지코드는 필수 입력 항목입니다.'));
          return false;
        }

        for (let i = 0; i < languageCodes.length; i++) {
          const targetLangCd = languageCodes[i].cmnCd || '';
          const targetMsgTxtCtn = _.get(restOfMsgTxtCtn, `msgTxtCtn${i + 1}`);

          if (!_.isEmpty(targetMsgTxtCtn)) {
            messagesToSave.push({
              crudKey: _.isEmpty(messages.find((message) => message.langCd === targetLangCd))
                ? CrudCode.CREATE
                : CrudCode.UPDATE,
              msgCtn: msgCtn,
              langCd: targetLangCd,
              msgTxtCtn: targetMsgTxtCtn || '',
              rmk: rmk,
              useYn: useYn === CommonYN.N ? CommonYN.N : CommonYN.Y,
              optValCtn1: null,
              optValCtn2: null,
              optValCtn3: null,
              dataInsUserId: null,
              dataInsUserIp: null,
              dataInsDtm: null,
              dataUpdUserId: null,
              dataUpdUserIp: null,
              dataUpdDtm: null,
            });
          } else {
            messagesToSave.push({
              crudKey: CrudCode.DELETE,
              msgCtn: msgCtn,
              langCd: targetLangCd,
              msgTxtCtn: targetMsgTxtCtn || '',
              rmk: rmk,
              useYn: useYn === CommonYN.N ? CommonYN.N : CommonYN.Y,
              optValCtn1: null,
              optValCtn2: null,
              optValCtn3: null,
              dataInsUserId: null,
              dataInsUserIp: null,
              dataInsDtm: null,
              dataUpdUserId: null,
              dataUpdUserIp: null,
              dataUpdDtm: null,
            });
          }
        }
        const msgTxtCtns = messagesToSave.every((item) => {
          return item.msgTxtCtn === '';
        });

        if (msgTxtCtns) {
          openMessageBar(
            'error',
            t(
              'com.msg.mndMsgCtnTxt',
              '__한국어, 영어, 중국어(중국) 중 최소 1개는 필수 입력 항목입니다.'
            )
          );
          return;
        }

        const saveApiResult = await saveMessages(messagesToSave);

        if (saveApiResult) {
          setMessageModalStateWhenModalClose();
          if (yesCallback) {
            findMessagesWithLastSearchedCondition();
            openMessageBar('success', t('com.msg.scsSave', '__저장되었습니다.'));
          }
        } else {
          openMessageBar('error', t('com.msg.failSave', '__저장에 실패했습니다.'));
        }
      },
    });
  }, [yesCallback, setMessageModalStateWhenModalClose, msgInfo, languageCodes]);

  return (
    <>
      {isOpen && (
        <DialogItem open={isOpen} fullWidth>
          <DialogTitleItem id="customized-dialog-title" onClose={handleNoButtonClick}>
            <GPopupTitle title2={t('com.label.00590', '__메시지 관리')} />
          </DialogTitleItem>
          <DialogContent className="popupContent">
            <GbasicTableWrap>
              <GbasicTable>
                <colgroup>
                  <col style={{ width: '160px' }} />
                  <col style={{ width: 'auto' }} />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <GLabelAtom isRequired={true} align="left">
                        {t('com.label.msgCd', '__메시지코드')}
                      </GLabelAtom>
                    </th>
                    <td>
                      <InputField
                        status={msgCtn !== null && msgCtn !== '' ? 'disabled' : 'default'}
                        align="left"
                        width="210px"
                        type="text"
                        placeholder={t('com.label.mgt.1029', '__메시지코드를 입력하세요') as string}
                        value={msgInfo.msgCtn}
                        disabled={msgCtn !== null && msgCtn !== ''}
                        onChange={(e) => {
                          setMsgInfo({ ...msgInfo, msgCtn: e.target.value });
                        }}
                      />
                    </td>
                  </tr>
                  {languageCodes.map((languageCode, index) => {
                    const langName = languageCode.cmnCdNm;
                    const langCd = languageCode.cmnCd;
                    return (
                      <tr key={`msgTxtCtn${index + 1}`}>
                        <th scope="row">
                          <GLabelAtom align="left">
                            {t(`com.code.LANG_CD.${langCd}`, `${langName}`)}
                          </GLabelAtom>
                        </th>
                        <td>
                          <TextArea
                            status="default"
                            height="90px"
                            id={`msgTxtCtn${index + 1}`}
                            className="fullWidth"
                            name={`msgTxtCtn${index + 1}`}
                            key={`msgTxtCtn${index + 1}`}
                            value={msgInfo[`msgTxtCtn${index + 1}`]}
                            placeholder={
                              t(`com.code.LANG_CD.${langCd}`, `${langName}`) +
                              ' ' +
                              t(`com.label.mgt.1030`, '__메시지를 입력하세요')
                            }
                            onChange={(e) => {
                              setMsgInfo((prevState) => {
                                _.set(prevState, `msgTxtCtn${index + 1}`, e.target.value);
                                return { ...prevState };
                              });
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                    <th>
                      <GLabelAtom align="left">{t('com.label.rmk', '__비고')}</GLabelAtom>
                    </th>
                    <td>
                      <div>
                        <TextArea
                          placeholder={t('com.label.rmkvali', '__비고를 입력하세요') as string}
                          status="default"
                          width="100%"
                          height="90px"
                          value={msgInfo.rmk}
                          onChange={(e) => {
                            setMsgInfo((prevState) => {
                              return { ...prevState, rmk: e.target.value };
                            });
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <GLabelAtom align="left" isRequired={true}>
                        {t('com.label.useYn', '__사용여부')}
                      </GLabelAtom>
                    </th>
                    <td>
                      <div>
                        <GSelectMUIAtom
                          option={[CommonYN.Y, CommonYN.N]}
                          value={msgInfo.useYn}
                          handleChange={(e) => {
                            setMsgInfo((prevState) => {
                              return { ...prevState, useYn: e.target.value as CommonYN };
                            });
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </GbasicTable>
            </GbasicTableWrap>
          </DialogContent>
          <DialogActions className="popupBottom">
            <GButtonBoxCum>
              <GButton
                txt={t('com.btn.cancel', '취소')}
                sizes="large"
                btnstyled="outline"
                btnColor="normal"
                hasImg={false}
                hasTxt={true}
                onClick={() => {
                  openCommonModal({
                    modalType: 'confirm',
                    content: (
                      <pre>
                        {`${t('com.msg.noSave', '__작성 중인 내용이 저장되지 않습니다.')}\n${t(
                          'com.msg.ynCncl',
                          '__그래도 화면을 닫으시겠습니까?'
                        )}`}
                      </pre>
                    ),
                    noCallback: () => {
                      return;
                    },
                    yesCallback: () => {
                      handleNoButtonClick();
                    },
                  });
                }}
              ></GButton>
              <GButton
                txt={t('com.btn.save', '저장')}
                chkImg={Img}
                sizes="large"
                btnstyled="contained"
                btnColor="primary"
                hasImg={true}
                hasTxt={true}
                onClick={handleYesButtonClick}
              ></GButton>
            </GButtonBoxCum>
          </DialogActions>
        </DialogItem>
      )}
    </>
  );
};

const DialogItem = styled(Dialog)`
  font-family: 'Spoqa Han Sans Neo';
  h2#customized-dialog-title {
    padding: 24px 18px 24px 24px;
  }
  .MuiFormGroup-root.MuiFormGroup-row.MuiFormGroup-root {
    margin-left: -4px;
  }
  .MuiDialogActions-root > :not(style) ~ :not(style) {
    margin-left: 4px;
  }
  .MuiDialog-paper {
    min-width: 600px;
    max-width: 50%;
    box-sizing: content-box;
    box-shadow: none;
    border-radius: 3px;
    border: 1px solid ${GlobalTokenColor.ESGrey400};
    color: ${FontColor.Default};
    word-break: keep-all;
  }

  .popupContent {
    padding: 0 24px;
    font-size: 13px;
    line-height: 19px;

    .searchBox {
      margin-bottom: 40px;
    }
  }

  .popupBottom {
    padding: 12px 24px 24px;
  }

  .buttonsTop {
    text-align: right;
    & + .section {
      margin-top: 20px;
    }
  }

  .ag-body-vertical-scroll-viewport {
    width: 17px !important;
    max-width: 17px !important;
    min-width: 17px !important;
    position: absolute;
    top: -24px;
    left: 0;
    height: calc(100% + 24px);
  }
`;
