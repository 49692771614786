import styled from '@emotion/styled';
import { GButton } from 'components/atom/button';
import EyeCount from 'components/atom/eyeCount';
import ReplyCount from 'components/atom/replyCount';
import { GButtonBox } from 'components/layouts/GLayoutBox';
import GButtonLayout from 'components/molecule/GbuttonLayout';
import SubTitleBox from 'components/molecule/SubTitleBox';
import WriteInfo from 'components/molecule/WriteInfo';
import Reply from 'components/organism/Reply';
import Delete from 'components/asset/images/Delete.svg';
import List from 'components/asset/images/List.svg';
import Avatar from 'components/asset/images/Avatar.png';
import ReplyArrow from 'components/asset/images/ReplyArrow.svg';
import TextArea from 'components/atom/textarea';
import ReplyArea from 'components/molecule/ReplyArea';

const QnaDetail = () => {
  const imagePath = ReplyArrow;

  return (
    <>
      <Title>텍스트타이틀 영역입니다.</Title>
      <WriteInfo />
      <Box>
        <QnaContents>Qna상세의 본문내용입니다.</QnaContents>
        <CountWrap>
          <EyeCount count={2345} />
          <ReplyCount count={3} />
        </CountWrap>
      </Box>
      <GButtonLayout>
        <GButtonBox>
          <GButton
            chkImg={List}
            txt="목록"
            sizes="small"
            btnstyled="outline"
            btnColor="normal"
            hasImg={true}
            hasTxt={true}
          ></GButton>
          <GButton
            chkImg={Delete}
            txt="삭제"
            sizes="small"
            btnstyled="outline"
            btnColor="normal"
            hasImg={true}
            hasTxt={true}
          ></GButton>
        </GButtonBox>
      </GButtonLayout>
      <ReplyWrap>
        <SubTitleBox showSubTitle={true} content="댓글" commentCount={3} isShowSumCount={true} />
        <ReplyContentWrap>
          <CommentField>
            <ProfileInfo>
              <WriterProfile src={Avatar} />
              <Nickname>장엔솔 선임</Nickname>
            </ProfileInfo>
            <TextArea
              status="default"
              width="100%"
              height="auto"
              placeholder="댓글을 입력해 주세요."
              isComment={true}
              count="120 / 500"
            />
          </CommentField>
          <ReplyItemWrap>
            <Reply
              profileImg={Avatar}
              nickName="김엔솔 선임"
              position="스마트팩토리 제조설비관리팀"
              date="2023-06-28 오후 05:58"
              contentText="댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. "
            />
            {/* 대댓글용 컨테이너입니다. */}
            <ReReplyContainer>
              <ReReplyWrap>
                <ReReply src={ReplyArrow} alt="Reply Arrow" /> {/* 대댓글용 화살표입니다. */}
                <Reply
                  profileImg={Avatar}
                  nickName="장엔솔 선임"
                  position="스마트팩토리 제조설비관리팀"
                  imgUpload={true}
                  date="2023-06-28 오후 05:58"
                  contentText="댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. "
                />
              </ReReplyWrap>
              <ReReplyContainer>
                <ReReplyWrap>
                  <ReReply src={ReplyArrow} alt="Reply Arrow" />
                  <ReplyArea
                    profileImg={Avatar}
                    nickName="김엔솔 선임"
                    position="스마트팩토리 제조설비관리팀"
                  />
                </ReReplyWrap>
                <ReReplyContainer>
                  <ReReplyWrap>
                    <ReReply src={ReplyArrow} alt="Reply Arrow" />
                    <ReplyArea
                      profileImg={Avatar}
                      nickName="장엔솔 선임"
                      position="스마트팩토리 제조설비관리팀"
                      reCommentIng={true}
                      reNickName="김엔솔"
                    />
                  </ReReplyWrap>
                </ReReplyContainer>
              </ReReplyContainer>
            </ReReplyContainer>
          </ReplyItemWrap>
          <ReplyItemWrap>
            <Reply
              profileImg={Avatar}
              nickName="장엔솔 선임"
              position="스마트팩토리 제조설비관리팀"
              date="2023-06-28 오후 05:58"
              contentText="댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. 댓글의 내용입니다. "
            />
          </ReplyItemWrap>
        </ReplyContentWrap>
      </ReplyWrap>
    </>
  );
};

const Title = styled.h3`
  color: #1f1f1f;
  font-family: Spoqa Han Sans Neo;
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;

  padding-top: 13px;
  border-top: 1px solid #dde0df;
`;

const QnaContents = styled.div`
  min-height: 147px;
  margin-top: 24px;
  margin-bottom: 48px;
`;

const Box = styled.div`
  border-top: 1px solid #dde0df;
  border-bottom: 1px solid #dde0df;
  padding-bottom: 12px;
`;

const CountWrap = styled.div`
  display: flex;
  gap: 24px;
`;

const CommentField = styled.div`
  width: 100%;
  height: auto;
  padding: 12px;
  background: #f7f9f8;
  margin-top: 16px;
`;

const ProfileInfo = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 4px;
`;

const WriterProfile = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50px;
`;

const Nickname = styled.text`
  color: #1f1f1f;
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Spoqa Han Sans Neo;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`;

const ReplyWrap = styled.div`
  margin-top: 16px;
`;

const ReplyContentWrap = styled.ul`
  li:first-child > div:first-child {
    border-bottom: 1px solid #ebeeed;
  }
`;

const ReReplyContainer = styled.div`
  width: 100%;
  align-items: flex-start;
  gap: 8px;
`;

const ReplyItemWrap = styled.li`
  border-bottom: 1px solid #ebeeed;

  &:first-of-type {
    border-bottom: none;
  }
`;

const ReReplyWrap = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  border-bottom: 1px solid #ebeeed;
  padding-left: 11px;
`;

const ReReply = styled.img`
  padding: 24px 0px 12px 0px;
`;

export default QnaDetail;
