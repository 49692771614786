import React, { lazy, Suspense } from 'react';

const ModalContent = (props: any) => {
  const ParamModalContent = lazy(() => import(`components/modals/${props.path}`));
  return (
    <Suspense fallback={<div>loading..</div>}>
      <ParamModalContent {...props} />
    </Suspense>
  );
};

export default ModalContent;
