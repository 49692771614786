import styled from '@emotion/styled';
import { ReactNode } from 'react';
import { GlobalTokenColor } from 'ui/theme/Color';
import PageHeaderLayout from 'components/layouts/PageHeaderLayout';
import { SideMenuBar } from 'components/layouts/menu/SideMenuBar';
import { useLocation, useNavigate } from 'react-router-dom';

interface MainLayoutProps {
  children: ReactNode;
  showMenu: boolean;
}

export const MainLayout = ({ children, showMenu }: MainLayoutProps) => {
  const { pathname } = useLocation();
  return (
    <Layout>
      {showMenu && <SideMenuBar />}
      <ContentLayout path={pathname}>
        {/* {pathname != '/' && <PageHeaderLayout showMenu={showMenu} />} */}
        <PageHeaderLayout showMenu={showMenu} />
        <ContainerLayout>{children}</ContainerLayout>
      </ContentLayout>
    </Layout>
  );
};

const Layout = styled.div`
  width: 100%;
  min-height: calc(100vh - 52px);
  flex: 1;
  display: flex;
  min-width: 1366px;
`;

const ContentLayout = styled.div<{ path?: string }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  width: 100%;
  background-color: ${GlobalTokenColor.ESGreyWHite};
  padding: ${({ path }) => (path != '/' && path != '/business/login' ? '0 32px' : '')};
  // padding: 0 32px;
  overflow: auto;
`;

export const ContainerLayout = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
