import styled from '@emotion/styled';
import { useContext, useEffect, useState } from 'react';
import { GlobalTokenColor } from 'ui/theme/Color';
import { Menu, MenuContextType } from 'models/admin/Menu';
import useSessionStore from 'stores/useSessionStore';
import { MenuContext } from 'App';
import { useTranslation } from 'react-i18next';
import SideMenuList from './SideMenuList';
import SidebarToggleButton from './components/HeadLinkList/SidebarToggleButton';

export interface menuType {
  menuInfo: Menu;
  children: Menu[];
}

export const SideMenuBar = () => {
  const [isOpened, setIsOpened] = useState(true);
  const [sideMenus, setSideMenus] = useState<menuType[]>([]);

  const { menus } = useSessionStore();
  const menuContext = useContext<MenuContextType>(MenuContext);
  const { headerMenus } = useSessionStore();
  const { t } = useTranslation();

  const nest = (menuData: Menu[], mnuId = '000000', link = 'upprMnuId') =>
    menuData
      .filter((item) => item[link] === mnuId)
      .map((item) => ({ ...item, children: nest(menuData, item.mnuId) }));

  const getHeaderMenuName = (mnuId: string) => {
    const headerMenu = headerMenus.find((item) => item.mnuId === mnuId);
    return String(t(`${headerMenu?.msgCtn}`, `${headerMenu?.mnuNm}`));
  };

  useEffect(() => {
    if (menus && menuContext.selectedHeaderMenu) {
      const depth1Menus: Menu[] = menus.filter(
        (item) => item.upprMnuId === menuContext.selectedHeaderMenu && item.mnuOptValCtn5 != 'Y'
      );

      const newSideMenuList: menuType[] = [];

      depth1Menus.forEach((item) => {
        const tree = nest(menus, item.mnuId);
        newSideMenuList.push({
          menuInfo: item,
          children: tree,
        });
      });
      // if(newSideMenuList.length == 0) {
      //   menuContext.selectedHeaderMenu = ''
      // }
      setSideMenus(newSideMenuList);
    }
  }, [menuContext.selectedHeaderMenu]);

  // 메뉴가 있으면 툴팁없는 토글 버튼 제공
  // 반대로 없으면 툴팁있는 토글 버튼 제공
  const show = sideMenus.length > 0 ? true : false;

  return (
    <>
      {menuContext.selectedHeaderMenu && sideMenus.length > 0 ? (
        <SideMenuWrap isOpened={isOpened}>
          <SidebarToggleButton
            onClick={() => setIsOpened(!isOpened)}
            isOpened={isOpened}
            show={show}
            top={'-52px'}
          />

          {isOpened && (
            <>
              <SideMenuTitle>{getHeaderMenuName(menuContext.selectedHeaderMenu)}</SideMenuTitle>
              <SideMenuItemWrap>
                {sideMenus.map((it) => (
                  <SideMenuList
                    key={it.menuInfo.mnuId}
                    summary={{ menuInfo: it.menuInfo }}
                    content={it.children}
                    isActive={false}
                    depth={2}
                  />
                ))}
              </SideMenuItemWrap>
            </>
          )}
        </SideMenuWrap>
      ) : menuContext.selectedHeaderMenu === '' ? (
        ''
      ) : (
        <SidebarToggleButton
          onClick={() => {
            // setIsOpened(!isOpened)
          }}
          isOpened={false}
          show={false}
          top={'0px'}
        />
      )}
    </>
  );
};

const SideMenuWrap = styled.div<{ isOpened: boolean }>`
  width: ${(props) => (props.isOpened ? '230px' : '0')};

  background-color: ${GlobalTokenColor.ESGrey50};
  transition: 0.3s;
  position: relative;
  border-right: 1px solid #ddd;
  font-family: 'Spoqa Han Sans Neo';
`;

const SideMenuTitle = styled.p`
  height: 61px;
  font-size: 15px;
  font-weight: bold;
  padding: 24px 8px 16px 12px;
  border-bottom: 1px solid #ddd;
  color: ${GlobalTokenColor.ESGrey700};
`;

const SideMenuItemWrap = styled.div`
  min-width: 230px;
  height: calc(100% - 56px);
  padding-bottom: 30px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px; /* 스크롤바의 너비 */
    background: ${GlobalTokenColor.ESGrey50}; /*스크롤바 뒷 배경 색상*/
  }

  &::-webkit-scrollbar-thumb {
    height: 10%; /* 스크롤바의 길이 */
    background: #8d8d8d; /* 스크롤바의 색상 */
    border-radius: 30px;
  }

  &::-webkit-scrollbar-track {
    background: transparent; /*스크롤바 뒷 배경 색상*/
  }
`;
