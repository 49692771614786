import { useState } from 'react';
import { useLoading } from 'components/process/Loading';
import { useMessageBar } from 'components/process/MessageBar';
import { useCommonModal } from 'hooks/useCommonModal';
import { useTranslation } from 'react-i18next';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { DialogTitleItem } from 'components/layouts/GModalPopup';
import Save from 'components/asset/images/Confirm.svg';
import { GButton } from 'components/atom/button';
import { GLabelAtom } from 'components/atom/label';
import { GButtonBox, GPopupTitle } from 'components/layouts/GLayoutBox';
import { GbasicTable, GbasicTableWrap } from 'components/tables/GTable';
import InputField from 'components/atom/input';
import ModalLayout from 'components/layouts/ModalLayout';
import useMemberReset from './hooks/useMemberReset';
import { AlignBox } from 'components/organism/AlignBox';
import { ValidationBox } from 'components/validation/ValidationBox';
import { checkVendorIdApi, resetMemberInfoApi } from 'apis/vars/member/Member';

type ResetModalProps = {
  open: boolean;
  singleSelect?: boolean;
  close: () => void;
  save: () => void;
};

export interface MemberResetVO {
  userId: string; // VendorID
  taxId: string; // TAX ID
  accnNo: string; // 계좌번호
  cfrmUserId: boolean; // VendorId 확인
}

const MemberResetModal = (props: ResetModalProps) => {
  const { t } = useTranslation();
  const t2 = t as (msgId: string, defVal: string) => string;
  const { openLoading } = useLoading();
  const { openMessageBar } = useMessageBar();
  const { openCommonModal } = useCommonModal();
  const { regSchema } = useMemberReset();
  const [validationErrMap, setValidationErrMap] = useState<Map<string, string>>(new Map());
  const [resetFormData, setResetFormData] = useState<MemberResetVO>({
    userId: '', // VendorID
    taxId: '', // TAX ID
    accnNo: '', // 계좌번호
    cfrmUserId: false, // VendorId 확인
  });

  const handleClose = () => {
    props.close();
  };

  const handleChangeForResetForm = (e) => {
    setResetFormData((prev) => {
      return { ...prev, [e.target.id]: e.target.value };
    });
  };

  const validateVenderId = async () => {
    if (!resetFormData.userId) {
      openMessageBar('warning', t('com.label.00016', 'Vendor ID를 입력하세요'));
      return;
    }
    await checkVendorIdApi(resetFormData.userId)
      .then((res) => {
        if (res === true) {
          openCommonModal({
            modalType: 'alert',
            content: t('com.label.00557', '__Vendor ID가 확인되었습니다.'),
          });
          return setResetFormData({ ...resetFormData, cfrmUserId: true });
        } else {
          openCommonModal({
            modalType: 'alert',
            content: t('com.label.00558', '__Vendor ID가 확인에 실패하였습니다.'),
          });
        }
      })
      .catch((error) => {
        console.error('Error deleting row:', error);
      });
  };

  const checkValidate = async (schema: any, targetData: any) => {
    try {
      setValidationErrMap(new Map());
      await schema.validate(targetData, { abortEarly: false });
    } catch (e: any) {
      const errMap = new Map();
      e.inner?.map((err) => {
        errMap.set(err.path, err.message);
      });
      setValidationErrMap(errMap);
    }
  };

  const handleOnReset = async () => {
    console.log('초기화 입력값 확인 :: ', resetFormData);
    if (!resetFormData.cfrmUserId) {
      openMessageBar('warning', t('com.label.00559', '__Vendor ID 확인이 필요합니다.'));
      return;
    }
    try {
      await checkValidate(regSchema, resetFormData).then((res) => {
        console.log(res);
      });
      const isFormValid =
        resetFormData.cfrmUserId &&
        Object.entries(resetFormData)
          .filter(([fieldName]) => fieldName !== 'cfrmUserId')
          .every(([_, value]) => value.trim() !== '');
      console.log('isFormValid', isFormValid);
      console.log('cfrmUserId', resetFormData.cfrmUserId);

      if (isFormValid === true) {
        openCommonModal({
          modalType: 'confirm',
          content: t('com.label.00560', '__회원정보를 초기화하시겠습니까?'),
          noCallback: () => {
            return;
          },
          yesCallback: async () => {
            openLoading(true);
            return resetMemberInfoApi(resetFormData)
              .then((res) => {
                if (res.successOrNot === 'Y') {
                  openLoading(false);
                  if (res.data === 'I') {
                    return openCommonModal({
                      modalType: 'alert',
                      content: t(
                        'com.label.00595',
                        '__지급계좌등록 시스템 담당자에게 문의바랍니다.'
                      ),
                    });
                  } else if (res.data === 'Y') {
                    openCommonModal({
                      modalType: 'alert',
                      content: `${t('com.label.00561', '__회원정보가 초기화되었습니다.')}
                      \n${t('com.label.00562', '__새로 회원가입 후 이용 가능합니다.')}
                      `,
                    });
                    handleClose();
                  } else if (res.data === 'N') {
                    openLoading(false);
                    openCommonModal({
                      modalType: 'alert',
                      content: t('com.label.00563', '__회원정보 초기화에 실패하였습니다.'),
                    });
                  }
                } else {
                  openLoading(false);
                  openCommonModal({
                    modalType: 'alert',
                    content: t('com.label.00563', '__회원정보 초기화에 실패하였습니다.'),
                  });
                }
              })
              .catch((error) => {
                openLoading(false);
                console.error('Error deleting row:', error);
              });
          },
        });
      }
    } catch (e: any) {
      return;
    }
  };

  return (
    <ModalLayout open={props.open} onClose={handleClose} mMinWidth={525} mMaxWidth={525}>
      <DialogTitleItem id="customized-dialog-title" onClose={handleClose}>
        <GPopupTitle title2={t('com.label.00401', '__회원 정보 초기화')} />
      </DialogTitleItem>
      <DialogContent className="popupContent">
        <GbasicTableWrap isBottomMgn={false}>
          <GbasicTable>
            <colgroup>
              <col style={{ width: '150px' }} />
              <col style={{ width: 'auto' }} />
            </colgroup>
            <tbody>
              <tr>
                <th>
                  <GLabelAtom align="left" isRequired={true}>
                    Vendor ID
                  </GLabelAtom>
                </th>
                <td>
                  <ValidationBox msg={validationErrMap.get('cfrmUserId')}>
                    <AlignBox>
                      <InputField
                        id="userId"
                        align="left"
                        placeholder={t2('com.label.00016', '__Vendor ID를 입력하세요')}
                        onChange={handleChangeForResetForm}
                        value={resetFormData.userId}
                        width="77%"
                        status={
                          validationErrMap.get('userId') && resetFormData.userId.trim() === ''
                            ? 'error'
                            : 'default'
                        }
                        helperText={
                          validationErrMap.get('userId') && resetFormData.userId.trim() === ''
                            ? validationErrMap.get('userId')
                            : undefined
                        }
                      />
                      <GButton
                        txt={t('com.label.00146', '__확인')}
                        sizes="medium"
                        btnstyled="outline"
                        btnColor="normal"
                        hasImg={false}
                        hasTxt={true}
                        onClick={validateVenderId}
                      ></GButton>
                    </AlignBox>
                  </ValidationBox>
                </td>
              </tr>
              <tr>
                <th>
                  <GLabelAtom align="left" isRequired={true}>
                    {`TAX ID(${t('com.label.bizRegNum', '사업자번호')})`}
                  </GLabelAtom>
                </th>
                <td>
                  <InputField
                    id="taxId"
                    align="left"
                    placeholder={t2('com.label.00502', '__TAX ID를 입력하세요')}
                    width="100%"
                    onChange={handleChangeForResetForm}
                    value={resetFormData.taxId}
                    status={
                      validationErrMap.get('taxId') && resetFormData.taxId.trim() === ''
                        ? 'error'
                        : 'default'
                    }
                    helperText={
                      validationErrMap.get('taxId') && resetFormData.taxId.trim() === ''
                        ? validationErrMap.get('taxId')
                        : undefined
                    }
                  />
                </td>
              </tr>
              <tr>
                <th>
                  <GLabelAtom align="left" isRequired={true}>
                    {t2('com.label.00070', '__계좌번호')}
                  </GLabelAtom>
                </th>
                <td>
                  <InputField
                    id="accnNo"
                    align="left"
                    placeholder={t2('com.label.00448', '__계좌번호를 입력하세요')}
                    width="100%"
                    onChange={handleChangeForResetForm}
                    value={resetFormData.accnNo}
                    status={
                      validationErrMap.get('accnNo') && resetFormData.accnNo.trim() === ''
                        ? 'error'
                        : 'default'
                    }
                    helperText={
                      validationErrMap.get('accnNo') && resetFormData.accnNo.trim() === ''
                        ? validationErrMap.get('accnNo')
                        : undefined
                    }
                  />
                </td>
              </tr>
            </tbody>
          </GbasicTable>
        </GbasicTableWrap>
      </DialogContent>
      <DialogActions className="popupBottom">
        <GButtonBox>
          <GButton
            txt={t('com.label.00036', '취소')}
            sizes="medium"
            onClick={handleClose}
            btnstyled="outline"
            btnColor="normal"
            hasImg={false}
            hasTxt={true}
          />
          <GButton
            txt={t('com.label.00505', '초기화')}
            sizes="medium"
            onClick={handleOnReset}
            chkImg={Save}
            btnstyled="contained"
            btnColor="primary"
            hasImg={true}
            hasTxt={true}
          />
        </GButtonBox>
      </DialogActions>
    </ModalLayout>
  );
};

export default MemberResetModal;
