/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';

import RoleManagementGrid from 'pages/admin/rolemanagement/RoleManagementGrid';
import { useTranslation } from 'react-i18next';
import { BasicColor, BorderColor, FontColor, BgColor, ButtonColor } from 'ui/theme/Color';
import { css } from '@emotion/react';
import { CustomInputText } from 'components/inputs/CustomInput';
import { GreyButton } from 'components/buttons/CustomButton';
import { ContainerLayout } from 'components/layouts/MainLayout';
import { SearchBox, SearchButtons, SearchRows } from 'components/layouts/SearchBox';
import SearchIcon from '@mui/icons-material/Search';
import { ContentSection } from 'components/layouts/ContentSection';
import searchImage from 'components/asset/images/Search.svg';
import { GButton } from 'components/atom/button';

const RoleManagementPage: React.FC = () => {
  const { t } = useTranslation();

  const [searchParam, setSearchParam] = useState<string>('');
  const [textboxData, setTextboxData] = useState<string>('');

  const handleSearchParamData = (e: any) => {
    setTextboxData(e.target.value);
  };

  const btnSearch = () => {
    setSearchParam(textboxData);
  };

  return (
    <ContainerLayout>
      <SearchBox>
        <SearchRows>
          <div className="searchCol">
            <label>{t('com.label.roleNm', '__역할명')}</label>
            <CustomInputText onChange={handleSearchParamData}></CustomInputText>
          </div>
        </SearchRows>
        <SearchButtons className="searchButtons">
          <GButton
            chkImg={searchImage}
            txt={t('com.label.00116', '__조회')}
            sizes="medium"
            btnstyled="contained"
            btnColor="normal"
            hasImg={true}
            hasTxt={true}
            onClick={btnSearch}
          ></GButton>
        </SearchButtons>
      </SearchBox>
      <ContentSection>
        <RoleManagementGrid sRoleNm={searchParam} editable={true} />
      </ContentSection>
    </ContainerLayout>
  );
};

export default RoleManagementPage;
