import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { ColDef } from 'ag-grid-community';
import { DefaultCellTooltip } from 'components/grids/tooltip/DefaultCellTooltip';

export const useColumnDefForCommonCodeList = () => {
  const { t } = useTranslation();
  const defaultColDef: ColDef = useMemo(() => {
    return {
      resizable: true,
      cellStyle: { textAlign: 'center' },
      flex: 0,
      tooltipComponent: DefaultCellTooltip,
    };
  }, []);

  const cmnCdcolDefs: any[] = useMemo(() => {
    return [
      {
        width: 51,
        editable: false,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        flex: 0,
        cellStyle: { display: 'flex', justifyContent: 'flex-end' },
      },
      {
        headerName: t('label.com.코드', '__코드'),
        field: 'cmnCd',
        tooltipField: 'cmnCd',
        width: 100,
        flex: 1,
      },
      {
        headerName: t('com.label.cdNm', '__코드명'),
        field: 'cmnCdNm',
        tooltipField: 'cmnCdNm',
        width: 200,
        cellStyle: { textAlign: 'left' },
        flex: 2,
      },
      {
        field: 'cmnCdDesc',
        hide: true,
      },
      {
        field: 'upprCmnCd',
        hide: true,
      },
      {
        field: 'sortOrd',
        hide: true,
      },
      {
        field: 'useYn',
        hide: true,
      },
      {
        field: 'msgCtn',
        hide: true,
      },
      {
        field: 'optValCtn1',
        hide: true,
      },
      {
        field: 'optValCtn2',
        hide: true,
      },
      {
        field: 'optValCtn3',
        hide: true,
      },
      {
        field: 'optValCtn4',
        hide: true,
      },
      {
        field: 'optValCtn5',
        hide: true,
      },
      {
        field: 'optValCtn6',
        hide: true,
      },
      {
        field: 'optValCtn7',
        hide: true,
      },
      {
        field: 'optValCtn8',
        hide: true,
      },
      {
        field: 'optValCtn9',
        hide: true,
      },
      {
        field: 'optValCtn10',
        hide: true,
      },
      {
        field: 'optValCtn11',
        hide: true,
      },
      {
        field: 'optValCtn12',
        hide: true,
      },
      {
        field: 'optValCtn13',
        hide: true,
      },
      {
        field: 'optValCtn14',
        hide: true,
      },
      {
        field: 'optValCtn15',
        hide: true,
      },
    ];
  }, []);

  return { defaultColDef, cmnCdcolDefs };
};
