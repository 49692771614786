import DOMPurify from 'dompurify';
import { unescape } from 'lodash';
import React from 'react';

interface valueProps {
  value: any;
}

const DetailContent: React.FC<valueProps> = ({ value }) => {
  return <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value + '') }} />;
};

export default DetailContent;
