import styled from '@emotion/styled';
import { SelectChangeEvent } from '@mui/material';
import AvatarChip from 'components/atom/avatarChip';
import ChipSelectBox from 'components/common/select/ChipSelectBox';
import InputField from 'components/atom/input';
import { GLabelAtom } from 'components/atom/label';
import GSelectMUIAtom from 'components/common/select/GSelectMUIAtom';
import GDatePicker from 'components/common/input/GDatePicker';
import React, { useState } from 'react';
// import GridRadio from './GridRadio';
// import GridCheckbox from './GridCheckbox';
import { CommonCode } from 'models/admin/CommonCode';
import dayjs, { Dayjs } from 'dayjs';
import GridCheckbox from 'components/molecule/GridCheckbox';
import GridRadio from 'components/molecule/GridRadio';
import { GButtonBox } from 'components/molecule/GbuttonLayout';
import AutoEmpInput from 'components/cims/common/AutoEmpInput';

interface Props {
  type:
    | 'input'
    | 'select'
    | 'chipSelect'
    | 'calendar'
    | 'avatarChip'
    | 'radio'
    | 'checkBox'
    | 'autoEmpInput'
    | undefined;
  searchWidth?: string | '100%'; //라벨을 포함한 width
  searchValueWidth?: string | '100%'; //atom 요소의 width

  // Label
  labelWidth?: string;
  labelTitle: string;
  pTxt?: string | undefined;
  isRequired?: boolean;
  isInfo?: boolean;
  labelAlign?: 'left' | 'right' | undefined;

  // Input
  inputStatus?:
    | 'default'
    | 'disabled'
    | 'readonly'
    | 'error'
    | 'warning'
    | 'confirmed'
    | 'search'
    | undefined;
  inputAlign?: 'left' | 'right' | undefined;

  // Select
  selectOption?: CommonCode[] | string[];
  selectReadOnly?: boolean;
  defaultOption?: CommonCode;

  // Select, Input
  placeholder?: string;

  //GDatePicker
  calendarReadonly?: boolean;
  calendarViews?: any | ['year', 'month', 'day'];
  calendarFormat?: string | 'YYYY.MM.DD';
  calendarMaxWidth?: string | '100%';
  calendarStatus?: 'default' | 'error' | 'warning' | 'confirm' | undefined;
  calendarDouble?: boolean | false;
  calendarWidth?: string | '100%';
  calendarMinWidth?: string;
  mindate?: string;
  maxdate?: string;

  //ChipBox
  ChipDisabled?: boolean | undefined;
  ChipReadonly?: boolean | undefined;
  ChipWidth?: string;
  ChipHeight?: string | '100%';
  ChipStatus?: 'default' | 'error' | 'warning' | 'confirm' | undefined;
  ChipType?: '1' | '2' | undefined;
  ChipFormat?: string | '';
  SelectChipType?: 'default' | 'user'; //selectChip의 "chip" type
  SelectChipImg?: string;

  limitTag?: boolean;
  // Radio
  radioOption?: string[];
  radioValue?: string;

  // Checkbox
  checkboxOption?: string[];
  checkboxValue?: string;

  //added
  codeKey?: string;
  codeName?: string;
  onChange?: (e: any, value?: any) => void;
  onKeyUp?: (e) => void;
  selectedValue?: string;
  value?: CommonCode[];
  startDate?: Dayjs | null;
  endDate?: Dayjs | null;
  textInputValue?: string;
  disableCloseOnSelect?: boolean;
  date?: Dayjs | null;
  handleExistInputValue?: (e: any) => void;
  inputReadonly?: boolean;
  helperText?: string;

  // Align
  alignItems?: string;
}

const CSearchMolecule = (props: Props) => {
  // 임시로 SearchMolecule안에 handleChange와 option state를 넣음.
  // const [option, setOption] = useState(props.defaultOption || '0');
  const handleChange = (event: any, value?: any) => {
    // setOption(event.target.value);
    props.onChange && props.onChange(event, value);
  };

  return (
    <GInputArea searchWidth={props.searchWidth}>
      <GLabelAtom
        align={props.labelAlign}
        labelWidth="120px"
        pTxt={props.pTxt}
        isRequired={props.isRequired}
        isInfo={props.isInfo}
      >
        {props.labelTitle}
      </GLabelAtom>
      <ItemWrap searchValueWidth={props.searchValueWidth} alignItems={props.alignItems}>
        {props.type == 'input' ? (
          <InputField
            status={props.inputStatus || 'default'}
            align={props.inputAlign || 'left'}
            placeholder={props.placeholder}
            value={props.textInputValue}
            onChange={props.onChange}
            onKeyUp={props.onKeyUp}
            readOnly={props.inputReadonly}
            helperText={props.helperText || ''}
          />
        ) : props.type == 'select' ? (
          <GSelectMUIAtom
            value={props.selectedValue}
            handleChange={handleChange}
            placeholder={props.placeholder}
            option={props.selectOption || []}
            codeKey={props.codeKey}
            codeName={props.codeName}
            readOnly={props.selectReadOnly}
          />
        ) : props.type == 'calendar' ? (
          <GDatePicker
            readonly={props.calendarReadonly}
            width={props.calendarWidth}
            maxWidth={props.calendarMaxWidth}
            minWidth={props.calendarMinWidth}
            status={props.calendarStatus}
            views={props.calendarViews}
            format={props.calendarFormat}
            double={props.calendarDouble}
            startDate={props.startDate}
            endDate={props.endDate}
            changeDate={props.onChange}
            date={props.date}
            mindate={dayjs(props.mindate)}
            maxdate={dayjs(props.maxdate)}
          />
        ) : props.type == 'avatarChip' ? (
          <AvatarChip />
        ) : props.type == 'chipSelect' ? (
          <ChipSelectBox
            disabled={props.ChipDisabled}
            readonly={props.ChipReadonly}
            width={props.ChipWidth || '100%'}
            height={props.ChipHeight}
            status={props.ChipStatus}
            type={props.ChipType}
            format={props.ChipFormat}
            codeName={props.codeName}
            codeKey={props.codeKey}
            optionList={props.selectOption as CommonCode[]}
            defaultOption={props.defaultOption}
            handleChange={handleChange}
            value={props.value}
            disableCloseOnSelect={props.disableCloseOnSelect}
            chipType={props.SelectChipType}
            chipImg={props.SelectChipImg}
          />
        ) : props.type == 'checkBox' ? (
          <GridCheckbox value={props.checkboxValue || ''} option={props.checkboxOption || []} />
        ) : props.type == 'radio' ? (
          <GridRadio value={props.radioValue || ''} option={props.radioOption || []} />
        ) : props.type == 'autoEmpInput' ? (
          <AutoEmpInput //검토담당자
            disabled={props.ChipDisabled}
            readonly={props.ChipReadonly}
            width={props.ChipWidth || '100%'}
            height={props.ChipHeight}
            status={props.ChipStatus}
            type={props.ChipType}
            limitTag={props.limitTag}
            onChange={
              props.onChange
                ? props.onChange
                : (e) => {
                    return;
                  }
            }
            handleExistInputValue={props.handleExistInputValue}
          />
        ) : (
          <></>
        )}
      </ItemWrap>
    </GInputArea>
  );
};

const GInputArea = styled.div<{ searchWidth: string | undefined }>`
  display: flex;
  width: ${(props) => props.searchWidth || '33%'};
`;

export const ItemWrap = styled.div<{
  searchValueWidth: string | undefined;
  alignItems: string | undefined;
}>`
  ${(props) => (props.searchValueWidth ? `width : ${props.searchValueWidth}` : `flex : 1`)};
  margin-left: 8px;
  display: flex;
  align-items: ${(props) => props.alignItems || 'flex-start'};
  min-height: 32px;

  &::placeholder {
    font-family: 'Spoqa Han Sas Neo';
  }
`;

// searchBox.tsx
export const SearchWrap = styled.div<{ marginBottom?: string }>`
  display: flex;
  flex-direction: column;
  padding: 8px 12px;

  background-color: #f7f9f8;
  border: 1px solid #dde0df;
  border-radius: 2px;
  margin-bottom: ${(props) => props.marginBottom || '14px'};
`;

export const GButtonBoxCum = styled(GButtonBox)`
  align-self: end;
`;

export const UnHiddenSearchWrap = styled.div`
  display: flex;
`;

export const SearchItemWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  gap: 8px 0;
  margin-right: 24px;
`;

export const SearchHr = styled.hr`
  margin: 8px 0;
  border: none;
  border-top: 1px solid #dde0df;
`;

export const HiddenSearchWrap = styled(SearchItemWrap)`
  width: calc(100% - 156px);
`;

export default CSearchMolecule;
