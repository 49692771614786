import { useState } from 'react';
import SearchPerson from 'template/vars/modals/SearchChangePerson';
import SearchChargePerson from 'template/vars/modals/SearchChargePerson';
import RequestAccountCertification from 'template/vars/modals/RequestAccountCertification';
import AttachingFile from 'template/vars/modals/AttachingFile';
import DomesticBankNumber from 'template/vars/modals/DomesticBankNumber';
import FindPassword from 'template/vars/modals/FindPassword';
import ForeignCurrencyBank from 'template/vars/modals/ForeignCurrencyBank';

import { BlueButton } from 'components/buttons/CustomButton';
import ManagerSearchModal from 'pages/common/ManagerSearchModal';
import BankNumberModal from 'pages/vars/accountregist/modal/BankNumberModal';
import FrgnCurrencyBankModal from 'pages/vars/accountregist/modal/FrgnCurrencyBankModal';
import ChangeOverseasAccount from 'template/vars/modals/ChangeOverseasAccount';
import ChangeKrwAccount from 'template/vars/modals/ChangeKrwAccount';
import InquiryKrwAccount from 'template/vars/modals/InquiryKrwAccount';
import RegistrationOverseasAccount from 'template/vars/modals/RegistrationOverseasAccount';
import RegistrationKrwAccount from 'template/vars/modals/RegistrationKrwAccount';
import InquiryOverseasAccount from 'template/vars/modals/InquiryOverseasAccount';

const ModalPage = () => {
  // SAMPLE(퍼블)
  const [searchPersonModalOpen, setSearchPersonModalOpen] = useState<boolean>(false);
  const [searchChargePersonModalOpen, setSearchChargePersonModalOpen] = useState<boolean>(false);
  const [requestAccountCertificationModalOpen, setRequestAccountCertificationModalOpen] =
    useState<boolean>(false);
  const [attachingFileModalOpen, setAttachingFileModalOpen] = useState<boolean>(false);
  const [domesticBankNumberModalOpen, setDomesticBankNumberModalOpen] = useState<boolean>(false);
  const [findPasswordModalOpen, setFindPasswordModalOpen] = useState<boolean>(false);
  const [foreignCurrencyBankModalOpen, setForeignCurrencyBankModalOpen] = useState<boolean>(false);
  const [changeKrwAccountModalOpen, setChangeKrwAccountModalOpen] = useState<boolean>(false);
  const [changeOverseasAccountModalOpen, setChangeOverseasAccountModalOpen] =
    useState<boolean>(false);
  const [inquiryKrwAccountModalOpen, setInquiryKrwAccountModalOpen] = useState<boolean>(false);
  const [inquiryOverseasAccountModalOpen, setInquiryOverseasAccountModalOpen] =
    useState<boolean>(false);
  const [registrationKrwAccountModalOpen, setRegistrationKrwAccountModalOpen] =
    useState<boolean>(false);
  const [registrationOverseasAccountModalOpen, setRegistrationOverseasAccountModalOpen] =
    useState<boolean>(false);

  // DEV 추가(추후 배경페이지 완성 시 연결예정)
  const [userSearchModalOpen, setUserSearchModalOpen] = useState<boolean>(false);
  const [bankNumberModalOpen, setBankNumberModalOpen] = useState<boolean>(false);
  const [frgnBankModalOpen, setFrgnBankModalOpen] = useState<boolean>(false);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <br />
      <h1 style={{ color: 'red' }}>**DEV</h1>
      <h1>사용자 조회(DEV)</h1>
      <BlueButton
        style={{ width: '180px' }}
        onClick={() => {
          setUserSearchModalOpen(true);
        }}
      >
        사용자 조회(DEV)
      </BlueButton>
      <ManagerSearchModal
        open={userSearchModalOpen}
        close={() => setUserSearchModalOpen(false)}
        save={() => console.log()}
      />
      <hr />
      <br />
      <h1>은행번호(DEV)</h1>
      <BlueButton
        style={{ width: '180px' }}
        onClick={() => {
          setBankNumberModalOpen(true);
        }}
      >
        은행번호(DEV)
      </BlueButton>
      <BankNumberModal
        open={bankNumberModalOpen}
        close={() => setBankNumberModalOpen(false)}
        save={() => console.log()}
      />
      <hr />
      <br />
      <h1>외화거래은행(DEV)</h1>
      <BlueButton
        style={{ width: '180px' }}
        onClick={() => {
          setFrgnBankModalOpen(true);
        }}
      >
        외화거래은행(DEV)
      </BlueButton>
      <FrgnCurrencyBankModal
        isKrw={false}
        open={frgnBankModalOpen}
        close={() => setFrgnBankModalOpen(false)}
        save={() => console.log()}
      />
      <hr />
      <br />
      <br />
      <h1 style={{ color: 'blue' }}>**SAMPLE(퍼블작업본)</h1>
      <h1>사용자 조회</h1>
      <BlueButton
        style={{ width: '180px' }}
        onClick={() => {
          setSearchPersonModalOpen(true);
        }}
      >
        사용자 조회
      </BlueButton>
      <SearchPerson
        open={searchPersonModalOpen}
        close={() => setSearchPersonModalOpen(false)}
        save={() => console.log()}
      />
      <hr />
      <br />

      <h1>Undefined</h1>
      <BlueButton
        style={{ width: '180px' }}
        onClick={() => {
          setSearchChargePersonModalOpen(true);
        }}
      >
        Undefined
      </BlueButton>
      <SearchChargePerson
        open={searchChargePersonModalOpen}
        close={() => setSearchChargePersonModalOpen(false)}
        save={() => console.log()}
      />
      <hr />
      <br />
      <h1>계좌인증요청</h1>
      <BlueButton
        style={{ width: '180px' }}
        onClick={() => {
          setRequestAccountCertificationModalOpen(true);
        }}
      >
        계좌인증요청
      </BlueButton>
      <RequestAccountCertification
        open={requestAccountCertificationModalOpen}
        close={() => setRequestAccountCertificationModalOpen(false)}
        save={() => console.log()}
      />
      <hr />
      <br />
      <h1>파일첨부</h1>
      <BlueButton
        style={{ width: '180px' }}
        onClick={() => {
          setAttachingFileModalOpen(true);
        }}
      >
        파일첨부
      </BlueButton>
      <AttachingFile
        open={attachingFileModalOpen}
        close={() => setAttachingFileModalOpen(false)}
        save={() => console.log()}
      />
      <hr />
      <br />
      <h1>은행번호</h1>
      <BlueButton
        style={{ width: '180px' }}
        onClick={() => {
          setDomesticBankNumberModalOpen(true);
        }}
      >
        은행번호
      </BlueButton>
      <DomesticBankNumber
        open={domesticBankNumberModalOpen}
        close={() => setDomesticBankNumberModalOpen(false)}
        save={() => console.log()}
      />
      <hr />
      <br />
      <h1>비밀번호찾기</h1>
      <BlueButton
        style={{ width: '180px' }}
        onClick={() => {
          setFindPasswordModalOpen(true);
        }}
      >
        비밀번호찾기
      </BlueButton>
      <FindPassword
        open={findPasswordModalOpen}
        close={() => setFindPasswordModalOpen(false)}
        save={() => console.log()}
      />
      <hr />
      <br />
      <h1>외화거래은행</h1>
      <BlueButton
        style={{ width: '180px' }}
        onClick={() => {
          setForeignCurrencyBankModalOpen(true);
        }}
      >
        외화거래은행
      </BlueButton>
      <ForeignCurrencyBank
        open={foreignCurrencyBankModalOpen}
        close={() => setForeignCurrencyBankModalOpen(false)}
        save={() => console.log()}
      />
      <hr />

      <br />
      <h1>국내등록 RegistrationKrwAccount</h1>
      <BlueButton
        style={{ width: '180px' }}
        onClick={() => {
          setRegistrationKrwAccountModalOpen(true);
        }}
      >
        국내등록
      </BlueButton>
      <RegistrationKrwAccount
        open={registrationKrwAccountModalOpen}
        close={() => setRegistrationKrwAccountModalOpen(false)}
        save={() => console.log()}
      />
      <hr />

      <br />
      <h1>해외등록 RegistrationOverseasAccount</h1>
      <BlueButton
        style={{ width: '180px' }}
        onClick={() => {
          setRegistrationOverseasAccountModalOpen(true);
        }}
      >
        해외등록
      </BlueButton>
      <RegistrationOverseasAccount
        open={registrationOverseasAccountModalOpen}
        close={() => setRegistrationOverseasAccountModalOpen(false)}
        save={() => console.log()}
      />
      <hr />

      <br />
      <h1>국내 ChangeKrwAccount </h1>
      <BlueButton
        style={{ width: '180px' }}
        onClick={() => {
          setChangeKrwAccountModalOpen(true);
        }}
      >
        국내1
      </BlueButton>
      <ChangeKrwAccount
        open={changeKrwAccountModalOpen}
        close={() => setChangeKrwAccountModalOpen(false)}
        save={() => console.log()}
      />
      <hr />

      <br />
      <h1>해외 ChangeOverseasAccount</h1>
      <BlueButton
        style={{ width: '180px' }}
        onClick={() => {
          setChangeOverseasAccountModalOpen(true);
        }}
      >
        해외1
      </BlueButton>
      <ChangeOverseasAccount
        open={changeOverseasAccountModalOpen}
        close={() => setChangeOverseasAccountModalOpen(false)}
        save={() => console.log()}
      />
      <hr />

      <br />
      <h1>국내 InquiryKrwAccount</h1>
      <BlueButton
        style={{ width: '180px' }}
        onClick={() => {
          setInquiryKrwAccountModalOpen(true);
        }}
      >
        국내2
      </BlueButton>
      <InquiryKrwAccount
        open={inquiryKrwAccountModalOpen}
        close={() => setInquiryKrwAccountModalOpen(false)}
        save={() => console.log()}
      />
      <hr />

      <br />
      <h1>해외 InquiryOverseasAccount</h1>
      <BlueButton
        style={{ width: '180px' }}
        onClick={() => {
          setInquiryOverseasAccountModalOpen(true);
        }}
      >
        해외2
      </BlueButton>
      <InquiryOverseasAccount
        open={inquiryOverseasAccountModalOpen}
        close={() => setInquiryOverseasAccountModalOpen(false)}
        save={() => console.log()}
      />
      <hr />
    </div>
  );
};

export default ModalPage;
