import { ICellRendererParams } from 'ag-grid-community';
import { GLabel } from 'components/layouts/GLayoutBox';
import { CustomSelect } from 'components/selects/CustomSelect';
import _ from 'lodash';
import { Emptiable } from 'models/common/FalsyGeneric';
import { ChangeEvent, CSSProperties, useCallback, useMemo, useState } from 'react';

export interface DropdownItem {
  label: string;
  value: string;
}

export interface IDropdownCellRendererParams extends ICellRendererParams {
  dropdownItems: DropdownItem[];
  useEmptyItem?: boolean;
  editable?: (() => boolean) | boolean;
  valueWhenEmptyItemSelected?: string;
  selectStyle?: CSSProperties;
  optionStyle?: CSSProperties;
}

const emptyDropdownItem: DropdownItem = {
  label: '',
  value: '',
};

export const DropdownCellRenderer = ({
  dropdownItems,
  useEmptyItem = false,
  editable,
  valueWhenEmptyItemSelected = '',
  selectStyle,
  optionStyle,

  ...params
}: IDropdownCellRendererParams) => {
  const [currentValue, setCurrentValue] = useState<string>(params.value ?? '');
  const disabled = useMemo(() => {
    if (typeof editable === 'boolean') {
      return !editable;
    }
    if (typeof editable === 'function') {
      return !editable();
    }
    return true;
  }, [editable, params.data, params.value, params.colDef]);

  const items = useMemo<DropdownItem[]>(() => {
    return useEmptyItem ? [emptyDropdownItem, ...dropdownItems] : [...dropdownItems];
  }, [dropdownItems, useEmptyItem]);

  const onChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      console.log(e);
      let newValue: Emptiable<string> = e.target.value;

      if (_.isEmpty(newValue) && useEmptyItem) {
        newValue = valueWhenEmptyItemSelected;
      } else if (_.isEmpty(items.find((item) => item.value === newValue))) {
        newValue = currentValue;
      }

      const { api, setValue } = params;
      if (setValue) {
        setValue(newValue);
        setCurrentValue(newValue ?? '');

        api.refreshCells({
          force: true,
          suppressFlash: true,
        });
      }
    },
    [currentValue, setCurrentValue, items, params.value, params.colDef, params.api, params.setValue]
  );

  const renderSelect = () => {
    return (
      <div>
        <CustomSelect
          disabled={disabled}
          className="widthAuto"
          onChange={onChange}
          value={params.value}
        >
          {[...items].map((item, index) => (
            <option key={`${item.value}_${index}`} value={item.value} style={{ ...optionStyle }}>
              {item.label}
            </option>
          ))}
        </CustomSelect>
      </div>
    );
  };

  const renderDisabled = () => {
    const list = dropdownItems.filter((item) => item.value === params.value);
    return <div>{(list?.length || 0) > 0 ? list[0].label : ''}</div>;
  };

  return (
    <>
      {disabled && renderDisabled()}
      {!disabled && renderSelect()}
    </>
  );
};
