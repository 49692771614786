import { ColDef } from 'ag-grid-community';
import { DefaultCellTooltip } from 'components/grids/tooltip/DefaultCellTooltip';
import { addCommasFor } from 'components/vars/common/FormatNumber';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useOPaymentColDefs = () => {
  const { t } = useTranslation();
  const defaultColDef = useMemo((): ColDef => {
    return {
      sortable: false,
      resizable: true,
      cellStyle: { textAlign: 'center' },
      tooltipComponent: DefaultCellTooltip,
    };
  }, []);

  const colDefs: any = [
    {
      headerName: t('com.label.00640', '__Payment Date'),
      headerTooltip: t('com.label.00640', '__Payment Date'),
      field: 'payDt',
      tooltipField: 'payDt',
      width: 160,
    },
    {
      headerName: t('com.label.00638', '__Currency'),
      headerTooltip: t('com.label.00638', '__Currency'),
      field: 'curr',
      tooltipField: 'curr',
      width: 160,
    },
    {
      headerName: t('com.label.00631', '__Amount'),
      headerTooltip: t('com.label.00631', '__Amount'),
      field: 'payAmt',
      tooltipField: 'payAmt',
      cellStyle: { textAlign: 'right' },
      valueFormatter: (params) => addCommasFor(params.value),
      width: 160,
    },
    {
      headerName: t('com.label.00623', '__Vendor Bank Account Information'),
      headerTooltip: t('com.label.00623', '__Vendor Bank Account Information'),
      flex: 1,
      children: [
        {
          headerName: t('com.label.00641', '__Bank'),
          headerTooltip: t('com.label.00641', '__Bank'),
          field: 'payBankNm',
          tooltipField: 'payBankNm',
          flex: 1,
        },
        {
          headerName: t('com.label.00642', '__SWIFT Code'),
          headerTooltip: t('com.label.00642', '__SWIFT Code'),
          field: 'swift',
          tooltipField: 'swift',
          flex: 1,
        },
        {
          headerName: t('com.label.00643', '__Account #'),
          headerTooltip: t('com.label.00643', '__Account #'),
          field: 'accnNo',
          tooltipField: 'accnNo',
          flex: 1,
        },
      ],
    },
    {
      headerName: t('com.label.00644', '__Swift Message #'),
      headerTooltip: t('com.label.00644', '__Swift Message #'),
      field: 'swiftMsg',
      tooltipField: 'swiftMsg',
      width: 250,
    },
    {
      headerName: t('com.label.00639', '__Payment Method'),
      headerTooltip: t('com.label.00639', '__Payment Method'),
      field: 'payDivNm',
      tooltipField: 'payDivNm',
      width: 250,
    },
    {
      headerName: t('com.label.00646', '__Payment Document No.'),
      headerTooltip: t('com.label.00646', '__Payment Document No.'),
      field: 'clearDmt',
      tooltipField: 'clearDmt',
      width: 250,
    },
  ];

  return { defaultColDef, colDefs };
};
