import styled from '@emotion/styled';
import { useCommonModal } from 'hooks/useCommonModal';
import { createContext, useRef, useState, useEffect } from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { GButtonBox, GPopupTitle } from 'components/layouts/GLayoutBox';
import { DialogTitleItem } from 'components/layouts/GModalPopup';
import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import { GButton } from 'components/atom/button';
import Save from 'components/asset/images/Confirm.svg';
import {
  GButtonBoxCum,
  SearchItemWrap,
  SearchWrap,
  UnHiddenSearchWrap,
} from 'components/molecule/SearchMolecule';
import GButtonLayout from 'components/molecule/GbuttonLayout';
import Search from 'components/asset/images/Search.svg';
import { GContentGrid } from 'components/layouts/GContentGrid';
import { useLoading } from 'components/process/Loading';
import { useMessageBar } from 'components/process/MessageBar';
import ModalLayout from 'components/layouts/ModalLayout';
import CSearchMolecule from 'components/vars/common/CSearchMolecule';
import { getAdminManagerListApi, getUserManagerListApi } from 'apis/common/ManagerSearchApi';
import { Employee } from 'models/admin/Employee';
import useAuthStore from 'stores/useAuthStore';
import { MemberRegVO } from 'pages/vars/member/signup/MemberRegistPage';
import { useManagerSearchSingleColumnDefs } from './hooks/ManagerSearchSingleColumnDefs';
import { MemberEditVO } from 'pages/vars/member/manage/MemberDetailPage';
import EmptyState from 'components/atom/emptyState';

type SearchModalProps = {
  id: string;
  open: boolean;
  singleSelect?: boolean;
  close: () => void;
  save?: (param) => void;
  // 추가
  signUpFormData: MemberRegVO | MemberEditVO;
  setSignUpFormData: any;
  selectedManagerData: Employee[];
  setSelectedManagerData: React.Dispatch<React.SetStateAction<Employee[]>>;
};

const ManagerSearchModalSingle = (props: SearchModalProps) => {
  const { t } = useTranslation();
  const t2 = t as (msgId: string, defVal: string) => string;
  const { openLoading } = useLoading();
  const { openMessageBar } = useMessageBar();
  const { openCommonModal } = useCommonModal();
  const gridRef = useRef<AgGridReact<Employee>>(null);
  const { accessToken } = useAuthStore();

  const [managerData, setManagerData] = useState<Employee[]>([]);
  const { defaultColDef, colDefs } = useManagerSearchSingleColumnDefs();
  const [inputValue, setInputValue] = useState<string>('');

  const handleError = (e: Error) => {
    openLoading(false);
    openMessageBar('error', e.message);
  };

  const getManagerList = async () => {
    // 회원가입 시 일반인user API 탐
    if (props.id === 'signup' || accessToken) {
      getUserManagerListApi(inputValue)
        .then((res) => {
          setManagerData(res);
        })
        .catch(handleError);
    } else {
      getAdminManagerListApi(inputValue)
        .then((res) => {
          setManagerData(res);
        })
        .catch(handleError);
    }
  };

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSearch = () => {
    getManagerList();
  };

  const handleClose = () => {
    setInputValue('');
    // setManagerData([]);
    // props.setSelectedManagerData([]);
    props.close();
  };

  const handleOnSave = () => {
    const selectedRows = gridRef.current?.api.getSelectedRows() || [];
    console.log('선택된 행 값 ::', selectedRows?.[0]);
    if (selectedRows.length === 0) {
      openCommonModal({
        modalType: 'alert',
        content: t('com.msg.noSelRow', '__선택된 행이 없습니다.'),
      });
      return;
    } else {
      props.setSelectedManagerData(selectedRows);
      props.setSignUpFormData({
        ...props.signUpFormData,
        soprMgrNm: selectedRows?.[0]?.empNm ?? '',
        soprMgrEmal: selectedRows?.[0]?.emlSvrDmnIfoNm ?? '',
        soprMgrEmpNo: selectedRows?.[0]?.empNo ?? '',
        userId: selectedRows?.[0]?.userId ?? '',
      });
      props.close();
    }
  };

  return (
    <ModalLayout open={props.open} onClose={handleClose} mMinWidth={1000} mMaxWidth={1000}>
      <DialogTitleItem id="customized-dialog-title" onClose={handleClose}>
        <GPopupTitle title2={t('com.label.mngSrch', '__담당자 검색')} />
      </DialogTitleItem>
      <DialogContent className="popupContent">
        <SearchWrap>
          <UnHiddenSearchWrap>
            <ItemWrap>
              <CSearchMolecule
                type="input"
                labelTitle={t('com.label.mngEml', '__담당자 이메일')}
                placeholder={t2('com.label.00496', '__담당자 이메일을 입력하세요')}
                searchWidth="100%"
                searchValueWidth="350px"
                onChange={handleChange}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') handleSearch();
                }}
              />
            </ItemWrap>
            <GButtonLayout paddingTop="0px" marginBottom="0px">
              <GButtonBoxCum>
                <GButton
                  chkImg={Search}
                  txt={t('com.label.00116', '__조회')}
                  sizes="medium"
                  btnstyled="contained"
                  btnColor="normal"
                  hasImg={true}
                  hasTxt={true}
                  onClick={handleSearch}
                ></GButton>
              </GButtonBoxCum>
            </GButtonLayout>
          </UnHiddenSearchWrap>
        </SearchWrap>
        <GContentGrid
          type="radio"
          className="ag-theme-alpine"
          isSortable={false}
          style={{ height: '336px' }}
        >
          <AgGridReact
            ref={gridRef}
            defaultColDef={defaultColDef}
            columnDefs={colDefs}
            rowData={managerData}
            rowSelection={'single'}
            suppressMovableColumns
            enableCellTextSelection
            tooltipShowDelay={0}
            tooltipHideDelay={2000}
            noRowsOverlayComponent={EmptyState}
            noRowsOverlayComponentParams={{ type: 'grid' }}
          ></AgGridReact>
        </GContentGrid>
      </DialogContent>
      <DialogActions className="popupBottom">
        <GButtonBox>
          <GButton
            txt={t('com.label.00036', '취소')}
            sizes="medium"
            onClick={handleClose}
            btnstyled="outline"
            btnColor="normal"
            hasImg={false}
            hasTxt={true}
          />
          <GButton
            txt={t('com.label.00023', '선택')}
            sizes="medium"
            onClick={handleOnSave}
            chkImg={Save}
            btnstyled="contained"
            btnColor="primary"
            hasImg={true}
            hasTxt={true}
          />
        </GButtonBox>
      </DialogActions>
    </ModalLayout>
  );
};
export default ManagerSearchModalSingle;

const ItemWrap = styled(SearchItemWrap)`
  display: flex;
  gap: 24px;
`;
