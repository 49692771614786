import styled from '@emotion/styled';
import Eye from 'components/asset/images/Eye.png';
import { TextLineHeight, TextSize, TextWeight } from 'ui/theme/CustomTypography';
import { GlobalTokenColor } from 'ui/theme/Color';

interface EyeCountProps {
  count: number;
}

const addCommas = (num: number): string => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const EyeCount = (props: EyeCountProps) => {
  const formattedCount = addCommas(props.count);

  return (
    <EyeCountBox>
      <img src={Eye} className="eye" />
      <Count>{formattedCount}</Count>
    </EyeCountBox>
  );
};

const EyeCountBox = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
  align-self: stretch;
`;
const Count = styled.span`
  width: auto;
  color: ${GlobalTokenColor.ESGrey900};
  font-family: Spoqa Han Sans Neo;
  font-size: ${TextSize.Basic};
  font-weight: ${TextWeight.Regualr};
  line-height: ${TextLineHeight.Basic};
`;

export default EyeCount;
