import { Radio, RadioProps } from '@mui/material';
import styled from '@emotion/styled';
import { BgColor, BorderColor, GlobalTokenColor } from 'ui/theme/Color';

interface PropsType {
  color?: 'primary' | 'secondary';
}

export const GRadio = ({ color = 'primary', ...props }: PropsType & RadioProps) => {
  return (
    <Radio
      sx={{
        '&:hover': { bgcolor: 'transparent' },
        padding: 0,
        marginRight: '4px',
      }}
      disableRipple
      checkedIcon={<RadiosCheckedIcon color={color} />}
      icon={<RadiosIcon color={color} />}
      {...props}
    />
  );
};

const RadiosIcon = styled.span<PropsType>`
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-color: ${BgColor.White};
  border: 1px solid ${BorderColor.Form};

  input:hover ~ & {
    border: 1px solid
      ${(props) =>
        props.color === 'primary'
          ? `${GlobalTokenColor.Primary}`
          : `${GlobalTokenColor.Secondary}`};
  }

  input:disabled ~ & {
    border: 1px solid ${GlobalTokenColor.ESGrey300Op40};
  }
`;

const RadiosCheckedIcon = styled(RadiosIcon)`
  border: 4px solid
    ${(props) =>
      props.color === 'primary'
        ? `${GlobalTokenColor.Primary600}`
        : `${GlobalTokenColor.Secondary500}`};

  input:hover ~ & {
    border: 4px solid
      ${(props) =>
        props.color === 'primary'
          ? `${GlobalTokenColor.Primary500}`
          : `${GlobalTokenColor.Secondary300}`};
  }

  input:disabled ~ & {
    border: 4px solid
      ${(props) =>
        props.color === 'primary'
          ? `${GlobalTokenColor.Primary600Op40}`
          : `${GlobalTokenColor.Secondary500Op40}`};
  }
`;

interface GRadioButtonPropsType {
  text?: string;
  value?: string;
  radioGroup?: string;
  size?: 'small' | 'medium' | 'large';
  isDisabled?: boolean;
  isSelected?: boolean;
}

export const GRadioButton = (
  { text, value, radioGroup, size = 'medium', isDisabled, isSelected }: GRadioButtonPropsType,
  { color = 'primary' }: PropsType
) => {
  return (
    <>
      <RadioButton
        defaultChecked={isSelected}
        disabled={isDisabled}
        color={color}
        type="radio"
        id={value}
        name={radioGroup}
      />
      <RadioLabel isSelected={isSelected} isDisabled={isDisabled} size={size} htmlFor={value}>
        {text}
      </RadioLabel>
    </>
  );
};

const RadioButton = styled.input<GRadioButtonPropsType>`
  display: none;

  :checked + label {
    background-color: ${(props) =>
      props.color === 'primary' ? `${GlobalTokenColor.Primary}` : `${GlobalTokenColor.Secondary}`};
    color: ${GlobalTokenColor.ESGreyWHite};
    border: 1px solid ${GlobalTokenColor.Primary};

    input:disabled ~ & {
      border: 1px solid ${GlobalTokenColor.Primary};
      opacity: 0.4;
      background: ${GlobalTokenColor.Primary};
    }
  }
`;

const RadioLabel = styled.label<GRadioButtonPropsType>`
  width: fit-content;
  background: ${GlobalTokenColor.ESGreyWHite};
  border: 1px solid
    ${(props) =>
      props.isSelected === true && props.isDisabled === true
        ? `${GlobalTokenColor.Primary}`
        : `${BorderColor.Form}`};
  border-radius: 100px;
  color: #5b5c5d;
  text-align: center;
  cursor: pointer;
  user-select: none;
  font-weight: 400;
  font-size: ${(props) => (props.size === 'large' ? '13px' : '12px')};
  ${(props) =>
    props.size === 'small' &&
    `
    padding: 3px 10px;
    height: 24px;
  `}

  ${(props) =>
    props.size === 'medium' &&
    `
    padding: 5px 14px;
    height: 28px;
    line-height: 18px;
  `}

  ${(props) =>
    props.size === 'large' &&
    `
    padding: 6px 16px;
    height: 32px;
    line-height: 19.5px;
  `}

  input:disabled ~ & {
    opacity: 0.4;
    cursor: default;
  }

  :hover {
    background-color: ${GlobalTokenColor.ESGrey100};
    border: 1px solid ${GlobalTokenColor.ESGrey500};
    color: ${GlobalTokenColor.ESGrey700};
  }
`;
