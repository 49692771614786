import React, { ReactNode, useContext, useEffect, useRef, useState } from 'react';

export type GFormControlProviderProps = {
  children: ReactNode;
  onInitialized?: () => void;
};

type CompletionCallback = (id: string) => void;

export type FormControlContextValue = {
  registerFieldId?: (id: string, completionCallback?: CompletionCallback) => void;
  completeInitialization?: (id: string) => void;
  getInitialzationState?: (id: string) => boolean;
  initCompleted?: boolean;
};

export const FormControlContext = React.createContext<FormControlContextValue>({});

export const FormControlProvider = ({ children, onInitialized }: GFormControlProviderProps) => {
  const inputFieldsToBeInitializedRef = useRef<any>({});
  const callbackRef = useRef<CompletionCallback[]>([]);
  const [initCompleted, setInitCompleted] = useState<boolean>(false);

  useEffect(() => {
    initCompleted && onInitialized?.();
  }, [initCompleted]);

  const registerFieldId = (id: string, completionCallback?: (id: string) => void) => {
    inputFieldsToBeInitializedRef.current[id] = false;
    completionCallback && callbackRef.current.push(completionCallback);
  };

  const completeInitialization = (id: string) => {
    inputFieldsToBeInitializedRef.current[id] = true;
    callbackRef.current.forEach((callback) => callback(id));

    let initializedAll = true;
    for (const property in inputFieldsToBeInitializedRef.current) {
      if (!inputFieldsToBeInitializedRef.current[property]) {
        initializedAll = false;
        break;
      }
    }

    console.log('completeInitialization', id, inputFieldsToBeInitializedRef.current);

    if (initializedAll) {
      setInitCompleted(true);
    }
  };

  const getInitialzationState = (id: string): boolean => {
    return inputFieldsToBeInitializedRef.current[id] || false;
  };

  const providerValue = {
    registerFieldId,
    completeInitialization,
    getInitialzationState,
    initCompleted,
  };

  return (
    <FormControlContext.Provider value={providerValue}>{children}</FormControlContext.Provider>
  );
};

export const useFormControlContext = () => {
  const ctx = useContext(FormControlContext);
  if (!ctx) {
    throw new Error('useFormControlContext must be used within FormControlProvider');
  }
  return ctx;
};
