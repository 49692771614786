import { GLabelAtom } from 'components/atom/label';
import { GbasicTable, GbasicTableWrap } from 'components/tables/GTable';
import InputField from 'components/atom/input';
import { GButton } from 'components/atom/button';
import ChipSelectBox from 'components/atom/chipSelect';
import SubTitleBox from 'components/molecule/SubTitleBox';
import { SelectChangeEvent } from '@mui/material';
import GSelectMUIAtom from 'components/atom/select';
import GridRadio, { RadioWrap } from 'components/molecule/GridRadio';
import { AlignBox } from 'components/organism/AlignBox';
import { SubTitleBtnArea } from 'components/organism/SubTitleBtnArea';
import { useState } from 'react';
import TextArea from 'components/atom/textarea';
import GButtonLayout from 'components/molecule/GbuttonLayout';
import Save from 'components/asset/images/Confirm.svg';
import styled from '@emotion/styled';

const Signup = () => {
  const [option, setOption] = useState('0');
  const handleChange = (event: SelectChangeEvent) => {
    setOption(event.target.value);
  };

  const [chipClick, setChipClick] = useState(false);
  const onSearchClick = () => {
    setChipClick((pre) => !pre);
    alert('ex. 모달에서 clickValue 선택');
  };

  const [option2, setOption2] = useState('1');
  const handleChange2 = (event: SelectChangeEvent) => {
    setOption2(event.target.value);
  };

  return (
    <>
      <GButtonLayout paddingTop="0" marginBottom="12px">
        <GButton
          chkImg={Save}
          txt="저장 "
          sizes="medium"
          btnstyled="contained"
          btnColor="primary"
          hasImg={true}
          hasTxt={true}
        />
      </GButtonLayout>
      <GbasicTableWrap isBottomMgn={true}>
        <GbasicTable>
          <colgroup>
            <col style={{ width: '160px' }} />
            <col style={{ width: 'auto' }} />
            <col style={{ width: '160px' }} />
            <col style={{ width: 'auto' }} />
          </colgroup>
          <tbody>
            <tr>
              <th>
                <GLabelAtom align="left" isRequired={true}>
                  한국/한국외 구분 선택
                </GLabelAtom>
              </th>
              <td>
                <AlignBox>
                  <GridRadio option={['한국', '한국외']} value="domesticOverseas" />
                  <GButton
                    hasImg={false}
                    hasTxt={true}
                    txt="공동인증서 관리"
                    sizes="medium"
                    btnstyled="outline"
                    btnColor="normal"
                  />
                </AlignBox>
              </td>
              <th>
                <GLabelAtom align="left" isRequired={true}>
                  회원 가입자 이름 등록
                </GLabelAtom>
              </th>
              <td>
                <InputField
                  width="240px"
                  height="32px"
                  status="default"
                  align="left"
                  value="김거래"
                />
              </td>
            </tr>
            <tr>
              <th>
                <GLabelAtom align="left" isRequired={true}>
                  Vendor ID 등록
                </GLabelAtom>
              </th>
              <td>
                <InputField
                  width="240px"
                  height="32px"
                  status="default"
                  align="left"
                  value="KR000211"
                />
              </td>
              <th>
                <GLabelAtom align="left" isRequired={true}>
                  업체명 등록
                </GLabelAtom>
              </th>
              <td>
                <InputField
                  width="240px"
                  height="32px"
                  status="default"
                  align="left"
                  value="한국배터리 산업협회"
                />
              </td>
            </tr>
            <tr>
              <th>
                <GLabelAtom align="left" isRequired={true}>
                  패스워드 등록
                </GLabelAtom>
              </th>
              <td>
                <InputField
                  width="240px"
                  isPassword={true}
                  height="32px"
                  status="default"
                  align="left"
                  value="password"
                />
              </td>
              <th>
                <GLabelAtom align="left" isRequired={true}>
                  패스워드 등록 확인
                </GLabelAtom>
              </th>
              <td>
                <InputField
                  width="240px"
                  isPassword={true}
                  height="32px"
                  status="default"
                  align="left"
                  value="password"
                />
              </td>
            </tr>
            <tr>
              <th>
                <GLabelAtom align="left" isRequired={true}>
                  연락처 등록
                </GLabelAtom>
              </th>
              <td>
                <InputField
                  width="240px"
                  height="32px"
                  status="default"
                  align="left"
                  value="12345678"
                />
              </td>
              <th>
                <GLabelAtom align="left" isRequired={true}>
                  이메일 등록
                </GLabelAtom>
              </th>
              <td>
                <AlignBox>
                  <InputField width="140px" status="default" value="kimelgee0123" />
                  @
                  <GSelectMUIAtom
                    selectWidth="147.5px"
                    value={option2}
                    handleChange={handleChange2}
                    option={['lgensol.com', 'lgensol.com']}
                  />
                  <InputField status="disabled" placeholder="직접입력" disabled width="147.5px" />
                </AlignBox>
              </td>
            </tr>
          </tbody>
        </GbasicTable>
      </GbasicTableWrap>

      <SubTitleBtnArea>
        <SubTitleBox
          content="LG에너지솔루션 담당"
          showSubTitle={true}
          isVisibleComment={false}
          isVisibleInfo={false}
        />
      </SubTitleBtnArea>
      <GbasicTableWrap isBottomMgn={true}>
        <GbasicTable>
          <colgroup>
            <col style={{ width: '160px' }} />
            <col style={{ width: 'auto' }} />
            <col style={{ width: '160px' }} />
            <col style={{ width: 'auto' }} />
          </colgroup>
          <tbody>
            <tr>
              <th>
                <GLabelAtom align="left" isRequired={true}>
                  법인코드 등록
                </GLabelAtom>
              </th>
              <td>
                <ChipSelectBox
                  chipType="default"
                  type="1"
                  width="240px"
                  height="32px"
                  onClickFunction={onSearchClick}
                />
              </td>
              <th>
                <GLabelAtom align="left" isRequired={true}>
                  담당자 등록
                </GLabelAtom>
              </th>
              <td>
                <ChipSelectBox
                  chipType="default"
                  type="1"
                  width="300px"
                  height="32px"
                  onClickFunction={onSearchClick}
                />
              </td>
            </tr>
            <tr>
              <th>
                <GLabelAtom align="left">이메일 등록</GLabelAtom>
              </th>
              <td>
                <AlignBox>
                  <InputField
                    disabled
                    readOnly={true}
                    width="140px"
                    status="disabled"
                    value="kimelgee0123"
                  />
                  @
                  <InputField
                    readOnly={true}
                    status="disabled"
                    value="lgensol.com"
                    disabled
                    width="140px"
                  />
                </AlignBox>
              </td>
              <th>
                <GLabelAtom align="left">연락처 등록</GLabelAtom>
              </th>
              <td>
                <InputField
                  disabled
                  width="240px"
                  height="32px"
                  status="disabled"
                  readOnly={true}
                  align="left"
                  value="12345678"
                />
              </td>
            </tr>
          </tbody>
        </GbasicTable>
      </GbasicTableWrap>

      <SubTitleBtnArea>
        <SubTitleBox
          content="거래처담당자"
          showSubTitle={true}
          isVisibleComment={false}
          isVisibleInfo={false}
        />
      </SubTitleBtnArea>
      <GbasicTableWrap isBottomMgn={true}>
        <GbasicTable>
          <colgroup>
            <col style={{ width: '160px' }} />
            <col style={{ width: 'auto' }} />
            <col style={{ width: '160px' }} />
            <col style={{ width: 'auto' }} />
          </colgroup>
          <tbody>
            <tr>
              <th>
                <GLabelAtom align="left">이름 등록</GLabelAtom>
              </th>
              <td>
                <InputField
                  status="default"
                  align="left"
                  width="240px"
                  placeholder="이름을 입력하세요"
                />
              </td>
              <th>
                <GLabelAtom align="left">연락처 등록</GLabelAtom>
              </th>
              <td>
                <InputField
                  width="240px"
                  height="32px"
                  status="default"
                  align="left"
                  value="12345678"
                />
              </td>
            </tr>
            <tr>
              <th>
                <GLabelAtom align="left" isRequired={true}>
                  이메일 등록
                </GLabelAtom>
              </th>
              <td>
                <AlignBox>
                  <InputField width="140px" status="default" placeholder="이메일아이디" />
                  @
                  <GSelectMUIAtom
                    selectWidth="147.5px"
                    value={option}
                    handleChange={handleChange}
                    option={['lgensol.com', 'lgensol.com']}
                    placeholder="선택"
                  />
                  <InputField status="disabled" placeholder="직접입력" disabled width="147.5px" />
                </AlignBox>
              </td>
              <th>
                <GLabelAtom align="left">추가 이메일 등록</GLabelAtom>
              </th>
              <td>
                <AlignBox>
                  <InputField width="140px" status="default" placeholder="이메일아이디" />
                  @
                  <GSelectMUIAtom
                    selectWidth="147.5px"
                    value={option}
                    handleChange={handleChange}
                    option={['lgensol.com', 'lgensol.com']}
                    placeholder="선택"
                  />
                  <InputField status="disabled" placeholder="직접입력" disabled width="147.5px" />
                </AlignBox>
              </td>
            </tr>
          </tbody>
        </GbasicTable>
      </GbasicTableWrap>

      <SubTitleBtnArea>
        <SubTitleBox
          content="거래처 자금부서 담당(해외)"
          showSubTitle={true}
          isVisibleComment={false}
          isVisibleInfo={false}
        />
      </SubTitleBtnArea>
      <GbasicTableWrap isBottomMgn={true}>
        <GbasicTable>
          <colgroup>
            <col style={{ width: '160px' }} />
            <col style={{ width: 'auto' }} />
            <col style={{ width: '160px' }} />
            <col style={{ width: 'auto' }} />
          </colgroup>
          <tbody>
            <tr>
              <th>
                <GLabelAtom align="left">이름 등록</GLabelAtom>
              </th>
              <td>
                <InputField
                  status="default"
                  align="left"
                  width="240px"
                  placeholder="이름을 입력하세요"
                />
              </td>
              <th>
                <GLabelAtom align="left">연락처 등록</GLabelAtom>
              </th>
              <td>
                <InputField
                  width="240px"
                  height="32px"
                  status="default"
                  align="left"
                  value="12345678"
                />
              </td>
            </tr>
            <tr>
              <th>
                <GLabelAtom align="left">이메일 등록</GLabelAtom>
              </th>
              <td>
                <AlignBox>
                  <InputField width="140px" status="default" placeholder="이메일아이디" />
                  @
                  <GSelectMUIAtom
                    selectWidth="147.5px"
                    value={option}
                    handleChange={handleChange}
                    option={['lgensol.com', '']}
                    placeholder="선택"
                  />
                  <InputField status="disabled" placeholder="직접입력" disabled width="147.5px" />
                </AlignBox>
              </td>
            </tr>
          </tbody>
        </GbasicTable>
      </GbasicTableWrap>

      <ContentWrap>
        <ContentWrap>
          <SubTitleBtnArea>
            <SubTitleBox
              content="개인정보의 수집 및 이용에 대한 안내"
              showSubTitle={true}
              isVisibleComment={false}
              isVisibleInfo={false}
            />
          </SubTitleBtnArea>
          <TextArea status="default" width="100%" flex={true} borderColor="#DDE0DF" readOnly />
          <RadioWrap>
            <GridRadio option={['동의함', '동의안함']} value="privacyConsent" />
          </RadioWrap>
        </ContentWrap>
        <ContentItem>
          <SubTitleBtnArea>
            <SubTitleBox
              content="납품대금 지급에 관한 약정서"
              showSubTitle={true}
              isVisibleComment={false}
              isVisibleInfo={false}
            />
          </SubTitleBtnArea>
          <TextArea status="default" width="100%" flex={true} borderColor="#DDE0DF" readOnly />
          <RadioWrap>
            <GridRadio option={['동의함', '동의안함']} value="DeliveryFeeConsent" />
          </RadioWrap>
        </ContentItem>
        <ContentItem>
          <SubTitleBtnArea>
            <SubTitleBox
              content="고유식별정보의 수집 및 이용"
              showSubTitle={true}
              isVisibleComment={false}
              isVisibleInfo={false}
            />
          </SubTitleBtnArea>
          <TextArea status="default" width="100%" flex={true} borderColor="#DDE0DF" readOnly />
          <RadioWrap>
            <GridRadio option={['동의함', '동의안함']} value="idConsent" />
          </RadioWrap>
        </ContentItem>
      </ContentWrap>

      <GButtonLayout marginBottom="8px">
        <GButton
          chkImg={Save}
          txt="저장"
          sizes="medium"
          btnstyled="contained"
          btnColor="primary"
          hasImg={true}
          hasTxt={true}
        />
      </GButtonLayout>
    </>
  );
};

const ContentWrap = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const ContentItem = styled(ContentWrap)`
  margin-top: 12px;
`;

export default Signup;
