import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { AbnoList, AbnoSearchParams } from 'pages/vars/abnormal/AbnormalMonitorPage';
import { callApi } from 'utils/ApiUtil';

// 이상징후 조회(관리자)
export const getAbnormalListApi = async (params: AbnoSearchParams) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/abnmonitor?regReqDtSt=${params.regReqDtSt}&regReqDtEnd=${params.regReqDtEnd}`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
  };
  const response: CommonResponse<AbnoList[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : []) as AbnoList[];
};
