import React, { useEffect, useState } from 'react';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import DarkSearch from 'components/asset/images/DarkSearch.svg';
import styled from '@emotion/styled';
import ErrorIcon from 'components/asset/images/error.svg';
import WarningIcon from 'components/asset/images/warning.svg';
import ConfirmedIcon from 'components/asset/images/confirmed.svg';
import chipUser from 'components/asset/images/chipUser.svg';
import { useTranslation } from 'react-i18next';

interface InputChipBoxProps {
  chips?: string[];
  inputValue?: string;
  value?: string;
  width?: string | '100%';
  status?: 'default' | 'error' | 'warning' | 'confirm' | undefined;
  format?: string | undefined;
  onClickFunction?: () => void;
  type?: 'singleChip' | 'multipleChip' | 'singleInput' | 'multiInput';
  setValue?: (value: string) => void;
  readonly?: boolean;

  chipType?: 'default' | 'user';
  chipImg?: string;

  height?: string | '32px';
  type2?: '1' | '2';
  isInGrid?: boolean;
}

export default function ChipInputBox({
  width,
  height,
  status = 'default',
  chipType = 'default',
  chipImg = '${avatarUser}',
  format,
  onClickFunction,
  value,
  type,
  setValue,
  readonly = false,
}: InputChipBoxProps) {
  const { t } = useTranslation();
  //단일 조건과 다중 조건 걸어야함.
  useEffect(() => {
    if (value) {
      setChips([value]);
      if (setValue && (type === 'multipleChip' || type === 'multiInput')) {
        setValue(value as string);
      }
    } else {
      setChips([]);
    }
  }, [value]);

  const [chips, setChips] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string>(value ? value : '');

  const onChange = (_event: React.ChangeEvent<any>, value: string[]) => {
    setChips(value);
  };

  const onInputChange = (_event: React.ChangeEvent<any>, newInputValue: string) => {
    if (type === 'singleInput' || type === 'multiInput') {
      setInputValue(newInputValue);
    }
  };

  const onKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && inputValue.trim() !== '') {
      if (type === 'singleInput') {
        setChips([inputValue]);
      } else if (type === 'multiInput') {
        setChips((prevChips) => [...prevChips, inputValue]);
        setInputValue('');
      }
    }
  };

  //칩이 변경됐을 때 상위 컴포넌트의 상태 변경해주는 내용
  useEffect(() => {
    if (chips.length >= 1) {
      setValue && setValue(chips.join());
    } else if (chips.length === 0) {
      setValue && setValue('');
    } else {
      setValue && setValue('');
    }
  }, [chips]);

  return (
    <Container
      width={width}
      height={height}
      status={status}
      type={type}
      readonly={readonly}
      chipType={chipType}
      chipImg={chipImg}
    >
      <Autocomplete
        multiple
        options={[]}
        freeSolo
        value={chips}
        onChange={onChange}
        inputValue={inputValue}
        onInputChange={onInputChange}
        onKeyPress={onKeyPress}
        readOnly={readonly}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <ChipContainer key={index}>
              <Chip label={option} {...getTagProps({ index })} />
            </ChipContainer>
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            disabled
            placeholder={
              chips.length === 0
                ? format
                  ? format
                  : (t('com.label.00670', '__돋보기를 클릭하세요') as string)
                : ''
            }
            InputProps={{
              style: {
                minHeight: '32px',
                fontWeight: '500',
                padding: readonly ? '5px 8px' : '5px 8px 5px 28px',
                caretColor: 'transparent',
              },

              startAdornment: <>{params.InputProps.startAdornment}</>,
            }}
          />
        )}
      />
      {!readonly && <SearchButton onClick={onClickFunction}></SearchButton>}
    </Container>
  );
}

const Container = styled.div<InputChipBoxProps>`
  z-index: 10;
  position: relative;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  ${(props) => (props.readonly ? `pointer-events: none;` : ``)};

  &::after {
    ${(props) => props.status === 'error' && `content: url('${ErrorIcon}');`}
    ${(props) => props.status === 'warning' && `content: url('${WarningIcon}');`}
  ${(props) => props.status === 'confirm' && `content: url('${ConfirmedIcon}');`}
  position: absolute;
    top: 50%;
    right: 8px;
    transform: translate(0, -50%);
    width: 16px;
    height: 16px;
    background-color: #fff;
    cursor: pointer;
  }

  .MuiAutocomplete-root {
    .MuiTextField-root {
      .MuiOutlinedInput-root {
        ${(props) =>
          props.type2 === '1' ? 'padding: 6px 50px 6px 30px;' : 'padding: 6px 50px 6px 8px;'}
        display: flex;
        gap: 4px 2px;
        ${(props) =>
          props.isInGrid
            ? `background-color: transparent`
            : props.readonly
            ? `background-color: #f1f4f3`
            : `background-color: #fff`};
        .MuiAutocomplete-input {
          min-width: 0;
          height: 20px;
          padding: 0;

          &::placeholder {
            color: #979998;
            opacity: 1;
          }
        }
        .MuiAutocomplete-endAdornment {
          top: 50%;
          transform: translate(0, -50%);
          ${(props) => (props.type2 === '1' ? `display:flex; flex-direction: row-reverse;` : ``)}
          .MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator {
            width: 14px;
            height: 14px;
            margin-right: 0;
            background-color: #5b5c5b;
            .MuiSvgIcon-root {
              padding: 2px 2px 2px 4px;
              color: #fff;
            }
          }
          .MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator {
            ${(props) => (props.type2 === '1' ? `width: 0; height: 0; padding: 0;` : ``)}
            .MuiSvgIcon-root {
              ${(props) => (props.type2 === '1' ? `width: 0;` : ``)}
            }
          }
          .MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator {
            ${(props) => (props.type2 === '1' ? `width: 0; height: 0; padding: 0;` : ``)}
            .MuiSvgIcon-root {
              ${(props) => (props.type2 === '1' ? `width: 0;` : ``)}
            }
          }
        }
        .MuiOutlinedInput-notchedOutline {
          top: 0;
          width: 100%;
          padding: 0;
          border-radius: 2px;
          ${(props) => props.isInGrid && `border: none;`}
          ${(props) =>
            !props.isInGrid && props.status === 'default' && `border: 1px solid #b9bcbb;`}
        ${(props) => !props.isInGrid && props.status === 'error' && `border: 1px solid #F94B50;`}
        ${(props) => !props.isInGrid && props.status === 'warning' && `border: 1px solid #FF9322;`}
        ${(props) => !props.isInGrid && props.status === 'confirm' && `border: 1px solid #00806A;`}
        }
      }
    }
  }

  .MuiOutlinedInput-root {
    padding-right: 30px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  /* chip tag */
  .MuiAutocomplete-root .MuiAutocomplete-tag {
    margin: 0;
    max-width: none;
    height: 20px;

    & > span {
      height: 20px;
      color: #1f1f1f;
      font-size: 13px;
      font-weight: 400;
      line-height: 150%;
      ${(props) =>
        props.chipType === 'default' ? `padding: 0 16px 0 8px;` : `padding: 0 16px 0 26px;`}
      position: relative;
      ${(props) =>
        props.chipType === 'user' &&
        `
      &::after {
        content: url('${chipUser}');
        position: absolute;
        top: 50%;
        left: 4px;
        transform: translate(0, -50%);
        width: 16px;
        height: 16px;
      }
      `}
    }
    & > svg {
      width: 14px;
    }
  }

  .MuiAutocomplete-popupIndicator {
    display: none;
  }
`;

const ChipContainer = styled.div`
  height: 20px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  & > div {
    width: 100%;
    height: 100%;
    & > span {
      height: 100%;
      padding: 0 16px 0 8px;
      color: #1f1f1f;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 19.5px */
    }
    & > svg {
      width: 14px;
    }
  }

  .MuiButtonBase-root-MuiChip-root {
    height: auto;
  }

  .MuiChip-label {
    overflow: visible;
  }

  .MuiChip-deleteIcon {
    margin-right: 8px;
  }
`;

const SearchButton = styled.div`
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translate(0, -50%);
  &::after {
    content: url('${DarkSearch}');
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    width: 16px;
    height: 16px;
  }
  cursor: pointer;
`;
