import { SelectHTMLAttributes, useEffect, useMemo } from 'react';
import { formatCdNm } from 'utils/Formatters';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import styled from '@emotion/styled';
import { GlobalTokenColor } from 'ui/theme/Color';
import CloseBtn from 'components/asset/images/Close.svg';
import { HelperText } from 'components/atom/input';
import ErrorIcon from 'components/asset/images/error.svg';
import WarningIcon from 'components/asset/images/warning.svg';
import ConfirmedIcon from 'components/asset/images/confirmed.svg';

export interface GCodeSelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  id: string;
  labelKey: string;
  emptyLabel?: string;
  listData: any[];
  onChange?: (e: any) => void;
  width?: string;
  selectFirstItem?: boolean;
  readonly?: boolean; // Select의 readonly를 지정
  readonlyInput?: boolean; // Select안의 input readonly를 지정 (input 검색을 막음)
  disabled?: boolean;
  inputPlaceholder?: string;
  onlyShowName?: boolean; // true일때, option앞의 []코드를 빼고 option값만 보여줌
  isRequired?: boolean;
  status?: 'default' | 'error' | 'warning' | 'confirmed';
  helperText?: string;
}

export const GCodeSelect = ({
  labelKey,
  emptyLabel,
  listData,
  onChange,
  selectFirstItem,
  style,
  readonly,
  readonlyInput,
  disabled,
  inputPlaceholder,
  onlyShowName,
  width,
  isRequired,
  status = 'default',
  helperText,
  ...rest
}: GCodeSelectProps) => {
  const optionList = useMemo(
    () => (emptyLabel ? [{ [rest.id]: '', [labelKey]: emptyLabel }, ...listData] : listData),
    [listData]
  );

  useEffect(() => {
    emitOnChangeEvent();
  }, [listData]);

  const emitOnChangeEvent = () => {
    const index = emptyLabel ? 1 : 0;
    const item = optionList[index];
    if (selectFirstItem && !rest.value && onChange && optionList.length > index) {
      const e: any = {
        target: {
          id: rest.id,
          value: item?.[rest.id],
          item: item[index],
        },
      };
      onChange(e);
    }
  };

  const handleChange = (e, newItem) => {
    if (newItem) {
      const value = newItem[rest.id];
      onChange?.({
        target: {
          id: rest.id,
          value: value,
          item: value ? newItem : {},
        },
      });
    }
  };

  // option 값이 20개이하라면 검색을 막는다.
  const autoReadonlyInput = useMemo(() => listData.length < 20, [listData]);
  const disableClearable = () =>
    readonly || readonlyInput || autoReadonlyInput || disabled || false;

  // option 검색 시 렌더링할 필터링된 옵션
  const filterOptions = (list, state) => {
    const filterFn = (item: any) =>
      formatCdNm(item[rest.id], item[labelKey], onlyShowName)
        ?.toLocaleLowerCase()
        .match(state.inputValue.toLocaleLowerCase());

    return state.inputValue ? list.filter(filterFn) : list;
  };

  // option이 주어진 값을 나타내는지 확인
  const isOptionEqualToValue = (option: any, value: any) => option?.[rest.id] === value?.[rest.id];

  // renderOption이 제공되지 않았을 때
  const selectedItem = useMemo(() => {
    return rest.value
      ? listData.filter((item) => item[rest.id] === rest.value)?.[0]
      : {
          [rest.id]: '',
          [labelKey]: emptyLabel,
        };
  }, [listData, rest.value]);
  const getOptionLabel = (value) => {
    return formatCdNm(value?.[rest.id] || '', selectedItem?.[labelKey], onlyShowName);
  };

  const renderOption = (props, option) => {
    const displayText = formatCdNm(option[rest.id], option[labelKey], onlyShowName);
    return (
      <Option component="li" key={displayText} {...props}>
        {displayText}
      </Option>
    );
  };

  const renderInput = (params) => {
    return (
      <SelectInput
        {...params}
        inputProps={{
          ...params.inputProps,
          readOnly: readonly || readonlyInput || autoReadonlyInput,
        }}
        placeholder={inputPlaceholder}
      />
    );
  };

  const renderSelect = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: `${width}` }}>
        <Select
          id="size-small-outlined"
          width={width}
          options={optionList}
          onChange={handleChange}
          disabled={disabled}
          disabledStyle={disabled}
          readOnly={readonly}
          readonlyStyle={readonly}
          disableClearable={disableClearable()}
          value={{ [rest.id]: rest.value }}
          filterOptions={filterOptions}
          isOptionEqualToValue={isOptionEqualToValue}
          getOptionLabel={getOptionLabel}
          renderOption={renderOption}
          renderInput={renderInput}
          status={status}
          isRequired={isRequired}
        />
        {helperText && <Helper status={status}>{helperText}</Helper>}
      </div>
    );
  };

  return <>{renderSelect()}</>;
};

const Helper = styled(HelperText)<{ status: string | undefined }>``;

const Option = styled(Box)<{ props: any }>`
  font-size: 13px;
  font-weight: 400;
  padding: 6px 20px 6px 8px !important;
  background-color: ${GlobalTokenColor.ESGreyWHite};

  &:hover {
    background-color: ${GlobalTokenColor.Secondary50} !important;
  }
`;

const SelectInput = styled(TextField)<{ props: any }>`
  & .MuiAutocomplete-input {
    padding: 0px !important;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
  }
  .MuiInputBase-formControl {
    border: none;
  }
`;

const Select = styled(Autocomplete)<{
  width: string | undefined;
  readonlyStyle: boolean | undefined;
  disabledStyle: boolean | undefined;
  status: string | undefined;
  isRequired: boolean | undefined;
}>`
  width: ${(prop) => prop.width || '100%'};
  margin: 0;
  position: relative;

  &:before {
    ${(props) => (props.isRequired ? `content : ""` : ``)};
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: ${GlobalTokenColor.StatusError500};
    position: absolute;
    left: 2px;
    top: 2px;
  }

  &::after {
    ${(props) => props.status === 'error' && `content: url('${ErrorIcon}');`}
    ${(props) => props.status === 'warning' && `content: url('${WarningIcon}');`}
    ${(props) => props.status === 'confirmed' && `content: url('${ConfirmedIcon}');`}
    position: absolute;
    top: 50%;
    right: 24px;
    transform: translate(0, -50%);
    width: 16px;
    height: 16px;
    background-color: #fff;
    cursor: pointer;
  }

  .MuiSvgIcon-root {
    width: 0.8em;
    height: 0.8em;
    right: 5px;
    top: calc(50% - 0.4em);
  }

  & .Mui-readOnly {
    background: ${GlobalTokenColor.ESGrey100} !important;
    color: ${GlobalTokenColor.ESGrey900} !important;
  }
  &.Mui-disabled {
    background: ${GlobalTokenColor.ESGrey100} !important;
    color: ${GlobalTokenColor.ESGrey900} !important;
  }

  & .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
    right: 5px !important;
    top: 4px;
    button {
      transform: none;
    }
  }

  & .MuiOutlinedInput-root {
    padding: 6px 0 6px 8px;
  }

  & .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-root .MuiOutlinedInput-root {
    padding-right: 25px !important;
  }

  &.MuiAutocomplete-root.Mui-focused .MuiAutocomplete-clearIndicator,
  &.MuiAutocomplete-root:hover .MuiAutocomplete-clearIndicator,
  .MuiIconButton-root-MuiAutocomplete-clearIndicator {
    transform: none;
    width: 18px;
    height: 18px;
    background: url(${CloseBtn}) no-repeat center center;

    > svg {
      display: none;
    }
  }

  &.MuiAutocomplete-root .MuiOutlinedInput-root {
    height: 32px;
    ${(props) => props.status == 'error' && `border: 1px solid #FDA293 !important;`}
    ${(props) => props.status == 'warning' && `border: 1px solid #FFCB7A !important;`}
    ${(props) => props.status == 'confirmed' && `border: 1px solid #56D8AA !important;`}
    border: 1px solid ${GlobalTokenColor.ESGrey400};
    border-radius: 2px !important;
  }

  &.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-root {
    background: ${(props) =>
      props.readonlyStyle
        ? `${GlobalTokenColor.ESGrey100}`
        : props.disabledStyle
        ? `${GlobalTokenColor.ESGrey100}`
        : `${GlobalTokenColor.ESGreyWHite}`};
  }

  & .MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline {
    border: none;
  }

  &.MuiAutocomplete-root .MuiOutlinedInput-root.Mui-focused {
    border: 1px solid ${GlobalTokenColor.ESGrey700} !important;
  }
`;
