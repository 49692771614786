import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { CommonCode, CommonCodeGroup, CommonCodeHeader } from 'models/admin/CommonCode';
import { DepartmentResponse } from 'models/admin/Department';
import { Code, CommonCodeCondition } from 'models/common/CommonCode';
import { FileInfo } from 'models/admin/FileInfo';
import { callJwtApi } from 'utils/JwtApiUtil';
import { AccessInfo } from 'models/vars/common/Common';
import axios from 'axios';
import useAuthStore from 'stores/useAuthStore';
import { callApi } from 'utils/ApiUtil';

export const getAccessInfo = async (clientLocale: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/api/common-vars/access-info`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({ clientLocale: clientLocale }),
  };
  const response: CommonResponse<AccessInfo> = await callApi(request);

  return response;
};

export const getJwtCommonCodes = async (cmnGrCd: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/api/common/commonCodes`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({ cmnGrCd: cmnGrCd }),
  };
  const response: CommonResponse<CommonCode[]> = await callJwtApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as CommonCode[];
};

export const getJwtCommonHqCodes = async (cmnGrCd: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/commonHqCodes/hq`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({ cmnGrCd: cmnGrCd }),
  };
  const response: CommonResponse<CommonCode> = await callJwtApi(request);

  return response;
  //return (response.successOrNot === 'Y' ? response.data : null) as CommonCode;
};

export const getJwtCommonCodeNames = async (cmnGrCd: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/api/common/commonCodeNames`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({ cmnGrCd: cmnGrCd }),
  };

  const response: CommonResponse<Code[]> = await callJwtApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as Code[];
};

export const getJwtDepartments = async (searchItem?: string, deptNm?: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/api/common/departments`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({
      searchItem: searchItem ? searchItem : '',
      deptNm: deptNm ?? '',
    }),
  };
  const response: CommonResponse<any> = await callJwtApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as DepartmentResponse;
};

export const getJwtAllDepartments = async () => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/api/common/departments`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
  };
  const response: CommonResponse<any> = await callJwtApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as DepartmentResponse;
};

export const findVarsFiles = async (atchFileGrId: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/api/common/files`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    headers: {},
    queryParams: new URLSearchParams({ atchFileGrId: atchFileGrId }),
  };
  const response: CommonResponse<FileInfo[]> = await callJwtApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as FileInfo[];
};

export const uploadVarsFiles = async (formData: FormData) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/api/common/file/upload`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    bodyParams: formData,
  };

  const response: CommonResponse<any> = await callJwtApi(request);

  return response.successOrNot === 'Y' ? response.data : null;
};

export const modifyVarsFiles = async (files: FileInfo[]) => {
  const request: CommonRequest = {
    method: Method.PUT,
    url: `/api/common/files`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    bodyParams: files,
  };

  const response: CommonResponse<any> = await callJwtApi(request);

  return response.successOrNot === 'Y' ? response.data : null;
};

export const getJwtUserInfo = async () => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/api/common/userinfo-short`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
  };
  const response: CommonResponse<string> = await callJwtApi(request);

  return response.data as string;
};

export const downloadAllFilesToFileNameVars = async (atchFileGrId, fileName) => {
  try {
    if (!atchFileGrId) return;

    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/common/file/download/all?atchFileGrId=${atchFileGrId}`,
      {
        responseType: 'blob',
      }
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  } catch (error) {
    console.log(error);
  }
};
