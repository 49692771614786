import { ColDef } from 'ag-grid-community';
import { DefaultCellTooltip } from 'components/grids/tooltip/DefaultCellTooltip';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ITagCellRendererParams, TagCellRenderer } from 'components/grids/TagCellRenderer';

export const useCheckDefs = () => {
  const { t } = useTranslation();

  const defaultColDef2 = useMemo((): ColDef => {
    return {
      sortable: false,
      resizable: true,
      cellStyle: { textAlign: 'center' },
      tooltipComponent: DefaultCellTooltip,
    };
  }, []);

  const colDefs2 = useMemo((): any[] => {
    return [
      {
        headerName: t('컬럼1코드', 'No.'),
        field: 'column1',
        tooltipField: 'column1',
        cellStyle: { display: 'flex', justifyContent: 'center', width: '80px' },
        width: '80px',
        minWidth: 80,
      },
      {
        headerName: t('컬럼2코드', '상태'),
        field: 'column2',
        tooltipField: 'column2',
        cellStyle: { display: 'flex', justifyContent: 'center', width: '112px' },
        width: '112px',
        minWidth: 112,
        cellRenderer: TagCellRenderer,
        cellRendererParams: (params: ITagCellRendererParams) => {
          return {
            label: '지급완료',
            status: 'completion',
            ...params,
          } as ITagCellRendererParams;
          //   return {
          //     label: '지급예정',
          //     status: 'waiting',
          //     ...params,
          //   } as ITagCellRendererParams;
        },
      },
      {
        headerName: t('컬럼3코드', '현금/어음구분?'),
        cellStyle: { display: 'flex', justifyContent: 'center', width: '152px' },
        field: 'column3',
        tooltipField: 'column3',
        width: '152px',
        minWidth: 152,
      },
      {
        headerName: t('컬럼4코드', '발생일'),
        field: 'column4',
        tooltipField: 'column4',
        cellStyle: { display: 'flex', justifyContent: 'center', width: '152px' },
        width: '152px',
        minWidth: 152,
      },
      {
        headerName: t('컬럼5코드', '화폐'),
        field: 'column5',
        tooltipField: 'column5',
        cellStyle: { display: 'flex', justifyContent: 'center', width: '80px' },
        width: '80px',
        minWidth: 80,
      },
      {
        headerName: t('컬럼6코드', '지급금액'),
        field: 'column6',
        tooltipField: 'column6',
        cellStyle: { display: 'flex', justifyContent: 'right' },
        flex: 1,
        minWidth: 160,
      },
      {
        headerName: t('컬럼7코드', '지급문서'),
        // field: 'column7',
        // tooltipField: 'column7',
        cellStyle: { display: 'flex', justifyContent: 'right', width: '112px' },
        width: '112px',
        minWidth: 112,
      },
      {
        headerName: t('컬럼8코드', '증빙일'),
        field: 'column8',
        tooltipField: 'column8',
        cellStyle: { display: 'flex', justifyContent: 'center', width: '152px' },
        width: '152px',
        minWidth: 152,
      },
      {
        headerName: t('컬럼9코드', '적요'),
        field: 'column8',
        tooltipField: 'column8',
        cellStyle: { display: 'flex', justifyContent: 'center', width: '80px' },
        width: '80px',
        minWidth: 80,
      },
      {
        headerName: t('com.label.00631', '__금액'),
        field: 'column8',
        tooltipField: 'column8',
        cellStyle: { display: 'flex', justifyContent: 'right' },
        flex: 1,
        minWidth: 160,
      },
      {
        headerName: t('컬럼11코드', '할인'),
        // field: 'column8',
        // tooltipField: 'column8',
        cellStyle: { display: 'flex', justifyContent: 'right', width: '80px' },
        width: '80px',
        minWidth: 80,
      },
      {
        headerName: t('컬럼11코드', '구매담당자'),
        // field: 'column8',
        // tooltipField: 'column8',
        cellStyle: { display: 'flex', justifyContent: 'right', width: '152px' },
        width: '152px',
        minWidth: 152,
      },
      {
        headerName: t('컬럼11코드', 'PO'),
        field: 'column8',
        tooltipField: 'column8',
        cellStyle: { display: 'flex', justifyContent: 'center', width: '152px' },
        width: '152px',
        minWidth: 152,
      },
    ];
  }, []);

  const handleAprvDetailBtn = (data) => {
    console.log('화면 연결이 필요합니다.');
  };

  return { defaultColDef2, colDefs2 };
};
