import styled from '@emotion/styled';
import { TextField } from '@mui/material';

interface PropsType {
  id: string;
  type?: string;
  label?: string;
  children?: React.ReactNode;
  time?: boolean;
  disabled?: boolean;
  onChange?: (e: any) => void;
}

const LoginInput = ({ id, type, label, children, time, disabled, onChange }: PropsType) => {
  return (
    <TextInputWrap>
      <TextFieldInput
        type={type}
        // id="standard-basic"
        id={id}
        label={label}
        variant="standard"
        time={time}
        disabled={disabled}
        onChange={onChange}
      />
      {children}
    </TextInputWrap>
  );
};

const TextInputWrap = styled.div`
  display: flex;
  border-bottom: 1px solid #b9bcbb;
  width: 340px;
  height: 48px;
`;

const TextFieldInput = styled(TextField)<{ time?: boolean }>`
  width: ${({ time }) => (time ? '77%' : '85%')};
  border: none;

  &.MuiFormControl-root {
    & .MuiFormLabel-root {
      font-size: 20px;
      font-weight: 400;
      line-height: 20px;
    }
    & .MuiInputLabel-shrink {
      font-size: 12px;
      font-weight: 400;
      line-height: 12px;
    }
    & .MuiFormLabel-root.Mui-focused {
      color: #979998;
      font-size: 12px;
      font-weight: 400;
      line-height: 12px;
    }
    & .MuiInputBase-root {
      &:after {
        border-bottom: none;
      }
      &:before {
        border: none;
      }
      &:hover:not(.Mui-disabled, .Mui-error):before {
        border: none;
      }
    }
  }
`;

export default LoginInput;
