import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { SelectChangeEvent } from '@mui/material';
import { GButton } from 'components/atom/button';
import SearchMolecule, { SearchItemWrap, SearchWrap } from 'components/molecule/SearchMolecule';
import SubTitleBox from 'components/molecule/SubTitleBox';
import { SubTitleBtnArea } from 'components/organism/SubTitleBtnArea';
import { GbasicTable, GbasicTableWrap } from 'components/tables/GTable';
import { GLabelAtom } from 'components/atom/label';
import InputField from 'components/atom/input';
import { AlignBox, AlignItem } from 'components/organism/AlignBox';
import GSelectMUIAtom from 'components/atom/select';
import Delete from 'components/asset/images/Delete.svg';
import SingleChipSelect from 'components/atom/singleChipSelect';
import { useColumnDefsForApprovalList } from './gridDef/OverseasColumnDefs';
import { SampleResponse } from 'models/sample/Sample';
import { useLoading } from 'components/process/Loading';
import { useMessageBar } from 'components/process/MessageBar';
import { getSamples } from 'apis/sample/SampleApi';
import { AgGridReact } from 'ag-grid-react';
import { GContentGrid } from 'components/layouts/GContentGrid';
import GButtonLayout from 'components/molecule/GbuttonLayout';
import { GButtonBox } from 'components/layouts/GLayoutBox';
import GTabs from 'components/organism/GTabs';
import Save from 'components/asset/images/Confirm.svg';
import GridRadio from 'components/molecule/GridRadio';
import New from 'components/asset/images/Pen.svg';

const Accountregist = () => {
  const [option, setOption] = React.useState('1');
  const handleChange = (event: SelectChangeEvent) => {
    setOption(event.target.value);
  };

  const [sampleList, setSampleList] = useState<SampleResponse[]>([]);
  const gridApiRef = useRef<any>(null);
  const { defaultColDef, colDefs } = useColumnDefsForApprovalList();
  const { openLoading } = useLoading();
  const { openMessageBar } = useMessageBar();

  useEffect(() => {
    loadSampleList();
  }, []);

  const makeSearchParams = () => {
    return {
      start: '0',
      pageSize: 30,
    };
  };

  const handleError = (e: Error) => {
    openLoading(false);
    openMessageBar('error', e.message);
  };

  const loadSampleList = async () => {
    getSamples(makeSearchParams())
      .then((data) => {
        setSampleList(data.list);
      })
      .catch(handleError);
  };

  // S : 02.01 [Dev] 디자인에 수정에 따라 코드 수정
  return (
    <Wrap>
      <GButtonLayout paddingTop="0px" marginBottom="12px">
        <GButtonBox>
          <GButton
            chkImg={New}
            txt="Add"
            sizes="medium"
            btnstyled="outline"
            btnColor="normal"
            hasImg={true}
            hasTxt={true}
          />
          <GButton
            chkImg={Save}
            txt="Modify and confirm"
            sizes="medium"
            btnstyled="contained"
            btnColor="primary"
            hasImg={true}
            hasTxt={true}
          />
        </GButtonBox>
      </GButtonLayout>

      <SearchWrap>
        <SearchItemWrap nonButton={true}>
          <SearchMolecule
            searchWidth="100%"
            type="radio"
            labelTitle="KRW/FC Type"
            labelPaddingTop="0px"
            isRequired={true}
            radioValue="KrwTypeRadio"
            radioOption={['KRW', 'NON-KRW']}
            alignItems="center"
          />
        </SearchItemWrap>
      </SearchWrap>

      <SubTitleBtnArea>
        <SubTitleBox showSubTitle={true} content="Vendor Information" />
      </SubTitleBtnArea>
      <GbasicTableWrap isBottomMgn={true}>
        <GbasicTable>
          <colgroup>
            <col style={{ width: '160px' }} />
            <col style={{ width: 'auto' }} />
            <col style={{ width: '160px' }} />
            <col style={{ width: 'auto' }} />
          </colgroup>
          <tbody>
            <tr>
              <th>
                <GLabelAtom align="left">Name</GLabelAtom>
              </th>
              <td>
                <InputField
                  status="readonly"
                  width="100%"
                  value="LG ENERGY SOLUTION ARIZONA ESS INC."
                  readOnly
                />
              </td>
              <th>
                <GLabelAtom align="left">Tax ID</GLabelAtom>
              </th>
              <td>
                <InputField status="default" width="100%" placeholder="Input Tax ID" />
              </td>
            </tr>
            <tr>
              <th>
                <GLabelAtom align="left">CEO Name</GLabelAtom>
              </th>
              <td>
                <InputField status="readonly" width="240px" value="SAM KIM" readOnly />
              </td>
              <th>
                <GLabelAtom align="left" isRequired={true}>
                  Account Type
                </GLabelAtom>
              </th>
              <td>
                <GridRadio value="accountTypeRadio" option={['Individual', 'Corporation']} />
              </td>
            </tr>
            <tr>
              <th>
                <GLabelAtom align="left">Corporation No.</GLabelAtom>
              </th>
              <td>
                <InputField status="readonly" width="240px" value="111011-2487050" readOnly />
              </td>
              <th>
                <GLabelAtom align="left">Vendor Code</GLabelAtom>
              </th>
              <td>
                <InputField status="readonly" width="240px" value="KR000211" readOnly />
              </td>
            </tr>
            <tr>
              <th>
                <GLabelAtom align="left">Adress</GLabelAtom>
              </th>
              <td colSpan={3}>
                <AlignBox>
                  <InputField
                    status="readonly"
                    width="100%"
                    value="300 North Central Avenue. sui"
                    readOnly
                  />
                  <AlignItem>
                    <InputField
                      status="disabled"
                      width="100%"
                      placeholder="Phoenix 85012"
                      disabled
                    />
                  </AlignItem>
                  <GButton
                    chkImg={Delete}
                    sizes="medium"
                    btnstyled="outline"
                    btnColor="normal"
                    hasImg={true}
                    hasTxt={false}
                  />
                </AlignBox>
              </td>
            </tr>
            <tr>
              <th>
                <GLabelAtom align="left">City</GLabelAtom>
              </th>
              <td>
                <InputField status="disabled" width="240px" placeholder="New York" disabled />
              </td>
              <th>
                <GLabelAtom align="left">Country Key</GLabelAtom>
              </th>
              <td>
                <AlignBox>
                  <InputField status="disabled" width="80px" placeholder="US" disabled />
                  <InputField status="disabled" width="156px" placeholder="USA" disabled />
                </AlignBox>
              </td>
            </tr>
          </tbody>
        </GbasicTable>
      </GbasicTableWrap>

      <SubTitleBtnArea>
        <SubTitleBox showSubTitle={true} content="Vendor Contact Info - the Person in Charge" />
      </SubTitleBtnArea>
      <GbasicTableWrap isBottomMgn={true}>
        <GbasicTable>
          <colgroup>
            <col style={{ width: '160px' }} />
            <col style={{ width: 'auto' }} />
            <col style={{ width: '160px' }} />
            <col style={{ width: 'auto' }} />
          </colgroup>
          <tbody>
            <tr>
              <th>
                <GLabelAtom align="left">Name</GLabelAtom>
              </th>
              <td>
                <InputField status="disabled" width="240px" placeholder="GEO RAE KIM" disabled />
              </td>
              <th>
                <GLabelAtom align="left">Telephone No.</GLabelAtom>
              </th>
              <td>
                <InputField status="disabled" width="240px" placeholder="12345678" disabled />
              </td>
            </tr>
            <tr>
              <th>
                <GLabelAtom align="left">E-mail</GLabelAtom>
              </th>
              <td>
                <InputField
                  status="disabled"
                  width="100%"
                  placeholder="georae2422@gmail.com"
                  disabled
                />
              </td>
              <th>
                <GLabelAtom align="left">E-mail(ADD)</GLabelAtom>
              </th>
              <td>
                <InputField
                  status="disabled"
                  width="100%"
                  placeholder="georae2422@gmail.com"
                  disabled
                />
              </td>
            </tr>
          </tbody>
        </GbasicTable>
      </GbasicTableWrap>

      <SubTitleBtnArea>
        <SubTitleBox showSubTitle={true} content="Vendor Contact Info. - Finance Department" />
      </SubTitleBtnArea>
      <GbasicTableWrap isBottomMgn={true}>
        <GbasicTable>
          <colgroup>
            <col style={{ width: '160px' }} />
            <col style={{ width: 'auto' }} />
            <col style={{ width: '160px' }} />
            <col style={{ width: 'auto' }} />
          </colgroup>
          <tbody>
            <tr>
              <th>
                <GLabelAtom isRequired={false} align="left">
                  Name
                </GLabelAtom>
              </th>
              <td>
                <InputField status="disabled" width="240px" placeholder="JA GUEN KIM" disabled />
              </td>
              <th>
                <GLabelAtom align="left">Telephone No.</GLabelAtom>
              </th>
              <td>
                <InputField status="disabled" width="240px" placeholder="12345678" disabled />
              </td>
            </tr>
            <tr>
              <th>
                <GLabelAtom align="left">E-mail</GLabelAtom>
              </th>
              <td colSpan={3}>
                <InputField
                  status="disabled"
                  width="100%"
                  placeholder="georae2422@gmail.com"
                  disabled
                />
              </td>
            </tr>
          </tbody>
        </GbasicTable>
      </GbasicTableWrap>

      <SubTitleBtnArea>
        <SubTitleBox showSubTitle={true} content="LG Energy Solution Contact Info." />
      </SubTitleBtnArea>
      <GbasicTableWrap isBottomMgn={true}>
        <GbasicTable>
          <colgroup>
            <col style={{ width: '160px' }} />
            <col style={{ width: 'auto' }} />
            <col style={{ width: '160px' }} />
            <col style={{ width: 'auto' }} />
          </colgroup>
          <tbody>
            <tr>
              <th>
                <GLabelAtom align="left">Corporation No.</GLabelAtom>
              </th>
              <td>
                {/* [Dev] 칩안에 들어가는 내용은 디자인과 다른 임의의 내용이 들어가 있습니다. (기존 : C100) */}
                <SingleChipSelect disabled width="240px" height="32px" isChip={true} />
              </td>
              <th>
                <GLabelAtom align="left">Name</GLabelAtom>
              </th>
              <td>
                {/* [Dev] 칩안에 들어가는 내용은 디자인과 다른 임의의 내용이 들어가 있습니다. (기존 : Gwanwoo Kim/Professional/Insurance Team) */}
                <SingleChipSelect disabled width="356px" height="32px" isChip={true} />
              </td>
            </tr>
            <tr>
              <th>
                <GLabelAtom align="left">E-mail</GLabelAtom>
              </th>
              <td>
                <AlignBox>
                  <InputField status="disabled" width="140px" placeholder="kimelgee0123" disabled />
                  @
                  <GSelectMUIAtom
                    selectWidth="147.5px"
                    option={['lgensol.com', '옵션2', '옵션3']}
                    disabled={true}
                    value={option}
                    handleChange={handleChange}
                  />
                  <InputField status="disabled" width="147.5px" placeholder="insert" disabled />
                </AlignBox>
              </td>
              <th>
                <GLabelAtom align="left">Telephone No.</GLabelAtom>
              </th>
              <td>
                <InputField status="disabled" width="240px" placeholder="12345678" disabled />
              </td>
            </tr>
          </tbody>
        </GbasicTable>
      </GbasicTableWrap>

      <GTabs
        flex={true}
        defaultValue={1}
        title={['Account Information', 'Account Registration History']}
      >
        <div className="tab1">
          <SubTitleBtnArea>
            <SubTitleBox
              showSubTitle={true}
              content="Account Information"
              currentColumnCount={12}
              totalCoulmnCount={12}
              isVisibleComment={true}
            />
          </SubTitleBtnArea>
          <GContentGrid
            type="radio"
            isSortable={false}
            className="ag-theme-alpine"
            style={{ flex: 1, minHeight: '249px' }}
          >
            <AgGridReact
              defaultColDef={defaultColDef}
              columnDefs={colDefs}
              rowData={sampleList}
              rowSelection={'single'}
              enableCellTextSelection
              onGridReady={(params) => (gridApiRef.current = params.api)}
              tooltipShowDelay={0}
            ></AgGridReact>
          </GContentGrid>

          <GButtonLayout marginBottom="8px">
            <GButtonBox>
              <GButton
                chkImg={New}
                txt="Add"
                sizes="medium"
                btnstyled="outline"
                btnColor="normal"
                hasImg={true}
                hasTxt={true}
              />
              <GButton
                chkImg={Save}
                txt="Modify and confirm"
                sizes="medium"
                btnstyled="contained"
                btnColor="primary"
                hasImg={true}
                hasTxt={true}
              />
            </GButtonBox>
          </GButtonLayout>
        </div>
        <div className="tab2">내용 넣는 공간</div>
      </GTabs>
    </Wrap>
  );
};
const Wrap = styled.div`
  &:has(.tab2) {
    > div:first-child {
      display: none;
    }
  }
`;

export default Accountregist;
