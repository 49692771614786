import { ColDef } from 'ag-grid-community';
import { DefaultCellTooltip } from 'components/grids/tooltip/DefaultCellTooltip';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import radioIcon from 'components/asset/RadioColumn.svg';

export const useColumnHistoryDefs = () => {
  const { t } = useTranslation();

  const defaultColDef1 = useMemo((): ColDef => {
    return {
      sortable: false,
      resizable: true,
      cellStyle: { textAlign: 'center' },
      tooltipComponent: DefaultCellTooltip,
    };
  }, []);

  const colDefs1 = useMemo((): any[] => {
    return [
      {
        headerName: t('컬럼1코드', 'NO.'),
        width: 80,
        field: 'column1',
        tooltipField: 'column1',
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: t('컬럼2코드', '이전계좌구분'),
        width: 100,
        field: 'column2',
        tooltipField: 'column2',
        cellStyle: { textAlign: 'left' },
      },
      {
        headerName: t('컬럼3코드', '계좌구분'),
        width: 100,
        field: 'column3',
        tooltipField: 'column3',
        cellStyle: { textAlign: 'left' },
      },
      {
        headerName: t('컬럼4코드', '이전은행'),
        width: 100,
        field: 'column4',
        tooltipField: 'column4',
        cellStyle: { textAlign: 'left' },
      },
      {
        headerName: t('컬럼5코드', '은행'),
        width: 100,
        field: 'column5',
        tooltipField: 'column5',
        cellStyle: { textAlign: 'left' },
      },
      {
        headerName: t('컬럼6코드', '주계좌'),
        width: 80,
        field: 'column6',
        tooltipField: 'column6',
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: t('컬럼7코드', '이전계좌번호'),
        width: 138,
        field: 'column7',
        tooltipField: 'column7',
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: t('컬럼8코드', '계좌번호'),
        width: 138,
        field: 'column8',
        tooltipField: 'column8',
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: t('컬럼9코드', '이전예금주'),
        width: 100,
        field: 'column9',
        tooltipField: 'column9',
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: t('컬럼10코드', '예금주'),
        width: 100,
        field: 'column10',
        tooltipField: 'column10',
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: t('컬럼11코드', '이전통화'),
        width: 80,
        field: 'column11',
        tooltipField: 'column11',
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: t('컬럼12코드', '통화'),
        width: 80,
        field: 'column12',
        tooltipField: 'column12',
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: t('컬럼13코드', '계좌인증여부'),
        width: 100,
        field: 'column13',
        tooltipField: 'column13',
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: t('컬럼14코드', '진행상태'),
        width: 80,
        field: 'column14',
        tooltipField: 'column14',
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: t('컬럼15코드', '요청일시'),
        width: 80,
        field: 'column15',
        tooltipField: 'column15',
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: t('컬럼16코드', '수정일시'),
        width: 190,
        field: 'column16',
        tooltipField: 'column16',
        cellStyle: { textAlign: 'center' },
        flex: 1,
      },
      {
        headerName: t('컬럼17코드', '등록일시'),
        width: 190,
        field: 'column17',
        tooltipField: 'column17',
        cellStyle: { textAlign: 'center' },
        flex: 1,
      },
    ];
  }, []);

  const handleAprvDetailBtn = (data) => {
    console.log('화면 연결이 필요합니다.');
  };

  return { defaultColDef1, colDefs1 };
};
