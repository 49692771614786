/** @jsxImportSource @emotion/react */
import useSessionStore from 'stores/useSessionStore';
import React, { useState, useRef, useEffect, useMemo, useCallback, memo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { useCommonModal } from 'hooks/useCommonModal';
import { useTranslation } from 'react-i18next';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { getRoles, setRoles } from 'apis/admin/Role';
import {
  ColDef,
  ValueFormatterParams,
  ISelectCellEditorParams,
  IsRowSelectable,
  IRowNode,
  CellValueChangedEvent,
  EditableCallbackParams,
  GridReadyEvent,
  ICellRendererParams,
} from 'ag-grid-community';
import { Role } from 'models/admin/Role';
import { CommonYN } from 'models/common/Common';
import { CrudCode } from 'models/common/Edit';
import SelectCellRenderer from 'components/grids/SelectCellRenderer';
import {
  BlueButton,
  BlueLineButton,
  GreyButton,
  GreyLineButton,
} from 'components/buttons/CustomButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DoneIcon from '@mui/icons-material/Done';
import {
  GridButtons,
  GridInfoSection,
  GridInfo,
  ContentSection,
} from 'components/layouts/ContentSection';
import { ContentGrid } from 'components/layouts/ContentGrid';
import {
  DropdownCellRenderer,
  DropdownItem,
  IDropdownCellRendererParams,
} from 'components/grids/DropdownCellRenderer';
import { getCommonCodes } from 'apis/admin/CommonCode';
import { CommonCode } from 'models/admin/CommonCode';
import Save from 'components/asset/images/Confirm.svg';
import { GButton } from 'components/atom/button';

interface SearchParamData {
  sRoleNm: string;
  editable: boolean;
  callBackRoleCd?: any;
  width?: number;
  height?: number;
}

const RoleManagementGrid = (props: SearchParamData) => {
  const gridRef = useRef<AgGridReact<Role>>(null);
  const [rowData, setRowData] = useState<Role[]>([]);
  const { openCommonModal } = useCommonModal();
  const { t } = useTranslation();
  const { gridNoRowsTemplate } = useSessionStore();

  const [acesAuthLvList, setAcesAuthLvList] = useState<CommonCode[]>([
    { cmnGrCd: '', cmnCd: '', cmnCdNm: '==선택==' },
  ]);

  const defaultColDef: ColDef = useMemo(
    () => ({
      editable: props.editable,
      sortable: true,
      filter: false,
      lockPosition: true,
    }),
    [props]
  );

  const columnDefs: ColDef[] = [
    {
      headerName: 'No.',
      field: 'crudKey',
      width: 60,
      editable: false,
      filter: false,
      cellStyle: { textAlign: 'center' },
      valueFormatter: (params: ValueFormatterParams) => {
        return `${parseInt(params.node!.id!) + 1}`;
      },
    },
    {
      width: 50,
      editable: false,
      checkboxSelection: props.editable,
      headerCheckboxSelection: props.editable,
      hide: !props.editable,
      cellStyle: { textAlign: 'center' },
    },
    {
      headerName: String(t('com.label.roleCd', '__역할코드')),
      field: 'roleCd',
      editable: (params: EditableCallbackParams) => {
        return params.node!.data.crudKey === CrudCode.CREATE;
      },
      width: 200,
      cellStyle: { textAlign: 'center' },
    },
    {
      headerName: String(t('com.label.roleNm', '__역할명')),
      field: 'roleNm',
      flex: 1,
      minWidth: 130,
    },
    {
      headerName: String(t('com.label.roleDesc', '__역할설명')),
      field: 'roleDesc',
      flex: 1,
      minWidth: 130,
    },
    {
      headerName: String(t('com.label.mgt.1006', '__데이터접근범위')),
      field: 'acesAuthLvCd',
      flex: 1,
      minWidth: 100,
      maxWidth: 400,
      editable: false,
      cellRenderer: DropdownCellRenderer,
      cellRendererParams: (params: ICellRendererParams) => {
        return {
          ...params,
          dropdownItems: acesAuthLvList.map((acesAuthLv) => {
            return { label: acesAuthLv.cmnCdNm, value: acesAuthLv.cmnCd };
          }) as DropdownItem[],
          editable: props.editable,
        } as IDropdownCellRendererParams;
      },
      cellStyle: props.editable ? { textAlign: 'center' } : { textAlign: 'left' },
    },
    {
      headerName: String(t('com.label.useYn', '__사용여부')),
      field: 'useYn',
      width: 200,
      editable: false,
      cellRenderer: DropdownCellRenderer,
      cellRendererParams: (params: ICellRendererParams) => {
        const useYnCds = [CommonYN.Y, CommonYN.N];
        return {
          ...params,
          dropdownItems: useYnCds.map((useYnCd) => {
            return { label: useYnCd, value: useYnCd };
          }) as DropdownItem[],
          editable: props.editable,
        } as IDropdownCellRendererParams;
      },
      cellStyle: { textAlign: 'center' },
    },
  ];

  const fnSearchRole = useCallback((params: string) => {
    getRoles(params).then((result: Role[]) => {
      setRowData(result);
      getCommonCodes('ACES_AUTH_LV_CD').then((response) => {
        setAcesAuthLvList((prev) => [...prev, ...response]);
      });
    });
  }, []);

  const btnAddRow = useCallback(() => {
    const newRow = {
      crudKey: CrudCode.CREATE,
      useYn: CommonYN.Y,
    } as Role;
    setRowData([newRow, ...rowData]);
  }, [rowData]);

  const btnDelRow = useCallback(() => {
    const selectedRowNodes = gridRef.current!.api.getSelectedNodes();

    const selectedIds = selectedRowNodes
      .map((rowNode) => {
        return parseInt(rowNode.id!);
      })
      .reverse();

    selectedIds.forEach((item) => {
      delete rowData[item];
    });

    const filteredData = rowData.filter((element) => element !== undefined);
    setRowData(filteredData);
  }, [rowData, gridRef]);

  const btnSave = () => {
    const valid = rowData
      .map((rowNode, index) => {
        if (rowNode.roleCd == null || rowNode.roleCd === '')
          return `\nNo.${index + 1}:` + t('com.label.mgt.1038', '__역할코드를 입력해 주세요.');
        if (rowNode.roleNm == null || rowNode.roleNm === '')
          return `\nNo.${index + 1}:` + t('com.label.mgt.1039', '__역할명을 입력해 주세요.');
      })
      .filter((element) => element !== undefined);

    if (valid.length) {
      openCommonModal({ content: valid.toString() });
      return false;
    }
    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.00170', '__저장하시겠습니까?'),
      yesCallback: async () => {
        const saveRows = rowData
          .map((rowNode) => {
            // return rowNode.crudKey ? rowNode : null;

            return rowNode.crudKey && rowNode.roleCd && rowNode.roleNm ? rowNode : null;
          })
          .filter((element) => element !== null) as Role[];
        setRoles(saveRows).then((result) => {
          if (!result) {
            openCommonModal({
              content: t('com.label.mgt.1022', '__저장에 실패하였습니다.'),
            });
            return false;
          }

          if ((result.insertedRows ?? 0) + (result.updatedRows ?? 0) === 0) {
            return openCommonModal({
              content: t('com.label.mgt.1040', '__변경된 행이 없습니다.'),
            });
          }

          openCommonModal({
            content: t('com.msg.save', '__저장되었습니다.'),
          });
          fnSearchRole(props.sRoleNm);
        });
      },
      noCallback: () => {
        return false;
      },
    });
  };

  const onCellClicked = useCallback((event: any) => {
    props.callBackRoleCd && props.callBackRoleCd(event.data.roleCd);
  }, []);

  useEffect(() => {
    fnSearchRole(props.sRoleNm);
  }, [props]);

  const onModelUpdated = useCallback((e: GridReadyEvent) => {
    // e.api.sizeColumnsToFit();
  }, []);

  const isRowSelectable = useMemo<IsRowSelectable>(() => {
    return (node: IRowNode) => {
      return !!node.data && node.data.crudKey === CrudCode.CREATE;
    };
  }, []);

  const onCellValueChanged = useCallback((e: CellValueChangedEvent) => {
    !e.data.crudKey && e.node.setDataValue('crudKey', CrudCode.UPDATE);
  }, []);

  return (
    <ContentSection className={`section width${props.width ?? 50}p marginT0`}>
      <GridInfoSection className={`minHeight${props.height ?? 28}`}>
        <GridInfo>
          <span>{t('com.label.roleLst', '__역할목록')}</span>
          <span className="primary">
            {rowData.length}
            {t('com.label.case', '__건')}
          </span>
        </GridInfo>
        <GridButtons>
          {props.editable && (
            <GreyLineButton className="small" onClick={btnAddRow}>
              <AddIcon></AddIcon>
              {t('com.btn.addRow', '__행추가')}
            </GreyLineButton>
          )}
          {props.editable && (
            <GreyLineButton className="small" onClick={btnDelRow}>
              <RemoveIcon></RemoveIcon>
              {t('com.btn.delRow', '__행삭제')}
            </GreyLineButton>
          )}
        </GridButtons>
      </GridInfoSection>
      <ContentGrid className="ag-theme-alpine" style={{ height: `${props.height ?? 500}px` }}>
        <AgGridReact<Role>
          overlayNoRowsTemplate={gridNoRowsTemplate}
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={true}
          suppressRowClickSelection={true}
          isRowSelectable={isRowSelectable}
          stopEditingWhenCellsLoseFocus={true}
          singleClickEdit={true}
          rowSelection="multiple"
          onCellClicked={onCellClicked}
          onCellValueChanged={onCellValueChanged}
          onModelUpdated={onModelUpdated}
        />
      </ContentGrid>
      <GridInfoSection className="contentEnd">
        {props.editable && (
          <GridButtons>
            <GButton
              chkImg={Save}
              txt={t('com.label.00055', '__저장')}
              sizes="medium"
              btnstyled="contained"
              btnColor="primary"
              hasImg={true}
              hasTxt={true}
              onClick={btnSave}
            />
          </GridButtons>
        )}
      </GridInfoSection>
    </ContentSection>
  );
};

export default memo(RoleManagementGrid);
