import styled from '@emotion/styled';
import { Autocomplete, Checkbox, TextField } from '@mui/material';
import DarkSearch from 'components/asset/images/DarkSearch.svg';
import ErrorIcon from 'components/asset/images/error.svg';
import WarningIcon from 'components/asset/images/warning.svg';
import ConfirmedIcon from 'components/asset/images/confirmed.svg';
import avatarUser from 'components/asset/images/avatarUserChip.svg';
import { ReactComponent as UnChecked } from 'components/asset/UnChecked.svg';
import { ReactComponent as Checked } from 'components/asset/CheckedBox.svg';
import { GlobalTokenColor } from 'ui/theme/Color';

const dummyData = [
  'IP000',
  'IP111',
  'IP222',
  'IP333',
  'IP444',
  'IP555',
  'IP666',
  'IP777',
  'IP888',
  'IP999',
];

export interface SelectChipBoxProps {
  disabled?: boolean;
  readonly?: boolean;
  width?: string;
  height?: string;
  status?: 'default' | 'error' | 'warning' | 'confirm';
  type?: '1' | '2';
  chipType?: 'default' | 'user';
  chipImg?: string;
  format?: string;
  isCheck?: boolean;
  onClickFunction?: () => void;
  isRequired?: boolean;
  helperText?: string;
}

export default function ChipSelectBox({
  readonly = false,
  disabled = false,
  width = '100%',
  height,
  status = 'default',
  type = '1',
  isCheck = true,
  chipType = 'default',
  chipImg = `${avatarUser}`,
  format = '',
  onClickFunction,
  isRequired,
  helperText,
}: SelectChipBoxProps) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: `${width}`,
        height: `${height}`,
      }}
    >
      <Container
        width={width}
        height={height}
        status={status}
        type={type}
        isCheck={isCheck}
        readonly={readonly}
        disabled={disabled}
        chipType={chipType}
        chipImg={chipImg}
        isRequired={isRequired}
      >
        {isCheck ? (
          <Autocomplete
            multiple
            limitTags={1}
            options={dummyData}
            getOptionLabel={(option) => option}
            renderOption={(props, option, { selected }) => (
              <CheckboxList {...props}>
                <Checkbox icon={<UnChecked />} checkedIcon={<Checked />} checked={selected} />
                <span>{option}</span>
              </CheckboxList>
            )}
            renderInput={(params) => <TextField {...params} placeholder={format} />}
          />
        ) : (
          <Autocomplete
            multiple
            limitTags={1}
            options={dummyData}
            getOptionLabel={(option) => option}
            renderInput={(params) => <TextField {...params} placeholder={format} />}
          />
        )}

        {type === '1' ? <SearchButton onClick={onClickFunction}></SearchButton> : <></>}
      </Container>
      {helperText && <HelperText status={status}>{helperText}</HelperText>}
    </div>
  );
}

const Container = styled.div<SelectChipBoxProps>`
  z-index: 10;
  position: relative;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  ${(props) => (props.disabled ? `opacity: 0.6; pointer-events: none;` : `opacity: 1;`)};
  ${(props) => (props.readonly ? `pointer-events: none;` : ``)};

  &:before {
    ${(props) => (props.isRequired ? `content : ""` : ``)};
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: ${GlobalTokenColor.StatusError500};
    position: absolute;
    left: 2px;
    top: 2px;
    z-index: 20;
  }

  > div:first-child:not(:has(.Mui-focused)) {
    &::after {
      ${(props) => props.status === 'error' && `content: url('${ErrorIcon}');`}
      ${(props) => props.status === 'warning' && `content: url('${WarningIcon}');`}
      ${(props) => props.status === 'confirm' && `content: url('${ConfirmedIcon}');`}
      position: absolute;
      top: 50%;
      right: 8px;
      transform: translate(0, -50%);
      width: 16px;
      height: 16px;
      background-color: #fff;
      cursor: pointer;
    }
  }

  ${(props) =>
    props.type === '1' &&
    `
    .MuiAutocomplete-clearIndicator {
      right: 0 !important;
    }
  `}

  ${(props) =>
    props.type === '2' &&
    `
    // focus 되었을 때
    > div:first-child:not(:has(.Mui-focused)) {
      &::after {
        ${(props) => props.status === 'error' && `content: url('${ErrorIcon}');`}
        ${(props) => props.status === 'warning' && `content: url('${WarningIcon}');`}
        ${(props) => props.status === 'confirm' && `content: url('${ConfirmedIcon}');`}
        position: absolute;
        top: 50%;
        right: 28px;
        transform: translate(0, -50%);
        width: 16px;
        height: 16px;
        background-color: #fff;
        cursor: pointer;
      }
    }
    // focus 안되어 있을 때
    &::after {
      display: none;
    }
  `}

  /* default padding-right 제거*/
  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.MuiAutocomplete-root
    .MuiOutlinedInput-root {
    padding-right: 50px;

    &:has(.MuiAutocomplete-tag) {
      input {
        &::placeholder {
          color: #fff;
        }
      }
    }
  }

  .MuiAutocomplete-root {
    width: 100%;
    height: 100%;

    /* field */
    .MuiOutlinedInput-root {
      &:has(.MuiAutocomplete-tag) {
        ${(props) =>
          props.type === '1' ? 'padding: 4px 50px 4px 30px;' : 'padding: 4px 50px 4px 8px;'}
      }
      ${(props) =>
        props.type === '1' ? 'padding: 6px 50px 6px 30px;' : 'padding: 6px 50px 6px 8px;'}
      display: flex;
      gap: 4px 2px;
      background-color: #fff;
      ${(props) => props.disabled && `background-color: #EBEEED;`};
      ${(props) => props.readonly && `background-color: #f1f4f3;`};

      /* input */
      .MuiAutocomplete-input {
        width: 0;
        padding: 0;
        min-width: 0;
        height: 20px;

        &::placeholder {
          color: #979998;
          opacity: 1;
        }
      }
    }
  }

  .MuiTextField-root {
    width: 100%;
    height: 100%;
  }

  /* fieldset */
  .MuiOutlinedInput-notchedOutline {
    top: 0;
    width: 100%;
    padding: 0;
    border-radius: 2px;
    ${(props) => props.status === 'default' && `border: 1px solid #b9bcbb !important;`}
    ${(props) =>
      props.status === 'error' &&
      `border: 1px solid ${GlobalTokenColor.StatusError300} !important;`}
    ${(props) =>
      props.status === 'warning' &&
      `border: 1px solid ${GlobalTokenColor.StatusWarningMajor300} !important;`}
    ${(props) =>
      props.status === 'confirm' &&
      `border: 1px solid ${GlobalTokenColor.StatusConfirmed300} !important;`}
    ${(props) => props.disabled && `border: 1px solid #b9bcbb !important;`};
  }

  .Mui-focused .MuiOutlinedInput,
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid #5b5c5d !important;
  }

  /* close button + popup button groups */
  .MuiAutocomplete-endAdornment {
    top: 50%;
    transform: translate(0, -50%);
    ${(props) => (props.type === '1' ? `display:flex; flex-direction: row-reverse;` : ``)}
  }

  /* close button */
  .MuiAutocomplete-clearIndicator {
    right: 20px;
    ${(props) => (props.type === '2' ? `right: 20px;` : ``)}
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 14px;
    height: 14px;
    margin-right: 0;
    background-color: #5b5c5b;

    & > .MuiSvgIcon-root {
      padding: 2px;
      color: #fff;
    }
  }

  /* popup button */
  .MuiAutocomplete-popupIndicator {
    transform: none !important;
    button {
      width: 0;
      height: 0;
      padding: 0;
    }
    & > .MuiSvgIcon-root {
      ${(props) => (props.type === '1' ? `width: 0;` : ``)}
    }
  }

  /* chip tag */
  .MuiAutocomplete-root {
    .MuiAutocomplete-tag {
      height: 24px;
      margin: 0;
      max-width: none;
      color: #1f1f1f;
      display: flex;
      align-items: center;

      & > span {
        height: 20px;
        color: #5b5c5d;
        font-size: 13px;
        font-weight: 400;
        line-height: 150%;
        ${(props) =>
          props.chipType === 'default' ? `padding: 0 16px 0 8px;` : `padding: 1px 16px 0 28px;`}
        position: relative;
        ${(props) =>
          props.chipType === 'user' &&
          `
        &::after {
          content: url('${avatarUser}');
          position: absolute;
          top: 50%;
          left: 4px;
          transform: translate(0, -50%);
          background-color: #554596;
          border: 1px solid #554596;
          border-radius: 50%;
          width: 20px;
          height: 20px;
        }
        `}
      }
      & > svg {
        width: 16px;
        margin: 0 5px 0 -12px !important;
        color: #979998;
      }
    }
    .MuiButtonBase-root-MuiChip-root {
      height: 20px;
    }
  }
`;

const SearchButton = styled.div`
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translate(0, -50%);
  &::after {
    content: url('${DarkSearch}');
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    width: 16px;
    height: 16px;
  }
  cursor: pointer;
`;

const CheckboxList = styled.li`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 32px;
  margin-left: -16px;
  font-size: 13px;

  > span {
    line-height: 20px;
  }
`;

const HelperText = styled.span<SelectChipBoxProps>`
  font-family: Spoqa Han Sans Neo;
  font-size: 12px;
  line-height: 150%;
  color: #979998;

  margin-top: 4px;
  width: auto;
  height: auto;

  ${(props) =>
    props.status === 'error' &&
    `
    color: #f94b50;
    `}

  ${(props) =>
    props.status === 'warning' &&
    `
    color: #ff9322;
    `}
    
    ${(props) =>
    props.status === 'confirm' &&
    `
    color: #00806a;
  `}
`;
