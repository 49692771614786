import { ColDef } from 'ag-grid-community';
import { DefaultCellTooltip } from 'components/grids/tooltip/DefaultCellTooltip';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import radioIcon from 'components/asset/RadioColumn.svg';
import { TagCellRenderer, ITagCellRendererParams } from 'components/grids/TagCellRenderer';

export const useColumnDefsForApprovalList = () => {
  const { t } = useTranslation();

  const defaultColDef = useMemo((): ColDef => {
    return {
      sortable: false,
      resizable: true,
      cellStyle: { textAlign: 'center' },
      tooltipComponent: DefaultCellTooltip,
    };
  }, []);

  const colDefs = useMemo((): any[] => {
    return [
      {
        headerName: 'check',
        headerComponentParams: {
          template: `<img src=${radioIcon} alt=''/>`,
        },
        width: '28px !important',
        height: '24px !important',
        padding: '2px 6px',
        resizable: false,
        headerCheckboxSelection: true,
        showDisabledCheckboxes: true,
        checkboxSelection: true,
        flex: 0,
        cellStyle: {
          display: 'flex',
          justifyContent: 'center',
          width: '28px !important',
          height: '24px',
        },
      },
      {
        headerName: t('컬럼1코드', 'No.'),
        width: 50,
        field: 'column1',
        tooltipField: 'column1',
      },
      {
        headerName: t('컬럼2코드', 'Name of Bank'),
        width: 100,
        cellStyle: { textAlign: 'start' },
        field: 'column2',
        tooltipField: 'column2',
      },
      {
        headerName: t('컬럼3코드', 'SWIFT/BIC'),
        width: 100,
        field: 'column3',
        tooltipField: 'column3',
      },
      {
        headerName: t('컬럼4코드', 'IBAN'),
        minWidth: 180,
        flex: 1,
        field: 'column4',
        tooltipField: 'column4',
      },
      {
        headerName: t('컬럼5코드', 'CNAP'),
        width: 80,
        field: 'column5',
        tooltipField: 'column5',
      },
      {
        headerName: t('컬럼6코드', 'Name of Account'),
        width: 120,
        field: 'column6',
        tooltipField: 'column6',
      },
      {
        headerName: t('컬럼7코드', 'Account Number'),
        width: 160,
        cellStyle: { textAlign: 'start' },
        field: 'column7',
        tooltipField: 'column7',
      },
      {
        headerName: t('컬럼8코드', 'Account Verification Status'),
        minWidth: 180,
        flex: 1,
        field: 'column8',
        tooltipField: 'column8',
      },
      {
        headerName: t('컬럼9코드', 'Purpose of Use'),
        width: 130,
        cellStyle: { textAlign: 'start' },
        field: 'column9',
        tooltipField: 'column9',
      },
      {
        headerName: t('컬럼10코드', 'Currency'),
        width: 100,
        field: 'column10',
        tooltipField: 'column10',
      },
      {
        headerName: t('컬럼11코드', 'Progress Status'),
        width: 150,
        field: 'column11',
        tooltipField: 'column11',
        cellStyle: { textAlign: 'center', display: 'flex', justifyContent: 'center' },
        cellRenderer: TagCellRenderer,
        cellRendererParams: (params: ITagCellRendererParams) => {
          return {
            label: 'Register Completed',
            status: 'completion',
            ...params,
          } as ITagCellRendererParams;
        },
      },
      {
        headerName: t('컬럼12코드', 'Request Date'),
        width: 100,
        field: 'column12',
        tooltipField: 'column12',
      },
      {
        headerName: t('컬럼13코드', 'Attached File'),
        width: 100,
        field: 'column13',
        tooltipField: 'column13',
      },
    ];
  }, []);

  const handleAprvDetailBtn = (data) => {
    console.log('화면 연결이 필요합니다.');
  };

  return { defaultColDef, colDefs };
};
