import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { GlobalTokenColor } from 'ui/theme/Color';
import txtIcon from 'components/asset/images/etcFile.svg';
import pdfIcon from 'components/asset/images/pdfFile.svg';
import jpgIcon from 'components/asset/images/imgFile.svg';
import excelIcon from 'components/asset/images/excelFile.svg';
import etcIcon from 'components/asset/images/etcFile.svg';
import imgIcon from 'components/asset/images/imgFile.svg';
import markdownIcon from 'components/asset/images/markdownFile.svg';
import pptIcon from 'components/asset/images/pptFile.svg';
import wordIcon from 'components/asset/images/wordFile.svg';
import zipIcon from 'components/asset/images/zipFile.svg';
import { findFiles } from 'apis/admin/FileUpload';
import { FileInfo } from 'models/admin/FileInfo';
import { useTranslation } from 'react-i18next';
import useSessionStore from 'stores/useSessionStore';
import axios from 'axios';

// 파일 목록
interface File {
  name: string;
  size: string;
}

interface FileListProps {
  files: FileInfo[];
  atchFileGrId: string;
}

export const FileList: React.FC<FileListProps> = ({ files, atchFileGrId }) => {
  const handleDownloadFile = async (file: FileInfo) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/v1/file/download?atchFileGrId=${file.atchFileGrId}&atchFileId=${file.atchFileId}`,
        {
          responseType: 'blob',
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file.atchFileNm);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error);
    }
  };

  const getFileSize = (allFileSize: number) => {
    return allFileSize >= 1000
      ? `${Math.ceil((allFileSize / 1000) * 10) / 10}KB`
      : `${allFileSize}bytes`;
  };

  return (
    <div>
      {files.map((file, index) => (
        <StyledFile key={index}>
          {getFileIcon(file.atchFileNm)} {/* 파일 아이콘 */}
          <FileName
            onClick={async (e) => {
              const fileInfo = {
                atchFileGrId: file.atchFileGrId,
                atchFileId: file.atchFileId,
                atchFileNm: file.atchFileNm,
              } as FileInfo;
              handleDownloadFile(fileInfo);
              e.preventDefault();
            }}
          >
            {file.atchFileNm}
          </FileName>{' '}
          {/* 파일 링크 */}
          <FileSize>{`${getFileSize(file.atchFileSize)}`}</FileSize> {/* 파일 크기 */}
        </StyledFile>
      ))}
    </div>
  );
};

// 확장자에 따라 다른 아이콘
const getFileIcon = (fileName: string): JSX.Element => {
  const extension = fileName.split('.').pop()?.toLowerCase();
  switch (extension) {
    case 'txt':
      return <img src={txtIcon} alt="txt icon" />;
    case 'jpg':
      return <img src={jpgIcon} alt="jpg icon" />;
    case 'jpeg':
      return <img src={jpgIcon} alt="jpg icon" />;
    case 'pdf':
      return <img src={pdfIcon} alt="pdf icon" />;
    case 'xls':
      return <img src={excelIcon} alt="excel icon" />;
    case 'xlsx':
      return <img src={excelIcon} alt="excel icon" />;
    case 'img':
      return <img src={imgIcon} alt="img icon" />;
    case 'png':
      return <img src={imgIcon} alt="img icon" />;
    case 'markdown':
      return <img src={markdownIcon} alt="markdown icon" />;
    case 'ppt':
      return <img src={pptIcon} alt="ppt icon" />;
    case 'pptx':
      return <img src={pptIcon} alt="ppt icon" />;
    case 'word':
      return <img src={wordIcon} alt="pdf icon" />;
    case 'zip':
      return <img src={zipIcon} alt="zip icon" />;
    default:
      return <img src={etcIcon} alt="etc icon" />;
  }
};

// 사용 예제
const FileDownload = ({ atchFileGrId = '' }: { atchFileGrId?: string }) => {
  const { t } = useTranslation();
  const { gridNoRowsTemplate } = useSessionStore();

  const [files, setFiles] = useState<FileInfo[]>([]);

  useEffect(() => {
    if (atchFileGrId) {
      findFiles(atchFileGrId).then((result: FileInfo[]) => {
        setFiles(result);
      });
    } else {
      setFiles([]);
    }
  }, [atchFileGrId]);

  const files1: File[] = [
    { name: 'document.txt', size: '10KB' },
    { name: 'image.jpg', size: '500KB' },
    { name: 'presentation.pdf', size: '2MB' },
  ];

  return <FileList files={files} atchFileGrId={atchFileGrId} />;
};

const StyledFile = styled.div`
  display: flex;
  padding: 6px 8px 6px 0px;
  align-items: center;
  gap: 4px;
  align-self: stretch;

  width: 100%;
`;

const FileName = styled.a`
  width: auto;
  height: auto;

  color: ${GlobalTokenColor.Secondary500};
  font-size: 13px;
  font-weight: 400;
  cursor: pointer;

  &:hover {
    color: ${GlobalTokenColor.Secondary500};
    font-size: 13px;
    font-weight: 400;
    text-decoration: underline;
  }
`;

const FileSize = styled.span`
  width: auto;
  height: auto;

  color: ${GlobalTokenColor.ESGrey500};
  font-size: 12px;
  font-weight: 400;
`;

export default FileDownload;
