/* eslint-disable */
import { ICellRendererParams } from 'ag-grid-community';
import { IRowNode } from 'ag-grid-community/dist/lib/interfaces/iRowNode';
import { GButton } from 'components/atom/button';
import DmsDtlKrwAcntModal from 'pages/vars/accountregist/modal/DmsDtlKrwAcntModal';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DmsChgKrwAcntModal from 'pages/vars/accountregist/modal/DmsChgKrwAcntModal';
import { DomesitcAccountVO } from 'models/vars/account/DomesitcAccountVO';
import useAuthStore from 'stores/useAuthStore';
import { ICellRendererParamsVars } from 'pages/vars/accountregist/hooks/DomesticColumnDefs';

// export interface AccnDtlRenderModalParams extends ICellRendererParams {
//   node: IRowNode;
// }

const useDtlModalState = () => {
  const [dtlModalOpen, setDtlModalOpen] = useState<boolean>(false);
  const handleClick = () => {
    setDtlModalOpen(() => true);
  };

  return [dtlModalOpen, setDtlModalOpen, handleClick];
};

const useChgModalState = () => {
  const [chgModalOpen, setChgModalOpen] = useState<boolean>(false);
  const handleClickChgModalOpen = () => {
    setChgModalOpen(() => true);
  };

  return [chgModalOpen, setChgModalOpen, handleClickChgModalOpen];
};

const renderButtonCell = (handleClick, node) => {
  const { t } = useTranslation();

  return (
    <GButton
      txt={t('com.label.veiwDtls', '__상세보기')}
      onClick={() => {
        handleClick();
      }}
      btnstyled="outline"
      btnColor="normal"
      sizes="xs"
      hasTxt={true}
    ></GButton>
  );
};

const renderDtlModal = ({ node, dtlModalOpen, setDtlModalOpen, setChgModalOpen, callbackFn }) => {
  return (
    <DmsDtlKrwAcntModal
      open={dtlModalOpen}
      close={() => setDtlModalOpen(() => false)}
      save={(result) => {
        if (callbackFn && result) {
          callbackFn();
          setChgModalOpen(false);
        }
      }}
      modify={() => {
        // handleClickChgModalOpen();
        setDtlModalOpen(() => false);
        setChgModalOpen((prev) => !prev);
      }}
      domAccount={node.data}
      node={node}
    />
  );
};

const renderChgModal = ({
  node,
  chgModalOpen,
  setChgModalOpen,
  domAccounts,
  stdAccnCds,
  setStdAccnCds,
  callbackFn,
  vendorInfo,
}) => {
  return (
    <DmsChgKrwAcntModal
      open={chgModalOpen}
      close={(result) => {
        setChgModalOpen(false);
      }}
      save={(result) => {
        if (callbackFn && result) {
          callbackFn();
          setChgModalOpen(false);
        }
      }}
      domAccount={node.data}
      vendorInfo={vendorInfo}
      node={node}
    />
  );
};

export const AccnDtlPopupCellRenderer = ({ node, ...params }: ICellRendererParamsVars) => {
  const [dtlModalOpen, setDtlModalOpen, handleClick] = useDtlModalState();
  const [chgModalOpen, setChgModalOpen] = useChgModalState();

  // const { chgModal, setChgModal } = useAuthStore();
  const callbackFn = params.callback;
  const vendorInfo = params.vendorInfo;

  const [domAccounts, setDomAccounts] = useState<DomesitcAccountVO[]>([]);
  const [stdAccnCds, setStdAccnCds] = useState<any>({
    stdAccnTpCd: false,
    stdAccnRegDivsCd: '1',
    stdRegDivCd: 'FG', //등록시 구분자 복수계좌만 : MM, 어음,복수 : GM, 현금,복수: FF, 전부 : FG
  }); // true 이면 현금만

  // useEffect(() => {
  //   const model = params.api.getModel();
  //   const cnt: number = model.getRowCount();
  //   if (cnt - 1 == node.rowIndex) {
  //     const tmpAccns: any = [];
  //     for (let index = 0; index < cnt; index++) {
  //       tmpAccns.push(model.getRow(index)?.data);
  //     }
  //     console.log('>>>>>>>>>>>>>', tmpAccns);
  //     setDomAccounts(tmpAccns);
  //   }
  // }, []);

  return (
    <>
      {renderButtonCell(handleClick, node)}
      {dtlModalOpen &&
        renderDtlModal({ node, dtlModalOpen, setDtlModalOpen, setChgModalOpen, callbackFn })}
      {chgModalOpen &&
        !dtlModalOpen &&
        renderChgModal({
          node,
          chgModalOpen,
          setChgModalOpen,
          domAccounts,
          stdAccnCds,
          setStdAccnCds,
          callbackFn,
          vendorInfo,
        })}
    </>
  );
};
