import { useState } from 'react';
import SearchMolecule, {
  GButtonBoxCum,
  HiddenSearchWrap,
  SearchHr,
  SearchItemWrap,
  SearchWrap,
  UnHiddenSearchWrap,
} from 'components/molecule/SearchMolecule';
import { GButton } from 'components/atom/button';
import hiddenImage from '../asset/images/hiddenImage.svg';
import beforeHiddenImage from '../asset/images/beforeHiddenImage.svg';
import searchImage from '../asset/images/Search.svg';
import RecycleB from 'components/asset/images/RecycleB.svg';
import GButtonLayout from 'components/molecule/GbuttonLayout';

const SearchBox = () => {
  const [hidden, setHidden] = useState(false);

  const isHidden = () => {
    setHidden(!hidden);
  };

  const buttonImg = () => {
    if (hidden) {
      return beforeHiddenImage;
    } else {
      return hiddenImage;
    }
  };

  return (
    <SearchWrap>
      {/*SearchWrap의 marginBotton 값이 12px와 다른 경우 <SearchWrap marginBottom="">  marginBottom 속성을 통해 주기*/}
      <UnHiddenSearchWrap>
        {/*<SearchItemWrap nonButton={true}> 만약 버튼이 없는 경우에는 nonButton 속성을 줄것*/}
        <SearchItemWrap>
          <SearchMolecule type="input" labelTitle="input" isRequired={true} />
          <SearchMolecule type="input" labelTitle="input" />
          <SearchMolecule type="input" labelTitle="input" searchWidth="34%" />
          <SearchMolecule
            type="input"
            searchWidth="66%"
            labelTitle="input"
            placeholder="placeholder"
          />
          <SearchMolecule type="input" labelTitle="input" isRequired={true} searchWidth="34%" />
          <SearchMolecule
            type="input"
            searchWidth="100%"
            labelTitle="input"
            placeholder="placeholder"
          />
        </SearchItemWrap>
        {/*만약 search가 한줄이라면 GButtonLayout의 paddingTop과 marginBotton을 0으로 줄것 
        <GButtonLayout marginBottom='0px' paddingTop='0px'>*/}
        <GButtonLayout marginBottom="0">
          <GButtonBoxCum>
            <GButton
              chkImg={RecycleB}
              sizes="medium"
              btnstyled="outline"
              btnColor="normal"
              hasImg={true}
              hasTxt={false}
            ></GButton>
            <GButton
              chkImg={searchImage}
              txt="조회"
              sizes="medium"
              btnstyled="contained"
              btnColor="normal"
              hasImg={true}
              hasTxt={true}
            ></GButton>
            <GButton
              chkImg={buttonImg()}
              sizes="medium"
              btnstyled="contained"
              btnColor="normal"
              hasImg={true}
              hasTxt={false}
              onClick={isHidden}
            ></GButton>
          </GButtonBoxCum>
        </GButtonLayout>
      </UnHiddenSearchWrap>
      {hidden ? (
        <>
          <SearchHr />
          <HiddenSearchWrap>
            <SearchMolecule
              type="select"
              labelTitle="select"
              placeholder="placeholder"
              selectOption={['옵션1', '옵션2']}
              isRequired={true}
            />
            <SearchMolecule
              type="select"
              labelTitle="select"
              placeholder="placeholder"
              selectOption={['옵션1', '옵션2']}
              selectReadOnly={true}
            />
            <SearchMolecule
              type="select"
              labelTitle="select"
              placeholder="placeholder"
              selectOption={['옵션1', '옵션2']}
              isRequired={true}
            />
          </HiddenSearchWrap>
        </>
      ) : (
        <></>
      )}
    </SearchWrap>
  );
};

export default SearchBox;
