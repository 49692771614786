import React, { useState } from 'react';
import styled from '@emotion/styled';
import ThumbsDownOn from 'components/asset/images/ThumbsDownOn.svg';
import ThumbsDownOff from 'components/asset/images/ThumbsDownOff.svg';
import { TextLineHeight, TextSize, TextWeight } from 'ui/theme/CustomTypography';
import { GlobalTokenColor } from 'ui/theme/Color';

const ButtonContainer = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  width: 20px; /* 이미지의 크기 조절 */
  height: 20px;
`;

const TDownCountBox = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
`;

const Count = styled.span`
  width: auto;
  color: ${GlobalTokenColor.ESGrey900};
  font-family: Spoqa Han Sans Neo;
  font-size: ${TextSize.Basic};
  font-weight: ${TextWeight.Regualr};
  line-height: ${TextLineHeight.Basic};
`;

const addCommas = (num: number): string => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

interface TDownCountProps {
  count: number;
}

const ThumbsDown = (props: TDownCountProps) => {
  const thumbsDownOff = `${ThumbsDownOff}`;
  const thumbsDownOn = `${ThumbsDownOn}`;

  const [currentImage, setCurrentImage] = useState(thumbsDownOff);

  const formattedCount = addCommas(props.count);

  const handleClick = () => {
    // 이미지가 클릭될 때마다 변경
    setCurrentImage((prevImage) => (prevImage === thumbsDownOff ? thumbsDownOn : thumbsDownOff));
  };

  return (
    <TDownCountBox>
      <ButtonContainer onClick={handleClick}>
        <Image src={currentImage} alt="Button Image" />
      </ButtonContainer>
      <Count>{formattedCount}</Count>
    </TDownCountBox>
  );
};

export default ThumbsDown;
