/** @jsxImportSource @emotion/react */
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { getCommonCodeNames, getCommonCodes } from 'apis/admin/CommonCode';
import { findMessages, findMessagesMsgCtn, saveMessages } from 'apis/admin/Message';
import { useMessageModalStore } from 'stores/useMessageModalStore';
import { Message, MessageInfo } from 'models/admin/Message';
import { CommonYN } from 'models/common/Common';
import { Code, commonYNList } from 'models/common/CommonCode';
import { useCommonModal } from 'hooks/useCommonModal';
import { CrudCode } from 'models/common/Edit';
import { di } from 'components/layouts/Dialog';
import { ViewTable } from 'components/tables/ViewTable';
import { CustomInputText, CustomTextarea } from 'components/inputs/CustomInput';
import { CustomSelect } from 'components/selects/CustomSelect';
import { BlueButton, GreyLineButton, IconButton } from 'components/buttons/CustomButton';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { useMenuAtchFileModalStore } from 'stores/useMenuAtchFileModalStore';
import { GSelectMUI } from 'components/inputs/GInput';
import GFileUploadZone from 'components/dropzone/GFileUploadZone';
import { GSelect } from 'components/inputs/GInput';
import { FileInfo, FileSaveResponse, FileSaveResult } from 'models/admin/FileInfo';
import { MenuAtchFileDesc } from 'models/admin/MenuAtchFile';
import useMenuManagementStore from 'stores/useMenuManagementStore';
import {
  createMenuAtchFile,
  getMenuAtchFiles,
  modifyMenuAtchFile,
} from 'apis/admin/MenuAtchFileApi';
import { GButton } from 'components/buttons/GButton copy';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import { GLabel } from 'components/layouts/GLayoutBox';

const getEmptyMessageInfo = () => {
  return {
    msgCtn: '',
    msgTxtCtn1: '',
    msgTxtCtn2: '',
    msgTxtCtn3: '',
    msgTxtCtn4: '',
    msgTxtCtn5: '',
    rmk: '',
    useYn: CommonYN.Y,
  } as MessageInfo;
};

export const MenuAtchFileModal = () => {
  const { t } = useTranslation();
  const { openCommonModal } = useCommonModal();
  const {
    isOpen,
    msgCtn,
    animation,
    koMessage,
    yesCallback,
    noCallback,
    setMenuAtchFileModalStateWhenModalClose,
  } = useMenuAtchFileModalStore();
  const { menuId } = useMenuManagementStore((state) => state);

  const [languageCodes, setLanguageCodes] = useState<Code[]>([]);
  const [messages, setMessages] = useState<Message[]>([]);
  const [msgInfo, setMsgInfo] = useState<MessageInfo>(getEmptyMessageInfo());

  const [fileCategoryItems, setFileCategoryItems] = useState<Code[]>([]);
  const [selectedFileCategory, setSelectedFileCategory] = useState('');
  const [atchFileUseYn, setAtchFileUseYn] = useState('Y');
  const [menuAtchFileList, setMenuAtchFileList] = useState<MenuAtchFileDesc[]>([]);

  const [allowFileTypes, setAllowFileTypes] = useState<string[]>([]);

  const initialDataFetch = async (fileKdnCd: string) => {
    let atchFiles: any[] = [];

    if (fileKdnCd !== '') {
      atchFiles = [...((await getMenuAtchFiles(menuId, fileKdnCd)) ?? [])];
    } else {
      atchFiles = [];
    }

    const atchFileList: MenuAtchFileDesc[] = [];

    if (atchFiles.length != 0) {
      languageCodes.map((language) => {
        if (atchFiles.some((item) => item.langCd === language.cmnCd)) {
          atchFileList.push(atchFiles.filter((item) => item.langCd === language.cmnCd)[0]);
        } else {
          atchFileList.push({
            mnuId: menuId,
            langCd: language.cmnCd ? language.cmnCd : '',
            atchFileKdnCd: fileKdnCd,
            atchFileGrId: '',
            useYn: atchFiles[0].useYn,
          });
        }
      });
      setAtchFileUseYn(atchFiles[0].useYn);
      // setSelectedFileCategory(atchFiles[0].atchFileKdnCd);
      setMenuAtchFileList(atchFileList);
    } else {
      languageCodes.map((item) => {
        const atchFile = {
          mnuId: menuId,
          langCd: item.cmnCd ? item.cmnCd : '',
          atchFileKdnCd: fileKdnCd,
          atchFileGrId: '',
          useYn: 'Y',
        };
        atchFileList.push(atchFile);
      });

      setMenuAtchFileList(atchFileList);
    }
  };

  const fileUploadRef = Array(5)
    .fill(null)
    .map(() => useRef<any>());

  useEffect(() => {
    if (selectedFileCategory == 'T') {
      initialDataFetch(selectedFileCategory);

      //initialDataFetch('');
      setAllowFileTypes(['xlsx']);
    } else if (selectedFileCategory == 'M') {
      initialDataFetch(selectedFileCategory);

      setAllowFileTypes(['png', 'jpg', 'jpeg', 'bmp', 'gif']);
    } else {
      initialDataFetch('');

      setAllowFileTypes([]);
    }
  }, [selectedFileCategory]);

  useEffect(() => {
    if (isOpen) {
      if (_.isUndefined(msgCtn)) {
        openCommonModal({
          content: t('com.label.00700', '__다시 시도해주세요.'),
        });
        setMenuAtchFileModalStateWhenModalClose();
        return;
      }

      (async () => {
        const languageCodesApiResult = [...((await getCommonCodeNames('LANG_CD')) ?? [])];
        const messagesApiResult = [...((await findMessagesMsgCtn({ msgCtn: msgCtn })) ?? [])];
        const fileCategoryItemsApiResult = [...((await getCommonCodes('ATCH_FILE_KDN_CD')) ?? [])];

        setFileCategoryItems([...fileCategoryItemsApiResult]);
        setLanguageCodes([...languageCodesApiResult]);
        setMessages([...messagesApiResult]);

        setSelectedFileCategory('');

        setMsgInfo(() => {
          const msgInfoResult = {
            msgCtn: msgCtn ?? '',
            useYn: messagesApiResult[0]?.useYn ?? '',
            rmk: messagesApiResult[0]?.rmk ?? '',
          } as MessageInfo;

          for (let i = 0; i < languageCodesApiResult.length; i++) {
            const languageCode = languageCodesApiResult[i].cmnCd ?? '';
            const findMessages = messagesApiResult.filter(
              (message) => message.langCd === languageCode
            );

            if (findMessages.length !== 0) {
              msgInfoResult[`msgTxtCtn${i + 1}`] = findMessages[0].msgTxtCtn;
            } else {
              msgInfoResult[`msgTxtCtn${i + 1}`] = '';
            }
          }
          if (koMessage && koMessage !== '') {
            msgInfoResult['msgTxtCtn1'] = koMessage;
          }
          return msgInfoResult;
        });
      })();

      document.body.style.top = `-${window.pageYOffset}px`;
      document.body.style.position = 'fixed';
      document.body.style.overflow = 'hidden';
      document.body.style.width = '100%';
    } else {
      setMsgInfo(getEmptyMessageInfo());
    }

    return () => {
      const heightScroll = parseInt(document.body.style.top || '0', 10) * -1;
      document.body.style.removeProperty('position');
      document.body.style.removeProperty('overflow');
      document.body.style.removeProperty('width');
      document.body.style.removeProperty('top');
      window.scrollTo(0, heightScroll);
    };
  }, [isOpen]);

  const handleNoButtonClick = useCallback(async () => {
    setMenuAtchFileModalStateWhenModalClose();
    if (noCallback) {
      noCallback();
    }
  }, [noCallback, setMenuAtchFileModalStateWhenModalClose]);

  const handleYesButtonClick = () => {
    if (selectedFileCategory == '') {
      openCommonModal({
        content: t('com.label.00158', '__첨부파일 유형을 선택하세요'),
      });
      return;
    }

    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.00170', '__저장하시겠습니까?'),
      yesCallback: async () => {
        menuAtchFileList.map(async (menuAtchFile, index) => {
          const fileSaveResponse: FileSaveResponse = await fileUploadRef[index].current.saveFiles();
          if (fileSaveResponse.fileSaveResult == FileSaveResult.FAIL) return;

          const fileId = fileSaveResponse.atchFileGrId;

          if (menuAtchFile.atchFileGrId == '') {
            menuAtchFile.atchFileGrId = fileId;
            createMenuAtchFile(menuAtchFile).then((response) => {
              if (response.insertedRows === 1) {
                setMenuAtchFileModalStateWhenModalClose();
              }
            });
          } else {
            menuAtchFile.atchFileGrId = fileId;
            modifyMenuAtchFile(menuAtchFile).then((response) => {
              if (response.updatedRows === 1) {
                setMenuAtchFileModalStateWhenModalClose();
              }
            });
          }
        });
      },
    });
  };

  const handleFileCategoryChange = (e) => {
    setSelectedFileCategory(e.target.value);
  };

  const handleAtchFileUseYnChange = (e) => {
    setAtchFileUseYn(e.target.value);
    setMenuAtchFileList((prev) => {
      return prev.map((item) => {
        return { ...item, useYn: e.target.value, crudKey: CrudCode.UPDATE };
      });
    });
  };

  const handleFileChange = (menuAtchFile, files, languageCode) => {
    // 기존의 첨부파일을 수정할 경우
    if (menuAtchFile.atchFileGrId !== '') {
      setMenuAtchFileList((prev) => {
        return prev.map((item) => {
          if (item.langCd === languageCode.cmnCd) {
            return { ...item, atchFileGrId: files[0]?.atchFileGrId, crudKey: CrudCode.UPDATE };
          }
          return item;
        });
      });
    } else {
      setMenuAtchFileList((prev) => {
        return prev.map((item) => {
          if (item.langCd === languageCode.cmnCd) {
            return {
              ...item,
              atchFileGrId: files[0]?.atchFileGrId ? files[0]?.atchFileGrId : '',
              crudKey: CrudCode.CREATE,
            };
          }
          return item;
        });
      });
    }
  };

  return (
    <>
      {isOpen && (
        <Dialog open={isOpen} css={di.dialog} maxWidth="xs">
          <DialogTitle className="popupTitle">
            {t('com.label.00701', '__첨부파일 관리')}
            <IconButton className="buttonClose" onClick={handleNoButtonClick}>
              <CloseIcon fontSize="large"></CloseIcon>
            </IconButton>
          </DialogTitle>
          <DialogContent className="popupContent">
            <FormControl>
              <ViewTable>
                <colgroup>
                  <col width="15%" />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row">
                      <GLabel>{t('com.label.00702', '__메뉴명')}</GLabel>
                    </th>
                    <td>{msgInfo.msgTxtCtn1}</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <GLabel>{t('com.label.00703', '__첨부파일 유형')}</GLabel>
                    </th>
                    <td>
                      <CustomSelect
                        id="fileCategory"
                        value={selectedFileCategory}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                          handleFileCategoryChange(e);
                        }}
                      >
                        <option value="">{t('com.label.00356', '__선택')}</option>
                        {fileCategoryItems.map((category, index) => (
                          <option key={index} value={category.cmnCd}>
                            {category.cmnCdNm}
                          </option>
                        ))}
                      </CustomSelect>
                    </td>
                  </tr>
                  {languageCodes.map((languageCode, index) => (
                    <tr key={`msgTxtCtn${index + 1}`}>
                      <th scope="row">
                        <label htmlFor={`msgTxtCtn${index + 1}`}>{languageCode.cmnCdNm}</label>
                      </th>
                      <td>
                        <GFileUploadZone
                          atchFileGrIdInput={menuAtchFileList[index]?.atchFileGrId}
                          ref={fileUploadRef[index]}
                          isMultipleFile={false}
                          allowFileTypes={allowFileTypes}
                        />
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <th scope="row">
                      <label htmlFor="useYn">
                        {t('message-management.label.사용여부', '__사용여부')}
                      </label>
                    </th>
                    <td>
                      <CustomSelect
                        id="useYn"
                        value={atchFileUseYn}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                          handleAtchFileUseYnChange(e);
                        }}
                      >
                        {commonYNList.map((useYnCode) => (
                          <option key={useYnCode.cmnCd} value={useYnCode.cmnCd}>
                            {useYnCode.cmnCdNm}
                          </option>
                        ))}
                      </CustomSelect>
                    </td>
                  </tr>
                </tbody>
              </ViewTable>
            </FormControl>
          </DialogContent>
          <DialogActions className="popupBottom">
            <GButton
              onClick={handleNoButtonClick}
              color="secondary"
              variant="outlined"
              size="medium"
            >
              {t('com.btn.cancel', '__취소')}
            </GButton>
            <GButton
              onClick={handleYesButtonClick}
              color="primary"
              variant="contained"
              size="medium"
              startIcon={<DoneRoundedIcon />}
            >
              {t('com.btn.ok', '__확인')}
            </GButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
