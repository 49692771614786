import styled from '@emotion/styled';
import { GButton } from 'components/atom/button';
import { GButtonBox } from 'components/layouts/GLayoutBox';
import GButtonLayout from 'components/molecule/GbuttonLayout';
import SearchMolecule, {
  SearchItemWrap,
  SearchWrap,
  UnHiddenSearchWrap,
} from 'components/molecule/SearchMolecule';
import Save from 'components/asset/images/Confirm.svg';

const FaqWrite = () => {
  return (
    <>
      <SearchWrap marginBottom="4px" style={{ padding: '12px' }}>
        <UnHiddenSearchWrap>
          <SearchItemWrap nonButton={true}>
            <SearchMolecule
              labelWidth="88px"
              type="input"
              searchWidth="100%"
              labelTitle="제목"
              labelAlign="left"
              isRequired={true}
              placeholder="제목을 입력하세요"
            />
            <SearchMolecule
              labelWidth="88px"
              type="text"
              searchWidth="100%"
              labelTitle="작성자"
              labelAlign="left"
              isRequired={true}
              textContent="이철영 책임 기타협력업체"
            />
          </SearchItemWrap>
        </UnHiddenSearchWrap>
      </SearchWrap>

      <Editor>에디터 영역 입니다.</Editor>

      <SearchWrap style={{ padding: '12px' }}>
        <UnHiddenSearchWrap>
          <SearchItemWrap nonButton={true}>
            <SearchMolecule
              labelWidth="80px"
              type="fileUpload"
              searchWidth="100%"
              labelTitle="파일첨부"
              labelAlign="left"
              isRequired={true}
            />
          </SearchItemWrap>
        </UnHiddenSearchWrap>
      </SearchWrap>

      <GButtonLayout paddingTop="0px">
        <GButtonBox>
          <GButton
            txt="취소"
            sizes="large"
            btnstyled="outline"
            btnColor="normal"
            hasImg={false}
            hasTxt={true}
          ></GButton>
          <GButton
            chkImg={Save}
            txt="저장"
            sizes="large"
            btnstyled="contained"
            btnColor="primary"
            hasImg={true}
            hasTxt={true}
          ></GButton>
        </GButtonBox>
      </GButtonLayout>
    </>
  );
};

const Editor = styled.div`
  width: 100%;
  height: 500px;
  margin-bottom: 12px;
  border: 1px solid #dde0df;
`;

export default FaqWrite;
