/** @jsxImportSource @emotion/react */
import { useCallback, useEffect, useState } from 'react';
import MenuSidebar from 'pages/admin/menumanagement/MenuSidebar';
import { MenuEnum, MenuRequest, MenuVO } from 'models/admin/Menu';
import { createMenu, getAllMenus, getMenusByRole, saveMenusByRole } from 'apis/admin/MenuApi';
import useMenuManagementStore from 'stores/useMenuManagementStore';
import RoleManagementGrid from 'pages/admin/rolemanagement/RoleManagementGrid';
import DepartmentManageTable from 'pages/admin/departmentmanagement/DepartmentManageTable';
import RoleEmpManagementGrid from 'pages/admin/rolemanagement/RoleEmpManagementGrid';
import { AgGridReact } from 'ag-grid-react';
import styled from '@emotion/styled';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';

import { ViewTable } from 'components/tables/ViewTable';
import { css } from '@emotion/react';
import {
  BlueButton,
  GreyButton,
  GreyLineButton,
  IconButton,
} from 'components/buttons/CustomButton';
import { CustomInputText, st } from 'components/inputs/CustomInput';
import { BgColor, BorderColor, ButtonColor, FontColor } from 'ui/theme/Color';
import { useCommonModal } from 'hooks/useCommonModal';
import { useTranslation } from 'react-i18next';
import { CrudCode } from 'models/common/Edit';
import MenumanagementTable, {
  MenuManagementTableColType,
} from './menumanagement/MenuManagementTable';
import { useMessageModalStore } from 'stores/useMessageModalStore';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { Code } from 'models/common/CommonCode';
import { commonYNarr } from 'models/common/Common';
import { SearchBox, SearchButtons, SearchRows } from 'components/layouts/SearchBox';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { ContentFlex } from 'components/layouts/ContentFlex';
import {
  ContentSection,
  GridButtons,
  GridInfo,
  GridInfoSection,
} from 'components/layouts/ContentSection';
import { ContentGrid } from 'components/layouts/ContentGrid';
import { di } from 'components/layouts/Dialog';
import { CustomSelect } from 'components/selects/CustomSelect';
import useSessionStore from 'stores/useSessionStore';
import { useMenuAtchFileModalStore } from 'stores/useMenuAtchFileModalStore';
import searchImage from 'components/asset/images/Search.svg';
import { GButton } from 'components/atom/button';
import Save from 'components/asset/images/Confirm.svg';
import { GButtonBox } from 'components/molecule/GbuttonLayout';

const MenuManagementPage = () => {
  useEffect(() => {
    getMenus();
    getLangCds();
  }, []);

  const {
    setModalOpen,
    modalOpen,
    menuId,
    setMenuId,
    menuName,
    setMenuName,
    targetMenuId,
    setTargetMenuId,
    menuLocation,
    setMenuLocation,
    menuUseYn,
    setMenuUseYn,
    menuExposureYn,
    setMenuExposureYn,
    menuOptionValue1,
    setMenuOptionValue1,
    menuOptionValue2,
    setMenuOptionValue2,
    menuOptionValue3,
    setMenuOptionValue3,
    menuOptionValue4,
    setMenuOptionValue4,
    menuOptionValue5,
    setMenuOptionValue5,
    messageCode,
    setMessageCode,
    menuUrl,
    setMenuUrl,
    menuDesc,
    setMenuDesc,
    menuInfoId,
    menuInfoUrl,
    menuInfoDesc,
    rolesByMenu,
    employeesByMenu,
    departmentByMenu,
    allMenuList,
    setAllMenuList,
    allMenuListExceptClickedMenu,
    menuListWithCheckbox,
    setMenuListWithCheckbox,
    menuListbyRole,
    setMenuListbyRole,
  } = useMenuManagementStore((state) => state);

  const { setMessageModalStateWhenModalOpen } = useMessageModalStore();
  const { setMenuAtchFileModalStateWhenModalOpen } = useMenuAtchFileModalStore();

  const [menuMgtModalOpen, setMenuMgtModalOpen] = useState<boolean>(false);
  const [colDefs, setColDefs] = useState<MenuManagementTableColType>(MenumanagementTable());
  const [roleSearchParamData, setRoleSearchParamData] = useState<string>('');
  const [departmentSearchParamData, setDepartmentSearchParamData] = useState<string>('');
  const [roleNameSearchParamData, setRoleNameSearchParamData] = useState<string>('');
  const [langCds, setLangCds] = useState<Code[]>([]);
  const [language, setLanguage] = useState<string>('ko');
  const { openCommonModal } = useCommonModal();
  const { t } = useTranslation();
  const { gridNoRowsTemplate } = useSessionStore();

  const getLangCds = async () => {
    const res = await getCommonCodeNames('LANG_CD');
    setLangCds(res ?? []);
  };

  const handleClose = () => {
    setMenuMgtModalOpen(false);
  };

  const saveMenu = async () => {
    if (!checkMandatoryParam()) return;
    const newMenu: MenuRequest = {
      mnuId: menuId,
      mnuNm: menuName,
      targetMenuId: targetMenuId,
      menuLocation: menuLocation,
      useYn: menuUseYn,
      mnuEpsYn: menuExposureYn,
      mnuOptValCtn1: menuOptionValue1,
      mnuOptValCtn2: menuOptionValue2,
      mnuOptValCtn3: menuOptionValue3,
      mnuOptValCtn4: menuOptionValue4,
      mnuOptValCtn5: menuOptionValue5,
      msgCtn: messageCode,
      mnuUrl: menuUrl,
      mnuDesc: menuDesc,
      crudKey: menuId ? CrudCode.UPDATE : CrudCode.CREATE,
    };
    await createMenu(newMenu);

    closeModal();
  };

  const checkMandatoryParam = () => {
    if (menuName === '') {
      openCommonModal({
        content: t('com.label.mgt.1042', '__메뉴 이름은 필수값입니다.'),
      });
      return false;
    }

    return true;
  };

  const closeModal = () => {
    getMenus();
    setMenuId('');
    setMenuName('');
    setTargetMenuId(MenuEnum.root);
    setMenuLocation('3');
    setMenuUseYn('Y');
    setMenuExposureYn('Y');
    setMenuOptionValue1('');
    setMenuOptionValue2('');
    setMenuOptionValue3('');
    setMenuOptionValue4('');
    setMenuOptionValue5('');
    setMessageCode('');
    setMenuUrl('');
    setMenuDesc('');
    setModalOpen(false);
    setMenuListbyRole([]);
  };

  async function getMenus() {
    const allMenuList = await getAllMenus();
    const allMenuListWithOptionName = allMenuList?.map((element: MenuVO) => {
      return { ...element, optionName: element.mnuNm, optionValue: element.mnuId };
    });
    setAllMenuList(allMenuListWithOptionName);
  }

  async function getRoleMenus(roleCd: string) {
    const menuListbyRole = await getMenusByRole(roleCd);

    setMenuListWithCheckbox(menuListbyRole.map((item) => item.mnuId));
    setMenuListbyRole(menuListbyRole);
    addChecked(menuListbyRole);
  }

  const addChecked = async (menuListbyRole: MenuVO[]) => {
    const allMenuLists = await getAllMenus();

    const alteredRoleMenuList = allMenuLists?.map((element: MenuVO) => {
      const exists = menuListbyRole?.find((el) => el.mnuId === element.mnuId);
      if (exists) {
        return { ...element, isChecked: true };
      } else {
        return { ...element, isChecked: false };
      }
    });
    setMenuListbyRole(alteredRoleMenuList);
  };

  const btnSearch = () => {
    setRoleSearchParamData(roleNameSearchParamData);
    setDepartmentSearchParamData('');
  };

  const handleSearchParamData = (e: any) => {
    setRoleNameSearchParamData(e.target.value);
  };

  const onCallbackRoleClick = useCallback((roleCd: string) => {
    setDepartmentSearchParamData(roleCd);
    getRoleMenus(roleCd);
  }, []);

  const onClickRoleMenuSave = async () => {
    const res = await saveMenusByRole(departmentSearchParamData, menuListWithCheckbox);
    if (res) {
      openCommonModal({ content: t('com.msg.save', '__저장되었습니다.') });
    }
  };

  const handleMultiLanguageButton = () => {
    if (messageCode) setMessageModalStateWhenModalOpen(messageCode);
  };

  const handleMenuAtchFileButton = () => {
    if (messageCode) setMenuAtchFileModalStateWhenModalOpen(messageCode);
  };

  return (
    <>
      <SearchBox>
        <SearchRows>
          <div className="searchCol">
            <label>{t('com.label.roleNm', '__역할명')}</label>
            <CustomInputText onChange={handleSearchParamData}></CustomInputText>
          </div>
        </SearchRows>
        <SearchButtons className="searchButtons">
          <GButton
            chkImg={searchImage}
            txt={t('com.label.00116', '__조회')}
            sizes="medium"
            btnstyled="contained"
            btnColor="normal"
            hasImg={true}
            hasTxt={true}
            onClick={btnSearch}
          ></GButton>
        </SearchButtons>
      </SearchBox>
      <ContentFlex>
        <ContentSection className="section width30p">
          <RoleManagementGrid
            sRoleNm={roleSearchParamData}
            editable={false}
            callBackRoleCd={onCallbackRoleClick}
            width={100}
            height={250}
          />
          <RoleEmpManagementGrid
            sRoleCd={departmentSearchParamData}
            editable={false}
            height={250}
          />

          <DepartmentManageTable
            roleCd={departmentSearchParamData}
            editable={false}
            height={300}
            width={250}
          />
        </ContentSection>
        <ContentSection className="section width30p">
          <GridInfoSection>
            <GridInfo>
              <span>{t('com.label.mgt.1015', '__메뉴')}</span>
            </GridInfo>
            <div>
              <GButtonBox>
                <GButton
                  txt={t('com.btn.mngMenu', '__메뉴관리')}
                  sizes="medium"
                  onClick={() => setMenuMgtModalOpen(true)}
                  btnstyled="outline"
                  btnColor="normal"
                  hasImg={false}
                  hasTxt={true}
                />
                <GButton
                  chkImg={Save}
                  txt={t('com.label.00055', '__저장')}
                  sizes="medium"
                  btnstyled="contained"
                  btnColor="primary"
                  hasImg={true}
                  hasTxt={true}
                  onClick={() => onClickRoleMenuSave()}
                />
              </GButtonBox>
            </div>
          </GridInfoSection>
          <CheckTreeBox>
            <CheckTree>
              {menuListbyRole?.length > 0 ? (
                <MenuSidebar items={menuListbyRole} hasCheckbox={true} />
              ) : (
                allMenuList && <MenuSidebar items={allMenuList} hasCheckbox={true} />
              )}
            </CheckTree>
          </CheckTreeBox>
        </ContentSection>
        <ContentSection className="section width40p">
          <GridInfoSection className="minHeight36">
            <GridInfo>
              <span>{t('com.label.mnuIfo', '__메뉴정보')}</span>
            </GridInfo>
          </GridInfoSection>
          <FormControl fullWidth>
            <ViewTable>
              <colgroup>
                <col width="30%" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">
                    <label>{t('com.label.mgt.1007', '__메뉴ID')}</label>
                  </th>
                  <td>
                    <CustomInputText readOnly={true} value={menuInfoId}></CustomInputText>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label>{t('com.label.url', '__URL')}</label>
                  </th>
                  <td>
                    <CustomInputText
                      className="fullWidth"
                      readOnly={true}
                      value={menuInfoUrl}
                    ></CustomInputText>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label>{t('com.label.mnuDesc', '__메뉴설명')}</label>
                  </th>
                  <td>
                    <CustomInputText
                      className="fullWidth"
                      readOnly={true}
                      value={menuInfoDesc}
                    ></CustomInputText>
                  </td>
                </tr>
              </tbody>
            </ViewTable>
          </FormControl>
          <GridInfoSection className="contentBetween marginT20">
            <GridInfo>
              <span>{t('com.label.avabRole', '__이용가능 역할')}</span>
            </GridInfo>
          </GridInfoSection>
          <ContentGrid className="ag-theme-alpine" style={{ height: '200px' }}>
            <AgGridReact
              overlayNoRowsTemplate={gridNoRowsTemplate}
              rowData={rolesByMenu}
              columnDefs={colDefs.roleByMenuCol}
            ></AgGridReact>
          </ContentGrid>
          <GridInfoSection className="contentBetween marginT20">
            <GridInfo>
              <span>{t('com.label.avabUser', '__이용가능 사용자')}</span>
            </GridInfo>
          </GridInfoSection>
          <ContentGrid className="ag-theme-alpine" style={{ height: '200px' }}>
            <AgGridReact
              overlayNoRowsTemplate={gridNoRowsTemplate}
              rowData={employeesByMenu}
              columnDefs={colDefs.employeeByMenuCol}
            ></AgGridReact>
          </ContentGrid>
          <GridInfoSection className="contentBetween marginT20">
            <GridInfo>
              <span>{t('com.label.mgt.1008', '__이용가능 부서')}</span>
            </GridInfo>
          </GridInfoSection>
          <ContentGrid className="ag-theme-alpine" style={{ height: '200px' }}>
            <AgGridReact
              overlayNoRowsTemplate={gridNoRowsTemplate}
              rowData={departmentByMenu}
              columnDefs={colDefs.departmentByMenuCol}
            ></AgGridReact>
          </ContentGrid>
        </ContentSection>
      </ContentFlex>

      <Dialog open={menuMgtModalOpen} onClose={handleClose} css={di.dialog} maxWidth="sm" fullWidth>
        <DialogTitle className="popupTitle">
          {t('com.btn.mngMenu', '__메뉴관리')}
          <IconButton className="buttonClose" onClick={handleClose}>
            <CloseIcon fontSize="large"></CloseIcon>
          </IconButton>
        </DialogTitle>
        <DialogContent className="popupContent" style={{ width: '500px' }}>
          <SearchBox>
            <SearchRows>
              <div className="searchCol fullWidth">
                <label>{t('com.label.mgt.1036', '__언어')}</label>
                <CustomSelect
                  onChange={(e) => {
                    setLanguage(e.target.value);
                  }}
                >
                  {langCds.map((code) => (
                    <option key={code.cmnCd} value={code.cmnCd}>
                      {code.cmnCdNm}
                    </option>
                  ))}
                </CustomSelect>
              </div>
            </SearchRows>
          </SearchBox>
          <ContentSection className="marginT0">
            <GridInfoSection className="contentEnd">
              <GridButtons>
                <GreyLineButton onClick={() => setModalOpen(true)}>
                  {t('com.label.mgt.1037', '__메뉴추가')}
                </GreyLineButton>
              </GridButtons>
            </GridInfoSection>
            <TreeBox>
              <Tree>
                {<MenuSidebar items={allMenuList} langCd={language} hasCheckbox={false} />}
              </Tree>
            </TreeBox>
          </ContentSection>
        </DialogContent>
        <DialogActions className="popupBottom">
          <GreyLineButton onClick={handleClose}>{t('com.btn.cancel', '__취소')}</GreyLineButton>
          <BlueButton onClick={handleClose}>
            {' '}
            <DoneIcon fontSize="small"></DoneIcon>
            {t('com.btn.ok', '__확인')}
          </BlueButton>
        </DialogActions>
      </Dialog>

      <Dialog open={modalOpen} onClose={closeModal} css={di.dialog}>
        <DialogTitle className="popupTitle">
          {t('com.label.mgt.1043', '__메뉴 추가/수정')}
          <IconButton className="buttonClose" onClick={closeModal}>
            <CloseIcon fontSize="large"></CloseIcon>
          </IconButton>
        </DialogTitle>
        <DialogContent className="popupContent">
          <ContentSection className="marginT0">
            <ViewTable className="width300">
              <colgroup>
                <col width="30%" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="col">
                    <label>{t('com.label.00702', '__메뉴명')}</label>
                    <GreyLineButton className="small" onClick={handleMultiLanguageButton}>
                      {t('com.label.mgt.1044', '__다국어설정')}
                    </GreyLineButton>
                  </th>
                  <td>
                    <CustomInputText
                      onChange={(e) => setMenuName(e.target.value)}
                      value={menuName}
                    ></CustomInputText>
                    <GreyLineButton className="small" onClick={handleMenuAtchFileButton}>
                      {t('com.label.00701', '__첨부파일 관리')}
                    </GreyLineButton>
                  </td>
                </tr>
                <tr>
                  <th scope="col">
                    <label> {t('com.label.mgt.1045', '__변경기준메뉴')}</label>
                  </th>
                  <td>
                    <CustomSelect
                      onChange={(e) => setTargetMenuId(e.target.value)}
                      defaultValue={menuId === '' ? MenuEnum.root : targetMenuId}
                    >
                      {menuId !== ''
                        ? allMenuListExceptClickedMenu.map((menu) => (
                            <option key={menu.mnuId} value={menu.mnuId}>
                              {menu.mnuNm}
                            </option>
                          ))
                        : allMenuList.map((menu) => (
                            <option key={menu.mnuId} value={menu.mnuId}>
                              {menu.mnuNm}
                            </option>
                          ))}
                    </CustomSelect>
                  </td>
                </tr>
                <tr>
                  <th scope="col">
                    <label> {t('com.label.mgt.1046', '__메뉴위치')}</label>
                  </th>
                  <td>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={menuLocation}
                        onChange={(e) => {
                          setMenuLocation(e.target.value);
                        }}
                      >
                        <FormControlLabel
                          value="1"
                          control={
                            <Radio
                              disabled={targetMenuId === MenuEnum.root ? true : false}
                              css={st.radio}
                              size="small"
                            />
                          }
                          label={t('com.label.mgt.1047', '__위')}
                          css={st.label}
                        />
                        <FormControlLabel
                          disabled={targetMenuId === MenuEnum.root ? true : false}
                          value="2"
                          control={<Radio css={st.radio} size="small" />}
                          label={t('com.label.mgt.1048', '__아래')}
                          css={st.label}
                        />
                        <FormControlLabel
                          value="3"
                          control={<Radio css={st.radio} size="small" />}
                          label={t('com.label.mgt.1049', '__하위')}
                          css={st.label}
                        />
                        <FormControlLabel
                          disabled={menuId === '' ? true : false}
                          value="4"
                          control={<Radio css={st.radio} size="small" />}
                          label={t('com.label.mgt.1050', '__변경없음')}
                          css={st.label}
                        />
                      </RadioGroup>
                    </FormControl>
                  </td>
                </tr>
                <tr>
                  <th scope="col">
                    <label>{t('com.label.useYn', '__사용여부')}</label>
                  </th>
                  <td>
                    <CustomSelect
                      onChange={(e) => setMenuUseYn(e.target.value)}
                      defaultValue={menuUseYn}
                    >
                      {commonYNarr.map((code) => (
                        <option key={code.optionValue} value={code.optionValue}>
                          {code.optionName}
                        </option>
                      ))}
                    </CustomSelect>
                  </td>
                </tr>
                <tr>
                  <th scope="col">
                    <label>{t('com.label.mgt.1051', '__메뉴노출여부')}</label>
                  </th>
                  <td>
                    <CustomSelect
                      onChange={(e) => setMenuExposureYn(e.target.value)}
                      defaultValue={menuExposureYn}
                    >
                      {commonYNarr.map((code) => (
                        <option key={code.optionValue} value={code.optionValue}>
                          {code.optionName}
                        </option>
                      ))}
                    </CustomSelect>
                  </td>
                </tr>
                <tr>
                  <th scope="col">
                    <label>{t('com.label.url', '__URL')}</label>
                  </th>
                  <td>
                    <CustomInputText
                      onChange={(e) => setMenuUrl(e.target.value)}
                      value={menuUrl}
                      className="fullWidth"
                    ></CustomInputText>
                  </td>
                </tr>
                <tr>
                  <th scope="col">
                    <label>{t('com.label.mnuDesc', '__메뉴설명')}</label>
                  </th>
                  <td>
                    <CustomInputText
                      onChange={(e) => setMenuDesc(e.target.value)}
                      value={menuDesc}
                      className="fullWidth"
                    ></CustomInputText>
                  </td>
                </tr>
                <tr>
                  <th scope="col">
                    <label>{t('com.label.msgCd', '__메시지코드')}</label>
                  </th>
                  <td>
                    <CustomInputText
                      onChange={(e) => setMessageCode(e.target.value)}
                      value={messageCode}
                      className="fullWidth"
                    ></CustomInputText>
                  </td>
                </tr>
                <tr>
                  <th scope="col">
                    <label>{t('com.label.mgt.1052', '__메뉴옵션값')}1</label>
                  </th>
                  <td>
                    <CustomInputText
                      onChange={(e) => setMenuOptionValue1(e.target.value)}
                      value={menuOptionValue1}
                      className="fullWidth"
                    ></CustomInputText>
                  </td>
                </tr>
                <tr>
                  <th scope="col">
                    <label>{t('com.label.mgt.1052', '__메뉴옵션값')}2</label>
                  </th>
                  <td>
                    <CustomInputText
                      onChange={(e) => setMenuOptionValue2(e.target.value)}
                      value={menuOptionValue2}
                      className="fullWidth"
                    ></CustomInputText>
                  </td>
                </tr>
                <tr>
                  <th scope="col">
                    <label>{t('com.label.mgt.1052', '__메뉴옵션값')}3</label>
                  </th>
                  <td>
                    <CustomInputText
                      onChange={(e) => setMenuOptionValue3(e.target.value)}
                      value={menuOptionValue3}
                      className="fullWidth"
                    ></CustomInputText>
                  </td>
                </tr>
                <tr>
                  <th scope="col">
                    <label>{t('com.label.mgt.1052', '__메뉴옵션값')}4</label>
                  </th>
                  <td>
                    <CustomInputText
                      onChange={(e) => setMenuOptionValue4(e.target.value)}
                      value={menuOptionValue4}
                      className="fullWidth"
                    ></CustomInputText>
                  </td>
                </tr>
                <tr>
                  <th scope="col">
                    <label>{t('com.label.mgt.1052', '__메뉴옵션값')}5</label>
                  </th>
                  <td>
                    <CustomInputText
                      onChange={(e) => setMenuOptionValue5(e.target.value)}
                      value={menuOptionValue5}
                      className="fullWidth"
                    ></CustomInputText>
                  </td>
                </tr>
              </tbody>
            </ViewTable>
          </ContentSection>
        </DialogContent>
        <DialogActions className="popupBottom">
          <GreyLineButton onClick={closeModal}>{t('com.btn.cancel', '__취소')}</GreyLineButton>
          <BlueButton onClick={saveMenu}>
            <DoneIcon fontSize="small"></DoneIcon>
            {t('com.btn.save', '__저장')}
          </BlueButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MenuManagementPage;

const CheckTreeBox = styled.div`
  min-height: 400px;
  padding: 10px 0;
  border: 1px solid ${BorderColor.Primary};
`;

const CheckTree = styled.div`
  margin-top: 5px;

  label {
    display: inline-block;
    position: relative;
    padding: 6px 0;
    cursor: pointer;
    color: ${FontColor.Gray400};
    width: 100%;
    height: 36px;
    font-size: 13px;

    &.root {
      color: ${FontColor.Default};
    }
    &.root:before {
      background-image: url(/assets/icon/ic-tree-close.png);
    }

    &:hover,
    &:focus,
    &.on {
      background-color: ${BgColor.Secondary50};

      :after {
        content: '';
        display: inline-block;
        width: 3px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: ${BgColor.Secondary100};
      }
    }

    &:before {
      content: '';
      width: 24px;
      height: 24px;
      display: inline-block;
      vertical-align: -6px;
      background: url(/assets/icon/ic-tree-close.png) no-repeat center;
    }

    &.lastTree:before {
      background-image: none;
    }
  }

  li {
    position: relative;
    list-style: none;
    font-size: 13px;

    &:before {
      content: '';
      display: inline-block;
      width: 1px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 10px;
    }

    &.lastList {
      :before {
        display: none;
      }
    }

    &.hasCheckbox > label:before {
      margin-right: 28px;
    }

    .MuiCheckbox-root {
      position: absolute;
      left: 26px;
      top: 8px;
      z-index: 2;
      input {
        display: block;
      }
      .Mui-checked {
        color: #3ec2cf;
      }
    }
  }

  ul {
    display: none;
  }

  *:before {
    width: 15px;
    height: 15px;
    display: inline-block;
  }

  input[type='checkbox'] {
    display: none;
    &:checked ~ ul {
      display: block;
    }
    &:checked ~ ul > li {
      label {
        padding-left: 25px;
      }

      .MuiCheckbox-root {
        left: 50px;
      }
    }

    &:checked ~ ul > li ul > li {
      label {
        padding-left: 50px;
      }

      .MuiCheckbox-root {
        left: 75px;
      }
    }

    &:checked ~ ul > li ul > li ul > li {
      label {
        padding-left: 75px;
      }

      .MuiCheckbox-root {
        left: 100px;
      }
    }

    &:checked + label:before {
      background-image: url(/assets/icon/ic-tree-open.png);
    }

    &:checked + label.lastTree:before {
      background-image: none;
    }

    &:checked + label.root:before {
      background-image: url(/assets/icon/ic-tree-open.png);
    }
  }

  [datatype='N'] {
    color: ${FontColor.Gray200} !important;
  }
`;
const TreeBox = styled.div`
  min-height: 400px;
  padding: 10px 0;
  border: 1px solid ${BorderColor.Primary};
`;

const Tree = styled.div`
  margin-top: 5px;

  label {
    display: inline-block;
    position: relative;
    padding: 6px 0 8px;
    cursor: pointer;
    color: ${FontColor.Gray400};
    width: 100%;
    font-size: 13px;

    &.root {
      color: ${FontColor.Default};
    }
    &.root:before {
      background-image: url(/assets/icon/ic-tree-close.png);
    }

    &:hover,
    &:focus,
    &.on {
      background-color: ${BgColor.Secondary50};

      :after {
        content: '';
        display: inline-block;
        width: 3px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: ${BgColor.Secondary100};
      }
    }

    &:before {
      content: '';
      width: 24px;
      height: 24px;
      display: inline-block;
      vertical-align: -6px;
      background: url(/assets/icon/ic-tree-close.png) no-repeat center;
    }

    &.lastTree:before {
      background-image: none;
    }
  }

  li {
    position: relative;
    list-style: none;
    font-size: 13px;

    &:before {
      content: '';
      display: inline-block;
      width: 1px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 10px;
    }

    &.lastList {
      :before {
        display: none;
      }
    }

    .MuiCheckbox-root {
      position: absolute;
      left: 26px;
      top: 8px;
      z-index: 2;
      input {
        display: block;
      }
      .Mui-checked {
        color: #3ec2cf;
      }
    }
  }

  ul {
    display: none;
  }

  *:before {
    width: 15px;
    height: 15px;
    display: inline-block;
  }

  input[type='checkbox'] {
    display: none;
    &:checked ~ ul {
      display: block;
    }
    &:checked ~ ul > li {
      label {
        padding-left: 25px;
      }

      .MuiCheckbox-root {
        left: 50px;
      }
    }

    &:checked ~ ul > li ul > li {
      label {
        padding-left: 50px;
      }

      .MuiCheckbox-root {
        left: 75px;
      }
    }

    &:checked ~ ul > li ul > li ul > li {
      label {
        padding-left: 75px;
      }

      .MuiCheckbox-root {
        left: 100px;
      }
    }

    &:checked + label:before {
      background-image: url(/assets/icon/ic-tree-open.png);
    }

    &:checked + label.lastTree:before {
      background-image: none;
    }

    &:checked + label.root:before {
      background-image: url(/assets/icon/ic-tree-open.png);
    }
  }

  [datatype='N'] {
    color: ${FontColor.Gray200} !important;
  }
`;
