import { GButton } from 'components/atom/button';
import { GButtonBox } from 'components/layouts/GLayoutBox';
import GButtonLayout from 'components/molecule/GbuttonLayout';
import SearchMolecule, {
  SearchItemWrap,
  SearchWrap,
  UnHiddenSearchWrap,
} from 'components/molecule/SearchMolecule';
import { object, string } from 'yup';
import Save from 'components/asset/images/Confirm.svg';
import FileUploadBoxVars from '../../../components/vars/common/FileUploadBoxVars';
import CrossEditor, { CrossEditorProps } from '../../../components/vars/qna/CrossEditor';
import { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLoading } from 'components/process/Loading';
import { useTranslation } from 'react-i18next';
import { useCommonModal } from 'hooks/useCommonModal';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useAuthStore from 'stores/useAuthStore';
import useSessionStore from 'stores/useSessionStore';
import CSearchMolecule from 'components/vars/common/CSearchMolecule';
import SearchMoleculeVars from 'components/vars/common/SearchMoleculeVars';
import { ValidationBox } from 'components/validation/ValidationBox';
import { FileSaveResponse, FileSaveResult } from 'models/admin/FileInfo';
import { getUserInfo2 } from 'apis/common/Employee';
import { getAdminQnaDetailApi } from 'apis/vars/qna/QnaAdminApi';
import {
  editUserQnaContentApi,
  getUserQnaDetailApi,
  postUserQnaContentApi,
} from 'apis/vars/qna/QnaUserApi';
import { useMessageBar } from 'components/process/MessageBar';
import { makeErrorMapFrom } from 'utils/ValidationUtil';
import avatarUser from 'components/asset/images/avatarUser.svg';

export interface QnaRegist {
  bbmTitNm: string;
  bbmCtn: string;
  atchFileGrId: string | null;
  emlTpCd: string;
}

export const InitQnaRegist: QnaRegist = {
  bbmTitNm: '',
  bbmCtn: '',
  atchFileGrId: '',
  emlTpCd: '',
};

const returnByteLength = (str: string) => {
  let byteLength = 0;

  for (let i = 0; i < str.length; ++i) {
    str.charCodeAt(i) > 127 ? (byteLength += 3) : byteLength++;
  }

  return byteLength;
};
export const QnaInfoContext = createContext<QnaRegist>(InitQnaRegist);

const QnaRegistPage = () => {
  const { t } = useTranslation();
  const t2 = t as (msgId: string, defVal: string) => string;
  const navigate = useNavigate();
  const dropzoneRef = useRef<any>();
  const editorRef = useRef<CrossEditor>(null);

  const { openLoading } = useLoading();
  const { openMessageBar } = useMessageBar();
  const { openCommonModal } = useCommonModal();

  const [formData, setFormData] = useState<QnaRegist>(InitQnaRegist);
  const [validationErrMap, setValidationErrMap] = useState<Map<string, string>>(new Map());
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [selectEmlTpCd, setSelectEmlTpCd] = useState<string>('V01');

  // const { bbmNo } = useParams();
  const { bbmNo }: { bbmNo?: number } = useLocation()?.state || {};
  const { accessToken } = useAuthStore();
  const { userId, userNm, langCd } = useSessionStore();
  const [userInfo, setUserInfo] = useState('');

  useEffect(() => {
    if (bbmNo) {
      openLoading(true);
      const uptVwct = 'N';
      if (accessToken) {
        getUserQnaDetailApi(Number(bbmNo), uptVwct).then((res) => {
          setFormData({
            bbmTitNm: res.bbmTitNm,
            bbmCtn: res.bbmCtn,
            atchFileGrId: res.atchFileGrId,
            emlTpCd: res.emlTpCd,
          });
          setSelectEmlTpCd(res.emlTpCd);
          openLoading(false);
        });
      } else {
        getAdminQnaDetailApi(Number(bbmNo), uptVwct).then((res) => {
          setFormData({
            bbmTitNm: res.bbmTitNm,
            bbmCtn: res.bbmCtn,
            atchFileGrId: res.atchFileGrId,
            emlTpCd: res.emlTpCd,
          });
          openLoading(false);
        });
      }
    }
  }, [bbmNo]);

  useEffect(() => {
    const init = async () => {
      if (userId) {
        if (accessToken) {
          setUserInfo(userNm);
        } else {
          setUserInfo(await getUserInfo2());
        }
      }
    };
    init();
  }, []);

  const schema = useMemo(() => {
    return object({
      bbmTitNm: string()
        .required(t2('com.label.00057', '__제목을 입력하세요'))
        .test(
          'check-byte',
          String(t('com.label.00469', '__제목은 300바이트를 초과할 수 없습니다.')),
          (value, context) => {
            return returnByteLength(value) <= 300;
          }
        ),
    });
  }, []);

  const handleTitleChange = useCallback((e) => {
    setFormData((prev) => {
      return { ...prev, bbmTitNm: e.target.value };
    });
  }, []);

  const handleCancel = () => {
    openCommonModal({
      modalType: 'confirm',
      content: (
        <>
          {t('com.label.00167', '__작성하신 내용이 저장되지 않았습니다.')}
          <br />
          {t('com.label.00168', '__그래도 화면을 닫으시겠습니까?')}
        </>
      ),
      noCallback: () => {
        return;
      },
      yesCallback: () => {
        navigate('/qna/list');
      },
    });
  };

  const checkValidate = async (object) => {
    try {
      setValidationErrMap(new Map());
      await schema.validate(object, { abortEarly: false });
    } catch (e: any) {
      setValidationErrMap(makeErrorMapFrom(e));
      throw new Error();
    }
  };

  const getFileId = async () => {
    const fileSaveResponse: FileSaveResponse = await dropzoneRef.current.saveFiles();
    return fileSaveResponse.fileSaveResult == FileSaveResult.FAIL
      ? null
      : fileSaveResponse.atchFileGrId;
  };

  const handleSave = async () => {
    try {
      const content = await editorRef.current?.editorRef.GetBodyValue();
      await checkValidate({ ...formData, content: content });

      openLoading(true);
      const fileId = await getFileId();
      setFormData((prev) => {
        return {
          ...prev,
          bbmCtn: content,
          emlTpCd: selectEmlTpCd,
          atchFileGrId: fileId,
        };
      });

      if (bbmNo) {
        editUserQnaContentApi({
          bbmNo: Number(bbmNo),
          bbmTitNm: formData.bbmTitNm,
          bbmCtn: content,
          atchFileGrId: fileId,
          emlTpCd: selectEmlTpCd,
        }).then((res) => {
          openLoading(false);
          openMessageBar('success', t('com.msg.save', '__저장되었습니다.'));
          navigate('/qna/detail', {
            state: { bbmNo: bbmNo },
          });
        });
      } else {
        postUserQnaContentApi({
          bbmTitNm: formData.bbmTitNm,
          bbmCtn: content,
          atchFileGrId: fileId,
          emlTpCd: selectEmlTpCd,
        }).then((res) => {
          openLoading(false);
          openMessageBar('success', t('com.msg.save', '__저장되었습니다.'));
          navigate('/qna/list');
        });
      }
    } catch (e: any) {
      return;
    }
  };

  const currentUserLang = langCd === 'en' ? 'enu' : langCd === 'zhC' ? 'chs' : 'kor';

  const editorProps: CrossEditorProps = {
    name: 'editor',
    params: {
      Width: '100%',
      Height: 500,
      event: {
        // UploadProc: uploadProc,
      },
      Placeholder: t2('com.label.00149', '__내용을 입력하세요.'),
      UserLang: currentUserLang,
    },
    value: formData.bbmCtn,
    onLoaded: () => {
      setIsLoaded(true);
    },
  };

  const handleChangeTpCd = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectEmlTpCd(e.target.value);
  };

  useEffect(() => {
    if (formData.bbmCtn && formData.bbmCtn.length > 0) {
      isLoaded && editorRef.current?.editorRef.SetBodyValue(formData.bbmCtn);
    }
  }, [isLoaded, formData.bbmCtn]);

  return (
    <>
      <SearchWrap marginBottom="4px" style={{ padding: '12px' }}>
        <UnHiddenSearchWrap>
          <SearchItemWrap nonButton={true}>
            <CSearchMolecule
              type="input"
              searchWidth="100%"
              labelTitle={t('com.label.00048', '__제목')}
              labelAlign="left"
              isRequired={true}
              placeholder={t2('com.label.00057', '__제목을 입력하세요')}
              textInputValue={formData.bbmTitNm}
              onChange={handleTitleChange}
              inputStatus={
                validationErrMap.get('bbmTitNm') && formData.bbmTitNm.trim() === ''
                  ? 'error'
                  : 'default'
              }
              helperText={
                validationErrMap.get('bbmTitNm') && formData.bbmTitNm.trim() === ''
                  ? validationErrMap.get('bbmTitNm')
                  : undefined
              }
            />
            <SearchMolecule
              type="avatarChip"
              avaterType="user"
              avaterSize="medium"
              searchWidth="50%"
              labelTitle={t('com.label.00058', '__작성자')}
              labelAlign="left"
              isRequired={true}
              avaterBackgroundBorder="lightGray"
              avaterProfileBackgroundColor="#554596"
              avaterProfileBorder="#554596"
              avaterProfileImg={avatarUser}
              avaterprofileItemColor="#fff"
              avaterNickname={userInfo}
            />
            <SearchMoleculeVars
              searchWidth="50%"
              radio2Id="qnaRegist"
              type="radio2"
              labelTitle={t('com.label.00117', '__구분')}
              labelAlign="left"
              isRequired={true}
              radio2Value={selectEmlTpCd || ''}
              radio2ValueKey="cmnCd"
              radio2LabelKey="cmnCdNm"
              radio2DefaultValue={selectEmlTpCd}
              radio2Option={[
                { cmnCd: 'V01', cmnCdNm: t('com.code.KRW_FCUR_CD.W', '원화') },
                { cmnCd: 'V02', cmnCdNm: t('com.code.KRW_FCUR_CD.F', '외화') },
                { cmnCd: 'V03', cmnCdNm: t('com.label.00227', '해외') },
              ]}
              onChange={handleChangeTpCd}
            />
          </SearchItemWrap>
        </UnHiddenSearchWrap>
      </SearchWrap>

      <CrossEditor ref={editorRef} {...editorProps} />

      <SearchWrap style={{ marginTop: '2px', padding: '12px', marginBottom: '4px' }}>
        <UnHiddenSearchWrap>
          <SearchItemWrap nonButton={true}>
            <FileUploadBoxVars
              labelWidth="120px"
              type="fileUpload"
              searchWidth="100%"
              isRequired={false}
              labelTitle={t('com.label.00056', '파일첨부')}
              labelAlign="left"
              searchParams={formData}
              dropzoneRef={dropzoneRef}
            />
          </SearchItemWrap>
        </UnHiddenSearchWrap>
      </SearchWrap>
      <GButtonLayout paddingTop="0px">
        <GButtonBox>
          <GButton
            txt={t('com.label.00036', '취소')}
            sizes="large"
            btnstyled="outline"
            btnColor="normal"
            hasImg={false}
            hasTxt={true}
            onClick={handleCancel}
          ></GButton>
          <GButton
            chkImg={Save}
            txt={t('com.label.00055', '저장')}
            sizes="large"
            btnstyled="contained"
            btnColor="primary"
            hasImg={true}
            hasTxt={true}
            onClick={handleSave}
          ></GButton>
        </GButtonBox>
      </GButtonLayout>
    </>
  );
};

export default QnaRegistPage;
