import { ICellRendererParams } from 'ag-grid-community';
import { GButton } from 'components/atom/button';

export interface IButtonCellRendererParams extends ICellRendererParams {
  img?: string;
  onClick?: () => void;
  disabled?: (() => boolean) | boolean;
  isDisplayNone?: boolean;
  isOnlyView?: boolean;
}

export const FileCellRenderer = ({
  img,
  onClick,
  disabled,
  isDisplayNone = false,
  isOnlyView = false,
  ...params
}: IButtonCellRendererParams) => {
  const handleClick = () => {
    onClick?.();
  };

  const renderButton = () => {
    return (
      <GButton
        hasImg={true}
        hasTxt={false}
        chkImg={img}
        sizes="small"
        btnColor="none"
        btnstyled="outline"
        onClick={handleClick}
        style={{
          padding: '0',
          height: '16px',
          display: isDisplayNone ? 'none' : 'inline-block',
          cursor: isOnlyView ? 'default' : 'pointer',
        }}
      />
    );
  };

  return <>{!disabled && renderButton()}</>;
};
