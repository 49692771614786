import axios from 'axios';
import { useCommonModal } from 'hooks/useCommonModal';
import { ChangeEvent, FormEvent, useState } from 'react';

const ExcelUploadSample = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const { openCommonModal } = useCommonModal();

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (fileList && fileList.length > 0) {
      setSelectedFile(fileList[0]);
    }
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (selectedFile) {
      try {
        const formData = new FormData();
        formData.append('file', selectedFile);
        axios
          .post(`/v1/excel/upload`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Access-Control-Allow-Origin': '*',
            },
          })
          .then((response) => {
            if (response.data.statusCode == 'FAIL') {
              openCommonModal({ content: '첨부파일을 확인해 주세요' });
            }
          });
      } catch (error) {
        console.error('파일 업로드 실패 : ', error);
        openCommonModal({ content: '파일 업로드 실패' });
      }
    } else {
      openCommonModal({ content: '첨부파일이 없습니다.' });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input type="file" onChange={handleFileChange} />
      <button type="submit">Excel Upload</button>
    </form>
  );
};

export default ExcelUploadSample;
