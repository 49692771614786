import styled from '@emotion/styled';
import { TextSize, TextWeight } from 'ui/theme/CustomTypography';
import { FontColor } from 'ui/theme/Color';

interface TitleProps {
  title: string;
}

const SubTitle = (props: TitleProps) => {
  return <Title>{props.title}</Title>;
};

const Title = styled.h3`
  color: ${FontColor.Default};
  font-family: Spoqa Han Sans Neo;
  font-size: ${TextSize.H3};
  font-style: normal;
  font-weight: ${TextWeight.Bold};
  line-height: 24px;
  display: inline-block;
  text-wrap: nowrap;
`;

export default SubTitle;
