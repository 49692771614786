import React, { InputHTMLAttributes } from 'react';
import styled from '@emotion/styled';

import ErrorIcon from '../../asset/images/error.svg';
import WarningIcon from '../../asset/images/warning.svg';
import ConfirmedIcon from '../../asset/images/confirmed.svg';
import { GlobalTokenColor } from 'ui/theme/Color';
import DarkSearch from 'components/asset/images/DarkSearch.svg';

export interface GInputProps extends InputHTMLAttributes<HTMLInputElement> {
  status:
    | 'default'
    | 'disabled'
    | 'readonly'
    | 'error'
    | 'warning'
    | 'confirmed'
    | 'search'
    | undefined;

  isPassword?: boolean;

  align?: 'left' | 'right' | undefined;

  width?: string;
  minWidth?: string;

  helperText?: string;

  isRequired?: boolean;
}

const InputField: React.FC<GInputProps> = ({ helperText, isPassword, ...props }) => {
  return (
    <StyledWrapper {...props}>
      <StyledInputWrapper {...props}>
        <StyledInput {...props} type={isPassword ? 'password' : 'text'} />
      </StyledInputWrapper>
      {helperText && <HelperText status={props.status}>{helperText}</HelperText>}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div<GInputProps>`
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width || '100%'};
  ${(props) => props.minWidth && `min-width : ${props.minWidth}`};
`;

const StyledInputWrapper = styled.div<GInputProps>`
  padding: 3px 7px;
  position: relative;
  width: 100%;
  height: 32px;
  border-radius: 2px;
  border: 1px solid ${GlobalTokenColor.ESGrey400};
  background: ${GlobalTokenColor.ESGreyWHite};

  &:focus-within {
    border-color: ${GlobalTokenColor.ESGrey700};
  }

  /* status prop */
  ${(props) =>
    props.status === 'disabled' &&
    `
    background: ${GlobalTokenColor.StatsuDone100};
  `}

  ${(props) =>
    props.status === 'readonly' &&
    `
    background: ${GlobalTokenColor.StatsuDone100};
    color: ${GlobalTokenColor.ESGrey900};
  `}

  ${(props) =>
    props.status === 'error' &&
    `
    border-color: ${GlobalTokenColor.StatusError300};

    padding-right: 28px;
    // error icon
    background: url(${ErrorIcon}) ${GlobalTokenColor.ESGreyWHite} no-repeat calc(100% - 8px) 7px;
    
    `}
    
  ${(props) =>
    props.status === 'warning' &&
    `
    border-color: ${GlobalTokenColor.StatusWarningMajor300};

    padding-right: 28px;

    // warning icon
    background: url(${WarningIcon}) ${GlobalTokenColor.ESGreyWHite} no-repeat calc(100% - 8px) 7px;

    `}
    
    ${(props) =>
      props.status === 'confirmed' &&
      `
    border-color: ${GlobalTokenColor.StatusConfirmed300};

    padding-right: 28px;
      
    // confirmed icon
    background: url(${ConfirmedIcon}) ${GlobalTokenColor.ESGreyWHite} no-repeat calc(100% - 8px) 7px;
      
  `}
  
  ${(props) =>
    props.status === 'search' &&
    `
    padding-left : 28px;
    background: url(${DarkSearch}) ${GlobalTokenColor.ESGreyWHite} no-repeat 7px;
  `}

  &::before {
    ${(props) =>
      props.isRequired
        ? `
        content:"";
        position: absolute;
        top: 2px;
        left: 2px;
        width: 4px;
        height: 4px;
        border-radius: 2px;
        background-color: #F94B50;
        z-index: 10;
    `
        : ``}
`;

const StyledInput = styled.input<GInputProps>`
  width: 100%;
  height: 24px;
  border: none;

  font-family: Spoqa Han Sans Neo;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  outline: none;

  &::placeholder {
    color: ${GlobalTokenColor.ESGrey500};
  }

  /* align prop */
  text-align: ${(props) => props.align || 'left'};

  ${(props) =>
    props.status === 'readonly' &&
    `
    background: ${GlobalTokenColor.StatsuDone100};
    color: ${GlobalTokenColor.ESGrey900};

    &::placeholder {
      color: ${GlobalTokenColor.ESGrey900};
    }
  `}
`;

export const HelperText = styled.span<GInputProps>`
  font-family: Spoqa Han Sans Neo;
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
  color: #979998;

  margin-top: 4px;
  width: auto;
  height: auto;

  ${(props) =>
    props.status === 'error' &&
    `
    color: #f94b50;
    `}

  ${(props) =>
    props.status === 'warning' &&
    `
    color: #ff9322;
    `}
    
    ${(props) =>
    props.status === 'confirmed' &&
    `
    color: #00806a;
  `}
`;

export default InputField;
