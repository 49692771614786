import React, { useEffect, useState } from 'react';

import { GButtonBox, GPopupTitle } from 'components/layouts/GLayoutBox';
import { DialogTitleItem } from 'components/layouts/GModalPopup';
import ModalLayout from 'components/layouts/ModalLayout';
import { DialogActions, DialogContent } from '@mui/material';
import { GButton } from 'components/atom/button';
import { GbasicTable, GbasicTableWrap } from 'components/tables/GTable';
import { GLabelAtom } from 'components/atom/label';
import Tag from 'components/atom/tag';
import FileDownloadVars from 'components/vars/common/FileDownloadVars';
import { useAccountRegist } from '../hooks/useAccountRegist';
import { getForeignAccount, removeForeginAccount } from 'apis/vars/account/ForeignAccount';
import { ForeignAccountVO } from 'models/vars/account/ForeignAccountVO';
import { useTranslation } from 'react-i18next';
import { removeDomesticAccount } from 'apis/vars/account/DomesticAccount';
import { useMessageBar } from 'components/process/MessageBar';

type DepartmentModalProps = {
  open: boolean;
  vendorInfo?: any;
  frgnAccount?: any;
  stdAccnCds?: any;
  close: () => void;
  save: (param?: boolean) => void;
  modify: () => void;
  node: any;
  isKrw?: string;
};

const DmsDtlFrgnAcntModal = (props: DepartmentModalProps) => {
  const { t } = useTranslation();
  const t2 = t as (msgId: string, defVal: string) => string;
  const [dtlFrgnAccount, setDtlFrgnAccount] = useState<ForeignAccountVO>();
  const { openMessageBar } = useMessageBar();

  const handleClose = () => {
    props.close();
  };

  const handlClickModify = () => {
    props.modify();
  };

  const handlClickRemove = () => {
    removeForeginAccount(props.frgnAccount.accnRegReqId).then((resp) => {
      console.log(resp);
      if (resp.successOrNot === 'Y') {
        openMessageBar('success', t('com.label.00179', '__삭제되었습니다.'));
        props.save(true);
      }
    });
  };

  useEffect(() => {
    if (props.frgnAccount.accnRegReqId) {
      getForeignAccount(props.frgnAccount.vdcpCd, props.frgnAccount.accnRegReqId).then((resp) => {
        if (resp) {
          setDtlFrgnAccount(resp);
        }
      });
    } else {
      // db에 데이터가 없는 경우
      setDtlFrgnAccount({
        ...props.frgnAccount,
        // bfBankNm: props.frgnAccount.oldBankNm,
        // bfEncAccnNo: props.frgnAccount.oldEncAccnNo,
        // bfDpstNm: props.frgnAccount.oldDpstNm,
        // bfCopCd: props.frgnAccount.oldCopCd,
        // bfCopNm: props.frgnAccount.oldCopNm,
        // bfBankCd: props.frgnAccount.oldBankCd,
        // bfBankKeyCd: props.frgnAccount.oldBankKeyCd,
        // bfBankCtryCd: props.frgnAccount.oldBankCtryCd,
        // bfAddDpstNm: props.frgnAccount.oldAddDpstNm,
        // bfCurrCd: props.frgnAccount.oldBfCurrCd,
        // bfPtnrBankTpId: props.frgnAccount.oldBfPtnrBankTpId,
        // bfBankBrchCd: props.frgnAccount.oldBankBrchCd,
        // bfIbanCd: props.frgnAccount.oldIbanCd,
      });
    }
  }, []);

  const { getTagStatus, callBackAccountsRetrieve } = useAccountRegist({});

  return (
    <ModalLayout open={props.open} onClose={handleClose} mMinWidth={1000} mMaxWidth={1000}>
      {dtlFrgnAccount && (
        <>
          <DialogTitleItem id="customized-dialog-title" onClose={handleClose}>
            <GPopupTitle title2={t('com.label.00455', '__계좌정보상세')} />
          </DialogTitleItem>
          <DialogContent className="popupContent">
            <GbasicTableWrap>
              <GbasicTable>
                <colgroup>
                  <col style={{ width: '160px' }} />
                  <col style={{ width: 'auto' }} />
                  <col style={{ width: '160px' }} />
                  <col style={{ width: 'auto' }} />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <GLabelAtom align="left">{t('com.label.00067', '__예금주')}</GLabelAtom>
                    </th>
                    <td
                      colSpan={2}
                      style={{
                        maxWidth: '200px',
                        wordWrap: 'break-word',
                        overflowWrap: 'break-word',
                        whiteSpace: 'normal',
                      }}
                    >
                      {dtlFrgnAccount.dpstNm}
                    </td>
                    <th>
                      <GLabelAtom align="left">{t('com.label.00069', '__예금주 확인')}</GLabelAtom>
                    </th>
                    <td
                      colSpan={2}
                      style={{
                        maxWidth: '200px',
                        wordWrap: 'break-word',
                        overflowWrap: 'break-word',
                        whiteSpace: 'normal',
                      }}
                    >
                      {dtlFrgnAccount.addDpstNm}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <GLabelAtom align="left">{t('com.label.00070', '__계좌번호')}</GLabelAtom>
                    </th>
                    <td colSpan={2}>{dtlFrgnAccount.encAccnNo}</td>
                    <th>
                      <GLabelAtom align="left">{t('com.label.00076', '__통화')}</GLabelAtom>
                    </th>
                    <td colSpan={2}>{dtlFrgnAccount.currCd}</td>
                  </tr>
                  <tr>
                    <th>
                      <GLabelAtom isRequired={false} align="left">
                        {t('com.label.00065', '__은행')}
                      </GLabelAtom>
                    </th>
                    <td
                      colSpan={2}
                      style={{
                        maxWidth: '200px',
                        wordWrap: 'break-word',
                        overflowWrap: 'break-word',
                        whiteSpace: 'normal',
                      }}
                    >
                      {dtlFrgnAccount.bankNm}
                    </td>
                    <th>
                      <GLabelAtom align="left">{t('com.label.00064', '__진행상태')}</GLabelAtom>
                    </th>
                    <td>
                      <Tag
                        content={dtlFrgnAccount?.accnRegProgStatCdNm}
                        status={getTagStatus(dtlFrgnAccount.accnRegProgStatCd ?? '')}
                      />
                    </td>
                  </tr>
                  {dtlFrgnAccount.isErpOnly == 'N' && (
                    <>
                      <tr>
                        <th>
                          <GLabelAtom align="left">
                            {t('com.code.bankAddr', '__은행주소')}
                          </GLabelAtom>
                        </th>
                        <td
                          colSpan={3}
                          style={{
                            maxWidth: '200px',
                            wordWrap: 'break-word',
                            overflowWrap: 'break-word',
                            whiteSpace: 'normal',
                          }}
                        >
                          {dtlFrgnAccount.bankAddr}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <GLabelAtom align="left">
                            {t('com.code.bankCntNum', '__은행연락처')}
                          </GLabelAtom>
                        </th>
                        <td colSpan={2}>{dtlFrgnAccount.bankCtpl}</td>
                        <th>
                          <GLabelAtom align="left">
                            {t2('com.code.bankEmal', '__은행이메일')}
                          </GLabelAtom>
                        </th>
                        <td>{dtlFrgnAccount.bankEml}</td>
                      </tr>
                      {props.vendorInfo.dmstFrgDivsCd == 'OV' && !props.isKrw && (
                        <tr>
                          <th>
                            <GLabelAtom align="left">
                              {t('com.code.bankAddIfo', '__계좌추가정보')}
                            </GLabelAtom>
                          </th>
                          <td colSpan={3}>
                            {dtlFrgnAccount.bankNoTpCdNm}&emsp;&emsp;
                            {dtlFrgnAccount.bankNo}
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                  <tr>
                    <th>
                      <GLabelAtom align="left">SWIFT/BIC</GLabelAtom>
                    </th>
                    <td colSpan={2}>{dtlFrgnAccount?.swftCd}</td>
                    {props.vendorInfo.dmstFrgDivsCd == 'OV' && !props.isKrw && (
                      <>
                        <th>
                          <GLabelAtom align="left">IBAN Code</GLabelAtom>
                        </th>
                        <td colSpan={2}>{dtlFrgnAccount?.ibanCd}</td>
                      </>
                    )}
                  </tr>
                  {dtlFrgnAccount.isErpOnly == 'N' && (
                    <>
                      <tr>
                        <th>
                          <GLabelAtom align="left">
                            {t('com.label.00453', '__계좌등록요청서')}
                          </GLabelAtom>
                        </th>
                        <td colSpan={3} style={{ verticalAlign: 'top' }}>
                          <FileDownloadVars atchFileGrId={dtlFrgnAccount.fileId1} />
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <GLabelAtom align="left">
                            {t('com.label.00659', '__은행계좌확인서 또는 계좌사본(인증시)')}
                          </GLabelAtom>
                        </th>
                        <td colSpan={3} style={{ verticalAlign: 'top' }}>
                          <FileDownloadVars atchFileGrId={dtlFrgnAccount.fileId2} />
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <GLabelAtom align="left">
                            {t('com.label.00284', '__국가별법인등록증')}
                          </GLabelAtom>
                        </th>
                        <td colSpan={3} style={{ verticalAlign: 'top' }}>
                          <FileDownloadVars atchFileGrId={dtlFrgnAccount.fileId3} />
                        </td>
                      </tr>
                      {/* <tr>
                        <th>
                          <GLabelAtom align="left">{t('com.label.00075', '__기타')}1</GLabelAtom>
                        </th>
                        <td colSpan={3}>
                          <FileDownloadVars atchFileGrId={dtlFrgnAccount.fileId4} />
                        </td>
                      </tr> */}
                      <tr>
                        <th>
                          <GLabelAtom align="left">{t('com.label.00075', '__기타')}</GLabelAtom>
                        </th>
                        <td colSpan={3}>
                          <FileDownloadVars atchFileGrId={dtlFrgnAccount.fileId5} />
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </GbasicTable>
            </GbasicTableWrap>
          </DialogContent>
          <DialogActions className="popupBottom">
            <GButtonBox>
              {(dtlFrgnAccount.accnRegProgStatCd == 'T' ||
                dtlFrgnAccount.accnRegProgStatCd == 'R' ||
                dtlFrgnAccount.accnRegProgStatCd == 'C') && (
                <GButton
                  txt={t('com.label.00209', '__수정')}
                  sizes="medium"
                  onClick={() => {
                    handlClickModify();
                  }}
                  btnstyled="outline"
                  btnColor="normal"
                  hasImg={false}
                  hasTxt={true}
                />
              )}

              {dtlFrgnAccount.accnRegProgStatCd == 'T' && (
                <GButton
                  txt={t('com.label.00054', '__삭제')}
                  sizes="medium"
                  onClick={() => {
                    handlClickRemove();
                  }}
                  btnstyled="outline"
                  btnColor="normal"
                  hasImg={false}
                  hasTxt={true}
                />
              )}
            </GButtonBox>
          </DialogActions>
        </>
      )}
    </ModalLayout>
  );
};

export default DmsDtlFrgnAcntModal;
