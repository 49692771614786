import { useEffect, useState } from 'react';
import { Code } from 'models/common/CommonCode';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { getJwtCommonCodeNames } from 'apis/vars/common/Common';
import useAuthStore from 'stores/useAuthStore';
import { CommonAccessCode } from 'models/vars/common/Common';

const useCommonCode = (cmnCd: string) => {
  const [codes, setCodes] = useState<Code[]>([]);
  const { accessCode } = useAuthStore();

  useEffect(() => {
    if (cmnCd) {
      if (accessCode == CommonAccessCode.INTERNAL) {
        getCommonCodeNames(cmnCd).then((res) => {
          setCodes(res);
        });
      } else {
        getJwtCommonCodeNames(cmnCd).then((res) => {
          setCodes(res);
        });
      }
    }
  }, [cmnCd]);

  return codes;
};

export default useCommonCode;
