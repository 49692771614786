import { t } from 'i18next';
import dayjs from 'dayjs';
import {
  GButtonBoxCum,
  SearchItemWrap,
  SearchWrap,
  UnHiddenSearchWrap,
} from 'components/molecule/SearchMolecule';
import Download from 'components/asset/images/Upload.svg';
import RecycleB from 'components/asset/images/RecycleB.svg';
import searchImage from 'components/asset/images/Search.svg';

import { AgGridReact } from 'ag-grid-react';
import { useLoading } from 'components/process/Loading';
import { useTranslation } from 'react-i18next';
import { useCommonModal } from 'hooks/useCommonModal';
import { useEffect, useRef, useState } from 'react';
import { useMessageBar } from 'components/process/MessageBar';
import useSessionStore from 'stores/useSessionStore';

import GTabs from 'components/organism/GTabs';
import GButtonLayout from 'components/molecule/GbuttonLayout';
import SubTitleBox from 'components/molecule/SubTitleBox';
import SearchMolecule from 'components/common/SearchMolecule';
import EmptyState from 'components/atom/emptyState';
import { GButton } from 'components/atom/button';
import { GContentGrid } from 'components/layouts/GContentGrid';
import { SubTitleBtnArea } from 'components/organism/SubTitleBtnArea';
import { TitleBtnBox } from 'components/molecule/ButtonArea';
import { VendorReqInfoVO } from 'models/vars/vendor/VendorReqInfoVO';
import { CellClickedEvent } from 'ag-grid-community';

import { useKPaymentColDefs } from './hooks/KPaymentListDefs';
import { useKPaymentDetailColDefs } from './hooks/KPaymentListDetailDefs';
import { useKNonPaymentColDefs } from './hooks/KNonPaymentDefs';
import { useKFrgnCurPaymentColDefs } from './hooks/KFrgnCurPaymentDefs';
import { useKFrgnCurPaymentDetailColDefs } from './hooks/KFrgnCurPaymentDetailDefs';
import {
  getFrgnCurApi,
  getFrgnCurDetailApi,
  getKNonPaymentApi,
  getKPaymentDetailApi,
  getKPaymentListApi,
} from 'apis/vars/payment/PaymentApi';
import {
  getKPaymentExcelDownloadApi,
  getKPaymentDetailExcelDownloadApi,
  getKNonPaymentExcelDownloadApi,
  getFrgnCurExcelDownloadApi,
  getFrgnCurDetailsExcelDownloadApi,
} from 'apis/vars/payment/PaymentExcelApi';
import SubTitleBoxVars from 'components/vars/common/SubTitleBoxVars';

export interface PaymentSearchParams {
  dataInsDtmSt: string; // 조회기간 시작일자
  dataInsDtmEnd: string; // 조회기간 종료일자
  userId: string;
  downloadName: string;
}

// 지급내역조회
export interface KPaymentRes {
  payDivCd: string; //지급방법
  payDivNm: string; // 구분 (지급방법명)
  payDt: string; // 지급일자
  payAmt: string; // 금액
  payBankCd: string; // 지급은행코드
  payBankNm: string; // 지급은행명
  accnNo: string; // 계좌번호
  curr: string; // 통화(외화)
  swift: string; // swift(외화)
  profNo: string; // 전문번호(외화)
}

// 지급내역조회 상세요청 값
export interface DetailReq {
  accnNo: string;
  slipNo: string; // 0404 전표번호 추가
  payBankCd: string;
  payDt: string;
  userId: string;
  downloadName: string;
}

// 지급내역조회 상세
export interface KPaymentDetail {
  slipNo: string; // 전표번호
  prfDt: string; // 증빙일
  expDt: string; // 만기일(원화)
  payAmt: string; // 금액(원화)
  mgrDeptCd: string; // 담당부서코드
  mgrDeptNm: string; // 담당부서명
  taxInvcNo: string; // 세금계산서번호(원화)
  purcOrd: string; // 구매오더
  impReqNo: string; // 수입의뢰번호(외화)
}

// 미지급내역조회
export interface KNonPaymentRes {
  payDivCd: string; //지급방법
  payDivNm: string; //구분 (지급방법명)
  slipNo: string; //전표번호
  prfDt: string; //증빙일
  expDt: string; //어음만기일
  curr: string; //통화
  payAmt: string; //금액
  mgrDeptCd: string; //담당부서
  mgrDeptNm: string; //담당부서명
  taxInvcNo: string; //세금계산서번호
  purcOrd: string; //구매오더
}

// 외화지급내역조회
export interface KFrgnCurRes {
  payDt: string; // 지급일자
  curr: string; // 통화
  payAmt: string; // 금액
  payBankCd: string; // 지급은행코드 (그리드에는 안띄우는 값)
  payBankNm: string; // 지급은행
  swift: string; // Swift
  accnNo: string; // 계좌번호
  profNo: string; // 전문번호
}

// 외화지급내역조회 상세
export interface KFrgnCurDetail {
  slipNo: string; // 전표번호
  prfDt: string; // 증빙일자
  mgrDeptCd: string; // 담당부서코드 (그리드에는 안띄우는 값)
  mgrDeptNm: string; // 담당부서명
  purcOrd: string; // 구매오더
  impReqNo: string; // 수입의뢰번호
}

interface PropsType {
  vendorInfo: VendorReqInfoVO;
}

const KPaymentSchedulePage = (props: PropsType) => {
  const { t } = useTranslation();
  const { userId } = useSessionStore();
  const gridApiRef = useRef<any>(null);
  const { openLoading } = useLoading();
  const { openMessageBar } = useMessageBar();
  const { openCommonModal } = useCommonModal();
  const [selectedTab, setSelectedTab] = useState<number>(1);

  const { defaultColDef, colDefs } = useKPaymentColDefs();
  const { defaultColDef1, colDefs1 } = useKPaymentDetailColDefs();
  const { defaultColDef2, colDefs2 } = useKNonPaymentColDefs();
  const { defaultColDef3, colDefs3 } = useKFrgnCurPaymentColDefs();
  const { defaultColDef4, colDefs4 } = useKFrgnCurPaymentDetailColDefs();

  const [paymentList, setPaymentList] = useState<KPaymentRes[]>([]);
  const [paymentDetails, setPaymentDetails] = useState<KPaymentDetail[]>([]);
  const [nonPaymentList, setNonPaymentList] = useState<KNonPaymentRes[]>([]);
  const [frgnCurList, setFrgnCurList] = useState<KFrgnCurRes[]>([]);
  const [frgnCurDetail, setFrgnCurDetail] = useState<KFrgnCurDetail[]>([]);

  const [detailReq, setDetailReq] = useState<DetailReq>({
    accnNo: '',
    slipNo: '',
    payBankCd: '',
    payDt: '',
    userId: '',
    downloadName: '',
  });

  const [searchParams, setSearchParams] = useState<PaymentSearchParams>({
    // 0329 조회기간 디폴트 3개월 재수정(현업요청)
    dataInsDtmSt: dayjs().subtract(3, 'month').format('YYYYMMDD'),
    dataInsDtmEnd: dayjs().format('YYYYMMDD'),
    userId: userId,
    downloadName: '',
  });

  useEffect(() => {
    openLoading(true);
    getKPaymentListApi(searchParams).then((res) => {
      setPaymentList(res);
      openLoading(false);
      if (!res) {
        setPaymentList([]);
      }
    });
  }, []);

  // 그리드 행 선택 시 상세 api호출
  useEffect(() => {
    if (detailReq.accnNo) {
      openLoading(true);
      if (selectedTab === 1) {
        getKPaymentDetailApi(detailReq).then((res) => {
          setPaymentDetails(res);
          openLoading(false);
        });
      } else if (selectedTab === 2) {
        getFrgnCurDetailApi(detailReq).then((res) => {
          setFrgnCurDetail(res);
          openLoading(false);
        });
      }
    }
  }, [detailReq]);

  const handleCrtDateChange = (newDate, id) => {
    handleDateRangeChange(newDate, id, 'dataInsDtm', 'YYYY.MM.DD');
  };

  const handleDateRangeChange = (newDate, id, key, format) => {
    const newParams = {};
    if (id === 'end') {
      newParams[key + 'End'] = newDate.format(format);

      if (searchParams[key + 'St'] === '' || newDate.isBefore(dayjs(searchParams[key + 'St'])))
        newParams[key + 'St'] = newDate.format(format);

      setSearchParams((prev) => {
        return {
          ...prev,
          ...newParams,
        };
      });
    } else if (id === 'start') {
      newParams[key + 'St'] = newDate.format(format);

      if (searchParams[key + 'End'] === '' || newDate.isAfter(dayjs(searchParams[key + 'End'])))
        newParams[key + 'End'] = newDate.format(format);

      setSearchParams((prev) => {
        return {
          ...prev,
          ...newParams,
        };
      });
    }
  };

  const handleReset = () => {
    setSearchParams({
      dataInsDtmSt: dayjs().subtract(3, 'month').format('YYYYMMDD'),
      dataInsDtmEnd: dayjs().format('YYYYMMDD'),
      userId: userId,
      downloadName: '',
    });
  };

  // 탭별 API 호출 분기처리
  const getDataByTap = (value, searchParams) => {
    if (value === 1) {
      getKPaymentListApi(searchParams).then((res) => {
        setPaymentList(res);
        openLoading(false);
        if (!res) {
          setPaymentList([]);
        }
      });
    } else if (value === 2) {
      getFrgnCurApi(searchParams).then((res) => {
        setFrgnCurList(res);
        openLoading(false);
        if (!res) {
          setFrgnCurList([]);
        }
      });
    } else if (value === 3) {
      getKNonPaymentApi(searchParams).then((res) => {
        setNonPaymentList(res);
        openLoading(false);
        if (!res) {
          setNonPaymentList([]);
        }
      });
    }
    setPaymentDetails([]);
    setFrgnCurDetail([]);
  };

  const handleSearch = () => {
    if (
      searchParams.dataInsDtmSt === 'Invalid Date' ||
      searchParams.dataInsDtmEnd === 'Invalid Date'
    ) {
      return openCommonModal({
        content: t('com.label.00477', '__조회기간을 설정해주세요.'),
      });
    }
    openLoading(true);
    const setdataInsDtmSt = dayjs(searchParams.dataInsDtmSt).format('YYYYMMDD');
    const setdataInsDtmEnd = dayjs(searchParams.dataInsDtmEnd).format('YYYYMMDD');
    const formattedReq = {
      dataInsDtmSt: setdataInsDtmSt,
      dataInsDtmEnd: setdataInsDtmEnd,
      userId: userId,
    };
    getDataByTap(selectedTab, formattedReq);
  };

  const handleTabChange = (e, value) => {
    setSelectedTab(value);
    openLoading(true);
    getDataByTap(value, searchParams);
  };

  return (
    <>
      <SearchWrap>
        <UnHiddenSearchWrap>
          <SearchItemWrap>
            {/* 미지급내역 탭 : 조회일자(오늘날) 고정 */}
            {selectedTab === 3 ? (
              <SearchMolecule
                type="calendar"
                isRequired
                calendarReadonly
                labelTitle={t('com.label.00113', '__지급일자')}
                calendarDouble={false}
                calendarFormat="YYYY.MM.DD"
                calendarViews={['year', 'month', 'day']}
                calendarMaxWidth="128px"
                date={dayjs()}
              />
            ) : (
              <SearchMolecule
                type="calendar"
                isRequired
                labelTitle={t('com.label.00113', '__지급일자')}
                calendarDouble={true}
                calendarFormat="YYYY.MM.DD"
                calendarViews={['year', 'month', 'day']}
                calendarMaxWidth="128px"
                startDate={dayjs(searchParams.dataInsDtmSt)}
                endDate={dayjs(searchParams.dataInsDtmEnd)}
                onChange={handleCrtDateChange}
                mindate={'2001.01.01'}
                maxdate={`${String(Number(new Date().getFullYear()) + 20)}.01.01`}
              />
            )}
          </SearchItemWrap>
          <GButtonLayout marginBottom="0px" paddingTop="0px">
            {selectedTab !== 3 && (
              <GButtonBoxCum>
                <GButton
                  chkImg={RecycleB}
                  sizes="medium"
                  btnstyled="outline"
                  btnColor="normal"
                  hasImg={true}
                  hasTxt={false}
                  onClick={handleReset}
                ></GButton>
                <GButton
                  chkImg={searchImage}
                  txt={t('com.label.00116', '__조회')}
                  sizes="medium"
                  btnstyled="contained"
                  btnColor="normal"
                  hasImg={true}
                  hasTxt={true}
                  onClick={handleSearch}
                ></GButton>
              </GButtonBoxCum>
            )}
          </GButtonLayout>
        </UnHiddenSearchWrap>
      </SearchWrap>

      <GTabs
        flex={true}
        defaultValue={1}
        title={[
          t('com.label.00111', '__지급내역조회'),
          t('com.label.00115', '__외화지급조회'),
          t('com.label.00335', '__미지급내역'),
        ]}
        onChange={handleTabChange}
      >
        <>
          <SubTitleBtnArea>
            <SubTitleBoxVars
              showSubTitle={true}
              content={t('com.label.00648', '__지급내역목록')}
              isInfo={true}
              pTxt={
                t(
                  'com.tooltip.pay01',
                  `__※ 원화 지급 기준
- 月 거래기준 합계액이 1천만원 이하인 경우 현금 결제 / 1천만원 초과인 경우 전자어음 결제\r\n
* 대기업의 경우 거래 합계액 불문 전자어음 결제
  - 전자어음 결제시 중소기업의 경우 60일 어음 / 대기업의 경우 90일 어음
  - 현금 결제일 : 매월 10일 / 20일 / 末일
  - 전자어음 결제일 : 매월 10일(전월 16~전월 말일자 계산서 및 전표처리 완료 분까지) 
    / 매월 20일(당월 1일~당월 15일자 계산서 및 전표처리 완료 분까지)`
                ) ?? ''
              }
              pPlace="3"
            />
            <TitleBtnBox>
              <GButton
                txt={t('com.label.00666', '__다운로드')}
                chkImg={Download}
                btnstyled="outline"
                btnColor="normal"
                sizes="small"
                hasTxt={true}
                hasImg={true}
                disabled={paymentList?.length > 0 ? false : true}
                onClick={() => {
                  openLoading(true);
                  getKPaymentExcelDownloadApi({
                    ...searchParams,
                    downloadName: t('com.label.00111', '__지급내역조회'),
                  })
                    .then((res) => {
                      openLoading(false);
                    })
                    .catch((error) => {
                      console.error('다운로드 중 에러 발생:', error);
                      openLoading(false);
                    });
                }}
              />
            </TitleBtnBox>
          </SubTitleBtnArea>
          <GContentGrid
            type="none"
            className="ag-theme-alpine"
            style={{ flex: 1, marginBottom: '12px' }}
            isSortable={false}
          >
            <AgGridReact
              defaultColDef={defaultColDef}
              columnDefs={colDefs}
              rowData={paymentList}
              rowSelection={'single'}
              enableCellTextSelection
              onGridReady={(params) => (gridApiRef.current = params.api)}
              tooltipShowDelay={0}
              noRowsOverlayComponent={EmptyState}
              noRowsOverlayComponentParams={{ type: 'grid' }}
              onCellClicked={(e: CellClickedEvent) => {
                setDetailReq({
                  accnNo: e.data.accnNo,
                  slipNo: e.data.slipNo,
                  payBankCd: e.data.payBankCd,
                  payDt: e.data.payDt,
                  userId: userId,
                  downloadName: '',
                });
              }}
            ></AgGridReact>
          </GContentGrid>
          <SubTitleBtnArea>
            <SubTitleBox showSubTitle={true} content={t('com.label.00120', '__지급내역상세')} />
            <TitleBtnBox>
              <GButton
                txt={t('com.label.00666', '__다운로드')}
                chkImg={Download}
                btnstyled="outline"
                btnColor="normal"
                sizes="small"
                hasTxt={true}
                hasImg={true}
                disabled={paymentDetails?.length > 0 ? false : true}
                onClick={() => {
                  openLoading(true);
                  getKPaymentDetailExcelDownloadApi({
                    ...detailReq,
                    downloadName: t('com.label.00120', '__지급내역상세'),
                  })
                    .then((res) => {
                      openLoading(false);
                    })
                    .catch((error) => {
                      console.error('다운로드 중 에러 발생:', error);
                      openLoading(false);
                    });
                }}
              />
            </TitleBtnBox>
          </SubTitleBtnArea>
          <GContentGrid
            type="none"
            className="ag-theme-alpine"
            style={{ flex: 1, marginBottom: '8px' }}
            isSortable={false}
          >
            <AgGridReact
              defaultColDef={defaultColDef1}
              columnDefs={colDefs1}
              rowData={paymentDetails}
              rowSelection={'single'}
              enableCellTextSelection
              onGridReady={(params) => (gridApiRef.current = params.api)}
              tooltipShowDelay={0}
              noRowsOverlayComponent={EmptyState}
              noRowsOverlayComponentParams={{ type: 'grid' }}
            ></AgGridReact>
          </GContentGrid>
        </>
        <>
          <SubTitleBtnArea>
            <SubTitleBox
              showSubTitle={true}
              content={t('com.label.00649', '__외화지급목록')}
              isVisibleInfo={true}
              infoContent={t(
                'com.tooltip.pay02',
                `__※ 외화 지급의 경우 지급 만기일이 도래하더라도 LG에너지솔루션 담당자의 송금 요청이 있어야 지급 됩니다.`
              )}
            />
            <TitleBtnBox>
              <GButton
                txt={t('com.label.00666', '__다운로드')}
                chkImg={Download}
                btnstyled="outline"
                btnColor="normal"
                sizes="small"
                hasTxt={true}
                hasImg={true}
                disabled={frgnCurList?.length > 0 ? false : true}
                onClick={() => {
                  openLoading(true);
                  getFrgnCurExcelDownloadApi({
                    ...searchParams,
                    downloadName: t('com.label.00649', '__외화지급목록'),
                  })
                    .then((res) => {
                      openLoading(false);
                    })
                    .catch((error) => {
                      console.error('다운로드 중 에러 발생:', error);
                      openLoading(false);
                    });
                }}
              />
            </TitleBtnBox>
          </SubTitleBtnArea>
          <GContentGrid
            type="none"
            className="ag-theme-alpine"
            style={{ flex: 1, marginBottom: '12px' }}
            isSortable={false}
          >
            <AgGridReact
              defaultColDef={defaultColDef3}
              columnDefs={colDefs3}
              rowData={frgnCurList}
              rowSelection={'single'}
              enableCellTextSelection
              onGridReady={(params) => (gridApiRef.current = params.api)}
              tooltipShowDelay={0}
              noRowsOverlayComponent={EmptyState}
              noRowsOverlayComponentParams={{ type: 'grid' }}
              onCellClicked={(e: CellClickedEvent) => {
                setDetailReq({
                  accnNo: e.data.accnNo,
                  slipNo: e.data.slipNo,
                  payBankCd: e.data.payBankCd,
                  payDt: e.data.payDt,
                  userId: userId,
                  downloadName: '',
                });
              }}
            ></AgGridReact>
          </GContentGrid>
          <SubTitleBtnArea>
            <SubTitleBox showSubTitle={true} content={t('com.label.00650', '__외화지급상세')} />
            <TitleBtnBox>
              <GButton
                txt={t('com.label.00666', '__다운로드')}
                chkImg={Download}
                btnstyled="outline"
                btnColor="normal"
                sizes="small"
                hasTxt={true}
                hasImg={true}
                disabled={frgnCurDetail?.length > 0 ? false : true}
                onClick={() => {
                  openLoading(true);
                  getFrgnCurDetailsExcelDownloadApi({
                    ...detailReq,
                    downloadName: t('com.label.00650', '__외화지급상세'),
                  })
                    .then((res) => {
                      openLoading(false);
                    })
                    .catch((error) => {
                      console.error('다운로드 중 에러 발생:', error);
                      openLoading(false);
                    });
                }}
              />
            </TitleBtnBox>
          </SubTitleBtnArea>
          <GContentGrid
            type="none"
            className="ag-theme-alpine"
            style={{ flex: 1, marginBottom: '8px', height: '345px' }}
            isSortable={false}
          >
            <AgGridReact
              defaultColDef={defaultColDef4}
              columnDefs={colDefs4}
              rowData={frgnCurDetail}
              rowSelection={'single'}
              enableCellTextSelection
              onGridReady={(params) => (gridApiRef.current = params.api)}
              tooltipShowDelay={0}
              noRowsOverlayComponent={EmptyState}
              noRowsOverlayComponentParams={{ type: 'grid' }}
            ></AgGridReact>
          </GContentGrid>
        </>
        <>
          <SubTitleBtnArea>
            <SubTitleBox
              showSubTitle={false}
              content={t('com.label.00335', '미지급내역')}
              isVisibleInfo={true}
              infoContent={t(
                'com.tooltip.pay03',
                `__※ 위 내역은 ERP에 기표된 내역으로 지급계좌 등록 여부, 상계 등에 따라 금액 및 지급 예정일자가 달라질 수 있습니다.`
              )}
            />
            <TitleBtnBox>
              <GButton
                txt={t('com.label.00666', '__다운로드')}
                chkImg={Download}
                btnstyled="outline"
                btnColor="normal"
                sizes="small"
                hasTxt={true}
                hasImg={true}
                disabled={nonPaymentList?.length > 0 ? false : true}
                onClick={() => {
                  openLoading(true);
                  getKNonPaymentExcelDownloadApi({
                    ...searchParams,
                    downloadName: t('com.label.00335', '미지급내역'),
                  })
                    .then((res) => {
                      openLoading(false);
                    })
                    .catch((error) => {
                      console.error('다운로드 중 에러 발생:', error);
                      openLoading(false);
                    });
                }}
              />
            </TitleBtnBox>
          </SubTitleBtnArea>
          <GContentGrid
            type="none"
            className="ag-theme-alpine"
            style={{ flex: 1, marginBottom: '8px' }}
            isSortable={false}
          >
            <AgGridReact
              defaultColDef={defaultColDef2}
              columnDefs={colDefs2}
              rowData={nonPaymentList}
              rowSelection={'single'}
              enableCellTextSelection
              onGridReady={(params) => (gridApiRef.current = params.api)}
              tooltipShowDelay={0}
              noRowsOverlayComponent={EmptyState}
              noRowsOverlayComponentParams={{ type: 'grid' }}
            ></AgGridReact>
          </GContentGrid>
        </>
      </GTabs>
    </>
  );
};

export default KPaymentSchedulePage;
