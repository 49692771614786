import dayjs from 'dayjs';
import {
  GButtonBoxCum,
  SearchItemWrap,
  SearchWrap,
  UnHiddenSearchWrap,
} from 'components/molecule/SearchMolecule';
import Download from 'components/asset/images/Upload.svg';
import RecycleB from 'components/asset/images/RecycleB.svg';
import searchImage from 'components/asset/images/Search.svg';

import { AgGridReact } from 'ag-grid-react';
import { useLoading } from 'components/process/Loading';
import { useTranslation } from 'react-i18next';
import { useCommonModal } from 'hooks/useCommonModal';
import { useEffect, useRef, useState } from 'react';
import { useMessageBar } from 'components/process/MessageBar';

import GTabs from 'components/organism/GTabs';
import GButtonLayout from 'components/molecule/GbuttonLayout';
import SubTitleBox from 'components/molecule/SubTitleBox';
import SearchMolecule, { GInputArea, ItemWrap } from 'components/common/SearchMolecule';
import EmptyState from 'components/atom/emptyState';
import { GButton } from 'components/atom/button';
import { GContentGrid } from 'components/layouts/GContentGrid';
import { SubTitleBtnArea } from 'components/organism/SubTitleBtnArea';
import { TitleBtnBox } from 'components/molecule/ButtonArea';
import { CellClickedEvent } from 'ag-grid-community';

import { useOPaymentAdColDefs } from './hooks/OPaymentListAdDefs';
import { useOPaymentDetailAdColDefs } from './hooks/OPaymentListDetailAdDefs';
import { useONonPaymentAdColDefs } from './hooks/ONonPaymentAdDefs';
import { useONonPaymentDetailAdColDefs } from './hooks/ONonPaymentDetailAdDefs';
import { useOPaymentDueAdColDef } from './hooks/OPaymentDueAdDefs';
import {
  getONotePayableDetailsExcelDownloadAdApi,
  getONotePayableExcelDownloadAdApi,
  getOPaymentDetailsExcelDownloadAdApi,
  getOPaymentDueListExcelDownloadAdApi,
  getOPaymentExcelDownloadAdApi,
} from 'apis/vars/payment/PaymentExcelAdminApi';
import SubTitleBoxVars from 'components/vars/common/SubTitleBoxVars';
import { CommonCode } from 'models/admin/CommonCode';
import {
  NotePayable,
  NotePayableDetail,
  OPaymentDetail,
  OPaymentRes,
  PaymentDue,
} from '../payment/OPaymentSchedulePage';
import {
  getONotePayableAdApi,
  getONotePayableDetailAdApi,
  getOPaymentDetailAdApi,
  getOPaymentListAdApi,
  getPaymentDueAdApi,
  getOverseasCopCds,
} from 'apis/vars/payment/PaymentAdminApi';
import { GLabelAtom } from 'components/atom/label';
import GSelectMUIAtom from 'components/common/select/GSelectMUIAtom';
import useAuthStore from 'stores/useAuthStore';
import { getOverseasCopCdsWithJwt } from 'apis/vars/payment/PaymentApi';
import useSessionStore from 'stores/useSessionStore';

// 조회조건
export interface PaymentSearchParams {
  copCd: string; // 법인명
  dataInsDtmSt: string; // 조회기간 시작일자
  dataInsDtmEnd: string; // 조회기간 종료일자
  userId: string;
  downloadName: string;
}

// 상세요청 값
export interface ODetailReq {
  accnNo: string;
  slipNo: string;
  payBankCd: string;
  payDt: string;
  userId: string;
  downloadName: string;
  copCd: string;
}

const OPaymentSchedulePage = () => {
  const { t } = useTranslation();
  const { roleCodes } = useSessionStore();
  const gridApiRef = useRef<any>(null);
  const { openLoading } = useLoading();
  const { openMessageBar } = useMessageBar();
  const { openCommonModal } = useCommonModal();
  const [selectedTab, setSelectedTab] = useState<number>(1);

  // 법인명
  const [coCdList, setCoCdList] = useState<CommonCode[]>([]);
  // 법인 국가코드가 'CN'일 경우 TAB 나타나기
  const [showTab, setShowTab] = useState<boolean>(false);

  const { defaultColDef, colDefs } = useOPaymentAdColDefs();
  const { defaultColDef1, colDefs1 } = useOPaymentDetailAdColDefs();
  const { defaultColDef3, colDefs3 } = useONonPaymentAdColDefs();
  const { defaultColDef4, colDefs4 } = useONonPaymentDetailAdColDefs();
  const { defaultColDef2, colDefs2 } = useOPaymentDueAdColDef();

  const [paymentList, setPaymentList] = useState<OPaymentRes[]>([]);
  const [paymentDetails, setPaymentDetails] = useState<OPaymentDetail[]>([]);
  const [notePayable, setNotePayable] = useState<NotePayable[]>([]);
  const [notePayableDetail, setNotePayableDetail] = useState<NotePayableDetail[]>([]);
  const [paymentDue, setPaymentDue] = useState<PaymentDue[]>([]);

  const { accessToken } = useAuthStore();

  const [detailReq, setDetailReq] = useState<ODetailReq>({
    accnNo: '',
    slipNo: '',
    payBankCd: '',
    payDt: '',
    userId: '',
    downloadName: '',
    copCd: '',
  });

  useEffect(() => {
    console.log('detailReq', detailReq);
  }, [detailReq]);

  const [searchParams, setSearchParams] = useState<PaymentSearchParams>({
    // 0329 조회기간 디폴트 3개월 재수정(현업요청)
    dataInsDtmSt: dayjs().subtract(3, 'month').format('YYYYMMDD'),
    dataInsDtmEnd: dayjs().format('YYYYMMDD'),
    userId: '',
    downloadName: '',
    copCd: '',
  });

  useEffect(() => {
    //법인명 가져오기
    if (accessToken) {
      getOverseasCopCdsWithJwt().then((result: CommonCode[]) => {
        console.log('result with JWT:: ', result);
        setCoCdList(result);
      });
    } else {
      // sso 로그인
      // getOverseasCopCds().then((result: CommonCode[]) => {
      //   console.log('result :: ', result);
      //   setCoCdList(result);
      // });
      getOverseasCopCds().then((result: CommonCode[]) => {
        console.log('result :: ', result);
        // 중복된 cmnCd를 제거하기 위해 Set을 사용
        const uniqueCmnCd = Array.from(new Set(result.map((corp) => corp.cmnCd)));

        // 중복 제거된 cmnCd를 포함하는 객체를 다시 만들어 반환
        const uniqueResult = uniqueCmnCd.map((cmnCd) => {
          // find 메서드가 반환할 수 있는 undefined를 처리
          const foundCorp = result.find((corp) => corp.cmnCd === cmnCd);
          if (foundCorp) {
            return foundCorp;
          }
          // 만약 foundCorp가 undefined이면, 빈 객체 또는 기본값을 반환할 수 있음
          return { cmnCd, role: '' }; // 또는 적절한 기본값
        });

        // 중복 제거된 결과를 상태에 설정
        setCoCdList(uniqueResult);
      });
    }
  }, []);

  // 그리드 행 선택 시 상세 api호출
  useEffect(() => {
    if (selectedTab === 1) {
      getOPaymentDetailAdApi(detailReq).then((res) => {
        setPaymentDetails(res);
        openLoading(false);
      });
    } else if (selectedTab === 3) {
      getONotePayableDetailAdApi(detailReq).then((res) => {
        setNotePayableDetail(res);
        openLoading(false);
      });
    }
    // }
  }, [detailReq]);

  // Vendor ID 대문자로 입력받기
  const handleChangeVendorId = (e) => {
    const upperVendorId = e.target.value.trim().toUpperCase();
    setSearchParams((prev) => {
      return { ...prev, userId: upperVendorId };
    });
  };

  // 법인명 선택
  const handleChangeCopCd = (e) => {
    const selectedCopCd = e.target.value;
    const selectedCopCdCtrCd = coCdList.find((item) => item.cmnCd === selectedCopCd);
    if (selectedCopCdCtrCd?.optValCtn1 === 'CN') {
      setShowTab(true);
      setSearchParams((prev) => {
        return { ...prev, copCd: selectedCopCd };
      });
    } else {
      setShowTab(false);
      setSearchParams((prev) => {
        return { ...prev, copCd: selectedCopCd };
      });
    }
  };

  const handleCrtDateChange = (newDate, id) => {
    handleDateRangeChange(newDate, id, 'dataInsDtm', 'YYYY.MM.DD');
  };

  const handleDateRangeChange = (newDate, id, key, format) => {
    const newParams = {};
    if (id === 'end') {
      newParams[key + 'End'] = newDate.format(format);

      if (searchParams[key + 'St'] === '' || newDate.isBefore(dayjs(searchParams[key + 'St'])))
        newParams[key + 'St'] = newDate.format(format);

      setSearchParams((prev) => {
        return {
          ...prev,
          ...newParams,
        };
      });
    } else if (id === 'start') {
      newParams[key + 'St'] = newDate.format(format);

      if (searchParams[key + 'End'] === '' || newDate.isAfter(dayjs(searchParams[key + 'End'])))
        newParams[key + 'End'] = newDate.format(format);

      setSearchParams((prev) => {
        return {
          ...prev,
          ...newParams,
        };
      });
    }
  };

  const handleReset = () => {
    setSearchParams({
      dataInsDtmSt: dayjs().subtract(3, 'month').format('YYYYMMDD'),
      dataInsDtmEnd: dayjs().format('YYYYMMDD'),
      userId: '',
      downloadName: '',
      copCd: '',
    });
  };

  // 탭별 API 호출 분기처리
  const getDataByTap = (value, searchParams) => {
    if (value === 1) {
      getOPaymentListAdApi(searchParams).then((res) => {
        setPaymentList(res);
        openLoading(false);
        if (!res) {
          setPaymentList([]);
        }
      });
    } else if (value === 2) {
      getPaymentDueAdApi(searchParams).then((res) => {
        setPaymentDue(res);
        openLoading(false);
        if (!res) {
          setPaymentDue([]);
        }
      });
    } else if (value === 3) {
      getONotePayableAdApi(searchParams).then((res) => {
        setNotePayable(res);
        openLoading(false);
        if (!res) {
          setNotePayable([]);
        }
      });
    }
    setPaymentDetails([]);
    setNotePayableDetail([]);
  };

  const handleSearch = () => {
    if (searchParams.userId.trim() === '') {
      return openCommonModal({
        content: t('com.label.00016', '__Vendor ID를 입력하세요.'),
      });
    }
    if (searchParams.copCd === '') {
      return openCommonModal({
        content: t('com.label.00612', '__법인명을 선택하세요.'),
      });
    }
    if (
      searchParams.dataInsDtmSt === 'Invalid Date' ||
      searchParams.dataInsDtmEnd === 'Invalid Date'
    ) {
      return openCommonModal({
        content: t('com.label.00477', '__조회기간을 설정해주세요.'),
      });
    }
    openLoading(true);
    const setdataInsDtmSt = dayjs(searchParams.dataInsDtmSt).format('YYYYMMDD');
    const setdataInsDtmEnd = dayjs(searchParams.dataInsDtmEnd).format('YYYYMMDD');
    const formattedReq = {
      dataInsDtmSt: setdataInsDtmSt,
      dataInsDtmEnd: setdataInsDtmEnd,
      userId: searchParams.userId,
      copCd: searchParams.copCd,
    };
    getDataByTap(selectedTab, formattedReq);
  };

  const handleTabChange = (e, value) => {
    setSelectedTab(value);
    openLoading(true);
    getDataByTap(value, searchParams);
  };

  return (
    <>
      <SearchWrap>
        <UnHiddenSearchWrap>
          <SearchItemWrap>
            {/* Vendor ID 입력 */}
            <SearchMolecule
              labelTitle="Vendor ID"
              labelWidth="100px"
              searchWidth="22%"
              searchValueWidth="200px"
              type="input"
              isRequired
              textInputValue={searchParams.userId}
              placeholder={t('com.label.00016', '__Vendor ID를 입력하세요')}
              onChange={handleChangeVendorId}
              maxLength={12}
            />
            {/* 법인명(C100) 고정 */}
            <GInputArea searchWidth="33%">
              <GLabelAtom isRequired labelWidth="120px">
                {t('com.label.copCd', '법인명')}
              </GLabelAtom>
              <ItemWrap searchValueWidth="380px">
                <GSelectMUIAtom
                  status="default"
                  // selectWidth="350px"
                  option={coCdList}
                  placeholder={t('com.label.00023', '__선택') as string}
                  codeKey="cmnCd"
                  codeName="cmnCdDesc"
                  value={searchParams.copCd}
                  handleChange={handleChangeCopCd}
                />
              </ItemWrap>
            </GInputArea>
            {selectedTab === 2 ? (
              <SearchMolecule
                type="calendar"
                isRequired
                calendarReadonly
                labelTitle={t('com.label.00640', '__Payment Date')}
                calendarDouble={false}
                calendarFormat="YYYY.MM.DD"
                calendarViews={['year', 'month', 'day']}
                calendarMaxWidth="128px"
                date={dayjs()}
              />
            ) : (
              <SearchMolecule
                type="calendar"
                isRequired
                labelTitle={t('com.label.00640', '__Payment Date')}
                calendarDouble={true}
                calendarFormat="YYYY.MM.DD"
                calendarViews={['year', 'month', 'day']}
                calendarMaxWidth="128px"
                startDate={dayjs(searchParams.dataInsDtmSt)}
                endDate={dayjs(searchParams.dataInsDtmEnd)}
                onChange={handleCrtDateChange}
                mindate={'2001.01.01'}
                maxdate={`${String(Number(new Date().getFullYear()) + 20)}.01.01`}
              />
            )}
          </SearchItemWrap>
          <GButtonLayout marginBottom="0px" paddingTop="0px">
            <GButtonBoxCum>
              <GButton
                chkImg={RecycleB}
                sizes="medium"
                btnstyled="outline"
                btnColor="normal"
                hasImg={true}
                hasTxt={false}
                onClick={handleReset}
              ></GButton>
              <GButton
                chkImg={searchImage}
                txt={t('com.label.00620', '__View')}
                sizes="medium"
                btnstyled="contained"
                btnColor="normal"
                hasImg={true}
                hasTxt={true}
                onClick={handleSearch}
              ></GButton>
            </GButtonBoxCum>
          </GButtonLayout>
        </UnHiddenSearchWrap>
      </SearchWrap>
      <GTabs
        flex={true}
        defaultValue={1}
        title={
          showTab
            ? [
                t('com.label.00615', '__Payment List'),
                t('com.label.00617', '__Payment Due(Scheduled) List'),
                t('com.label.00616', '__Note Payable(Issue)'),
              ]
            : [
                t('com.label.00615', '__Payment List'),
                t('com.label.00617', '__Payment Due(Scheduled) List'),
              ]
        }
        onChange={handleTabChange}
      >
        <>
          <SubTitleBtnArea>
            <SubTitleBoxVars
              showSubTitle={true}
              content={t('com.label.00615', '__Payment List')}
              isInfo={true}
              pTxt={
                t(
                  'com.tooltip.pay01',
                  `__※ 원화 지급 기준
- 月 거래기준 합계액이 1천만원 이하인 경우 현금 결제 / 1천만원 초과인 경우 전자어음 결제\r\n
* 대기업의 경우 거래 합계액 불문 전자어음 결제
  - 전자어음 결제시 중소기업의 경우 60일 어음 / 대기업의 경우 90일 어음
  - 현금 결제일 : 매월 10일 / 20일 / 末일
  - 전자어음 결제일 : 매월 10일(전월 16~전월 말일자 계산서 및 전표처리 완료 분까지) 
    / 매월 20일(당월 1일~당월 15일자 계산서 및 전표처리 완료 분까지)`
                ) ?? ''
              }
              pPlace="3"
            />
            <TitleBtnBox>
              <GButton
                txt={t('com.label.00666', '__다운로드')}
                chkImg={Download}
                btnstyled="outline"
                btnColor="normal"
                sizes="small"
                hasTxt={true}
                hasImg={true}
                disabled={paymentList?.length > 0 ? false : true}
                onClick={() => {
                  openLoading(true);
                  getOPaymentExcelDownloadAdApi({
                    ...searchParams,
                    downloadName: t('com.label.00615', '__Payment List'),
                  })
                    .then((res) => {
                      openLoading(false);
                    })
                    .catch((error) => {
                      console.error('다운로드 중 에러 발생:', error);
                      openLoading(false);
                    });
                }}
              />
            </TitleBtnBox>
          </SubTitleBtnArea>
          <GContentGrid
            type="none"
            className="ag-theme-alpine"
            style={{ flex: 1, marginBottom: '12px' }}
            isSortable={false}
            scrollHeight="48px"
          >
            <AgGridReact
              defaultColDef={defaultColDef}
              columnDefs={colDefs}
              rowData={paymentList}
              rowSelection={'single'}
              enableCellTextSelection
              onGridReady={(params) => (gridApiRef.current = params.api)}
              tooltipShowDelay={0}
              noRowsOverlayComponent={EmptyState}
              noRowsOverlayComponentParams={{ type: 'grid' }}
              onCellClicked={(e: CellClickedEvent) => {
                setDetailReq({
                  accnNo: e.data.accnNo,
                  slipNo: e.data.slipNo,
                  payBankCd: e.data.payBankCd,
                  payDt: e.data.payDt,
                  userId: searchParams.userId,
                  downloadName: '',
                  copCd: searchParams.copCd,
                });
              }}
            ></AgGridReact>
          </GContentGrid>
          <SubTitleBtnArea>
            <SubTitleBox
              showSubTitle={true}
              content={t('com.label.00618', '__Payment List Detail')}
            />
            <TitleBtnBox>
              <GButton
                txt={t('com.label.00666', '__다운로드')}
                chkImg={Download}
                btnstyled="outline"
                btnColor="normal"
                sizes="small"
                hasTxt={true}
                hasImg={true}
                disabled={paymentDetails?.length > 0 ? false : true}
                onClick={() => {
                  openLoading(true);
                  getOPaymentDetailsExcelDownloadAdApi({
                    ...detailReq,
                    downloadName: t('com.label.00618', '__Payment List Detail'),
                  })
                    .then((res) => {
                      openLoading(false);
                    })
                    .catch((error) => {
                      console.error('다운로드 중 에러 발생:', error);
                      openLoading(false);
                    });
                }}
              />
            </TitleBtnBox>
          </SubTitleBtnArea>
          <GContentGrid
            type="none"
            className="ag-theme-alpine"
            style={{ flex: 1, marginBottom: '8px' }}
            isSortable={false}
          >
            <AgGridReact
              defaultColDef={defaultColDef1}
              columnDefs={colDefs1}
              rowData={paymentDetails}
              rowSelection={'single'}
              enableCellTextSelection
              onGridReady={(params) => (gridApiRef.current = params.api)}
              tooltipShowDelay={0}
              noRowsOverlayComponent={EmptyState}
              noRowsOverlayComponentParams={{ type: 'grid' }}
            ></AgGridReact>
          </GContentGrid>
        </>

        <>
          <SubTitleBtnArea>
            <SubTitleBox
              showSubTitle={false}
              content={t('com.label.00617', '__Payment Due(Scheduled) List')}
              isVisibleInfo={true}
              infoContent={t(
                'com.tooltip.pay03',
                `__The following details may vary in amount and payment schedule depending on the registration status of the payment account, offsets, etc., based on the entries recorded in the ERP.`
              )}
              //  ${t(
              //   'com.tooltip.pay02',
              //   '__In the case of foreign currency payments, payment will only be made upon request for transfer by the LG Energy Solutions representative, even if the payment maturity date has arrived'
              // )}
            />
            <TitleBtnBox>
              <GButton
                txt={t('com.label.00666', '__다운로드')}
                chkImg={Download}
                btnstyled="outline"
                btnColor="normal"
                sizes="small"
                hasTxt={true}
                hasImg={true}
                disabled={paymentDue?.length > 0 ? false : true}
                onClick={() => {
                  openLoading(true);
                  getOPaymentDueListExcelDownloadAdApi({
                    ...searchParams,
                    downloadName: t('com.label.00617', '__Payment Due(Scheduled) List'),
                  })
                    .then((res) => {
                      openLoading(false);
                    })
                    .catch((error) => {
                      console.error('다운로드 중 에러 발생:', error);
                      openLoading(false);
                    });
                }}
              />
            </TitleBtnBox>
          </SubTitleBtnArea>
          <GContentGrid
            type="none"
            className="ag-theme-alpine"
            style={{ flex: 1, marginBottom: '8px' }}
            isSortable={false}
          >
            <AgGridReact
              defaultColDef={defaultColDef2}
              columnDefs={colDefs2}
              rowData={paymentDue}
              rowSelection={'single'}
              enableCellTextSelection
              onGridReady={(params) => (gridApiRef.current = params.api)}
              tooltipShowDelay={0}
              noRowsOverlayComponent={EmptyState}
              noRowsOverlayComponentParams={{ type: 'grid' }}
            ></AgGridReact>
          </GContentGrid>
        </>
        {showTab && (
          <>
            <SubTitleBtnArea>
              <SubTitleBox
                showSubTitle={true}
                content={t('com.label.00719', '__Note Payable(Issue) List')}
              />
              <TitleBtnBox>
                <GButton
                  txt={t('com.label.00666', '__다운로드')}
                  chkImg={Download}
                  btnstyled="outline"
                  btnColor="normal"
                  sizes="small"
                  hasTxt={true}
                  hasImg={true}
                  disabled={notePayable?.length > 0 ? false : true}
                  onClick={() => {
                    openLoading(true);
                    getONotePayableExcelDownloadAdApi({
                      ...searchParams,
                      downloadName: t('com.label.00719', '__Note Payable(Issue) List'),
                    })
                      .then((res) => {
                        openLoading(false);
                      })
                      .catch((error) => {
                        console.error('다운로드 중 에러 발생:', error);
                        openLoading(false);
                      });
                  }}
                />
              </TitleBtnBox>
            </SubTitleBtnArea>
            <GContentGrid
              type="none"
              className="ag-theme-alpine"
              style={{ flex: 1, marginBottom: '12px' }}
              isSortable={false}
              scrollHeight="48px"
            >
              <AgGridReact
                defaultColDef={defaultColDef3}
                columnDefs={colDefs3}
                rowData={notePayable}
                rowSelection={'single'}
                enableCellTextSelection
                onGridReady={(params) => (gridApiRef.current = params.api)}
                tooltipShowDelay={0}
                noRowsOverlayComponent={EmptyState}
                noRowsOverlayComponentParams={{ type: 'grid' }}
                onCellClicked={(e: CellClickedEvent) => {
                  setDetailReq({
                    accnNo: e.data.accnNo,
                    slipNo: e.data.slipNo,
                    payBankCd: e.data.payBankCd,
                    payDt: e.data.payDt,
                    userId: searchParams.userId,
                    downloadName: '',
                    copCd: searchParams.copCd,
                  });
                }}
              ></AgGridReact>
            </GContentGrid>
            <SubTitleBtnArea>
              <SubTitleBox
                showSubTitle={true}
                content={t('com.label.00619', '__Note Payable(Issue) Detail')}
              />
              <TitleBtnBox>
                <GButton
                  txt={t('com.label.00666', '__다운로드')}
                  chkImg={Download}
                  btnstyled="outline"
                  btnColor="normal"
                  sizes="small"
                  hasTxt={true}
                  hasImg={true}
                  disabled={notePayableDetail?.length > 0 ? false : true}
                  onClick={() => {
                    openLoading(true);
                    getONotePayableDetailsExcelDownloadAdApi({
                      ...detailReq,
                      downloadName: t('com.label.00619', '__Note Payable(Issue) Detail'),
                    })
                      .then((res) => {
                        openLoading(false);
                      })
                      .catch((error) => {
                        console.error('다운로드 중 에러 발생:', error);
                        openLoading(false);
                      });
                  }}
                />
              </TitleBtnBox>
            </SubTitleBtnArea>
            <GContentGrid
              type="none"
              className="ag-theme-alpine"
              style={{ flex: 1, marginBottom: '8px' }}
              isSortable={false}
            >
              <AgGridReact
                defaultColDef={defaultColDef4}
                columnDefs={colDefs4}
                rowData={notePayableDetail}
                rowSelection={'single'}
                enableCellTextSelection
                onGridReady={(params) => (gridApiRef.current = params.api)}
                tooltipShowDelay={0}
                noRowsOverlayComponent={EmptyState}
                noRowsOverlayComponentParams={{ type: 'grid' }}
              ></AgGridReact>
            </GContentGrid>
          </>
        )}
      </GTabs>
    </>
  );
};

export default OPaymentSchedulePage;
