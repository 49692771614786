import { SuccessOrNot } from 'models/common/RestApi';
import { LanguageCode } from 'models/common/Session';

export enum CommonAccessCode {
  LOCAL = 'LOCAL',
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
}

export interface AccessInfo {
  clientLocale: string;
  accessCode: CommonAccessCode;
  langCd: LanguageCode;
}

export interface CommonJwtResponse<T = any> {
  successOrNot: SuccessOrNot;
  statusCode: string;
  textMessage?: string;
  errorCode?: string;
  data?: T;
}
