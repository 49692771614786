import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { QnaDetailRes, QnaReplyRegist } from 'pages/vars/qna/QnaDetailPage';
import { QnaSearchParams, QnaSearchRes } from 'pages/vars/qna/QnaListPage';
import { QnaRegist } from 'pages/vars/qna/QnaRegistPage';
import { callJwtApi } from 'utils/JwtApiUtil';

// Qna 목록 조회(USER)
export const getUserQnaListApi = async (qnaSearchParams: QnaSearchParams) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/api/sec/bbs/qnas?pageSize=${qnaSearchParams.pageSize}&start=${qnaSearchParams.start}`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
  };
  const response: CommonResponse<QnaSearchRes> = await callJwtApi(request);
  return (response.successOrNot === 'Y' ? response.data : []) as QnaSearchRes;
};

// Qna 상세조회(USER)
export const getUserQnaDetailApi = async (bbmNo: number, uptVwct: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/api/sec/bbs/qna/${bbmNo}?uptVwct=${uptVwct}`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
  };
  const response: CommonResponse<QnaDetailRes> = await callJwtApi(request);
  return (response.successOrNot === 'Y' ? response.data : []) as QnaDetailRes;
};

// Qna 등록(USER)
export const postUserQnaContentApi = async (searchParams: QnaRegist) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/api/sec/bbs/qna`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    bodyParams: searchParams,
  };
  const response: CommonResponse = await callJwtApi(request);
  return response.successOrNot === 'Y' ? true : false;
};

// Qna 수정(USER)
export const editUserQnaContentApi = async (qnaEdit) => {
  const request: CommonRequest = {
    method: Method.PUT,
    url: `/api/sec/bbs/qna/${qnaEdit.bbmNo}`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    bodyParams: qnaEdit,
  };
  const response: CommonResponse = await callJwtApi(request);
  return response.successOrNot === 'Y' ? true : false;
};

// Qna 삭제(USER)
export const deleteUserQnaApi = async (bbmNo: number) => {
  const request: CommonRequest = {
    method: Method.DELETE,
    url: `/api/sec/bbs/qna?bbmNo=${bbmNo}`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
  };
  const response: CommonResponse = await callJwtApi(request);
  return response.successOrNot === 'Y' ? true : false;
};

// Qna 댓글 등록(사용자)
export const postUserQnaReplyApi = async (searchParams: QnaReplyRegist) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/api/sec/bbs/qna/reply`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    bodyParams: searchParams,
  };
  const response: CommonResponse = await callJwtApi(request);
  return response.successOrNot === 'Y' ? true : false;
};

// Qna 댓글 수정(사용자)
export const editUserQnaReplyApi = async (qnaReplyEdit) => {
  const request: CommonRequest = {
    method: Method.PUT,
    url: `/api/sec/bbs/qna/reply/${qnaReplyEdit.bbmReNo}`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    bodyParams: qnaReplyEdit,
  };
  const response: CommonResponse = await callJwtApi(request);
  return response.successOrNot === 'Y' ? true : false;
};

// Qna 댓글 삭제(사용자)
export const deleteUserQnaReplyApi = async (bbmNo: number, bbmReNo: number) => {
  const request: CommonRequest = {
    method: Method.DELETE,
    url: `/api/sec/bbs/qna/reply?bbmNo=${bbmNo}&bbmReNo=${bbmReNo}`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
  };
  const response: CommonResponse = await callJwtApi(request);
  return response.successOrNot === 'Y' ? true : false;
};
