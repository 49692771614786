import styled from '@emotion/styled';
import { GlobalTokenColor } from 'ui/theme/Color';
// import styled from 'styled-components';

export const PrivacyAgreeEn = () => {
  return (
    <>
      <PrivacyDiv>
        <p>
          LG Energy Solution, Ltd. (hereinafter referred to as “The Company”, “we”, or “us”
          interchangeably) cares about your privacy.
          <br />
          This privacy policy will explain how our organization uses the personal data we collect
          from you when you use our “Web site” (hereinafter referred to as “Services”
          interchangeably) supported LG Energy solution products.
          <br />
          The protection of your privacy when processing personal data is important to us. We
          therefore process your personal data in compliance with the relevant data protection
          regulations.
        </p>
        <PrivacyTable>
          <thead>
            <tr>
              <th>Category</th>
              <th>Purpose</th>
              <th colSpan={2}>Items for collection</th>
              <th>Retention and Usage Period</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Member management</td>
              <td>Processing membership and withdrawal for system use</td>
              <th>Required items</th>
              <td>
                Password, address (in the case of a corporation, Company address), Country where the
                company is located
                <br />
                <br />□ Customer representatives only : Name, email, company name{' '}
              </td>
              <td rowSpan={2} style={{ color: 'blue', fontWeight: 'bold' }}>
                Until the conclusion of the continuous business relationship with the Company or
                until a separate deletion request is made
              </td>
            </tr>
            <tr>
              <td>Personal Vendor</td>
              <td>Account authentication</td>
              <th>Required items</th>
              <td>Name of bank, name of depositor, account number, date of birth</td>
            </tr>
          </tbody>
        </PrivacyTable>
      </PrivacyDiv>
    </>
  );
};
const PrivacyDiv = styled.div`
  box-sizing: border-box;
  // border: 1px solid #ebeeed;
  border: 1px solid ${GlobalTokenColor.ESGrey300};
  width: 100%;
  height: 270px;
  overflow-y: auto;
  color: #1f1f1f;
  font-size: 13px;
  line-height: 20px;
  padding: 15px;
`;

const PrivacyTable = styled.table`
  margin: 10px;

  th,
  td {
    padding: 8px;
    border: 1px solid #ebeeed;
  }

  th {
    background: #f1f4f3;
    font-weight: 600;
  }

  td {
    vertical-align: top;
  }
`;
export default PrivacyAgreeEn;
