import styled from '@emotion/styled';
import { useCommonModal } from 'hooks/useCommonModal';
import { createContext, useRef, useState, useEffect } from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { GButtonBox, GPopupTitle } from 'components/layouts/GLayoutBox';
import { DialogTitleItem } from 'components/layouts/GModalPopup';
import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import { GButton } from 'components/atom/button';
import Save from 'components/asset/images/Confirm.svg';
import {
  GButtonBoxCum,
  SearchItemWrap,
  SearchWrap,
  UnHiddenSearchWrap,
} from 'components/molecule/SearchMolecule';
import GButtonLayout from 'components/molecule/GbuttonLayout';
import Search from 'components/asset/images/Search.svg';
import SubTitleBox from 'components/molecule/SubTitleBox';
import RightChevron from 'components/asset/images/RightChevron.svg';
import LeftChevron from 'components/asset/images/LeftChevron.svg';
import { GContentGrid } from 'components/layouts/GContentGrid';
import { useLoading } from 'components/process/Loading';
import { useMessageBar } from 'components/process/MessageBar';
import ModalLayout from 'components/layouts/ModalLayout';
import { useSelectedManagerColumnDefs } from './hooks/SelectedManagerColumnDef';
import { useManagerSearchColumnDefs } from './hooks/ManagerSearchColumnDefs';
import CSearchMolecule from 'components/vars/common/CSearchMolecule';
import { getAdminManagerListApi, getUserManagerListApi } from 'apis/common/ManagerSearchApi';
import { Employee } from 'models/admin/Employee';
import useAuthStore from 'stores/useAuthStore';
import EmptyState from 'components/atom/emptyState';

type SearchModalProps = {
  open: boolean;
  singleSelect?: boolean;
  close: () => void;
  save: () => void;
  id?: string;
};

const ManagerSearchModal = (props: SearchModalProps) => {
  const { t } = useTranslation();
  const t2 = t as (msgId: string, defVal: string) => string;

  const { openLoading } = useLoading();
  const { openMessageBar } = useMessageBar();
  const { openCommonModal } = useCommonModal();
  const gridRef = useRef<AgGridReact<Employee>>(null);
  const selectedGridRef = useRef<any>(null);
  const { accessToken } = useAuthStore();

  const [managerData, setManagerData] = useState<Employee[]>([]);
  const [selectedManagerData, setSelectedManagerData] = useState<Employee[]>([]);
  const { defaultColDef, colDefs } = useManagerSearchColumnDefs();
  const { sDefaultColDef, sColDefs } = useSelectedManagerColumnDefs();
  const [inputValue, setInputValue] = useState<string>('');

  const handleError = (e: Error) => {
    openLoading(false);
    openMessageBar('error', e.message);
  };

  const getManagerList = async () => {
    // 회원가입 시 일반인user API 탐
    if (props.id || accessToken) {
      getUserManagerListApi(inputValue)
        .then((res) => {
          setManagerData(res);
        })
        .catch(handleError);
    } else {
      getAdminManagerListApi(inputValue)
        .then((res) => {
          setManagerData(res);
        })
        .catch(handleError);
    }
  };

  useEffect(() => {
    if (props.open) {
      getManagerList();
    }
  }, [props.open]);

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSearch = () => {
    getManagerList();
  };

  const handleAdd = () => {
    const selectedRows = gridRef.current?.api.getSelectedRows();

    if (selectedRows && selectedRows.length > 0) {
      const selectedUserList = selectedRows.map((row) => row.userId);
      const filteredManagerData = managerData.filter((manager) =>
        selectedUserList.includes(manager.userId)
      );

      // 이미 추가된 데이터 중복제거
      setSelectedManagerData((prev) => {
        const newManagerData = filteredManagerData.filter(
          (newManager) =>
            !prev.some((existingManager) => existingManager.userId === newManager.userId)
        );
        return [...prev, ...newManagerData];
      });
    }
  };

  const handleRemove = () => {
    const selectedRows = selectedGridRef.current?.api.getSelectedRows();

    if (selectedRows && selectedRows.length > 0) {
      const selectedUserList = selectedRows.map((row) => row.userId);
      setSelectedManagerData((prev) =>
        prev.filter((item) => !selectedUserList.includes(item.userId))
      );
    }
  };

  const handleClose = () => {
    setInputValue('');
    setSelectedManagerData([]);
    props.close();
  };

  const handleOnSave = () => {
    const selectedRows = selectedGridRef.current?.api.getSelectedRows() || [];
    console.log('저장 ::: ', selectedRows);

    if (selectedRows.length === 0) {
      openCommonModal({
        modalType: 'alert',
        content: t('com.msg.noSelRow', '__선택된 행이 없습니다.'),
      });
      return;
    }
    // props.close();
  };

  return (
    <ModalLayout open={props.open} onClose={handleClose} mMinWidth={1200} mMaxWidth={1200}>
      <DialogTitleItem id="customized-dialog-title" onClose={handleClose}>
        <GPopupTitle title2={t('com.label.mngSrch', '__담당자 검색')} />
      </DialogTitleItem>
      <DialogContent className="popupContent">
        <SearchWrap>
          <UnHiddenSearchWrap>
            <SearchItemWrap>
              <CSearchMolecule
                type="input"
                inputStatus="search"
                labelTitle={t('com.label.mngEml', '담당자 이메일')}
                placeholder={t2('com.label.00496', '__담당자 이메일을 입력하세요')}
                searchWidth="100%"
                searchValueWidth="240px"
                onChange={handleChange}
              />
            </SearchItemWrap>
            <GButtonLayout paddingTop="0px" marginBottom="0px">
              <GButtonBoxCum>
                <GButton
                  chkImg={Search}
                  txt={t('com.label.00116', '__조회')}
                  sizes="medium"
                  btnstyled="contained"
                  btnColor="normal"
                  hasImg={true}
                  hasTxt={true}
                  onClick={handleSearch}
                ></GButton>
              </GButtonBoxCum>
            </GButtonLayout>
          </UnHiddenSearchWrap>
        </SearchWrap>
        <Content>
          <GridContent>
            <SubTitleBox
              showSubTitle={true}
              content={t('com.label.00575', '__전체 목록')}
              isVisbleSum={true}
              commentCount={managerData.length}
            />
            <GContentGrid
              type="check"
              isSortable={false}
              className="ag-theme-alpine"
              scrollHeight="26px"
              style={{ marginTop: '4px', flex: 1 }}
            >
              <AgGridReact
                ref={gridRef}
                defaultColDef={defaultColDef}
                columnDefs={colDefs}
                rowData={managerData}
                rowSelection={'multiple'}
                stopEditingWhenCellsLoseFocus={true}
                suppressRowClickSelection={true}
                suppressMovableColumns
                enableCellTextSelection
                // onGridReady={(params) => (gridRef.current = params.api)}
                tooltipShowDelay={0}
                tooltipHideDelay={2000}
                noRowsOverlayComponent={EmptyState}
                noRowsOverlayComponentParams={{ type: 'grid' }}
              ></AgGridReact>
            </GContentGrid>
          </GridContent>
          <ButtonBox>
            <GButton
              chkImg={RightChevron}
              sizes="large"
              btnstyled="outline"
              btnColor="normal"
              hasImg={true}
              hasTxt={false}
              onClick={handleAdd}
            />
            <GButton
              chkImg={LeftChevron}
              sizes="large"
              btnstyled="outline"
              btnColor="normal"
              hasImg={true}
              hasTxt={false}
              onClick={handleRemove}
            />
          </ButtonBox>
          <GridContent>
            <SubTitleBox
              showSubTitle={true}
              content={t('com.label.00721', '__선택한 목록')}
              isVisbleSum={true}
              commentCount={selectedManagerData.length}
            />
            <GContentGrid
              type="check"
              isSortable={false}
              className="ag-theme-alpine"
              scrollHeight="26px"
              style={{ marginTop: '4px', flex: 1 }}
            >
              <AgGridReact
                ref={selectedGridRef}
                defaultColDef={sDefaultColDef}
                columnDefs={sColDefs}
                rowData={selectedManagerData}
                rowSelection={'multiple'}
                stopEditingWhenCellsLoseFocus={true}
                suppressRowClickSelection={true}
                suppressMovableColumns
                enableCellTextSelection
                // onGridReady={(params) => (selectedGridRef.current = params.api)}
                tooltipShowDelay={0}
                tooltipHideDelay={2000}
                noRowsOverlayComponent={EmptyState}
                noRowsOverlayComponentParams={{ type: 'grid' }}
              ></AgGridReact>
            </GContentGrid>
          </GridContent>
        </Content>
      </DialogContent>
      <DialogActions className="popupBottom">
        <GButtonBox>
          <GButton
            txt={t('com.label.00036', '취소')}
            sizes="medium"
            onClick={handleClose}
            btnstyled="outline"
            btnColor="normal"
            hasImg={false}
            hasTxt={true}
          />
          <GButton
            txt={t('com.label.00055', '저장')}
            sizes="medium"
            onClick={handleOnSave}
            chkImg={Save}
            btnstyled="contained"
            btnColor="primary"
            hasImg={true}
            hasTxt={true}
          />
        </GButtonBox>
      </DialogActions>
    </ModalLayout>
  );
};

const Content = styled.div`
  display: flex;
  flex: 1;
  gap: 24px;
  height: 60vh;
`;

const GridContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const ButtonBox = styled.div`
  display: flex;
  width: 36px;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
`;

export default ManagerSearchModal;
