import styled from '@emotion/styled';
import Reply from 'components/asset/images/Reply.svg';
import { TextLineHeight, TextSize, TextWeight } from 'ui/theme/CustomTypography';
import { GlobalTokenColor } from 'ui/theme/Color';

interface ReplyCountProps {
  count: number;
}

const addCommas = (num: number): string => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const ReplyCount = (props: ReplyCountProps) => {
  const formattedCount = addCommas(props.count);

  return (
    <ReplyCountBox>
      <img src={Reply} />
      <Count>{formattedCount}</Count>
    </ReplyCountBox>
  );
};

const ReplyCountBox = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
`;
const Count = styled.span`
  width: auto;
  color: ${GlobalTokenColor.ESGrey900};
  font-family: Spoqa Han Sans Neo;
  font-size: ${TextSize.Basic};
  font-weight: ${TextWeight.Regualr};
  line-height: ${TextLineHeight.Basic};
`;

export default ReplyCount;
