import { ICellRendererParams } from 'ag-grid-community';
import Tag from 'components/atom/tag';

export interface ITagCellRendererParams extends ICellRendererParams {
  label?: string;
  status?:
    | 'completion'
    | 'ongoing'
    | 'waiting'
    | 'waitingCompletion'
    | 'emergency'
    | 'end'
    | 'possibility';
  customColor?: string;
  customBackgroundColor?: string;
  customBorderColor?: string;
}

export const TagCellRenderer = ({
  label,
  status,
  customColor,
  customBackgroundColor,
  customBorderColor,
  ...params
}: ITagCellRendererParams) => {
  const renderTag = () => {
    return (
      <Tag
        content={label}
        status={status}
        customColor={customColor}
        customBackgroundColor={customBackgroundColor}
        customBorderColor={customBorderColor}
        {...params}
      />
    );
  };

  return <>{renderTag()}</>;
};
