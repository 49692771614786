import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { QnaDetailRes, QnaReplyRegist } from 'pages/vars/qna/QnaDetailPage';
import { QnaSearchParams, QnaSearchRes } from 'pages/vars/qna/QnaListPage';
import { callApi } from 'utils/ApiUtil';

// Qna 리스트 불러오기(관리자)
export const getAdminQnaListApi = async (qnaSearchParams: QnaSearchParams) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/bbs/qnas?pageSize=${qnaSearchParams.pageSize}&start=${qnaSearchParams.start}`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
  };
  const response: CommonResponse<QnaSearchRes> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : []) as QnaSearchRes;
};

// Qna 게시글 리스트 다건 삭제(관리자)
export const deleteAdminQnaListApi = async (bbmNoList: number[]) => {
  const queryString = bbmNoList.map((no) => `bbmNoList=${no}`).join('&');
  const request: CommonRequest = {
    method: Method.DELETE,
    url: `/v1/bbs/qna?${queryString}`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
  };
  const response: CommonResponse = await callApi(request);
  return response.successOrNot === 'Y' ? true : false;
};

// Qna 게시글 상세조회(관리자)
export const getAdminQnaDetailApi = async (bbmNo: number, uptVwct: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/bbs/qna/${bbmNo}?uptVwct=${uptVwct}`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
  };
  const response: CommonResponse<QnaDetailRes> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : []) as QnaDetailRes;
};

// Qna 댓글 등록(관리자)
export const postAdminQnaReplyApi = async (searchParams: QnaReplyRegist) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/bbs/qna/reply`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    bodyParams: searchParams,
  };
  const response: CommonResponse = await callApi(request);
  return response.successOrNot === 'Y' ? true : false;
};

// Qna 댓글 수정(관리자)
export const editAdminQnaReplyApi = async (qnaReplyEdit) => {
  const request: CommonRequest = {
    method: Method.PUT,
    url: `/v1/bbs/qna/reply/${qnaReplyEdit.bbmReNo}`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    bodyParams: qnaReplyEdit,
  };
  const response: CommonResponse = await callApi(request);
  return response.successOrNot === 'Y' ? true : false;
};

// Qna 댓글 삭제(관리자)
export const deleteAdminQnaReplyApi = async (bbmNo: number, bbmReNo: number) => {
  const request: CommonRequest = {
    method: Method.DELETE,
    url: `/v1/bbs/qna/reply?bbmNo=${bbmNo}&bbmReNo=${bbmReNo}`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
  };
  const response: CommonResponse = await callApi(request);
  return response.successOrNot === 'Y' ? true : false;
};
