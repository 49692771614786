import React, { useEffect } from 'react';
/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import AccordionArrow from 'components/asset/images/accordionArrow.svg';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { GButtonBox } from 'components/molecule/GbuttonLayout';
import { GButton } from 'components/atom/button';
import { SubTitleBtnArea } from 'components/organism/SubTitleBtnArea';
import SubTitleBox from 'components/molecule/SubTitleBox';
import Download from 'components/asset/images/Download.svg';
import FileDownload from 'components/molecule/FileDownload';

interface ContentProps {
  title: string;
  contents: string;
}

export default function ContentAccordion(props: ContentProps) {
  const [expanded, setExpanded] = React.useState<string | false>('panel');

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    setExpanded(false);
  }, []);

  return (
    <>
      <Accordion expanded={expanded === 'panel'} onChange={handleChange('panel')}>
        <AccordionSummary aria-controls="paneld-content" id="paneld-header">
          <AccordionTitle expanded={expanded === 'panel'}>{props.title}</AccordionTitle>
          <GButtonBox>
            <GButton
              txt="수정"
              sizes="small"
              btnstyled="outline"
              btnColor="normal"
              hasImg={false}
              hasTxt={true}
            ></GButton>
            <GButton
              txt="삭제"
              sizes="small"
              btnstyled="outline"
              btnColor="normal"
              hasImg={false}
              hasTxt={true}
            ></GButton>
          </GButtonBox>
        </AccordionSummary>
        <AccordionDetails>
          <AccordionText>{props.contents}</AccordionText>
          <FileBox>
            <SubTitleBtnArea>
              <SubTitleBox
                showSubTitle={true}
                content="첨부파일"
                isVisbleSum={true}
                commentCount={3}
              />
              <GButton
                chkImg={Download}
                txt="전체 다운로드"
                sizes="small"
                btnstyled="outline"
                btnColor="normal"
                hasImg={true}
                hasTxt={true}
              ></GButton>
            </SubTitleBtnArea>
            <FileDownload atchFileGrId="test" />
            <FileDownload atchFileGrId="test" />
            <FileDownload atchFileGrId="test" />
          </FileBox>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderTop: '1px solid #dde0df',
  '&.MuiAccordion-root::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<img src={AccordionArrow} alt="Expand Icon" />} {...props} />
))(({ theme }) => ({
  padding: '0px 8px',
  backgroundColor: '#fff',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'scaleY(-1)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: '0 0 24px 0',
  border: 'none',
  '&{AccordionTitle}': {
    color: 'var(--Common-Text-Basic, #1F1F1F)',
    fontFeatureSettings: 'clig off, liga off',

    fontFamily: 'Spoqa Han Sans Neo',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '150%',
  },
}));

const AccordionTitle = styled.p<{ expanded: boolean }>`
  color: ${(props) => (props.expanded ? '#1f1f1f' : '#5b5c5b')};
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Spoqa Han Sans Neo;
  font-size: 16px;
  font-style: normal;
  font-weight: ${(props) => (props.expanded ? '700' : '400')};
  line-height: 150%;
`;

const AccordionText = styled.div`
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  border-radius: 2px;
  background: #f7f9f8;

  color: #1f1f1f;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Spoqa Han Sans Neo;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

const FileBox = styled.div`
  margin-top: 12px;
  padding: 12px;
  border: 1px solid #dde0df;
  background: #f7f9f8;
`;
