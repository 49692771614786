import styled from '@emotion/styled';
import ChipSelectBox from '../chipSelect';
import DarkSearch from 'components/asset/images/DarkSearch.svg';
import { Chip } from '@mui/material';
import avatarUser from 'components/asset/images/avatarUserChip.svg';
import { GlobalTokenColor } from 'ui/theme/Color';

interface SingleChipSelectProps {
  width?: string;
  height?: string;
  status?: 'default' | 'error' | 'warning' | 'confirm';
  format?: string;
  isChip?: boolean;
  chipType?: 'default' | 'user';
  chipImg?: string;
  disabled?: boolean;
  onClickFunction?: () => void;
  isRequired?: boolean;
  helperText?: string;
}

export default function SingleChipSelect({
  width = '100%',
  height,
  status = 'default',
  format = '',
  isChip = false,
  chipType = 'default',
  chipImg = `${avatarUser}`,
  disabled = false,
  isRequired,
  helperText,
  onClickFunction,
}: SingleChipSelectProps) {
  const handleDelete = () => {
    console.info('You clicked the delete icon.');
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: `${width}`,
        height: `${height}`,
      }}
    >
      <Container>
        <Disabled width={width} height={height}>
          <ChipSelectBox
            width={width}
            height={height}
            status={status}
            chipType={chipType}
            chipImg={chipImg}
            disabled={disabled}
            isRequired={isRequired}
          />
        </Disabled>
        {isChip ? (
          <>
            {chipType === 'default' ? (
              <ChipDefault disabled={disabled} label="김관우" onDelete={handleDelete} />
            ) : (
              <ChipUser label="김관우" onDelete={handleDelete} />
            )}
          </>
        ) : (
          <Placeholder>{format}</Placeholder>
        )}
        <SearchButton disabled={disabled} onClick={onClickFunction}></SearchButton>
      </Container>
      {helperText && <HelperText status={status}>{helperText}</HelperText>}
    </div>
  );
}

const Container = styled.div<SingleChipSelectProps>`
  position: relative;
`;

const Disabled = styled.div<SingleChipSelectProps>`
  position: relative;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  pointer-events: none;
  &:before {
    z-index: 10;
    ${(props) => (props.isRequired ? `content : ""` : ``)};
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: ${GlobalTokenColor.StatusError500};
    position: absolute;
    left: 2px;
    top: 2px;
  }
`;

const ChipCustom = styled(Chip)`
  z-index: 100;
  position: absolute;
  top: 50%;
  left: 28px;
  transform: translate(0, -50%);
  height: 24px;

  svg {
    width: 16px;
    margin: 0 4px 0 -12px !important;
    color: #979998 !important;
    ${(props) => props.disabled && `width: 0; height: 0;`};
  }

  span {
    position: relative;
    color: #5b5c5d;
    font-size: 12px;
    font-weight: 400;
    line-height: 150%;
  }
  .MuiAutocomplete-root .MuiAutocomplete-tag {
    margin: 0;
    max-width: none;
    color: #1f1f1f !important;
  }
`;

const ChipDefault = styled(ChipCustom)`
  span {
    padding: 0 16px 0 8px;
    ${(props) => props.disabled && `padding: 0 16px 0 8px;`};
  }
`;

const ChipUser = styled(ChipCustom)`
  span {
    padding: 0 16px 0 28px;
    &::after {
      content: url('${avatarUser}');
      position: absolute;
      top: 50%;
      left: 4px;
      transform: translate(0, -50%);
      background-color: #554596;
      border: 1px solid #554596;
      border-radius: 50%;
      width: 20px;
      height: 20px;
    }
  }
`;

const Placeholder = styled.p`
  z-index: 100;
  position: absolute;
  top: 50%;
  left: 28px;
  transform: translate(0, -50%);
  color: #979998;
  font-size: 13px;
  font-weight: 400;
  line-height: 150%;
`;

const SearchButton = styled.button`
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translate(0, -50%);
  &::after {
    content: url('${DarkSearch}');
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    width: 16px;
    height: 16px;
    /* ${(props) => props.disabled && `filter: invert(); cursor: none;`}; */
  }
`;

const HelperText = styled.span<SingleChipSelectProps>`
  font-family: Spoqa Han Sans Neo;
  font-size: 12px;
  line-height: 150%;
  color: #979998;

  margin-top: 4px;
  width: auto;
  height: auto;

  ${(props) =>
    props.status === 'error' &&
    `
    color: #f94b50;
    `}

  ${(props) =>
    props.status === 'warning' &&
    `
    color: #ff9322;
    `}
    
    ${(props) =>
    props.status === 'confirm' &&
    `
    color: #00806a;
  `}
`;
