/*
  AssetRegister.tsx의 단위 항목 콤보박스 보고 만들었습니다.

  사용 방법
  <GCmnCodeSearchSelect
    //style={{ width: '100%' }}
    cmnCd={'ASST_QTY_UNIT_CD'}
    id={'asstQtyUnitCd'}
    value={basicFormData.asstQtyUnitCd}  => 컨트롤 할 value
    onChange={handleChangeForBasicForm}  => onChange 이벤트 e 항목에 e.target.id, e.target.value만 들어있습니다.
    selectFirstItem  => 옵션 리스트 중 첫번째 값을 default로 가지도록 할 때 prop
    placeholder="단위를 선택해주세요"  => 콤보박스의 placeholder
  />

*/
import Box from '@mui/material/Box';

import React, { useCallback, useState, useRef } from 'react';
import { GGuideTitle } from '../../components/layouts/GLayoutBox';
import { GSearchSelect } from '../../components/selects/GSearchSelect';
import GCmnCodeSearchSelect from 'components/selects/GCmnCodeSearchSelect';

type BasicFormData = {
  dmstYn: string;
  coCd: string;
  coNm: string;
  cctrCd: string;
  cctrNm: string;
  cmnCctrYn: string;
  bplcCd: string;
  bplcNm: string;
  bizAreaCd: string;
  bizAreaNm: string;
  asstClsCd: string;
  asstClsNm: string;
  asstNo: string;
  asstNm: string;
  asstQty: number;
  asstQtyUnitCd: string;
  asstDesc: string;
  asstLocNm: string;
  bldgNm: string;
  bldgFloNm: string;
  ocopAsstLocNm: string;
  mgtDeptCd: string;
  mgtDeptNm: string;
  prjCd: string;
  prjNm: string;
  asstCrgeEmpNo: string;
  asstCrgeEmpNm: string;
  dtalProcCd: string;
  deptTpCd: string;
  mdlNm: string;
  atchFileGrId: string;
};

const defaultValuesForBasicForm: BasicFormData = {
  dmstYn: '',
  coCd: '',
  coNm: '',
  cctrCd: '',
  cctrNm: '',
  bplcCd: '',
  bplcNm: '',
  bizAreaCd: '',
  bizAreaNm: '',
  cmnCctrYn: '',
  asstClsCd: '',
  asstClsNm: '',
  asstNo: '',
  asstNm: '',
  asstQty: 0,
  asstQtyUnitCd: '',
  asstDesc: '',
  asstLocNm: '',
  bldgNm: '',
  bldgFloNm: '',
  ocopAsstLocNm: '',
  mgtDeptCd: '',
  mgtDeptNm: '',
  prjCd: '',
  prjNm: '',
  asstCrgeEmpNo: '',
  asstCrgeEmpNm: '',
  dtalProcCd: '',
  deptTpCd: '',
  mdlNm: '',
  atchFileGrId: '',
};

const SampleSearchSelectComponent = () => {
  const [basicFormData, setBasicFormData] = useState<BasicFormData>(defaultValuesForBasicForm);
  const [basicFormData2, setBasicFormData2] = useState<BasicFormData>(defaultValuesForBasicForm);

  const handleChangeForBasicForm = (e) => {
    console.log(basicFormData);
    let extraDataToUpdate = {};
    if (e.target.id === 'asstLocNm') {
      extraDataToUpdate = { bldgNm: '', bldgFloNm: '', ocopAsstLocNm: '' };
    } else if (e.target.id === 'bldgNm') {
      extraDataToUpdate = { bldgFloNm: '', ocopAsstLocNm: '' };
    }

    setBasicFormData((prev) => {
      return { ...prev, [e.target.id]: e.target.value, ...extraDataToUpdate };
    });
  };
  const handleChangeForBasicForm2 = (e) => {
    console.log(basicFormData);
    let extraDataToUpdate = {};
    if (e.target.id === 'asstLocNm') {
      extraDataToUpdate = { bldgNm: '', bldgFloNm: '', ocopAsstLocNm: '' };
    } else if (e.target.id === 'bldgNm') {
      extraDataToUpdate = { bldgFloNm: '', ocopAsstLocNm: '' };
    }

    setBasicFormData2((prev) => {
      return { ...prev, [e.target.id]: e.target.value, ...extraDataToUpdate };
    });
  };

  return (
    <Box>
      <GGuideTitle>이광호 샘플</GGuideTitle>

      <GGuideTitle>검색 필터 콤보박스 width 50%</GGuideTitle>
      <GSearchSelect placeholder="testPlaceholder" style={{ width: '50%' }} />
      <GGuideTitle>검색 필터 콤보박스 readonly</GGuideTitle>
      <GSearchSelect isSelectReadonly={true} />
      <GGuideTitle>검색 필터 콤보박스 disabled</GGuideTitle>
      <GSearchSelect isSelectDisabled={true} />
      <GGuideTitle>검색 필터 콤보박스 인풋 박스 키인 제거</GGuideTitle>
      <GSearchSelect isInputReadOnly={true} />

      <GGuideTitle>검색 필터 콤보박스 공통 컴포넌트</GGuideTitle>
      <GGuideTitle>검색 필터 콤보박스 width 100%</GGuideTitle>
      <GCmnCodeSearchSelect
        style={{ width: '100%' }}
        cmnCd={'ASST_QTY_UNIT_CD'}
        id={'asstQtyUnitCd'}
        value={basicFormData.asstQtyUnitCd}
        onChange={handleChangeForBasicForm}
        selectFirstItem
        placeholder="단위를 선택해주세요"
      />
      <GGuideTitle>검색 필터 콤보박스 readonly width 50%</GGuideTitle>
      <GCmnCodeSearchSelect
        style={{ width: '50%' }}
        isSelectReadonly={true}
        cmnCd={'ASST_QTY_UNIT_CD'}
        id={'asstQtyUnitCd'}
        value={basicFormData.asstQtyUnitCd}
        onChange={handleChangeForBasicForm}
        selectFirstItem
        placeholder="단위를 선택해주세요"
      />
      <GGuideTitle>검색 필터 콤보박스 disabled width 120px</GGuideTitle>
      <GCmnCodeSearchSelect
        style={{ width: '120px' }}
        isSelectDisabled={true}
        cmnCd={'ASST_QTY_UNIT_CD'}
        id={'asstQtyUnitCd'}
        value={basicFormData.asstQtyUnitCd}
        onChange={handleChangeForBasicForm}
        selectFirstItem
        placeholder="단위를 선택해주세요"
      />
      <GGuideTitle>검색 필터 콤보박스 인풋 박스 키인 제거 width 50%</GGuideTitle>
      <GCmnCodeSearchSelect
        style={{ width: '50%' }}
        isInputReadOnly={true}
        cmnCd={'ASST_QTY_UNIT_CD'}
        id={'asstQtyUnitCd'}
        value={basicFormData.asstQtyUnitCd}
        onChange={handleChangeForBasicForm}
        selectFirstItem
        placeholder="단위를 선택해주세요"
      />

      <GGuideTitle>검색 필터 콤보박스 기본값 width 100%</GGuideTitle>
      <GCmnCodeSearchSelect
        style={{ width: '100%' }}
        isInputReadOnly={true}
        cmnCd={'ASST_QTY_UNIT_CD'}
        id={'asstQtyUnitCd'}
        value={basicFormData2.asstQtyUnitCd}
        onChange={handleChangeForBasicForm2}
        defaultValue="2M"
        placeholder="단위를 선택해주세요"
      />
    </Box>
  );
};

export default SampleSearchSelectComponent;
