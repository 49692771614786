import { Autocomplete, Chip, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { getEmployeeBySearchCondition } from 'apis/admin/Employee';
import { Employee } from 'models/admin/Employee';
import { useEffect, useState } from 'react';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import PersonIcon from '@mui/icons-material/Person';
import GEmployeeModal from 'components/cims/common/GEmployeeModal';
import styled from '@emotion/styled';
import DarkSearch from 'components/asset/images/DarkSearch.svg';
import ErrorIcon from 'components/asset/images/error.svg';
import WarningIcon from 'components/asset/images/warning.svg';
import ConfirmedIcon from 'components/asset/images/confirmed.svg';
import chipUser from 'components/asset/images/chipUser.svg';

interface AutoEmpInputParams {
  disabled?: boolean;
  readonly?: boolean;
  height?: string | '32px';
  status?: 'default' | 'error' | 'warning' | 'confirm';
  type?: '1' | '2';
  chipType?: 'default' | 'user';
  chipImg?: string;

  limitTag?: boolean;

  onInit?: Employee[];
  onChange: (empList: Employee[]) => void;
  handleExistInputValue?: (e: any) => void;
  width?: string | '100%';

  singleSelect?: boolean;
  isInGrid?: boolean;
}

interface InputChipBoxProps {
  readonly;
  disabled;
  chipType?: 'default' | 'user';
  chipImg?: string;

  width?: string | '100%';
  height?: string | '32px';
  status?: 'default' | 'error' | 'warning' | 'confirm';
  type?: '1' | '2';
  isInGrid?: boolean;
}

const AutoEmpInput = ({
  readonly,
  disabled,
  height,
  status = 'default',
  type,
  chipType = 'default',
  chipImg = '${avatarUser}',
  limitTag = false,

  onInit,
  onChange,
  handleExistInputValue,
  width,

  singleSelect = false,
  isInGrid = false,
}: AutoEmpInputParams) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<readonly Employee[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [textValue, setTextValue] = useState<string>('');
  const [focus, setFocus] = useState<boolean>(false);

  const [empList, setEmpList] = useState<Employee[]>([]);

  useEffect(() => {
    if (onInit) {
      setEmpList(onInit);
    }
  }, [onInit]);

  useEffect(() => {
    if (textValue?.length > 1) {
      // 입력이 끝날 때마다 타이머를 재설정
      const timer = setTimeout(() => {
        // 0.7초 후에 실행되는 코드
        setLoading(true);
        setOpen(true);
        getEmployeeBySearchCondition('', '', '', textValue).then((data) => {
          setOptions(data);
          setLoading(false);
        });
        // setOpen(true);
      }, 700);

      // cleanup 함수를 이용하여 타이머 해제
      return () => clearTimeout(timer);
    } else {
      // setOpen(false);
    }
  }, [textValue]);

  const handleClickText = () => {
    if (textValue?.length > 1) {
      setOpen(true);
    }
  };

  const handleClickSearchBtn = (e) => {
    e.stopPropagation();
    setOpenModal(true);
  };

  const renderModal = () => {
    return (
      <GEmployeeModal
        // singleSelect
        open={openModal}
        close={() => {
          setOpenModal(false);
        }}
        save={(employee) => {
          handleChangeEmp(employee, false);
          setOpenModal(false);
        }}
        selectedEmployee={empList}
        singleSelect={singleSelect}
      />
    );
  };

  const handleChangeEmp = (employee: Employee[], isLastSelect) => {
    let selectedEmpList = employee;
    if (singleSelect && employee.length > 1) {
      if (isLastSelect) {
        selectedEmpList = [employee[employee.length - 1]];
      } else {
        selectedEmpList = [employee[0]];
      }
    }

    setEmpList(selectedEmpList);
    onChange(selectedEmpList);
  };

  const handleTextFeildChange = (e) => {
    setTextValue(e.target.value);
    handleExistInputValue && handleExistInputValue(e.target.value);
  };

  return (
    <>
      <Container
        width={width}
        height={height}
        status={status}
        type={type}
        readonly={readonly}
        disabled={disabled}
        chipType={chipType}
        chipImg={chipImg}
        isInGrid={isInGrid}
      >
        <Autocomplete
          limitTags={limitTag ? 1 : 30}
          readOnly={readonly}
          disabled={disabled}
          multiple
          options={options}
          // freeSolo
          filterOptions={(x) => x}
          filterSelectedOptions
          // autoComplete
          // includeInputInList
          noOptionsText="사용자를 찾을 수 없습니다"
          value={empList}
          onInputChange={(e, value, reason) => {
            if (reason === 'clear' || reason === 'reset') {
              setTextValue('');
              setOptions([]);
            }
          }}
          onChange={(event, newValue, reason) => {
            //delete 버튼과 user 넣는 이벤트 모두 이곳에서 통제된다.
            if (reason === 'clear') {
              setTextValue('');
            }
            handleExistInputValue && handleExistInputValue('');

            const allString = newValue.every((value) => typeof value === 'string');

            if (allString) {
              //이 경우는 delete 버튼을 눌러서 Autocomplete에 데이터가 빈 경우 이곳으로 통제된다.
              if (newValue) {
                handleChangeEmp([], false);
              }
            } else {
              //이 경우는 delete 버튼을 눌러도 데이터가 있을 경우, user를 추가한 경우 이곳으로 통제된다.
              handleChangeEmp(newValue as Employee[], true);
            }
          }}
          // open={true}
          onClose={() => {
            setLoading(false);
            // setOpen(false);
          }}
          isOptionEqualToValue={(option, value) => option.userId === value.userId}
          getOptionLabel={(option) => {
            if (typeof option === 'string') {
              return option;
            } else {
              return option.empNm ? option.empNm : '';
            }
          }}
          loading={loading}
          renderTags={(value: readonly Employee[], getTagProps) =>
            value.map((option: Employee, index: number) => (
              <ChipContainer key={option.userId + index}>
                <Chip label={option.empNm} {...getTagProps({ index })} />
              </ChipContainer>
            ))
          }
          renderOption={(props, option, { inputValue }) => {
            const matches = match(option.empNm, inputValue, { insideWords: true });
            const parts = parse(option.userInfo, matches);

            return (
              <li {...props} style={{ padding: '5px 5px 5px 5px' }}>
                <Grid container alignItems="center">
                  <Grid item sx={{ display: 'flex', width: 30 }}>
                    <PersonIcon fontSize="large" />
                  </Grid>
                  <Grid item sx={{ width: 'calc(100% - 35px)', wordWrap: 'break-word' }}>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{
                          fontWeight: part.highlight ? 700 : 400,
                        }}
                      >
                        {part.text}
                      </span>
                    ))}
                    <Typography variant="body2" color="text.secondary">
                      {option.emlSvrDmnIfoNm}
                    </Typography>
                  </Grid>
                </Grid>
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField
              onClick={handleClickText}
              value={textValue}
              onChange={handleTextFeildChange}
              placeholder={empList.length === 0 ? '사용자명을 입력하거나 돋보기를 클릭하세요' : ''}
              {...params}
              InputProps={{
                ...params.InputProps,
                style: {
                  padding: isInGrid ? '1px 30px 1px 28px' : '5px 30px 5px 28px',
                  minHeight: isInGrid ? 'unset' : '32px',
                },
                startAdornment: (
                  <>
                    {/* //TODO 검색 버튼 확인 */}
                    <SearchButton
                      className="searchBtn"
                      onClick={handleClickSearchBtn}
                    ></SearchButton>
                    {params.InputProps.startAdornment}
                  </>
                ),
                endAdornment: (
                  <>
                    {/* //TODO 로딩바 확인 */}
                    {loading ? (
                      <CircularProgress
                        color="primary"
                        size={20}
                        style={{ margin: 'auto', boxSizing: 'content-box' }}
                      />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </Container>
      {openModal && renderModal()}
    </>
  );
};

export default AutoEmpInput;

const SearchButton = styled.div`
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translate(0, -50%);
  &::after {
    content: url('${DarkSearch}');
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    width: 16px;
    height: 16px;
  }
  cursor: pointer;
`;

const ChipContainer = styled.div`
  height: 20px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  & > div {
    width: 100%;
    height: 100%;
    & > span {
      height: 100%;
      padding: 0 16px 0 8px;
      color: #1f1f1f;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 19.5px */
    }
    & > svg {
      width: 14px;
    }
  }

  .MuiButtonBase-root-MuiChip-root {
    height: auto;
  }

  .MuiChip-label {
    overflow: visible;
  }

  .MuiChip-deleteIcon {
    margin-right: 8px;
  }
`;

const Container = styled.div<InputChipBoxProps>`
  z-index: 10;
  position: relative;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  ${(props) => (props.disabled ? `opacity: 0.6; pointer-events: none;` : `opacity: 1;`)};
  ${(props) => (props.readonly ? `pointer-events: none;` : ``)};

  &::after {
    ${(props) => props.status === 'error' && `content: url('${ErrorIcon}');`}
    ${(props) => props.status === 'warning' && `content: url('${WarningIcon}');`}
    ${(props) => props.status === 'confirm' && `content: url('${ConfirmedIcon}');`}
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translate(0, -50%);
    width: 16px;
    height: 16px;
    background-color: #fff;
    cursor: pointer;
  }

  .MuiAutocomplete-root {
    .MuiTextField-root {
      .MuiOutlinedInput-root {
        ${(props) =>
          props.type === '1' ? 'padding: 6px 50px 6px 30px;' : 'padding: 6px 50px 6px 8px;'}
        display: flex;
        gap: 4px 2px;
        ${(props) =>
          props.isInGrid
            ? `background-color: transparent`
            : props.readonly
            ? `background-color: #f1f4f3`
            : `background-color: #fff`};
        .MuiAutocomplete-input {
          min-width: 0;
          height: 20px;
          padding: 0;

          &::placeholder {
            color: #979998;
            opacity: 1;
          }
        }
        .MuiAutocomplete-endAdornment {
          top: 50%;
          transform: translate(0, -50%);
          ${(props) => (props.type === '1' ? `display:flex; flex-direction: row-reverse;` : ``)}
          .MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator {
            width: 14px;
            height: 14px;
            margin-right: 0;
            background-color: #5b5c5b;
            .MuiSvgIcon-root {
              padding: 2px 2px 2px 4px;
              color: #fff;
            }
          }
          .MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator {
            ${(props) => (props.type === '1' ? `width: 0; height: 0; padding: 0;` : ``)}
            .MuiSvgIcon-root {
              ${(props) => (props.type === '1' ? `width: 0;` : ``)}
            }
          }
          .MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator {
            ${(props) => (props.type === '1' ? `width: 0; height: 0; padding: 0;` : ``)}
            .MuiSvgIcon-root {
              ${(props) => (props.type === '1' ? `width: 0;` : ``)}
            }
          }
        }
        .MuiOutlinedInput-notchedOutline {
          top: 0;
          width: 100%;
          padding: 0;
          border-radius: 2px;
          ${(props) => props.isInGrid && `border: none;`}
          ${(props) =>
            !props.isInGrid && props.status === 'default' && `border: 1px solid #b9bcbb;`}
          ${(props) => !props.isInGrid && props.status === 'error' && `border: 1px solid #F94B50;`}
          ${(props) =>
            !props.isInGrid && props.status === 'warning' && `border: 1px solid #FF9322;`}
          ${(props) =>
            !props.isInGrid && props.status === 'confirm' && `border: 1px solid #00806A;`}
        }
      }
    }
  }

  .MuiOutlinedInput-root {
    padding-right: 30px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  /* chip tag */
  .MuiAutocomplete-root .MuiAutocomplete-tag {
    margin: 0;
    max-width: none;
    height: 20px;

    & > span {
      height: 20px;
      color: #1f1f1f;
      font-size: 13px;
      font-weight: 400;
      line-height: 150%;
      ${(props) =>
        props.chipType === 'default' ? `padding: 0 16px 0 8px;` : `padding: 0 16px 0 26px;`}
      position: relative;
      ${(props) =>
        props.chipType === 'user' &&
        `
        &::after {
          content: url('${chipUser}');
          position: absolute;
          top: 50%;
          left: 4px;
          transform: translate(0, -50%);
          width: 16px;
          height: 16px;
        }
        `}
    }
    & > svg {
      width: 14px;
    }
  }

  .MuiAutocomplete-popupIndicator {
    display: none;
  }
`;
