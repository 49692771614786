import { useCommonModal } from 'hooks/useCommonModal';
import { Department } from 'models/admin/Department';
import { useRef, useState } from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { GButtonBox, GPopupTitle } from 'components/layouts/GLayoutBox';
import { DialogTitleItem } from 'components/layouts/GModalPopup';
import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import { GButton } from 'components/atom/button';
import ModalLayout from 'components/layouts/ModalLayout';
import { GbasicTable, GbasicTableWrap } from 'components/tables/GTable';
import { GLabelAtom } from 'components/atom/label';
import { GCodeSelect } from 'components/selects/GCodeSelect';
import InputField from 'components/atom/input';
import { SearchParams } from 'pages/sample/TestPage';
import { CommonCode } from 'models/admin/CommonCode';
import ChipSelectBox from 'components/atom/chipSelect';
import styled from '@emotion/styled';
import FileUploadZone from 'components/molecule/FileUpload';
import Save from 'components/asset/images/Confirm.svg';
import { SubTitleBtnArea } from 'components/organism/SubTitleBtnArea';
import SubTitleBox from 'components/molecule/SubTitleBox';
import GSelectMUIAtom from 'components/atom/select';
import { SelectChangeEvent } from '@mui/material';

type DepartmentModalProps = {
  open: boolean;
  singleSelect?: boolean;
  close: () => void;
  save: () => void;
};

const RegistrationKrwAccount = (props: DepartmentModalProps) => {
  const { t } = useTranslation();
  const gridRef = useRef<AgGridReact<Department>>(null);
  const { openCommonModal } = useCommonModal();

  const handleClose = () => {
    props.close();
  };

  const handleOnSave = () => {
    const selectedRows = gridRef.current?.api.getSelectedRows() || [];
    if (selectedRows.length === 0) {
      openCommonModal({
        modalType: 'alert',
        content: t(
          'department-choice-popup.alert.한 부서 이상 선택해야 합니다.',
          '__선택된 행이 없습니다.'
        ),
      });
      return;
    }
    props.close();
  };

  const dommy: CommonCode[] = [{ cmnCd: 'v1', cmnCdNm: '옵션1' }];
  const [searchParams, setSearchParams] = useState<SearchParams>({
    coCd: '',
  });
  const handleChangeCoCd = (e) => {
    setSearchParams({ coCd: e.target.value });
  };
  const [chipClick, setChipClick] = useState(false);
  const onSearchClick = () => {
    setChipClick((pre) => !pre);
    alert('ex. 모달에서 clickValue 선택');
  };
  const [option, setOption] = useState('1');
  const handleChange = (event: SelectChangeEvent) => {
    setOption(event.target.value);
  };

  return (
    <ModalLayout open={props.open} onClose={handleClose} mMinWidth={1000} mMaxWidth={1000}>
      <DialogTitleItem id="customized-dialog-title" onClose={handleClose}>
        <GPopupTitle title2="계좌정보등록" />
      </DialogTitleItem>
      <DialogContent className="popupContent">
        <SubTitleBtnArea>
          <SubTitleBox showSubTitle={true} content="신규 계좌정보" />
        </SubTitleBtnArea>
        <GbasicTableWrap isBottomMgn={false}>
          <GbasicTable>
            <colgroup>
              <col style={{ width: '160px' }} />
              <col style={{ width: 'auto' }} />
              <col style={{ width: '160px' }} />
              <col style={{ width: 'auto' }} />
            </colgroup>
            <tbody>
              <tr>
                <th>
                  <GLabelAtom align="left" isRequired>
                    계좌종류
                  </GLabelAtom>
                </th>
                <td>
                  <GSelectMUIAtom
                    selectWidth="300px"
                    value={option}
                    handleChange={handleChange}
                    option={['현금계좌', '현금계좌']}
                  />
                </td>
                <th>
                  <GLabelAtom align="left">진행상태</GLabelAtom>
                </th>
                <td></td>
              </tr>
              <tr>
                <th>
                  <GLabelAtom align="left" isRequired={true}>
                    은행
                  </GLabelAtom>
                </th>
                <td colSpan={3}>
                  <ChipSelectBox
                    type="1"
                    width="100%"
                    format="돋보기를 클릭하세요"
                    onClickFunction={onSearchClick}
                  />
                </td>
              </tr>
              <tr>
                <th>
                  <GLabelAtom align="left" isRequired>
                    예금주
                  </GLabelAtom>
                </th>
                <td>
                  <InputField status="default" align="left" width="100%" placeholder="입력하세요" />
                </td>
                <th>
                  <GLabelAtom align="left">추가 예금주</GLabelAtom>
                </th>
                <td>
                  <InputField status="default" align="left" width="100%" placeholder="입력하세요" />
                </td>
              </tr>
              <tr>
                <th>
                  <GLabelAtom align="left" isRequired>
                    계좌번호
                  </GLabelAtom>
                </th>
                <td>
                  <Wrap>
                    <InputField
                      status="default"
                      align="left"
                      width="240px"
                      placeholder="'-'없이 입력하세요"
                    />
                    <GButton
                      txt="인증요청"
                      sizes="medium"
                      btnColor="normal"
                      btnstyled="outline"
                      hasImg={false}
                      hasTxt={true}
                    />
                  </Wrap>
                </td>
                <th>
                  <GLabelAtom align="left" isRequired>
                    통화
                  </GLabelAtom>
                </th>
                <td>
                  <GCodeSelect
                    id={'cmnCd'}
                    labelKey={'cmnCdNm'}
                    emptyLabel="KRW"
                    listData={dommy}
                    value={searchParams.coCd}
                    onChange={handleChangeCoCd}
                    width="100px"
                    readonly={true}
                  />
                </td>
              </tr>
              <tr>
                <th>
                  <GLabelAtom align="left" isRequired={true}>
                    계좌용도
                  </GLabelAtom>
                </th>
                <td colSpan={3}>
                  <InputField status="default" align="left" width="100%" placeholder="입력하세요" />
                </td>
              </tr>
              <tr>
                <th>
                  <GLabelAtom align="left" isRequired>
                    사업자등록증
                  </GLabelAtom>
                </th>
                <td colSpan={3}>
                  <FileUploadZone
                    ref={FileUploadZone}
                    atchFileGrIdInput="9cb7a782-f893-4e6b-b58c-20794684bbc6"
                    isMultipleFile={true}
                    allowFileTypes={['txt', 'jpg', 'png', 'xlsx']}
                    height="40px"
                    width="100%"
                  />
                </td>
              </tr>
              <tr>
                <th>
                  <GLabelAtom align="left" isRequired>
                    통장사본
                  </GLabelAtom>
                </th>
                <td colSpan={3}>
                  <FileUploadZone
                    ref={FileUploadZone}
                    atchFileGrIdInput="9cb7a782-f893-4e6b-b58c-20794684bbc6"
                    isMultipleFile={true}
                    allowFileTypes={['txt', 'jpg', 'png', 'xlsx']}
                    height="40px"
                    width="100%"
                  />
                </td>
              </tr>
              <tr>
                <th>
                  <GLabelAtom align="left" isRequired>
                    어음약정확인서
                  </GLabelAtom>
                </th>
                <td colSpan={3}>
                  <FileUploadZone
                    ref={FileUploadZone}
                    atchFileGrIdInput="9cb7a782-f893-4e6b-b58c-20794684bbc6"
                    isMultipleFile={true}
                    allowFileTypes={['txt', 'jpg', 'png', 'xlsx']}
                    height="40px"
                    width="100%"
                  />
                </td>
              </tr>
              {/* <tr>
                <th>
                  <GLabelAtom align="left">기타1</GLabelAtom>
                </th>
                <td colSpan={3}>
                  <FileUploadZone
                    ref={FileUploadZone}
                    atchFileGrIdInput="9cb7a782-f893-4e6b-b58c-20794684bbc6"
                    isMultipleFile={true}
                    allowFileTypes={['txt', 'jpg', 'png', 'xlsx']}
                    height="40px"
                    width="100%"
                  />
                </td>
              </tr> */}
              <tr>
                <th>
                  <GLabelAtom align="left">기타</GLabelAtom>
                </th>
                <td colSpan={3}>
                  <FileUploadZone
                    ref={FileUploadZone}
                    atchFileGrIdInput="9cb7a782-f893-4e6b-b58c-20794684bbc6"
                    isMultipleFile={true}
                    allowFileTypes={['txt', 'jpg', 'png', 'xlsx']}
                    height="40px"
                    width="100%"
                  />
                </td>
              </tr>
            </tbody>
          </GbasicTable>
        </GbasicTableWrap>
      </DialogContent>
      <DialogActions className="popupBottom">
        <GButtonBox>
          <GButton
            txt="취소"
            sizes="medium"
            onClick={handleClose}
            btnColor="normal"
            btnstyled="outline"
            hasImg={false}
            hasTxt={true}
          />
          <GButton
            txt="임시저장"
            sizes="medium"
            btnColor="normal"
            btnstyled="outline"
            hasImg={false}
            hasTxt={true}
          />
          <GButton
            txt="저장"
            chkImg={Save}
            sizes="medium"
            onClick={handleOnSave}
            btnstyled="contained"
            btnColor="primary"
            hasImg={true}
            hasTxt={true}
          ></GButton>
        </GButtonBox>
      </DialogActions>
    </ModalLayout>
  );
};

const Wrap = styled.div`
  display: flex;
  gap: 4px;
`;

export default RegistrationKrwAccount;
