import styled from '@emotion/styled';
import EyeCount from 'components/atom/eyeCount';
import ReplyCount from 'components/atom/replyCount';
import { GButton } from 'components/atom/button';
import { GButtonBox } from 'components/layouts/GLayoutBox';
import SubTitleBox from 'components/molecule/SubTitleBox';
import GButtonLayout from 'components/molecule/GbuttonLayout';
import New from 'components/asset/images/Pen.svg';
import List from 'components/asset/images/List.svg';
import Delete from 'components/asset/images/Delete.svg';
import Download from 'components/asset/images/Download.svg';
import ProfileImg from 'components/asset/images/userIcon.svg';
import ReplyArrow from 'components/asset/images/ReplyArrow.svg';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { useCommonModal } from 'hooks/useCommonModal';
import { useLoading } from 'components/process/Loading';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { SubTitleBtnArea } from 'components/organism/SubTitleBtnArea';
import useAuthStore from 'stores/useAuthStore';
import DateInfo from 'components/atom/dateInfo';
import useSessionStore from 'stores/useSessionStore';
import EditProfile from 'components/vars/qna/ProfileQna';
import ProfileQna from 'components/vars/qna/ProfileQna';
import ReplyTextArea from 'components/vars/qna/ReplyTextArea';
import DetailContent from 'components/vars/common/DetailContent';
import ReplyContentBox from 'components/vars/qna/ReplyContentBox';
import FileDownloadVars from 'components/vars/common/FileDownloadVars';
import {
  deleteAdminQnaListApi,
  getAdminQnaDetailApi,
  postAdminQnaReplyApi,
} from 'apis/vars/qna/QnaAdminApi';
import {
  deleteUserQnaApi,
  getUserQnaDetailApi,
  postUserQnaReplyApi,
} from 'apis/vars/qna/QnaUserApi';
import { downloadAllFilesToFileName } from 'apis/admin/FileUpload';
import { downloadAllFilesToFileNameVars, findVarsFiles } from 'apis/vars/common/Common';
import { findFiles } from 'apis/admin/FileUpload';
import { FileInfo, FileSaveResponse, FileSaveResult } from 'models/admin/FileInfo';
import { useMessageBar } from 'components/process/MessageBar';
import { replaceWithTimezone } from 'utils/TimeZoneUtil';

// 게시글 상세조회
export interface QnaDetailRes {
  bbmNo: number; // 게시글 번호
  bbmTitNm: string; // 제목
  dataInsUserId: string; // 게시자id
  dataInsUserNm: string; // 게시자nm
  vdcpNm: string; // 공급업체명(일반사용자)
  dataInsDtm: string; // 등록일시
  strDataInsDtm: string; // 등록일시(로케일 적용)
  dataUpdDtm: string; // 수정일시
  strDataUpdDtm: string; // 수정일시(로케일 적용)
  bbmCtn: string; // 내용
  bbmVwct: string; // 조회 수
  atchFileGrId: string; // 첨부파일 GrId
  atchFileCnt: number; // 첨부파일 개수
  emlTpCd: string; // 이메일 유형코드
  replyCnt: number; // 댓글 개수(삭제 댓글 제외)
  replies: QnaReplies[]; // 댓글
}

// 댓글 조회
export interface QnaReplies {
  bbmNo: number; // 게시글 번호
  bbmReNo: number; // 댓글 번호
  bbmReCtn: string; // 댓글 내용
  dataInsUserId: string; // 게시자id
  dataInsUserNm: string; // 게시자nm
  vdcpNm: string; // 공급업체명(일반사용자)
  dataInsUserJikwiNm: string; // 직위(관리자)
  dataInsUserDeptNm: string; // 부서(관리자)
  atchFileGrId?: string; // 첨부파일 GrId
  atchFileCnt?: number; // 첨부파일 개수
  dataInsDtm: string; // 등록일시
  strDataInsDtm: string; // 등록일시(로케일)
  dataUpdDtm: string; // 수정일시
  strDataUpdDtm: string; // 수정일시(로케일)
  upprBbmReNo: string; // 대댓글 작성 시 댓글 번호
  reDelYn: string; // 댓글 삭제여부
}

// 댓글 등록
export interface QnaReplyRegist {
  bbmNo: number; // 게시글 번호
  bbmReCtn: string; // 댓글 내용
  atchFileGrId: string | null; // 첨부파일 GrId
  bbmReNo: number; // 댓글 번호
  upprBbmReNo: number; // 대댓글 작성 시 댓글 번호
}

const QnaDetailPage = () => {
  const imagePath = ReplyArrow;
  const { t } = useTranslation();
  const t2 = t as (msgId: string, defVal: string) => string;
  const navigate = useNavigate();
  // const { bbmNo } = useParams();
  const { bbmNo }: { bbmNo?: number } = useLocation()?.state || {};
  const replyzoneRef = useRef<any>();
  const { accessToken } = useAuthStore();
  const { openLoading } = useLoading();
  const { openMessageBar } = useMessageBar();
  const { openCommonModal } = useCommonModal();
  const { userNm, deptNm, jtiNm } = useSessionStore();
  const [detailQnaData, setDetailQnaData] = useState<QnaDetailRes>();
  const [replyQnaData, setReplyQnaData] = useState<QnaReplies[]>([]);
  const [replyRegist, setReplyRegist] = useState<QnaReplyRegist>({
    bbmNo: 0,
    bbmReCtn: '',
    atchFileGrId: '',
    bbmReNo: 0,
    upprBbmReNo: 0,
  });
  const [comment, setComment] = useState<string>('');
  const [newComment, setNewComment] = useState<string>('');
  const [files, setFiles] = useState<FileInfo[]>([]);
  const replyMaxLength = 300;

  const getQnaDetailData = async (uptVwct) => {
    try {
      if (accessToken) {
        const data = await getUserQnaDetailApi(Number(bbmNo), uptVwct);
        setDetailQnaData(data);
        setReplyQnaData(data.replies);
        setReplyRegist((prev) => ({
          ...prev,
          bbmNo: Number(bbmNo),
        }));
        if (data.atchFileGrId) {
          findVarsFiles(data.atchFileGrId).then((result: FileInfo[]) => {
            setFiles(result);
          });
        }
      } else {
        const data = await getAdminQnaDetailApi(Number(bbmNo), uptVwct);
        setDetailQnaData(data);
        setReplyQnaData(data.replies);
        setReplyRegist((prev) => ({
          ...prev,
          bbmNo: Number(bbmNo),
        }));
        if (data.atchFileGrId) {
          findFiles(data.atchFileGrId).then((result: FileInfo[]) => {
            setFiles(result);
          });
        }
      }
    } catch (error) {
      console.error('상세조회 오류:', error);
    }
  };

  useEffect(() => {
    if (bbmNo) {
      // 상세 페이지에 접근할 때에만 조회수 카운팅
      // 다른 상황(댓글 작성/수정/삭제 후 조회 시)은 'N'
      const uptVwct = 'Y';
      getQnaDetailData(uptVwct);
    }
  }, []);

  const handleChangePage = () => {
    navigate('/qna/list');
  };

  const handleEditPage = () => {
    navigate('/qna/edit', {
      state: { bbmNo: bbmNo },
    });
  };

  const handleDelete = () => {
    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.00191', '__Q&A 게시글을 삭제하시겠습니까?'),
      noCallback: () => {
        return;
      },
      yesCallback: async () => {
        openLoading(true);
        if (accessToken) {
          return deleteUserQnaApi(Number(bbmNo))
            .then((res) => {
              if (res === true) {
                openLoading(false);
                openMessageBar('success', t('com.label.00179', '__삭제되었습니다.'));
                navigate('/qna/list');
              }
            })
            .catch((error) => {
              openLoading(false);
              console.error('Error deleting row:', error);
            });
        } else {
          const bbmNoArr: number[] = [Number(bbmNo)];
          return deleteAdminQnaListApi(bbmNoArr)
            .then((res) => {
              if (res === true) {
                openLoading(false);
                openMessageBar('success', t('com.label.00179', '__삭제되었습니다.'));
                navigate('/qna/list');
              }
            })
            .catch((error) => {
              openLoading(false);
              console.error('Error deleting row:', error);
            });
        }
      },
    });
  };

  useEffect(() => {
    setReplyRegist((prev) => ({
      ...prev,
      bbmReCtn: comment,
    }));
  }, [comment]);

  const handleWriteReply = (e) => {
    const newValue = e.target.value;

    if (newValue.length <= replyMaxLength) {
      setNewComment(e.target.value);
      setReplyRegist((prev) => ({
        ...prev,
        bbmReCtn: e.target.value,
      }));
    } else {
      openCommonModal({
        content: t('com.label.00194', '__댓글 본문은 300자를 넘을 수 없습니다.'),
      });
    }
  };

  const getFileId = async () => {
    const fileSaveResponse: FileSaveResponse = await replyzoneRef.current.saveFiles();
    return fileSaveResponse.fileSaveResult == FileSaveResult.FAIL
      ? null
      : fileSaveResponse.atchFileGrId;
  };

  const handleReplySave = async () => {
    const uptVwct = 'N';
    const fileId = await getFileId();
    if (!comment) {
      if (accessToken) {
        postUserQnaReplyApi({ ...replyRegist, atchFileGrId: fileId ? fileId : '' }).then((res) => {
          if (res === true) {
            getQnaDetailData(uptVwct);
            setReplyRegist((prev) => ({
              ...prev,
              bbmReCtn: '',
              atchFileGrId: '',
            }));
            setNewComment('');
            replyzoneRef.current.resetFile();
          }
        });
      } else {
        postAdminQnaReplyApi({ ...replyRegist, atchFileGrId: fileId ? fileId : '' }).then((res) => {
          if (res === true) {
            getQnaDetailData(uptVwct);
            setReplyRegist((prev) => ({
              ...prev,
              bbmReCtn: '',
              atchFileGrId: '',
            }));
            setNewComment('');
            replyzoneRef.current.resetFile();
          }
        });
      }
    }
  };

  return (
    <>
      <Title>{detailQnaData?.bbmTitNm}</Title>
      <InfoBox>
        <EditProfile
          profileImg={ProfileImg}
          nickName={detailQnaData?.dataInsUserNm}
          position={detailQnaData?.vdcpNm}
          isPosition={true}
        />
        <DateInfo
          text={t('com.label.regDtm', '등록일시')}
          date={
            detailQnaData
              ? replaceWithTimezone(detailQnaData.strDataInsDtm, 'YYYY-MM-DD HH:mm:ss') ?? ''
              : ''
          }
        />
        {detailQnaData?.dataUpdDtm !== '' && (
          <DateInfo
            text={t('com.label.updDtm', '수정일시')}
            date={
              detailQnaData
                ? replaceWithTimezone(detailQnaData.strDataUpdDtm, 'YYYY-MM-DD HH:mm:ss') ?? ''
                : ''
            }
          />
        )}
      </InfoBox>
      <Box>
        {files.length !== 0 && (
          <FileBox>
            <SubTitleBtnArea>
              <SubTitleBox
                showSubTitle={true}
                content={t('com.label.00103', '첨부파일')}
                isVisbleSum={true}
                commentCount={detailQnaData?.atchFileCnt}
              />
              <GButton
                chkImg={Download}
                txt={t('com.label.downloadAll', '전체다운로드')}
                sizes="small"
                btnstyled="outline"
                btnColor="normal"
                hasImg={true}
                hasTxt={true}
                onClick={() => {
                  if (accessToken) {
                    downloadAllFilesToFileNameVars(
                      detailQnaData?.atchFileGrId,
                      `VARS_QNA_[${detailQnaData?.bbmNo}].zip`
                    );
                  } else {
                    downloadAllFilesToFileName(
                      detailQnaData?.atchFileGrId,
                      `VARS_QNA_[${detailQnaData?.bbmNo}].zip`
                    );
                  }
                }}
              ></GButton>
            </SubTitleBtnArea>
            <FileDownloadVars atchFileGrId={detailQnaData?.atchFileGrId} />
          </FileBox>
        )}

        <QnaContents>
          <DetailContent value={detailQnaData?.bbmCtn}></DetailContent>
        </QnaContents>
        <CountWrap>
          <EyeCount count={detailQnaData?.bbmVwct ? Number(detailQnaData.bbmVwct) : 0} />
          <ReplyCount count={detailQnaData?.replyCnt ? Number(detailQnaData.replyCnt) : 0} />
        </CountWrap>
      </Box>
      <GButtonLayout>
        <GButtonBox style={{ marginBottom: '12px' }}>
          <GButton
            chkImg={List}
            txt={t('com.label.00053', '목록')}
            sizes="large"
            btnstyled="outline"
            btnColor="normal"
            hasImg={true}
            hasTxt={true}
            onClick={handleChangePage}
          ></GButton>
          {accessToken && (
            <GButton
              chkImg={New}
              txt={t('com.label.edit', '__수정')}
              sizes="large"
              btnstyled="outline"
              btnColor="normal"
              hasImg={true}
              hasTxt={true}
              onClick={handleEditPage}
            ></GButton>
          )}
          <GButton
            chkImg={Delete}
            txt={t('com.label.00054', '삭제')}
            sizes="large"
            btnstyled="outline"
            btnColor="normal"
            hasImg={true}
            hasTxt={true}
            onClick={handleDelete}
          ></GButton>
        </GButtonBox>
      </GButtonLayout>
      <ReplyWrap>
        <SubTitleBox
          showSubTitle={true}
          content={t('com.label.comm', '__댓글')}
          commentCount={detailQnaData?.replyCnt ? Number(detailQnaData.replyCnt) : 0}
          isShowSumCount={true}
        />
        <ReplyContentWrap>
          {/* 등록영역(댓글) */}
          <CommentField>
            <ProfileInfo>
              <ProfileQna
                profileImg={ProfileImg}
                nickName={userNm + ' ' + (jtiNm ? jtiNm : '')}
                position={deptNm}
                isPosition={true}
              />
            </ProfileInfo>
            <ReplyTextArea
              status="default"
              width="100%"
              height="auto"
              placeholder={t2('com.label.00051', '댓글을 입력해 주세요.')}
              isComment={true}
              value={newComment}
              count={newComment.length + ' / 300'}
              onChange={handleWriteReply}
              handleReplySave={handleReplySave}
              replyzoneRef={replyzoneRef}
            />
          </CommentField>
          {/* 등록된 댓글, 대댓글 렌더링 */}
          {replyQnaData?.map((item) => (
            <ReplyContentBox
              key={item.bbmReNo}
              replyData={item}
              setComment={setComment}
              getQnaDetailData={getQnaDetailData}
              handleWriteReply={handleWriteReply}
              handleReplySave={handleReplySave}
            />
          ))}
        </ReplyContentWrap>
      </ReplyWrap>
    </>
  );
};

const Title = styled.h3`
  color: #1f1f1f;
  font-family: Spoqa Han Sans Neo;
  font-size: 24px;
  font-weight: 700;
  line-height: 150%;

  padding-top: 24px;
  border-top: 1px solid #dde0df;
`;

const QnaContents = styled.div`
  // min-height: 147px;
  margin-top: 24px;
  margin-bottom: 48px;
`;

const Box = styled.div`
  border-top: 1px solid #dde0df;
  border-bottom: 1px solid #dde0df;
  padding-bottom: 12px;
`;

const CountWrap = styled.div`
  display: flex;
  gap: 24px;
`;

const CommentField = styled.div`
  width: 100%;
  height: auto;
  padding: 12px;
  background: #f7f9f8;
  margin-top: 12px;
`;

const ProfileInfo = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 4px;
`;

const ReplyWrap = styled.div`
  margin-top: 0px;
`;

const ReplyContentWrap = styled.ul`
  li:first-child > div:first-child {
    border-bottom: 1px solid #ebeeed;
  }
`;

const InfoBox = styled.div`
  height: auto;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  margin-top: 8px;
`;

const FileBox = styled.div`
  margin-top: 4px;
  margin-bottom: 24px;
  padding: 12px;
  border: 1px solid #dde0df;
  background: #f7f9f8;
`;

export default QnaDetailPage;
