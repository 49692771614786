/** @jsxImportSource @emotion/react */
import { useRef, useState } from 'react';
import styled from '@emotion/styled';
import { BlueButton } from 'components/buttons/CustomButton';
import Dropzone from 'components/dropzone/Dropzone';
import { CustomInputText } from 'components/inputs/CustomInput';
import { useCommonModal } from 'hooks/useCommonModal';
import FileGrid from 'components/dropzone/FileGrid';
import { FileSaveResponse, FileSaveResult } from 'models/admin/FileInfo';
import LicenseFileGrid from 'components/dropzone/LicenseFileGrid';
import OrderSaveFileGrid from 'components/dropzone/OrderSaveFileGrid';

const DropzonSamplePage = () => {
  const { openCommonModal } = useCommonModal();
  const dropzoneRef = useRef<any>();
  const [atchFileGrId, setAtchFileGrId] = useState<string>('');

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAtchFileGrId(e.target.value);
  };

  const handleSave = async () => {
    const fileSaveResponse: FileSaveResponse = await dropzoneRef.current.saveFiles();
    if (fileSaveResponse.fileSaveResult == FileSaveResult.FAIL) return;
    const atchFileGrId = fileSaveResponse.atchFileGrId;

    setAtchFileGrId(atchFileGrId);
    openCommonModal({
      content: '저장완료',
    });
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CardContainer>
        <CardTitle>Dropzone Sample</CardTitle>
        <hr />
        <CustomInputText
          type="text"
          value={atchFileGrId}
          placeholder="File Group Id"
          onChange={onInputChange}
        ></CustomInputText>{' '}
        <BlueButton onClick={handleSave}>저장</BlueButton>
        <Dropzone
          ref={dropzoneRef}
          atchFileGrIdInput={atchFileGrId}
          showAllDownload={true}
          atchFileTpCd="DROP_ZONE_SAMPLE"
        />
      </CardContainer>
      <CardContainer>
        <CardTitle>FileGrid Sample</CardTitle>
        <hr />
        <FileGrid atchFileGrId={atchFileGrId} />
      </CardContainer>
      <CardContainer>
        <CardTitle>LicenseFileGrid Sample</CardTitle>
        <hr />
        <LicenseFileGrid atchFileGrId={'9e78b760-fbbb-44f4-9dbe-33b4ade846c3'} />
      </CardContainer>
      <CardContainer>
        <CardTitle>OrderSaveFileGrid Sample</CardTitle>
        <hr />
        <OrderSaveFileGrid
          atchFileGrId={'23419837-30b6-4a23-b446-2e40be76392d'}
          showAllDownload={true}
        />
      </CardContainer>
    </div>
  );
};

const CardContainer = styled.div`
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.12);
  padding: 20px;
  align-content: space-around;
  hr {
    width: 100%;
  }
  justify-items: start;
`;

const CardTitle = styled.h3`
  font-size: 20px;
  font-weight: 500;
`;
export default DropzonSamplePage;
