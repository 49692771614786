import { GButton } from 'components/atom/button';
import Arrow from '../../components/asset/images/arrow.jpg';
import LgEnsol from '../../components/asset/images/LGEnergySolution.svg';

const PrintTestPage = () => {
  // 실data 들어갈 위치 표시
  const data = '<span style="color:blue;">Sample Data Here</span>';
  const handleClickPif = () => {
    const htmlContent = `
     <html lang="en"><head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="stylesheet" type="text/css" href="design.css">
    <title>LG Energy Solution</title>
    <style>@charset "UTF-8";
html,
body {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  color: #222;
  font-family: "맑은 고딕", Dotum, "돋움", Gulim, "굴림", Tahoma, Verdana,
    AppleGothic, UnDotum, Arial;
  -webkit-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
select,
textarea {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-text-size-adjust: none;
}

table,
th,
td,
input,
textarea,
select,
button {
  box-sizing: border-box;
  font-family: "맑은 고딕", Dotum, "돋움", Gulim, "굴림", Tahoma, Verdana,
    AppleGothic, UnDotum, Arial;
  -webkit-text-size-adjust: none;
}

th,
td {
  font-size: 12px;
  word-break: break-all;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
}

table {
  width: 100%;
  border-spacing: 0;
  background-color: #ffffff;
  border-collapse: collapse;
  -webkit-text-size-adjust: none;
  border: 0;
  table-layout: fixed;
  box-sizing: border-box;
}

address,
caption,
cite,
code,
dfn,
em,
var {
  font-style: normal;
  font-weight: normal;
  -webkit-text-size-adjust: none;
}
em {
  font-weight: inherit;
}
ol,
ul,
li {
  list-style: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  box-sizing: border-box;
}

legend,
caption {
  visibility: hidden;
  overflow: hidden;
  line-height: 0;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  text-indent: -99999px;
  font-size: 0;
}
img,
fieldset {
  border: 0 none;
}
span,
em,
strong {
  box-sizing: border-box;
}

a {
  color: #777;
  box-sizing: border-box;
}
a:link,
a:visited,
a:hover {
  color: #777;
  text-decoration: none;
}
input,
img {
  vertical-align: middle;
}

input,
button {
  border-radius: 0;
  -webkit-appearance: none;
  border: 0;
}
button {
  background-color: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;
  font-size: 14px;
}

textarea {
  width: 100%;
  resize: none;
  padding: 10px;
  border: 1px solid #c9c9c9;
}
label {
  vertical-align: middle;
}

/* html5 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*:before,
*:after,
* {
  box-sizing: border-box;
}

/* page layout style (순서대로) */
.page {
  position: relative;
  width: 21cm;
  min-height: 29.7cm;
  margin: 0 auto;
  border: 1px solid #ddd;
}
@page {
  size: A4;
  margin: 0;
}

@media print {
  html,
  body {
    width: 210mm;
    height: 297mm;
    font-family: "맑은고딕", Dotum, "돋움", Gulim, "굴림", Tahoma, Verdana,
      AppleGothic, UnDotum, Arial;
  }
  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }

  .bold_text {
    font-weight: bold;
  }
}

/* 페이지 퍼블리싱 css */
.content_wrap {
  margin: 18px 15px;
}

.logo_wrap {
  text-align: right;

  img {
    width: 200px;
  }
}

.top_table {
  width: 100%;
  border: none;
  margin-bottom: 5px;
}

.title {
  font-size: 32px;
  text-align: center;
}

.sub_title {
  padding-top: 10px;
}

.text {
  font-size: 12px;
}

p.text {
  margin-top: 5px;
}

.bold_text {
  font-weight: bold !important;
}
tk

/* 콘텐츠 테이블 부분 */

.content_table {
  width: 100%;
  border: 1px solid #7f7f7f;
  font-size: 13px;
  border-collapse: collapse;
}

table[class="content_table"] td {
  border: 1px solid #7f7f7f;
  height: 30px !important;
  padding: 4px;
  border-collapse: collapse;
  text-align: left;
  font-weight: 400;
}

table[class="content_table"] th {
  border: 1px solid #7f7f7f;
  padding: 4px;
  border-collapse: collapse;
  text-align: left;
  font-weight: 400;
}

table[class="content_table"] .th_align_right {
  text-align: right !important;
}

.td_fog_text {
  color: #c4c4c4;
}

table[class="content_table"] .table_border_wrap {
  border: 2px solid black;
}

table[class="content_table"] .border_first {
  border-bottom: none;
}

table[class="content_table"] .boder_middle {
  border-top: none;
  border-bottom: none;
}

table[class="content_table"] .border_last {
  border-top: none;
}

.td_arrow_img {
  width: 12px;
  height: 12px;
  margin-left: 5px;
  margin-bottom: 2px;
}

.td_align {
  align-items: center;
  vertical-align: middle;
}

/* 입력란 부분 */
.write_wrap {
  margin-top: 8px;
}

.write_align {
  display: flex;
  gap: 13px;
  margin-top: 2px;
}

.write {
  border-top: 1px solid black;
  margin-top: 40px;
  padding-top: 2px;
}

.first {
  width: 350px;
}

.second {
  width: 150px;
}
</style>
  </head>
  <body>
    <div class="page">
      <div class="content_wrap">
        <div class="logo_wrap">
          <img src=${LgEnsol} alt="LGEnergySolutionLogo">
        </div>
        <table class="top_table bold_text">
          <tbody><tr>
            <td class="title">PAYMENT INFORMATION FORM</td>
          </tr>
          <tr>
            <td class="text bold_text sub_title">
              *All of the information below is required to set up payments.
            </td>
          </tr>
        </tbody></table>

        <table class="content_table">
          <colgroup>
            <col style="width: 200px">
            <col stlye="width: auto">
          </colgroup>
          <tbody>
            <tr>
              <th colspan="2" class="bold_text td_align">
                <img src=${Arrow} class="td_arrow_img">
                COMPANY INFORMATION
              </th>
            </tr>
            <tr>
              <th>Company / Customer Name</th>
              <td>${data}</td>
            </tr>
            <tr>
              <th rowspan="2">Address:</th>
              <td>${data}</td>
            </tr>
            <tr>
              <td>${data}</td>
            </tr>
            <tr>
              <th>Telephone Number:</th>
              <td>${data}</td>
            </tr>
            <tr>
              <th>Representative(CEO)</th>
              <td>${data}</td>
            </tr>
            <tr>
              <th>Business Registration number</th>
              <td>${data}</td>
            </tr>
            <tr>
              <th>Sales dept. Contact info</th>
              <td class="td_fog_text">
                (Name of the Person who contacts with LG Energy Solution, LTD
                for trade, service)
              </td>
            </tr>
            <tr>
              <th class="th_align_right">Telephone Number</th>
              <td>${data}</td>
            </tr>
            <tr>
              <th class="th_align_right">Fax Number</th>
              <td>${data}</td>
            </tr>
            <tr>
              <th class="th_align_right">E-mail Address</th>
              <td>${data}</td>
            </tr>
            <tr class="table_border_wrap border_first">
              <th>Finance dept. Contact info</th>
              <td class="td_fog_text">
                (Name of the Person who contacts with LG Energy Solution, LTD
                for trade, service)
              </td>
            </tr>
            <tr class="table_border_wrap boder_middle">
              <th class="th_align_right">Telephone Number</th>
              <td>${data}</td>
            </tr>
            <tr class="table_border_wrap boder_middle">
              <th class="th_align_right">Fax Number</th>
              <td>${data}</td>
            </tr>
            <tr class="table_border_wrap border_last">
              <th class="th_align_right">E-mail Address</th>
              <td>${data}</td>
            </tr>
            <tr>
              <th colspan="2" class="bold_text">
                *All of the contact information must be company’s official
                numbers(not private email or phone/fax no.) <br>
                *Information in above bold box must be filled.
              </th>
            </tr>
            <tr>
              <th colspan="2" class="bold_text td_align">
                <img src=${Arrow} class="td_arrow_img">
                BANK INFORMATION
              </th>
            </tr>
            <tr>
              <th>Name on the Account</th>
              <td class="td_fog_text">(Beneficiary of the Account)</td>
            </tr>
            <tr>
              <th>Currency</th>
              <td>${data}</td>
            </tr>
            <tr>
              <th>Swift Code / ABA Routing No.</th>
              <td>${data}</td>
            </tr>
            <tr>
              <th>
                Account Number <br>
                (IBAN Code if available)
              </th>
              <td>${data}</td>
            </tr>
            <tr>
              <th>Name of Bank</th>
              <td>${data}</td>
            </tr>
            <tr>
              <th rowspan="2">Bank Address</th>
              <td>${data}</td>
            </tr>
            <tr>
              <td>${data}</td>
            </tr>
            <tr>
              <th>Bank Telephone Number:</th>
              <td>${data}</td>
            </tr>
            <tr>
              <th>Bank E-mail Address</th>
              <td>${data}</td>
            </tr>
          </tbody>
        </table>
        <p class="text">
          By providing the required information, the undersigned agrees to be
          paid by <span class="bold_text">LG Energy Solution, LTD.</span> for
          all future invoices via Electronic Funds Trasfer to the bank account
          named above.
        </p>
        <div class="write_wrap">
          <div class="write_align">
            <div class="write first">Signature</div>
            <div class="write second">Date</div>
          </div>
          <div class="write_align">
            <div class="write first">Print Name/Title</div>
            <div class="write second">E-mail</div>
          </div>
        </div>
      </div>
    </div>
</body></html>
    `;

    const newWindow = window.open('', '_blank');
    if (newWindow) {
      newWindow.document.write(htmlContent);
      newWindow.document.close();
      newWindow.onload = () => {
        newWindow.print();
      };
    } else {
      console.error('팝업 창을 열 수 없습니다.');
    }
  };

  const handleClickMultiAcnt = () => {
    const htmlContent = `
     <html lang="en"><head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="stylesheet" type="text/css" href="design.css">
    <title>LG Report</title>
    <style>@charset "UTF-8";

html,
body {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  color: #222;
  font-family: "맑은 고딕", Dotum, "돋움", Gulim, "굴림", Tahoma, Verdana,
    AppleGothic, UnDotum, Arial;
  -webkit-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
select,
textarea {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-text-size-adjust: none;
}
table,
th,
td,
input,
textarea,
select,
button {
  box-sizing: border-box;
  font-family: "맑은 고딕", Dotum, "돋움", Gulim, "굴림", Tahoma, Verdana,
    AppleGothic, UnDotum, Arial;
  -webkit-text-size-adjust: none;
}
th,
td {
  word-break: break-all;
  word-wrap: break-word;
  border: 1px solid #7f7f7f;
  -webkit-text-size-adjust: none;
}
table {
  font-size: 14px;
  border-spacing: 0;
  border: 1px solid #7f7f7f;
  background-color: #ffffff;
  border-collapse: collapse;
  -webkit-text-size-adjust: none;
  border: 0;
  table-layout: fixed;
  box-sizing: border-box;
}
address,
caption,
cite,
code,
dfn,
em,
var {
  font-style: normal;
  font-weight: normal;
  -webkit-text-size-adjust: none;
}
em {
  font-weight: inherit;
}
ol,
ul,
li {
  list-style: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  box-sizing: border-box;
}

legend,
caption {
  visibility: hidden;
  overflow: hidden;
  line-height: 0;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  text-indent: -99999px;
  font-size: 0;
}
img,
fieldset {
  border: 0 none;
}
span,
em,
strong {
  box-sizing: border-box;
}

a {
  color: #777;
  box-sizing: border-box;
}
a:link,
a:visited,
a:hover {
  color: #777;
  text-decoration: none;
}
input,
img {
  vertical-align: middle;
}

input,
button {
  border-radius: 0;
  -webkit-appearance: none;
  border: 0;
}
button {
  background-color: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;
  font-size: 14px;
}

textarea {
  width: 100%;
  resize: none;
  padding: 10px;
  border: 1px solid #c9c9c9;
}
label {
  vertical-align: middle;
}

/* html5 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*:before,
*:after,
* {
  box-sizing: border-box;
}

/* Font */
/* @font-face {
  font-family: "LG Smart";
  font-weight: 400;
  src: url("../font/LG_Smart_Light.woff") format("woff"),
    url("../font/LG_Smart_Light.woff2") format("woff2"),
    url("../font/LG_Smart_Light.ttf") format("truetype");
}
@font-face {
  font-family: "LG Smart";
  font-weight: 500;
  src: url("../font/LG_Smart_Regular.woff") format("woff"),
    url("../font/LG_Smart_Regular.woff2") format("woff2"),
    url("../font/LG_Smart_Regular.ttf") format("truetype");
}
@font-face {
  font-family: "LG Smart";
  font-weight: 600;
  src: url("../font/LG_Smart_Bold.woff") format("woff"),
    url("../font/LG_Smart_Bold.woff") format("woff"),
    url("../font/LG_Smart_Bold.ttf") format("truetype");
} */

.page {
  position: relative;
  width: 21cm;
  min-height: 29.7cm;
  margin: 0 auto;
  border: 1px solid #ddd;
}
@page {
  size: A4;
  margin: 0;
}

@media print {
  html,
  body {
    width: 210mm;
    height: 297mm;
    font-family: "맑은고딕", Dotum, "돋움", Gulim, "굴림", Tahoma, Verdana,
      AppleGothic, UnDotum, Arial;
  }
  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
  .bold_text {
    font-weight: bold;
  }
}

ol {
  list-style-type: none;
  counter-reset: list-counter;
}

li {
  counter-increment: list-counter;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 900;
}

/* 페이지 퍼블리싱 css */
.content_wrap {
  margin: 40px 35px;
  text-align: center;
  font-size: 16px;
}

.top_content_align_wrap {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-bottom: 30px;
}

.title {
  font-size: 28px;
}

.content_text {
  font-size: 15px;
}

.history_wrap {
  table {
    width: 100%;
  }
  p {
    font-size: 15px;
    text-align: left;
    margin-bottom: 4px;
  }
}

.left_list_wrap {
  p {
    font-size: 15px;
    text-align: left;
    margin-bottom: 4px;
  }
}

/* table style*/
table {
  width: 100%;
}

th,
td {
  font-weight: 400;
  padding: 2px 5px;
}

td {
  text-align: left;
}

.td_align {
  text-align: center !important;
}

.date_text {
  font-size: 12px;
  margin: 0 0 100px;
  text-align: right;
}

.bottom_cotnent_wrap {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.bottom_content {
  display: flex;
  justify-content: end;
  font-size: 14px;
}

.bottom_content ol {
  width: 440px;
  margin-bottom: 10px;
}

.fields_title {
  width: 85px;
  margin-right: 30px;
  text-align: left;
  padding: 4px;
}

.align_wrap {
  display: flex;
  border-bottom: 1px solid black;
  padding: 4px;
  width: 330px;

  span {
    margin-left: 150px;
  }

  p:nth-child(1) {
    text-align: left;
    width: 80px;
    margin-right: 20px;
  }
}

/* list style */
list {
  text-align: left;
}

.in_list {
  text-indent: 20px;
}

ol {
  list-style-type: none;
  counter-reset: list-counter;
}

li {
  counter-increment: list-counter;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 400;
}

li:before {
  content: counter(list-counter) ")";
}

.info_text {
  font-size: 12px !important;
  text-indent: 120px;
  margin-top: 4px;
}

.in_text {
  text-indent: 16px;
}
</style>
  </head>
  <body>
    <div class="page">
      <div class="content_wrap">
        <div class="top_content_align_wrap">
          <h1 class="title">복수계좌 신고서</h1>
          <p class="content_text">
            당사가 LG에너지솔루션에 재화와 용역을 제공하고 납품대금을 은행을<br>
            통하여 수령함에 있어 수금용 결제계좌를 아래와 같이 추가 요청 합니다.
          </p>
          <div>
            <p>
              ******************************** 아 래
              ********************************
            </p>
          </div>
          <table>
            <colgroup>
              <col style="width: 120px">
              <col style="width: 340px">
              <col style="width: 120px">
              <col>
            </colgroup>
            <tbody>
              <tr>
                <th>상호</th>
                <td>삼아알미늄</td>
                <th>대표자</th>
                <td>한남희</td>
              </tr>
              <tr>
                <th>사업자등록번호</th>
                <td>123-81-01882</td>
                <th>공급업체</th>
                <td>KR000200</td>
              </tr>
              <tr>
                <th>주소</th>
                <td colspan="3">경기도 평택시 포승읍평택향로 92</td>
              </tr>
            </tbody>
          </table>
          <div class="history_wrap">
            <p>[추가/변경 복수계좌 내역]</p>
            <table>
              <tbody>
                <tr>
                  <th style="width: 120px">은행명</th>
                  <th style="width: 155px">계좌번호</th>
                  <th stlye="width: 140px">예금주명</th>
                  <th style="width: 120px">계좌 용도</th>
                  <th style="width: 140px">유형</th>
                </tr>
                <tr>
                  <td class="td_align">신한은행</td>
                  <td class="td_align">100011852384</td>
                  <td class="td_align">삼아알미늄(주)</td>
                  <td class="td_align">주금납입</td>
                  <td class="td_align">추가</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="history_wrap">
            <p>[기등록 계좌 내역]</p>
            <table>
              <tbody>
                <tr>
                  <th style="width: 120px">은행명</th>
                  <th style="width: 155px">계좌번호</th>
                  <th stlye="width: 140px">예금주명</th>
                  <th style="width: 120px">계좌 용도</th>
                  <th style="width: 95px">계좌등록일</th>
                  <th style="width: 45px">비고</th>
                </tr>
                <tr>
                  <td class="td_align">우리</td>
                  <td class="td_align">1220585611301</td>
                  <td class="td_align">20051129 남역삼</td>
                  <td class="td_align">정규계좌</td>
                  <td class="td_align">2005-11-29</td>
                  <td class="td_align"></td>
                </tr>
              </tbody></table>
              

          </div>
          <div class="left_list_wrap">
            <p>※ 추가제출 서류</p>
            <list class="in_list">
              <ol>
                <li>
                  법인 사업자: 통장 사본, 사업자등록증 사본, 법인인감증명서 원본,
                  신고서 하단 법인인감 날인.
                </li>
                <li>
                  개인 사업자: 통장 사본, 신고서 하단 통장 인감 날인
                  <p class="info_text">
                    (통장 서명으로 개설 시, 신고서 하단 대표자 개인인감 날인 후
                    개인인감 증명서 유첨)
                  </p>
                  <p class="info_text">(통장은 사업자용 통장만 가능)</p>
                </li>
              </ol>
            </list>
          </div>
          <p class="date_text">2024년 02월 06일</p>
        </div>
        <div class="bottom_cotnent_wrap">
          <div class="bottom_content">
            <p class="fields_title">신고인</p>
            <div>
              <div class="align_wrap">
                <p>주소</p>
                <p>경기도 평택시 포승옵평택항로 92</p>
              </div>
              <div class="align_wrap">
                <p>상호</p>
                <p>삼아알미늄</p>
              </div>
              <div class="align_wrap">
                <p>대표이사</p>
                <p>한남희</p>
                <span>(인)</span>
              </div>
            </div>
          </div>
          <div class="bottom_content">
            <list>
              <ol>
                <li>
                  법인 사업자: 법인인감증명서와 동일 인감날인
                </li>
                <li>
                  개인 사업자: 업체계좌 등록 통장 인감과 동일 인감 날인
                  <p class="in_text">
                    (통장 서명으로 개설 시, 대표자 개인인감증명서와 동일 인감
                    날인)
                  </p>
                </li>
              </ol>
            </list>
          </div>
          <div class="bottom_content">
            <p class="fields_title">업체담당자</p>
            <div>
              <div class="align_wrap">
                <p>TEL</p>
                <p>023-4580-668</p>
              </div>
              <div class="align_wrap">
                <p>E-mail</p>
                <p>jwhan@sama-al.com</p>
              </div>
              <div class="align_wrap">
                <p>담당자명</p>
                <p>한지원</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</body></html>
    `;

    const newWindow = window.open('', '_blank');
    if (newWindow) {
      newWindow.document.write(htmlContent);
      newWindow.document.close();
      newWindow.onload = () => {
        newWindow.print();
      };
    } else {
      console.error('팝업 창을 열 수 없습니다.');
    }
  };

  return (
    <>
      <GButton
        hasTxt={true}
        hasImg={false}
        btnstyled="outline"
        btnColor="normal"
        sizes="small"
        txt="Payment Information Form 출력"
        onClick={handleClickPif}
      />
      <br />
      <hr />
      <br />
      <GButton
        hasTxt={true}
        hasImg={false}
        btnstyled="outline"
        btnColor="normal"
        sizes="small"
        txt="복수계좌 신고서 출력"
        onClick={handleClickMultiAcnt}
      />
    </>
  );
};

export default PrintTestPage;
