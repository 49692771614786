import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

declare const unisign: any;

export const useCommonCert = () => {
  const [signedText, setSignedText] = useState('');
  const [rValueBox, setRValueBox] = useState('');
  const { t } = useTranslation();
  const t2 = t as (msgId: string, defVal: string) => string;

  const callCertModule = useCallback(({ userId, bznoKey, callbackFn }) => {
    //console.log('bznoKey : ', bznoKey);
    if (!userId) {
      alert(t2('com.label.00723', '__vendorId가 없습니다.'));
      return;
    }

    if (!bznoKey) {
      alert(t2('com.label.00724', '__bznoKey가 없습니다.'));
      return;
    }

    unisign.SetMultiUsingOpt(true);

    unisign.SignDataEx(bznoKey, null, function (resultObject) {
      setSignedText(() => {
        return resultObject.signedData;
      }); //전자 서명 값

      console.log('signData :::', resultObject.signedData);
      if (!resultObject || resultObject.resultCode != 0) {
        if (
          !(
            resultObject.resultCode == 21002 ||
            resultObject.resultCode == 11003 ||
            resultObject.resultCode == 31001
          )
        )
          alert(resultObject.resultMessage);
        return;
      }

      if (resultObject.curDevice != 16 && resultObject.curDevice != 21) {
        GetRValueFromKey(resultObject.certAttrs.subjectName, resultObject.signedData);
      } else {
        setRValueBox(() => {
          return resultObject.Rvalue;
        });
      }
    });

    function GetRValueFromKey(userDN, signedData) {
      unisign.GetRValueFromKey(userDN, '', function (resultObject) {
        if (!resultObject || resultObject.resultCode != 0) {
          alert(resultObject.resultMessage + '\n오류코드 : ' + resultObject.resultCode);
          return;
        }
        setRValueBox(() => {
          return resultObject.Rvalue;
        });
        resultObject.signedData = signedData;
        callbackFn(resultObject);
        // setLoginFormData((prev) => {
        //   return { ...prev, publCertKey: resultObject.RValue };
        // });
      });
    }
  }, []);

  return {
    signedText,
    rValueBox,
    callCertModule,
  };
};
