import { rest } from 'msw';
import { StatusCode, SuccessOrNot } from 'models/common/RestApi';

export const handlers = [
  rest.get('/v1/sample', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        successOrNot: SuccessOrNot.Y,
        statusCode: StatusCode.SUCCESS,
        data: {},
      })
    );
  }),
];

export const handlerUrls = ['/v1/sample'];
