import styled from '@emotion/styled';
import { SelectChangeEvent } from '@mui/material';
import GSelectMUIAtom from 'components/atom/select';
import React from 'react';
import { ItemWrap } from './SearchMolecule';
import GridRadioButton from './GridRadioButton';
import GDatePicker from 'components/inputs/GDatePicker';

interface Props {
  selectValueWidth?: string;
  selectWidth?: string;
  selectOption?: string[];
  selectIsRequired?: boolean;
  selectOptionWidth?: string;

  valueType?: 'select' | 'calendar' | undefined;

  // valueSelect
  valueSelectOption?: string[];
  valueSelectPlaceholder?: string;
  valueSelectWidth?: string;

  // Radio
  radioOption?: string[];
  radioValue: string;

  //GDatePicker
  calendarReadonly?: boolean;
  calendarViews?: any | ['year', 'month', 'day'];
  calendarFormat?: string | 'YYYY.MM.DD';
  calendarMaxWidth?: string | '100%';
  calendarStatus?: 'default' | 'error' | 'warning' | 'confirm' | undefined;
  calendarDouble?: boolean | false;
  calendarWidth?: string | '100%';
}

const SelectMolecule = (props: Props) => {
  const [option, setOption] = React.useState('1');
  const handleChange = (event: SelectChangeEvent) => {
    setOption(event.target.value);
  };

  const [valueOption, setValueOption] = React.useState('0');
  const valueOptionHandleChange = (event: SelectChangeEvent) => {
    setValueOption(event.target.value);
  };

  return (
    <GInputArea searchWidth={props.selectWidth || '33%'}>
      <SelectWrap>
        <GSelectMUIAtom
          option={props.selectOption || []}
          value={option}
          selectWidth={props.selectOptionWidth || '120px'}
          handleChange={handleChange}
          isRequired={props.selectIsRequired}
        />
      </SelectWrap>

      <ValueWrap searchValueWidth={props.selectValueWidth}>
        {option == '1' ? (
          <GridRadioButton
            value={props.radioValue}
            option={props.radioOption || []}
            height="32px"
          />
        ) : option == '2' ? (
          props.valueType == 'select' ? (
            <GSelectMUIAtom
              selectWidth={props.valueSelectWidth}
              value={valueOption}
              handleChange={valueOptionHandleChange}
              placeholder={props.valueSelectPlaceholder}
              option={props.valueSelectOption || []}
            />
          ) : props.valueType == 'calendar' ? (
            <GDatePicker
              readonly={props.calendarReadonly}
              width={props.calendarWidth}
              maxWidth={props.calendarMaxWidth}
              status={props.calendarStatus}
              views={props.calendarViews}
              format={props.calendarFormat}
              double={props.calendarDouble}
            />
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
      </ValueWrap>
    </GInputArea>
  );
};

const GInputArea = styled.div<{ searchWidth: string | undefined }>`
  display: flex;
  width: ${(props) => props.searchWidth || '33%'};
`;

const SelectWrap = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const ValueWrap = styled(ItemWrap)`
  margin-left: 8px;
`;

export default SelectMolecule;
