/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import { getCommonCodeNamesCondition } from 'apis/admin/CommonCode';
import { getExcelSample } from 'apis/common/Excel';
import { getSample } from 'apis/common/Sample';
import { BlueButton, BlueLineButton, GreyButton } from 'components/buttons/CustomButton';
import ExcelReaderSample from 'components/excel/ExcelReadSample';
import ExcelUploadSample from 'components/excel/ExcelUploadSample';
import MailSample from 'components/mail/MailSample';
import DepartmentModal from 'components/modals/common/DepartmentModal';
import EmployeeModal from 'components/modals/common/EmployeeModal';
import { useCommonModal } from 'hooks/useCommonModal';
import { Department } from 'models/admin/Department';
import { Employee } from 'models/admin/Employee';
import { multiLangTest } from 'apis/admin/MultiLang';
import { CommonCodeCondition } from 'models/common/CommonCode';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// -- START TEST를 위한 임시로 수정 2023.08.18 Shin E.K
// import AssetClassModal from 'components/modals/common/AssetClassModal';
import CommonCodeModal from 'components/modals/common/CommonCodeModal/CommonCodeModal';
// import { AssetClassModel } from 'models/standard/AssetClassModel';

// import WbsInvestModal from 'components/modals/common/WbsInvestModal';
// import { WbsInvest } from 'models/standard/WbsInvest';
import { useCallback } from 'react';
import useSessionStore from 'stores/useSessionStore';
import InsuranceSearchModal from 'template/cims/modals/InsuranceSearchModal';
// import WorkPlaceModal from 'components/modals/asset/WorkCenter/WorkCenterModal';
// import StatementModal from 'components/modals/statement/StatementModal';
// import StocktakingPlanModal from 'components/modals/stocktaking/StocktakingPlan/StocktakingPlanModal';
// import { StocktakingPlan } from 'models/stocktaking/StocktakingPlan';

type SearchParams = {
  coCd?: string; // 법인 코드
  cctrCd?: string; // 코스트센터 코드
  cctrNm?: string; // 코스트센터 명
  wbsCd?: string; // WBS 코드
  wbsNm?: string; // WBS 명
};

type AssetClassSearchParams = {
  dmstYn?: string; // 국내여부
  asstClsCd?: string; // 자산클래스코드
  asstClsNm?: string; // CCTR
};

const StocktakingPlanModals = () => {
  const [AssetClassSearchParams, setAssetClassSearchParams] = useState<any>({});
  const [openStocktakingPlanModal, setOpenStocktakingPlanModal] = useState<boolean>(false);
  const handleSearch = useCallback(() => {
    setOpenStocktakingPlanModal(true);
  }, []);

  const handleModalOK = useCallback(async (listPlan: []) => {
    console.log('return > ', listPlan);
  }, []);

  const renderStocktakingPlanModal = () => {
    // return (
    //   <StocktakingPlanModal
    //     open={openStocktakingPlanModal}
    //     close={() => setOpenStocktakingPlanModal(false)}
    //     ok={handleModalOK}
    //     params={{
    //       coCd: 'C100',
    //       bplcCd: 'DJ',
    //       pridAntmDivsCd: '01',
    //       pyctYy: '',
    //       hlfDivsCd: '01',
    //       pyctTitNm: '실사',
    //       pyctProgStatCd: '01',
    //     }}
    //   />
    // );
  };

  return (
    <>
      <div>
        <button onClick={handleSearch}>실사계획 팝업</button>
      </div>

      {renderStocktakingPlanModal()}
    </>
  );
};

const StatementModals = () => {
  const [searchParams, setSearchParams] = useState<any>({});
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleOpen = useCallback(() => {
    setOpenModal(true);
  }, []);

  const handleWorkPlaceModalOk = useCallback(() => {
    console.log('ok');
  }, []);

  const renderTestModal = () => {
    // return (
    //   openModal && (
    //     <StatementModal
    //       param={{
    //         coCd: 'C100',
    //         stmtNo: '401438501',
    //         actgYy: '2023',
    //         eaprDocNo: 'test2023',
    //         asstNo: '',
    //         trstCurrDpslAmt: 0,
    //         inputParam: [],
    //       }}
    //       open={openModal}
    //       close={() => setOpenModal(false)}
    //       ok={handleWorkPlaceModalOk}
    //     />
    //   )
    // );
  };

  return (
    <>
      <div>
        <button onClick={handleOpen}>전표 조회 팝업</button>
      </div>

      {renderTestModal()}
    </>
  );
};

const CommonCodeModals = () => {
  const [searchParams, setSearchParams] = useState<any>({});
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleOpen = useCallback(() => {
    setOpenModal(true);
  }, []);

  const handleWorkPlaceModalOk = useCallback(() => {
    console.log('ok');
  }, []);

  const renderTestModal = () => {
    return (
      openModal && (
        <CommonCodeModal
          open={openModal}
          close={() => setOpenModal(false)}
          ok={handleWorkPlaceModalOk}
        />
      )
    );
  };

  return (
    <>
      <div>
        <button onClick={handleOpen}>공통 코드 팝업</button>
      </div>

      {renderTestModal()}
    </>
  );
};

const AssetClassModals = () => {
  const { dmstYn } = useSessionStore();
  const initValues = {
    dmstYn: dmstYn, // 국내여부
    asstClsCd: '', // 자산클래스코드
    asstClsNm: '', // CCTR
  };
  const [AssetClassSearchParams, setAssetClassSearchParams] = useState<AssetClassSearchParams>({});
  const [openAssetClassModal, setOpenAssetClassModal] = useState<boolean>(false);
  const handleSearchAssetClass = useCallback(() => {
    // setAssetClassSearchParams({ ...initValues });
    setOpenAssetClassModal(true);
  }, []);

  const handleAssetClassModalOK = useCallback(async (assetClass: any) => {
    console.log('return > ', assetClass);
  }, []);

  const renderAssetListModal = () => {
    // return (
    //   <AssetClassModal
    //     open={openAssetClassModal}
    //     close={() => setOpenAssetClassModal(false)}
    //     ok={handleAssetClassModalOK}
    //     params={AssetClassSearchParams}
    //   />
    // );
  };

  return (
    <>
      <div>
        <button onClick={handleSearchAssetClass}>자산클래스 팝업</button>
      </div>

      {renderAssetListModal()}
    </>
  );
};

const WbsInvestModals = () => {
  const initValues = {
    coCd: 'C100',
    cctrCd: '521101',
    cctrNm: '소형 전자사업부',
    wbsCd: '001',
    wbsNm: '회의비',
  };
  const [searchParams, setSearchParams] = useState<SearchParams>({});
  const [openWbsInvestModal, setOpenWbsInvestModal] = useState<boolean>(false);
  const handleSearchWbsInvest = useCallback(() => {
    setSearchParams({ ...initValues });
    setOpenWbsInvestModal(true);
  }, []);

  const handleWbsInvestModalOK = useCallback(async (WbsInvest: any) => {
    console.log('return > ', WbsInvest);
  }, []);

  const renderWbsInvestModal = () => {
    // return (
    //   <WbsInvestModal
    //     open={openWbsInvestModal}
    //     close={() => setOpenWbsInvestModal(false)}
    //     ok={handleWbsInvestModalOK}
    //     // params={searchParams}
    //   />
    // );
  };

  return (
    <>
      <div>
        <button onClick={handleSearchWbsInvest}>WBS(투자)팝업</button>
      </div>

      {renderWbsInvestModal()}
    </>
  );
};

// -- END TEST를 위한 임시로 수정 2023.08.18 Shin E.K

const SamplePage = () => {
  const { t } = useTranslation();
  const { openCommonModal } = useCommonModal();
  const [employeeModalOpen, setEmployeeModalOpen] = useState<boolean>(false);
  const [selectedEmployee, setSelectedEmployee] = useState<Employee[]>([]);
  const [departmentModalOpen, setDepartmentModalOpen] = useState<boolean>(false);
  const [selectedDepartment, setSelectedDepartment] = useState<Department>();
  const [insuraceModalOpen, setInsuranceModalOpen] = useState<boolean>(false);
  // const [selectInsurance, setSelectedInsurace] = useState<Insurance[]>([]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* START TEST를 위한 임시로 수정 2023.08.18 Shin E.K */}
      <CardContainer style={{ height: '204px', display: 'grid' }}>
        <CardTitle>팝업 테스트</CardTitle>
        <AssetClassModals />
        <WbsInvestModals />
        <StatementModals />
        <CommonCodeModals />
        <StocktakingPlanModals />
      </CardContainer>
      {/* END TEST를 위한 임시로 수정 2023.08.18 Shin E.K */}
      <CardContainer style={{ height: '204px', display: 'grid' }}>
        <CardTitle>공통 페이지</CardTitle>
        <hr />
        <Link to="/dev/login">
          <GreyButton>Dev Login</GreyButton>
        </Link>
        <Link to="/accessible">접근 가능 페이지</Link>
        <Link to="/inaccessible">접근 불가능 페이지</Link>
      </CardContainer>
      <CardContainer style={{ height: '180px', display: 'grid' }}>
        <CardTitle>다국어 Sample</CardTitle>
        <hr />
        <div style={{ margin: '10px' }}>{t('glsp', '__glsp')}</div>
        <div>
          <GreyButton>{t('common.button.조회', '__조회')}</GreyButton>
          <BlueButton>{t('common.button.저장', '__저장')}</BlueButton>
          <br></br>
          {t('test.hello', '{{name}}님 안녕하세요', { name: '지지' })}
        </div>
      </CardContainer>
      <CardContainer style={{ height: '150px', display: 'grid' }}>
        <CardTitle>Mock Service Worker Sample</CardTitle>
        <hr />
        <GreyButton onClick={getSample} style={{ width: '350px' }}>
          Mock Service Worker Test
        </GreyButton>
      </CardContainer>
      <CardContainer style={{ height: '300px', display: 'grid' }}>
        <CardTitle>Excel Sample</CardTitle>
        <hr />
        <button onClick={getExcelSample}>Excel Download Test</button>
        <br></br>
        <h3>Excel Read</h3>
        <div>
          <ExcelReaderSample />
        </div>

        <br></br>
        <h3>Excel Upload</h3>
        <div>
          <ExcelUploadSample />
        </div>
      </CardContainer>
      <CardContainer style={{ height: '140px', display: 'grid' }}>
        <CardTitle>공통 모달 예시</CardTitle>
        <hr />
        <div style={{ display: 'flex' }}>
          <BlueButton
            onClick={(e) => {
              openCommonModal({
                content: 'alert content',
              });
            }}
          >
            alert
          </BlueButton>
          <BlueLineButton
            onClick={(e) => {
              openCommonModal({
                modalType: 'confirm',
                content: 'confirm content',
                yesCallback: () => {
                  openCommonModal({ content: 'im applied!' });
                },
                noCallback: () => {
                  openCommonModal({ content: 'im cancelled!' });
                },
              });
            }}
          >
            confirm
          </BlueLineButton>
          <GreyButton
            onClick={(e) => {
              openCommonModal({
                modalType: 'confirm',
                showCallbackResult: true,
                title: 'confirm',
                content: '확인을 누르면, 결과 모달이 발생합니다.',
                yesCallback: () => {
                  return 'applied';
                },
              });
            }}
          >
            confirm after api output is string
          </GreyButton>
        </div>
      </CardContainer>
      <CardContainer style={{ minHeight: '200px', display: 'grid', height: 'fit-content' }}>
        <CardTitle>공통 팝업</CardTitle>
        <hr />
        <h1>사용자 팝업</h1>
        <BlueButton
          onClick={() => {
            setEmployeeModalOpen(true);
          }}
        >
          사용자 팝업
        </BlueButton>

        <EmployeeModal
          open={employeeModalOpen}
          close={() => setEmployeeModalOpen(false)}
          save={(employee) => {
            setSelectedEmployee(employee);
            setEmployeeModalOpen(false);
          }}
        />

        <div>
          선택한 사용자 <br />
          {selectedEmployee.map((item, index) => (
            <p key={index}>{item.empNm}</p>
          ))}
        </div>

        <hr />
        <h1>부서 조회 팝업</h1>
        <BlueButton
          onClick={() => {
            setDepartmentModalOpen(true);
          }}
        >
          부서 조회 팝업
        </BlueButton>

        {departmentModalOpen && (
          <DepartmentModal
            open={departmentModalOpen}
            close={() => setDepartmentModalOpen(false)}
            save={(department: Department) => {
              setSelectedDepartment(department);
            }}
          />
        )}
        <div>
          <div>선택한 부서 :{selectedDepartment?.deptNm}</div>
        </div>

        {/* 보험증권 검색 팝업 */}
        <hr />
        <h1>보험증권 검색 팝업</h1>
        <BlueButton
          onClick={() => {
            setInsuranceModalOpen(true);
          }}
        >
          보험증권 검색 팝업
        </BlueButton>

        <InsuranceSearchModal
          open={insuraceModalOpen}
          close={() => setInsuranceModalOpen(false)}
          save={() => console.log()}
        />
      </CardContainer>
      <hr />
      <CardContainer>
        <CardTitle>Mail 발송 Sample</CardTitle>
        <hr />
        <div>
          <MailSample />
        </div>
      </CardContainer>
      <CardContainer>
        <CardTitle>다국어테스트</CardTitle>
        <hr />
        <BlueButton
          style={{ width: '150px' }}
          onClick={async () => {
            const response = await multiLangTest();
            alert(response.toString());
          }}
        >
          다국어테스트
        </BlueButton>
      </CardContainer>
      <CardContainer>
        <CardTitle>공통 코드 조건 포함(OPT_VAL_CTN1~15) 조회</CardTitle>
        <hr />
        <BlueButton
          style={{ width: '150px' }}
          onClick={async () => {
            const condition = {
              cmnGrCd: 'COP_CD',
              optValCtn1: '',
              optValCtn2: 'E',
              optValCtn3: '',
            } as CommonCodeCondition;
            const response = await getCommonCodeNamesCondition(condition);
            alert(response.map((item) => item.cmnCdNm));
          }}
        >
          optValCtn2:E
        </BlueButton>
        <BlueButton
          style={{ width: '150px' }}
          onClick={async () => {
            const condition = {
              cmnGrCd: 'COP_CD',
            } as CommonCodeCondition;
            const response = await getCommonCodeNamesCondition(condition);
            alert(response.map((item) => item.cmnCdNm));
          }}
        >
          전체
        </BlueButton>
      </CardContainer>
      <hr />
    </div>
  );
};

const CardContainer = styled.div`
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.12);
  padding: 20px;
  align-content: space-around;
  hr {
    width: 100%;
  }
  justify-items: start;
`;

const CardTitle = styled.h3`
  font-size: 20px;
  font-weight: 500;
`;
export default SamplePage;
