/** @jsxImportSource @emotion/react */
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { findNoticePost, createNotice, deleteNotice, modifyNotice } from 'apis/admin/Notice';
import { BbsEmployee, NoticePostDetail } from 'models/admin/Notice';
import { FileInfo, FileSaveResponse, FileSaveResult } from 'models/admin/FileInfo';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { Code } from 'models/common/CommonCode';
import { useCommonModal } from 'hooks/useCommonModal';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import dayjs, { Dayjs } from 'dayjs';

import { ContentSection, GridInfoSection, GridTop } from 'components/layouts/ContentSection';
import { BlueButton, GreyButton, GreyLineButton } from 'components/buttons/CustomButton';
import { ViewTable } from 'components/tables/ViewTable';
import { CustomInputText } from 'components/inputs/CustomInput';
import DatePicker from 'components/inputs/DatePicker';
import CrossEditor from 'components/crosseditor/CrossEditor';
import { CustomSelect } from 'components/selects/CustomSelect';
import Dropzone from 'components/dropzone/Dropzone';
import { useTranslation } from 'react-i18next';
import FileGrid from 'components/dropzone/FileGrid';
import DOMPurify from 'dompurify';

const mockNoticeEmployee: BbsEmployee = {
  userId: '',
  empNm: '',
  deptNm: '',
  jtiNm: '',
  jpsNm: '',
  ofcTanoPhn: '',
  email: '',
};

const emptyPostDetail: NoticePostDetail = {
  bbmNo: '',
  dataInsUser: mockNoticeEmployee,
  dataInsDtm: '',
  dataUpdUser: mockNoticeEmployee,
  dataUpdDtm: '',
  bbsTpCd: 'NOTI',
  bbsTpNm: '공지',
  ptupTgtCopCd: 'C100',
  bbmTitNm: '',
  bbmCtn: '',
  atchFiles: [],
  atchFileGrId: '',
  ptupEndDt: dayjs().add(7, 'day').format('YYYYMMDD'),
  poupStDt: dayjs().format('YYYY-MM-DD'),
  poupEndDt: dayjs().add(7, 'day').format('YYYY-MM-DD'),
  poupStTm: dayjs().format('HH:mm:ss'),
  poupEndTm: dayjs().format('HH:mm:ss'),
  poupStDtm: dayjs().format('YYYY-MM-DD HH:mm:ss'),
  poupEndDtm: dayjs().add(7, 'day').format('YYYY-MM-DD HH:mm:ss'),
  poupEpsNuseDdn: '',
  bbmClsfCd: 'VENDOR',
};

interface NoticeEditProps {
  editType?: 'UPDATE' | 'CREATE' | 'SELECT';
  bbmNo?: string;
  bbmCtn?: string;
  atchFileGrId?: string;
}

const NoticeManagementDetailPage = () => {
  const { t } = useTranslation();
  // eslint-disable-next-line no-unsafe-optional-chaining
  const { editType, bbmNo, bbmCtn, atchFileGrId }: NoticeEditProps = useLocation()?.state || {
    editType: 'SELECT',
  };
  const [noticeDetail, setNoticeDetail] = useState<NoticePostDetail>(emptyPostDetail);
  const [ptupTgtCopCd, setPtupTgtCopCd] = useState<Code[]>([]);
  const [bbmClsfCd, setBbmClsfCd] = useState<Code[]>([]);
  const [refreshPtupEndDtKey, setRefreshPtupEndDtKey] = useState<number>(0);
  const [refreshPoupStDtKey, setRefreshPoupStDtKey] = useState<number>(0);
  const [refreshPoupEndDtKey, setRefreshPoupEndDtKey] = useState<number>(0);
  const editorRef = useRef<CrossEditor>(null);
  const navigate = useNavigate();
  const { openCommonModal } = useCommonModal();
  const dropzoneRef = useRef<any>();

  const returnByteLength = (str: string) => {
    let byteLength = 0;

    for (let i = 0; i < str.length; ++i) {
      str.charCodeAt(i) > 127 ? (byteLength += 3) : byteLength++;
    }

    return byteLength;
  };

  const handleOnChange = (e) => {
    setNoticeDetail({ ...noticeDetail, [e.target.id]: e.target.value });
  };

  const handleOnDelete = () => {
    openCommonModal({
      modalType: 'confirm',
      content: '게시글을 삭제하시겠습니까?',
      yesCallback: async () => {
        const response = await deleteNotice(noticeDetail.bbmNo);
        if (response != null)
          openCommonModal({
            content: '삭제되었습니다.',
            yesCallback: () => {
              navigate('/system/admin/sample/notice', { replace: true });
            },
          });
      },
    });
  };

  const handleOnCancel = () => {
    openCommonModal({
      modalType: 'confirm',
      content: '작성을 취소하시겠습니까? 작성된 내용이 사라집니다.',
      yesCallback() {
        navigate('/system/admin/sample/notice', { replace: true });
      },
    });
  };

  const handleOnConfirm = () => {
    navigate('/system/admin/sample/notice', { replace: true });
  };

  const handleEditerOnLoad = () => {
    if (editType == 'UPDATE' || editType == 'CREATE')
      editorRef.current?.editorRef.SetReadonly(false);
    if (bbmCtn && bbmCtn.length > 0) editorRef.current?.editorRef.SetBodyValue(bbmCtn);
  };

  const saveValidation = () => {
    if (noticeDetail.bbmTitNm === '') {
      openCommonModal({
        content: String(t('notice.alert.제목을 입력해주세요.', '__제목을 입력해주세요.')),
      });
      return false;
    }
    if (returnByteLength(noticeDetail.bbmTitNm) > 300) {
      openCommonModal({
        content: String(t('com.label.00469', '__제목은 300바이트를 초과할 수 없습니다.')),
      });
      return false;
    }
    if (editorRef.current?.editorRef.GetBodyValue() === '<p><br></p>') {
      openCommonModal({
        content: String(t('notice.alert.본문을 입력해주세요.', '본문을 입력해주세요.')),
      });
      return false;
    }
    if (noticeDetail.ptupEndDt === '') {
      openCommonModal({
        content: String(t('notice.alert.게시기한을 입력해주세요.', '__게시기한을 입력해주세요.')),
      });
      return false;
    }
    if (noticeDetail.poupStDt === '') {
      openCommonModal({
        content: String(
          t('notice.alert.팝업시작일을 입력해주세요.', '__팝업시작일을 입력해주세요.')
        ),
      });
      return false;
    }
    if (noticeDetail.poupStTm === '') {
      openCommonModal({
        content: String(
          t('notice.alert.팝업시작시간을 입력해주세요.', '__팝업시작시간을 입력해주세요.')
        ),
      });
      return false;
    }
    if (noticeDetail.poupEndDt === '') {
      openCommonModal({
        content: String(
          t('notice.alert.팝업종료일을 입력해주세요.', '__팝업종료일을 입력해주세요.')
        ),
      });
      return false;
    }
    if (noticeDetail.poupEndTm === '') {
      openCommonModal({
        content: String(
          t('notice.alert.팝업종료시간을 입력해주세요.', '__팝업종료시간을 입력해주세요.')
        ),
      });
      return false;
    }
    if (noticeDetail.poupEndTm === '') {
      openCommonModal({
        content: String(
          t('notice.alert팝업종료시간을 입력해주세요.', '__팝업종료시간을 입력해주세요.')
        ),
      });
      return false;
    }
    if (noticeDetail.poupEpsNuseDdn === '') {
      openCommonModal({
        content: String(
          t('notice.alert.다시 안보기 설정을 입력해주세요.', '__다시 안보기 설정을 입력해주세요.')
        ),
      });
      return false;
    }
    if (!/^[0-9]+$/.test(noticeDetail.poupEpsNuseDdn as string)) {
      openCommonModal({
        content: String(
          t(
            'notice.alert.다시 안보기 설정은 숫자만 입력 가능합니다.',
            '__다시 안보기 설정은 숫자만 입력 가능합니다.'
          )
        ),
      });
      return false;
    }
    return true;
  };

  const handleOnSave = async () => {
    if (!saveValidation()) return;

    const fileSaveResponse: FileSaveResponse = await dropzoneRef.current.saveFiles();
    if (fileSaveResponse.fileSaveResult == FileSaveResult.FAIL) return;
    const atchFileGrId = fileSaveResponse.atchFileGrId;

    const content = editorRef.current?.editorRef.GetBodyValue();
    let response;
    if (editType === 'CREATE') {
      response = await createNotice({
        ...noticeDetail,
        bbmCtn: content,
        atchFileGrId: atchFileGrId,
      });
    } else if (editType === 'UPDATE') {
      response = await modifyNotice({ ...noticeDetail, bbmCtn: content });
    }
    if (response != null)
      openCommonModal({
        content: '저장되었습니다.',
        yesCallback: () => {
          navigate('/system/admin/sample/notice', { replace: true });
        },
      });
  };

  useEffect(() => {
    const init = async () => {
      const noticePostDetail = await findNoticePost(bbmNo || '');
      noticePostDetail &&
        setNoticeDetail({
          ...noticePostDetail,
          poupStDt: noticePostDetail?.poupStDtm?.substring(0, 10),
          poupStTm: noticePostDetail?.poupStDtm?.substring(11),
          poupEndDt: noticePostDetail?.poupEndDtm?.substring(0, 10),
          poupEndTm: noticePostDetail?.poupEndDtm?.substring(11),
        });
    };
    if ((editType == 'SELECT' || editType == 'UPDATE') && bbmNo) init();

    (async function () {
      const bbmClsfCd = await getCommonCodeNames('BBM_CLSF_CD');
      if (bbmClsfCd != null) setBbmClsfCd(bbmClsfCd);
    })();
  }, []);

  return (
    <>
      <ContentSection className="marginT0">
        <ViewTable>
          <colgroup>
            <col width="13%" />
            <col />
            <col width="13%" />
            <col />
            <col width="13%" />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">
                <label>{t('notice.label.게시판분류', '__게시판분류')}</label>
              </th>
              <td>
                {editType === 'SELECT' ? (
                  noticeDetail.bbsTpNm
                ) : (
                  <CustomSelect
                    id="bbsTpCd"
                    value={noticeDetail.bbsTpCd}
                    onChange={handleOnChange}
                    style={{
                      pointerEvents: 'auto',
                    }}
                  >
                    <option key="NOTI" value="NOTI">
                      {t('notice.label.공지', '__공지')}
                    </option>
                  </CustomSelect>
                )}
              </td>
              <th scope="row">
                <label>{t('notice.label.게시글분류코드', '__게시글분류코드')}</label>
              </th>
              <td>
                {editType === 'SELECT' ? (
                  noticeDetail.bbmClsfCd
                ) : (
                  <CustomSelect
                    id="bbmClsfCd"
                    value={noticeDetail.bbmClsfCd}
                    onChange={handleOnChange}
                    style={{
                      pointerEvents: 'auto',
                    }}
                  >
                    {bbmClsfCd.map((key) => (
                      <option key={key.cmnCd} value={key.cmnCd}>
                        {key.cmnCdNm}
                      </option>
                    ))}
                  </CustomSelect>
                )}
              </td>
              <th scope="row">
                <label>{t('notice.label.게시기한', '__게시기한')}</label>
              </th>
              <td>
                {editType === 'SELECT' ? (
                  noticeDetail.ptupEndDt?.substring(0, 4) +
                  '-' +
                  noticeDetail.ptupEndDt?.substring(4, 6) +
                  '-' +
                  noticeDetail.ptupEndDt?.substring(6, 8)
                ) : (
                  <DatePicker
                    key={refreshPtupEndDtKey}
                    date={dayjs(noticeDetail.ptupEndDt as string)}
                    changeDate={(date: Dayjs) => {
                      if (
                        date.format('YYYY-MM-DD') < (noticeDetail.poupStDt as string) ||
                        date.format('YYYY-MM-DD') < (noticeDetail.poupEndDt as string)
                      ) {
                        openCommonModal({
                          content: String(
                            t(
                              'notice.alert.게시기한은 팝업시작일 혹은 팝업종료일보다 이후 날짜로 선택해주세요.',
                              '__게시기한은 팝업시작일 혹은 팝업종료일보다 이후 날짜로 선택해주세요.'
                            )
                          ),
                        });
                        setRefreshPtupEndDtKey((prevKey) => prevKey + 1);
                        return;
                      }
                      date &&
                        setNoticeDetail({ ...noticeDetail, ptupEndDt: date.format('YYYYMMDD') });
                    }}
                    mindate={dayjs().add(1, 'day')}
                  />
                )}
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label>{t('notice.label.제목', '__제목')}</label>
              </th>
              <td colSpan={5}>
                {editType === 'SELECT' ? (
                  noticeDetail.bbmTitNm
                ) : (
                  <CustomInputText
                    id="bbmTitNm"
                    className="fullWidth"
                    value={noticeDetail.bbmTitNm}
                    onChange={handleOnChange}
                    style={{
                      pointerEvents: 'auto',
                    }}
                  />
                )}
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label>{t('notice.label.본문', '__본문')}</label>
              </th>
              <td colSpan={5}>
                {editType === 'SELECT' ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(noticeDetail?.bbmCtn + ''),
                    }}
                  ></div>
                ) : (
                  <div>
                    <CrossEditor
                      ref={editorRef}
                      name="crosseditor"
                      params={{
                        Width: '100%',
                        Height: 500,
                        Chevron: true,
                        Readonly: true,
                      }}
                      value={noticeDetail.bbmCtn}
                      onLoaded={handleEditerOnLoad}
                    />
                  </div>
                )}
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label>{t('notice.label.첨부파일', '__첨부파일')}</label>
              </th>
              <td colSpan={5}>
                {editType === 'SELECT' ? (
                  <FileGrid atchFileGrId={atchFileGrId ?? ''} />
                ) : (
                  <Dropzone ref={dropzoneRef} atchFileGrIdInput={atchFileGrId} />
                )}
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label>{t('notice.label.Popup 시작일시', '__Popup 시작일시')}</label>
              </th>
              <td>
                {editType === 'SELECT' ? (
                  <div>
                    <span>{noticeDetail.poupStDt}</span> <span>{noticeDetail.poupStTm}</span>
                  </div>
                ) : (
                  <div>
                    <DatePicker
                      key={refreshPoupStDtKey}
                      date={dayjs(noticeDetail.poupStDt as string)}
                      changeDate={(date: Dayjs) => {
                        if (
                          date.format('YYYY-MM-DD') > (noticeDetail.poupEndDt as string) ||
                          date.format('YYYYMMDD') > (noticeDetail.ptupEndDt as string)
                        ) {
                          openCommonModal({
                            content: String(
                              t(
                                'notice.alert.팝업시작일은 게시기한 혹은 팝업종료일보다 이전 날짜로 선택해주세요.',
                                '__팝업시작일은 게시기한 혹은 팝업종료일보다 이전 날짜로 선택해주세요.'
                              )
                            ),
                          });
                          setRefreshPoupStDtKey((prevKey) => prevKey + 1);
                          return;
                        }
                        date &&
                          setNoticeDetail({
                            ...noticeDetail,
                            poupStDt: date.format('YYYY-MM-DD'),
                            poupStDtm: date.format('YYYY-MM-DD') + ' ' + noticeDetail.poupStTm,
                          });
                      }}
                      mindate="today"
                    />
                    <CustomInputText
                      type="time"
                      value={noticeDetail.poupStTm}
                      min="00:00:00"
                      max="23:59:59"
                      onChange={(e) => {
                        e.target.value &&
                          setNoticeDetail({
                            ...noticeDetail,
                            poupStTm: e.target.value,
                            poupStDtm: noticeDetail.poupStDt + ' ' + e.target.value,
                          });
                      }}
                    />
                  </div>
                )}
              </td>
              <th scope="row">
                <label>{t('notice.label.Popup 종료일시', '__Popup 종료일시')}</label>
              </th>
              <td>
                {editType === 'SELECT' ? (
                  <div>
                    <span>{noticeDetail.poupStDt}</span> <span>{noticeDetail.poupStTm}</span>
                  </div>
                ) : (
                  <div>
                    <DatePicker
                      key={refreshPoupEndDtKey}
                      date={dayjs(noticeDetail.poupEndDt as string)}
                      changeDate={(date: Dayjs) => {
                        if (
                          date.format('YYYY-MM-DD') < (noticeDetail.poupStDt as string) ||
                          date.format('YYYYMMDD') > (noticeDetail.ptupEndDt as string)
                        ) {
                          openCommonModal({
                            content: String(
                              t(
                                'notice.alert.팝업종료일은 게시기한 이전 혹은 팝업시작일 이후 날짜로 선택해주세요.',
                                '__팝업종료일은 게시기한 이전 혹은 팝업시작일 이후 날짜로 선택해주세요.'
                              )
                            ),
                          });
                          setRefreshPoupEndDtKey((prevKey) => prevKey + 1);
                          return;
                        }
                        date &&
                          setNoticeDetail({
                            ...noticeDetail,
                            poupEndDt: date.format('YYYY-MM-DD'),
                            poupEndDtm: date.format('YYYY-MM-DD') + ' ' + noticeDetail.poupEndTm,
                          });
                      }}
                      mindate="today"
                    />
                    <CustomInputText
                      type="time"
                      value={noticeDetail.poupEndTm}
                      min="00:00:00"
                      max="23:59:59"
                      onChange={(e) => {
                        e.target.value &&
                          setNoticeDetail({
                            ...noticeDetail,
                            poupEndTm: e.target.value,
                            poupEndDtm: noticeDetail.poupEndDt + ' ' + e.target.value,
                          });
                      }}
                    />
                  </div>
                )}
              </td>
              <th scope="row">
                <label>{t('notice.label.다시 안보기 설정', '__다시 안보기 설정')}</label>
              </th>
              <td>
                {editType === 'SELECT' ? (
                  noticeDetail.poupEpsNuseDdn
                ) : (
                  <CustomInputText
                    id="poupEpsNuseDdn"
                    value={noticeDetail.poupEpsNuseDdn ?? ''}
                    onChange={handleOnChange}
                    style={{
                      pointerEvents: 'auto',
                    }}
                  />
                )}
                {t('notice.label.일', '__일')}
              </td>
            </tr>
          </tbody>
        </ViewTable>
        <GridInfoSection className="contentEnd">
          <div className="gridbtns">
            {editType == 'SELECT' && (
              <GreyButton onClick={handleOnConfirm}>{t('common.button.확인', '_확인')}</GreyButton>
            )}
            {(editType == 'CREATE' || editType == 'UPDATE') && (
              <GreyButton onClick={handleOnCancel}>{t('common.button.취소', '_취소')}</GreyButton>
            )}
            {editType === 'UPDATE' && (
              <GreyLineButton onClick={handleOnDelete}>
                {t('common.button.삭제', '_삭제')}
              </GreyLineButton>
            )}
            {(editType == 'CREATE' || editType == 'UPDATE') && (
              <BlueButton onClick={handleOnSave}>{t('common.button.저장', '_저장')}</BlueButton>
            )}
          </div>
        </GridInfoSection>
      </ContentSection>
    </>
  );
};

export default NoticeManagementDetailPage;
