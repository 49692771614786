/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import { getEmployeeBySearchCondition } from 'apis/admin/Employee';
import DepartmentTree from 'pages/admin/departmentmanagement/DepartmentTree';
import { Employee } from 'models/admin/Employee';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Department } from 'models/admin/Department';
import ModalLayout from 'components/layouts/ModalLayout';
import { DialogTitleItem } from 'components/layouts/GModalPopup';
import { GPopupTitle } from 'components/layouts/GLayoutBox';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import GButtonLayout from 'components/molecule/GbuttonLayout';
import Search from 'components/asset/images/Search.svg';
import {
  GButtonBoxCum,
  SearchItemWrap,
  SearchWrap,
  UnHiddenSearchWrap,
} from 'components/molecule/SearchMolecule';
import SubTitleBox from 'components/molecule/SubTitleBox';
import { GContentGrid } from 'components/layouts/GContentGrid';
import RightChevron from 'components/asset/images/RightChevron.svg';
import LeftChevron from 'components/asset/images/LeftChevron.svg';
import Save from 'components/asset/images/Confirm.svg';
import { useColumnDefsForSearchPerson } from 'template/cims/modals/gridDef/SearchPersonDef';
import { UseColumnDefsForSearchPersonSelect } from 'template/cims/modals/gridDef/SearchPersonSelectDef';
import { useCommonModal } from 'hooks/useCommonModal';
import { useTranslation } from 'react-i18next';
import useSessionStore from 'stores/useSessionStore';
import { GButton } from 'components/atom/button';
import { DepartmentContext, defaultContext } from 'components/modals/common/EmployeeModal';
import SearchMolecule from 'components/common/SearchMolecule';

type Props = {
  open: boolean;
  close: () => void;
  save: (item: Employee[]) => void;
  singleSelect?: boolean;
  selectedEmployee?: Employee[];
};

const emptyDepartment: Department = {
  deptCd: '',
  coCd: '',
  deptNm: '',
  deptEngNm: '',
  temLdrUserId: '',
  upprDeptCd: '',
  useYn: '',
  deptCngNm: '',
};

const GEmployeeModal = (props: Props) => {
  const { t } = useTranslation();
  const { gridNoRowsTemplate } = useSessionStore();
  const gridRef = useRef<AgGridReact<Employee>>(null);
  const selectedEmpGridRef = useRef<AgGridReact<Employee>>(null);
  const { openCommonModal } = useCommonModal();
  const [employeeList, setEmployeeList] = useState<Employee[]>([]);
  const [selectedEmployee, setSelectedEmployee] = useState<Employee[]>([]);
  const [searchItem, setSearchItem] = useState<string>('');
  const [contextValue, setContextValue] = useState({
    ...defaultContext,
    handleSelectDepartment: (item) => setContextValue(item),
  });

  const { defaultColDef, colDefs } = useColumnDefsForSearchPerson();
  const { sDefaultColDef, sColDefs } = UseColumnDefsForSearchPersonSelect();
  const gridApiRef = useRef<any>(null);
  useEffect(() => {
    if (props.selectedEmployee) {
      setSelectedEmployee(props.selectedEmployee);
    }
  }, []);

  useEffect(() => {
    props.open && contextValue.selectedDepartment[0] && getEmployeeList();
  }, [contextValue]);

  const getEmployeeList = useCallback(async () => {
    const response = await getEmployeeBySearchCondition(
      searchItem,
      contextValue.selectedDepartment[0]?.deptCd
    );
    setEmployeeList(response);
  }, [contextValue, searchItem]);

  const handleOnClose = () => {
    setSelectedEmployee([]);
    props.close();
  };
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchItem(e.target.value);
  };

  const handleOnSelectionChange = useCallback(() => {
    if (props.singleSelect) {
      setSelectedEmployee(gridRef.current?.api.getSelectedRows() ?? []);
    }
  }, [employeeList]);

  const handleSearch = useCallback(() => {
    setContextValue({ ...contextValue, selectedDepartment: [emptyDepartment] });
    getEmployeeList();
  }, [searchItem]);

  const handleOnSave = () => {
    if (selectedEmployee && selectedEmployee.length <= 0) {
      openCommonModal({
        content: t('common.alert.한명 이상 선택해야 합니다.', '__한명 이상 선택해야 합니다.'),
      });
      return;
    }
    props.save(selectedEmployee);
    handleOnClose();
  };

  const handleInsertEmpList = () => {
    const selectedRows = gridRef.current?.api.getSelectedRows();
    if (selectedRows && selectedRows.length > 0) {
      const selectedUserIdList = selectedRows.map((row) => row.userId);

      const alreadyInsertedEmpList = selectedEmployee.map((emp) => {
        if (selectedUserIdList.includes(emp.userId)) {
          return emp.userId;
        }
      });

      const filteredRows = selectedRows.filter(
        (selectedRow) => !alreadyInsertedEmpList.includes(selectedRow.userId)
      );
      setSelectedEmployee((selectedEmpList) => [...selectedEmpList, ...filteredRows]);
    }
  };

  const handleExtractEmpList = () => {
    const selectedRows = selectedEmpGridRef.current?.api.getSelectedRows();
    if (selectedRows && selectedRows.length > 0) {
      const selectedUserIdList = selectedRows.map((row) => row.userId);
      setSelectedEmployee((selectedEmpList) =>
        selectedEmpList.filter((selectedEmp) => !selectedUserIdList.includes(selectedEmp.userId))
      );
    }
  };
  return (
    <ModalLayout open={props.open} onClose={handleOnClose} mMinWidth={1100} mMaxWidth={1200}>
      <DialogTitleItem id="customized-dialog-title" onClose={handleOnClose}>
        <GPopupTitle title2="인물검색" />
      </DialogTitleItem>
      <DialogContent className="popupContent">
        <SearchWrap>
          <UnHiddenSearchWrap>
            <SearchItemWrap>
              <SearchMolecule
                type="input"
                labelTitle="사용자"
                placeholder="사용자명이나 사용자ID를 입력하세요"
                searchWidth="100%"
                searchValueWidth="240px"
                textInputValue={searchItem}
                onChange={handleOnChange}
                // onKeyDown={(e) => {
                //   if (e.key === 'Enter') handleSearch();
                // }}
              />
            </SearchItemWrap>
            <GButtonLayout paddingTop="0px" marginBottom="0px">
              <GButtonBoxCum>
                <GButton
                  chkImg={Search}
                  txt="조회"
                  sizes="medium"
                  btnstyled="contained"
                  btnColor="normal"
                  hasImg={true}
                  hasTxt={true}
                  onClick={handleSearch}
                ></GButton>
              </GButtonBoxCum>
            </GButtonLayout>
          </UnHiddenSearchWrap>
        </SearchWrap>
        <Content>
          <TreeContent>
            <DepartmentContext.Provider value={contextValue}>
              <DepartmentTree totalCount={true} isRetrieveEnabled={props.open} />
            </DepartmentContext.Provider>
          </TreeContent>
          <GridContent>
            <SubTitleBox
              showSubTitle={true}
              content="전체 목록"
              isVisbleSum={true}
              commentCount={employeeList.length}
            />
            <GContentGrid
              type="check"
              isSortable={false}
              className="ag-theme-alpine"
              scrollHeight="26px"
              style={{ marginTop: '4px', flex: 1 }}
            >
              <AgGridReact
                defaultColDef={defaultColDef}
                columnDefs={colDefs}
                ref={gridRef}
                rowData={employeeList}
                rowSelection={props.singleSelect ? 'single' : 'multiple'}
                suppressRowClickSelection={true}
                onSelectionChanged={handleOnSelectionChange}
                overlayNoRowsTemplate={gridNoRowsTemplate}
                enableCellTextSelection
                onGridReady={(params) => (gridApiRef.current = params.api)}
                tooltipShowDelay={0}
                tooltipHideDelay={2000}
              ></AgGridReact>
            </GContentGrid>
          </GridContent>
          {!props.singleSelect && (
            <>
              <ButtonBox>
                <GButton
                  chkImg={RightChevron}
                  sizes="large"
                  btnstyled="outline"
                  btnColor="normal"
                  hasImg={true}
                  hasTxt={false}
                  onClick={handleInsertEmpList}
                />
                <GButton
                  chkImg={LeftChevron}
                  sizes="large"
                  btnstyled="outline"
                  btnColor="normal"
                  hasImg={true}
                  hasTxt={false}
                  onClick={handleExtractEmpList}
                />
              </ButtonBox>
              <GridContent>
                <SubTitleBox
                  showSubTitle={true}
                  content={t('com.label.00721', '__선택한 목록')}
                  isVisbleSum={true}
                  commentCount={selectedEmployee.length}
                />
                <GContentGrid
                  type="check"
                  isSortable={false}
                  className="ag-theme-alpine"
                  scrollHeight="26px"
                  style={{ marginTop: '4px', flex: 1 }}
                >
                  <AgGridReact
                    defaultColDef={sDefaultColDef}
                    columnDefs={sColDefs}
                    ref={selectedEmpGridRef}
                    rowData={selectedEmployee}
                    rowSelection={props.singleSelect ? 'single' : 'multiple'}
                    suppressRowClickSelection={true}
                    enableCellTextSelection
                    onGridReady={(params) => (gridApiRef.current = params.api)}
                    tooltipShowDelay={0}
                    tooltipHideDelay={2000}
                  ></AgGridReact>
                </GContentGrid>
              </GridContent>
            </>
          )}
        </Content>
      </DialogContent>
      <DialogActions className="popupBottom">
        <GButton
          txt="취소"
          sizes="medium"
          onClick={handleOnClose}
          btnstyled="outline"
          btnColor="normal"
          hasImg={false}
          hasTxt={true}
        />
        <GButton
          txt="선택"
          sizes="medium"
          onClick={handleOnSave}
          chkImg={Save}
          btnstyled="contained"
          btnColor="primary"
          hasImg={true}
          hasTxt={true}
        />
      </DialogActions>
    </ModalLayout>
  );
};

const Content = styled.div`
  display: flex;
  flex: 1;
  gap: 24px;
  height: 60vh;
`;

const TreeContent = styled.div`
  display: flex;
  width: 236px;
  flex-direction: column;
`;

const GridContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const ButtonBox = styled.div`
  display: flex;
  width: 36px;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
`;

export default GEmployeeModal;
