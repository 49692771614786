import closeIcon from '../../asset/images/Close.svg';
import styled from '@emotion/styled';

interface Props {
  onClick?: (e) => void;
}

const DelBtn = (props: Props) => {
  return (
    <Button onClick={props.onClick}>
      <img src={closeIcon} />
    </Button>
  );
};

const Button = styled.button`
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  padding: 0;
  cursor: pointer;
`;

export default DelBtn;
