import styled from '@emotion/styled';
import { DateView, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { CalendarIcon } from 'components/icons/Icons';
import 'dayjs/locale/ko';
import ErrorIcon from 'components/asset/images/error.svg';
import WarningIcon from 'components/asset/images/warning.svg';
import ConfirmedIcon from 'components/asset/images/confirmed.svg';

//TODO : form dir로 이동
export interface DatePickerProps {
  id?: string;
  date?: Dayjs | null;
  mindate?: Dayjs | 'today';
  maxdate?: Dayjs | 'today';
  changeDate?: (date: Dayjs, id: string) => void;
  readonly?: boolean;
  disabled?: boolean;
  opento?: DateView;
  views?: any;
  format?: string;
  width?: string;
  maxWidth?: string;
  minWidth?: string;
  status?: 'default' | 'error' | 'warning' | 'confirm' | 'grid';
  double?: boolean;
  startDate?: Dayjs | null;
  endDate?: Dayjs | null;
  doubleWidth?: string;
  isEssential?: boolean;
  isRequired?: boolean;
  inGrid?: boolean;
}

export default function GDatePicker({
  id,
  date,
  mindate = 'today',
  maxdate = 'today',
  changeDate,
  readonly = false,
  disabled = false,
  opento = 'day',
  views = ['year', 'month', 'day'],
  format = 'YYYY.MM.DD',
  width = '100%',
  minWidth = '110px',
  maxWidth = '100%',
  status = 'default',
  double = false,
  doubleWidth,
  startDate,
  endDate,
  isEssential = false,
  isRequired = false,
}: DatePickerProps) {
  const min = mindate == 'today' ? dayjs() : mindate;
  const max = maxdate == 'today' ? dayjs() : maxdate;

  return (
    <LocalizationProvider
      dateFormats={{ monthAndYear: 'YYYY년 MM월' }}
      dateAdapter={AdapterDayjs}
      adapterLocale="ko"
    >
      {double ? (
        <DoubleWrapper doubleWidth={doubleWidth}>
          <DatePickerCustom
            status={status}
            width={width}
            maxWidth={maxWidth}
            minWidth={minWidth}
            readOnly={readonly}
            disabled={disabled}
            value={startDate && startDate?.isValid() ? startDate : null}
            onChange={(value) => changeDate && changeDate(value as Dayjs, id ?? 'start')}
            minDate={min}
            maxDate={max}
            openTo={opento}
            views={views}
            format={format}
            slotProps={{ textField: { placeholder: format } }}
            slots={{ openPickerIcon: CalendarIcon }}
            isRequired={isRequired}
            isEssential={isEssential}
          />
          -
          <DatePickerCustom
            status={status}
            width={width}
            maxWidth={maxWidth}
            minWidth={minWidth}
            readOnly={readonly}
            disabled={disabled}
            value={endDate && endDate?.isValid() ? endDate : null}
            onChange={(value) => changeDate && changeDate(value as Dayjs, id ?? 'end')}
            minDate={min}
            maxDate={max}
            openTo={opento}
            views={views}
            format={format}
            slotProps={{ textField: { placeholder: format } }}
            slots={{ openPickerIcon: CalendarIcon }}
            isRequired={isRequired}
            isEssential={isEssential}
          />
        </DoubleWrapper>
      ) : (
        <DatePickerCustom
          status={status}
          width={width}
          maxWidth={maxWidth}
          minWidth={minWidth}
          readOnly={readonly}
          disabled={disabled}
          value={date && date?.isValid() ? date : null}
          onChange={(value) => changeDate && changeDate(value as Dayjs, id ?? '')}
          minDate={min}
          maxDate={max}
          openTo={opento}
          views={views}
          format={format}
          slotProps={{ textField: { placeholder: format } }}
          slots={{ openPickerIcon: CalendarIcon }}
          isRequired={isRequired}
          isEssential={isEssential}
        />
      )}
    </LocalizationProvider>
  );
}

const DatePickerCustom = styled(DatePicker)<DatePickerProps>`
  position: relative;
  width: ${(props) => props.width};
  min-width: ${(props) =>
    props.views && props.views.length === 1 && props.views[0] === 'year' ? '96px' : '128px'};

  &::before {
    ${(props) =>
      props.isEssential
        ? `
        content:"";
        position: absolute;
        top: 2px;
        left: 2px;
        width: 4px;
        height: 4px;
        border-radius: 2px;
        background-color: #F94B50;
        z-index: 10;
    `
        : ``}
  }

  &::after {
    ${(props) => props.status === 'error' && `content: url('${ErrorIcon}');`}
    ${(props) => props.status === 'warning' && `content: url('${WarningIcon}');`}
    ${(props) => props.status === 'confirm' && `content: url('${ConfirmedIcon}');`}
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translate(0, -50%);
    width: 16px;
    height: 16px;
    background-color: #fff;
    cursor: pointer;
  }

  .MuiSvgIcon-root {
    width: 24px;
    height: 24px;
  }

  .MuiOutlinedInput-input {
    margin-left: 0;
    padding: 0 8px 0 27px;
    font-size: 13px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  .MuiFormControl-root.MuiTextField-root {
    position: relative;
  }

  .MuiInputBase-root.MuiOutlinedInput-root {
    max-width: ${(props) => props.maxWidth};
    padding-right: 0;
    font-size: 13px;
  }

  .MuiOutlinedInput-root {
    height: 32px;
    width: 100%;
    border: 1px solid #b9bcbb;
    border-radius: 2px;
    color: #1f1f1f;
    font-weight: inherit;
    background-color: #fff;
    ${(props) => props.status == 'default' && `border: 1px solid #b9bcbb;`}
    ${(props) => props.status == 'error' && `border: 1px solid #FDA293;`}
  ${(props) => props.status == 'warning' && `border: 1px solid #FFCB7A;`}
  ${(props) => props.status == 'confirm' && `border: 1px solid #56D8AA;`}
  ${(props) => props.status == 'grid' && `border: none; background-color: transparent;`}
  }

  .MuiOutlinedInput-root.Mui-focused {
    border-color: #5b5c5d !important;
  }

  .ag-cell & .MuiOutlinedInput-root {
    height: 23px;
  }

  .MuiInputAdornment-root {
    margin-left: 0;
  }

  .MuiIconButton-root {
    position: absolute;
    left: 0;
    padding: 2px 0px 0px 4px;
  }

  .MuiInputBase-readOnly {
    background: #f1f4f3;
  }

  .MuiOutlinedInput-notchedOutline {
    position: block;
    padding: 0;
    border-style: none;
    border-width: none;
  }
`;

const DoubleWrapper = styled.div<DatePickerProps>`
  width: ${(props) => props.doubleWidth};
  gap: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
