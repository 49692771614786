import { styled } from '@mui/system';
import { Tabs } from '@mui/base/Tabs';
import { TabsList as BaseTabsList } from '@mui/base/TabsList';
import { TabPanel as BaseTabPanel } from '@mui/base/TabPanel';
import { Tab as BaseTab, tabClasses } from '@mui/base/Tab';
import tabBottomBorder from 'components/asset/images/tabBottomBorder.png';
import React, { useState } from 'react';

interface TabProps {
  title?: string[];
  defaultValue?: number;
  children: React.ReactNode;
  flex?: boolean;
  value?: number;
  onChange?: (e, value) => void;
}

const GTabs = ({ children, title, defaultValue, flex, onChange, value }: TabProps) => {
  return (
    <TabWrap defaultValue={defaultValue} flex={flex} onChange={onChange} value={value}>
      <TabsList>
        {title &&
          title.map((tabTitle, index) => (
            <Tab key={index + 1} value={index + 1}>
              {tabTitle}
            </Tab>
          ))}
      </TabsList>
      {React.Children.toArray(children) &&
        React.Children.toArray(children).map((item, index) => (
          <TabPanel key={index + 1} value={index + 1} flex={flex}>
            {/* {React.Children.toArray(children)[index]} */}
            {item}
          </TabPanel>
        ))}
    </TabWrap>
  );
};

const TabWrap = styled(Tabs)<{ flex: boolean | undefined }>`
  height: 100%;
  ${(props) =>
    props.flex &&
    `
    display : flex;
    flex-direction: column;
    flex : 1;
  `}

  .MuiTabPanel-root.MuiTabPanel-hidden {
    display: none;
  }
`;

const Tab = styled(BaseTab)`
  background-color: #f1f4f3;
  color: #5b5c5b;
  cursor: pointer;
  font-weight: bold;
  width: auto;
  padding: 8px 12px;
  border: 1px solid #b9bcbb;
  display: flex;
  justify-content: center;
  font-size: 13px;
  font-weight: 400;
  font-family: 'Spoqa Han Sans Neo';
  margin-left: -1px;

  &.${tabClasses.selected} {
    color: #2d9bb2;
    background-color: #fff;
    border-bottom: none;
  }
`;

const TabPanel = styled(BaseTabPanel)`
  width: 100%;
  margin-top: 12px;

  ${(props) =>
    props.flex &&
    `
    display : flex;
    flex-direction: column;
    flex : 1;
  `}
`;

const TabsList = styled(BaseTabsList)`
  background-image: url(${tabBottomBorder});
  background-repeat: repeat-x;
  display: flex;
  background-position-y: bottom;
  padding-left: 1px;
  height: 36px;
`;

export default GTabs;
