import { ColDef } from 'ag-grid-community';
import { DefaultCellTooltip } from 'components/grids/tooltip/DefaultCellTooltip';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import radioIcon from 'components/asset/RadioColumn.svg';

export const useBankNumberColumnDef = () => {
  const { t } = useTranslation();
  const defaultColDef = useMemo((): ColDef => {
    return {
      sortable: false,
      resizable: true,
      cellStyle: { textAlign: 'left' },
      tooltipComponent: DefaultCellTooltip,
    };
  }, []);

  const colDefs: any = [
    {
      headerName: 'radio',
      headerComponentParams: {
        template: `<img src=${radioIcon} alt=''/>`,
      },
      width: '28px !important',
      height: '24px !important',
      padding: '2px 6px',
      resizable: false,
      headerCheckboxSelection: true,
      showDisabledCheckboxes: true,
      checkboxSelection: true,
      flex: 0,
      cellStyle: {
        display: 'flex',
        justifyContent: 'center',
        width: '28px !important',
        height: '24px',
      },
    },
    {
      headerName: t('com.label.00478', '__개설은행'),
      headerTooltip: t('com.label.00478', '__개설은행'),
      field: 'bankCode',
      tooltipField: 'bankCode',
      width: '200px',
      minWidth: 200,
    },
    {
      headerName: t('com.label.00105', '__은행이름'),
      headerTooltip: t('com.label.00105', '__은행이름'),
      field: 'bankName',
      tooltipField: 'bankName',
      flex: 1,
      minWidth: 700,
    },
  ];

  const handleAprvDetailBtn = (data) => {
    console.log('화면 연결이 필요합니다.');
  };

  return { defaultColDef, colDefs };
};
