import { useCallback, useMemo } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import _ from 'lodash';

import { useMessageModalStore } from 'stores/useMessageModalStore';

export interface IMessageCellRendererParams extends ICellRendererParams {
  showButton?: (() => boolean) | boolean;
  noCallback?: () => void;
  yesCallback?: (item?: string) => void;
  msgCtn?: string;
  koMessage?: string;
}

export const MessageCellRenderer = ({
  showButton,
  noCallback,
  yesCallback,
  msgCtn,
  koMessage,
  ...params
}: IMessageCellRendererParams) => {
  const { setMessageModalStateWhenModalOpen } = useMessageModalStore();
  const cellValue = params.valueFormatted ? params.valueFormatted : params.value;

  const enableButton = useMemo(() => {
    if (typeof showButton === 'boolean') {
      return showButton;
    }
    if (typeof showButton === 'function') {
      return showButton();
    }
    return true;
  }, [showButton, params.data, params.value, params.valueFormatted, params.colDef]);

  const handleButtonClick = useCallback(() => {
    setMessageModalStateWhenModalOpen(
      msgCtn ?? cellValue,
      koMessage ?? '',
      yesCallback,
      noCallback
    );
  }, [cellValue, yesCallback, noCallback]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          display: 'inline-block',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <span>{cellValue}</span>
      </div>
      {enableButton && (
        <button
          onClick={handleButtonClick}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            width: '28px',
            // height: '28px',

            border: 'none',
            borderRadius: '15px',
            backgroundColor: 'transparent',
          }}
        >
          <img src={`${process.env.PUBLIC_URL}/assets/icon/ic-popup.svg`} alt="language button" />
        </button>
      )}
    </div>
  );
};
