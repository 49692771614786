/** @jsxImportSource @emotion/react */

import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Accept, useDropzone } from 'react-dropzone';
import './Dropzone.css';
import { v4 as uuidv4 } from 'uuid';
import {
  downloadAllFiles,
  findFiles,
  modifyFiles,
  modifyFilesWithNewGroupId,
  uploadFileWithNewGroupId,
  uploadFiles,
} from 'apis/admin/FileUpload';
import { FileInfo, FileSaveResponse, FileSaveResult } from 'models/admin/FileInfo';
import axios from 'axios';
import { GreyLineButton, IconLineButton } from 'components/buttons/CustomButton';
import { CustomInputText } from 'components/inputs/CustomInput';
import RemoveIcon from '@mui/icons-material/Remove';
import { CommonYN } from 'models/common/Common';
import { ViewTable } from 'components/tables/ViewTable';
import { CrudCode } from 'models/common/Edit';
import SearchIcon from '@mui/icons-material/Search';
import { css } from '@emotion/react';
import { BorderColor, FontColor, BgColor } from 'ui/theme/Color';
import { Link } from 'react-router-dom';

interface DropzoneProps {
  atchFileGrIdInput?: string;
  acceptExtensions?: Accept;
  showAllDownload?: boolean;
  newGrId?: boolean;
  atchFileTpCd?: string;
}

// eslint-disable-next-line react/display-name
const Dropzone = forwardRef(
  (
    {
      atchFileGrIdInput,
      acceptExtensions,
      showAllDownload = false,
      newGrId,
      atchFileTpCd = '',
    }: DropzoneProps,
    ref: React.Ref<any>
  ) => {
    const { t } = useTranslation();
    const [files, setFiles] = useState<FileInfo[]>([]);
    const [atchFileGrId, setAtchFileGrId] = useState(uuidv4());

    const saveFiles = async (newGrId2?: boolean) => {
      const newGroupId = uuidv4(); // 다른 파일그룹 아이디에 저장할 때만 사용
      const response = {
        atchFileGrId: '',
        fileSaveResult: FileSaveResult.NONE,
      } as FileSaveResponse;
      if (files.length == 0) return response;

      const insertFormData = new FormData();
      const updateFiles: FileInfo[] = [];

      let strFileSortOrds = '';

      files.forEach((fileInfo) => {
        if (fileInfo.crudKey === CrudCode.CREATE) {
          insertFormData.append('files', fileInfo.file as Blob);
          strFileSortOrds += `${fileInfo.sortOrd},`;
        } else if (fileInfo.crudKey === CrudCode.UPDATE) {
          updateFiles.push(fileInfo);
        }
      });

      let result: any = null;
      if (insertFormData.has('files')) {
        insertFormData.append('atchFileGrId', atchFileGrId);
        insertFormData.set('fileSortOrds', strFileSortOrds.slice(0, -1));
        insertFormData.append('atchFileTpCd', atchFileTpCd);
        if (!(newGrId || newGrId2)) result = await uploadFiles(insertFormData);
        else {
          insertFormData.append('oldAtchFileGrId', atchFileGrId);
          insertFormData.append('newAtchFileGrId', newGroupId);
          result = await uploadFileWithNewGroupId(insertFormData);
        }
      }
      if (updateFiles.length > 0) {
        if (!(newGrId || newGrId2)) result = await modifyFiles(updateFiles);
        else result = await modifyFilesWithNewGroupId(newGroupId, updateFiles);
      }
      if (result || files.length > 0) {
        response.atchFileGrId = newGrId || newGrId2 ? newGroupId : files[0].atchFileGrId;
        response.fileSaveResult = FileSaveResult.SUCCESS;
      } else {
        response.fileSaveResult = FileSaveResult.FAIL;
      }
      retrieveFiles();
      return response;
    };

    const retrieveFiles = () => {
      findFiles(atchFileGrId).then((result: FileInfo[]) => {
        result.map((files) => ({ ...files, newYn: false }));
        setFiles(result);
      });
    };

    const handleDownload = async () => {
      await downloadAllFiles(atchFileGrId);
    };

    const onDropHandler = useCallback(
      (acceptedFiles: File[]) => {
        const newFiles: FileInfo[] = acceptedFiles.map((acceptedFile) => {
          return {
            atchFileGrId: atchFileGrId,
            atchFileId: uuidv4(),
            atchFileNm: acceptedFile.name,
            sortOrd: '',
            atchFileSaveLocDivsCd: '',
            atchFileSaveNm: acceptedFile.name,
            atchFileSize: acceptedFile.size,
            atchFileEfnmNm: '',
            atchFileSavePathCtn: '',
            atchFileTpCd: '',
            optValCtn1: '',
            optValCtn2: '',
            optValCtn3: '',
            optValCtn4: '',
            optValCtn5: '',
            useYn: CommonYN.Y,
            newYn: true,
            crudKey: CrudCode.CREATE,
            file: acceptedFile,
          };
        });

        setFiles((prev) => [...prev, ...newFiles]);
      },
      [atchFileGrId]
    );

    const deleteFileHandler = useCallback((atchFileId: string) => {
      setFiles((prev) =>
        prev.map((file) =>
          file.atchFileId === atchFileId
            ? { ...file, useYn: CommonYN.N, sortOrd: '', crudKey: CrudCode.UPDATE }
            : file
        )
      );
    }, []);

    const deleteCancelHandler = useCallback((atchFileId: string) => {
      setFiles((prev) =>
        prev.map((file) => {
          if (file.newYn) {
            return file.atchFileId === atchFileId
              ? { ...file, useYn: CommonYN.Y, crudKey: CrudCode.CREATE }
              : file;
          } else {
            return file.atchFileId === atchFileId
              ? { ...file, useYn: CommonYN.Y, crudKey: CrudCode.READ }
              : file;
          }
        })
      );
    }, []);

    const handleSortOrdChange = (key, newValue) => {
      newValue = newValue.replace(/[^-0-9]/g, '');
      setFiles((prev) =>
        prev.map((file) =>
          file.atchFileId === key
            ? {
                ...file,
                crudKey: file.crudKey == CrudCode.CREATE ? CrudCode.CREATE : CrudCode.UPDATE,
                sortOrd: newValue,
              }
            : file
        )
      );
    };

    const handleDownloadFile = async (file: FileInfo) => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/v1/file/download?atchFileGrId=${file.atchFileGrId}&atchFileId=${file.atchFileId}`,
          {
            responseType: 'blob',
          }
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file.atchFileNm);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } catch (error) {
        console.log(error);
      }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      noClick: false,
      onDrop: onDropHandler,
      accept: acceptExtensions,
      maxSize: 10485760, // 10MB
    });

    useImperativeHandle(ref, () => ({
      saveFiles: async (newGrId2?: boolean) => {
        const fileSaveResponse: FileSaveResponse = await saveFiles(newGrId2);
        return fileSaveResponse;
      },
      getFileCount: () => {
        return files.length;
      },
    }));

    useEffect(() => {
      if (atchFileGrIdInput && atchFileGrIdInput.length > 0) {
        setAtchFileGrId(atchFileGrIdInput);
        findFiles(atchFileGrIdInput).then((result: FileInfo[]) => {
          result.map((files) => ({ ...files, newYn: false }));
          setFiles(result);
        });
      }
    }, [atchFileGrIdInput]);

    return (
      <div>
        {files && files.length > 0 && (
          <ViewTable>
            <colgroup>
              <col width="130px" />
              <col />
              <col width="130px" />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">{t('com.label.00689', '__정렬순번')}</th>
                <th scope="col">{t('com.label.00690', '__파일명')}</th>
                <th scope="col">
                  {showAllDownload && (
                    <GreyLineButton className="small" onClick={handleDownload}>
                      {t('com.label.00691', '__All Download')}
                    </GreyLineButton>
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {files.map((file, idx) => (
                <tr key={file.atchFileId}>
                  <td>
                    <CustomInputText
                      id="sortOrd"
                      className="fullWidth alignC"
                      value={file.sortOrd || ''}
                      onChange={(e) => {
                        handleSortOrdChange(file.atchFileId, e.target.value);
                      }}
                      disabled={file.useYn === CommonYN.N}
                    />
                  </td>
                  <td colSpan={2}>
                    <div key={file.atchFileId} className="file-item">
                      {file.useYn === CommonYN.N ? (
                        <Link to="#" className="link" key={file.atchFileId}>
                          <del>
                            {file.atchFileNm}
                            <span className="file-size">
                              {` [${(file.atchFileSize / 1024).toFixed(2)} KB ]`}
                            </span>
                          </del>
                          <GreyLineButton
                            onClick={() => deleteCancelHandler(file.atchFileId)}
                            className="small"
                          >
                            {t('com.label.00692', '__삭제취소')}
                          </GreyLineButton>
                        </Link>
                      ) : (
                        <div className="file-info" key={file.atchFileId}>
                          <Link to="#" className="link" onClick={() => handleDownloadFile(file)}>
                            {file.atchFileNm}
                            <span className="file-size">
                              {` [${(file.atchFileSize / 1024).toFixed(2)} KB ]`}
                            </span>
                          </Link>
                          <IconLineButton onClick={() => deleteFileHandler(file.atchFileId)}>
                            <RemoveIcon className="xs"></RemoveIcon>
                          </IconLineButton>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </ViewTable>
        )}
        <div {...getRootProps()} css={st.root}>
          <input {...getInputProps()} css={st.input} />
          <label
            css={st.inputLabel}
            htmlFor="input-file-upload"
            className={isDragActive ? 'drag-active' : ''}
          >
            <SearchIcon />
            <p>Drag and Drop file here</p>
          </label>
        </div>
      </div>
    );
  }
);
const st = {
  root: css`
    height: 100%;
    min-height: 100px;
    width: 100%;
    text-align: center;
    position: relative;
    border: solid 1px ${BorderColor.Primary};
  `,
  input: css`
    display: none;
  `,
  inputLabel: css`
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-style: dashed;
    background-color: transparent;

    svg {
      margin-right: 5px;
      fill: ${FontColor.Gray300};
    }

    p {
      color: ${FontColor.Gray400};
      letter-spacing: -0.32px;
      font-size: 13px;
      font-weight: 400;
      transition: 0.3s;
    }

    &.drag-active p {
      color: ${BgColor.Gray300};
      transition: 0.3s;
    }
  `,
  element: css`
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  `,
};

export default Dropzone;
