import styled from '@emotion/styled';
import NoticeError from 'components/asset/NoticeError.svg';
import NoticeConfirmed from 'components/asset/NoticeConfirmed.svg';
import NoticeWarning from 'components/asset/NoticeWarning.svg';

interface PropsType {
  type: 'success' | 'error' | 'warning' | undefined;
  children: React.ReactNode;
}

const NoticeBox = ({ type, children }: PropsType) => {
  return (
    <NoticeBoxWrap type={type}>
      <img
        src={`${
          type === 'success' ? NoticeConfirmed : type === 'error' ? NoticeError : NoticeWarning
        }`}
      ></img>
      <ChildrenWrap>{children}</ChildrenWrap>
    </NoticeBoxWrap>
  );
};

const NoticeBoxWrap = styled.div<PropsType>`
  color: #1f1f1f;
  padding: 12px;
  display: flex;
  align-items: flex-start;
  border-radius: 2px;
  background-color: ${(props) =>
    props.type === 'success'
      ? `rgba(220, 251, 234, 1)`
      : props.type === 'error'
      ? `rgba(254, 230, 219, 1)`
      : `rgba(255, 248, 205, 1)`};
`;

const ChildrenWrap = styled.div`
  margin-left: 12px;
`;

export const NoticeText = styled.p`
  font-size: 12px;
  color: #1f1f1f;
`;

export const NoticeList = styled.ul``;

export const Item = styled.li`
  font-size: 12px !important;
  align-items: center;
  display: flex;

  &::before {
    content: '';
    display: inline-block;
    width: 3px;
    height: 3px;
    background-color: rgba(151, 153, 152, 1);
    border-radius: 50%;
    margin-right: 13px;
    margin-bottom: 2px;
  }
`;

export const LinkText = styled.a`
  font-size: 12px;
  color: rgba(0, 128, 106, 1);
  text-decoration-line: underline;
  text-decoration-color: rgba(0, 128, 106, 1);
  margin-left: 4px;
`;

export default NoticeBox;
