import styled from '@emotion/styled';
import Alert from '@mui/material/Alert';
import AlertConfirmed from '../../asset/images/AlertConfirmed.svg';
import AlertError from '../../asset/images/AlertError.svg';
import AlertClose from '../../asset/images/AlertCloseButton.svg';

interface Props {
  type: 'success' | 'error' | 'info' | undefined;
  message: string;
}

const MessageAlert = (prop: Props) => {
  return (
    <AlertBox
      severity={prop.type}
      action={<Button />}
      iconMapping={{
        success: <img src={AlertConfirmed} />,
        error: <img src={AlertError} />,
        info: <img src="" />,
      }}
    >
      {prop.message}
    </AlertBox>
  );
};

const AlertBox = styled(Alert)`
  padding: 10.5px 16px;
  display: inline-flex;
  border-radius: 3px;
  max-width: 600px;

  box-shadow: 0px 4px 5px 0px #0000001c;

  & .MuiAlert-icon {
    padding: 0;
    margin-right: 8px;

    > img {
      width: 20px;
      height: 20px;
    }
  }

  &.MuiAlert-standardError {
    background-color: #fee6db;
  }

  &.MuiAlert-standardSuccess {
    background-color: #dcfbea;
  }

  &.MuiAlert-standardInfo {
    background-color: #3c3e3d;

    .MuiAlert-message {
      color: #fff;
      font-weight: 300;
    }
    .MuiAlert-icon {
      display: none;
    }
    button::before {
      filter: brightness(0) invert(1);
    }
  }

  & .MuiAlert-message {
    color: #1f1f1f;
    font-weight: 400;
    font-size: 13px;
    padding: 0;
    word-break: break-all;
    word-wrap: break-word;
  }

  & .MuiAlert-action {
    padding: 0;
    margin: 0 0 0 8px;
    height: 20px;
  }
`;

const Button = styled.button`
  background-color: transparent;
  width: 20px;
  height: 20px;

  ::before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url(${AlertClose}) center center no-repeat;
  }
`;

export default MessageAlert;
