import { ColDef } from 'ag-grid-community';
import { DefaultCellTooltip } from 'components/grids/tooltip/DefaultCellTooltip';
import { addCommasFor } from 'components/vars/common/FormatNumber';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useOPaymentDueAdColDef = () => {
  const { t } = useTranslation();
  const defaultColDef2 = useMemo((): ColDef => {
    return {
      sortable: false,
      resizable: true,
      cellStyle: { textAlign: 'center' },
      tooltipComponent: DefaultCellTooltip,
    };
  }, []);

  const colDefs2: any = [
    {
      headerName: t('com.label.00628', '__Document #'),
      headerTooltip: t('com.label.00628', '__Document #'),
      field: 'slipNo',
      tooltipField: 'slipNo',
      minWidth: 100,
      flex: 1,
    },
    {
      headerName: t('com.label.00629', '__Document Date'),
      headerTooltip: t('com.label.00629', '__Document Date'),
      field: 'prfDt',
      tooltipField: 'prfDt',
      minWidth: 130,
      flex: 1,
    },
    {
      headerName: t('com.label.00637', '__Net Due Date'),
      headerTooltip: t('com.label.00637', '__Net Due Date'),
      field: 'expDt',
      tooltipField: 'expDt',
      minWidth: 120,
      flex: 1,
    },
    {
      headerName: t('com.label.00638', '__Currency'),
      headerTooltip: t('com.label.00638', '__Currency'),
      field: 'curr',
      tooltipField: 'curr',
      minWidth: 70,
      flex: 1,
    },
    {
      headerName: t('com.label.00631', '__Amount'),
      headerTooltip: t('com.label.00631', '__Amount'),
      field: 'payAmt',
      tooltipField: 'payAmt',
      cellStyle: { textAlign: 'right' },
      valueFormatter: (params) => addCommasFor(params.value),
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: t('com.label.00639', '__Payment Method'),
      headerTooltip: t('com.label.00639', '__Payment Method'),
      field: 'payDivNm',
      tooltipField: 'payDivNm',
      minWidth: 230,
      flex: 1,
    },
    {
      headerName: t('com.label.00632', '__Invoice No.'),
      headerTooltip: t('com.label.00632', '__Invoice No.'),
      field: 'invcNo',
      tooltipField: 'invcNo',
      minWidth: 230,
      flex: 1,
    },
    {
      headerName: t('com.label.00633', '__Reference No.'),
      headerTooltip: t('com.label.00633', '__Reference No.'),
      field: 'rfrnNo',
      tooltipField: 'rfrnNo',
      minWidth: 230,
      flex: 1,
    },
    {
      headerName: t('com.label.00634', '__Department'),
      headerTooltip: t('com.label.00634', '__Department'),
      field: 'mgrDeptNm',
      tooltipField: 'mgrDeptNm',
      minWidth: 200,
      flex: 1,
    },
    {
      headerName: t('com.label.00635', '__Purchase Order'),
      headerTooltip: t('com.label.00635', '__Purchase Order'),
      field: 'purcOrd',
      tooltipField: 'purcOrd',
      minWidth: 120,
      flex: 1,
    },
  ];

  return { defaultColDef2, colDefs2 };
};
