import styled from '@emotion/styled';
import { useCommonModal } from 'hooks/useCommonModal';
import { Department } from 'models/admin/Department';
import { createContext, useRef, useState, useEffect } from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { GPopupTitle } from 'components/layouts/GLayoutBox';
import { DialogTitleItem } from 'components/layouts/GModalPopup';
import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import { GButton } from 'components/atom/button';
import Save from 'components/asset/images/Confirm.svg';
import SearchMolecule, {
  GButtonBoxCum,
  SearchItemWrap,
  SearchWrap,
  UnHiddenSearchWrap,
} from 'components/molecule/SearchMolecule';
import GButtonLayout, { GButtonBox } from 'components/molecule/GbuttonLayout';
import Search from 'components/asset/images/Search.svg';
import SubTitleBox from 'components/molecule/SubTitleBox';
import RightChevron from 'components/asset/images/RightChevron.svg';
import LeftChevron from 'components/asset/images/LeftChevron.svg';
import { GContentGrid } from 'components/layouts/GContentGrid';
import { SampleResponse } from 'models/sample/Sample';
import { useLoading } from 'components/process/Loading';
import { useMessageBar } from 'components/process/MessageBar';
import { getSamples } from 'apis/sample/SampleApi';
import { useColumnDefsForSearchChangePerson } from './gridDef/SearchChangePerson';
import { useColumnDefsForSearchChangePersonSelect } from './gridDef/SearchChangePersonSelect';
import ModalLayout from 'components/layouts/ModalLayout';

type DepartmentModalProps = {
  open: boolean;
  singleSelect?: boolean;
  close: () => void;
  save: () => void;
};

const SearchPerson = (props: DepartmentModalProps) => {
  const gridApiRef = useRef<any>(null);
  const [sampleList, setSampleList] = useState<SampleResponse[]>([]);
  const { defaultColDef, colDefs } = useColumnDefsForSearchChangePerson();
  const { sDefaultColDef, sColDefs } = useColumnDefsForSearchChangePersonSelect();
  const { openLoading } = useLoading();
  const { openMessageBar } = useMessageBar();
  const { t } = useTranslation();
  const gridRef = useRef<AgGridReact<Department>>(null);
  const { openCommonModal } = useCommonModal();

  useEffect(() => {
    loadSampleList();
  }, []);

  const makeSearchParams = () => {
    return {
      start: '0',
      pageSize: 30,
    };
  };

  const handleError = (e: Error) => {
    openLoading(false);
    openMessageBar('error', e.message);
  };

  const loadSampleList = async () => {
    getSamples(makeSearchParams())
      .then((data) => {
        setSampleList(data.list);
      })
      .catch(handleError);
  };

  const handleClose = () => {
    props.close();
  };

  const handleOnSave = () => {
    const selectedRows = gridRef.current?.api.getSelectedRows() || [];
    if (selectedRows.length === 0) {
      openCommonModal({
        modalType: 'alert',
        content: t(
          'department-choice-popup.alert.한 부서 이상 선택해야 합니다.',
          '__선택된 행이 없습니다.'
        ),
      });
      return;
    }
    props.close();
  };

  return (
    <ModalLayout open={props.open} onClose={handleClose} mMinWidth={1200} mMaxWidth={1200}>
      <DialogTitleItem id="customized-dialog-title" onClose={handleClose}>
        <GPopupTitle title2="담당자 검색" />
      </DialogTitleItem>
      <DialogContent className="popupContent">
        <SearchWrap>
          <UnHiddenSearchWrap>
            <SearchItemWrap>
              <SearchMolecule
                type="input"
                inputStatus="search"
                labelTitle="담당자"
                placeholder="담당자를 입력하세요"
                searchWidth="100%"
                searchValueWidth="240px"
              />
            </SearchItemWrap>
            <GButtonLayout paddingTop="0px" marginBottom="0px">
              <GButtonBoxCum>
                <GButton
                  chkImg={Search}
                  txt="조회"
                  sizes="medium"
                  btnstyled="contained"
                  btnColor="normal"
                  hasImg={true}
                  hasTxt={true}
                ></GButton>
              </GButtonBoxCum>
            </GButtonLayout>
          </UnHiddenSearchWrap>
        </SearchWrap>
        <Content>
          <GridContent>
            <SubTitleBox
              showSubTitle={true}
              content="전체 목록"
              isVisbleSum={true}
              commentCount={21}
            />
            <GContentGrid
              type="check"
              isSortable={false}
              className="ag-theme-alpine"
              scrollHeight="26px"
              style={{ marginTop: '4px', flex: 1 }}
            >
              <AgGridReact
                defaultColDef={defaultColDef}
                columnDefs={colDefs}
                rowData={sampleList}
                rowSelection={'multiple'}
                enableCellTextSelection
                onGridReady={(params) => (gridApiRef.current = params.api)}
                tooltipShowDelay={0}
                tooltipHideDelay={2000}
              ></AgGridReact>
            </GContentGrid>
          </GridContent>
          <ButtonBox>
            <GButton
              chkImg={RightChevron}
              sizes="large"
              btnstyled="outline"
              btnColor="normal"
              hasImg={true}
              hasTxt={false}
            />
            <GButton
              chkImg={LeftChevron}
              sizes="large"
              btnstyled="outline"
              btnColor="normal"
              hasImg={true}
              hasTxt={false}
            />
          </ButtonBox>
          <GridContent>
            <SubTitleBox
              showSubTitle={true}
              content="선택한 목록"
              isVisbleSum={true}
              commentCount={10}
            />

            <GContentGrid
              type="check"
              isSortable={false}
              className="ag-theme-alpine"
              scrollHeight="26px"
              style={{ marginTop: '4px', flex: 1 }}
            >
              <AgGridReact
                defaultColDef={sDefaultColDef}
                columnDefs={sColDefs}
                rowData={sampleList}
                rowSelection={'multiple'}
                enableCellTextSelection
                onGridReady={(params) => (gridApiRef.current = params.api)}
                tooltipShowDelay={0}
                tooltipHideDelay={2000}
              ></AgGridReact>
            </GContentGrid>
          </GridContent>
        </Content>
      </DialogContent>
      <DialogActions className="popupBottom">
        <GButtonBox>
          <GButton
            txt="취소"
            sizes="medium"
            onClick={handleClose}
            btnstyled="outline"
            btnColor="normal"
            hasImg={false}
            hasTxt={true}
          />
          <GButton
            txt="저장"
            sizes="medium"
            onClick={handleOnSave}
            chkImg={Save}
            btnstyled="contained"
            btnColor="primary"
            hasImg={true}
            hasTxt={true}
          />
        </GButtonBox>
      </DialogActions>
    </ModalLayout>
  );
};

const Content = styled.div`
  display: flex;
  flex: 1;
  gap: 24px;
  height: 60vh;
`;

const TreeContent = styled.div`
  display: flex;
  width: 236px;
  flex-direction: column;
`;

const GridContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const ButtonBox = styled.div`
  display: flex;
  width: 36px;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
`;

export default SearchPerson;
