import styled from '@emotion/styled';
import { GlobalTokenColor } from 'ui/theme/Color';
// import styled from 'styled-components';

export const DeliveryAgreeZhc = () => {
  return (
    <>
      <PrivacyDiv>
        <p>
          LG Energy Solution,Ltd.（以下称“LGES”）与贵司就LGES向贵司支付对价相关事宜约定如下：
          <br />
          <br />
          <b>第一条 （目的）</b>
          <br />
          本约定书的目的在于确定甲方向乙方支对价相关必要事项。银行是指甲方与乙方事先协商约定的一个或多个金融机构。
          <br />
          <br />
          <b>第二条 （适用范围）</b>
          <br />
          1.如甲方与乙方间签署的个别合同中约定的支付条件与本约定不一致的，则优先适用个别合同。
          <br />
          2.本约定书优先于双方已签署的《对价支付协议》（如有）。
          <br />
          3.本约定书仅适用于甲方以T/T方式和通过电子支付系统支付对价的情形。
          <br />
          <br />
          <b>第三条 （开立账户及登记）</b>
          <br />
          为了从甲方收取对价，乙方应在银行开立基本存款账户或电子账户（以下统称“结算账户”）并在甲方账户登记系统（http://vars.lgensol.com,
          以下称“账户登记系统”）登记结算账户信息。如甲方要求乙方提交原件时，乙方应根据甲方要求向甲方提供相关资料的原件。
          <br />
          <br />
          <b>第四条 （支付）</b>
          <br />
          1.以T/T方式支付对价的，甲方将款项汇至乙方根据第三条在账户登记系统登记的结算账户。
          <br />
          2.通过电子支付系统支付对价的，甲方向银行通知支付明细并要求银行在指定付款日向乙方支付对价。如乙方希望在指定付款日前收到对价的，可根据乙方和银行签署的“电子结算协议”扣除提前支付手续费或贷款利息后再支付。
          <br />
          <br />
          <b>第五条 （汇款及汇错款的退回）</b>
          <br />
          1.甲方以T/T方式和电子支付系统支付对价时不从乙方收取收款单。
          <br />
          2.根据本约定书甲方向乙方的结算账户支付对价时发生错误的，乙方应立即通知甲方并退回汇错款。
          <br />
          <br />
          <b>第六条 （暂停支付）</b>
          <br />
          属于下列情形之一的，甲方可暂停支付：①乙方未按照第三条登记结算账户信息的；
          ②乙方填写信息有误或与事实不符的； ③乙方未立即退回第五条约定的汇错款。
          <br />
          <br />
          <b>第七条 （免责）</b>
          <br />
          1.甲方依据第四条支付对价的，若以T/T方式支付的，完成汇款时视为甲方履行了支付义务；若以电子支付系统支付的，甲方向银行交付（可以电子邮件方式交付）支付明细时视为甲方履行了支付义务。此后，乙方与银行间发生的保管、支付、追回等任何问题，甲方不承担任何责任。
          <br />
          2.乙方需要变更依据上述第三条登记的结算账户相关信息的（
          以下称“变更信息”），应立即向甲方通知变更内容并在账户登记系统进行变更登记。若甲方要求乙方提供变更信息相关文件时，乙方应立即提交。
          <br />
          3.乙方未在账户登记系统完成变更登记或乙方向甲方提交上述变更信息相关文件前，甲方向变更前账户支付对价的，视为甲方已履行付款义务。
          <br />
          4.因乙方未及时登记变更信息、延迟或未提交变更相关文件而发生的任何损失及责任由乙方承担。
          <br />
          <br />
          <b>第八条 （协议期间）</b>
          <br />
          甲方与乙方维持交易期间本约定书持续有效。
        </p>
      </PrivacyDiv>
    </>
  );
};
const PrivacyDiv = styled.div`
  box-sizing: border-box;
  // border: 1px solid #ebeeed;
  border: 1px solid ${GlobalTokenColor.ESGrey300};
  width: 100%;
  height: 270px;
  overflow-y: auto;
  color: #1f1f1f;
  font-size: 13px;
  line-height: 20px;
  padding: 15px;
`;

const PrivacyTable = styled.table`
  margin: 10px;

  th,
  td {
    padding: 8px;
    border: 1px solid #ebeeed;
  }

  th {
    background: #f1f4f3;
    font-weight: 600;
  }

  td {
    vertical-align: top;
  }
`;
export default DeliveryAgreeZhc;
