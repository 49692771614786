/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';

export const CustomTag = styled.span`
  display: inline-block;
  height: 20px;
  min-width: 40px;
  padding: 0 3px;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  background-color: #dcfbea;
  border: 1px solid #56d8aa;
  border-radius: 2px;
  color: #01463a;
  text-align: center;

  &.red {
    background-color: #fee6db;
    border-color: #fda293;
    color: #b32542;
  }

  &.yellow {
    background-color: #fff8cd;
    border-color: #ffe56a;
    color: #b78803;
  }
`;
