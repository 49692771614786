import styled from '@emotion/styled';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
} from '@mui/material';
import { useState } from 'react';

interface PropsType {
  type?: string;
  label?: string;
  children?: React.ReactNode;
}

const PassWordInput = ({ type, label, children }: PropsType) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <TextInputWrap>
      <FormControl sx={{ width: '100%' }} variant="standard">
        <PasswordLabel htmlFor="standard-adornment-password">{label}</PasswordLabel>
        <PasswordInput
          id="standard-adornment-password"
          type={showPassword ? 'text' : 'password'}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      {children}
    </TextInputWrap>
  );
};

const TextInputWrap = styled.div`
  display: felx;
  border-bottom: 1px solid #b9bcbb;
  width: 340px;
`;

const PasswordLabel = styled(InputLabel)`
  &.Mui-focused {
    color: #979998;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
  }
  &[data-shrink='true'] {
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
  }

  &[data-shrink='false'] {
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
  }
`;

const PasswordInput = styled(Input)`
  &.MuiInputBase-root {
    &:after {
      border-bottom: none;
    }
    &:before {
      border: none;
    }
    &:hover:not(.Mui-disabled, .Mui-error):before {
      border: none;
    }
  }
`;

export default PassWordInput;
