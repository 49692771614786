import React from 'react';
import styled from '@emotion/styled';

const PrivacyEn = () => {
  return (
    <PrivacyDiv>
      <div className="privacy">
        <div className="title_header">Privacy Policy</div>
        <div className="article_header">
          LG Energy Solution, Ltd. (hereinafter referred to as “The Company”, “we”, or “us”
          interchangeably) cares about your privacy.
          <br />
          This privacy policy will explain how our organization uses the personal data we collect
          from you when you use our &quot;Web site&quot; (hereinafter referred to as “Services”
          interchangeably) supported LG Energy solution products.
          <br />
          The protection of your privacy when processing personal data is important to us. We
          therefore process your personal data in compliance with the relevant data protection
          regulations.
        </div>
        <div className="box">
          <div className="title">
            ■ Personal information items for collection and purposes of processing, retention period
          </div>
          <div className="article">
            The company collects and uses the following personal information solely for the
            specified purposes.
            <br />
            The company processes and retains users’ personal information only within the process
            and retention period, as agreed by users upon collection of personal information or
            under relevant laws.
          </div>
          <table>
            <thead>
              <tr>
                <th>Category</th>
                <th>Purpose</th>
                <th colSpan={2}>Items for collection</th>
                <th>Retention and Usage Period</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Member management</td>
                <td>Processing membership and withdrawal for system use</td>
                <th>Required items</th>
                <td>
                  Password, address (in the case of a corporation, Company address), Country where
                  the company is located
                  <br />
                  <br />□ Customer representatives only : Name, email, company name{' '}
                </td>
                <td rowSpan={2} style={{ color: 'blue', fontWeight: 'bold' }}>
                  Until the conclusion of the continuous business relationship with the Company or
                  until a separate deletion request is made
                </td>
              </tr>
              <tr>
                <td>Personal Vendor</td>
                <td>Account authentication</td>
                <th>Required items</th>
                <td>Name of bank, name of depositor, account number, date of birth</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="box">
          <div className="title">■ Provision of personal information to a third party</div>
          <div className="article">
            The company does not provide users’ personal information to an external party or a third
            party without consent of the user.
            <br />
            In the following cases however, personal information may be provided to a third party
            without prior consent of the user.
            <br />
            <br />
            - If provision of the information is inevitable to comply with special regulations or
            statutory obligations.
            <br />
            - If the owner of the information or its legal representative is unable to make an
            expression of intention or is unable to express prior content due to unknown address,
            <br />
            and if such information is clearly deemed urgent and necessary for the life, body and
            property of the information owner or a third party.
            <br />- If provision of the information is required by law or investigative authorities
            for investigative purposes under relevant laws.
            <br />
            <br />
            Your information could be exported to outside of your country if you’re not reside in
            South Korea.
          </div>
        </div>
        <div className="box">
          <div className="title">■ Consignment of personal information processing</div>
          <div className="article">
            The company consigns the following personal information processing tasks to external
            companies for smooth personal information business processing.
          </div>
          <table>
            <thead>
              <tr>
                <th>Entrusted Company</th>
                <th>Entrusted Operations</th>
                <th>Retention and Usage Period</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="tl_center">
                  LG CNS
                  <br />
                  (Re-consignment : Biztech i)
                </td>
                <td>
                  Service operation, system operation, service homepage operation management, system
                  maintenance, etc
                </td>
                <td>
                  Until the conclusion of the continuous business relationship with the Company or
                  Until the end of the consignment contract
                </td>
              </tr>
              <tr>
                <td className="tl_center">
                  A total of 21 banks
                  <br />
                  (Woori, Kookmin, Shinhan, SC, KEB, Hana, Nonghyup, Citi, IBK, Kyongnam, Daegu,
                  Busan, Kwangju, Korea Development, National Federation of Fisheries, Korea
                  Eximbank, Jeju, Jeonbuk, Shinhyeop, Koreapost, Kakao, Deutsche)
                </td>
                <td>Account authentication</td>
                <td>
                  Until the conclusion of the continuous business relationship with the Company or
                  Until the end of the consignment contract
                </td>
              </tr>
            </tbody>
          </table>
          <div className="article">
            The company prohibits strictly defines and manages technical/administrative protection
            measures, safety assurance measures, re-entrustment restrictions, management and
            supervision of trustees, confidentiality and liabilities to protect personal information
            processed through consignments.
            <br />
            <br />
            If any changes in the consigning company or commissioned tasks occur, the company posts
            such changes on the company bulletin board without delay.
          </div>
        </div>
        <div className="box">
          <div className="title">■ Deletion of personal information</div>
          <div className="article">
            The company shall delete the relevant personal information without delay by the
            following procedures and methods if the period of personal information is elapsed and
            the purpose of processing is achieved.
            <br />
            If the personal information held by the user has expired or the purpose of processing
            has been achieved, the personal information must be kept in accordance with other
            statutes.
            <br />
            <br />
            The procedure and method for destroying personal information of the company are as
            follows.
            <br />
            <br />
            ① Deletion procedure
            <br />
            Measures for destruction or deletion after being stored for a certain period in
            accordance with internal policies and other related statutes
            <br />
            <br />
            ② Deletion method
            <br />
            Personal information recorded in a paper document is scraped or incinerated by a
            grinder, and personal information stored in the form of an electronic file is deleted by
            a method that can not be recorded or restored.
          </div>
        </div>
        <div className="box">
          <div className="title">■ Cookies and other tracking technologies</div>
          <div className="article">
            The company does not use &quot;cookie&quot; that stores users’ usage information and
            calls them from time to time.
          </div>
        </div>
        <div className="box">
          <div className="title">■ Security</div>
          <div className="article">
            The Company implements appropriate technical and organizational security measures to
            protect your data, appropriate to the risk and the personal data that we collect.
            <br />
            We follow industry standards to protect your personal information from unauthorized
            access or disclosure, alteration or destruction.
            <br />
            These standards include reasonable administrative, physical, and technical safeguards.
            However, we do not collect or store sensitive personal information.
            <br />
            Please understand that email or other Internet communications, as well as SMS or text
            messages, are not secure and may be intercepted while being transmitted over the
            Internet, by WiFi, or by other means.
          </div>
        </div>
        <div className="box">
          <div className="title">
            ■ Rights, obligations and exercing methods of users and legal representatives
          </div>
          <div className="article">
            Users and their legal representatives may exercise the following rights in connection
            with the processing of personal information.
            <br />
            <br />
            ① Users who provide personal information may exercise the following rights to the
            company at any time
            <br />
            - Need to view personal information
            <br />
            - If there is an error, correction request
            <br />
            - Deletetion request
            <br />
            - Processing stop request
            <br />
            <br />
            ※ Requests for access to personal information of children under the age of 14 must be
            made by the legal representative, and the data subject, who is a minor over the age of
            14, may exercise his or her rights regarding the personal information of the data
            subject or through his or her legal representative.
            <br />
            <br />
            ② The exercise of rights under paragraph ① can be done in writing, telephone, e-mail,
            etc. to the company, and the company will take action without delay.
            <br />
            <br />
            ③ The exercise of rights under paragraph ① can be done through the agent, such as the
            legal representative of the user or the person who has been delegated.
            <br />
            In this case, you must submit a letter of attorney in accordance with Appendix 11 of the
            Enforcement Rule of the Personal Information Protection Act.
            <br />
            <br />
            ④ The user shall not infringe on the personal information and privacy of the user
            himself / herself or other person under the management of the company in order to comply
            with related statutes such as the Personal Information Protection Act.
            <br />
            <br />
            ⑤ The company verifies whether the person who requested access, correction and deletion,
            or suspension of processing, according to the user’s rights, is the principal or a
            legitimate agent.
            <br />
            <br />
            ⑥ The company is seeking the consent of the legal representative in case of the need to
            collect personal information of children under the age of 14 to provide services.
            <br />
            <br />
          </div>
        </div>
        <div className="box">
          <div className="title">■ Contact Us</div>
          <div className="article">
            The Company has designated a security department in charge of personal information
            protection as below, with overall responsibility for processing of personal information
            and to handle complaints and damage relief sought by data subjects in relation to
            personal information processing, etc.
            <br />
            Users may make any inquiries about personal information protection, complaint handling
            or damage relief, etc. that may arise while using the Company’s services (or business)
            through the person in charge and the relevant department, and the Company will respond
            to and address users’ inquiries as required by law.
          </div>
          <div className="flx">
            <table className="flx_30">
              <tr>
                <th colSpan={3}>Customer Inquiry Service Department</th>
              </tr>
              <tr>
                <th rowSpan={2}>LG Energy Solution, LTD.</th>
                <th>Department</th>
                <td>자금팀</td>
              </tr>
              <tr>
                <th>E-Mail</th>
                <td>vars_hq@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>LG Energy Solution Australia Pty Ltd</th>
                <th>Department</th>
                <td>Admin Part</td>
              </tr>
              <tr>
                <th>E-Mail</th>
                <td>vars_au@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>NEXTSTAR ENERGY INC.</th>
                <th>Department</th>
                <td>Finance Team</td>
              </tr>
              <tr>
                <th>E-Mail</th>
                <td>vars_st@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>LG Energy Solution (Nanjing) Co., Ltd.</th>
                <th>Department</th>
                <td>LGESNJ.China Manage.SSC banking T.fund P</td>
              </tr>
              <tr>
                <th>E-Mail</th>
                <td>vars_nj_na@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>LG Energy Solution Technology (Nanjing) Co., Ltd.</th>
                <th>Department</th>
                <td>LGESNJ.China Manage.SSC banking T.fund P</td>
              </tr>
              <tr>
                <th>E-Mail</th>
                <td>vars_nb@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>LG Energy Solution Battery (Nanjing) Co., Ltd.</th>
                <th>Department</th>
                <td>LGESNJ.China Manage.SSC banking T.fund P</td>
              </tr>
              <tr>
                <th>E-Mail</th>
                <td>vars_nj_na@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>LG Energy Solution Europe GmbH</th>
                <th>Department</th>
                <td>Business Management Team</td>
              </tr>
              <tr>
                <th>E-Mail</th>
                <td>vars_eg@lgensol.com</td>
              </tr>
              <tr>
                {/* <th rowSpan={2}>PT. HKML Battery Indonesia</th> */}
                <th rowSpan={2}>PT HLI Green Power</th>
                <th>Department</th>
                <td>Treasury Team</td>
              </tr>
              <tr>
                <th>E-Mail</th>
                <td>vars_hm@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>LG ENERGY SOLUTION INDIA PRIVATE LIMITED</th>
                <th>Department</th>
                <td>Account Team</td>
              </tr>
              <tr>
                <th>E-Mail</th>
                <td>vars_il@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>LG Energy Solution Japan Co., Ltd.</th>
                <th>Department</th>
                <td>Management Part</td>
              </tr>
              <tr>
                <th>E-Mail</th>
                <td>vars_jp@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>LG Energy Solution Wroclaw sp. z o.o.</th>
                <th>Department</th>
                <td>Biz Mgt.Finance&Accounting Team</td>
              </tr>
              <tr>
                <th>E-Mail</th>
                <td>vars_wa@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>LG Energy Solution (Taiwan), Ltd</th>
                <th>Department</th>
                <td>Business Administration Part</td>
              </tr>
              <tr>
                <th>E-Mail</th>
                <td>vars_tw@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>Ultium holdings LLC</th>
                <th>Department</th>
                <td>Treasury Team</td>
              </tr>
              <tr>
                <th>E-Mail</th>
                <td>vars_gm@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>HL-GA Battery Company LLC</th>
                <th>Department</th>
                <td>Treasury Team</td>
              </tr>
              <tr>
                <th>E-Mail</th>
                <td>vars_hg@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>Ultium Cells LLC</th>
                <th>Department</th>
                <td>Treasury Team</td>
              </tr>
              <tr>
                <th>E-Mail</th>
                <td>vars_gm@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>LG ENERGY SOLUTION ARIZONA ESS, Inc.</th>
                <th>Department</th>
                <td>Treasury Team</td>
              </tr>
              <tr>
                <th>E-Mail</th>
                <td>vars_ae@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>LG Energy Solution Michigan, Inc.</th>
                <th>Department</th>
                <td>Admin Team.Treasury P</td>
              </tr>
              <tr>
                <th>E-Mail</th>
                <td>vars_mi@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>LG ENERGY SOLUTION ARIZONA, INC.</th>
                <th>Department</th>
                <td>Treasury Team</td>
              </tr>
              <tr>
                <th>E-Mail</th>
                <td>vars_az@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>LG Energy Solution Vertech, Inc</th>
                <th>Department</th>
                <td>Finance & Accounting</td>
              </tr>
              <tr>
                <th>E-Mail</th>
                <td>vars_vt@lgensol.com</td>
              </tr>
              <tr>
                <th rowSpan={2}>L-H Battery Company, Incorporated</th>
                <th>Department</th>
                <td>Finance Team</td>
              </tr>
              <tr>
                <th>E-Mail</th>
                <td>vars_hd@lgensol.com</td>
              </tr>
            </table>
            <table style={{ height: '80px' }}>
              <tr>
                <th colSpan={2}>Personal Information Protection Management Department</th>
              </tr>
              <tr>
                <th>Chief Privacy Officer(CPO)</th>
                <td className="tl_center">Jongguk Lee</td>
              </tr>
              <tr>
                <th>Protection Management Department</th>
                <td className="tl_center">
                  Security Strategy Team
                  <br />
                  (privacy.es@lgensol.com)
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div className="box">
          <div className="title">
            ■ Method for compensatinng for performing perference of persoanl information and
            revention for inpension of rights.
          </div>
          <div className="article">
            You can contact the following agencies for reports, damage relief, and counseling on
            user’s personal information infringement.
            <br />
            <br />
            - Personal Information Dispute Mediation Committee (http://www.privacy.go.kr, 1833-6972)
            <br />
            - Personal Information Violation Report Center (http://privacy.kisa.or.kr, Tel.118)
            <br />
            - Supreme Public Prosecutor’s Office Cyber Investigation Department
            (http://www.spo.go.kr, Tel.1301)
            <br />- Electronic Cybercrime Report & Management system (https://ecrm.cyber.go.kr,
            Tel.182)
          </div>
        </div>
        <div className="box">
          <div className="title">■ Changes to our Privacy Policy</div>
          <div className="article">
            The Company keeps its Privacy Policy under regular review and places any updates on this
            web page. This Privacy Policy was last updated on [ 2024. 04. 15 ]
          </div>
        </div>
      </div>
    </PrivacyDiv>
  );
};

export default PrivacyEn;

const PrivacyDiv = styled.div`
  body {
    box-sizing: border-box;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  /* privacy */
  .privacy {
    box-sizing: border-box;
    margin: 0;
    font-family: 'Spoqa Han Sans Neo', '맑은 고딕', 'malgun gothic', AppleGothicNeoSD,
      'Apple SD 산돌고딕 Neo', 'Microsoft NeoGothic', 'Droid sans', sans-serif;
    font-size: 13px;
    color: #1f1f1f;
    padding: 20px;
    line-height: 20px;
  }

  .privacy .tl_center {
    text-align: center;
  }
  .privacy .tl_left {
    text-align: left;
  }
  .privacy .tl_right {
    text-align: right;
  }

  .privacy .title_header {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    padding: 10px;
  }

  .privacy .article_header {
    padding: 10px;
  }

  .privacy .box {
    padding: 10px;
  }

  .privacy .box .title {
    font-size: 14px;
    font-weight: 600;
    padding: 10px 0;
  }

  .privacy .box .article {
    font-size: 13px;
    font-weight: 400;
  }

  .privacy .box table {
    border-collapse: collapse;
    margin: 10px 0px;
  }

  .privacy .box table th,
  .privacy .box table td {
    border: 1px solid #dde0df;
    padding: 5px 10px;
  }

  .privacy .box table th {
    background: #f1f4f3;
    font-weight: 600;
  }

  .privacy .box table td {
    vertical-align: top;
  }

  .privacy .flx {
    display: flex;
    justify-content: space-around;
    gap: 20px;
  }

  /* privacy_pop */
  .privacy_pop {
    box-sizing: border-box;
    margin: 0;
    font-family: 'Spoqa Han Sans Neo', '맑은 고딕', 'malgun gothic', AppleGothicNeoSD,
      'Apple SD 산돌고딕 Neo', 'Microsoft NeoGothic', 'Droid sans', sans-serif;
    font-size: 13px;
    color: #1f1f1f;
    padding: 20px;
    line-height: 20px;
  }
  .privacy_pop .title_header {
    font-size: 16px;
    font-weight: 600;
    padding: 10px;
  }

  .privacy_pop .article_header {
    padding: 10px;
  }

  .privacy_pop .scrollbox {
    height: 500px;
    border: 1px solid #dde0df;
    overflow-y: scroll;
  }

  .privacy_pop .tl_right {
    padding: 10px;
    text-align: right;
  }

  .privacy_pop .input {
    vertical-align: bottom;
  }
`;
