import styled from '@emotion/styled';
import { GButton } from 'components/atom/button';
import { GPagination, PaginationRef } from 'components/layouts/GPagination';
import GButtonLayout from 'components/molecule/GbuttonLayout';
import SubTitleBox from 'components/molecule/SubTitleBox';
import { SubTitleBtnArea } from 'components/organism/SubTitleBtnArea';
import { useRef, useState } from 'react';
import New from 'components/asset/images/Penw.svg';
import QnaTable from 'components/tables/QnaTable';

const QnaList = () => {
  // pagination
  const [totalCount, setTotalCount] = useState<number>(10);
  const paginationRef = useRef<PaginationRef>(null);
  const [pageSize, setPageSize] = useState<number>(30);
  const [pageNo, setPageNo] = useState<number>(1);

  const handlePageSizeChange = (size) => {
    paginationRef.current?.setPageSize(size);
    setPageNo(1);
    setPageSize(size);
  };

  const handlePageNoClick = (no: number) => {
    setPageNo(no);
  };

  return (
    <>
      <SubTitleBtnArea>
        <SubTitleBox
          isVisibleComment={false}
          isVisibleInfo={false}
          isVisbleSum={true}
          isVisbleSelect={true}
          showSubTitle={false}
          commentCount={332}
          selectOption={['15', '30', '45']}
        />
      </SubTitleBtnArea>
      <TableBorderWrap>
        <QnaTable />
        <GPagination
          ref={paginationRef}
          totalCount={totalCount}
          defaultPageNo={pageNo}
          defaultPageSize={pageSize}
          onChangePageSize={handlePageSizeChange}
          onClickPageNo={handlePageNoClick}
        />
      </TableBorderWrap>
      <GButtonLayout marginBottom="8px">
        <GButton
          chkImg={New}
          txt="신규"
          sizes="large"
          btnstyled="contained"
          btnColor="primary"
          hasImg={true}
          hasTxt={true}
        ></GButton>
      </GButtonLayout>
    </>
  );
};

const TableBorderWrap = styled.div`
  border-top: 1px solid #dde0df;
  border-bottom: 1px solid #dde0df;
  padding-bottom: 4px;
`;

export default QnaList;
