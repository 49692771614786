import styled from '@emotion/styled';
import New from 'components/asset/images/Pen.svg';
import Delete from 'components/asset/images/Delete.svg';
import Download from 'components/asset/images/Download.svg';
import ReplyArrow from 'components/asset/images/ReplyArrow.svg';
import { GButton } from 'components/atom/button';
import { deleteAdminQnaReplyApi } from 'apis/vars/qna/QnaAdminApi';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCommonModal } from 'hooks/useCommonModal';
import { useLoading } from 'components/process/Loading';
import { QnaReplies } from 'pages/vars/qna/QnaDetailPage';
import { deleteUserQnaReplyApi } from 'apis/vars/qna/QnaUserApi';
import { SubTitleBtnArea } from 'components/organism/SubTitleBtnArea';
import ReReplyArea from './ReReplyArea';
import useAuthStore from 'stores/useAuthStore';
import useSessionStore from 'stores/useSessionStore';
import SubTitleBox from 'components/molecule/SubTitleBox';
import FileDownloadVars from 'components/vars/common/FileDownloadVars';
import { downloadAllFilesToFileName } from 'apis/admin/FileUpload';
import { downloadAllFilesToFileNameVars } from 'apis/vars/common/Common';
import { useMessageBar } from 'components/process/MessageBar';
import { FileInfo } from 'models/admin/FileInfo';
import { replaceWithTimezone } from 'utils/TimeZoneUtil';

interface ReplyProps {
  replyData: QnaReplies;
  profileImg: string;
  openEditReReply: any;
  getQnaDetailData: (uptVwct) => void | undefined;
  handleClickEditBtn: () => void;
}

const QnaReply: React.FC<ReplyProps> = (props: ReplyProps) => {
  const { t } = useTranslation();
  const { accessToken } = useAuthStore();
  const { openLoading } = useLoading();
  const { openMessageBar } = useMessageBar();
  const { openCommonModal } = useCommonModal();
  const [openReReply, setOpenReReply] = useState<boolean>(false);
  const { userId, userNm, deptNm, jtiNm } = useSessionStore();
  const {
    bbmNo,
    bbmReNo,
    dataInsUserId,
    dataInsUserNm,
    vdcpNm,
    dataInsUserJikwiNm,
    dataInsUserDeptNm,
    bbmReCtn,
    atchFileGrId,
    atchFileCnt,
    dataInsDtm,
    strDataInsDtm,
    dataUpdDtm,
    strDataUpdDtm,
    upprBbmReNo,
    reDelYn,
  } = props.replyData;

  const handleDeleteReply = () => {
    const uptVwct = 'N';
    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.00200', '__댓글을 삭제하시겠습니까?'),
      noCallback: () => {
        return;
      },
      yesCallback: async () => {
        openLoading(true);
        if (accessToken) {
          return deleteUserQnaReplyApi(bbmNo, bbmReNo)
            .then((res) => {
              if (res) {
                openLoading(false);
                openMessageBar('success', t('com.label.00179', '__삭제되었습니다.'));
                props.getQnaDetailData ? props.getQnaDetailData(uptVwct) : '';
              } else {
                openLoading(false);
              }
            })
            .catch((error) => {
              openLoading(false);
              console.error('Error deleting row:', error);
            });
        } else {
          return deleteAdminQnaReplyApi(bbmNo, bbmReNo)
            .then((res) => {
              if (res) {
                openLoading(false);
                openMessageBar('success', t('com.label.00179', '__삭제되었습니다.'));
                props.getQnaDetailData ? props.getQnaDetailData(uptVwct) : '';
              } else {
                openLoading(false);
              }
            })
            .catch((error) => {
              openLoading(false);
              console.error('Error deleting row:', error);
            });
        }
      },
    });
  };

  const handleClickWriteRereply = () => {
    setOpenReReply(true);
  };

  return (
    <>
      <ReplyItem {...props}>
        {/* <WriterProfile src={replyData.profileImg} {...props} /> */}
        <span
          style={{
            width: '24px',
            height: '24px',
            backgroundColor: '#554596',
            borderRadius: '50px',
            margin: '6px 4px',
          }}
        >
          <WriterProfile
            src={props.profileImg}
            {...props}
            style={{ width: '20px', height: '20px', margin: '2px' }}
          />
        </span>
        <ContentWrap {...props}>
          <TopArea>
            <Left>
              <NameWrap {...props}>
                <Nickname>
                  {dataInsUserNm + ' ' + (dataInsUserJikwiNm ? dataInsUserJikwiNm : '')}
                </Nickname>
                <Position>{dataInsUserDeptNm || vdcpNm}</Position>
              </NameWrap>
            </Left>
            {dataInsUserId === userId && reDelYn === 'N' && (
              <GButtonWrap>
                <GButton
                  chkImg={New}
                  sizes="small"
                  btnstyled="outline"
                  btnColor="none"
                  hasImg={true}
                  hasTxt={false}
                  onClick={props.handleClickEditBtn}
                ></GButton>
                <GButton
                  chkImg={Delete}
                  sizes="small"
                  btnstyled="outline"
                  btnColor="none"
                  hasImg={true}
                  hasTxt={false}
                  onClick={handleDeleteReply}
                ></GButton>
              </GButtonWrap>
            )}
          </TopArea>
          <ReplyContentWrap>
            {reDelYn === 'N' && atchFileCnt !== 0 && (
              <FileBox>
                <SubTitleBtnArea>
                  <SubTitleBox
                    showSubTitle={true}
                    content={t('com.label.00103', '__첨부파일')}
                    isVisbleSum={true}
                    commentCount={atchFileCnt}
                  />
                  <GButton
                    chkImg={Download}
                    txt={t('com.label.00221', '__전체다운로드')}
                    sizes="small"
                    btnstyled="outline"
                    btnColor="normal"
                    hasImg={true}
                    hasTxt={true}
                    onClick={() => {
                      if (accessToken) {
                        downloadAllFilesToFileNameVars(
                          atchFileGrId,
                          `VARS_QNA_RE_[${bbmReNo}].zip`
                        );
                      } else {
                        downloadAllFilesToFileName(atchFileGrId, `VARS_QNA_RE_[${bbmReNo}].zip`);
                      }
                    }}
                  ></GButton>
                </SubTitleBtnArea>
                <FileDownloadVars atchFileGrId={atchFileGrId} />
              </FileBox>
            )}
            {reDelYn === 'Y' ? (
              t('com.label.00197', '__삭제된 댓글입니다.')
            ) : (
              <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>{bbmReCtn}</pre>
            )}
          </ReplyContentWrap>

          <BottomWrap>
            <Date>
              {replaceWithTimezone(strDataUpdDtm, 'YYYY-MM-DD HH:mm:ss') ??
                replaceWithTimezone(strDataInsDtm, 'YYYY-MM-DD HH:mm:ss')}
            </Date>
            {reDelYn === 'N' && !upprBbmReNo ? (
              <GButton
                txt={t('com.label.00052', '댓글쓰기')}
                sizes="xs"
                btnstyled="outline"
                btnColor="none"
                hasImg={false}
                hasTxt={true}
                onClick={handleClickWriteRereply}
                style={{ paddingLeft: '0px' }}
              ></GButton>
            ) : (
              <div style={{ marginBottom: '16px' }}></div>
            )}
          </BottomWrap>
        </ContentWrap>
      </ReplyItem>
      {/* 등록영역(대댓글) */}
      {openReReply && (
        <ReReplyContainer>
          <ReReplyContainer>
            <ReReplyWrap>
              <ReReply src={ReplyArrow} alt="Reply Arrow" />
              <ReReplyArea
                id="rereply"
                profileImg={props.profileImg}
                nickName={userNm + ' ' + (jtiNm ? jtiNm : '')}
                position={deptNm}
                replyData={props.replyData}
                setOpenReReply={setOpenReReply}
                getQnaDetailData={props.getQnaDetailData}
                openEditReReply={props.openEditReReply}
              />
            </ReReplyWrap>
          </ReReplyContainer>
        </ReReplyContainer>
      )}
    </>
  );
};

const ReplyContentWrap = styled.ul`
  width: 100%;
  height: auto;
`;

const ReplyItem = styled.div<ReplyProps>`
  display: flex;
  align-items: flex-start;
  padding: 12px 0;
  width: 100%;
  gap: 8px;
  // border-bottom: 1px solid #ebeeed;
  &:hover > div > div:first-child > div:last-child {
    opacity: 1;
  }
`;

const ContentWrap = styled.div<ReplyProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const WriterProfile = styled.img<ReplyProps>`
  width: 24px;
  height: 24px;
  border-radius: 50px;
  margin: 6px 4px;
`;

const TopArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 4px;
  // margin-bottom: 16px;
`;

const NameWrap = styled.div<ReplyProps>`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Nickname = styled.text`
  color: var(--Common-Text-Basic, #1f1f1f);
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Spoqa Han Sans Neo;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`;

const Position = styled.text`
  color: var(--Common-Text-Basic, #1f1f1f);
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Spoqa Han Sans Neo;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

const GButtonWrap = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 4px;
  opacity: 0;
`;

const BottomWrap = styled.div`
  width: 100%;
  height: 24px;
  display: flex;
  align-items: center;
  gap: 24px;

  .css-skuaea-MuiButtonBase-root-MuiButton-root span {
    font-size: 13px;
    font-weight: normal;
    color: #1f1f1f;
  }
`;

const Left = styled.div`
  width: auto;
`;

const Date = styled.span`
  color: var(--Common-Text-Light, #5b5c5b);
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Spoqa Han Sans Neo;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

const FileBox = styled.div`
  margin-top: 4px;
  margin-bottom: 24px;
  padding: 12px;
  border: 1px solid #dde0df;
  background: #f7f9f8;
`;

const ReReplyWrap = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  // border-bottom: 1px solid #ebeeed;
  padding-left: 11px;
`;

const ReReply = styled.img`
  padding: 24px 0px 12px 0px;
`;

const ReReplyContainer = styled.div`
  width: 100%;
  align-items: flex-start;
  gap: 8px;
`;

export default QnaReply;
