import { useCommonModal } from 'hooks/useCommonModal';
import { Department } from 'models/admin/Department';
import { useRef } from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { GButtonBox, GPopupTitle } from 'components/layouts/GLayoutBox';
import { DialogTitleItem } from 'components/layouts/GModalPopup';
import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import { GButton } from 'components/atom/button';
import ModalLayout from 'components/layouts/ModalLayout';

type DepartmentModalProps = {
  open: boolean;
  singleSelect?: boolean;
  close: () => void;
  save: () => void;
};

const RequestAccountCertification = (props: DepartmentModalProps) => {
  const { t } = useTranslation();
  const gridRef = useRef<AgGridReact<Department>>(null);
  const { openCommonModal } = useCommonModal();

  const handleClose = () => {
    props.close();
  };

  const handleOnSave = () => {
    const selectedRows = gridRef.current?.api.getSelectedRows() || [];
    if (selectedRows.length === 0) {
      openCommonModal({
        modalType: 'alert',
        content: t(
          'department-choice-popup.alert.한 부서 이상 선택해야 합니다.',
          '__선택된 행이 없습니다.'
        ),
      });
      return;
    }
    props.close();
  };

  return (
    <ModalLayout open={props.open} onClose={handleClose} mMinWidth={400} mMaxWidth={400}>
      <DialogTitleItem id="customized-dialog-title" onClose={handleClose}>
        <GPopupTitle title2="계좌인증요청" />
      </DialogTitleItem>
      <DialogContent className="popupContent">계좌인증 요청하시겠습니까?</DialogContent>
      <DialogActions className="popupBottom" style={{ padding: '12px 24px 24px' }}>
        <GButtonBox>
          <GButton
            txt="취소"
            sizes="large"
            onClick={handleClose}
            btnColor="normal"
            btnstyled="unfilled"
            hasImg={false}
            hasTxt={true}
          />
          <GButton
            txt="확인"
            sizes="popup"
            onClick={handleOnSave}
            btnstyled="unfilled"
            btnColor="primary"
            hasImg={false}
            hasTxt={true}
          ></GButton>
        </GButtonBox>
      </DialogActions>
    </ModalLayout>
  );
};

export default RequestAccountCertification;
