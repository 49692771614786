import { ICellRendererParams } from 'ag-grid-community';
import { GButton } from 'components/atom/button';
import _ from 'lodash';

export interface IButtonCellRendererParams extends ICellRendererParams {
  label?: string;
  btnStyled?: string;
  btnColor?: string;
  onClick?: () => void;
  disabled?: (() => boolean) | boolean;
}

export const ButtonCellRenderer = ({
  label,
  btnStyled = 'outlined',
  btnColor = 'normal',
  onClick,
  disabled,
  ...params
}: IButtonCellRendererParams) => {
  const handleClick = () => {
    onClick?.();
  };

  const renderButton = () => {
    return (
      <GButton
        {...params}
        hasTxt={true}
        txt={label}
        btnstyled={btnStyled}
        btnColor={btnColor}
        sizes="xs"
        onClick={handleClick}
      />
    );
  };

  return <>{!disabled && renderButton()}</>;
};
