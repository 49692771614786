/* eslint-disable */
import { useEffect, useMemo, useState } from 'react';
import { array, bool, boolean, number, object, string } from 'yup';
import { useTranslation } from 'react-i18next';

const useFrgnRegist = () => {
  const { t } = useTranslation();

  const fileSchema = useMemo(() => {
    return object({
      fileId1: string()
        .required(t('com.label.00479', '__사업자등록증은 필수 입력 항목입니다.') as string)
        .test('fileId1', '__TestString' as string, (value, ctx) => {
          if (!value) {
            return false;
          }
          return true;
        }),
      fileId2: string().required(
        t('com.label.00480', '__통장사본은 필수 입력 항목입니다.') as string
      ),
      fileId3: string().required(
        t('com.label.00481', '__어음약정확인서는 필수 입력 항목입니다.') as string
      ),
    });
  }, []);

  const regSchema = useMemo(() => {
    return object({
      dpstNm: string().required(t('com.label.00609', '__예금주는 필수 입력 항목입니다.') as string),
      bankNm: string().required(t('com.label.00482', '__은행명 필수 입력 항목입니다.') as string),
      bankLoctCtryCd: string().required(
        t('com.label.00706', '__은행소재국가 필수 입력 항목입니다.') as string
      ),
      bankAddr: string().required(
        t('com.label.00707', '__은행주소 필수 입력 항목입니다.') as string
      ),
      bankCtpl: string().required(
        t('com.label.00708', '__은행연락처 필수 입력 항목입니다.') as string
      ),
      bankEml: string().required(
        t('com.label.00709', '__은행이메일 필수 입력 항목입니다.') as string
      ),
      bankNoTpCd: string().when('dmstFrgDivsCd', {
        is: (dmstFrgDivsCd) => dmstFrgDivsCd == 'OV',
        then: (schema) =>
          schema.required(t('com.label.00710', '__계좌추가정보 필수 입력 항목입니다.') as string),
        otherwise: (schema) => schema.notRequired(),
      }),
      bankNo: string().when('bankNoTpCd', {
        is: (bankNoTpCd) => bankNoTpCd != '9',
        then: (schema) =>
          schema.required(t('com.label.00710', '__계좌추가정보 필수 입력 항목입니다.') as string),
        otherwise: (schema) => schema.notRequired(),
      }),
      encAccnNo: string().when('ibanYn', {
        is: (ibanYn) => ibanYn == 'Normal',
        then: (schema) =>
          schema.required(t('com.label.00704', '__계좌번호 필수 입력 항목입니다.') as string),
        otherwise: (schema) => schema.notRequired(),
      }),
      ibanCd: string().when('ibanYn', {
        is: (ibanYn) => ibanYn == 'IBAN',
        then: (schema) =>
          schema.required(t('com.label.00712', '__IBAN코드 필수 입력 항목입니다.') as string),
        otherwise: (schema) => schema.notRequired(),
      }),
      swftCd: string().when('dmstFrgDivsCd', {
        is: (dmstFrgDivsCd) => dmstFrgDivsCd == 'DO',
        then: (schema) =>
          schema.required(t('com.label.00729', '__SWIFT/BIC 필수 입력 항목입니다.') as string),
        otherwise: (schema) => schema.notRequired(),
      }),
    });
  }, []);

  const regSchemaKrw = useMemo(() => {
    return object({
      dpstNm: string().required(t('com.label.00609', '__예금주는 필수 입력 항목입니다.') as string),
      bankLoctCtryCd: string().required(
        t('com.label.00706', '__은행소재국가 필수 입력 항목입니다.') as string
      ),
      bankNm: string().required(t('com.label.00482', '__은행명 필수 입력 항목입니다.') as string),
      bankAddr: string().required(
        t('com.label.00707', '__은행주소 필수 입력 항목입니다.') as string
      ),
      bankCtpl: string().required(
        t('com.label.00708', '__은행연락처 필수 입력 항목입니다.') as string
      ),
      bankEml: string().required(
        t('com.label.00709', '__은행이메일 필수 입력 항목입니다.') as string
      ),
      encAccnNo: string().required(
        t('com.label.00704', '__계좌번호 필수 입력 항목입니다.') as string
      ),
    });
  }, []);

  const regSchemaDo = useMemo(() => {
    return object({
      dpstNm: string().required(t('com.label.00609', '__예금주는 필수 입력 항목입니다.') as string),
      bankNm: string().required(t('com.label.00482', '__은행명 필수 입력 항목입니다.') as string),
      bankLoctCtryCd: string().required(
        t('com.label.00706', '__은행소재국가 필수 입력 항목입니다.') as string
      ),
      bankAddr: string().required(
        t('com.label.00707', '__은행주소 필수 입력 항목입니다.') as string
      ),
      bankCtpl: string().required(
        t('com.label.00708', '__은행연락처 필수 입력 항목입니다.') as string
      ),
      bankEml: string().required(
        t('com.label.00709', '__은행이메일 필수 입력 항목입니다.') as string
      ),
      encAccnNo: string().when('ibanYn', {
        is: (ibanYn) => ibanYn == 'Normal',
        then: (schema) =>
          schema.required(t('com.label.00704', '__계좌번호 필수 입력 항목입니다.') as string),
        otherwise: (schema) => schema.notRequired(),
      }),
      ibanCd: string().when('ibanYn', {
        is: (ibanYn) => ibanYn == 'IBAN',
        then: (schema) =>
          schema.required(t('com.label.00712', '__IBAN코드 필수 입력 항목입니다.') as string),
        otherwise: (schema) => schema.notRequired(),
      }),
      swftCd: string().when('dmstFrgDivsCd', {
        is: (dmstFrgDivsCd) => dmstFrgDivsCd == 'DO',
        then: (schema) =>
          schema.required(t('com.label.00729', '__SWIFT/BIC 필수 입력 항목입니다.') as string),
        otherwise: (schema) => schema.notRequired(),
      }),
    });
  }, []);

  return {
    regSchemaKrw,
    regSchema,
    fileSchema,
    regSchemaDo,
  };
};

export default useFrgnRegist;
