import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { BorderColor, FontColor } from 'ui/theme/Color';
import dayjs, { Dayjs } from 'dayjs';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import useSessionStore from 'stores/useSessionStore';

import 'dayjs/locale/en';
import 'dayjs/locale/ko';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/zh-tw';
import 'dayjs/locale/pl';
import styled from '@emotion/styled';
interface DatePickerProps {
  id?: string;
  date?: Dayjs | null;
  mindate?: Dayjs | 'today';
  maxdate?: Dayjs | 'today';
  changeDate?: (date: Dayjs, id: string) => void;
  readonly?: boolean;
}

export default function BasicDatePicker({
  id,
  date,
  mindate,
  maxdate,
  changeDate,
  readonly,
}: DatePickerProps) {
  const min = mindate == 'today' ? dayjs() : mindate;
  const max = maxdate == 'today' ? dayjs() : maxdate;
  const lang = useSessionStore.getState().langCd || 'ko';

  //datepicker locale 초기값 설정
  const getLocaleCd = (langCd: string) => {
    switch (langCd) {
      case 'zhC':
        return 'zh-cn';
      case 'zhT':
        return 'zh-tw';
      default:
        return langCd;
    }
  };

  //datepicker 캘린더 헤더 날짜 포맷 설정
  const setLocaleDateFormat = (langCd: string) => {
    switch (langCd) {
      case 'en':
      case 'pl':
        return 'MMMM YYYY';
      case 'zhC':
      case 'zhT':
        return 'YYYY年 MM月';
      default:
        return 'YYYY년 MM월';
    }
  };

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={getLocaleCd(lang)}
      dateFormats={{ monthAndYear: setLocaleDateFormat(lang) }}
    >
      <DemoContainerCustom components={['DatePicker']}>
        <DatePickerCustom
          readOnly={readonly}
          value={date ?? null}
          onChange={(value) => changeDate && changeDate(value as Dayjs, id ?? '')}
          minDate={min}
          maxDate={max}
          format="YYYY.MM.DD"
        />
      </DemoContainerCustom>
    </LocalizationProvider>
  );
}

const DemoContainerCustom = styled(DemoContainer)`
  overflow: hidden;
  padding: 0;
  flex: 1 auto;
  width: auto;
`;

const DatePickerCustom = styled(DatePicker)`
  min-width: 0 !important;
  background-color: white;

  .MuiOutlinedInput-input {
    width: 110px !important;
    margin-left: 0 !important;
    padding: 5px 0 5px 14px;
    font-size: 13px;
  }

  .MuiOutlinedInput-notchedOutlin {
    border-color: ${BorderColor.Form} !important;
    border-radius: 2px;
  }

  .MuiSvgIcon-root {
    font-size: 20px;
    color: #444;
  }

  .MuiOutlinedInput-root {
    border-radius: 0;
    color: #666 !important;
    font-weight: inherit;
    height: 32px;
  }

  .Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-width: 1px !important;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-width: 1px !important;
  }
`;
