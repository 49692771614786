import styled from '@emotion/styled';
import { GlobalTokenColor, BorderColor } from 'ui/theme/Color';
import { TextLineHeight, TextSize, TextWeight } from 'ui/theme/CustomTypography';

interface TagProps {
  content?: string;
  status?:
    | 'completion' // 긍정/완료
    | 'ongoing' // 진행중
    | 'waiting' // 대기
    | 'waitingCompletion' // 완료후 대기중
    | 'emergency' // 부정/긴급
    | 'end' // 종료/만료/비활성
    | 'possibility'; // 가능/Task명
  customColor?: string;
  customBackgroundColor?: string;
  customBorderColor?: string;
}

const statusStyles = {
  completion: {
    color: GlobalTokenColor.StatusConfirmed700,
    backgroundColor: GlobalTokenColor.StatusConfirmed100,
    borderColor: GlobalTokenColor.StatusConfirmed300,
  },
  ongoing: {
    color: GlobalTokenColor.Secondary500,
    backgroundColor: GlobalTokenColor.Secondary100,
    borderColor: GlobalTokenColor.Secondary300,
  },
  waiting: {
    color: GlobalTokenColor.StatusWarningMinor700,
    backgroundColor: GlobalTokenColor.StatusWarningMinor100,
    borderColor: GlobalTokenColor.StatusWarningMinor300,
  },
  waitingCompletion: {
    color: GlobalTokenColor.StatusWarningMajor700,
    backgroundColor: GlobalTokenColor.StatusWarningMajor100,
    borderColor: GlobalTokenColor.StatusWarningMajor300,
  },
  emergency: {
    color: GlobalTokenColor.StatusError700,
    backgroundColor: GlobalTokenColor.StatusError100,
    borderColor: GlobalTokenColor.StatusError300,
  },
  end: {
    color: GlobalTokenColor.ESGrey700,
    backgroundColor: GlobalTokenColor.ESGrey100,
    borderColor: GlobalTokenColor.ESGrey500,
  },
  possibility: {
    color: GlobalTokenColor.LightBlue600,
    backgroundColor: GlobalTokenColor.LightBlue100,
    borderColor: GlobalTokenColor.LightBlue300,
  },
};

const StyledTag = styled.div<TagProps>`
  display: inline-flex;
  padding: 1px 4px;
  justify-content: center;
  align-items: center;
  border: 1px solid
    ${(props) =>
      props.customBorderColor ||
      (props.status && statusStyles[props.status]?.borderColor) ||
      BorderColor};
  color: ${(props) =>
    props.customColor || (props.status && statusStyles[props.status]?.color) || 'inherit'};
  background-color: ${(props) =>
    props.customBackgroundColor ||
    (props.status && statusStyles[props.status]?.backgroundColor) ||
    'transparent'};
  font-size: ${TextSize.Small};
  font-weight: ${TextWeight.Normal};
  line-height: normal;
  border-radius: 2px;
  height: 20px;
`;

const Tag = ({
  status,
  content,
  customColor,
  customBackgroundColor,
  customBorderColor,
}: TagProps) => {
  return (
    <StyledTag
      status={status}
      content={content}
      customColor={customColor}
      customBackgroundColor={customBackgroundColor}
      customBorderColor={customBorderColor}
    >
      {content}
    </StyledTag>
  );
};

export default Tag;
