import { MenuAtchFileDesc } from 'models/admin/MenuAtchFile';
import {
  CommonRequest,
  CommonResponse,
  DmlResponse,
  Method,
  ServiceName,
} from 'models/common/RestApi';
import { callApi } from 'utils/ApiUtil';

export const getMenuAtchFiles = async (menuId: string, atchFileKdnCd: string, langCd?: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/api/menuAtchFile`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({
      menuId: menuId ?? '',
      atchFileKdnCd: atchFileKdnCd ?? '',
      langCd: langCd ?? '',
    }),
  };
  const response: CommonResponse<any> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as MenuAtchFileDesc[];
};

export const createMenuAtchFile = async (menuAtchFile: MenuAtchFileDesc) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/api/menuAtchFile/insert`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    bodyParams: menuAtchFile,
  };

  const response: CommonResponse<DmlResponse> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as DmlResponse;
};

export const modifyMenuAtchFile = async (menuAtchFile: MenuAtchFileDesc) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/api/menuAtchFile/modify`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    bodyParams: menuAtchFile,
  };

  const response: CommonResponse<DmlResponse> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as DmlResponse;
};
