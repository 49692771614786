import { ColDef } from 'ag-grid-community';
import GTag from 'components/Gtag/GTag';
import { DefaultCellTooltip } from 'components/grids/tooltip/DefaultCellTooltip';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { replaceWithTimezone } from 'utils/TimeZoneUtil';

export const useColumnDefsForApprovalList = () => {
  const { t } = useTranslation();

  const defaultColDef = useMemo((): ColDef => {
    return {
      sortable: true,
      resizable: true,
      cellStyle: { textAlign: 'center' },
      tooltipComponent: DefaultCellTooltip,
    };
  }, []);

  const getTagRender = (params) => {
    const value: string = params.data.mbrPsgStatCdNm || '';
    if (value == '승인완료') {
      return (
        <>
          <GTag status="completion">{t('com.label.00296', '승인완료')}</GTag>
        </>
      );
    } else if (value == '처리중') {
      return (
        <>
          <GTag status="ongoing">{t('com.label.00299', '처리중')}</GTag>
        </>
      );
    }
  };

  const colDefs: any = [
    {
      headerName: t('No.', 'No.'),
      field: '#',
      tooltipField: 'no',
      cellStyle: { display: 'flex', justifyContent: 'center' },
      valueGetter: 'node.rowIndex + 1',
      width: 50,
      minWidth: 50,
      sortable: false,
      headerClass: 'fixed',
    },
    {
      headerName: t('Vendor ID', 'Vendor ID'),
      headerTooltip: t('Vendor ID', 'Vendor ID'),
      field: 'vdcpCd',
      tooltipField: 'vdcpCd',
      cellStyle: { textAlign: 'left' },
      width: 110,
    },
    {
      headerName: `${t('com.label.00017', '업체명')}(ERP)`,
      headerTooltip: `${t('com.label.00017', '업체명')}(ERP)`,
      field: 'vdcpErpNm',
      tooltipField: 'vdcpErpNm',
      cellStyle: { textAlign: 'left' },
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: `${t('com.label.00017', '업체명')} (${t('com.label.00514', '거래처')})`,
      headerTooltip: `${t('com.label.00017', '업체명')} (${t('com.label.00514', '거래처')})`,
      field: 'vdcpNm',
      tooltipField: 'vdcpNm',
      cellStyle: { textAlign: 'left' },
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: t('com.label.00012', '이름'),
      headerTooltip: t('com.label.00012', '이름'),
      field: 'vdcpMgrNm',
      tooltipField: 'vdcpMgrNm',
      width: 110,
    },
    {
      headerName: t('com.label.00090', '이메일'),
      headerTooltip: t('com.label.00090', '이메일'),
      field: 'vdcpMgrEmal',
      tooltipField: 'vdcpMgrEmal',
      cellStyle: { textAlign: 'left' },
      width: 220,
    },
    {
      headerName: t('com.label.00096', '법인코드'),
      headerTooltip: t('com.label.00096', '법인코드'),
      field: 'soprMgrCopCd',
      tooltipField: 'soprMgrCopCd',
      width: 110,
    },
    {
      headerName: t('com.label.00095', '담당자'),
      headerTooltip: t('com.label.00095', '담당자'),
      field: 'soprMgrNm',
      tooltipField: 'soprMgrNm',
      width: 110,
    },
    {
      headerName: t('com.label.00506', '처리상태'),
      headerTooltip: t('com.label.00506', '처리상태'),
      field: 'mbrPsgStatCdNm',
      tooltipField: 'mbrPsgStatCdNm',
      cellRenderer: getTagRender,
      width: 100,
    },
    {
      headerName: t('com.label.reqDtm', '__요청일시'),
      headerTooltip: t('com.label.reqDtm', '__요청일시'),
      field: 'dataInsDtm',
      tooltipField: 'dataInsDtm',
      valueFormatter: (params: any) => replaceWithTimezone(params.value, 'YYYY-MM-DD HH:mm:ss'),
      width: 180,
    },
  ];

  const handleAprvDetailBtn = (data) => {
    console.log('화면 연결이 필요합니다.');
  };

  return { defaultColDef, colDefs };
};
