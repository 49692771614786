import styled from '@emotion/styled';
import defaultProfileImg from 'components/asset/images/defaultProfileImg.png';

interface ProfileProps {
  type?: '1' | '2';
  profileImg?: string;
  nickName?: string;
  isPosition?: boolean;
  position?: string;
}

export default function Profile({
  type = '1',
  profileImg = `${defaultProfileImg}`,
  nickName = '김엔솔',
  isPosition = false,
  position = '선임',
}: ProfileProps) {
  return (
    <Container type={type}>
      <ProfileImg src={`${profileImg}`} type={type} />
      <Nickname type={type}>{nickName}</Nickname>
      {isPosition ? <Position>{position}</Position> : <></>}
    </Container>
  );
}

const Container = styled.div<ProfileProps>`
  display: flex;
  align-items: center;
  gap: ${(props) => (props.type === '1' ? `8px` : '4px')};
`;

const ProfileImg = styled.img<ProfileProps>`
  width: ${(props) => (props.type === '1' ? `24px` : '20px')};
  border-radius: 50px;
`;

const TextStyle = styled.p<ProfileProps>`
  color: #1f1f1f;
  font-size: ${(props) => (props.type === '1' ? `13px` : '12px')};
  font-weight: ${(props) => (props.type === '1' ? `500` : '700')};
  line-height: 150%;
`;

const Nickname = styled(TextStyle)``;

const Position = styled(TextStyle)``;
