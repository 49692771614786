import { create } from 'zustand';
import { Session, LangType, GridNoRowsTemplateData } from 'models/common/Session';
import { Menu } from 'models/admin/Menu';
import { AccessLevelInfo } from 'models/admin/AccessLevel';
import { BmkResponse } from 'models/cims/Bmk';

interface SessionState {
  userId: string;
  userNm: string;
  userNo: string; //사원번호
  empNm: string;
  langCd: LangType | '';
  roleCodes: string[];
  accessLevelInfo?: AccessLevelInfo;
  menus: Menu[];
  bmkMenus: BmkResponse[]; // 북마크
  headerMenus: Menu[];
  timeZoneCd: string;
  userCopCd: string;
  userDeptCd: string;
  deptNm: string; //부서명
  deptEngNm: string; //부서영문명
  deptCngNm: string; //부서중문명
  empNo?: string;
  empEngNm?: string;
  empCngNm?: string;
  deptCd?: string;
  copCd?: string;
  jtiCd?: string;
  jtiNm?: string;
  jtiEngNm?: string;
  jtiCngNm?: string;
  jpsCd?: string;
  jpsNm?: string;
  jpsEngNm?: string;
  jpsCngNm?: string;
  upprEmpNo?: string;
  upprUserId?: string;
  onduRegnCd?: string;
  onduRegnNm?: string;
  ctryCd?: string;
  teamYn: string;
  tldYn: string;
  leasTeamYn: string;
  leasTldYn: string;
  gleasTeamYn: string;
  gleasTldYn: string;
  mgrDeptCd: string;
  coCd: string;
  coNm: string;
  bplcCd: string;
  bplcNm: string;
  bizAreaCd: string;
  bizAreaNm: string;
  cctrCd: string;
  cctrNm: string;
  dmstYn: string;
  bokCurrCd: string;
  resetSession: () => void;
  setSession: (session: Session) => void;
  setLangCd: (langCd: LangType) => void;
  setBmkMenus: (bmkMenus: BmkResponse[]) => void;
  gridNoRowsTemplate: GridNoRowsTemplateData;
  isAdm: boolean;
}

export const homeMenu: Menu = {
  mnuId: '',
  mnuNm: 'HOME',
  mnuUrl: '',
};

export const useSessionStore = create<SessionState>((set, get) => {
  return {
    userId: '',
    empNm: '',
    userNm: '',
    userNo: '',
    langCd: '',
    roleCodes: [],
    accessLevelInfo: { accessLevels: [], prjCds: [] },
    // accessLevels: [],
    menus: [],
    bmkMenus: [],
    headerMenus: [],
    timeZoneCd: '',
    userCopCd: '',
    userDeptCd: '',
    empNo: '',
    empEngNm: '',
    empCngNm: '',
    deptCd: '',
    deptNm: '',
    deptEngNm: '',
    deptCngNm: '',
    copCd: '',
    jtiCd: '',
    jtiNm: '',
    jtiEngNm: '',
    jtiCngNm: '',
    jpsCd: '',
    jpsNm: '',
    jpsEngNm: '',
    jpsCngNm: '',
    upprEmpNo: '',
    upprUserId: '',
    onduRegnCd: '',
    onduRegnNm: '',
    ctryCd: '',
    teamYn: '',
    tldYn: '',
    leasTeamYn: '',
    leasTldYn: '',
    gleasTeamYn: '',
    gleasTldYn: '',
    mgrDeptCd: '',
    gridNoRowsTemplate: GridNoRowsTemplateData.ko,
    coCd: '',
    coNm: '',
    bplcCd: '',
    bplcNm: '',
    bizAreaCd: '',
    bizAreaNm: '',
    cctrCd: '',
    cctrNm: '',
    dmstYn: '',
    bokCurrCd: '',
    isAdm: false,
    resetSession: async () => {
      set({
        userId: '',
        empNm: '',
        userNm: '',
        userNo: '',
        langCd: '',
        roleCodes: [],
        accessLevelInfo: { accessLevels: [], prjCds: [] },
        menus: [],
        bmkMenus: [],
        headerMenus: [],
        timeZoneCd: '',
        userCopCd: '',
        userDeptCd: '',
        empNo: '',
        empEngNm: '',
        empCngNm: '',
        deptCd: '',
        deptNm: '',
        deptEngNm: '',
        deptCngNm: '',
        copCd: '',
        jtiCd: '',
        jtiNm: '',
        jtiEngNm: '',
        jtiCngNm: '',
        jpsCd: '',
        jpsNm: '',
        jpsEngNm: '',
        jpsCngNm: '',
        upprEmpNo: '',
        upprUserId: '',
        onduRegnCd: '',
        onduRegnNm: '',
        ctryCd: '',
        teamYn: '',
        tldYn: '',
        leasTeamYn: '',
        leasTldYn: '',
        gleasTeamYn: '',
        gleasTldYn: '',
        mgrDeptCd: '',
        gridNoRowsTemplate: GridNoRowsTemplateData.ko,
        coCd: '',
        coNm: '',
        bplcCd: '',
        bplcNm: '',
        bizAreaCd: '',
        bizAreaNm: '',
        cctrCd: '',
        cctrNm: '',
        dmstYn: '',
        bokCurrCd: '',
        isAdm: false,
      });
    },
    setSession: (session: Session) => {
      const headerMenuList =
        session.menus?.filter((item) => {
          return item['upprMnuId'] === '000000' && item['mnuEpsYn'] != 'N';
        }) ?? [];

      set({
        userId: session.userId,
        empNm: session.empNm,
        userNm: session.userNm,
        userNo: session.userNo,
        langCd: session.langCd,
        roleCodes: session.roleCodes,
        accessLevelInfo: session.accessLevelInfo,
        menus: session.menus,
        bmkMenus: session.bmkMenus,
        headerMenus: headerMenuList,
        timeZoneCd: session.timeZoneCd,
        userCopCd: session.userCopCd,
        userDeptCd: session.userDeptCd,
        empNo: session.empNo,
        empEngNm: session.empEngNm,
        empCngNm: session.empCngNm,
        deptCd: session.deptCd,
        deptNm: session.deptNm,
        deptEngNm: session.deptEngNm,
        deptCngNm: session.deptCngNm,
        copCd: session.copCd,
        jtiCd: session.jtiCd,
        jtiNm: session.jtiNm,
        jtiEngNm: session.jtiEngNm,
        jtiCngNm: session.jtiCngNm,
        jpsCd: session.jpsCd,
        jpsNm: session.jpsNm,
        jpsEngNm: session.jpsEngNm,
        jpsCngNm: session.jpsCngNm,
        upprEmpNo: session.upprEmpNo,
        upprUserId: session.upprUserId,
        onduRegnCd: session.onduRegnCd,
        onduRegnNm: session.onduRegnNm,
        ctryCd: session.ctryCd,
        teamYn: session.teamYn,
        tldYn: session.tldYn,
        leasTeamYn: session.leasTeamYn,
        leasTldYn: session.leasTldYn,
        gleasTeamYn: session.gleasTeamYn,
        gleasTldYn: session.gleasTldYn,
        mgrDeptCd: session.mgrDeptCd,
        gridNoRowsTemplate: GridNoRowsTemplateData[session.langCd || 'ko'],
        coCd: session.coCd,
        coNm: session.coNm,
        bplcCd: session.bplcCd,
        bplcNm: session.bplcNm,
        bizAreaCd: session.bizAreaCd,
        bizAreaNm: session.bizAreaNm,
        cctrCd: session.cctrCd,
        cctrNm: session.cctrNm,
        dmstYn: session.dmstYn,
        bokCurrCd: session.bokCurrCd,
        isAdm: session.roleCodes ? session.roleCodes.includes('ADM') : false,
      });
      sessionStorage.setItem('roleCodes', JSON.stringify(session.roleCodes)); //TODO
      sessionStorage.setItem('leasTeamYn', session.leasTeamYn || '');
      sessionStorage.setItem('leasTldYn', session.leasTldYn || '');
      sessionStorage.setItem('gleasTeamYn', session.gleasTeamYn || '');
      sessionStorage.setItem('gleasTldYn', session.gleasTldYn || '');
    },
    setLangCd: (langCd: LangType) => {
      set((prev) => ({ ...prev, langCd: langCd }));
    },
    setBmkMenus: (bmkMenus: BmkResponse[]) => {
      set((prev) => ({ ...prev, bmkMenus: bmkMenus }));
    },
  };
});
export default useSessionStore;
