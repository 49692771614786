import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import avatarUser from 'components/asset/images/avatarUser.svg';
import styled from '@emotion/styled';
import { Avatar } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

export interface AvatarChipProps {
  disabled?: boolean;
  size?: 'largest' | 'medium' | 'small';
  type?: 'text' | 'user' | 'custom' | 'onlyText';
  width?: string;
  backgroundColor?: 'white' | 'blue' | 'lightGray' | 'darkGray';
  backgroundBorder?: 'white' | 'blue' | 'lightGray' | 'defaultGray' | 'darkGray' | 'none';
  userId?: string;
  nickName?: string;
  nickNameColor?: 'white' | 'gray' | 'blue';
  profileText?: string;
  profileBackgroundColor?: string;
  profileBorder?: string;
  profileItemColor?: string;
  profileImg?: string;
}

export default function AvatarChip({
  disabled = false,
  size = 'largest',
  type = 'user',
  width = 'fit-content',
  backgroundColor = 'lightGray',
  backgroundBorder = 'lightGray',
  userId = '',
  nickName = 'Text',
  nickNameColor = 'gray',
  profileText = '엘지',
  profileBackgroundColor = '#554596',
  profileBorder = '#554596',
  profileItemColor = '',
  profileImg = `${avatarUser}`,
}: AvatarChipProps) {
  // 50글자 이상이면 말줄임표
  const getNameEllipsis = (name) => {
    if (!name) return false;
    if (name.length <= 50) {
      return name;
    } else {
      return `${name.substring(0, 5)}...`;
    }
  };

  const ellipsisName = getNameEllipsis(nickName);

  const handleClickChip = (e) => {
    if (userId) {
      popupCenter({
        url: `http://gportalapp.lgensol.com/support/profile/getProfile.do?targetUserId=${userId}`,
        title: '',
        w: 1102,
        h: 870,
      });
    }
  };

  const popupCenter = ({ url, title, w, h }) => {
    // Fixes dual-screen position                             Most browsers      Firefox
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width;
    const height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft;
    const top = (height - h) / 2 / systemZoom + dualScreenTop;

    const newWindow = window.open(
      url,
      title,
      `
      scrollbars=yes,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
    );

    if (newWindow) newWindow.focus();
  };

  return (
    <StackCustom
      direction="row"
      spacing={1}
      disabled={disabled}
      size={size}
      width={width}
      backgroundColor={backgroundColor}
      backgroundBorder={backgroundBorder}
      nickNameColor={nickNameColor}
      profileBackgroundColor={profileBackgroundColor}
      profileBorder={profileBorder}
      profileItemColor={profileItemColor}
      style={!ellipsisName ? { display: 'none' } : userId ? { cursor: 'pointer' } : {}}
      onClick={handleClickChip}
    >
      {type === 'text' && (
        <Chip
          avatar={<Avatar>{profileText}</Avatar>}
          label={
            <Tooltip
              title={<Tip>{nickName}</Tip>}
              placement="bottom"
              slotProps={{
                popper: {
                  sx: {
                    [`& .${tooltipClasses.tooltip}`]: {
                      backgroundColor: '#3c3e3d',
                      color: '#fff',
                      maxWidth: 500,
                      fontSize: 12,
                      borderRadius: '2px',
                    },
                  },
                },
              }}
              disableHoverListener={ellipsisName === nickName}
            >
              {ellipsisName}
            </Tooltip>
          }
        />
      )}
      {type === 'user' && (
        <Chip
          avatar={<Avatar sx={{ padding: '3px' }} src={`${profileImg}`} />}
          label={
            <Tooltip
              title={<Tip>{nickName}</Tip>}
              placement="bottom"
              slotProps={{
                popper: {
                  sx: {
                    [`& .${tooltipClasses.tooltip}`]: {
                      backgroundColor: '#3c3e3d',
                      color: '#fff',
                      maxWidth: 500,
                      fontSize: 12,
                      borderRadius: '2px',
                    },
                  },
                },
              }}
              disableHoverListener={ellipsisName === nickName}
            >
              {ellipsisName}
            </Tooltip>
          }
        />
      )}
      {type === 'custom' && (
        <Chip
          avatar={<Avatar sx={{ padding: '0' }} src={`${profileImg}`} />}
          label={
            <Tooltip
              title={<Tip>{nickName}</Tip>}
              placement="bottom"
              slotProps={{
                popper: {
                  sx: {
                    [`& .${tooltipClasses.tooltip}`]: {
                      backgroundColor: '#3c3e3d',
                      color: '#fff',
                      maxWidth: 500,
                      fontSize: 12,
                      borderRadius: '2px',
                    },
                  },
                },
              }}
              disableHoverListener={ellipsisName === nickName}
            >
              {ellipsisName}
            </Tooltip>
          }
        />
      )}
      {type === 'onlyText' && (
        <Chip
          className="onlyText"
          label={
            <Tooltip
              title={<Tip>{nickName}</Tip>}
              placement="bottom"
              slotProps={{
                popper: {
                  sx: {
                    [`& .${tooltipClasses.tooltip}`]: {
                      backgroundColor: '#3c3e3d',
                      color: '#fff',
                      maxWidth: 500,
                      fontSize: 12,
                      borderRadius: '2px',
                    },
                  },
                },
              }}
              disableHoverListener={ellipsisName === nickName}
            >
              {ellipsisName}
            </Tooltip>
          }
        />
      )}
    </StackCustom>
  );
}

const StackCustom = styled(Stack)<AvatarChipProps>`
  float: left;
  margin: 0 2px;
  background-color: transparent;
  opacity: ${(props) => (props.disabled ? '0.7' : '1')};

  .onlyText {
    span {
      padding: 0 12px;
    }
  }

  & > div {
    height: auto;
    margin: 0;
    padding: ${(props) => (props.size === 'small' ? '2px' : '4px')};

    /* border */
    ${(props) => props.backgroundBorder === 'none' && 'box-shadow: 0'}
    ${(props) => props.backgroundBorder === 'white' && 'box-shadow: 0 0 0 1px #fff inset;'}
    ${(props) => props.backgroundBorder === 'lightGray' && 'box-shadow: 0 0 0 1px #EBEEED inset;'}
    ${(props) => props.backgroundBorder === 'defaultGray' && 'box-shadow: 0 0 0 1px #979998 inset;'}
    ${(props) => props.backgroundBorder === 'darkGray' && 'box-shadow: 0 0 0 1px #5B5C5B inset;'}
    ${(props) => props.backgroundBorder === 'blue' && 'box-shadow: 0 0 0 1px #2D9BB2 inset;'}

    /* background-color */
    ${(props) => props.backgroundColor === 'white' && 'background-color: #fff;'}
    ${(props) => props.backgroundColor === 'lightGray' && 'background-color: #EBEEED;'}
    ${(props) => props.backgroundColor === 'darkGray' && 'background-color: #5B5C5B;'}
    ${(props) => props.backgroundColor === 'blue' && 'background-color: #2D9BB2;'}
  }

  .MuiAvatar-root {
    border: 1px solid ${(props) => props.profileBorder};
    background-color: ${(props) => props.profileBackgroundColor};
    color: ${(props) => props.profileItemColor};
  }

  .onlyText {
    padding: 4px;
    border: 1px solid ${(props) => props.profileBorder};
    background-color: ${(props) => props.profileBackgroundColor};
  }

  .MuiChip-label {
    padding: ${(props) => (props.size === 'small' ? '0 8px 0 4px' : '0 12px 0 6px')};
    ${(props) => props.nickNameColor === 'white' && 'color: #fff'};
    ${(props) => props.nickNameColor === 'gray' && 'color: #5B5C5B'};
    ${(props) => props.nickNameColor === 'blue' && 'color: #2D9BB2'};
    font-size: ${(props) => (props.size === 'medium' || props.size === 'small' ? '12px' : '13px')};
    font-weight: 400;
    line-height: 150%; /* 19.5px */
  }

  .MuiChip-root {
    ${(props) => props.size === 'small' && 'height: 24px'};
    ${(props) => props.size === 'medium' && 'height: 28px'};
    ${(props) => props.size === 'largest' && 'height: 32px'};
  }

  .MuiChip-root .MuiChip-avatar {
    ${(props) => props.size === 'small' && 'width: 20px; padding: 4px'};
    ${(props) => props.size === 'medium' && 'width: 20px; padding: 3px'};
    ${(props) => props.size === 'largest' && 'width: 24px; padding: 4px;'};
    ${(props) => props.size === 'small' && 'height: 20px'};
    ${(props) => props.size === 'medium' && 'height: 20px'};
    ${(props) => props.size === 'largest' && 'height: 24px'};
    margin: 0;
    border: 1px solid ${(props) => props.profileBorder};
    background-color: ${(props) => props.profileBackgroundColor};
    color: ${(props) => props.profileItemColor};
  }
`;

/* 툴팁 */
export const Tip = styled.span`
  font-family: 'Spoqa Han Sans Neo';
  color: #fff;
  font-weight: 300;
  font-size: 12px;
`;
