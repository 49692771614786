import styled from '@emotion/styled';
import { GlobalTokenColor } from 'ui/theme/Color';
import { TextSize, TextWeight } from 'ui/theme/CustomTypography';

interface GTagProps {
  status:
    | 'confirmed'
    | 'warning'
    | 'error'
    | 'disabled'
    | 'default'
    | 'completion' // 긍정/완료
    | 'ongoing' // 진행중
    | 'waiting' // 대기
    | 'waitingCompletion' // 완료후 대기중
    | 'emergency' // 부정/긴급
    | 'end' // 종료/만료/비활성
    | 'possibility'; // 가능/Task명';
  children?: React.ReactNode;
}

const statusStyles = {
  confirmed: {
    color: GlobalTokenColor.StatusConfirmed700,
    backgroundColor: GlobalTokenColor.StatusConfirmed100,
    borderColor: GlobalTokenColor.StatusConfirmed300,
    text: '추가',
  },
  warning: {
    color: GlobalTokenColor.StatusWarningMinor700,
    backgroundColor: GlobalTokenColor.StatusWarningMinor100,
    borderColor: GlobalTokenColor.StatusWarningMinor300,
    text: '변경',
  },
  error: {
    color: GlobalTokenColor.Pink600,
    backgroundColor: GlobalTokenColor.Pink100,
    borderColor: GlobalTokenColor.Pink300,
    text: '삭제',
  },
  disabled: {
    color: GlobalTokenColor.ESGrey700,
    backgroundColor: GlobalTokenColor.ESGrey100,
    borderColor: GlobalTokenColor.ESGrey300,
    text: 'Disabled',
  },
  default: {
    color: GlobalTokenColor.ESGrey700,
    backgroundColor: GlobalTokenColor.ESGreyWHite,
    borderColor: GlobalTokenColor.ESGrey100,
    text: 'Default',
  },
  completion: {
    color: GlobalTokenColor.StatusConfirmed700,
    backgroundColor: GlobalTokenColor.StatusConfirmed100,
    borderColor: GlobalTokenColor.StatusConfirmed300,
    text: '',
  },
  ongoing: {
    color: GlobalTokenColor.Secondary500,
    backgroundColor: GlobalTokenColor.Secondary100,
    borderColor: GlobalTokenColor.Secondary300,
    text: '',
  },
  waiting: {
    color: GlobalTokenColor.StatusWarningMinor700,
    backgroundColor: GlobalTokenColor.StatusWarningMinor100,
    borderColor: GlobalTokenColor.StatusWarningMinor300,
    text: '',
  },
  waitingCompletion: {
    color: GlobalTokenColor.StatusWarningMajor700,
    backgroundColor: GlobalTokenColor.StatusWarningMajor100,
    borderColor: GlobalTokenColor.StatusWarningMajor300,
    text: '',
  },
  emergency: {
    color: GlobalTokenColor.StatusError700,
    backgroundColor: GlobalTokenColor.StatusError100,
    borderColor: GlobalTokenColor.StatusError300,
    text: '',
  },
  end: {
    color: GlobalTokenColor.ESGrey700,
    backgroundColor: GlobalTokenColor.ESGrey100,
    borderColor: GlobalTokenColor.ESGrey500,
    text: '',
  },
  possibility: {
    color: GlobalTokenColor.LightBlue600,
    backgroundColor: GlobalTokenColor.LightBlue100,
    borderColor: GlobalTokenColor.LightBlue300,
    text: '',
  },
};

const StyledGTag = styled.span<GTagProps>`
  display: inline-block;
  height: 20px;
  padding: 1px 4px;
  font-size: ${TextSize.Small};
  font-weight: ${TextWeight.Normal};
  line-height: 16px;
  border-radius: 2px;
  border: 1px solid
    ${(props) => statusStyles[props.status]?.borderColor || statusStyles.default.borderColor};
  color: ${(props) => statusStyles[props.status]?.color || statusStyles.default.color};
  background-color: ${(props) =>
    statusStyles[props.status]?.backgroundColor || statusStyles.default.backgroundColor};
`;

const GTag = ({ status, children }: GTagProps) => {
  const { text } = statusStyles[status] || statusStyles.default;

  return <StyledGTag status={status}>{children || text}</StyledGTag>;
};

export default GTag;
