import styled from '@emotion/styled';
import { Autocomplete, Chip, TextField, Checkbox } from '@mui/material';
import DarkSearch from 'components/asset/images/DarkSearch.svg';
import { CommonCode } from 'models/admin/CommonCode';
import ErrorIcon from 'components/asset/images/error.svg';
import WarningIcon from 'components/asset/images/warning.svg';
import ConfirmedIcon from 'components/asset/images/confirmed.svg';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import chipUser from 'components/asset/images/chipUser.svg';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const dummyData = [
  'IP000',
  'IP111',
  'IP222',
  'IP333',
  'IP444',
  'IP555',
  'IP666',
  'IP777',
  'IP888',
  'IP999',
];

interface SelectChipBoxProps {
  disabled?: boolean;
  readonly?: boolean;
  width?: string | '100%';
  height?: string | '32px';
  status?: 'default' | 'error' | 'warning' | 'confirm';
  type?: '1' | '2';
  chipType?: 'default' | 'user';
  chipImg?: string;
  format?: string | '';
  onClickFunction?: () => void | undefined;
  codeKey?: string;
  codeName?: string;
  optionList?: CommonCode[];
  defaultOption?: CommonCode;
  handleChange?: (e: any, value: any) => void;
  value?: CommonCode[];
  disableCloseOnSelect?: boolean;
}

export default function ChipSelectBox({
  readonly,
  disabled,
  width,
  height,
  status = 'default',
  type,
  chipType = 'default',
  chipImg = '${avatarUser}',
  format,
  onClickFunction,
  codeKey,
  codeName,
  optionList = [],
  defaultOption,
  handleChange,
  value,
  disableCloseOnSelect = false,
}: SelectChipBoxProps) {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIconCustom fontSize="small" />;
  return (
    <Container
      width={width}
      height={height}
      status={status}
      type={type}
      readonly={readonly}
      disabled={disabled}
      chipType={chipType}
      chipImg={chipImg}
    >
      {optionList.length > 0 && (
        <Autocomplete
          limitTags={1}
          disableCloseOnSelect={disableCloseOnSelect}
          readOnly={readonly}
          disabled={disabled}
          multiple
          options={optionList}
          defaultValue={[optionList[0]]}
          getOptionLabel={(option) => (codeName ? option[codeName] : option)}
          onChange={handleChange}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
              {codeName && option ? option[codeName] : option}
            </li>
          )}
          value={value || [optionList[0]]}
          renderInput={(params) => <TextField {...params} placeholder={format} />}
        />
      )}
      {type === '1' ? <SearchButton onClick={onClickFunction}></SearchButton> : <></>}
    </Container>
  );
}

// const Container = styled.div<InputChipBoxProps>`
//   position: relative;
//   width: ${(props) => props.width};
//   height: ${(props) => props.height};

//   .MuiOutlinedInput-root {
//     position: relative;
//     width: 100%;
//     height: 100%;
//     padding-right: 0;
//     border: 1px solid #b9bcbb;
//     border-radius: 2px;
//     gap: 4px;
//     background-color: #fff;
//     ${(props) => props.status === 'default' && 'border: 1px solid #b9bcbb;'}
//     ${(props) => props.status === 'error' && 'border: 1px solid #F94B50;'}
//     ${(props) => props.status === 'warning' && 'border: 1px solid #FF9322;'}
//     ${(props) => props.status === 'confirm' && 'border: 1px solid #00806A;'}
//   }

//   .MuiAutocomplete-root .MuiAutocomplete-inputRoot {
//     height: ${(props) => props.height};
//     padding-right: 0;
//     ${(props) => (props.type === '1' ? 'padding: 5px 8px 5px 28px;' : 'padding: 5px 8px 5px 8px;')}
//   }

//   .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
//     width: 100%;
//     padding: 0;
//   }

//   .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.MuiAutocomplete-root
//     .MuiAutocomplete-inputRoot {
//     padding-right: 34px;
//   }

//   .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-root .MuiOutlinedInput-root,
//   .MuiAutocomplete-hasClearIcon.MuiAutocomplete-root .MuiOutlinedInput-root {
//     ${(props) => (props.type === '1' ? 'padding: 5px 8px 5px 28px;' : 'padding: 5px 8px 5px 8px;')}
//   }

//   .MuiOutlinedInput-root.MuiInputBase-formControl {
//     position: relative;
//     height: 100%;
//     min-height: 32px;
//   }

//   .MuiOutlinedInput-root .MuiAutocomplete-input {
//     height: 20px;
//     padding: 0;
//     width: min-content;
//     color: #1f1f1f;
//     font-size: 13px;
//     font-style: normal;
//     font-weight: 400;
//     line-height: 150%; /* 19.5px */
//   }
//   .MuiOutlinedInput-root {
//     display: flex;
//     gap: 0 0 10px 0;
//     padding: 0;
//   }

//   .MuiFormControl-root.MuiTextField-root {
//     width: 100%;
//     height: 100%;
//   }

//   .MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator {
//     ${(props) =>
//       props.type === '1'
//         ? `
//     width: 14px;
//     height: 14px;
//     margin-top:4px;
//     margin-right: 4px;
//     visibility: visible;
//     color: #fff;
//     background-color: #5b5c5b;
//     `
//         : `
//     width: 0;
//     height: 0;
//     margin: 0;
//     visibility: visible;
//     color: #fff;
//     background-color: #fff;
//     `}
//     & > svg {
//       padding: 2px;
//       ${(props) =>
//         props.type === '1'
//           ? `
//         margin: 2px;
//     `
//           : `
//     margin: 0;
//     `}
//     }
//   }

//   .MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator {
//     position: relative;
//     margin-top: 4px;
//     &:hover {
//       background-color: none;
//     }
//     ${(props) =>
//       props.type === '1'
//         ? `
//           width: 0;
//           height: 0;`
//         : ''}
//     & > svg {
//       position: absolute;
//       top: 50%;
//       left: 0;
//       transform: translate(0, -50%);
//       ${(props) =>
//         props.type === '1'
//           ? `
//           width: 0;
//           height: 0;`
//           : ''}
//     }
//   }

//   .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
//     padding: 0;
//     width: 0;
//   }

//   fieldset {
//     position: block;
//     padding: 0;
//     border-style: none;
//   }

//   .MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator {
//     background-color: none;
//     padding: 0;
//     margin-top: 4px;
//     & > svg {
//       ${(props) =>
//         props.type === '1'
//           ? `
//           width: 0;
//           height: 0;`
//           : ''}
//     }
//     & > span {
//       ${(props) =>
//         props.type === '1'
//           ? `
//           width: 0;
//           height: 0;`
//           : ``}
//     }
//     .MuiSvgIcon-root {
//       ${(props) =>
//         props.type === '1'
//           ? `
//           width: 0;
//           height: 0;`
//           : ''}
//     }
//     &:hover {
//       background-color: none;
//     }
//   }
// `;

const Container = styled.div<SelectChipBoxProps>`
  z-index: 10;
  position: relative;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  ${(props) => (props.disabled ? `opacity: 0.6; pointer-events: none;` : `opacity: 1;`)};
  ${(props) => (props.readonly ? `pointer-events: none;` : ``)};

  &::after {
    ${(props) => props.status === 'error' && `content: url('${ErrorIcon}');`}
    ${(props) => props.status === 'warning' && `content: url('${WarningIcon}');`}
    ${(props) => props.status === 'confirm' && `content: url('${ConfirmedIcon}');`}
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translate(0, -50%);
    width: 16px;
    height: 16px;
    background-color: #fff;
    cursor: pointer;
  }

  .MuiAutocomplete-root {
    .MuiTextField-root {
      .MuiOutlinedInput-root {
        ${(props) =>
          props.type === '1' ? 'padding: 6px 50px 6px 30px;' : 'padding: 6px 50px 6px 8px;'}
        display: flex;
        gap: 4px 2px;
        background-color: ${(props) => (props.readonly ? `#f1f4f3` : `#fff`)};
        .MuiAutocomplete-input {
          min-width: 0;
          height: 20px;
          padding: 0;
        }
        .MuiAutocomplete-endAdornment {
          top: 50%;
          transform: translate(0, -50%);
          ${(props) => (props.type === '1' ? `display:flex; flex-direction: row-reverse;` : ``)}
          .MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator {
            width: 14px;
            height: 14px;
            margin-right: 0;
            background-color: #5b5c5b;
            .MuiSvgIcon-root {
              padding: 2px 2px 2px 4px;
              color: #fff;
            }
          }
          .MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator {
            ${(props) => (props.type === '1' ? `width: 0; height: 0; padding: 0;` : ``)}
            .MuiSvgIcon-root {
              ${(props) => (props.type === '1' ? `width: 0;` : ``)}
            }
          }
          .MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator {
            ${(props) => (props.type === '1' ? `width: 0; height: 0; padding: 0;` : ``)}
            .MuiSvgIcon-root {
              ${(props) => (props.type === '1' ? `width: 0;` : ``)}
            }
          }
        }
        .MuiOutlinedInput-notchedOutline {
          top: 0;
          width: 100%;
          padding: 0;
          border-radius: 2px;
          ${(props) => props.status === 'default' && `border: 1px solid #b9bcbb;`}
          ${(props) => props.status === 'error' && `border: 1px solid #F94B50;`}
          ${(props) => props.status === 'warning' && `border: 1px solid #FF9322;`}
          ${(props) => props.status === 'confirm' && `border: 1px solid #00806A;`}
        }
      }
    }
  }

  .MuiOutlinedInput-root {
    padding-right: 30px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  /* chip tag */
  .MuiAutocomplete-root .MuiAutocomplete-tag {
    margin: 0;
    max-width: none;
    height: 20px;

    & > span {
      height: 20px;
      color: #1f1f1f;
      font-size: 13px;
      font-weight: 400;
      line-height: 150%;
      ${(props) =>
        props.chipType === 'default' ? `padding: 0 16px 0 8px;` : `padding: 0 16px 0 26px;`}
      position: relative;
      ${(props) =>
        props.chipType === 'user' &&
        `
        &::after {
          content: url('${chipUser}');
          position: absolute;
          top: 50%;
          left: 4px;
          transform: translate(0, -50%);
          width: 16px;
          height: 16px;
        }
        `}
    }
    & > svg {
      width: 14px;
    }
  }
`;

const SearchButton = styled.div`
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translate(0, -50%);
  &::after {
    content: url('${DarkSearch}');
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    width: 16px;
    height: 16px;
  }
  cursor: pointer;
`;

const CheckBoxIconCustom = styled(CheckBoxIcon)`
  width: 16px;
  height: 16px;
  color: #554596;
`;
