/* eslint-disable */
import { ICellRendererParams } from 'ag-grid-community';
import { IRowNode } from 'ag-grid-community/dist/lib/interfaces/iRowNode';
import { GButton } from 'components/atom/button';

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { DomesitcAccountVO } from 'models/vars/account/DomesitcAccountVO';
import useAuthStore from 'stores/useAuthStore';
import { ICellRendererParamsVars } from 'pages/vars/accountregist/hooks/OverseasColumnDefs';
import DmsDtlFrgnAcntModal from 'pages/vars/accountregist/modal/DmsDtlFrgnAcntModal';
import DmsChgFrgnAcntModal from 'pages/vars/accountregist/modal/DmsChgFrgnAcntModal';

const useDtlModalState = () => {
  const [dtlModalOpen, setDtlModalOpen] = useState<boolean>(false);
  const handleClick = () => {
    setDtlModalOpen(() => true);
  };

  return [dtlModalOpen, setDtlModalOpen, handleClick];
};

const useChgModalState = () => {
  const [chgModalOpen, setChgModalOpen] = useState<boolean>(false);
  const handleClickChgModalOpen = () => {
    setChgModalOpen(() => true);
  };

  return [chgModalOpen, setChgModalOpen, handleClickChgModalOpen];
};

const renderButtonCell = (handleClick, node) => {
  const { t } = useTranslation();
  const onClick = async () => {
    console.log('data?? ', node);
    handleClick();
  };

  return (
    <GButton
      txt={t('com.label.veiwDtls', '__상세보기')}
      onClick={() => {
        handleClick();
      }}
      btnstyled="outline"
      btnColor="normal"
      sizes="xs"
      hasTxt={true}
    ></GButton>
  );
};

const renderDtlModal = ({
  node,
  vendorInfo,
  dtlModalOpen,
  setDtlModalOpen,
  setChgModalOpen,
  isKrw,
  callbackFn,
}) => {
  return (
    <DmsDtlFrgnAcntModal
      open={dtlModalOpen}
      close={() => setDtlModalOpen(() => false)}
      save={(result) => {
        if (callbackFn && result) {
          callbackFn();
          setDtlModalOpen(false);
        }
      }}
      modify={() => {
        // handleClickChgModalOpen();
        setDtlModalOpen(() => false);
        setChgModalOpen((prev) => !prev);
      }}
      frgnAccount={node.data}
      vendorInfo={vendorInfo}
      node={node}
      isKrw={isKrw}
    />
  );
};

const renderChgModal = ({
  node,
  chgModalOpen,
  setChgModalOpen,
  domAccounts,
  stdAccnCds,
  setStdAccnCds,
  callbackFn,
  vendorInfo,
  isKrw,
}) => {
  return (
    <DmsChgFrgnAcntModal
      open={chgModalOpen}
      close={(result) => {
        setChgModalOpen(false);
      }}
      save={(result) => {
        if (callbackFn && result) {
          callbackFn();
          setChgModalOpen(false);
        }
      }}
      frgnAccount={node.data}
      vendorInfo={vendorInfo}
      node={node}
      isKrw={isKrw}
    />
  );
};

export const AccnFrgnDtlPopupCellRenderer = ({ node, ...params }: ICellRendererParamsVars) => {
  const [dtlModalOpen, setDtlModalOpen, handleClick] = useDtlModalState();
  const [chgModalOpen, setChgModalOpen] = useChgModalState();

  // const { chgModal, setChgModal } = useAuthStore();
  const callbackFn = params.callback;
  const vendorInfo = params.vendorInfo;
  const isKrw = params.isKrw;

  const [domAccounts, setDomAccounts] = useState<DomesitcAccountVO[]>([]);
  const [stdAccnCds, setStdAccnCds] = useState<any>({
    stdAccnTpCd: false,
    stdAccnRegDivsCd: '1',
  }); // true 이면 현금만

  return (
    <>
      {renderButtonCell(handleClick, node)}
      {dtlModalOpen &&
        renderDtlModal({
          node,
          vendorInfo,
          dtlModalOpen,
          setDtlModalOpen,
          setChgModalOpen,
          isKrw,
          callbackFn,
        })}
      {chgModalOpen &&
        !dtlModalOpen &&
        renderChgModal({
          node,
          chgModalOpen,
          setChgModalOpen,
          domAccounts,
          stdAccnCds,
          setStdAccnCds,
          callbackFn,
          vendorInfo,
          isKrw,
        })}
    </>
  );
};
