import styled from '@emotion/styled';

interface SubTitleBtnAreaType {
  marginTop?: string | undefined;
}

export const SubTitleBtnArea = styled.div<SubTitleBtnAreaType>`
  margin-top: ${(props) => (props.marginTop ? props.marginTop : 0)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 28px;
  margin-bottom: 4px;
`;
