import { FormControlLabel, RadioGroup } from '@mui/material';
import { GRadio } from 'components/atom/radio';
import { GInputLabel } from 'components/inputs/GInput';
import styled from '@emotion/styled';

interface Props {
  option: string[];
  value: string;
  row?: boolean;
  height?: string;
  gap?: string;
  onChange?: (e) => void;
  defaultValue?: string;
}

const GRadioGroup = (props: Props) => {
  return (
    <RadioGroup
      row={props.row == undefined || props.row ? true : false}
      sx={{
        gap: '24px',
        paddingLeft: '13px',
        height: `${props.height}`,
      }}
      // defaultValue={props.defaultValue ? props.defaultValue : props.value + '0'}
      value={props.value}
      aria-labelledby="demo-customized-radios"
      name="primary-radios"
      onChange={props.onChange}
    >
      {props.option.map((it, index) => {
        return (
          <FormControlLabelWrap
            key={index}
            value={it}
            control={<GRadio />}
            label={<GInputLabel>{it}</GInputLabel>}
          />
        );
      })}
    </RadioGroup>
  );
};

export const RadioWrap = styled.div`
  margin-top: 4px;
  display: flex;
  justify-content: flex-end;
  height: 28px;
`;

const FormControlLabelWrap = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin-right: 0;
  }
`;

export default GRadioGroup;
