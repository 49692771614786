import React, { useState } from 'react';
//import Switch, { SwitchProps } from '@mui/material/Switch';
import Switch from '@mui/material/Switch';
import styled from '@emotion/styled';
import Check from '../../asset/SwitchCheck.svg';
import { GlobalTokenColor } from 'ui/theme/Color';
import { CheckboxProps } from '@mui/material';

interface SwitchOption {
  color?: 'primary' | 'secondary';
  isLabel?: boolean;
}

export default function SwitcheButton({
  color = 'primary',
  ...props
}: SwitchOption & CheckboxProps) {
  const [clicked, setClicked] = useState(false);

  const handleChange = () => {
    setClicked((prev) => !prev);
  };

  return (
    <>
      {props.isLabel && <SwitchLabel>{clicked ? 'Y' : 'N'}</SwitchLabel>}
      {/* <SwitchStyle
        disableRipple
        checkedIcon={<SwitchIcon color={color} />}
        icon={<SCIcon color={color} />}
        inputProps={{ 'aria-label': 'Switch demo' }}
        onClick={handleChange}
        {...props}
        color={color}
      /> */}
    </>
  );
}

const SwitchLabel = styled.label`
  margin-left: 11px;
`;

const SwitchStyle = styled(Switch)<SwitchOption>`
  height: 32.5px;
  padding: 11px;
  .MuiSwitch-track {
    background-color: #dde0df;
    opacity: 1;
    width: 32px;
    height: 12px;
  }

  .MuiButtonBase-root.MuiSwitch-switchBase {
    padding: 8px;
  }

  .MuiButtonBase-root.MuiSwitch-switchBase:hover {
    background: none;
  }

  .Mui-checked {
    transform: translateX(14px);
  }

  .Mui-checked + .MuiSwitch-track {
    background-color: ${(props) =>
      props.color == 'primary'
        ? `${GlobalTokenColor.Primary} `
        : `${GlobalTokenColor.Secondary}`} !important;
    opacity: 1 !important;
  }

  .Mui-disabled + .MuiSwitch-track {
    opacity: 0.4 !important;
  }
`;

const SCIcon = styled.span<SwitchOption>`
  box-shadow: none;
  background-color: #fff;
  border: 2px solid #6e706f;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  input:disabled ~ & {
    border: 2px solid rgba(110, 112, 111, 0.4);
  }
`;

const SwitchIcon = styled(SCIcon)`
  border: 2px solid
    ${(props) =>
      props.color === 'primary' ? `${GlobalTokenColor.Primary}` : `${GlobalTokenColor.Secondary}`};
  position: relative;
  input:disabled ~ & {
    border: 2px solid
      ${(props) =>
        props.color === 'primary'
          ? `${GlobalTokenColor.Primary600Op40};`
          : `${GlobalTokenColor.Secondary500Op40}`};
  }
  &:before {
    position: absolute;
    content: url(${Check});
    display: inline-block;
    width: 1px;
    height: 100%;
    left: -14px;
    top: -4px;
    vertical-align: middle;
  }
`;
