import dayjs from 'dayjs';
import {
  GButtonBoxCum,
  SearchItemWrap,
  SearchWrap,
  UnHiddenSearchWrap,
} from 'components/molecule/SearchMolecule';
import { GButton } from 'components/atom/button';
import { useEffect, useRef, useState } from 'react';
import SubTitleBox from 'components/molecule/SubTitleBox';
import searchImage from 'components/asset/images/Search.svg';
import RecycleB from 'components/asset/images/RecycleB.svg';
import { SubTitleBtnArea } from 'components/organism/SubTitleBtnArea';
import { GContentGrid } from 'components/layouts/GContentGrid';
import { AgGridReact } from 'ag-grid-react';
import { useAbnormalMonitorDefs } from './hooks/AbnormalMonitorDefs';
import { getAbnormalListApi } from 'apis/vars/abnormal/AbnormalApi';
import GButtonLayout from 'components/molecule/GbuttonLayout';
import SearchMolecule from 'components/common/SearchMolecule';
import { useTranslation } from 'react-i18next';
import { useCommonModal } from 'hooks/useCommonModal';
import EmptyState from 'components/atom/emptyState';

export interface AbnoSearchParams {
  regReqDtSt: string; // 조회기간 시작일자
  regReqDtEnd: string; // 조회기간 종료일자
}

export interface AbnoList {
  mgtSeq: string; // 관리번호SEQ
  vdcpCd: string; // 공급업체코드 - 거래처코드
  crtyKey: string; // 국가키
  dataChgDtm: string; // 변경일시
  chgCunt: 0; // 변경횟수
  currCd: string; // 통화
  bankCd: string; // 지급은행
  accnNo: string; // 계좌번호
  bankCtryCd: string; // 은행국가코드
  bfBankCd: string; // 이전은행
  bfAccnNo: string; // 이전전계좌번호
  bfBankCtryCd: string; // 이전전은행국가코드
  mgrId: string; // 담당자
  emlAddr: string; // 이메일
  puchMgrId: string; // 구매담당자
  bfRegIp: string; // 이전등록IP대역
  bfRegCtryIp: string; // 이전IP등록국가 - 최근등록IP국가
  regCtryIp: string; // IP국가
  regIp: string; // IP대역
  swiftCd: string; // SWIFT/BIC
  bfSwiftCd: string; // 이전SWIFT/BIC
}

const AbnormalMonitorPage = () => {
  const { t } = useTranslation();
  const { openCommonModal } = useCommonModal();

  const gridApiRef = useRef<any>(null);
  const [gridData, setGridData] = useState<AbnoList[]>([]);
  const { defaultColDef, colDefs } = useAbnormalMonitorDefs();

  const [searchParams, setSearchParams] = useState<AbnoSearchParams>({
    regReqDtSt: dayjs().subtract(3, 'month').format('YYYYMMDD'),
    regReqDtEnd: dayjs().format('YYYYMMDD'),
  });

  const getGridData = () => {
    getAbnormalListApi(searchParams).then((res) => {
      setGridData(res);
    });
  };

  useEffect(() => {
    getGridData();
  }, []);

  const handleCrtDateChange = (newDate, id) => {
    handleDateRangeChange(newDate, id, 'regReqDt', 'YYYY.MM.DD');
  };

  const handleDateRangeChange = (newDate, id, key, format) => {
    const newParams = {};
    if (id === 'end') {
      newParams[key + 'End'] = newDate.format(format);

      if (searchParams[key + 'St'] === '' || newDate.isBefore(dayjs(searchParams[key + 'St'])))
        newParams[key + 'St'] = newDate.format(format);

      setSearchParams((prev) => {
        return {
          ...prev,
          ...newParams,
        };
      });
    } else if (id === 'start') {
      newParams[key + 'St'] = newDate.format(format);

      if (searchParams[key + 'End'] === '' || newDate.isAfter(dayjs(searchParams[key + 'End'])))
        newParams[key + 'End'] = newDate.format(format);

      setSearchParams((prev) => {
        return {
          ...prev,
          ...newParams,
        };
      });
    }
  };

  const handleReset = () => {
    setSearchParams({
      regReqDtSt: dayjs().subtract(3, 'month').format('YYYYMMDD'),
      regReqDtEnd: dayjs().format('YYYYMMDD'),
    });
  };

  const handleSearch = () => {
    if (searchParams.regReqDtSt === 'Invalid Date' || searchParams.regReqDtEnd === 'Invalid Date') {
      return openCommonModal({
        content: t('com.label.00477', '__조회기간을 설정해주세요.'),
      });
    }

    const setregReqDtSt = dayjs(searchParams.regReqDtSt).format('YYYYMMDD');
    const setregReqDtEnd = dayjs(searchParams.regReqDtEnd).format('YYYYMMDD');

    getAbnormalListApi({
      ...searchParams,
      regReqDtSt: setregReqDtSt,
      regReqDtEnd: setregReqDtEnd,
    }).then((res) => {
      setGridData(res);
    });
  };

  return (
    <>
      <SearchWrap>
        <UnHiddenSearchWrap>
          <SearchItemWrap>
            <SearchMolecule
              type="calendar"
              labelTitle={t('com.label.srchPeriod', '__조회기간')}
              isRequired={true}
              calendarDouble={true}
              calendarFormat="YYYY.MM.DD"
              calendarViews={['year', 'month', 'day']}
              calendarMaxWidth="128px"
              startDate={dayjs(searchParams.regReqDtSt)}
              endDate={dayjs(searchParams.regReqDtEnd)}
              onChange={handleCrtDateChange}
              mindate={'2001.01.01'}
              maxdate={`${String(Number(new Date().getFullYear()) + 10)}.01.01`}
            />
          </SearchItemWrap>
          <GButtonLayout marginBottom="0px" paddingTop="0px">
            <GButtonBoxCum>
              <GButton
                chkImg={RecycleB}
                sizes="medium"
                btnstyled="outline"
                btnColor="normal"
                hasImg={true}
                hasTxt={false}
                onClick={handleReset}
              ></GButton>
              <GButton
                chkImg={searchImage}
                txt={t('com.label.00116', '__조회')}
                sizes="medium"
                btnstyled="contained"
                btnColor="normal"
                hasImg={true}
                hasTxt={true}
                onClick={handleSearch}
              ></GButton>
            </GButtonBoxCum>
          </GButtonLayout>
        </UnHiddenSearchWrap>
      </SearchWrap>
      <SubTitleBtnArea>
        <SubTitleBox
          showSubTitle={true}
          content={`${t('com.label.00135', '__역외계좌')}(${t(
            'com.label.00136',
            '__구매처소재국가와 계좌개설국가상이'
          )})`}
        />
      </SubTitleBtnArea>
      <GContentGrid
        type="none"
        className="ag-theme-alpine"
        style={{ flex: 1, marginBottom: '8px' }}
        isSortable={false}
      >
        <AgGridReact
          defaultColDef={defaultColDef}
          columnDefs={colDefs}
          rowData={gridData}
          rowSelection={'single'}
          enableCellTextSelection
          onGridReady={(params) => (gridApiRef.current = params.api)}
          tooltipShowDelay={0}
          noRowsOverlayComponent={EmptyState}
          noRowsOverlayComponentParams={{ type: 'grid' }}
        ></AgGridReact>
      </GContentGrid>
    </>
  );
};
export default AbnormalMonitorPage;
