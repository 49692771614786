import { getMultiAccntDataApi, getPifDataApi } from 'apis/vars/account/PrintApi';
import { useEffect, useState } from 'react';
import { usePrintStore } from './usePrintStore';
import useSessionStore from 'stores/useSessionStore';
import { useLoading } from 'components/process/Loading';

export interface MultiAccntRes {
  vdcpNm: string; // 상호
  rpsnNm: string; // 대표자
  bzno: string; // 사업자등록번호
  vdcpCd: string; // 공급업체
  vdcpCtyNm: string; // 주소1
  vdcpAddrCtn: string; // 주소2

  // 추가/변경 복수계좌 내역
  bankNm: string; // 은행명
  encAccnNo: string; // 계좌번호
  dpstNm: string; // 예금주명
  accnUsegNm: string; // 계좌용도
  chgTpCd: string; // 유형
  chgTpCdNm: string; // 유형

  // 기등록 계좌 내역
  obankNm: string; // 은행명
  oencAccnNo: string; // 계좌번호
  odpstNm: string; // 예금주명
  oaccnUsegNm: string; // 계좌용도
  oaccnRegDt: string; // 계좌등록일

  // 업체담당자
  vdcpMgrEncPhn: string; // TEL
  vdcpMgrEmal1: string; // E-mail
  vdcpMgrNm1: string; // 담당자명
}

const useMultiAccntPrint = () => {
  const [formData, setFormData] = useState<MultiAccntRes>();
  const [currentDate, setCurrentDate] = useState<string>('');
  const { accnId } = usePrintStore();
  const { userId } = useSessionStore();

  const { openLoading } = useLoading();

  const getCurrentDate = () => {
    const today: Date = new Date();
    const year: number = today.getFullYear();
    const month: string = String(today.getMonth() + 1).padStart(2, '0');
    const day: string = String(today.getDate()).padStart(2, '0');

    return setCurrentDate(`${year}년 ${month}월 ${day}일`);
  };

  const transNullToEmpty = (data: MultiAccntRes): MultiAccntRes => {
    const transformedData: MultiAccntRes = { ...data };
    for (const key in transformedData) {
      if (transformedData[key] === null) {
        transformedData[key] = ''; // null을 공란으로 변경
      }
    }
    return transformedData;
  };

  const fillData = async () => {
    openLoading(true);
    getMultiAccntDataApi(userId, accnId).then((res) => {
      const transformedData = transNullToEmpty(res);
      setFormData(transformedData);
      openLoading(false);
    });
  };

  useEffect(() => {
    if (accnId && accnId !== 'undefined') {
      fillData();
      getCurrentDate();
    }
  }, [accnId]);

  const handleClickMultiAcnt = () => {
    const htmlContent = `
     <html lang="en"><head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="stylesheet" type="text/css" href="design.css">
    <title>LG Report</title>
    <style>@charset "UTF-8";

html,
body {
  margin: 0 auto;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  color: #222;
  font-family: "맑은 고딕", Dotum, "돋움", Gulim, "굴림", Tahoma, Verdana,
    AppleGothic, UnDotum, Arial;
  -webkit-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  zoom: 98%;
}
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
select,
textarea {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-text-size-adjust: none;
}
table,
th,
td,
input,
textarea,
select,
button {
  box-sizing: border-box;
  font-family: "맑은 고딕", Dotum, "돋움", Gulim, "굴림", Tahoma, Verdana,
    AppleGothic, UnDotum, Arial;
  -webkit-text-size-adjust: none;
}
th,
td {
  word-break: break-all;
  word-wrap: break-word;
  border: 1px solid #7f7f7f;
  -webkit-text-size-adjust: none;
}
table {
  font-size: 14px;
  border-spacing: 0;
  border: 1px solid #7f7f7f;
  background-color: #ffffff;
  border-collapse: collapse;
  -webkit-text-size-adjust: none;
  border: 0;
  table-layout: fixed;
  box-sizing: border-box;
}
address,
caption,
cite,
code,
dfn,
em,
var {
  font-style: normal;
  font-weight: normal;
  -webkit-text-size-adjust: none;
}
em {
  font-weight: inherit;
}
ol,
ul,
li {
  list-style: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  box-sizing: border-box;
}

legend,
caption {
  visibility: hidden;
  overflow: hidden;
  line-height: 0;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  text-indent: -99999px;
  font-size: 0;
}
img,
fieldset {
  border: 0 none;
}
span,
em,
strong {
  box-sizing: border-box;
}

a {
  color: #777;
  box-sizing: border-box;
}
a:link,
a:visited,
a:hover {
  color: #777;
  text-decoration: none;
}
input,
img {
  vertical-align: middle;
}

input,
button {
  border-radius: 0;
  -webkit-appearance: none;
  border: 0;
}
button {
  background-color: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;
  font-size: 14px;
}

textarea {
  width: 100%;
  resize: none;
  padding: 10px;
  border: 1px solid #c9c9c9;
}
label {
  vertical-align: middle;
}

/* html5 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*:before,
*:after,
* {
  box-sizing: border-box;
}

/* Font */
/* @font-face {
  font-family: "LG Smart";
  font-weight: 400;
  src: url("../font/LG_Smart_Light.woff") format("woff"),
    url("../font/LG_Smart_Light.woff2") format("woff2"),
    url("../font/LG_Smart_Light.ttf") format("truetype");
}
@font-face {
  font-family: "LG Smart";
  font-weight: 500;
  src: url("../font/LG_Smart_Regular.woff") format("woff"),
    url("../font/LG_Smart_Regular.woff2") format("woff2"),
    url("../font/LG_Smart_Regular.ttf") format("truetype");
}
@font-face {
  font-family: "LG Smart";
  font-weight: 600;
  src: url("../font/LG_Smart_Bold.woff") format("woff"),
    url("../font/LG_Smart_Bold.woff") format("woff"),
    url("../font/LG_Smart_Bold.ttf") format("truetype");
} */

.page {
  position: relative;
  width: 21cm;
  min-height: 29.7cm;
  margin: 0 auto;
  border: 1px solid #ddd;
}
@page {
  size: A4;
  margin: 0;
}

@media print {
  html,
  body {
    width: 210mm;
    height: 297mm;
    font-family: "맑은고딕", Dotum, "돋움", Gulim, "굴림", Tahoma, Verdana,
      AppleGothic, UnDotum, Arial;
  }
  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
  .bold_text {
    font-weight: bold;
  }
}

ol {
  list-style-type: none;
  counter-reset: list-counter;
}

li {
  counter-increment: list-counter;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 900;
}

/* 페이지 퍼블리싱 css */
.content_wrap {
  margin: 40px 35px;
  text-align: center;
  font-size: 16px;
}

.top_content_align_wrap {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-bottom: 30px;
}

.title {
  font-size: 28px;
}

.content_text {
  font-size: 15px;
}

.history_wrap {
  table {
    width: 100%;
  }
  p {
    font-size: 15px;
    text-align: left;
    margin-bottom: 4px;
  }
}

.left_list_wrap {
  p {
    font-size: 15px;
    text-align: left;
    margin-bottom: 4px;
  }
}

/* table style*/
table {
  width: 100%;
}

th,
td {
  font-weight: 400;
  padding: 2px 5px;
}

td {
  text-align: left;
}

.td_align {
  text-align: center !important;
}

.date_text {
  font-size: 12px;
  margin: 0 0 100px;
  text-align: right;
}

.bottom_cotnent_wrap {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.bottom_content {
  display: flex;
  justify-content: end;
  font-size: 14px;
}

.bottom_content ol {
  width: 440px;
  margin-bottom: 10px;
}

.fields_title {
  width: 85px;
  margin-right: 30px;
  text-align: left;
  padding: 4px;
}

.align_wrap {
  display: flex;
  border-bottom: 1px solid black;
  padding: 4px;
  width: 330px;

  span {
    margin-left: 150px;
  }

  p:nth-child(1) {
    text-align: left;
    width: 80px;
    margin-right: 20px;
  }
}

/* list style */
list {
  text-align: left;
}

.in_list {
  text-indent: 20px;
}

ol {
  list-style-type: none;
  counter-reset: list-counter;
}

li {
  counter-increment: list-counter;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 400;
}

li:before {
  content: counter(list-counter) ")";
}

.info_text {
  font-size: 12px !important;
  text-indent: 120px;
  margin-top: 4px;
}

.in_text {
  text-indent: 16px;
}
</style>
  </head>
  <body>
    <div class="page">
      <div class="content_wrap">
        <div class="top_content_align_wrap">
          <h1 class="title">복수계좌 신고서</h1>
          <p class="content_text">
            당사가 LG에너지솔루션에 재화와 용역을 제공하고 납품대금을 은행을<br>
            통하여 수령함에 있어 수금용 결제계좌를 아래와 같이 추가 요청 합니다.
          </p>
          <div>
            <p>
              ******************************** 아 래
              ********************************
            </p>
          </div>
          <table>
            <colgroup>
              <col style="width: 120px">
              <col style="width: 340px">
              <col style="width: 120px">
              <col>
            </colgroup>
            <tbody>
              <tr>
                <th>상호</th>
                <td>${formData?.vdcpNm ?? ''}</td>
                <th>대표자</th>
                <td>${formData?.rpsnNm ?? ''}</td>
              </tr>
              <tr>
                <th>사업자등록번호</th>
                <td>${formData?.bzno ?? ''}</td>
                <th>공급업체</th>
                <td>${formData?.vdcpCd ?? ''}</td>
              </tr>
              <tr>
                <th>주소</th>
                <td colspan="3">${formData?.vdcpCtyNm ?? ''} ${formData?.vdcpAddrCtn ?? ''}</td>
              </tr>
            </tbody>
          </table>
          <div class="history_wrap">
            <p>[추가/변경 복수계좌 내역]</p>
            <table>
              <tbody>
                <tr>
                  <th style="width: 120px">은행명</th>
                  <th style="width: 155px">계좌번호</th>
                  <th stlye="width: 140px">예금주명</th>
                  <th style="width: 120px">계좌 용도</th>
                  <th style="width: 140px">유형</th>
                </tr>
                <tr>
                  <td class="td_align">${formData?.bankNm ?? ''}</td>
                  <td class="td_align">${formData?.encAccnNo ?? ''}</td>
                  <td class="td_align">${formData?.dpstNm ?? ''}</td>
                  <td class="td_align">${formData?.accnUsegNm ?? ''}</td>
                  <td class="td_align">${formData?.chgTpCdNm ?? ''}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="history_wrap">
            <p>[기등록 계좌 내역]</p>
            <table>
              <tbody>
                <tr>
                  <th style="width: 120px">은행명</th>
                  <th style="width: 155px">계좌번호</th>
                  <th stlye="width: 140px">예금주명</th>
                  <th style="width: 120px">계좌 용도</th>
                  <th style="width: 95px">계좌등록일</th>
                  <th style="width: 45px">비고</th>
                </tr>
                <tr>
                  <td class="td_align">${formData?.obankNm ?? ''}</td>
                  <td class="td_align">${formData?.oencAccnNo ?? ''}</td>
                  <td class="td_align">${formData?.odpstNm ?? ''}</td>
                  <td class="td_align">${formData?.oaccnUsegNm ?? ''}</td>
                  <td class="td_align">${formData?.oaccnRegDt ?? ''}</td>
                  <td class="td_align"></td>
                </tr>
              </tbody></table>
              

          </div>
          <div class="left_list_wrap">
            <p>※ 추가제출 서류</p>
            <list class="in_list">
              <ol>
                <li>
                  법인 사업자: 통장 사본, 사업자등록증 사본, 법인인감증명서 원본,
                  신고서 하단 법인인감 날인.
                </li>
                <li>
                  개인 사업자: 통장 사본, 신고서 하단 통장 인감 날인
                  <p class="info_text">
                    (통장 서명으로 개설 시, 신고서 하단 대표자 개인인감 날인 후
                    개인인감 증명서 유첨)
                  </p>
                  <p class="info_text">(통장은 사업자용 통장만 가능)</p>
                </li>
              </ol>
            </list>
          </div>
          <p class="date_text">${currentDate}</p>
        </div>
        <div class="bottom_cotnent_wrap">
          <div class="bottom_content">
            <p class="fields_title">신고인</p>
            <div>
              <div class="align_wrap">
                <p>주소</p>
                <p>&nbsp;${formData?.vdcpCtyNm ?? ''} ${formData?.vdcpAddrCtn ?? ''}</p>
              </div>
              <div class="align_wrap">
                <p>상호</p>
                <p>&nbsp;${formData?.vdcpNm ?? ''}</p>
              </div>
              <div class="align_wrap">
                <p>대표이사</p>
                <p>&nbsp;${formData?.rpsnNm ?? ''}</p>
                <span>(인)</span>
              </div>
            </div>
          </div>
          <div class="bottom_content">
            <list>
              <ol>
                <li>
                  법인 사업자: 법인인감증명서와 동일 인감날인
                </li>
                <li>
                  개인 사업자: 업체계좌 등록 통장 인감과 동일 인감 날인
                  <p class="in_text">
                    (통장 서명으로 개설 시, 대표자 개인인감증명서와 동일 인감
                    날인)
                  </p>
                </li>
              </ol>
            </list>
          </div>
          <div class="bottom_content">
            <p class="fields_title">업체담당자</p>
            <div>
              <div class="align_wrap">
                <p>TEL</p>
                <p>&nbsp;${formData?.vdcpMgrEncPhn ?? ''}</p>
              </div>
              <div class="align_wrap">
                <p>E-mail</p>
                <p>&nbsp;${formData?.vdcpMgrEmal1 ?? ''}</p>
              </div>
              <div class="align_wrap">
                <p>담당자명</p>
                <p>&nbsp;${formData?.vdcpMgrNm1 ?? ''}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</body>
<script>
  window.onload = function() {
    window.print();

    setTimeout(() => {
        window.close();
      }, 0);
  };
</script>
</html>
    `;
    const width = Math.round(window.screen.width * 0.7);
    const height = Math.round(window.screen.height * 0.7);
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 2;

    const newWindow = window.open(
      '',
      '_blank',
      `width=${width},height=${height},left=${left},top=${top},scrollbars=no,toolbar=no,location=no,status=no,resizable=no`
    );

    if (newWindow) {
      newWindow.document.write(htmlContent);
      newWindow.document.close();
    }
  };

  return { handleClickMultiAcnt };
};

export default useMultiAccntPrint;
