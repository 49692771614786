import styled from '@emotion/styled';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCommonModal } from 'hooks/useCommonModal';
import ProfileImg from 'components/asset/images/userIcon.svg';
import ReplyArrow from 'components/asset/images/ReplyArrow.svg';
import QnaReply from './QnaReply';
import ReReplyArea from './ReReplyArea';
import ReplyTextArea from './ReplyTextArea';
import useAuthStore from 'stores/useAuthStore';
import useSessionStore from 'stores/useSessionStore';
import EditProfile from 'components/molecule/Profile';
import { QnaReplies } from 'pages/vars/qna/QnaDetailPage';
import { editUserQnaReplyApi } from 'apis/vars/qna/QnaUserApi';
import { editAdminQnaReplyApi } from 'apis/vars/qna/QnaAdminApi';
import { FileInfo, FileSaveResponse, FileSaveResult } from 'models/admin/FileInfo';
import { useMessageBar } from 'components/process/MessageBar';

export interface ReplyProps {
  replyData: QnaReplies;
  setComment: any;
  getQnaDetailData: (uptVwct) => void;
  handleWriteReply: (e) => void;
  handleReplySave: () => void;
}

const ReplyContentBox = (props: ReplyProps) => {
  const { t } = useTranslation();
  const replyzoneRef = useRef<any>();
  const { accessToken } = useAuthStore();
  const { openMessageBar } = useMessageBar();
  const { openCommonModal } = useCommonModal();
  const { userNm, deptNm, jtiNm } = useSessionStore();
  const { bbmNo, bbmReNo, bbmReCtn, atchFileGrId, upprBbmReNo } = props.replyData;
  const [openEditReply, setOpenEditReply] = useState<boolean>(false);
  const [openEditReReply, setOpenEditReReply] = useState<boolean>(false);
  const [editingArea, setEditingArea] = useState<string>(props.replyData.bbmReCtn);

  const handleClickEditBtn = () => {
    if (Number(upprBbmReNo) > 0) {
      setOpenEditReReply(true);
    } else {
      setOpenEditReply(true);
    }
  };

  const handleEditReply = (e) => {
    setEditingArea(e.target.value);
    props.setComment(e.target.value);
  };

  const getFileId = async () => {
    const fileSaveResponse: FileSaveResponse = await replyzoneRef.current.saveFiles();
    return fileSaveResponse.fileSaveResult == FileSaveResult.FAIL
      ? null
      : fileSaveResponse.atchFileGrId;
  };

  const handleReplyEditSave = async () => {
    const uptVwct = 'N';
    const fileId = await getFileId();

    if (accessToken) {
      editUserQnaReplyApi({
        bbmNo: bbmNo,
        bbmReCtn: editingArea,
        atchFileGrId: fileId ? fileId : '',
        bbmReNo: bbmReNo,
        upprBbmReNo: upprBbmReNo,
      }).then((res) => {
        if (res === true) {
          props.getQnaDetailData(uptVwct);
          setEditingArea('');
        }
      });
      setOpenEditReply(false);
    } else {
      editAdminQnaReplyApi({
        bbmNo: bbmNo,
        bbmReCtn: editingArea,
        atchFileGrId: fileId ? fileId : '',
        bbmReNo: bbmReNo,
        upprBbmReNo: upprBbmReNo,
      }).then((res) => {
        if (res === true) {
          props.getQnaDetailData(uptVwct);
          setEditingArea('');
        }
      });
      setOpenEditReply(false);
    }
  };

  return (
    <ReplyItemWrap>
      {Number(upprBbmReNo) === 0 && !openEditReply && (
        <QnaReply
          replyData={props.replyData}
          profileImg={ProfileImg}
          getQnaDetailData={props.getQnaDetailData}
          handleClickEditBtn={handleClickEditBtn}
          openEditReReply={openEditReReply}
        />
      )}
      {Number(upprBbmReNo) > 0 && !openEditReply && !openEditReReply && (
        <ReReplyContainer>
          <ReReplyContainer>
            <ReReplyWrap>
              <ReReply src={ReplyArrow} alt="Reply Arrow" />
              <QnaReply
                replyData={props.replyData}
                profileImg={ProfileImg}
                getQnaDetailData={props.getQnaDetailData}
                handleClickEditBtn={handleClickEditBtn}
                openEditReReply={openEditReReply}
              />
            </ReReplyWrap>
          </ReReplyContainer>
        </ReReplyContainer>
      )}
      {/* 수정영역(댓글)*/}
      {openEditReply && (
        <CommentField>
          <ProfileInfo>
            <EditProfile
              profileImg={ProfileImg}
              nickName={userNm + ' ' + (jtiNm ? jtiNm : '')}
              position={deptNm}
              isPosition={true}
            />
          </ProfileInfo>
          <ReplyTextArea
            status="default"
            width="100%"
            height="auto"
            placeholder={t('com.label.00051', '댓글을 입력해 주세요.') as string}
            isComment={true}
            reComment={true}
            replyzoneRef={replyzoneRef}
            value={editingArea}
            count={editingArea ? editingArea.length + ' / 300' : 0 + ' / 300'}
            contentText={bbmReCtn}
            atchFileGrId={atchFileGrId}
            onChange={handleEditReply}
            handleReplySave={handleReplyEditSave}
            setEditingArea={setEditingArea}
            setOpenEditReply={setOpenEditReply}
          />
        </CommentField>
      )}
      {/* 수정영역(대댓글) */}
      {openEditReReply && (
        <ReReplyContainer>
          <ReReplyContainer>
            <ReReplyWrap>
              <ReReply src={ReplyArrow} alt="Reply Arrow" />
              <ReReplyArea
                profileImg={ProfileImg}
                nickName={userNm + ' ' + (jtiNm ? jtiNm : '')}
                position={deptNm}
                replyData={props.replyData}
                openEditReReply={openEditReReply}
                getQnaDetailData={props.getQnaDetailData}
                setOpenEditReReply={setOpenEditReReply}
              />
            </ReReplyWrap>
          </ReReplyContainer>
        </ReReplyContainer>
      )}
    </ReplyItemWrap>
  );
};

const CommentField = styled.div`
  width: 100%;
  height: auto;
  padding: 12px;
  background: #f7f9f8;
  margin-top: 16px;
`;

const ProfileInfo = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 4px;
`;

const ReReplyContainer = styled.div`
  width: 100%;
  align-items: flex-start;
  gap: 8px;
`;

const ReplyItemWrap = styled.li`
  border-bottom: 1px solid #ebeeed;

  // &:first-of-type {
  //   border-bottom: none;
  // }
`;

const ReReplyWrap = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  // border-bottom: 1px solid #ebeeed;
  padding-left: 11px;
`;

const ReReply = styled.img`
  padding: 24px 0px 12px 0px;
`;

export default ReplyContentBox;
