import { Menu } from 'models/admin/Menu';
import { AccessLevelInfo } from 'models/admin/AccessLevel';
import { BmkResponse } from 'models/cims/Bmk';

export type LangType = 'ko' | 'en' | 'zhC' | 'zhT' | 'pl';

//TODO
export interface Session {
  userId?: string;
  userNm?: string;
  userNo?: string;
  empNm?: string;
  langCd?: LanguageCode;
  roleCodes?: string[];
  accessLevelInfo?: AccessLevelInfo;
  menus?: Menu[];
  bmkMenus?: BmkResponse[];
  timeZoneCd?: string;
  userCopCd?: string;
  userDeptCd?: string;
  deptNm?: string;
  deptEngNm?: string;
  deptCngNm?: string;
  empNo?: string;
  empEngNm?: string;
  empCngNm?: string;
  deptCd?: string;
  copCd?: string;
  jtiCd?: string;
  jtiNm?: string;
  jtiEngNm?: string;
  jtiCngNm?: string;
  jpsCd?: string;
  jpsNm?: string;
  jpsEngNm?: string;
  jpsCngNm?: string;
  upprEmpNo?: string;
  upprUserId?: string;
  onduRegnCd?: string;
  onduRegnNm?: string;
  ctryCd?: string;
  teamYn?: string;
  tldYn?: string;
  leasTeamYn?: string;
  leasTldYn?: string;
  gleasTeamYn?: string;
  gleasTldYn?: string;
  mgrDeptCd?: string;
  coCd?: string;
  coNm?: string;
  bplcCd?: string;
  bplcNm?: string;
  bizAreaCd?: string;
  bizAreaNm?: string;
  cctrCd?: string;
  cctrNm?: string;
  dmstYn?: string;
  bokCurrCd?: string;
}

export enum LanguageCode {
  ko = 'ko',
  en = 'en',
  pl = 'pl',
  zhC = 'zhC',
  zhT = 'zhT',
}

export enum GridNoRowsTemplateData {
  ko = '조회 가능한 데이터가 없습니다.',
  en = 'There is no data available to view.',
  pl = 'Brak danych do wyświetlenia.',
  zhC = '没有可查看的数据。',
  zhT = '没有可供查看的数据。',
}
