import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { DefaultCellTooltip } from 'components/grids/tooltip/DefaultCellTooltip';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import radioIcon from 'components/asset/RadioColumn.svg';
import { TagCellRenderer, ITagCellRendererParams } from 'components/grids/TagCellRenderer';
import { useAccountRegist } from './useAccountRegist';
import { AccnFrgnDtlPopupCellRenderer } from 'components/vars/account/grids/AccnFrgnDtlPopCellRenderer';
import { formatDate } from 'components/vars/common/FormatNumber';

type UseColumnDetailDefsProps = {
  callback?: () => void;
  vendorInfo?: any;
  isKrw: boolean;
};

export type ICellRendererParamsVars = ICellRendererParams & UseColumnDetailDefsProps;

export const useColumnDefsForFrgnlList = (props: UseColumnDetailDefsProps) => {
  const { t } = useTranslation();
  const { getTagStatus } = useAccountRegist({});

  const defaultFrgnColDef = useMemo((): ColDef => {
    return {
      sortable: false,
      resizable: true,
      cellStyle: { textAlign: 'center' },
      tooltipComponent: DefaultCellTooltip,
    };
  }, []);

  const colFrgnDefs: any = [
    {
      headerName: 'check',
      headerComponentParams: {
        template: `<img src=${radioIcon} alt=''/>`,
      },
      width: '28px !important',
      height: '24px !important',
      padding: '2px 6px',
      resizable: false,
      headerCheckboxSelection: true,
      showDisabledCheckboxes: true,
      checkboxSelection: true,
      flex: 0,
      cellStyle: {
        display: 'flex',
        justifyContent: 'center',
        width: '28px !important',
        height: '24px',
      },
    },
    {
      headerName: t('No.', 'No.'),
      width: 56,
      field: 'column1',
      tooltipField: 'column1',
      valueGetter: 'node.rowIndex + 1',
    },
    {
      headerName: t('com.label.00065', '은행'),
      headerTooltip: t('com.label.00065', '은행'),
      width: 200,
      cellStyle: { textAlign: 'start' },
      field: 'bankNm',
      tooltipField: 'bankNm',
    },
    {
      headerName: t('SWIFT/BIC', 'SWIFT/BIC'),
      headerTooltip: t('SWIFT/BIC', 'SWIFT/BIC'),
      width: 140,
      field: 'swftCd',
      tooltipField: 'swftCd',
    },
    {
      headerName: t('IBAN', 'IBAN'),
      headerTooltip: t('IBAN', 'IBAN'),
      minWidth: 180,
      flex: 1,
      field: 'ibanCd',
      tooltipField: 'ibanCd',
    },
    {
      headerName: 'Branch(CNAPS)',
      headerTooltip: 'Branch(CNAPS)',
      width: 150,
      field: 'bankBrchCd',
      tooltipField: 'bankBrchCd',
    },
    {
      headerName: t('com.label.00067', '예금주'),
      headerTooltip: t('com.label.00067', '예금주'),
      width: 120,
      field: 'dpstNm',
      tooltipField: 'dpstNm',
    },
    {
      headerName: t('com.label.00070', '계좌번호'),
      headerTooltip: t('com.label.00070', '계좌번호'),
      width: 220,
      cellStyle: { textAlign: 'start' },
      field: 'encAccnNo',
      tooltipField: 'encAccnNo',
    },
    // {
    //   headerName: t('com.label.00101', '계좌인증여부'),
    //   headerTooltip: t('com.label.00101', '계좌인증여부'),
    //   width: 100,
    //   minWidth: 100,
    //   field: 'accnNoCertYn',
    //   tooltipField: 'accnNoCertYn',
    // },
    {
      headerName: t('com.label.00071', '계좌용도'),
      headerTooltip: t('com.label.00071', '계좌용도'),
      flex: 1,
      minWidth: 150,
      cellStyle: { textAlign: 'start' },
      field: 'usePurpCtn',
      tooltipField: 'usePurpCtn',
    },
    {
      headerName: t('com.label.00076', '통화'),
      headerTooltip: t('com.label.00076', '통화'),
      width: 64,
      field: 'currCd',
      tooltipField: 'currCd',
    },
    {
      headerName: t('com.label.00064', '진행상태'),
      headerTooltip: t('com.label.00064', '진행상태'),
      width: 140,
      field: 'accnRegProgStatCd',
      tooltipField: 'accnRegProgStatCd',
      cellStyle: { textAlign: 'center', display: 'flex', justifyContent: 'center' },
      cellRenderer: TagCellRenderer,
      cellRendererParams: (params: ITagCellRendererParams) => {
        return {
          label: params.data.accnRegProgStatCdNm,
          status: getTagStatus(params.data.accnRegProgStatCd),
          ...params,
        } as ITagCellRendererParams;
      },
    },
    // {
    //   headerName: t('com.label.regReqDtm', '__등록요청일자'),
    //   headerTooltip: t('com.label.regReqDtm', '__등록요청일자'),
    //   width: 140,
    //   field: 'regReqDt',
    //   tooltipField: 'regReqDt',
    //   valueFormatter: (params) => formatDate(params),
    // },
    // {
    //   headerName: t('컬럼13코드', '첨부파일'),
    //   width: 100,
    //   field: 'column13',
    //   tooltipField: 'column13',
    // },
    {
      headerName: t('com.label.veiwDtls', '__상세보기'),
      headerTooltip: t('com.label.veiwDtls', '__상세보기'),
      width: 140,
      field: 'column13',
      tooltipField: 'column13',
      // cellRenderer: ButtonCellRenderer,
      // cellRendererParams: (params: IButtonCellRendererParams) => {
      //   return {
      //     label: '상세보기',
      //     btnStyled: 'outline',
      //     btnColor: 'normal',
      //     onClick: () => {
      //       alert('!');
      //     },
      //     ...params,
      //   } as IButtonCellRendererParams;
      // },
      cellRenderer: AccnFrgnDtlPopupCellRenderer,
      cellRendererParams: (params: ICellRendererParamsVars) => {
        params.callback = props.callback;
        params.vendorInfo = props.vendorInfo;
        params.isKrw = props.isKrw;
        return { ...params };
      },
    },
  ];

  return { defaultFrgnColDef, colFrgnDefs };
};
