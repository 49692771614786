import { create } from 'zustand';
import { Menu, MenuEnum, MenuRequest, MenuVO } from 'models/admin/Menu';

const useMenuManagementStore = create<any>((set, get) => ({
  modalOpen: false,
  setModalOpen(modalOpen) {
    set((prev) => ({ ...prev, modalOpen }));
  },

  menuId: '',
  setMenuId(menuId) {
    set((prev) => ({ ...prev, menuId }));
  },

  menuName: '',
  setMenuName(menuName) {
    set((prev) => ({ ...prev, menuName }));
  },

  targetMenuId: MenuEnum.root,
  setTargetMenuId(targetMenuId) {
    set((prev) => ({ ...prev, targetMenuId }));
  },

  menuLocation: '3',
  setMenuLocation(menuLocation) {
    set((prev) => ({ ...prev, menuLocation }));
  },

  menuUseYn: 'Y',
  setMenuUseYn(menuUseYn) {
    set((prev) => ({ ...prev, menuUseYn }));
  },

  menuExposureYn: 'Y',
  setMenuExposureYn(menuExposureYn) {
    set((prev) => ({ ...prev, menuExposureYn }));
  },

  menuOptionValue1: '',
  setMenuOptionValue1(menuOptionValue1) {
    set((prev) => ({ ...prev, menuOptionValue1 }));
  },

  menuOptionValue2: '',
  setMenuOptionValue2(menuOptionValue2) {
    set((prev) => ({ ...prev, menuOptionValue2 }));
  },

  menuOptionValue3: '',
  setMenuOptionValue3(menuOptionValue3) {
    set((prev) => ({ ...prev, menuOptionValue3 }));
  },

  menuOptionValue4: '',
  setMenuOptionValue4(menuOptionValue4) {
    set((prev) => ({ ...prev, menuOptionValue4 }));
  },

  menuOptionValue5: '',
  setMenuOptionValue5(menuOptionValue5) {
    set((prev) => ({ ...prev, menuOptionValue5 }));
  },

  messageCode: '',
  setMessageCode(messageCode) {
    set((prev) => ({ ...prev, messageCode }));
  },

  menuUrl: '',
  setMenuUrl(menuUrl) {
    set((prev) => ({ ...prev, menuUrl }));
  },

  menuDesc: '',
  setMenuDesc(menuDesc) {
    set((prev) => ({ ...prev, menuDesc }));
  },

  menuInfoId: '',
  setMenuInfoId(menuInfoId) {
    set((prev) => ({ ...prev, menuInfoId }));
  },

  menuInfoUrl: '',
  setMenuInfoUrl(menuInfoUrl) {
    set((prev) => ({ ...prev, menuInfoUrl }));
  },

  menuInfoDesc: '',
  setMenuInfoDesc(menuInfoDesc) {
    set((prev) => ({ ...prev, menuInfoDesc }));
  },

  rolesByMenu: [],
  setRolesByMenu(rolesByMenu) {
    set((prev) => ({ ...prev, rolesByMenu }));
  },

  employeesByMenu: [],
  setEmployeesByMenu(employeesByMenu) {
    set((prev) => ({ ...prev, employeesByMenu }));
  },

  departmentByMenu: [],
  setDepartmentsByMenu(departmentByMenu) {
    set((prev) => ({ ...prev, departmentByMenu }));
  },

  allMenuList: [],
  setAllMenuList(allMenuList) {
    set((prev) => ({ ...prev, allMenuList }));
  },

  allMenuListExceptClickedMenu: [],
  setAllMenuListExceptClickedMenu(allMenuListExceptClickedMenu) {
    set((prev) => ({ ...prev, allMenuListExceptClickedMenu }));
  },

  menuListWithCheckbox: [],
  setMenuListWithCheckbox(menuListWithCheckbox) {
    set((prev) => ({ ...prev, menuListWithCheckbox }));
  },

  menuListbyRole: [],
  setMenuListbyRole(menuListbyRole) {
    set((prev) => ({ ...prev, menuListbyRole }));
  },
}));

export default useMenuManagementStore;
