import styled from '@emotion/styled';
import { Autocomplete, Chip, TextField } from '@mui/material';
import DarkSearch from 'components/asset/images/DarkSearch.svg';
import ErrorIcon from 'components/asset/images/error.svg';
import WarningIcon from 'components/asset/images/warning.svg';
import ConfirmedIcon from 'components/asset/images/confirmed.svg';
import chipUser from 'components/asset/images/chipUser.svg';
import { Employee } from 'models/admin/Employee';
import ManagerSearchModalMulti from './ManagerSearchModalMulti';
import { MemberRegVO } from 'pages/vars/member/signup/MemberRegistPage';
import ManagerSearchModalSingle from './ManagerSearchModalSingle';
import { MemberEditVO } from 'pages/vars/member/manage/MemberDetailPage';
import { useTranslation } from 'react-i18next';
import useSessionStore from 'stores/useSessionStore';
import { useState } from 'react';

export interface SelectChipBoxProps {
  disabled?: boolean;
  readonly?: boolean;
  width?: string;
  height?: string;
  status?: 'default' | 'error' | 'warning' | 'confirm';
  type?: '1' | '2';
  chipType?: 'default' | 'user';
  chipImg?: string;
  format?: string;
  isCheck?: boolean;
  // 추가
  id: string;
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  signUpFormData: MemberRegVO | MemberEditVO;
  setSignUpFormData: any;
  selectedManagerData: Employee[];
  setSelectedManagerData: React.Dispatch<React.SetStateAction<Employee[]>>;
  onClick?: () => void;
}

export default function VarsAutoInput({
  readonly = false,
  disabled = false,
  width = '460px',
  height,
  status = 'default',
  type = '1',
  isCheck = true,
  chipType = 'default',
  chipImg = '${avatarUser}',
  format = '',
  id,
  openModal,
  setOpenModal,
  signUpFormData,
  setSignUpFormData,
  selectedManagerData,
  setSelectedManagerData,
  onClick,
}: SelectChipBoxProps) {
  const { t } = useTranslation();
  const { langCd } = useSessionStore();
  const [inputValue, setInputValue] = useState<string>('');

  const handleDelChip = () => {
    setSelectedManagerData([]);
    setSignUpFormData({
      ...signUpFormData,
      soprMgrNm: '',
      soprMgrEmal: '',
      soprMgrEmpNo: '',
    });
  };

  // 담당자 chip 말줄임표
  const getNameEllipsis = (name) => {
    if (!name) return false;
    const maxLength = 40;
    return name.length <= maxLength ? name : `${name.substring(0, maxLength)}...`;
  };

  return (
    <>
      <Container
        id={id}
        width={width}
        height={height}
        status={status}
        type={type}
        isCheck={isCheck}
        readonly={readonly}
        disabled={disabled}
        chipType={chipType}
        chipImg={chipImg}
        openModal={openModal}
        setOpenModal={setOpenModal}
        signUpFormData={signUpFormData}
        setSignUpFormData={setSignUpFormData}
        selectedManagerData={selectedManagerData}
        setSelectedManagerData={setSelectedManagerData}
        style={{
          maxWidth: '350px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          textAlign: 'left',
        }}
      >
        <Autocomplete
          freeSolo
          disableClearable={true}
          multiple
          limitTags={1}
          noOptionsText={null}
          value={selectedManagerData}
          renderTags={(value: readonly Employee[], getTagProps) =>
            value.map((option: Employee, index: number) => (
              <ChipContainer key={option?.userId}>
                {/* <Chip
                  label={getNameEllipsis(`${option?.empNm} / ${option?.jtiNm} / ${option?.deptNm}`)}
                  {...getTagProps({ index })}
                  onDelete={() => handleDelChip()}
                /> */}
                {/* 퇴사자 같은 경우 조회시 undefined뜨기에 retiree(퇴사자)로 표시 */}
                <Chip
                  label={getNameEllipsis(
                    `${option?.empNm || t('com.label.00807')} / ${
                      option?.jtiNm || t('com.label.00807')
                    } / ${option?.deptNm || t('com.label.00807')}`
                  )}
                  {...getTagProps({ index })}
                  onDelete={() => handleDelChip()}
                />
              </ChipContainer>
            ))
          }
          options={[]}
          inputValue={inputValue}
          onInputChange={(e, v) => {
            //input 내용 수정하게 하려면 이곳에 내용 추가
            //setInputValue(v);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={
                signUpFormData.soprMgrNm
                  ? format
                  : (t('com.label.00670', '__돋보기를 클릭하세요') as string)
              }
              InputProps={{
                style: {
                  minHeight: '32px',
                  paddingRight: '8px',
                  fontWeight: '500',
                  padding: readonly ? '5px 8px' : '5px 8px 5px 28px',
                  caretColor: 'transparent',
                },
                startAdornment: <>{params.InputProps.startAdornment}</>,
              }}
            />
          )}
        />
        <SearchButton onClick={onClick}></SearchButton>
      </Container>
      <ManagerSearchModalSingle
        id={id}
        open={openModal}
        close={() => setOpenModal(false)}
        signUpFormData={signUpFormData}
        setSignUpFormData={setSignUpFormData}
        selectedManagerData={selectedManagerData}
        setSelectedManagerData={setSelectedManagerData}
      />
    </>
  );
}

const Container = styled.div<SelectChipBoxProps>`
  z-index: 10;
  position: relative;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  ${(props) => (props.disabled ? `opacity: 0.6; pointer-events: none;` : `opacity: 1;`)};
  ${(props) => (props.readonly ? `pointer-events: none;` : ``)};

  &::after {
    ${(props) => props.status === 'error' && `content: url('${ErrorIcon}');`}
    ${(props) => props.status === 'warning' && `content: url('${WarningIcon}');`}
    ${(props) => props.status === 'confirm' && `content: url('${ConfirmedIcon}');`}
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translate(0, -50%);
    width: 16px;
    height: 16px;
    background-color: #fff;
    cursor: pointer;
  }

  .MuiAutocomplete-root {
    width: 100%;
    height: 100%;

    /* field */
    .MuiOutlinedInput-root {
      ${(props) =>
        props.type === '1' ? 'padding: 6px 50px 6px 30px;' : 'padding: 6px 50px 6px 8px;'}
      display: flex;
      gap: 4px 2px;
      background-color: ${(props) => (props.readonly ? `#f1f4f3` : `#fff`)};
      .MuiAutocomplete-input {
        width: 0;
        padding: 0;
      }
    }
  }

  .MuiTextField-root {
    width: 100%;
    height: 100%;
  }

  /* input */
  .MuiAutocomplete-input {
    width: 0;
    padding: 0;
    min-width: 0;
    height: 20px;
  }

  /* fieldset */
  .MuiOutlinedInput-notchedOutline {
    top: 0;
    width: 100%;
    padding: 0;
    border-radius: 2px;
    ${(props) => props.status === 'default' && `border: 1px solid #b9bcbb;`}
    ${(props) => props.status === 'error' && `border: 1px solid #FDA293;`}
    ${(props) => props.status === 'warning' && `border: 1px solid #FF9322;`}
    ${(props) => props.status === 'confirm' && `border: 1px solid #00806A;`}
  }

  .Mui-focused .MuiOutlinedInput-notchedOutline {
    ${(props) => props.status === 'default' && `border: 1px solid #b9bcbb;`}
    ${(props) => props.status === 'error' && `border: 1px solid #FDA293;`}
    ${(props) => props.status === 'warning' && `border: 1px solid #FF9322;`}
    ${(props) => props.status === 'confirm' && `border: 1px solid #00806A;`}
  }

  /* close button + popup button groups */
  .MuiAutocomplete-endAdornment {
    top: 50%;
    transform: translate(0, -50%);
    ${(props) => (props.type === '1' ? `display:flex; flex-direction: row-reverse;` : ``)}
  }

  /* close button */
  .MuiAutocomplete-clearIndicator {
    ${(props) => (props.type === '2' ? `right: 24px;` : ``)}
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 14px;
    height: 14px;
    margin-right: 0;
    background-color: #5b5c5b;

    & > .MuiSvgIcon-root {
      padding: 2px;
      color: #fff;
    }
  }

  /* popup button */
  .MuiAutocomplete-popupIndicator {
    button {
      width: 0;
      height: 0;
      padding: 0;
    }
    & > .MuiSvgIcon-root {
      ${(props) => (props.type === '1' ? `width: 0;` : ``)}
    }
  }

  /* chip tag */
  .MuiAutocomplete-root {
    .MuiAutocomplete-tag {
      height: 20px;
      margin: 0;
      max-width: none;

      & > span {
        height: 20px;
        color: #1f1f1f;
        font-size: 13px;
        font-weight: 400;
        line-height: 150%;
        ${(props) =>
          props.chipType === 'default' ? `padding: 0 16px 0 8px;` : `padding: 0 16px 0 26px;`}
        position: relative;
        ${(props) =>
          props.chipType === 'user' &&
          `
        &::after {
          content: url('${chipUser}');
          position: absolute;
          top: 50%;
          left: 4px;
          transform: translate(0, -50%);
          width: 16px;
          height: 16px;
        }
        `}
      }
      & > svg {
        width: 14px;
      }
    }
    .MuiButtonBase-root-MuiChip-root {
      height: 20px;
    }
  }
`;

const SearchButton = styled.button`
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translate(0, -50%);
  &::after {
    content: url('${DarkSearch}');
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    width: 16px;
    height: 16px;
  }
  cursor: pointer;
`;

const ChipContainer = styled.div`
  height: 20px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  & > div {
    width: 100%;
    height: 100%;
    & > span {
      height: 100%;
      padding: 0 16px 0 8px;
      color: #1f1f1f;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 19.5px */
    }
    & > svg {
      width: 14px;
    }
  }

  .MuiButtonBase-root-MuiChip-root {
    height: auto;
  }

  .MuiChip-label {
    overflow: visible;
  }

  .MuiChip-deleteIcon {
    margin-right: 8px;
  }
`;
