import styled from '@emotion/styled';

interface PropsType {
  width?: string;
}

export const AlignBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 4px;
`;

export const AlignItem = styled.span<PropsType>`
  flex: ${(props) => (props.width ? '0 0 ' + props.width : '1')};
`;
