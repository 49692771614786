import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { FileInfo } from 'models/admin/FileInfo';
import { callApi } from 'utils/ApiUtil';
import axios from 'axios';

export const findFiles = async (atchFileGrId: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/files`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    headers: {},
    queryParams: new URLSearchParams({ atchFileGrId: atchFileGrId }),
  };
  const response: CommonResponse<FileInfo[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as FileInfo[];
};

export const uploadFiles = async (formData: FormData) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/file/upload`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    bodyParams: formData,
  };

  const response: CommonResponse<any> = await callApi(request);

  return response.successOrNot === 'Y' ? response.data : null;
};

export const uploadFileWithNewGroupId = async (formData: FormData) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/file/upload/new`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    bodyParams: formData,
  };

  const response: CommonResponse<any> = await callApi(request);

  return response.successOrNot === 'Y' ? response.data : null;
};

export const modifyFiles = async (files: FileInfo[]) => {
  const request: CommonRequest = {
    method: Method.PUT,
    url: `/v1/files`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    bodyParams: files,
  };

  const response: CommonResponse<any> = await callApi(request);

  return response.successOrNot === 'Y' ? response.data : null;
};

export const modifyFilesWithNewGroupId = async (atchFileGrId: string, files: FileInfo[]) => {
  const request: CommonRequest = {
    method: Method.PUT,
    url: `/v1/files/new`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    bodyParams: {
      atchFileGrId: atchFileGrId,
      files: files,
    },
  };

  const response: CommonResponse<any> = await callApi(request);

  return response.successOrNot === 'Y' ? response.data : null;
};

export const downloadFile = async (atchFileGrId: string, atchFileId: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/file/download`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({ atchFileGrId: atchFileGrId, atchFileId: atchFileId }),
  };

  const response = await callApi(request);

  return response.data;
};

export const downloadAllFiles = async (atchFileGrId: string) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/v1/file/download/all?atchFileGrId=${atchFileGrId}`,
      {
        responseType: 'blob',
      }
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', atchFileGrId + '.zip');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  } catch (error) {
    console.log(error);
  }
};

export const downloadAllFilesToFileName = async (atchFileGrId, fileName) => {
  const fileDownAxios = axios.create({
    baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  try {
    if (!atchFileGrId) return;

    const response = await fileDownAxios.get(
      `${process.env.REACT_APP_API_BASE_URL}/v1/file/download/all?atchFileGrId=${atchFileGrId}`,
      {
        responseType: 'blob',
      }
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  } catch (error) {
    console.log(error);
  }
};
