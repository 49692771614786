import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Color } from 'ui/theme/Color';

export interface TooltipCust {
  pTxt: string | undefined;
  pPlace: string | undefined;
  // children: JSX.Element;
  children?: JSX.Element | any;
  isInfo?: any;
}

export const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    color: `${Color.White}`,
    backgroundColor: `${Color.Grey800}`,
    padding: '4px 8px',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '18px',
    whiteSpace: 'pre-wrap',
  },
  [`& .${tooltipClasses.tooltipPlacementTop}`]: {
    marginBottom: '5px !important',
  },
  [`& .${tooltipClasses.tooltipPlacementBottom}`]: {
    marginTop: '5px !important',
  },
  [`& .${tooltipClasses.tooltipPlacementLeft}`]: {
    marginRight: '5px !important',
  },
  [`& .${tooltipClasses.tooltipPlacementRight}`]: {
    marginLeft: '5px !important',
  },
}));

const setToolTipPosition = (
  placement?: string
):
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top'
  | undefined => {
  let result:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
    | undefined;

  switch (placement) {
    case '1':
      result = 'top-end';
      break;
    case '2':
      result = 'right-start';
      break;
    case '3':
      result = 'right';
      break;
    case '4':
      result = 'right-end';
      break;
    case '5':
      result = 'bottom-end';
      break;
    case '6':
      result = 'bottom';
      break;
    case '7':
      result = 'bottom-start';
      break;
    case '8':
      result = 'left-end';
      break;
    case '9':
      result = 'left';
      break;
    case '10':
      result = 'left-start';
      break;
    case '11':
      result = 'top-start';
      break;
    case '12':
      result = 'top';
      break;
    default:
      result = 'top';
      break;
  }
  return result;
};

export const GTooltipOverCustVars = (props: TooltipCust) => {
  return (
    <CustomWidthTooltip title={props.pTxt} placement={setToolTipPosition(props.pPlace)}>
      <div>{props.children}</div>
    </CustomWidthTooltip>
  );
};
