import { ColDef } from 'ag-grid-community';
import { DefaultCellTooltip } from 'components/grids/tooltip/DefaultCellTooltip';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useColumnDefsRegistrationDefs = () => {
  const { t } = useTranslation();

  const defaultColDef = useMemo((): ColDef => {
    return {
      sortable: false,
      resizable: true,
      cellStyle: { textAlign: 'center' },
      tooltipComponent: DefaultCellTooltip,
    };
  }, []);

  const colDefs = useMemo((): any[] => {
    return [
      {
        headerName: t('컬럼1코드', 'No.'),
        field: 'column1',
        tooltipField: 'column1',
        width: '56px',
        minWidth: 56,
      },
      {
        headerName: t('컬럼2코드', '거래처코드'),
        field: 'column2',
        tooltipField: 'column2',
        width: '80px',
        minWidth: 80,
      },
      {
        headerName: t('컬럼3코드', '국가키'),
        field: 'column3',
        tooltipField: 'column3',
        width: '80px',
        minWidth: 80,
      },
      {
        headerName: t('컬럼4코드', '변경일시'),
        field: 'column4',
        tooltipField: 'column4',
        flex: 1,
      },
      {
        headerName: t('컬럼5코드', '통화'),
        field: 'column5',
        tooltipField: 'column5',
        width: '56px',
        minWidth: 56,
      },
      {
        headerName: t('컬럼6코드', '지급은행'),
        field: 'column6',
        tooltipField: 'column6',
        cellStyle: { textAlign: 'left' },
        width: '80px',
        minWidth: 80,
      },
      {
        headerName: t('컬럼7코드', '계좌번호'),
        field: 'column7',
        tooltipField: 'column7',
        cellStyle: { textAlign: 'left' },
        flex: 1,
      },
      {
        headerName: t('컬럼8코드', 'Swift'),
        field: 'column8',
        tooltipField: 'column8',
        cellStyle: { textAlign: 'left' },
        width: '100px',
        minWidth: 100,
      },
      {
        headerName: t('컬럼9코드', '담당자'),
        field: 'column9',
        tooltipField: 'column9',
        width: '80px',
        minWidth: 80,
      },
      {
        headerName: t('컬럼10코드', 'E-mail'),
        field: 'column10',
        tooltipField: 'column10',
        cellStyle: { textAlign: 'left' },
        flex: 1,
      },
      {
        headerName: t('컬럼11코드', '구매담당자 '),
        field: 'column11',
        tooltipField: 'column11',
        width: '100px',
        minWidth: 100,
      },
      {
        headerName: t('컬럼12코드', 'IP대역 '),
        field: 'column12',
        tooltipField: 'column12',
        flex: 1,
      },
      {
        headerName: t('컬럼13코드', '최근등록IP국가 '),
        field: 'column13',
        tooltipField: 'column13',
        cellStyle: { textAlign: 'left' },
        width: '100px',
        minWidth: 100,
      },
    ];
  }, []);

  const handleAprvDetailBtn = (data) => {
    console.log('화면 연결이 필요합니다.');
  };

  return { defaultColDef, colDefs };
};
