import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useCommonModal } from 'hooks/useCommonModal';
import { useMessageBar } from 'components/process/MessageBar';
import { useLoading } from 'components/process/Loading';
import ChipInputBox from 'components/common/input/ChipInputBoxCopy';
import { Department } from 'models/admin/Department';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { GButtonBox, GPopupTitle } from 'components/layouts/GLayoutBox';
import { DialogTitleItem } from 'components/layouts/GModalPopup';
import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import { GButton } from 'components/atom/button';
import ModalLayout from 'components/layouts/ModalLayout';
import { GbasicTable, GbasicTableWrap } from 'components/tables/GTable';
import { GLabelAtom } from 'components/atom/label';
import { GCodeSelect } from 'components/selects/GCodeSelect';
import InputField from 'components/atom/input';
import { SearchParams } from 'pages/sample/TestPage';
import { CommonCode } from 'models/admin/CommonCode';

import styled from '@emotion/styled';

import Save from 'components/asset/images/Confirm.svg';

import GSelectMUIAtom from 'components/common/select/GSelectMUIAtom';
import BankNumberModal from 'pages/vars/accountregist/modal/BankNumberModal';
import { DomesitcAccountVO } from 'models/vars/account/DomesitcAccountVO';
import FileUploadVars from 'components/vars/common/FileUploadVars';
import { FileSaveResponse, FileSaveResult } from 'models/admin/FileInfo';
import { getAccntApprv, registDomesticAccount } from 'apis/vars/account/DomesticAccount';
import useDmsRegist from './hooks/useDmsRegist';
import { getJwtCommonCodes } from 'apis/vars/common/Common';
import { ValidationBox } from 'components/validation/ValidationBox';
import { AccountValidVO } from 'models/vars/account/AccountValidVO';
import { getAccnValid0237, getAccnValidInfo } from 'apis/vars/account/CommonAccount';
import { GLabelAtomVars } from 'components/vars/common/GLabelAtomVars';

type DmsRegModalProps = {
  open: boolean;
  vendorInfo: any;
  stdAccnCds: any;
  domDftAccount: any;
  accounts: any;
  close: (param?: boolean) => void;
  save: () => void;
};

interface AccnRegVO extends DomesitcAccountVO {
  currCd?: string; //미사용
}

interface AccnFiles {
  fileId1?: string;
  fileId2?: string;
  fileId3?: string;
  fileId4?: string;
}

const DmsRegKrwAcntModal = (props: DmsRegModalProps) => {
  const { t } = useTranslation();
  const t2 = t as (msgId: string, defVal: string) => string;
  const gridRef = useRef<AgGridReact<Department>>(null);
  const { openCommonModal } = useCommonModal();
  const { openMessageBar } = useMessageBar();
  const { openLoading } = useLoading();
  const [validationErrMap, setValidationErrMap] = useState<Map<string, string>>(new Map());
  const { regSchema, fileSchema } = useDmsRegist();

  const [bankNumberModalOpen, setBankNumberModalOpen] = useState<boolean>(false);
  const [certFlag, setCertFlag] = useState<boolean>(false);
  const files = {} as AccnFiles;

  const fileDropzoneRef1 = useRef<any>();
  const fileDropzoneRef2 = useRef<any>();
  const fileDropzoneRef3 = useRef<any>();
  const fileDropzoneRef4 = useRef<any>();

  const [regAccnInfo, setRegAccnInfo] = useState<AccnRegVO>({
    ...props.vendorInfo,
    accnTpCd: props.stdAccnCds.stdRegDivCd == 'GG' ? 'G' : 'F', // 최초 현금 default
    accnNoCertYn: 'N',
    addAtrValCtn06: 'N',
    dftAccnYn: props.stdAccnCds.stdDftAccnYn,
  } as AccnRegVO);

  const [accnTpCdList, setAccnTpCdList] = useState<CommonCode[]>([]);
  const [atchFileTpCdList, setAtchFileTpCdList] = useState<CommonCode[]>([]); //ATCH_FILE_KRW_CD

  const [accnRegDivsCdFlag, setAccnRegDivsCdFlag] = useState(props.stdAccnCds.stdAccnRegDivsCd);

  const handleClose = (result?: boolean) => {
    props.close(result);
  };

  const dommy: CommonCode[] = [{ cmnCd: 'v1', cmnCdNm: '옵션1' }];
  const [searchParams, setSearchParams] = useState<SearchParams>({
    coCd: '',
  });

  const handleChangeCoCd = (e) => {
    setSearchParams({ coCd: e.target.value });
  };
  const onSearchClick = () => {
    setBankNumberModalOpen(true);
  };

  /**
   * 인풋 컴포넌트 체인지 이벤트
   * @param e
   */
  const handleChangeForRegAccn = (e) => {
    if (e.target.id == 'encAccnNo') {
      e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, '', '');
      setRegAccnInfo((prev) => {
        return { ...prev, addAtrValCtn06: 'N', accnNoCertYn: 'N' };
      });
      setCertFlag(false);
    }

    setRegAccnInfo((prev) => {
      return { ...prev, [e.target.id]: e.target.value };
    });
  };

  const getFileId = useCallback(async (dropzoneRef) => {
    const fileSaveResponse: FileSaveResponse = await dropzoneRef.current.saveFiles();
    return fileSaveResponse.fileSaveResult == FileSaveResult.FAIL
      ? null
      : fileSaveResponse.atchFileGrId;
  }, []);

  const getAccountFileIds = async () => {
    if (fileDropzoneRef1.current.getFiles().length) {
      await getFileId(fileDropzoneRef1).then((respFileId) => {
        if (respFileId) {
          files.fileId1 = respFileId;
          setRegAccnInfo((prev) => {
            return { ...prev, fileId1: respFileId };
          });
        }
      });
    }
    if (fileDropzoneRef2.current.getFiles().length) {
      await getFileId(fileDropzoneRef2).then((respFileId) => {
        if (respFileId) {
          files.fileId2 = respFileId;
          setRegAccnInfo((prev) => {
            return { ...prev, fileId2: respFileId };
          });
        }
      });
    }
    if (fileDropzoneRef3.current?.getFiles().length) {
      await getFileId(fileDropzoneRef3).then((respFileId) => {
        if (respFileId) {
          files.fileId3 = respFileId;
          setRegAccnInfo((prev) => {
            return { ...prev, fileId3: respFileId };
          });
        }
      });
    }
    if (fileDropzoneRef4.current.getFiles().length) {
      await getFileId(fileDropzoneRef4).then((respFileId) => {
        if (respFileId) {
          files.fileId4 = respFileId;
          setRegAccnInfo((prev) => {
            return { ...prev, fileId4: respFileId };
          });
        }
      });
    }
  };

  /**
   * 첨부파일 필수여부 체크
   */
  const validFiles = (arrRef, arrMsg) => {
    let flag = true;
    // return arrRef.every((o) => o.current.getFiles().length);

    for (let index = 0; index < arrRef.length; index++) {
      const o = arrRef[index];
      if (!o.current.getFiles().filter((o) => o.useYn == 'Y').length) {
        openCommonModal({
          modalType: 'alert',
          content: `${arrMsg[index]} ${t('com.label.00545', '__첨부파일을 추가하세요')}`,
        });
        flag = false;
        break;
      }
    }

    return flag;
  };

  /**
   * 예금주 인증
   * @returns
   */
  const handleValidAccnClick = async () => {
    if (!regAccnInfo.bankCd) {
      openCommonModal({
        modalType: 'alert',
        content: t('com.label.00538', '__은행을 선택하세요.'),
      });
      return;
    }

    if (!regAccnInfo.encAccnNo) {
      openCommonModal({
        modalType: 'alert',
        content: t('com.label.00539', '__계좌번호를 입력하세요.'),
      });
      return;
    }

    // if (!regAccnInfo.dpstNm) {
    //   alert(t('com.label.00540', '__예금주를 입력하세요.'));
    //   return;
    // }

    const accnValidParam = {
      ZBNKY: regAccnInfo.bankCd,
      ZBNKN: regAccnInfo.encAccnNo,
      ZNME1: regAccnInfo.dpstNm ?? '',
      WAERS: 'KRW',
    } as AccountValidVO;
    openLoading(true);
    await getAccnValidInfo(accnValidParam)
      .then((resp) => {
        openLoading(false);
        if (resp.result == 'S') {
          setRegAccnInfo((prev) => {
            return {
              ...prev,
              // accnNoCertYn: 'Y',
              addAtrValCtn06: 'Y', //예금주 확인 여부
              addAtrValCtn08: resp.result, //예금주 인증처리 결과코드
              addAtrValCtn09: resp.e_ZNME1, //예금주 인증처리 결과내용
              dpstNm: resp.e_ZNME1,
            };
          });
          openMessageBar('success', t('com.label.00040', '__인증에 성공하였습니다.'));
        } else {
          const appEnv = `${process.env.REACT_APP_NODE_ENV}`;

          if (appEnv != 'prd') {
            //운영 제외하고 패스
            setRegAccnInfo((prev) => {
              return {
                ...prev,
                accnNoCertYn: 'Y',
                addAtrValCtn06: 'Y', //예금주 확인 여부
                addAtrValCtn08: resp.result, //예금주 인증처리 결과코드
                addAtrValCtn09: resp.e_ZNME1, //예금주 인증처리 결과내용
                dpstNm: 'tester',
              };
            });
            openMessageBar('success', t('com.label.00040', '__인증에 성공하였습니다.'));
          } else {
            openCommonModal({
              modalType: 'alert',
              content: resp.error_INFO,
            });
            return;
          }
        }
      })
      .catch((e) => {
        console.error('error', e);
        openLoading(false);
      });
  };

  /**
   *  동일 은행 중복계좌 번호 체크 로직
   */
  const validDuplAccn = () => {
    const duplAccn = props.accounts.filter(
      (o) =>
        o.encAccnNo == regAccnInfo.encAccnNo?.trim() &&
        o.bankCd == regAccnInfo.bankCd &&
        o.accnTpCd == regAccnInfo.accnTpCd
    );

    if (duplAccn.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const validInput = async (regAccnInfo) => {
    let infoFlag = false;
    regSchema
      .validate(regAccnInfo)
      .then(() => {
        infoFlag = true;
        return infoFlag;
      })
      .catch((e) => {
        console.log('error : ', e);
        openCommonModal({
          modalType: 'alert',
          content: e.message,
        });
        return infoFlag;
      });
  };

  const getAccnRegDivsCd = () => {
    const stdRegDivCd = props.stdAccnCds.stdRegDivCd;
    let accnRegDivsCd;
    if (stdRegDivCd == 'MM') {
      // 복수 계좌
      accnRegDivsCd = '2';
    } else if (stdRegDivCd == 'GM') {
      //어음 복수
      if (regAccnInfo.accnTpCd == 'F') {
        // 현금 고른 경우
        accnRegDivsCd = '2';
      } else {
        //어음 고른 경우
        accnRegDivsCd = '1';
      }
    } else if (stdRegDivCd == 'FF') {
      // 기본 현금
      accnRegDivsCd = '1';
    } else if (stdRegDivCd == 'FG') {
      // 현금 어음 전부 가능
      accnRegDivsCd = '1';
    } else if (stdRegDivCd == 'GG') {
      // 어음만 가능
      accnRegDivsCd = '1';
    } else {
      accnRegDivsCd = '1';
      console.error('error??');
    }
    setRegAccnInfo((prev) => {
      return {
        ...prev,
        accnRegDivsCd: accnRegDivsCd,
      };
    });
    setAccnRegDivsCdFlag(accnRegDivsCd);
  };

  const handleAccnSaveClick = async () => {
    // 복수 계좌의 경우 한번에 한건씩만
    if (regAccnInfo.accnTpCd == 'F' && regAccnInfo.accnRegDivsCd == '2') {
      if (props.accounts.some((o) => o.accnRegDivsCd == '2' && o.accnRegProgStatCd != 'C')) {
        openCommonModal({
          modalType: 'alert',
          content: t('com.label.00682', '__처리중인 복수계좌가 있습니다.'),
        });
        return;
      }
    }

    //어음 계좌의 경우 한건만 가능
    if (regAccnInfo.accnTpCd == 'G' && regAccnInfo.accnRegDivsCd == '1') {
      if (props.accounts.some((o) => o.accnTpCd == 'G' && o.accnRegProgStatCd != 'C')) {
        openCommonModal({
          modalType: 'alert',
          content: t('com.label.00683', '__처리중인 어음계좌가 있습니다.'),
        });
        return;
      }
    }

    if (validDuplAccn()) {
      openCommonModal({
        modalType: 'alert',
        content: t('com.label.00542', '__중복된 계좌를 등록할 수 없습니다.'),
      });
      return;
    }

    //TODO 상태에 따라 파일 구분
    let fileRefArr;
    let fileNameArr;
    if (regAccnInfo.accnTpCd == 'G') {
      fileRefArr = [fileDropzoneRef1, fileDropzoneRef2, fileDropzoneRef3];
      fileNameArr = [
        t('com.label.00072', '__사업자등록증'),
        t('com.code.ATCH_FILE_KRW_CD.10', '__통장사본'),
        t('com.label.00074', '__어음약정확인서'),
      ];
    } else {
      fileRefArr = [fileDropzoneRef1, fileDropzoneRef2];
      fileNameArr = [
        t('com.label.00072', '__사업자등록증'),
        t('com.code.ATCH_FILE_KRW_CD.10', '__통장사본'),
      ];
    }

    if (!validFiles(fileRefArr, fileNameArr)) {
      return;
    }

    //TODO 예금주 인증
    if (regAccnInfo.addAtrValCtn06 != 'Y') {
      openCommonModal({
        modalType: 'alert',
        content: t('com.label.00727', '__계좌를 인증해주세요'),
      });
      return;
    }

    getAccnRegDivsCd();
    regSchema
      .validate(regAccnInfo)
      .then(async () => {
        // 2차 검수 피드백 : 저장노티 띄우지 않고 바로 '저장되었습니다' 메세지 노출
        // 수정자 : 양지원
        try {
          openLoading(true);
          await getAccnValid0237({
            I_REQ_DIVS: 'KRW', // 원화 외화 구분
            I_LIFNR: props.vendorInfo.vdcpCd, //구매처코드
            I_ACCT_TYPE: regAccnInfo.accnTpCd, //계좌구분
            I_MODE: regAccnInfo.accnRegDivsCd == '2' ? 'A' : 'N', //변경유형  0318 복수인 경우 A로 넘김
            I_BANKN_N: regAccnInfo.encAccnNo, //신규_은행계좌번호
            I_BKONT_N: regAccnInfo.bankCd, //신규_은행코드(원화)
          }).then(async (resp) => {
            const result = resp.stat;
            if (result == 'S') {
              setRegAccnInfo((prev) => {
                return {
                  ...prev,
                  accnNoCertYn: 'Y', // 계좌인증여부
                };
              });
              setCertFlag(true);
              await getAccountFileIds();
              await registDomesticAccount(
                { ...regAccnInfo, accnNoCertYn: 'Y' },
                files,
                props.domDftAccount
              ).then((resp) => {
                if (resp.successOrNot === 'Y') {
                  openMessageBar('success', t('com.label.00549', '__등록되었습니다.'));
                  handleClose(true);
                }
              });
            } else {
              openCommonModal({
                modalType: 'alert',
                content: resp.msg,
              });
              return;
            }
          });
        } catch (e) {
          console.log('e', e);
          return;
        } finally {
          openLoading(false);
        }
      })
      .catch((e) => {
        console.log('error : ', e);
        openCommonModal({
          modalType: 'alert',
          content: e.message,
        });
        return;
      });
  };

  useEffect(() => {
    (async () => {
      await getJwtCommonCodes('ACCN_TP_CD').then((resp) => {
        setAccnTpCdList(resp);
      });
      await getJwtCommonCodes('ATCH_FILE_KRW_CD').then((resp) => {
        setAtchFileTpCdList(resp);
      });
    })();
  }, []);

  useEffect(() => {
    getAccnRegDivsCd();
  }, [regAccnInfo.accnTpCd]);

  useEffect(() => {
    setRegAccnInfo((prev) => {
      return {
        ...prev,
        accnRegDivsCd: accnRegDivsCdFlag,
      };
    });
  }, [accnRegDivsCdFlag]);

  useEffect(() => {
    if (certFlag) {
      setRegAccnInfo((prev) => {
        return {
          ...prev,
          accnNoCertYn: 'Y', // 계좌인증여부
        };
      });
    }
  }, [certFlag]);

  return (
    <>
      <ModalLayout
        open={props.open}
        onClose={() => handleClose(false)}
        mMinWidth={1000}
        mMaxWidth={1000}
      >
        <DialogTitleItem id="customized-dialog-title" onClose={() => handleClose(false)}>
          <GPopupTitle title2={t('com.label.00061', '__계좌정보등록')} />
        </DialogTitleItem>
        <DialogContent className="popupContent">
          <GbasicTableWrap isBottomMgn={false}>
            <GbasicTable>
              <colgroup>
                <col style={{ width: '160px' }} />
                <col style={{ width: 'auto' }} />
                <col style={{ width: '160px' }} />
                <col style={{ width: 'auto' }} />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    <GLabelAtom align="left" isRequired>
                      {t('com.label.00063', '__계좌종류')}
                    </GLabelAtom>
                  </th>
                  <td>
                    <GSelectMUIAtom
                      selectWidth="300px"
                      value={regAccnInfo.accnTpCd}
                      handleChange={(e) => {
                        setRegAccnInfo((prev) => {
                          return {
                            ...prev,
                            accnTpCd: e.target.value,
                            bankCd: '',
                            bankNm: '',
                            bankCtryCd: '',
                            addAtrValCtn06: 'N',
                            accnNoCertYn: 'N',
                          };
                        });
                        setCertFlag(false);
                      }}
                      readOnly={props.stdAccnCds.stdAccnTpCd == true ? true : false}
                      codeKey="cmnCd"
                      codeName="cmnCdNm"
                      option={accnTpCdList}
                    />
                  </td>
                  <th>
                    <GLabelAtom align="left">{t('com.label.00064', '__진행상태')}</GLabelAtom>
                  </th>
                  <td></td>
                </tr>
                <tr>
                  <th>
                    <GLabelAtom align="left" isRequired={true}>
                      {t('com.label.00065', '__은행')}
                    </GLabelAtom>
                  </th>
                  <td colSpan={3}>
                    <ValidationBox msg={validationErrMap.get('bankNm')}>
                      <ChipInputBox
                        width="100%"
                        status="default"
                        type="singleChip"
                        value={regAccnInfo.bankNm}
                        setValue={(value) => {
                          if (!value) {
                            setRegAccnInfo((prev) => {
                              return {
                                ...prev,
                                bankCd: '',
                                bankNm: '',
                                addAtrValCtn06: 'N',
                                accnNoCertYn: 'N',
                              };
                            });
                          }
                          setCertFlag(false);
                        }}
                        onClickFunction={onSearchClick}
                      />
                    </ValidationBox>
                  </td>
                </tr>
                <tr>
                  <th>
                    <GLabelAtom align="left" isRequired>
                      {t('com.label.00067', '__예금주')}
                    </GLabelAtom>
                  </th>
                  <td>
                    <InputField
                      id="dpstNm"
                      status="readonly"
                      readOnly={true}
                      align="left"
                      width="100%"
                      maxLength={60}
                      value={regAccnInfo.dpstNm}
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <GLabelAtom align="left" isRequired>
                      {t('com.label.00070', '__계좌번호')}
                    </GLabelAtom>
                  </th>
                  <td>
                    <Wrap>
                      <InputField
                        id="encAccnNo"
                        status="default"
                        align="left"
                        width="240px"
                        placeholder={t2('com.label.00377', `__'-'없이 입력하세요`)}
                        onChange={handleChangeForRegAccn}
                        maxLength={100}
                      />
                      <GButton
                        txt={t(`com.label.00548`, `__인증요청`)}
                        sizes="medium"
                        btnColor="normal"
                        btnstyled="outline"
                        hasImg={false}
                        hasTxt={true}
                        onClick={() => {
                          handleValidAccnClick();
                        }}
                      />
                    </Wrap>
                  </td>
                  <th>
                    <GLabelAtom align="left" isRequired>
                      {t('com.label.00076', '__통화')}
                    </GLabelAtom>
                  </th>
                  <td>
                    <GCodeSelect
                      id={'cmnCd'}
                      labelKey={'cmnCdNm'}
                      emptyLabel="KRW"
                      listData={dommy}
                      value={searchParams.coCd}
                      onChange={handleChangeCoCd}
                      width="100px"
                      readonly={true}
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <GLabelAtom align="left" isRequired={true}>
                      {t('com.label.00071', '__계좌용도')}
                    </GLabelAtom>
                  </th>
                  <td colSpan={3}>
                    <InputField
                      id="accnUsegNm"
                      status="default"
                      align="left"
                      width="100%"
                      // placeholder={t2('com.label.00068', '__입력하세요')}
                      onChange={handleChangeForRegAccn}
                      maxLength={25}
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <GLabelAtomVars
                      align="left"
                      isRequired
                      isInfo={true}
                      pTxt={
                        t(
                          'com.tooltip.accn.reg01',
                          '__사업자인 경우 현재 유효한 사업자등록증을 첨부해주세요.\r\n일반 개인인 경우 신분증을 첨부해주세요(주민등록번호 뒷자리 마스킹)'
                        ) ?? ''
                      }
                      pPlace="3"
                    >
                      {t('com.label.00072', '__사업자등록증')}
                    </GLabelAtomVars>
                  </th>
                  <td colSpan={3}>
                    <FileUploadVars
                      ref={fileDropzoneRef1}
                      atchFileGrIdInput=""
                      isMultipleFile={false}
                      maxCount={1}
                      allowFileTypes={['jpg', 'png', 'pdf']}
                      height="40px"
                      width="100%"
                      getSelectedFile={(value) => {
                        if (value.length == 0) {
                          files.fileId1 = '';
                        }
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <GLabelAtomVars
                      align="left"
                      isRequired
                      isInfo={true}
                      pTxt={
                        t(
                          'com.tooltip.accn.reg02',
                          `__사업용 계좌를 첨부해주세요\r\n- 계좌주명이 '대표자'일 경우, 대표자명+(상호명) 이거나 사업용 통장이어야 합니다`
                        ) ?? ''
                      }
                      pPlace="3"
                    >
                      {t('com.code.ATCH_FILE_KRW_CD.10', '__통장사본')}
                    </GLabelAtomVars>
                  </th>
                  <td colSpan={3}>
                    <FileUploadVars
                      ref={fileDropzoneRef2}
                      atchFileGrIdInput=""
                      isMultipleFile={false}
                      maxCount={1}
                      allowFileTypes={['jpg', 'png', 'pdf']}
                      height="40px"
                      width="100%"
                      getSelectedFile={(value) => {
                        if (value.length == 0) {
                          files.fileId2 = '';
                        }
                      }}
                    />
                  </td>
                </tr>
                {regAccnInfo.accnTpCd == 'G' && (
                  <tr>
                    <th>
                      <GLabelAtomVars
                        align="left"
                        isRequired
                        isInfo={true}
                        pTxt={
                          t(
                            'com.tooltip.accn.reg03',
                            `__은행을 통해 전자어음약정을 체결한 후 어음약정 확인서를 첨부해주세요\r\n- 하나은행 어음 약정 시, 금융거래 정보제공 동의서(금융거래 정보제공 동의 업무 상세 조회)를 필수서류로 제출하여야 합니다`
                          ) ?? ''
                        }
                        pPlace="3"
                      >
                        {t('com.label.00074', '__어음약정확인서')}
                      </GLabelAtomVars>
                    </th>
                    <td colSpan={3}>
                      <FileUploadVars
                        ref={fileDropzoneRef3}
                        atchFileGrIdInput=""
                        isMultipleFile={true}
                        maxCount={2}
                        allowFileTypes={['jpg', 'png', 'pdf']}
                        height="40px"
                        width="100%"
                        getSelectedFile={(value) => {
                          if (value.length == 0) {
                            files.fileId3 = '';
                          }
                        }}
                      />
                    </td>
                  </tr>
                )}

                <tr>
                  <th>
                    <GLabelAtomVars align="left">{t('com.label.00075', '__기타')}</GLabelAtomVars>
                  </th>
                  <td colSpan={3}>
                    <FileUploadVars
                      ref={fileDropzoneRef4}
                      atchFileGrIdInput=""
                      isMultipleFile={true}
                      maxCount={5}
                      allowFileTypes={['jpg', 'png', 'xlsx', 'pdf']}
                      height="40px"
                      width="100%"
                      getSelectedFile={(value) => {
                        if (value.length == 0) {
                          files.fileId4 = '';
                        }
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </GbasicTable>
          </GbasicTableWrap>
        </DialogContent>
        <DialogActions className="popupBottom">
          <GButtonBox>
            <GButton
              txt={t('com.label.00036', '취소')}
              sizes="medium"
              onClick={() => handleClose(false)}
              btnColor="normal"
              btnstyled="outline"
              hasImg={false}
              hasTxt={true}
            />
            <GButton
              txt={t('com.label.00055', '저장')}
              chkImg={Save}
              sizes="medium"
              onClick={handleAccnSaveClick}
              btnstyled="contained"
              btnColor="primary"
              hasImg={true}
              hasTxt={true}
            ></GButton>
          </GButtonBox>
        </DialogActions>
      </ModalLayout>
      <BankNumberModal
        open={bankNumberModalOpen}
        close={() => setBankNumberModalOpen(false)}
        save={(data) => {
          setRegAccnInfo((prev) => {
            return {
              ...prev,
              bankCd: data.bankCode,
              bankNm: data.bankName,
              bankCtryCd: data.bankCtryCd,
              addAtrValCtn06: 'N',
              accnNoCertYn: 'N',
            };
          });
          setCertFlag(false);
        }}
        vendorInfo={props.vendorInfo}
        accnTpCd={regAccnInfo.accnTpCd}
      />
    </>
  );
};

const Wrap = styled.div`
  display: flex;
  gap: 4px;
`;

export default DmsRegKrwAcntModal;
