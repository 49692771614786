import { forwardRef, useEffect, useState, useImperativeHandle } from 'react';
import styled from '@emotion/styled';

import { Link } from 'react-router-dom';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { CustomSelect } from 'components/selects/CustomSelect';
import { BorderColor, FontColor } from 'ui/theme/Color';

interface PaginationProps {
  totalCount: number;
  defaultPageNo?: number;
  defaultPageSize?: number;
  onChangePageSize: (param: any) => void;
  onClickPageNo: (param: number) => void;
}
export interface PaginationRef {
  setSelectNo: (no: number, newPageSize?: number, totalCount?: number) => void;
  setPageSize: (pageSize: number) => void;
  setPageSizeAndNo: (pageSize: number, pageNo: number, totalCount: number) => void;
}

// eslint-disable-next-line react/display-name
export const Pagination = forwardRef((props: PaginationProps, ref?: React.Ref<PaginationRef>) => {
  const defalutPageLength = 10;
  const [pageLength, setPageLength] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(props.defaultPageSize ?? 10);
  const [pageNoList, setPageNoList] = useState<number[]>([]);
  const [selectedNo, setSelectedNo] = useState<number>(props.defaultPageNo ?? 1);
  const [lastPageNo, setLastPageNo] = useState<number>(10);

  useImperativeHandle(ref, () => ({
    setSelectNo: (no: number, newPageSize?: number, totalCount?: number) => {
      setSelectedNo(no);
      // const index = Math.floor((no - 1) / (newPageSize ?? pageSize)) + 1;
      // const firstNoOfRange = no % 10 === 1 ? no : index * (newPageSize ?? pageSize) + 1;
      const firstNoOfRange = Math.floor((no - 1) / defalutPageLength) * defalutPageLength + 1;

      let newLastPageNo = lastPageNo;
      if (newPageSize && totalCount) {
        newLastPageNo = firstNoOfRange + defalutPageLength - 1;
        setLastPageNo(newLastPageNo);
      }
      if (newLastPageNo < defalutPageLength) {
        setPageLength(newLastPageNo);
        setPageNoList(getRange(firstNoOfRange, newLastPageNo));
      } else if (firstNoOfRange > defalutPageLength && newLastPageNo % defalutPageLength != 0) {
        setPageLength(newLastPageNo % defalutPageLength);
        setPageNoList(getRange(firstNoOfRange, newLastPageNo % defalutPageLength));
      } else {
        setPageLength(defalutPageLength);
        setPageNoList(getRange(firstNoOfRange, defalutPageLength));
      }
    },
    setPageSize: (pageSize: number) => {
      setPageSize(pageSize);
    },
    setPageSizeAndNo(pageSize: number, pageNo: number, totalCount: number) {
      this.setSelectNo(pageNo, pageSize, totalCount);
      this.setPageSize(pageSize);
    },
  }));

  const getRange = (start: number, length: number) => {
    return Array.from({ length: length }, (_, i) => start + i);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(e.target.value);
    const calLastPageNo = Math.floor(props.totalCount / e.target.value);
    const lastPageNo =
      props.totalCount / e.target.value === calLastPageNo ? calLastPageNo : calLastPageNo + 1;
    setLastPageNo(lastPageNo);
    setSelectedNo(1);
    props.onChangePageSize(e.target.value);
  };

  const handlePageNoClick = (no: number) => {
    setSelectedNo(no);
    props.onClickPageNo(Number(no));
  };

  const handleFirstPageClick = () => {
    setSelectedNo(1);
    props.onClickPageNo(1);
    if (lastPageNo < defalutPageLength) {
      setPageNoList(getRange(1, pageLength));
    } else {
      setPageLength(defalutPageLength);
      setPageNoList(getRange(1, defalutPageLength));
    }
  };

  const handleDoubleArrowLeftClick = () => {
    if (selectedNo - pageLength < 1) {
      handleFirstPageClick();
    } else {
      if (lastPageNo < defalutPageLength) {
        setPageNoList(getRange(pageNoList[0] - pageLength, pageLength));
      } else {
        setPageLength(defalutPageLength);
        setPageNoList(getRange(pageNoList[0] - defalutPageLength, defalutPageLength));
      }
      props.onClickPageNo(selectedNo - pageLength);
      setSelectedNo((prevNo) => prevNo - pageLength);
    }
  };

  const handleArrowLeftClick = () => {
    if (selectedNo == 1) return false;
    if (selectedNo == pageNoList[0]) {
      if (lastPageNo < defalutPageLength) {
        setPageNoList(getRange(selectedNo - pageLength, pageLength));
      } else {
        setPageLength(defalutPageLength);
        setPageNoList(getRange(selectedNo - defalutPageLength, defalutPageLength));
      }
    }
    props.onClickPageNo(selectedNo - 1);
    setSelectedNo((prevNo) => prevNo - 1);
  };

  const handleArrowRightClick = () => {
    if (selectedNo == lastPageNo) return false;
    if (selectedNo == pageNoList[pageLength - 1]) {
      if (selectedNo + pageLength > lastPageNo) {
        setPageNoList(
          getRange(Math.floor(lastPageNo / pageLength) * pageLength + 1, lastPageNo % pageLength)
        );
      } else {
        setPageNoList(getRange(selectedNo + 1, pageLength));
      }
    }
    props.onClickPageNo(selectedNo + 1);
    setSelectedNo((prevNo: number) => prevNo + 1);
  };

  const handleDoubleArrowRightClick = () => {
    if (selectedNo + pageLength > lastPageNo) {
      handleLastPageClick();
    } else if (
      Math.floor((selectedNo + pageLength) / pageLength) == Math.floor(lastPageNo / pageLength)
    ) {
      setPageNoList(
        getRange(Math.floor(lastPageNo / pageLength) * pageLength + 1, lastPageNo % pageLength)
      );
      props.onClickPageNo(selectedNo + pageLength);
      setSelectedNo((prevNo) => prevNo + pageLength);
    } else {
      setPageNoList(getRange(pageNoList[0] + pageLength, pageLength));
      props.onClickPageNo(selectedNo + pageLength);
      setSelectedNo((prevNo) => prevNo + pageLength);
    }
  };

  const handleLastPageClick = () => {
    props.onClickPageNo(lastPageNo);
    setSelectedNo(lastPageNo);
    if (lastPageNo > defalutPageLength) {
      const startPage = Math.floor((lastPageNo - 1) / defalutPageLength) * defalutPageLength + 1;
      setPageNoList(getRange(startPage, lastPageNo - startPage + 1));
    }
  };

  useEffect(() => {
    // const index = Math.floor((selectedNo - 1) / pageSize);
    // const firstNoOfRange = lastPageNo % defalutPageLength === 1 ? lastPageNo : index * pageSize + 1;
    const firstNoOfRange = Math.floor((selectedNo - 1) / defalutPageLength) * defalutPageLength + 1;
    if (lastPageNo < defalutPageLength) {
      setPageLength(lastPageNo);
      setPageNoList(getRange(firstNoOfRange, lastPageNo));
    } else if (firstNoOfRange > defalutPageLength && lastPageNo % defalutPageLength != 0) {
      setPageLength(lastPageNo % defalutPageLength);
      setPageNoList(getRange(firstNoOfRange, lastPageNo % defalutPageLength));
    } else {
      setPageLength(defalutPageLength);
      setPageNoList(getRange(firstNoOfRange, defalutPageLength));
    }
  }, [lastPageNo]);

  useEffect(() => {
    const lastPageNo =
      props.totalCount % pageSize == 0
        ? props.totalCount / pageSize
        : Math.floor(props.totalCount / pageSize) + 1;
    setLastPageNo(lastPageNo);
  }, [props.totalCount]);

  return (
    <Page>
      <SelRows>
        <CustomSelect value={pageSize} onChange={handlePageSizeChange}>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
        </CustomSelect>
        <span> / Page</span>
      </SelRows>
      <Paging>
        <Link to="#" onClick={handleFirstPageClick}>
          <FirstPageIcon></FirstPageIcon>
        </Link>
        <Link to="#" onClick={handleDoubleArrowLeftClick}>
          <KeyboardDoubleArrowLeftIcon></KeyboardDoubleArrowLeftIcon>
        </Link>
        <Link to="#" className="prev" onClick={handleArrowLeftClick}>
          <KeyboardArrowLeftIcon></KeyboardArrowLeftIcon>
        </Link>
        {pageNoList &&
          pageNoList.map((no) => (
            <Link
              key={no}
              to="#"
              className={no === selectedNo ? 'current' : ''}
              onClick={() => handlePageNoClick(no)}
              style={{ width: 'auto' }}
            >
              {no}
            </Link>
          ))}
        <Link to="#" className="next" onClick={handleArrowRightClick}>
          <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
        </Link>
        <Link to="#" onClick={handleDoubleArrowRightClick}>
          <KeyboardDoubleArrowRightIcon></KeyboardDoubleArrowRightIcon>
        </Link>
        <Link to="#" onClick={handleLastPageClick}>
          <LastPageIcon></LastPageIcon>
        </Link>
      </Paging>
    </Page>
  );
});

const Page = styled.div`
  position: relative;
  margin-top: 45px;
  padding-bottom: 10px;
  padding-left: 3px;
  text-align: center;
`;

const SelRows = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  select {
    width: 60px;
    height: 28px;
    min-width: auto;
  }
  span {
    color: ${FontColor.Gray400};
    font-size: 13px;
  }
`;

const Paging = styled.div`
  display: inline-block;

  a {
    display: inline-block;
    width: 18px;
    margin: 0 4px;
    color: ${FontColor.Gray300};

    &.prev {
      margin-right: 10px;
    }
    &.next {
      margin-left: 10px;
    }
    &.current {
      color: ${FontColor.Gray400};
      font-weight: 500;
      width: 24px;
      height: 28px;
      padding: 5px 0;
      border-radius: 2px;
      border: solid 1px ${BorderColor.Form};
      background-color: ${BorderColor.Primary};
      line-height: 1;
    }
  }

  svg {
    font-size: 20px;
  }
`;
