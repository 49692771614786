import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react';
import { CommonYN } from 'models/common/Common';
import { CrudCode } from 'models/common/Edit';
import { v4 as uuidv4 } from 'uuid';
import { FileInfo, FileSaveResponse, FileSaveResult } from 'models/admin/FileInfo';
import { findFiles, modifyFiles, uploadFiles } from 'apis/admin/FileUpload';
import { GInputIconButton } from 'components/buttons/GInputIconButton';
import { useCommonModal } from 'hooks/useCommonModal';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useMessageBar } from 'components/process/MessageBar';

interface GFileUploadZoneProps {
  atchFileGrIdInput?: string;
  isMultipleFile: boolean;
  allowFileTypes: string[];
  atchFileTpCd?: string;
  fileList?: FileInfo[];
  getSelectedFile?: (files: any) => void;
  onChange?: (files: any) => void;
}

// eslint-disable-next-line react/display-name
const GFileUploadZone = forwardRef(
  (
    {
      atchFileGrIdInput,
      isMultipleFile,
      allowFileTypes,
      atchFileTpCd = '',
      fileList,
      getSelectedFile,
      onChange,
    }: GFileUploadZoneProps,
    ref: React.Ref<any>
  ) => {
    const { t } = useTranslation();
    const t2 = t as (msgId: string, defVal: string) => string;
    const { openCommonModal } = useCommonModal();
    const { openMessageBar } = useMessageBar();
    const [files, setFiles] = useState<FileInfo[]>(fileList || []);
    const [allFileSize, setAllFileSize] = useState(0);
    const [atchFileGrId, setAtchFileGrId] = useState(uuidv4());

    const fileCount = {};
    const maxSize = 10485760;
    const maxSizeText = '10MB';

    const fileInput = useRef<any>(null);

    useEffect(() => {
      getSelectedFile ? getSelectedFile(files) : null;

      let sum = 0;
      files.map((file, index) => {
        if (file.useYn == 'Y') {
          sum += file.atchFileSize;
        }
      });

      setAllFileSize(sum);

      onChange ? onChange(files) : null;

      fileInput.current.value = '';
    }, [files]);

    // 초기 파일들이 있을 경우 atchFileGrIdInput을 통해 받음
    useEffect(() => {
      if (fileList && fileList.length != 0) return;

      if (atchFileGrIdInput != null) {
        findFiles(atchFileGrIdInput).then((result: FileInfo[]) => {
          if (result.length != 0) {
            result.map((files) => ({ ...files, newYn: false }));
            setFiles(result);
            setAtchFileGrId(atchFileGrIdInput);
          }
        });
      }
    }, [atchFileGrIdInput]);

    useImperativeHandle(ref, () => ({
      saveFiles: async () => {
        const fileSaveResponse: FileSaveResponse = await saveFiles();

        findFiles(fileSaveResponse.atchFileGrId).then((result: FileInfo[]) => {
          if (result.length != 0) {
            result.map((files) => ({ ...files, newYn: false }));
            setFiles(result);
            setAtchFileGrId(fileSaveResponse.atchFileGrId);
          }
        });

        return fileSaveResponse;
      },
    }));

    const fileDown = async (file) => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/v1/file/download?atchFileGrId=${file.atchFileGrId}&atchFileId=${file.atchFileId}`,
          {
            responseType: 'blob',
          }
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file.atchFileNm);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } catch (error) {
        console.log(error);
      }
    };

    const handleAtchFileAddClick = () => {
      fileInput.current.click();
    };

    const duplicateCheckSave = (fileList) => {
      if (allowFileTypes.length == 0) {
        openCommonModal({
          modalType: 'alert',
          content: t('com.label.00158', '__첨부파일 유형을 선택하세요'),
        });

        return;
      }
      // 선택된 파일 리스트 files 에서 중복파일 dupleFileLst로 보냄
      files.map((file) => {
        for (let i = 0; i < fileList.length; i++) {
          if (
            file.crudKey == CrudCode.CREATE &&
            fileList[i].name === file.atchFileNm &&
            fileList[i].size == file.atchFileSize
          ) {
            fileList.splice(i, 1);
          } else if (
            fileList[i].name === file.atchFileNm &&
            fileList[i].size != file.atchFileSize
          ) {
            if (Object.keys(fileCount).includes(fileList[i].name)) {
              fileCount[fileList[i].name]++;
            } else {
              fileCount[fileList[i].name] = 1;
            }

            const oldFile = fileList[i];

            fileList.push(
              new File(
                [oldFile],
                `${oldFile.name.split('.')[0]}(${fileCount[oldFile.name]}).${
                  oldFile.name.split('.')[1]
                }`,
                { type: oldFile.type }
              )
            );
            fileList.splice(i, 1);
          }
        }
      });
      // 중복되지 않은 파일 fileList 저장

      fileList.map((file) => {
        // 파일첨부 부분
        const newFile = {
          atchFileGrId: atchFileGrId,
          atchFileId: uuidv4(),
          atchFileNm: file.name,
          sortOrd: '',
          atchFileSaveLocDivsCd: '',
          atchFileSaveNm: file.name,
          atchFileSize: file.size,
          atchFileEfnmNm: '',
          atchFileSavePathCtn: '',
          atchFileTpCd: '',
          optValCtn1: '',
          optValCtn2: '',
          optValCtn3: '',
          optValCtn4: '',
          optValCtn5: '',
          useYn: CommonYN.Y,
          newYn: true,
          crudKey: CrudCode.CREATE,
          file: file,
        };

        if (!isMultipleFile) {
          files.map((item) => {
            (item.useYn = CommonYN.N), (item.crudKey = CrudCode.UPDATE);
          });
          setFiles([...files, newFile]);
        } else {
          setFiles([...files, newFile]);
        }
      });
    };

    const handleFileChange = (e) => {
      const fileList: any = Array.from(e.target.files);

      // 파일 용량 및 형식 제한
      for (let i = 0; i < fileList.length; i++) {
        if (fileList[i].size > maxSize) {
          fileList.splice(i, 1);
          alert(t('com.label.00686', '__첨부파일 최대용량') + ` : ${maxSizeText}`);
          return;
        }

        if (
          !allowFileTypes.includes(
            fileList[i].name.slice(fileList[i].name.lastIndexOf('.') + 1).toLowerCase()
          )
        ) {
          fileList.splice(i, 1);
          openCommonModal({
            modalType: 'alert',
            content: t('com.label.00687', '__첨부파일 형식을 확인하세요'),
          });
        }
      }
      duplicateCheckSave(fileList);
    };

    const handleAtchFileDrop = (e) => {
      e.preventDefault();
      e.stopPropagation();

      const fileList: any = Array.from(e.dataTransfer.files);

      // 파일 용량 및 형식 제한
      for (let i = 0; i < fileList.length; i++) {
        if (fileList[i].size > maxSize) {
          fileList.splice(i, 1);
          alert(t('com.label.00686', '__첨부파일 최대용량') + ` : ${maxSizeText}`);
          return;
        }
        if (
          !allowFileTypes.includes(
            fileList[i].name.slice(fileList[i].name.lastIndexOf('.') + 1).toLowerCase()
          )
        ) {
          fileList.splice(i, 1);
          openCommonModal({
            modalType: 'alert',
            content: t('com.label.00687', '__첨부파일 형식을 확인하세요'),
          });
        }
      }

      duplicateCheckSave(fileList);
    };

    const saveFiles = async () => {
      const response = {
        atchFileGrId: '',
        fileSaveResult: FileSaveResult.NONE,
      } as FileSaveResponse;

      if (files.length == 0) return response;

      const insertFormData = new FormData();
      const updateFiles: FileInfo[] = [];

      let strFileSortOrds = '';

      files.forEach((fileInfo) => {
        if (fileInfo.crudKey === CrudCode.CREATE) {
          insertFormData.append('files', fileInfo.file as Blob);
          strFileSortOrds += `${fileInfo.sortOrd},`;
        } else if (fileInfo.crudKey === CrudCode.UPDATE) {
          updateFiles.push(fileInfo);
        }
      });

      let result: any = null;

      if (insertFormData.has('files')) {
        insertFormData.append('atchFileGrId', atchFileGrId);
        insertFormData.set('fileSortOrds', strFileSortOrds.slice(0, -1));
        insertFormData.append('atchFileTpCd', atchFileTpCd);
        await uploadFiles(insertFormData);
      }

      if (updateFiles.length > 0) {
        result = await modifyFiles(updateFiles);
      }

      if (result || files.length > 0) {
        response.atchFileGrId = files[0].atchFileGrId;
        response.fileSaveResult = FileSaveResult.SUCCESS;
      } else {
        response.fileSaveResult = FileSaveResult.FAIL;
      }

      return response;
    };

    const cancelFile = (atchFileId: string) => {
      const tempFiles: FileInfo[] = [];
      files.map((file) => {
        if (file.atchFileId == atchFileId) {
          if (file.crudKey != CrudCode.CREATE) {
            tempFiles.push({ ...file, useYn: CommonYN.N, sortOrd: '', crudKey: CrudCode.UPDATE });
          }
        } else {
          tempFiles.push(file);
        }
      });
      setFiles(tempFiles);
    };

    return (
      <div className="drag-drop-wrap fileUploader">
        <div
          id="drag-drop"
          onDrop={handleAtchFileDrop}
          onDragOver={(e) => {
            e.preventDefault();
          }}
          onClick={handleAtchFileAddClick}
        >
          <div id="zone">
            <input
              multiple={isMultipleFile}
              type="file"
              onChange={handleFileChange}
              ref={fileInput}
              hidden
            />
            <span className="fileUploaderIcon"></span>
            <span className="fileDropInfo">
              {t('com.label.00688', '__첨부할 파일을 선택하거나 여기에 drag & drop하세요')}
            </span>
            <input
              type="text"
              placeholder={t2(
                'com.label.00688',
                '__첨부할 파일을 선택하거나 여기에 drag & drop하세요'
              )}
              className="input-file"
              readOnly={true}
            />
            <span className="upload-bite">
              {allFileSize >= 1000
                ? `${Math.ceil((allFileSize / 1000) * 10) / 10}KB`
                : `${allFileSize}bytes`}
              <span className="bite">/10MB</span>
            </span>
          </div>
          <div id="file-list">
            {files.map((file, index) => {
              if (!file.crudKey) {
                return (
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      fileDown(file);
                    }}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: '8px 8px 8px 8px',
                      height: '36px',
                      marginLeft: '2px',
                    }}
                    key={index}
                  >
                    <div style={{ display: 'inline-block' }}>
                      <span className="file-name">
                        <i className="icon icon-file"></i>
                        {file.atchFileNm}{' '}
                      </span>
                    </div>
                    <div style={{ display: 'inline-block' }}>
                      <span className="file-size">
                        {file.atchFileSize >= 1000
                          ? Math.ceil((file.atchFileSize / 1000) * 10) / 10 + 'KB'
                          : file.atchFileSize + 'bytes'}
                      </span>
                      <GInputIconButton
                        className="btn-del"
                        onClick={(e) => {
                          e.stopPropagation();
                          cancelFile(file.atchFileId);
                        }}
                        color="secondary"
                        variant="outlined"
                        size="small"
                      >
                        <p className="misuse"></p>
                      </GInputIconButton>
                    </div>
                  </div>
                );
              } else if (file.crudKey !== CrudCode.UPDATE) {
                return (
                  <div className="files" key={index}>
                    <div style={{ display: 'inline-block' }}>
                      <span className="file-name">
                        <i className="icon icon-file"></i>
                        {file.atchFileNm}{' '}
                      </span>
                    </div>
                    <div style={{ display: 'inline-block' }}>
                      <span className="file-size">
                        {file.atchFileSize >= 1000
                          ? Math.ceil((file.atchFileSize / 1000) * 10) / 10 + 'KB'
                          : file.atchFileSize + 'bytes'}
                      </span>
                      <GInputIconButton
                        className="btn-del"
                        onClick={(e) => {
                          e.stopPropagation();
                          cancelFile(file.atchFileId);
                        }}
                        color="secondary"
                        variant="outlined"
                        size="small"
                      >
                        <p className="misuse"></p>
                      </GInputIconButton>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
    );
  }
);

export default GFileUploadZone;
