import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { AccountMgtVO } from 'models/vars/account/AccountMgtVO';
import { ForeignAccountVO } from 'models/vars/account/ForeignAccountVO';
import { callApi } from 'utils/ApiUtil';
import { callJwtApi } from 'utils/JwtApiUtil';

const PREFIX_URI = '/api/sec/account/foreign';

export const getForeignAccounts = async (userId: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `${PREFIX_URI}/${userId}`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
  };
  const response: CommonResponse<ForeignAccountVO[]> = await callJwtApi(request);
  return response.successOrNot === 'Y' ? (response.data as ForeignAccountVO[]) : null;
};

export const getForeignAccount = async (userId: string, accnRegReqId: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `${PREFIX_URI}/detail/${userId}`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({ accnRegReqId }),
  };
  const response: CommonResponse<ForeignAccountVO> = await callJwtApi(request);
  return response.successOrNot === 'Y' ? (response.data as ForeignAccountVO) : null;
};

export const registForeignAccount = async (param, files, dftData) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `${PREFIX_URI}/regist-account`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    bodyParams: { ...param, ...files, dftData: dftData },
  };
  const response: CommonResponse = await callJwtApi(request);
  return response;
};

export const modifyForeignAccount = async (param, files, bfData) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `${PREFIX_URI}/modify-account`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    bodyParams: { ...param, ...files, bfData: bfData },
  };
  const response: CommonResponse = await callJwtApi(request);

  return response;
};

export const removeForeginAccount = async (accnRegReqId) => {
  const request: CommonRequest = {
    method: Method.DELETE,
    url: `${PREFIX_URI}/remove-account`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({ accnRegReqId }),
  };
  const response: CommonResponse = await callJwtApi(request);

  return response;
};

export const requestRegistForeignAccount = async (param) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `${PREFIX_URI}/request-regist-account`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    bodyParams: { ...param },
  };
  const response: CommonResponse = await callJwtApi(request);

  return response;
};

export const getMultiCopCdList = async (copCd) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `${PREFIX_URI}/multicopCdList`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({ copCd: copCd }),
  };
  const response: CommonResponse<any[]> = await callJwtApi(request);
  return response.successOrNot === 'Y' ? (response.data as any[]) : null;
};
