/** @jsxImportSource @emotion/react */
import useSessionStore from 'stores/useSessionStore';
import React, { useState, useRef, useEffect, useMemo, useCallback, memo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {
  ColDef,
  ValueFormatterParams,
  ISelectCellEditorParams,
  IsRowSelectable,
  IRowNode,
  CellValueChangedEvent,
  EditableCallbackParams,
  ICellRendererParams,
} from 'ag-grid-community';
import SelectCellRenderer from 'components/grids/SelectCellRenderer';
import { getCommonCodeGroups, setCommonCodeGroups } from 'apis/admin/CommonCode';
import { CommonCodeGroup } from 'models/admin/CommonCode';
import { CommonYN } from 'models/common/Common';
import { BlueButton, GreyLineButton } from 'components/buttons/CustomButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DoneIcon from '@mui/icons-material/Done';
import { useCommonModal } from 'hooks/useCommonModal';
import {
  IMessageCellRendererParams,
  MessageCellRenderer,
} from 'components/grids/MessageCellRenderer';
import { useTranslation } from 'react-i18next';
import { CrudCode } from 'models/common/Edit';
import { GridButtons, GridInfo, GridInfoSection } from 'components/layouts/ContentSection';
import { ContentGrid } from 'components/layouts/ContentGrid';
import Save from 'components/asset/images/Confirm.svg';
import { GButton } from 'components/atom/button';
interface SearchParamData {
  sGrCd?: string;
  sUseYn?: string;
  sCode?: string;
  editable: boolean;
  callBack?: any;
}

const CodeGroupGrid = (props: SearchParamData) => {
  const gridRef = useRef<AgGridReact<CommonCodeGroup>>(null);
  const [rowData, setRowData] = useState<CommonCodeGroup[]>([]);
  const { openCommonModal } = useCommonModal();
  const { t } = useTranslation();
  const { gridNoRowsTemplate } = useSessionStore();

  const defaultColDef: ColDef = useMemo(
    () => ({
      editable: props.editable,
      sortable: props.editable,
      resizable: props.editable,
      filter: props.editable,
      minWidth: 50,
      width: 150,
      cellStyle: { textAlign: 'center' },
    }),
    []
  );

  const [columnDefs, setColumnDefs] = useState<ColDef[]>([
    {
      headerName: 'No.',
      field: 'crudKey',
      width: 80,
      editable: false,
      filter: false,
      valueFormatter: (params: ValueFormatterParams) => {
        return `${parseInt(params.node!.id!) + 1}`;
      },
    },
    {
      width: 50,
      editable: false,
      checkboxSelection: props.editable,
      headerCheckboxSelection: props.editable,
      hide: !props.editable,
      cellStyle: { textAlign: 'center' },
    },
    {
      headerName: String(t('com.label.grCd', '__그룹코드')),
      field: 'cmnGrCd',
      width: 200,
      editable: (params: EditableCallbackParams) => {
        return params.node!.data.crudKey === CrudCode.CREATE;
      },
      cellRenderer: MessageCellRenderer,
      cellRendererParams: (params: ICellRendererParams<CommonCodeGroup>) => {
        return {
          msgCtn: params.data?.msgCtn,
          koMessage: params.data?.cmnGrCdNm,
          showButton: () => {
            return params.data?.crudKey !== CrudCode.CREATE;
          },
          yesCallback: (item?: string) => {
            if (item && params.data) {
              setCommonCodeGroups([
                { ...params.data, cmnGrCdNm: item, crudKey: CrudCode.UPDATE },
              ]).then(
                (result) =>
                  result &&
                  fnSearchCodeGroup(props.sGrCd || '', props.sUseYn || '', props.sCode || '')
              );
            }
            openCommonModal({ content: t('com.msg.save', '__저장되었습니다.') });
          },
        } as IMessageCellRendererParams;
      },
    },
    {
      headerName: String(t('com.label.grCdNm', '__그룹코드명')),
      field: 'cmnGrCdNm',
      width: 160,
      editable: (params: EditableCallbackParams) => {
        return params.node!.data.crudKey === CrudCode.CREATE;
      },
    },
    {
      headerName: String(t('com.label.desc', '__설명')),
      field: 'cmnGrCdDesc',
    },
    {
      headerName: String(t('com.label.msgCd', '__메시지코드')),
      field: 'msgCtn',
      editable: true,
    },
    {
      headerName: String(t('com.label.sortOrd', '__정렬순서')),
      field: 'sortOrd',
      valueParser: (params) => Number(params.newValue) || '',
      width: 110,
      cellStyle: { textAlign: 'center' },
    },
    {
      headerName: String(t('com.label.useYn', '__사용여부')),
      field: 'useYn',
      width: 110,
      cellStyle: { textAlign: 'center' },
      cellRenderer: SelectCellRenderer,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: [CommonYN.Y, CommonYN.N],
      } as ISelectCellEditorParams,
    },
    {
      headerName: String(t('com.label.mgt.1011', '__옵션명') + 1),
      field: 'optValNm1',
    },
    {
      headerName: String(t('com.label.mgt.1011', '__옵션명') + 2),
      field: 'optValNm2',
    },
    {
      headerName: String(t('com.label.mgt.1011', '__옵션명') + 3),
      field: 'optValNm3',
    },
    {
      headerName: String(t('com.label.mgt.1011', '__옵션명') + 4),
      field: 'optValNm4',
    },
    {
      headerName: String(t('com.label.mgt.1011', '__옵션명') + 5),
      field: 'optValNm5',
    },
    {
      headerName: String(t('com.label.mgt.1011', '__옵션명') + 6),
      field: 'optValNm6',
    },
    {
      headerName: String(t('com.label.mgt.1011', '__옵션명') + 7),
      field: 'optValNm7',
    },
    {
      headerName: String(t('com.label.mgt.1011', '__옵션명') + 8),
      field: 'optValNm8',
    },
    {
      headerName: String(t('com.label.mgt.1011', '__옵션명') + 9),
      field: 'optValNm9',
    },
    {
      headerName: String(t('com.label.mgt.1011', '__옵션명') + 10),
      field: 'optValNm10',
    },
    {
      headerName: String(t('com.label.mgt.1011', '__옵션명') + 11),
      field: 'optValNm11',
    },
    {
      headerName: String(t('com.label.mgt.1011', '__옵션명') + 12),
      field: 'optValNm12',
    },
    {
      headerName: String(t('com.label.mgt.1011', '__옵션명') + 13),
      field: 'optValNm13',
    },
    {
      headerName: String(t('com.label.mgt.1011', '__옵션명') + 14),
      field: 'optValNm14',
    },
    {
      headerName: String(t('com.label.mgt.1011', '__옵션명') + 15),
      field: 'optValNm15',
    },
    {
      headerName: String(t('com.label.rmk', '__비고')),
      field: 'rmk',
    },
  ]);

  const fnSearchCodeGroup = (searchGrCd: string, searchUseYn: string, searchCode: string) => {
    getCommonCodeGroups(searchGrCd, searchUseYn, searchCode).then((result: CommonCodeGroup[]) => {
      setRowData(result);
    });
  };

  const btnAddRow = useCallback(() => {
    const newRow = {
      crudKey: CrudCode.CREATE,
      useYn: CommonYN.Y,
    } as CommonCodeGroup;

    setRowData([newRow, ...rowData]);
  }, [rowData]);

  const btnDelRow = useCallback(() => {
    const selectedRowNodes = gridRef.current!.api.getSelectedNodes();

    const selectedIds = selectedRowNodes
      .map((rowNode) => {
        return parseInt(rowNode.id!);
      })
      .reverse();

    selectedIds.forEach((item) => {
      delete rowData[item];
    });

    const filteredData = rowData.filter((element) => element !== undefined);
    setRowData(filteredData);
  }, [rowData, gridRef]);

  const btnSave = () => {
    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.00170', '__저장하시겠습니까?'),
      noCallback: () => {
        return false;
      },
    });

    const valid = rowData
      .map((rowNode, index) => {
        if (rowNode.cmnGrCd == null || rowNode.cmnGrCd == '')
          return `${index + 1} :Null Cmn Gr Cd \n`;
        if (rowNode.cmnGrCdNm == null || rowNode.cmnGrCdNm == '')
          return `${index + 1} :Null Cmn Gr Cd Nm \n`;
        if (rowNode.sortOrd == null || rowNode.sortOrd == '') rowNode.sortOrd = '0';
      })
      .filter((element) => element !== undefined);
    if (valid?.length) {
      openCommonModal({ content: valid.toString() });
      return false;
    }

    const saveRows = rowData
      .map((rowNode) => {
        return rowNode.crudKey ? rowNode : null;
      })
      .filter((element) => element !== null) as CommonCodeGroup[];

    if (saveRows?.length == 0) {
      openCommonModal({
        content: t('com.label.mgt.1024', '__수정된 행이 없습니다.'),
      });
      return true;
    }

    setCommonCodeGroups(saveRows).then((result) => {
      if (!result) {
        openCommonModal({
          content: t('com.label.mgt.1025', '__저장 중 오류가 발생했습니다.'),
        });
        return false;
      }
      openCommonModal({
        content: t('com.msg.save', '__저장되었습니다.'),
      });
      fnSearchCodeGroup(props.sGrCd || '', props.sUseYn || '', props.sCode || '');
    });
  };

  const onCellClicked = (event: any) => {
    props.callBack && event.data.crudKey != CrudCode.CREATE && props.callBack(event.data.cmnGrCd);
  };

  useEffect(() => {
    fnSearchCodeGroup(props.sGrCd || '', props.sUseYn || '', props.sCode || '');
  }, [props.sGrCd, props.sUseYn, props.sCode]);

  const isRowSelectable = useMemo<IsRowSelectable>(() => {
    return (node: IRowNode) => {
      return !!node.data && node.data.crudKey === CrudCode.CREATE;
    };
  }, []);

  const onCellValueChanged = useCallback((e: CellValueChangedEvent) => {
    !e.data.crudKey && e.node.setDataValue('crudKey', CrudCode.UPDATE);
  }, []);

  return (
    <>
      <GridInfoSection>
        <GridInfo>
          <span>{t('com.label.mgt.1001', '__그룹코드목록')}</span>
          <span className="primary">
            {rowData?.length ?? 0}
            {t('com.label.case', '__건')}
          </span>
        </GridInfo>
        <GridButtons>
          {props.editable && (
            <GreyLineButton className="small" onClick={btnAddRow}>
              <AddIcon></AddIcon>
              {t('com.btn.addRow', '__행추가')}
            </GreyLineButton>
          )}
          {props.editable && (
            <GreyLineButton className="small" onClick={btnDelRow}>
              <RemoveIcon></RemoveIcon>
              {t('com.btn.delRow', '__행삭제')}
            </GreyLineButton>
          )}
        </GridButtons>
      </GridInfoSection>

      <ContentGrid
        className="ag-theme-alpine"
        style={{
          height: '300px',
        }}
      >
        <AgGridReact<CommonCodeGroup>
          overlayNoRowsTemplate={gridNoRowsTemplate}
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={true}
          suppressRowClickSelection={true}
          isRowSelectable={isRowSelectable}
          stopEditingWhenCellsLoseFocus={true}
          singleClickEdit={true}
          rowSelection="multiple"
          onCellClicked={onCellClicked}
          onCellValueChanged={onCellValueChanged}
        />
      </ContentGrid>
      <GridInfoSection className="contentEnd">
        <GridButtons>
          {props.editable && (
            <GButton
              chkImg={Save}
              txt={t('com.label.00055', '__저장')}
              sizes="medium"
              btnstyled="contained"
              btnColor="primary"
              hasImg={true}
              hasTxt={true}
              onClick={btnSave}
            />
          )}
        </GridButtons>
      </GridInfoSection>
    </>
  );
};

export default CodeGroupGrid;
