import styled from '@emotion/styled';
import { useContext, useEffect, useState } from 'react';
import { GlobalTokenColor } from 'ui/theme/Color';
import { Menu, MenuContextType } from 'models/admin/Menu';
import useSessionStore from 'stores/useSessionStore';
import { MenuContext } from 'App';
import { useTranslation } from 'react-i18next';
import SideMenuList from './SideMenuList';

export interface menuType {
  menuInfo: Menu;
  children: Menu[];
}

export interface menuStyleType {
  width?: string;
  marginTop?: string;
}

export const SideMenuBar = ({ width = '100%', marginTop = '0' }: menuStyleType) => {
  const [sideMenus, setSideMenus] = useState<menuType[]>([]);

  const { menus } = useSessionStore();
  const menuContext = useContext<MenuContextType>(MenuContext);
  const { t } = useTranslation();

  const nest = (menuData: Menu[], mnuId = '000000', link = 'upprMnuId') =>
    menuData
      .filter((item) => item[link] === mnuId)
      .map((item) => ({ ...item, children: nest(menuData, item.mnuId) }));

  useEffect(() => {
    if (menus && menuContext.selectedHeaderMenu) {
      const depth1Menus: Menu[] = menus.filter((item) => item.upprMnuId === '000100');

      const newSideMenuList: menuType[] = [];

      depth1Menus.forEach((item) => {
        const tree = nest(menus, item.mnuId);
        newSideMenuList.push({
          menuInfo: item,
          children: tree,
        });
      });
      setSideMenus(newSideMenuList);
    }
  }, [menuContext.selectedHeaderMenu]);

  return (
    <>
      {menuContext.selectedHeaderMenu && sideMenus.length > 0 && (
        <SideMenuWrap width={width} marginTop={marginTop}>
          <>
            {sideMenus.map((it) => (
              <SideMenuList
                key={it.menuInfo.mnuId}
                summary={{ menuInfo: it.menuInfo }}
                content={it.children}
                isActive={false}
                depth={2}
              />
            ))}
          </>
        </SideMenuWrap>
      )}
    </>
  );
};

const SideMenuWrap = styled.div<menuStyleType>`
  width: ${(props) => props.width};
  position: relative;
  background-color: ${GlobalTokenColor.ESGreyWHite};
  transition: 0.3s;
  border: 1px solid ${GlobalTokenColor.ESGrey300};
  border-radius: 2px;
  flex: 1;
  overflow-y: auto;
  margin-top: ${(props) => props.marginTop};
`;
