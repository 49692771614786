import React, { useEffect, useState } from 'react';

import { GButtonBox, GPopupTitle } from 'components/layouts/GLayoutBox';
import { DialogTitleItem } from 'components/layouts/GModalPopup';
import ModalLayout from 'components/layouts/ModalLayout';
import { DialogActions, DialogContent } from '@mui/material';
import { GButton } from 'components/atom/button';
import { GbasicTable, GbasicTableWrap } from 'components/tables/GTable';
import { GLabelAtom } from 'components/atom/label';
import Tag from 'components/atom/tag';
import FileDownloadVars from 'components/vars/common/FileDownloadVars';
import { useAccountRegist } from '../hooks/useAccountRegist';
import { getDomesticAccount, removeDomesticAccount } from 'apis/vars/account/DomesticAccount';
import { DomesitcAccountVO } from 'models/vars/account/DomesitcAccountVO';
import { useTranslation } from 'react-i18next';
import { useMessageBar } from 'components/process/MessageBar';

type DepartmentModalProps = {
  open: boolean;
  vendorInfo?: any;
  domAccount?: any;
  stdAccnCds?: any;
  close: () => void;
  save: (param?: boolean) => void;
  modify: () => void;
  node: any;
};

const DmsDtlKrwAcntModal = (props: DepartmentModalProps) => {
  const { t } = useTranslation();
  const [dtlDomAccount, setDtlDomAccount] = useState<DomesitcAccountVO>();
  const { openMessageBar } = useMessageBar();

  const handleClose = () => {
    props.close();
  };

  const handlClickModify = () => {
    props.modify();
  };

  const handlClickRemove = () => {
    removeDomesticAccount(dtlDomAccount?.accnRegReqId).then((resp) => {
      console.log(resp);
      if (resp.successOrNot === 'Y') {
        openMessageBar('success', t('com.label.00179', '__삭제되었습니다.'));
        props.save(true);
      }
    });
  };

  useEffect(() => {
    if (props.domAccount.accnRegReqId) {
      getDomesticAccount(props.domAccount.vdcpCd, props.domAccount.accnRegReqId).then((resp) => {
        if (resp) {
          setDtlDomAccount(resp);
        }
      });
    } else {
      setDtlDomAccount({ ...props.domAccount });
    }
  }, []);

  const { getTagStatus, callBackAccountsRetrieve } = useAccountRegist({});

  return (
    <ModalLayout open={props.open} onClose={handleClose} mMinWidth={1000} mMaxWidth={1000}>
      {dtlDomAccount && (
        <>
          <DialogTitleItem id="customized-dialog-title" onClose={handleClose}>
            <GPopupTitle title2={t('com.label.00455', '__계좌정보상세')} />
          </DialogTitleItem>
          <DialogContent className="popupContent">
            <GbasicTableWrap isBottomMgn={false}>
              <GbasicTable>
                <colgroup>
                  <col style={{ width: '160px' }} />
                  <col style={{ width: 'auto' }} />
                  <col style={{ width: '160px' }} />
                  <col style={{ width: 'auto' }} />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <GLabelAtom align="left">{t('com.label.00063', '__계좌종류')}</GLabelAtom>
                    </th>
                    <td>
                      {dtlDomAccount?.accnTpCd == 'F'
                        ? t('com.code.ACCN_REG_DIVS_CD.03', '현금계좌')
                        : t('com.code.ACCN_REG_DIVS_CD.04', '어음계좌')}
                    </td>
                    <th>
                      <GLabelAtom align="left">{t('com.label.00064', '__진행상태')}</GLabelAtom>
                    </th>
                    <td>
                      <Tag
                        content={dtlDomAccount?.accnRegProgStatCdNm}
                        status={getTagStatus(dtlDomAccount.accnRegProgStatCd ?? '')}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <GLabelAtom align="left">{t('com.label.00065', '__은행')}</GLabelAtom>
                    </th>
                    <td colSpan={3}>{dtlDomAccount.bankNm}</td>
                  </tr>
                  <tr>
                    <th>
                      <GLabelAtom align="left">{t('com.label.00067', '__예금주')}</GLabelAtom>
                    </th>
                    <td colSpan={3}>{dtlDomAccount.dpstNm}</td>
                  </tr>
                  <tr>
                    <th>
                      <GLabelAtom align="left">{t('com.label.00070', '__계좌번호')}</GLabelAtom>
                    </th>
                    <td>{dtlDomAccount.encAccnNo}</td>
                    <th>
                      <GLabelAtom align="left">{t('com.label.00076', '__통화')}</GLabelAtom>
                    </th>
                    <td>KRW</td>
                  </tr>
                  <tr>
                    <th>
                      <GLabelAtom align="left">{t('com.label.00071', '__계좌용도')}</GLabelAtom>
                    </th>
                    <td colSpan={3}>{dtlDomAccount.accnUsegNm}</td>
                  </tr>
                  {dtlDomAccount.isErpOnly == 'N' && (
                    <>
                      <tr>
                        <th>
                          <GLabelAtom align="left">
                            {t('com.label.00072', '__사업자등록증')}
                          </GLabelAtom>
                        </th>
                        <td colSpan={3} style={{ verticalAlign: 'top' }}>
                          <FileDownloadVars atchFileGrId={dtlDomAccount.fileId1 ?? ''} />
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <GLabelAtom align="left">
                            {t('com.code.ATCH_FILE_KRW_CD.10', '__통장사본')}
                          </GLabelAtom>
                        </th>
                        <td colSpan={3} style={{ verticalAlign: 'top' }}>
                          <FileDownloadVars atchFileGrId={dtlDomAccount.fileId2 ?? ''} />
                        </td>
                      </tr>
                      {dtlDomAccount.accnTpCd == 'G' && (
                        <tr>
                          <th>
                            <GLabelAtom align="left">
                              {t('com.label.00074', '__어음약정확인서')}
                            </GLabelAtom>
                          </th>
                          <td colSpan={3} style={{ verticalAlign: 'top' }}>
                            <FileDownloadVars atchFileGrId={dtlDomAccount.fileId3 ?? ''} />
                          </td>
                        </tr>
                      )}
                      <tr>
                        <th>
                          <GLabelAtom align="left">{t('com.label.00075', '__기타')}</GLabelAtom>
                        </th>
                        <td colSpan={3}>
                          <FileDownloadVars atchFileGrId={dtlDomAccount.fileId4 ?? ''} />
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </GbasicTable>
            </GbasicTableWrap>
          </DialogContent>
          <DialogActions className="popupBottom">
            <GButtonBox>
              {(dtlDomAccount.accnRegProgStatCd == 'T' ||
                dtlDomAccount.accnRegProgStatCd == 'R' ||
                dtlDomAccount.accnRegProgStatCd == 'C') && (
                <GButton
                  txt={t('com.label.00209', '__수정')}
                  sizes="medium"
                  onClick={() => {
                    handlClickModify();
                  }}
                  btnstyled="outline"
                  btnColor="normal"
                  hasImg={false}
                  hasTxt={true}
                />
              )}

              {dtlDomAccount.accnRegProgStatCd == 'T' && (
                <GButton
                  txt={t('com.label.00054', '__삭제')}
                  sizes="medium"
                  onClick={() => {
                    handlClickRemove();
                  }}
                  btnstyled="outline"
                  btnColor="normal"
                  hasImg={false}
                  hasTxt={true}
                />
              )}
            </GButtonBox>
          </DialogActions>
        </>
      )}
    </ModalLayout>
  );
};

export default DmsDtlKrwAcntModal;
