import { useState, useEffect, useContext, useRef } from 'react';
import styled from '@emotion/styled';
import { GlobalTokenColor } from 'ui/theme/Color';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Menu, MenuContextType } from 'models/admin/Menu';
import { MenuContext } from 'App';
import useSessionStore from 'stores/useSessionStore';
import { useTranslation } from 'react-i18next';
import Add from '../../asset/images/sideMenuAdd.svg';
import Minus from '../../asset/images/sideMenuMinus.svg';
import { GTooltipOverCust } from 'components/etc/GTooltipOverCust';
import { useCommonModal } from 'hooks/useCommonModal';

interface SideMenuListProps {
  summary: { menuInfo: Menu };
  content: Menu[];
  isActive: boolean;
  openParent?: () => void;
  depth?: 2 | 3;
}

const SideMenuList = ({ summary, content, isActive, openParent, depth }: SideMenuListProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(isActive);
  const navigate = useNavigate();
  const menuContext = useContext<MenuContextType>(MenuContext);
  const { openCommonModal } = useCommonModal();
  const { t } = useTranslation();

  useEffect(() => {
    isOpen && openParent && openParent();
  }, [isOpen]);

  const navigateToMenu = (menuUrl: string, linkTo?: boolean) => {
    linkTo && menuUrl && navigate(menuUrl, { replace: true });
    setIsOpen(!isOpen);
  };

  return (
    <AccordionContainer>
      {summary.menuInfo.mnuEpsYn === 'Y' && (
        <SideMenuSummaryWrap
          isAccClick={
            content.filter((item) => item.mnuEpsYn === 'Y').length > 0
              ? isOpen
              : menuContext.currentMenu.mnuId === summary.menuInfo.mnuId
          }
          on={
            menuContext.currentMenu?.mnuId === summary.menuInfo.mnuId ||
            (menuContext.currentMenu?.upprMnuId === summary.menuInfo.mnuId &&
              menuContext.currentMenu?.mnuEpsYn === 'N')
              ? true
              : false
          }
          onClick={() =>
            navigateToMenu(
              summary.menuInfo.mnuUrl ?? '',
              content.filter((item) => item.mnuEpsYn === 'Y').length === 0
            )
          }
          depth={depth}
        >
          <SideMenuSummary header={summary.menuInfo} depth={depth} />
          {content.filter((item) => item.mnuEpsYn === 'Y').length > 0 && (
            <SideMenuIcon isActive={isOpen} />
          )}
        </SideMenuSummaryWrap>
      )}
      {content.filter((item) => item.mnuEpsYn === 'Y').length > 0 && (
        <SideMenuContent
          contentList={content.filter((item) => item.mnuEpsYn === 'Y')}
          isActive={isOpen}
          openParent={() => setIsOpen(true)}
          depth={depth}
        />
      )}
    </AccordionContainer>
  );
};

const AccordionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const SideMenuSummaryWrap = styled.div<{
  isAccClick: boolean;
  on: boolean;
  depth: 2 | 3 | undefined;
}>`
  width: 100%;
  height: ${(props) => (props.depth == 3 ? '34px;' : '40px;')};
  padding: ${(props) => (props.depth == 3 ? '0 12px 0 36px;' : '0 12px 0 14px;')};

  display: flex;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;
  transition: 0.3s;
  overflow: hidden;

  &:hover {
    background: #dde0df;
  }

  ${(props) =>
    props.isAccClick &&
    `
    p {
      font-weight: 500;
      color: ${GlobalTokenColor.Primary700};
    }
  `}

  ${(props) =>
    props.on &&
    `
    background-color: ${GlobalTokenColor.ESGrey600};
    p {
      color: ${GlobalTokenColor.ESGreyWHite} !important;
    }
    
    &:hover {
      background-color: ${GlobalTokenColor.ESGrey600};
      p {
        color: ${GlobalTokenColor.ESGreyWHite} !important;
      }
    }
  `}
`;

const SideMenuIcon = styled.button<{ isActive: boolean }>`
  background: url(${(props) => (props.isActive ? `${Minus}` : `${Add}`)}) center center no-repeat;

  width: 12px;
  height: 12px;

  transition: 0.3s;
  transform: rotate(180deg);
`;

// SideMenuSummary
type SideMenuSummaryType = {
  header: Menu;
  depth?: 2 | 3;
};

const SideMenuSummary = ({ header, depth }: SideMenuSummaryType) => {
  const { t } = useTranslation();

  return (
    <>
      {header.mnuUrl ? (
        <GTooltipOverCust pTxt={t(`${header.msgCtn}`, `${header.mnuNm}`)}>
          <Link to={header.mnuUrl}>
            <SummaryTypography depth={depth}>
              {t(`${header.msgCtn}`, `${header.mnuNm}`)}
            </SummaryTypography>
          </Link>
        </GTooltipOverCust>
      ) : (
        <GTooltipOverCust pTxt={t(`${header.msgCtn}`, `${header.mnuNm}`)}>
          <SummaryTypography depth={depth}>
            {t(`${header.msgCtn}`, `${header.mnuNm}`)}
          </SummaryTypography>
        </GTooltipOverCust>
      )}
    </>
  );
};

const SummaryTypography = styled.p<{ depth: 2 | 3 | undefined }>`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${(props) =>
    props.depth == 3
      ? `
        font-size: 12x;
        color : ${GlobalTokenColor.ESGrey700};
        width: 164px;
      `
      : `
        font-size: 13px;
        width: 192px;
  `};
`;

// SideMenuContent
const SideMenuContent = ({ contentList, isActive, openParent, depth }: any) => {
  const [lowestMenu, setLowestMenu] = useState<Menu>();
  const menuContext = useContext<MenuContextType>(MenuContext);
  const { menus } = useSessionStore();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { openCommonModal } = useCommonModal();

  useEffect(() => {
    if (contentList.find((item) => item.mnuId === menuContext.currentMenu.mnuId)) {
      setLowestMenu(null as unknown as Menu);
      openParent();
    } else if (menuContext.currentMenu) {
      let cur = menuContext.currentMenu;
      while (cur.mnuId != '000000') {
        cur = menus.find((menu) => menu.mnuId === cur.upprMnuId) ?? (null as unknown as Menu);
        if (!cur) break;
        const lowestParent = contentList.find((item) => item.mnuId === cur.mnuId);
        if (lowestParent) {
          setLowestMenu(lowestParent);
          openParent();
          break;
        }
      }
    }
  }, [menuContext]);

  const navigateToMenu = (menuUrl: string) => {
    menuUrl && navigate(menuUrl ?? '/notFound', { replace: true });
  };

  return (
    <>
      {contentList.map((it) =>
        it.children && it.children.filter((child) => child.mnuEpsYn === 'Y').length > 0 ? (
          <ListWrap key={it.mnuId} isClicked={isActive}>
            <SideMenuList
              key={it.mnuId}
              summary={{ menuInfo: it }}
              content={it.children}
              isActive={false}
              openParent={openParent}
              depth={3}
            />
          </ListWrap>
        ) : (
          <Content
            key={it.mnuId}
            isClicked={isActive}
            isSelected={
              it.mnuId === menuContext.currentMenu.mnuId || it.mnuId === lowestMenu?.mnuId
            }
            depth={depth}
            on={
              menuContext.currentMenu?.mnuId === it.mnuId ||
              (menuContext.currentMenu?.mnuEpsYn === 'N' &&
                menuContext.currentMenu?.upprMnuId === it.mnuId)
                ? true
                : false
            }
            onClick={() => {
              if (it.mnuUrl === location.pathname.slice(1)) window.location.reload();
              else navigateToMenu(it.mnuUrl ?? '');
            }}
          >
            <GTooltipOverCust pTxt={t(`${it.msgCtn}`, `${it.mnuNm}`)}>
              <ContentLink to={it.mnuUrl ?? '/notFound'}>
                {t(`${it.msgCtn}`, `${it.mnuNm}`)}
              </ContentLink>
            </GTooltipOverCust>
          </Content>
        )
      )}
    </>
  );
};

const ListWrap = styled.div<{
  isClicked: boolean;
}>`
  height: ${(props) => (props.isClicked ? '100%' : '0px')};
  width: 100%;
  overflow: hidden;
  transition: height 0.25s ease;
`;

const Content = styled.div<{
  isClicked: boolean;
  isSelected: boolean;
  on: boolean;
  depth: 2 | 3 | undefined;
}>`
  width: 230px;
  padding-right: 10px;
  height: ${(props) => (props.isClicked ? '34px' : '0px')};
  overflow: hidden;
  transition: height 0.35s ease;

  display: flex;
  align-items: center;

  &:hover {
    background-color: ${GlobalTokenColor.ESGrey300};
    transition: 0.3s;
  }

  ${(props) =>
    props.depth == 3
      ? `
      padding-left : 58px;
      background-color : ${GlobalTokenColor.ESGrey200};
      
    `
      : `
      padding-left : 36px;
      
    `};

  ${(props) =>
    props.on &&
    `
    background-color: ${GlobalTokenColor.ESGrey600};
    a {
      color: ${GlobalTokenColor.ESGreyWHite} !important;
    }
    &:hover {
      background-color: ${GlobalTokenColor.ESGrey600};
    }
    `};
`;

const ContentLink = styled(Link)`
  width: 100%;
  display: block;
  transition: 0.3s;
  cursor: pointer;
  color: ${GlobalTokenColor.ESGrey700};
  font-weight: 400;
  font-size: 12px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default SideMenuList;
