/** @jsxImportSource @emotion/react */
import { GreyLineButton } from 'components/buttons/CustomButton';
import { ContentSection } from 'components/layouts/ContentSection';
import { css } from '@emotion/react';
import useSessionStore from 'stores/useSessionStore';
import { useEffect, useState } from 'react';

enum ERROR_PAGE {
  NOT_FOUND = 1,
  NOT_ALLOWD = 2,
}

export const NotFoundPage = () => {
  const { roleCodes } = useSessionStore();
  const [pageType, setPageType] = useState<ERROR_PAGE | null>(null);

  useEffect(() => {
    if (roleCodes.filter((item) => item !== 'PTN').length === 0) {
      setPageType(ERROR_PAGE.NOT_ALLOWD);
    } else {
      setPageType(ERROR_PAGE.NOT_FOUND);
    }
  }, [roleCodes]);

  return (
    <ContentSection>
      <div css={st.error}>
        <span className="icon">
          <i>
            <img src="/assets/icon/ic-ban.svg" alt="" />
          </i>
        </span>
        <p className="strong">
          {pageType === ERROR_PAGE.NOT_ALLOWD ? 'Not Allowed To Access' : 'Page Not Found'}
        </p>
        <p>
          {pageType === ERROR_PAGE.NOT_ALLOWD
            ? 'You don\t have permission to access this page'
            : 'Page does not exist or unusable'}
        </p>
        <p>
          {pageType === ERROR_PAGE.NOT_ALLOWD
            ? 'Please request permission and try again'
            : 'Please check if you entered the right page address'}
        </p>
        <GreyLineButton
          onClick={() => {
            window.history.go(-3);
            return false;
          }}
        >
          Go back to previous page
        </GreyLineButton>
      </div>
    </ContentSection>
  );
};

const st = {
  error: css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    p {
      &.strong {
        font-size: 15px;
        font-weight: 700;
        margin-bottom: 5px;
      }
      color: #979998;
      font-size: 13px;
      line-height: 24px;
    }
    button {
      margin-top: 10px;
    }
  `,
};
export default NotFoundPage;
