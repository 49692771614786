import React, { useEffect, useState } from 'react';
/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import AccordionArrow from 'components/asset/images/accordionArrow.svg';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { GButtonBox } from 'components/molecule/GbuttonLayout';
import { GButton } from 'components/atom/button';
import { SubTitleBtnArea } from 'components/organism/SubTitleBtnArea';
import SubTitleBox from 'components/molecule/SubTitleBox';
import Download from 'components/asset/images/Download.svg';
import { deleteAdminFaqListApi } from 'apis/vars/faq/FaqAdminApi';
import { useNavigate } from 'react-router-dom';
import { useCommonModal } from 'hooks/useCommonModal';
import { useTranslation } from 'react-i18next';
import FileDownloadVars from '../common/FileDownloadVars';
import useAuthStore from 'stores/useAuthStore';
import DetailContent from 'components/vars/common/DetailContent';
import { downloadAllFilesToFileName } from 'apis/admin/FileUpload';
import { downloadAllFilesToFileNameVars } from 'apis/vars/common/Common';
import { useLoading } from 'components/process/Loading';
import { useMessageBar } from 'components/process/MessageBar';
import useSessionStore from 'stores/useSessionStore';

interface ContentProps {
  faqNo: number;
  title: string;
  contents: string;
  atchFile: string | null;
  getFaqListData: () => void;
  atchFileCnt: number;
  atchFileExist: string;
}

export default function FaqContentAccordion(props: ContentProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { openLoading } = useLoading();
  const { openMessageBar } = useMessageBar();

  const { accessToken } = useAuthStore();
  const { roleCodes } = useSessionStore();

  const { openCommonModal } = useCommonModal();
  const [expanded, setExpanded] = React.useState<string | false>('panel');
  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    setExpanded(false);
  }, []);

  const handleEdit = () => {
    // navigate(`/faq/edit/${props.faqNo}`);
    navigate('/faq/edit', {
      state: { faqNo: props.faqNo },
    });
  };

  const handleDelete = async () => {
    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.00182', '__FAQ 게시글을 삭제하시겠습니까?'),
      noCallback: () => {
        return;
      },
      yesCallback: async () => {
        openLoading(true);
        return deleteAdminFaqListApi(props.faqNo)
          .then((res) => {
            if (res === true) {
              openLoading(false);
              openMessageBar('success', t('com.label.00179', '__삭제되었습니다.'));
              props.getFaqListData();
            }
          })
          .catch((error) => {
            openLoading(false);
            console.error('Error deleting row:', error);
          });
      },
    });
  };

  return (
    <>
      <Accordion expanded={expanded === 'panel'} onChange={handleChange('panel')}>
        <AccordionSummary aria-controls="paneld-content" id="paneld-header">
          <AccordionTitle expanded={expanded === 'panel'}>{props.title}</AccordionTitle>
          {!roleCodes.every((o) => o == 'PTN') && (
            <>
              {!accessToken && (
                <GButtonBox>
                  <GButton
                    txt={t('com.label.00209', '수정')}
                    sizes="small"
                    btnstyled="outline"
                    btnColor="normal"
                    hasImg={false}
                    hasTxt={true}
                    onClick={handleEdit}
                  ></GButton>
                  <GButton
                    txt={t('com.label.00054', '삭제')}
                    sizes="small"
                    btnstyled="outline"
                    btnColor="normal"
                    hasImg={false}
                    hasTxt={true}
                    onClick={handleDelete}
                  ></GButton>
                </GButtonBox>
              )}
            </>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <AccordionText>
            <DetailContent value={props.contents}></DetailContent>
          </AccordionText>
          {props.atchFile && props.atchFileCnt > 0 && (
            <FileBox>
              <SubTitleBtnArea>
                <SubTitleBox
                  showSubTitle={true}
                  content={t('com.label.00218', '첨부파일')}
                  isVisbleSum={true}
                  commentCount={props.atchFileCnt}
                />
                <GButton
                  chkImg={Download}
                  txt={t('com.label.00221', '전체다운로드')}
                  sizes="small"
                  btnstyled="outline"
                  btnColor="normal"
                  hasImg={true}
                  hasTxt={true}
                  onClick={() => {
                    if (accessToken) {
                      downloadAllFilesToFileNameVars(
                        props.atchFile,
                        `VARS_FAQ_[${props.faqNo}].zip`
                      );
                    } else {
                      downloadAllFilesToFileName(props.atchFile, `VARS_FAQ_[${props.faqNo}].zip`);
                    }
                  }}
                ></GButton>
              </SubTitleBtnArea>
              <FileDownloadVars atchFileGrId={props.atchFile} />
            </FileBox>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderTop: '1px solid #dde0df',
  '&.MuiAccordion-root::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<img src={AccordionArrow} alt="Expand Icon" />} {...props} />
))(({ theme }) => ({
  padding: '0px 8px',
  backgroundColor: '#fff',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'scaleY(-1)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: '0 0 24px 0',
  border: 'none',
  '&{AccordionTitle}': {
    color: 'var(--Common-Text-Basic, #1F1F1F)',
    fontFeatureSettings: 'clig off, liga off',

    fontFamily: 'Spoqa Han Sans Neo',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '150%',
  },
}));

const AccordionTitle = styled.p<{ expanded: boolean }>`
  color: ${(props) => (props.expanded ? '#1f1f1f' : '#5b5c5b')};
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Spoqa Han Sans Neo;
  font-size: 16px;
  font-style: normal;
  font-weight: ${(props) => (props.expanded ? '700' : '400')};
  line-height: 150%;
`;

const AccordionText = styled.div`
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  border-radius: 2px;
  background: #f7f9f8;

  color: #1f1f1f;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Spoqa Han Sans Neo;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

const FileBox = styled.div`
  margin-top: 12px;
  padding: 12px;
  border: 1px solid #dde0df;
  background: #f7f9f8;
`;
