import { ColDef, ICellEditorParams, ICellRendererParams } from 'ag-grid-community';
import { CellValueChangedEvent, GridSizeChangedEvent } from 'ag-grid-community';
import { CrudCode } from 'models/common/Edit';
import { Box, Button } from '@mui/material';
import styled from '@emotion/styled';
import { GGuideTitle, GLabel, GLabelInfoWithToolTip } from 'components/layouts/GLayoutBox';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import React, { useState, useMemo, useRef } from 'react';
import { useMessageBar } from 'components/process/MessageBar';
import { BlueButton } from 'components/buttons/CustomButton';
import { GContentGrid } from 'components/layouts/GContentGrid';
import { AgGridReact } from 'ag-grid-react';
import { ChipCellRenderer, IChipCellRendererParams } from 'components/grids/ChipCellRenderer';
import { GButton } from 'components/buttons/GButton';

export const MsgBar = styled.div`
  & {
    display: inline-block;
    position: relative;
    padding: 16px 50px 16px 16px;
    font-size: 13px;
    line-height: 19.5px;
    font-weight: 400;
    border-radius: 2px;
    box-sizing: border-box;
    max-width: 600px;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.2);
  }

  & .stat {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    vertical-align: top;
  }

  & .msg {
    display: inline-block;
    max-width: 500px;
  }

  & .noBorder {
    position: absolute;
    left: auto;
    right: 16px;
    top: 12px;
  }

  & .noBorder:hover {
    background: none !important;
  }

  &.normal {
    background: #3c3e3d;
    color: #ffffff;
  }

  &.normal .stat {
    display: none;
  }

  &.confirm {
    background: #dcfbea;
    color: #1f1f1f;
  }
  &.confirm .stat {
    display: inline-block;
    background-position: 0px 0px;
  }
  &.confirm .noBorder p {
    background-position: 0 -16px !important;
  }

  &.warning {
    background: #feefd6;
    color: #1f1f1f;
  }
  &.warning .stat {
    display: inline-block;
    background-position: 0px -20px;
  }
  &.warning .noBorder p {
    background-position: 0 -16px !important;
  }

  &.error {
    background: #fee6db;
    color: #1f1f1f;
  }
  &.error .stat {
    display: inline-block;
    background-position: 0px -40px;
  }
  &.error .noBorder p {
    background-position: 0 -16px !important;
  }
`;

const SampleComponent = () => {
  interface State extends SnackbarOrigin {
    open: boolean;
  }

  const { openMessageBar } = useMessageBar();

  const [state, setState] = useState<State>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });

  const defaultColDef: ColDef = useMemo(() => {
    return {
      resizable: true,
      cellStyle: { textAlign: 'center' },
    };
  }, []);

  const colDefs: any[] = useMemo(() => {
    return [
      {
        headerName: '__컬럼1',
        width: 120,
        field: 'col1',
      },
      {
        headerName: '__컬럼2',
        field: 'col2',
        width: 160,
        cellStyle: { textAlign: 'left' },
        editable: true,
      },
      {
        headerName: '__컬럼3',
        field: 'col3',
        width: 653,
        cellStyle: { textAlign: 'left' },
        cellRenderer: ChipCellRenderer,
        cellRendererParams: (params: ICellRendererParams) => {
          return {
            ...params,
            isEdit: true,
            useModal: {
              open: false,
              close: () => {
                return false;
              },
              ok: (arrSelected) => {
                params.node.setDataValue(
                  `${params?.colDef?.field}`,
                  arrSelected.reduce((acc, curr) => {
                    acc.push(curr.asstNm);
                    return acc;
                  }, [])
                );
              },
              path: 'asset/AssetListModal/AssetListModal',
            },
            delCallBack: (delIdx) => {
              params.node.setDataValue(
                `${params?.colDef?.field}`,
                params.value.filter((o, i) => i != delIdx)
              );
            },
          } as IChipCellRendererParams;
        },
      },
      {
        headerName: '__컬럼4',
        field: 'col4',
        width: 110,
      },
      {
        headerName: '__컬럼5',
        field: 'col5',
        width: 140,
      },
    ];
  }, []);

  const gridRef = useRef<any>(null);

  const bplcList = [
    {
      col1: '1',
      col2: '테스트1',
      col3: ['테스트1-1', '테스트1-2', '테스트1-3'],
      col4: 'col4',
      col5: 'col5',
    },
    {
      col1: '2',
      col2: '테스트3',
      col3: ['테스트2-1', '테스트2-2', '테스트2-3'],
      col4: 'col4',
      col5: 'col5',
    },
    {
      col1: '3',
      col2: '테스트3',
      col3: ['테스트3-1', '테스트3-2', '테스트3-3'],
      col4: 'col4',
      col5: 'col5',
    },
  ];

  const handleChangeCellValue = (e: CellValueChangedEvent) => {
    const { data, newValue, oldValue, api, node } = e;

    if (newValue === oldValue) {
      return;
    }

    if (data.crudKey !== CrudCode.CREATE) {
      data.crudKey = CrudCode.UPDATE;
    }

    api.applyTransaction({
      update: [data],
    });

    api.refreshCells({
      force: true,
      suppressFlash: true,
    });
  };

  return (
    <>
      <Box>
        <GGuideTitle>GLabel</GGuideTitle>
        <GLabel>GLabel</GLabel>
        <br />
        <br />
        <GGuideTitle>GLabel (isRequired)</GGuideTitle>
        <GLabel pTxt={undefined} isRequired>
          GLabel
        </GLabel>
        <br />
        <br />
        <GGuideTitle>GLabel (isInfo)</GGuideTitle>
        <GLabel isInfo={true} pTxt="기본 노출 위치는 12시 방향 입니다.">
          GLabel
        </GLabel>
        <br />
        <br />
        <GGuideTitle>GLabel (isRequired=true isInfo=true)</GGuideTitle>
        <GLabel isRequired={true} isInfo={true} pTxt="3시 방향 노출 툴팁" pPlace="3">
          GLabel
        </GLabel>
      </Box>
      <Box>
        <GGuideTitle>GTooltipOver</GGuideTitle>
        <GLabelInfoWithToolTip pTxt="기본 노출 위치는 12시 방향 입니다." isRequired={true}>
          GLabel Required Info With ToolTip
        </GLabelInfoWithToolTip>
        <br />
        <GLabelInfoWithToolTip pTxt="3시 방향 노출 툴팁" pPlace="3">
          GLabel Info With ToolTip
        </GLabelInfoWithToolTip>
        <br />
      </Box>
      <Box>
        <br />
      </Box>
      <Box>
        <GGuideTitle>Message Bar</GGuideTitle>
        <GButton
          color="primary"
          variant="contained"
          size="medium"
          onClick={() => {
            openMessageBar('error', '메시지 바에 노출될 텍스트');
          }}
        >
          MessageBar Test
        </GButton>
      </Box>
      <Box>
        <GGuideTitle>Chip Array in Grid</GGuideTitle>
        <div style={{ border: '1px solid #DDE0DF', borderTop: 'none' }}>
          <GContentGrid type="check" className="ag-theme-alpine" style={{ height: '410px' }}>
            <AgGridReact
              defaultColDef={defaultColDef}
              columnDefs={colDefs}
              rowData={bplcList}
              onCellValueChanged={handleChangeCellValue}
              onGridReady={(params) => (gridRef.current = params.api)}
              onGridSizeChanged={(e: GridSizeChangedEvent) => {
                e.api.sizeColumnsToFit();
              }}
            />
          </GContentGrid>
        </div>
      </Box>
    </>
  );
};

export default SampleComponent;
