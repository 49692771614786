import { RadioGroup } from '@mui/material';
import { GRadioButton } from '../atom/radio';

interface Props {
  option: string[];
  value: string;
  height?: string;
}

const GridRadioButton = (props: Props) => {
  return (
    <RadioGroup
      row
      sx={{ gap: '4px', alignItems: 'center', minHeight: `${props.height}` }}
      defaultValue={props.value + '0'}
      aria-labelledby="demo-customized-radios"
      name="primary-radios"
    >
      {props.option.map((it, index) => {
        return (
          <GRadioButton
            key={index}
            radioGroup={props.value}
            value={props.value + index}
            text={it}
          />
        );
      })}
    </RadioGroup>
  );
};

export default GridRadioButton;
