import styled from '@emotion/styled';

import { getEmployeeBySearchCondition } from 'apis/admin/Employee';
import DepartmentTree from 'pages/admin/departmentmanagement/DepartmentTree';
import { Employee } from 'models/admin/Employee';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import React, { createContext, useCallback, useEffect, useRef, useState } from 'react';
import { Department } from 'models/admin/Department';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import {
  BlueButton,
  GreyButton,
  GreyLineButton,
  IconButton,
} from 'components/buttons/CustomButton';
import { ContentSection, GridInfo, GridInfoSection } from 'components/layouts/ContentSection';
import { SearchBox, SearchRows, SearchButtons } from 'components/layouts/SearchBox';
import { CustomInputText } from 'components/inputs/CustomInput';
import { ContentFlex } from 'components/layouts/ContentFlex';
import { ContentGrid } from 'components/layouts/ContentGrid';
import { useCommonModal } from 'hooks/useCommonModal';
import { CrudCode } from 'models/common/Edit';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import useSessionStore from 'stores/useSessionStore';
import { GButton } from '../../atom/button';

import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import { GLabel } from 'components/layouts/GLayoutBox';

import { GlobalTokenColor, FontColor } from 'ui/theme/Color';
import { TextSize, TextWeight, TextLineHeight } from 'ui/theme/CustomTypography';

import CloseImg from '../../asset/Close.svg';
import Search from 'components/asset/images/Search.svg';

type Props = {
  open: boolean;
  close: () => void;
  save: (item: Employee[]) => void;
  singleSelect?: boolean;
};

const emptyDepartment: Department = {
  deptCd: '',
  coCd: '',
  deptNm: '',
  deptEngNm: '',
  temLdrUserId: '',
  upprDeptCd: '',
  useYn: '',
  deptCngNm: '',
};

export const defaultContext = {
  userDeptCd: '',
  userDepartment: emptyDepartment,
  departmentList: [] as Department[],
  selectedDepartment: [] as Department[],
  multipleSelect: false,
  handleSelectDepartment: (item: any) => {
    return;
  },
  handleGetAllDepartemtn: (item: any) => {
    return;
  },
};

export const DepartmentContext = createContext(defaultContext);

const EmployeeModal = (props: Props) => {
  const { t } = useTranslation();
  const { gridNoRowsTemplate } = useSessionStore();
  const gridRef = useRef<AgGridReact<Employee>>(null);
  const { openCommonModal } = useCommonModal();
  const [employeeList, setEmployeeList] = useState<Employee[]>([]);
  const [selectedEmployee, setSelectedEmployee] = useState<Employee[]>([]);
  const [searchItem, setSearchItem] = useState<string>('');
  const [contextValue, setContextValue] = useState({
    ...defaultContext,
    handleSelectDepartment: (item) => setContextValue(item),
  });

  const columns: ColDef[] = [
    {
      width: 51,
      headerCheckboxSelection: props.singleSelect ? false : true,
      checkboxSelection: true,
      cellStyle: { display: 'flex', justifyContent: 'flex-end' },
    },
    {
      headerName: 'No',
      width: 80,
      cellStyle: { textAlign: 'center' },
      cellRenderer: (params) => {
        return params.node.rowIndex + 1;
      },
    },
    {
      field: 'userId',
      headerName: String(t('com.label.00694', '__아이디')),
      width: 140,
      cellStyle: { textAlign: 'center' },
    },
    {
      field: 'empNm',
      headerName: String(t('com.label.00012', '__이름')),
      width: 120,
      cellStyle: { textAlign: 'center' },
    },
    {
      field: 'jtiNm',
      headerName: String(t('com.label.00695', '__직책/직급 호칭')),
      width: 140,
      cellStyle: { textAlign: 'center' },
    },
    {
      field: 'deptNm',
      headerName: String(t('com.label.dept', '__부서')),
      flex: 1,
      cellStyle: { textAlign: 'left' },
    },
  ];

  useEffect(() => {
    props.open && contextValue.selectedDepartment[0] && getEmployeeList();
  }, [contextValue]);

  const getEmployeeList = useCallback(async () => {
    const response = await getEmployeeBySearchCondition(
      searchItem,
      contextValue.selectedDepartment[0]?.deptCd
    );
    setEmployeeList(response);
  }, [contextValue, searchItem]);

  const handleOnClose = () => {
    setSelectedEmployee([]);
    props.close();
  };
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchItem(e.target.value);
  };

  const handleOnSelectionChange = useCallback(() => {
    setSelectedEmployee(gridRef.current?.api.getSelectedRows() ?? []);
  }, [employeeList]);

  const handleSearch = useCallback(() => {
    setContextValue({ ...contextValue, selectedDepartment: [emptyDepartment] });
    getEmployeeList();
  }, [searchItem]);

  const handleOnSave = () => {
    // if (selectedEmployee && selectedEmployee.length <= 0) {
    //   openCommonModal({
    //     content: t('common.alert.한명 이상 선택해야 합니다.', '__한명 이상 선택해야 합니다.'),
    //   });
    //   return;
    // }
    props.save(selectedEmployee);
    handleOnClose();
  };

  return (
    <DialogItem
      // style={{ height: '1000px' }}
      open={props.open}
      onClose={handleOnClose}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitleItem>
        {t('com.label.00696', '__인물 검색')}
        <GButton
          hasImg={true}
          sizes="medium"
          chkImg={CloseImg}
          imgWidth="20px"
          imgHeight="20px"
          onClick={handleOnClose}
        />
      </DialogTitleItem>
      <DialogContent className="popupContent">
        <SearchBox>
          <SearchRows>
            <div>
              <label> {t('com.label.00697', '__검색어')}</label>
              <CustomInputText
                value={searchItem}
                onChange={handleOnChange}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') handleSearch();
                }}
              ></CustomInputText>
            </div>
          </SearchRows>
          <SearchButtons>
            <GButton
              chkImg={Search}
              txt={t('com.label.00116', '__조회')}
              sizes="medium"
              btnstyled="contained"
              btnColor="normal"
              hasImg={true}
              hasTxt={true}
            />
          </SearchButtons>
        </SearchBox>
        <ContentFlex>
          <ContentSection className="section width30p marginT0">
            <DepartmentContext.Provider value={contextValue}>
              <DepartmentTree totalCount={true} isRetrieveEnabled={props.open} />
            </DepartmentContext.Provider>
          </ContentSection>
          <ContentSection className="section width65p">
            <GridInfoSection>
              <GridInfo>
                <span>{t('com.label.00699', '__사용자')}</span>
                <span className="primary">
                  {employeeList.length}
                  {t('com.label.case', '__건')}
                </span>
              </GridInfo>
            </GridInfoSection>
            <ContentGrid className="ag-theme-alpine" style={{ height: '372px' }}>
              <AgGridReact
                ref={gridRef}
                rowData={employeeList}
                columnDefs={columns}
                rowSelection={props.singleSelect ? 'single' : 'multiple'}
                suppressRowClickSelection={true}
                onSelectionChanged={handleOnSelectionChange}
                overlayNoRowsTemplate={gridNoRowsTemplate}
              ></AgGridReact>
            </ContentGrid>
          </ContentSection>
        </ContentFlex>
      </DialogContent>
      <DialogActionsItem>
        <GButton
          onClick={handleOnClose}
          btnstyled="outline"
          btnColor="normal"
          sizes="medium"
          txt={t('com.btn.cancel', '__취소')}
          hasTxt={true}
        />
        <GButton
          onClick={handleOnSave}
          btnstyled="contained"
          btnColor="normal"
          sizes="medium"
          txt={t('com.btn.ok', '__확인')}
          hasTxt={true}
        />
      </DialogActionsItem>
    </DialogItem>
  );
};

const DialogTitleItem = styled(DialogTitle)`
  padding: 0;
  font-size: 18px;
  font-weight: bold;
  color: ${FontColor.Primary700};
  &:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${FontColor.Primary};
  }
  > button {
    float: right;
    border: none;
    background-color: transparent;
  }
`;

const DialogActionsItem = styled(DialogActions)`
  padding: 0;

  &:last-child {
    background-color: none;
  }
`;

const DialogItem = styled(Dialog)`
  font-family: 'Spoqa Han Sans Neo';

  .MuiDialog-paper {
    min-width: 400px;
    padding: 25px;
    box-sizing: content-box;
    box-shadow: none;
    border-radius: 5px;
    color: ${FontColor.Default};
    word-break: keep-all;
  }

  .popupContent {
    padding: 20px 0;
    font-size: 13px;
    line-height: 19px;

    .searchBox {
      margin-bottom: 40px;
    }
  }

  .MuiDialogTitle-root + .MuiDialogContent-root {
    padding-top: 20px;
  }

  .buttonsTop {
    text-align: right;
    & + .section {
      margin-top: 20px;
    }
  }
`;

export default EmployeeModal;
