import styled from '@emotion/styled';
import React, { useContext, useRef } from 'react';
import { GlobalTokenColor } from 'ui/theme/Color';
import useSessionStore from 'stores/useSessionStore';
import { getUserInfo2 } from 'apis/common/Employee';
import { useEffect, useState } from 'react';
import { MenuContext } from 'App';
import { MenuContextType } from 'models/admin/Menu';
import HeadLinkList from './HeadLinkList';
import GSelectMUIAtom from 'components/atom/select';
import { SelectChangeEvent } from '@mui/material';
import EditProfile from 'components/molecule/Profile';
import ProfileImg from 'components/asset/images/userIcon.svg';
import Logo from './components/Logo';
import Star from 'components/asset/images/star.svg';
import { GButton } from 'components/atom/button';
import Condensed from 'components/asset/images/Condensed.svg';
import Sitemap from './components/HeadLinkList/Sitemap';
import { Link, useNavigate } from 'react-router-dom';
import BookMark from 'components/atom/bookMark';

import useAuthStore from 'stores/useAuthStore';
import { useLocation } from 'react-router-dom';
import useLanguageStore from 'stores/useLanguageStore';
import { useTranslation } from 'react-i18next';
import { useCommonModal } from 'hooks/useCommonModal';

const langType = ['ko', 'en', 'zhC'];

const HeadMenuBar = () => {
  const { userId, userNm, langCd, deptNm, menus } = useSessionStore();
  const menuContext = useContext<MenuContextType>(MenuContext);
  const { pathname } = useLocation();

  const [slctdMnuLv, setSlctdMnuLv] = useState<number | null | undefined>(null);

  const [userInfo, setUserInfo] = useState('');
  const { accessToken } = useAuthStore();
  const { changeLanguage } = useLanguageStore();

  const { t } = useTranslation();
  const { openCommonModal } = useCommonModal();

  const navigate = useNavigate();

  useEffect(() => {
    const init = async () => {
      if (userId) {
        if (accessToken) {
          setUserInfo(deptNm);
        } else {
          setUserInfo(await getUserInfo2());
        }
      }
    };
    init();
  });

  const [option, setOption] = React.useState('1');
  const handleChange = (event: SelectChangeEvent) => {
    setOption(event.target.value);
    changeLanguage(langType[parseInt(event.target.value) - 1 + '']);
    if (menuContext.currentPage.mnuId === '000147') {
      navigate('/', { replace: true });
    } else if (accessToken) {
      menuContext.handleMenuChange({ ...menuContext, selectedHeaderMenu: '000139' });
      navigate('/account-regist', { replace: true });
    } else {
      navigate('/system/admin/member/member-list', { replace: true });
    }
  };

  useEffect(() => {
    if (langCd) {
      setOption(langType.findIndex((type) => type === langCd) + 1 + '');
    }
  }, [langCd]);

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    if (sitemapOpen) {
      setSitemapOpen(false);
    } else {
      setOpen(!open);
    }
  };

  const [sitemapOpen, setSitemapOpen] = useState(false);
  const handleClickSitemapOpen = () => {
    setOpen(false);
    setSitemapOpen(true);
  };
  const handleSitemapClose = () => {
    setSitemapOpen(false);
  };

  // 메뉴 이외 영역 클릭시 닫힘
  const menuRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleOutsideClose = (e: { target: any }) => {
      if (open && !menuRef.current?.contains(e.target)) setOpen(false);
    };
    document.addEventListener('click', handleOutsideClose);

    return () => document.removeEventListener('click', handleOutsideClose);
  }, [open]);

  // const dummyData = ['사고관리', 'claim 관리', '배서현황', '보험개요', '보험현황', '보험증권'];
  // E : [Dev]

  useEffect(() => {
    if (menus.length > 0) {
      const lvNo: number | null | undefined =
        menus.filter((o) => o.mnuId == menuContext.selectedHeaderMenu)[0]?.mnuLvNo ?? null;
      setSlctdMnuLv(lvNo ?? null);
    }
  }, [menuContext.selectedHeaderMenu, slctdMnuLv]);

  return (
    <HeadMenu path={pathname}>
      {/* <LogoWrap headerMenu={menuContext.selectedHeaderMenu} mnuLv={slctdMnuLv}> */}
      {/* 사이드 메뉴를 쓰는 프로젝트는 
      사이드 메뉴 폴딩 버튼이 접혀있지 않는 상황으로 가이드 고정
      만약 사이드메뉴 폴딩 버튼이 조건부로 바뀌거나 하면 
      LogoWrap에 headerMenu 부분 확인
       */}
      <LogoWrap headerMenu={'true'} mnuLv={slctdMnuLv}>
        <Logo />
      </LogoWrap>

      <HeadLinkList />

      <RightMenu>
        {/* 회원가입 시 다국어 처리 필요 */}
        <GSelectMUIAtom
          selectWidth="70px"
          // option={['한국어', 'English', '中文']}
          option={['KOR', 'ENG', 'CHN']}
          nonColor={true}
          value={option}
          handleChange={handleChange}
        />
        {userId && (
          <>
            {/*
            즐겨찾기 버튼 클릭시 메뉴가 뜨도록 onClick이벤트를 넣었습니다. 
            즐겨찾기 메뉴와 메뉴에서 AllMenu클릭시 나오는 전체 메뉴 페이지(Sitemap)를 불러왔습니다  */}

            {/* <div ref={menuRef}>
              <GButton
                chkImg={Star}
                imgHeight="24px"
                imgWidth="24px"
                sizes="none"
                btnstyled="contained"
                btnColor="none"
                hasImg={true}
                hasTxt={false}
                onClick={handleClickOpen}
              />

              <BookMarkMenu open={open}>
                <ul></ul>
                <SitemapButton onClick={handleClickSitemapOpen}>All Menus</SitemapButton>
              </BookMarkMenu>
            </div> */}
            {/* <Sitemap open={sitemapOpen} handleClose={handleSitemapClose} /> */}
            <EditProfile
              type="1"
              textColor="#5b5c5b"
              profileImg={ProfileImg}
              nickName={userInfo}
              userId={userId}
            />
          </>
        )}

        {/* <span>
          {theme.palette.mode} mode
          <Button
            sx={{ ml: 1 }}
            onClick={colorMode.toggleColorMode}
            color="inherit"
            variant="contained"
          >
            {theme.palette.mode === 'dark' ? <p>다크</p> : <p>라이트</p>}
          </Button>
        </span> */}
      </RightMenu>
    </HeadMenu>
  );
};

const BookMarkMenu = styled.div<{ open: boolean }>`
  ${(props) =>
    props.open
      ? `
    visibility: visible;
    opacity: 1;
    `
      : `
    visibility: hidden;
    opacity: 0;
  `}
  transition: all 0.1s;
  z-index: 999;

  position: absolute;
  top: 54px;
  right: 68px;

  width: 200px;
  padding: 8px 0;
  background-color: #ffff;
  border: 1px solid #b9bcbb;
  border-radius: 2px;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.11), 0px 0px 2px 0px rgba(0, 0, 0, 0.17);

  li {
    a {
      color: #1f1f1f;
      line-height: 20px;
      margin-left: 4px;
    }

    display: flex;
    align-items: center;
    padding: 6px 8px;

    &:hover {
      background-color: #eeeaf8;
    }
  }
`;

const SitemapButton = styled.button`
  background-color: transparent;
  color: #554596;
  font-size: 13px;
  font-weight: 700;

  width: 100%;
  padding: 6px 16px;

  display: flex;
  align-items: center;

  cursor: auto;

  &::after {
    content: '';
    display: inline-block;
    background: url(${Condensed}) center center no-repeat;
    width: 24px;
    height: 24px;
    margin-left: 4px;
    cursor: pointer;
  }
`;

const HeadMenu = styled.div<{ path: string }>`
  position: relative;
  width: 100%;
  height: 52px;
  background-color: ${GlobalTokenColor.ESGrey50};
  display: ${({ path }) => (path != '/' && path != '/business/login' ? 'flex' : 'none')};
  align-items: center;
  justify-content: space-between;
  padding-right: 16px;
  border-bottom: 1px solid #ddd;
  font-family: 'Spoqa Han Sans Neo';
`;

const LogoWrap = styled.div<{ headerMenu: string; mnuLv: number | null | undefined }>`
  height: 100%;
  display: flex;
  align-items: center;
  ${(props) => (props.mnuLv ? 'padding-left: 52px;' : 'border-left: 16px solid #2d9bb2;')}
  ${(props) => (props.headerMenu ? 'padding-left: 52px;' : 'border-left: 16px solid #2d9bb2;')}
`;

const RightMenu = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export default React.memo(HeadMenuBar);
