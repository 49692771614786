import styled from '@emotion/styled';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import selectConfirm from '../../asset/images/selectConfirm.svg';
import { GlobalTokenColor } from 'ui/theme/Color';
import ErrorIcon from 'components/asset/images/error.svg';
import WarningIcon from 'components/asset/images/warning.svg';
import ConfirmedIcon from 'components/asset/images/confirmed.svg';
import { HelperText } from '../input';

interface Props {
  selectHeight?: string | undefined;
  selectWidth?: string | undefined;
  placeholder?: string;
  option: string[];
  readOnly?: boolean;
  disabled?: boolean;
  isRequired?: boolean;
  value?: string;
  handleChange: (event: SelectChangeEvent) => void;
  nonColor?: boolean;
  helperText?: string;
  status?: 'default' | 'error' | 'warning' | 'confirmed';
  header?: boolean;
}

const GSelectMUIAtom = ({
  selectHeight,
  selectWidth,
  placeholder,
  option,
  readOnly = false,
  disabled = false,
  isRequired,
  value,
  handleChange,
  nonColor,
  status = 'default',
  helperText,
  header = false,
}: Props) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: `${selectWidth || '100%'}`,
      }}
    >
      <SelectMUI
        nonColor={nonColor}
        selectWidth={selectWidth}
        selectHeight={selectHeight}
        inputProps={{ 'aria-label': 'Without label' }}
        value={value}
        onChange={(event: any) => handleChange(event)}
        isRequired={isRequired}
        readOnly={readOnly}
        readOnlyStyle={readOnly}
        disabledStyle={disabled}
        disabled={disabled}
        status={status}
        header={header}
      >
        <PlaceholderOption value={0}>
          <Placeholder readOnly={readOnly}>{placeholder}</Placeholder>
        </PlaceholderOption>
        {option.map((it, index) => {
          return (
            <Option key={index + 1} value={index + 1}>
              {it}
            </Option>
          );
        })}
      </SelectMUI>
      {helperText && <Helper status={status}>{helperText}</Helper>}
    </div>
  );
};

const Helper = styled(HelperText)<{ status: string | undefined }>``;

const Placeholder = styled.span<{ readOnly: boolean | undefined }>`
  color: ${(prop) =>
    prop.readOnly ? `${GlobalTokenColor.ESGrey900}` : `${GlobalTokenColor.StatsuDone500}`};
  font-size: 13px;
  font-weight: 400;
  display: inline-block;
`;

const PlaceholderOption = styled(MenuItem)`
  visibility: hidden;
  padding: 0;
  min-height: 0;
  height: 0;
  width: 0;
  line-height: 0;

  &:hover {
    background: ${GlobalTokenColor.Secondary50} !important;
  }

  &.Mui-selected {
    background: ${GlobalTokenColor.ESGreyWHite} url(${selectConfirm}) 90% center no-repeat !important;

    &:hover {
      background: ${GlobalTokenColor.Secondary50} url(${selectConfirm}) 90% center no-repeat !important;
    }
  }
`;

const Option = styled(MenuItem)`
  font-size: 13px;
  font-weight: 400;
  padding: 6px 8px;
  color: ${GlobalTokenColor.ESGrey900};

  &:hover {
    background: ${GlobalTokenColor.Secondary50} !important;
  }

  &.Mui-selected {
    background-image: url(${selectConfirm}) !important; /* 배경 이미지 설정 */
    background-color: ${GlobalTokenColor.ESGreyWHite} !important;
    background-repeat: no-repeat !important; /* 배경 이미지 반복 제거 */
    background-position: right 8px top 8px !important; /* 오른쪽으로 8px 이동 */
    &:hover {
      background-image: url(${selectConfirm}) !important; /* 배경 이미지 설정 */
      background-color: ${GlobalTokenColor.Secondary50} !important;
      background-repeat: no-repeat !important; /* 배경 이미지 반복 제거 */
      background-position: right 8px top 8px !important; /* 오른쪽으로 8px 이동 */
    }
  }
`;

export const SelectMUI = styled(Select)<{
  selectWidth: string | undefined;
  readOnlyStyle: boolean | undefined;
  disabledStyle: boolean | undefined;
  selectHeight: string | undefined;
  isRequired: boolean | undefined;
  nonColor: boolean | undefined;
  status: string | undefined;
  header: boolean | undefined;
}>`
  height: ${(prop) => prop.selectHeight || '32px'};
  min-width: ${(prop) => prop.selectWidth || '100%'};
  margin: 0;
  border-radius: 2px;
  font-size: 13px;
  ${(props) => props.status == 'error' && `border: 1px solid #FDA293 !important;`}
  ${(props) => props.status == 'warning' && `border: 1px solid #FFCB7A !important;`}
  ${(props) => props.status == 'confirmed' && `border: 1px solid #56D8AA !important;`}

  &:focus-within,
  &.Mui-focused,
  &.Mui-expanded {
    border-color: ${GlobalTokenColor.ESGrey700} !important;
  }

  &:before {
    ${(props) => (props.isRequired ? `content : ""` : ``)};
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: ${GlobalTokenColor.StatusError500};
    position: absolute;
    left: 1px;
    top: 1px;
  }

  &::after {
    ${(props) => props.status === 'error' && `content: url('${ErrorIcon}');`}
    ${(props) => props.status === 'warning' && `content: url('${WarningIcon}');`}
    ${(props) => props.status === 'confirmed' && `content: url('${ConfirmedIcon}');`}
    position: absolute;
    top: 50%;
    right: 24px;
    transform: translate(0, -50%);
    width: 16px;
    height: 16px;
    background-color: #fff;
    cursor: pointer;
  }

  & .MuiSelect-select {
    border: none;
    margin: 0;
    padding: 0 0 0 10px;
    font-size: 13px;
    font-weight: normal;
    color: ${GlobalTokenColor.ESGrey900};
  }

  .MuiButtonBase-root.MuiMenuItem-root {
    font-size: 13px;
  }

  .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input {
    ${(props) =>
      props.header &&
      `
      width: ${props.selectWidth};
      height: 20px;
      padding-right: 0px;
  `}
  }
  .MuiSelect-icon {
    width: 0.8em;
    height: 0.8em;
    right: 5px;
    top: calc(50% - 0.4em);
    transform: none;

    ${(props) =>
      props.header &&
      `
    left: 39px;
  `}
  }

  fieldset {
    border: none;
  }

  ${(props) =>
    !props.nonColor &&
    `
      border: 1px solid ${GlobalTokenColor.ESGrey400};
      background-color: ${
        props.readOnlyStyle || props.disabledStyle
          ? `${GlobalTokenColor.ESGrey100}`
          : `${GlobalTokenColor.ESGreyWHite}`
      };

      ${
        !props.disabledStyle &&
        `
          &:hover,
          &:active {
            ${(props) => props.status == 'error' && `border: 1px solid #FDA293 !important;`}
            ${(props) => props.status == 'warning' && `border: 1px solid #FFCB7A !important;`}
            ${(props) => props.status == 'confirmed' && `border: 1px solid #56D8AA !important;`}
            border: 1px solid ${GlobalTokenColor.ESGrey600} !important;
          }
        `
      };
      
    `}
`;

export default GSelectMUIAtom;
