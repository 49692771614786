import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { GButtonBox, GPopupTitle } from 'components/layouts/GLayoutBox';
import { DialogTitleItem } from 'components/layouts/GModalPopup';
import { GButton } from 'components/atom/button';
import ModalLayout from 'components/layouts/ModalLayout';
import styled from '@emotion/styled';
import { t } from 'i18next';
import useSessionStore from 'stores/useSessionStore';
import PrivacyEn from './PrivacyEn';
import PrivacyKo from './PrivacyKo';
import PrivacyZhc from './PrivacyZhc';
import { useTranslation } from 'react-i18next';

type PrivacyModalProps = {
  open: boolean;
  close: () => void;
  save: () => void;
};

const PrivacyPolicyModal = (props: PrivacyModalProps) => {
  // 'ko', 'en', 'zhC'
  const { t } = useTranslation();
  const { langCd } = useSessionStore();

  const handleClose = () => {
    props.close();
  };

  return (
    <>
      <ModalLayout open={props.open} onClose={handleClose} mMinWidth={1600} mMaxWidth={1600}>
        <DialogTitleItem id="customized-dialog-title" onClose={handleClose}>
          <GPopupTitle title2={t('com.label.00007', '__개인정보 처리방침')} />
        </DialogTitleItem>
        <DialogContent className="popupContent">
          <AreaAlignWrap>
            {langCd === 'en' ? <PrivacyEn /> : langCd === 'ko' ? <PrivacyKo /> : <PrivacyZhc />}
          </AreaAlignWrap>
        </DialogContent>
        <DialogActions className="popupBottom">
          <GButtonBox>
            {/* <GButton
              txt={t('com.label.00036', '취소')}
              sizes="medium"
              onClick={handleClose}
              btnColor="normal"
              btnstyled="outline"
              hasImg={false}
              hasTxt={true}
            /> */}
          </GButtonBox>
        </DialogActions>
      </ModalLayout>
    </>
  );
};

const AreaAlignWrap = styled.div`
  width: 100%;
  display: flex;
  gap: 24px;
`;

export default PrivacyPolicyModal;
