import { InfoMsg, InputBox, InputBoxProps } from 'components/inputs/InputBox';
import { ReactNode } from 'react';

export type ValidationBoxProps = {
  msg?: string;
  children: any;
  errorBorder?: boolean;
};

export const ValidationBox = ({ msg, children, errorBorder }: ValidationBoxProps) => {
  const style = errorBorder && msg ? { border: '1px solid #fda293', marginBottom: '2px' } : {};

  return (
    <>
      <div style={style}>{children}</div>
      <InfoMsg status="error">{msg}</InfoMsg>
    </>
  );
};

export interface ValidationInputBoxProps extends InputBoxProps {
  msg?: string;
}

export const ValidationInputBox = ({ msg, status, ...props }: ValidationInputBoxProps) => {
  return (
    <>
      <InputBox status={msg ? 'error' : 'normal'} {...props} />
      {msg && <InfoMsg status="error">{msg}</InfoMsg>}
    </>
  );
};
