import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import {
  DetailReq,
  KFrgnCurDetail,
  KFrgnCurRes,
  KNonPaymentRes,
  KPaymentDetail,
  KPaymentRes,
  PaymentSearchParams,
} from 'pages/vars/payment/KPaymentSchedulePage';
import { callJwtApi } from 'utils/JwtApiUtil';
import {
  NotePayable,
  NotePayableDetail,
  ODetailReq,
  OPaymentDetail,
  OPaymentRes,
  PaymentDue,
} from 'pages/vars/payment/OPaymentSchedulePage';
import { CommonCode } from 'models/admin/CommonCode';

// 지급내역조회(USER)
export const getKPaymentListApi = async (params: PaymentSearchParams) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/api/sec/payments`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({ ...params }),
  };
  const response: CommonResponse<KPaymentRes[]> = await callJwtApi(request);
  return (response.successOrNot === 'Y' ? response.data : []) as KPaymentRes[];
};

// 지급내역조회 상세(USER)
export const getKPaymentDetailApi = async (detailReq: DetailReq) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/api/sec/payments/details`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({ ...detailReq }),
  };
  const response: CommonResponse<KPaymentDetail[]> = await callJwtApi(request);
  return (response.successOrNot === 'Y' ? response.data : []) as KPaymentDetail[];
};

// 미지급 조회(USER)
export const getKNonPaymentApi = async (params: PaymentSearchParams) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/api/sec/payments/non-payments`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({ ...params }),
  };
  const response: CommonResponse<KNonPaymentRes[]> = await callJwtApi(request);
  return (response.successOrNot === 'Y' ? response.data : []) as KNonPaymentRes[];
};

// 외화지급내역 조회(USER)
export const getFrgnCurApi = async (params: PaymentSearchParams) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/api/sec/payments/foreign-payments`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({ ...params }),
  };
  const response: CommonResponse<KFrgnCurRes[]> = await callJwtApi(request);
  return (response.successOrNot === 'Y' ? response.data : []) as KFrgnCurRes[];
};

// 외화지급내역 상세(USER)
export const getFrgnCurDetailApi = async (detailReq: DetailReq) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/api/sec/payments/foreign-details`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({ ...detailReq }),
  };
  const response: CommonResponse<KFrgnCurDetail[]> = await callJwtApi(request);
  return (response.successOrNot === 'Y' ? response.data : []) as KFrgnCurDetail[];
};

// 해외법인 지급내역조회(업체-법인명)
export const getOverseasCopCdsWithJwt = async () => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/api/sec/payments/overseas/cop-list`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
  };
  const response: CommonResponse<CommonCode[]> = await callJwtApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as CommonCode[];
};

// ---------------------------------------해외----------
// PaymentList(USER)
export const getOPaymentListApi = async (params: PaymentSearchParams) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/api/sec/payments/overseas`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({ ...params }),
  };
  const response: CommonResponse<OPaymentRes[]> = await callJwtApi(request);
  return (response.successOrNot === 'Y' ? response.data : []) as OPaymentRes[];
};

// PaymentList Detail(USER)
export const getOPaymentDetailApi = async (detailReq: ODetailReq) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/api/sec/payments/overseas/details`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({ ...detailReq }),
  };
  const response: CommonResponse<OPaymentDetail[]> = await callJwtApi(request);
  return (response.successOrNot === 'Y' ? response.data : []) as OPaymentDetail[];
};

// Note Payable(Issue)(USER)
export const getONotePayableApi = async (params: PaymentSearchParams) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/api/sec/payments/overseas/notepayable`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({ ...params }),
  };
  const response: CommonResponse<NotePayable[]> = await callJwtApi(request);
  return (response.successOrNot === 'Y' ? response.data : []) as NotePayable[];
};

// Note Payable(Issue) Detail(USER)
export const getONotePayableDetailApi = async (detailReq: ODetailReq) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/api/sec/payments/overseas/notepayable-details`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({ ...detailReq }),
  };
  const response: CommonResponse<NotePayableDetail[]> = await callJwtApi(request);
  return (response.successOrNot === 'Y' ? response.data : []) as NotePayableDetail[];
};

// PaymentDue(USER)
export const getPaymentDueApi = async (params: PaymentSearchParams) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/api/sec/payments/overseas/payment-due-list`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({ ...params }),
  };
  const response: CommonResponse<PaymentDue[]> = await callJwtApi(request);
  return (response.successOrNot === 'Y' ? response.data : []) as PaymentDue[];
};
