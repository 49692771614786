import { create } from 'zustand';

interface PrintStore {
  accnId: string;
  setAccnId: (value: string) => void;
  ovAccnId: string;
  setOvAccnId: (value: string) => void;
}

export const usePrintStore = create<PrintStore>((set) => ({
  // 원화 > 복수계좌 신고서 출력 시 전달하는 accnRegReqId 값
  accnId: '',
  setAccnId: (value) => set({ accnId: value }),
  // 외화 > PIF 출력 시 전달하는 accnRegReqId 값
  ovAccnId: '',
  setOvAccnId: (value) => set({ ovAccnId: value }),
}));
