import useSessionStore from 'stores/useSessionStore';
import { useState, useRef, useEffect, useMemo, useCallback, memo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {
  deleteRoleDepartment,
  getRoleDepartment,
  insertRoleDepartment,
} from 'apis/admin/RoleDepartment';
import {
  ColDef,
  ValueFormatterParams,
  ICellRendererParams,
  CellValueChangedEvent,
} from 'ag-grid-community';
import SelectCellRenderer from 'components/grids/SelectCellRenderer';
import { Department } from 'models/admin/Department';
import { BlueButton, GreyLineButton, IconButton } from 'components/buttons/CustomButton';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { CommonYN } from 'models/common/Common';
import { useCommonModal } from 'hooks/useCommonModal';
import { CrudCode } from 'models/common/Edit';
import DepartmentModal from 'components/modals/common/DepartmentModal';
import { ContentGrid } from 'components/layouts/ContentGrid';
import DoneIcon from '@mui/icons-material/Done';

import {
  ContentSection,
  GridInfoSection,
  GridInfo,
  GridButtons,
} from 'components/layouts/ContentSection';
import { useTranslation } from 'react-i18next';
import { CustomTag } from 'components/buttons/CustomTag';
import { CustomInputText } from 'components/inputs/CustomInput';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';

interface SearchParamData {
  roleCd: string;
  editable: boolean;
  height?: number;
  width?: number;
}

const DepartmentManageTable = (props: SearchParamData) => {
  const newRow: Department = {
    deptCd: '',
    coCd: '',
    deptNm: '',
    deptEngNm: '',
    deptCngNm: '',
    temLdrUserId: '',
    upprDeptCd: '',
    useYn: CommonYN.Y,
    crudKey: CrudCode.CREATE,
    isDeleted: false,
    isUpdated: false,
  };

  const gridRef = useRef<AgGridReact<Department>>(null);
  const [rowData, setRowData] = useState<Department[]>([]);
  const [rowIdx, setRowIdx] = useState<number>(-1);
  const { openCommonModal } = useCommonModal();
  const { t } = useTranslation();
  const { gridNoRowsTemplate } = useSessionStore();

  const defaultColDef: ColDef = {
    editable: props.editable,
    resizable: true,
    cellStyle: { textAlign: 'center' },
  };

  const columnDefs: ColDef[] = [
    {
      headerName: '',
      headerCheckboxSelection: true,
      width: 50,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      hide: !props.editable,
    },
    {
      headerName: String(t('com.label.stat', '__상태')),
      field: 'crudKey',
      width: 80,
      cellStyle: { textAlign: 'center' },
      hide: !props.editable,
      cellRenderer: function (params: any) {
        if (params.data.crudKey === CrudCode.DELETE && !params.data.isUpdated) {
          return <CustomTag className="red">{t('com.label.00179', '__삭제었습니다.')}</CustomTag>;
        } else if (params.data.crudKey === CrudCode.UPDATE) {
          return (
            <CustomTag className="yellow">{t('com.label.00173', '__수정되었습니다.')}</CustomTag>
          );
        } else if (params.data.crudKey === CrudCode.CREATE) {
          return <CustomTag>{t('common.label.추가됨', '__추가됨')}</CustomTag>;
        } else {
          return;
        }
      },
    },
    {
      headerName: 'No.',
      width: 80,
      editable: false,
      filter: false,
      valueFormatter: (params: ValueFormatterParams) => {
        return `${parseInt(params.node!.id!) + 1}`;
      },
    },

    {
      headerName: String(t('com.label.deptCd', '__부서코드')),
      field: 'deptCd',
      width: 120,
      editable: props.editable,
      cellRenderer: (params: ICellRendererParams) => (
        <div>
          {!props.editable && <div style={{ width: '150px' }}>{params.value}</div>}
          {props.editable && (
            <>
              <CustomInputWithSearch
                value={params.value ?? ''}
                readOnly
                onSearchClick={() => {
                  handleClickOpen(params.rowIndex);
                }}
                deleteButton={false}
              />
            </>
          )}
        </div>
      ),
    },
    {
      headerName: String(t('com.label.00096', '__법인코드')),
      field: 'coCd',
      width: 120,
      editable: false,
    },
    {
      headerName: String(t('com.label.deptNm', '__부서명')),
      field: 'deptNm',
      flex: 1,
      editable: false,
      cellStyle: { alignText: 'left' },
    },
  ];

  const [open, setOpen] = useState(false);

  const handleClickOpen = (rowIdx: number) => {
    if (rowIdx === -1) return;
    setRowIdx(rowIdx);
    setOpen(true);
  };

  const onGridReady = (e) => {
    e.api.sizeColumnsToFit();
  };

  const fnSearchRoleDepartment = function (params: string) {
    getRoleDepartment(params)
      .then((result: Department[]) => {
        result &&
          result.map((row) => {
            row.crudKey = CrudCode.READ;
          });
        setRowData(result);
      })
      .then(() => {
        gridRef.current?.api.sizeColumnsToFit();
      });
  };

  const fnAddRow = () => {
    setRowData([{ crudKey: CrudCode.CREATE, deptCd: '' } as Department, ...rowData]);
  };

  const fnDelRow = () => {
    const selectedRowNodes = gridRef.current!.api.getSelectedNodes();

    const selectedIds = selectedRowNodes
      .map((rowNode) => {
        return parseInt(rowNode.id!);
      })
      .reverse();

    selectedIds.forEach((item) => {
      if (rowData[item].crudKey === CrudCode.CREATE) {
        delete rowData[item];
      } else {
        rowData[item].crudKey = CrudCode.DELETE;
      }
    });
    const filteredData = rowData.filter((element) => element !== undefined);

    setRowData(filteredData);
  };

  console.log(rowData);
  const fnSave = async () => {
    const saveDeptCds = rowData
      .map((rowNode) => {
        if (rowNode.crudKey === CrudCode.CREATE || rowNode.crudKey === CrudCode.UPDATE) {
          return rowNode.deptCd;
        }
      })
      .filter((element) => element) as string[];

    const deleteDeptCds = rowData
      .map((rowNode) => {
        if (rowNode.crudKey === CrudCode.DELETE) {
          return rowNode.deptCd;
        }
      })
      .filter((element) => element !== undefined) as string[];

    if (saveDeptCds.length === 0 && deleteDeptCds.length === 0) {
      openCommonModal({
        content: t('role-department.alert.저장할 정보가 없습니다.', '__저장할 정보가 없습니다.'),
      });
      return;
    }

    let result: any = null;
    if (saveDeptCds.length > 0) result = await insertRoleDepartment(props.roleCd, saveDeptCds);
    if (deleteDeptCds.length > 0) result = await deleteRoleDepartment(props.roleCd, deleteDeptCds);

    if (result != null) {
      openCommonModal({ content: t('com.msg.save', '__저장되었습니다.') });
      fnSearchRoleDepartment(props.roleCd);
    } else {
      openCommonModal({
        content: t('com.label.mgt.1025', '__저장 중 오류가 발생했습니다.'),
      });
    }
  };

  const handleSaveDepartment = (department: Department) => {
    if (rowIdx < 0) return;
    const newDepartments: Department[] = [...rowData];
    if (newDepartments[rowIdx].crudKey !== CrudCode.CREATE) {
      newDepartments.push({ ...newDepartments[rowIdx], crudKey: CrudCode.DELETE, isUpdated: true });
      newDepartments[rowIdx] = { ...department, crudKey: CrudCode.UPDATE } as Department;
    } else {
      newDepartments[rowIdx] = { ...department, crudKey: CrudCode.CREATE } as Department;
    }
    setRowData([
      ...newDepartments.filter((item) => item.crudKey !== CrudCode.DELETE),
      ...newDepartments.filter((item) => item.crudKey === CrudCode.DELETE),
    ]);
  };

  // Example load data from server
  useEffect(() => {
    !props.roleCd || fnSearchRoleDepartment(props.roleCd);
  }, [props]);

  return (
    <>
      <ContentSection className={`section width${props.width ?? 50}p`}>
        <GridInfoSection>
          <GridInfo>
            <span>{t('com.label.dept', '__부서')}</span>
            <span className="primary">
              {rowData.length}
              {t('com.label.case', '__건')}
            </span>
          </GridInfo>
          {props.editable && (
            <GridButtons>
              <GreyLineButton className="small" onClick={fnAddRow}>
                <AddIcon></AddIcon>
                {t('com.btn.addRow', '__행추가')}
              </GreyLineButton>
              <GreyLineButton className="small" onClick={fnDelRow}>
                <RemoveIcon></RemoveIcon>
                {t('com.btn.delRow', '__행삭제')}
              </GreyLineButton>
            </GridButtons>
          )}
        </GridInfoSection>
        <ContentGrid className="ag-theme-alpine" style={{ height: `${props.height ?? 500}px` }}>
          <AgGridReact<Department>
            overlayNoRowsTemplate={gridNoRowsTemplate}
            ref={gridRef}
            rowData={rowData.filter((data) => !data.isUpdated)}
            columnDefs={columnDefs}
            suppressRowClickSelection={true}
            defaultColDef={defaultColDef}
            rowSelection="multiple"
            onGridReady={onGridReady}
          />
        </ContentGrid>
        {props.editable && (
          <GridInfoSection className="contentEnd">
            <GridButtons>
              <BlueButton onClick={fnSave}>
                <DoneIcon></DoneIcon>
                {t('com.btn.save', '__저장')}
              </BlueButton>
            </GridButtons>
          </GridInfoSection>
        )}
      </ContentSection>
      <DepartmentModal open={open} close={() => setOpen(false)} save={handleSaveDepartment} />
    </>
  );
};

export default memo(DepartmentManageTable);
