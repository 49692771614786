/* eslint-disable prettier/prettier */
import SearchMolecule, {
  GButtonBoxCum,
  SearchItemWrap,
  SearchWrap,
  UnHiddenSearchWrap,
} from 'components/molecule/SearchMolecule';
import { GButton } from 'components/atom/button';
import SubTitleBox from 'components/molecule/SubTitleBox';
import searchImage from 'components/asset/images/Search.svg';
import { GContentGrid } from 'components/layouts/GContentGrid';
import { SubTitleBtnArea } from 'components/organism/SubTitleBtnArea';
import { AgGridReact } from 'ag-grid-react';
import { useEffect, useRef, useState } from 'react';
import { SampleResponse } from 'models/sample/Sample';
import RecycleB from 'components/asset/images/RecycleB.svg';
import styled from '@emotion/styled';
import GDatePicker from 'components/inputs/GDatePicker';
import { GLabelAtom } from 'components/atom/label';
import GSelectMUIAtom from 'components/atom/select';
import { SelectChangeEvent } from '@mui/material';
import React from 'react'

// 지불예정내역
import { usePaymentScheduledDetailsDefs } from './gridDef/PaymentScheduledDetailsDefs';
// 지불내역
import { usePaymentDetailsDefs } from './gridDef/PaymentDetailsDefs';
//수표
import { useCheckDefs } from './gridDef/CheckDefs';
import { useLoading } from 'components/process/Loading';
import { useMessageBar } from 'components/process/MessageBar';
import { PaginationRef } from 'components/layouts/GPagination';
import { getSamples } from 'apis/sample/SampleApi';

const Payment = () => {
  // get grid data, defaultColDef
  const [sampleList, setSampleList] = useState<SampleResponse[]>([]);
  const gridApiRef = useRef<any>(null);
  const { defaultColDef, colDefs } = usePaymentScheduledDetailsDefs();
  const { defaultColDef1, colDefs1 } = usePaymentDetailsDefs();
  const { defaultColDef2, colDefs2 } = useCheckDefs();
  // pagination
  const [totalCount, setTotalCount] = useState<number>(0);
  const paginationRef = useRef<PaginationRef>(null);
  const [pageSize, setPageSize] = useState<number>(6);
  const [pageNo, setPageNo] = useState<number>(1);
  const { openLoading } = useLoading();
  const { openMessageBar } = useMessageBar();

  useEffect(() => {
    loadSampleList();
  }, [pageNo, pageSize]);

  const makeSearchParams = () => {
    return {
      start: String(pageSize * (pageNo - 1)),
      pageSize: String(pageSize),
    };
  };

  const handleError = (e: Error) => {
    openLoading(false);
    openMessageBar('error', e.message);
  };

  const loadSampleList = async () => {
    getSamples(makeSearchParams())
      .then((data) => {
        setSampleList(data.list);
        setTotalCount(data?.totalCount || 0);
      })
      .catch(handleError);
  };
    const [option, setOption] = React.useState('1');
    const handleChange = (event: SelectChangeEvent) => {
      setOption(event.target.value);
    }

  return (
    <>
      <SearchWrap>
        <UnHiddenSearchWrap>
          <SearchItemWrap>
            <SearchMolecule
              searchWidth="50%"
              type="select"
              labelTitle="조회구분"
              searchValueWidth="300px"
              defaultOption="1"
              selectOption={['지불예정 내역', '옵션2']}
              isRequired={true}
            />
            <SearchMolecule
              searchWidth="50"
              type="select"
              labelTitle="조회구분"
              searchValueWidth="300px"
              defaultOption="1"
              selectOption={['원화', '옵션2']}
            />
            <GInputArea>
                <GLabelWrap>
                    <GLabelAtom align="right" pTxt={undefined} labelWidth='120px'>
                        지급일시
                    </GLabelAtom>
                </GLabelWrap>
                <SelectWrap>
                    <GSelectMUIAtom
                        option={['지급일']}
                        value={option}
                        selectWidth="100px"
                        handleChange={handleChange}
                    />
                </SelectWrap>
                <ValueWrap>
                    <GDatePicker 
                    double = {true}
                    width="128px"
                    />
                </ValueWrap>
            </GInputArea>
            <SearchMolecule
              type="input"
              labelTitle="송장번호"
              searchValueWidth="300px"
              searchWidth="50"
              placeholder="입력하세요"
            />
          </SearchItemWrap>
          <GButtonBoxCum>
            <GButton
              chkImg={RecycleB}
              sizes="medium"
              btnstyled="outline"
              btnColor="normal"
              hasImg={true}
              hasTxt={false}
            ></GButton>
            <GButton
              chkImg={searchImage}
              txt="조회"
              sizes="medium"
              btnstyled="contained"
              btnColor="normal"
              hasImg={true}
              hasTxt={true}
            ></GButton>
          </GButtonBoxCum>
        </UnHiddenSearchWrap>
      </SearchWrap>
      <SubTitleBtnArea>
        <SubTitleBox
          content="지불예정 내역"
          isVisibleComment={false}
          isVisibleInfo={false}
          isVisbleSum={false}
          showSubTitle={true}
          commentCount={2}
        />
      </SubTitleBtnArea>
      <GContentGrid
        type="none"
        className="ag-theme-alpine"
        style={{ flex: 1, marginBottom: '12px' }}
        isSortable={false}
        scrollHeight="48px"
      >
        <AgGridReact
          defaultColDef={defaultColDef}
          columnDefs={colDefs}
          rowData={sampleList}
          rowSelection={'single'}
          //suppressRowClickSelection
          //suppressMovableColumns
          enableCellTextSelection
          onGridReady={(params) => (gridApiRef.current = params.api)}
          tooltipShowDelay={0}
          //tooltipHideDelay={2000}
        ></AgGridReact>
      </GContentGrid>
      <SubTitleBtnArea>
        <SubTitleBox
          content="지불내역"
          isVisibleComment={false}
          isVisibleInfo={false}
          isVisbleSum={false}
          showSubTitle={true}
          commentCount={2}
        />
      </SubTitleBtnArea>
      <GContentGrid
        type="none"
        className="ag-theme-alpine"
        style={{ flex: 1, marginBottom: '12px' }}
        isSortable={false}
        scrollHeight="48px"
      >
        <AgGridReact
          defaultColDef={defaultColDef1}
          columnDefs={colDefs1}
          rowData={sampleList}
          rowSelection={'single'}
          //suppressRowClickSelection
          //suppressMovableColumns
          enableCellTextSelection
          onGridReady={(params) => (gridApiRef.current = params.api)}
          tooltipShowDelay={0}
          //tooltipHideDelay={2000}
        ></AgGridReact>
      </GContentGrid>
      <SubTitleBtnArea>
        <SubTitleBox
          content="수표"
          isVisibleComment={false}
          isVisibleInfo={false}
          isVisbleSum={false}
          showSubTitle={true}
          commentCount={2}
        />
      </SubTitleBtnArea>
      <GContentGrid
        type="none"
        className="ag-theme-alpine"
        style={{ flex: 1, marginBottom: '8px' }}
        isSortable={false}
        scrollHeight="48px"
      >
        <AgGridReact
          defaultColDef={defaultColDef2}
          columnDefs={colDefs2}
          rowData={sampleList}
          rowSelection={'single'}
          //suppressRowClickSelection
          //suppressMovableColumns
          enableCellTextSelection
          onGridReady={(params) => (gridApiRef.current = params.api)}
          tooltipShowDelay={0}
          //tooltipHideDelay={2000}
        ></AgGridReact>
      </GContentGrid>
    </>
  );
};
const GInputArea = styled.div`
  display: flex;
  width: 50%;
`;

const SelectWrap = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const ValueWrap = styled.div`
  margin-left: 4px;
`;
const GLabelWrap = styled.div`
  margin-right: 8px;
`;

export default Payment;