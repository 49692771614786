import { unescape } from 'lodash';

export const DefaultCellTooltip = (params: any) => {
  const el = params.api.rowRenderer.rowCtrlsByRowIndex[params.rowIndex]?.centerCellCtrls?.list
    .filter((o) => o.column?.colId === params.column.colId)?.[0]
    ?.eGui?.querySelector('.ag-cell-value');

  const mergeEl = params.api.rowRenderer.rowCtrlsByRowIndex[params.rowIndex]?.centerCellCtrls?.list
    .filter((o) => o.column?.colId === params.column.colId)?.[0]
    ?.eGui?.querySelector('.ag-cell-value')
    ?.querySelector('#merge');

  const clWidth = el?.clientWidth || 0;
  const scWidth = el?.scrollWidth || 0;

  const mergeClWidth = mergeEl?.clientWidth || 0;
  const mergeScWidth = mergeEl?.scrollWidth || 0;

  return (
    <div
      style={{
        backgroundColor: '#3C3E3D',
        color: '#FFFFFF',
        fontSize: '13px',
        fontWeight: '400',
        padding: '4px 8px',
        borderRadius: '2px',
        display:
          clWidth < scWidth || mergeClWidth < mergeScWidth || (clWidth === 0 && mergeClWidth === 0)
            ? ''
            : 'none',
      }}
    >
      <pre>
        {mergeClWidth < mergeScWidth
          ? unescape(mergeEl.innerHTML)
          : unescape(params.valueFormatted) || unescape(params.value)}
      </pre>
    </div>
  );
};
