import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { DetailReq, PaymentSearchParams } from 'pages/vars/payment/KPaymentSchedulePage';
import { ODetailReq } from 'pages/vars/payment/OPaymentSchedulePage';
import { callApi } from 'utils/ApiUtil';
// 지급내역조회 엑셀 다운로드
export const getKPaymentExcelDownloadAdApi = async (params: PaymentSearchParams) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/file/admin-exceldownload/admin/payments`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({ ...params }),
    headers: {
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'Content-Type': 'application/json',
    },
    responseType: 'blob',
  };
  const response: CommonResponse<Blob> = await callApi(request);
  if (response?.data) downloadFile(response.data, params.downloadName + '.xlsx');
};

// 지급내역조회 상세 엑셀 다운로드
export const getKPaymentDetailExcelDownloadAdApi = async (detailReq: DetailReq) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/file/admin-exceldownload/admin/payments-details`,
    queryParams: new URLSearchParams({ ...detailReq }),
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    headers: {
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'Content-Type': 'application/json',
    },
    responseType: 'blob',
  };
  const response: CommonResponse<Blob> = await callApi(request);
  if (response?.data) downloadFile(response.data, detailReq.downloadName + '.xlsx');
};

// 미지급내역조회 엑셀 다운로드
export const getKNonPaymentExcelDownloadAdApi = async (params: PaymentSearchParams) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/file/admin-exceldownload/admin/nonpayments`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({ ...params }),
    headers: {
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'Content-Type': 'application/json',
    },
    responseType: 'blob',
  };
  const response: CommonResponse<Blob> = await callApi(request);
  if (response?.data) downloadFile(response.data, params.downloadName + '.xlsx');
};

// 외화지급조회 엑셀 다운로드
export const getFrgnCurExcelDownloadAdApi = async (params: PaymentSearchParams) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/file/admin-exceldownload/admin/foreign-payments`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({ ...params }),
    headers: {
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'Content-Type': 'application/json',
    },
    responseType: 'blob',
  };
  const response: CommonResponse<Blob> = await callApi(request);
  if (response?.data) downloadFile(response.data, params.downloadName + '.xlsx');
};

// 외화지급조회 상세 엑셀 다운로드
export const getFrgnCurDetailsExcelDownloadAdApi = async (detailReq: DetailReq) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/file/admin-exceldownload/admin/foreign-payments-details`,
    queryParams: new URLSearchParams({ ...detailReq }),
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    headers: {
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'Content-Type': 'application/json',
    },
    responseType: 'blob',
  };
  const response: CommonResponse<Blob> = await callApi(request);
  if (response?.data) downloadFile(response.data, detailReq.downloadName + '.xlsx');
};

// -----------------------------해외----------------------------

// PaymentList 엑셀 다운로드
export const getOPaymentExcelDownloadAdApi = async (params: PaymentSearchParams) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/file/admin-exceldownload/admin/overseas/payments`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({ ...params }),
    headers: {
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'Content-Type': 'application/json',
    },
    responseType: 'blob',
  };
  const response: CommonResponse<Blob> = await callApi(request);
  if (response?.data) downloadFile(response.data, params.downloadName + '.xlsx');
};

// PaymentList Details 엑셀 다운로드
export const getOPaymentDetailsExcelDownloadAdApi = async (detailReq: ODetailReq) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/file/admin-exceldownload/admin/overseas/payments-details`,
    queryParams: new URLSearchParams({ ...detailReq }),
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    headers: {
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'Content-Type': 'application/json',
    },
    responseType: 'blob',
  };
  const response: CommonResponse<Blob> = await callApi(request);
  if (response?.data) downloadFile(response.data, detailReq.downloadName + '.xlsx');
};

// NotePayable 엑셀 다운로드
export const getONotePayableExcelDownloadAdApi = async (params: PaymentSearchParams) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/file/admin-exceldownload/admin/overseas/notepayable`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({ ...params }),
    headers: {
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'Content-Type': 'application/json',
    },
    responseType: 'blob',
  };
  const response: CommonResponse<Blob> = await callApi(request);
  if (response?.data) downloadFile(response.data, params.downloadName + '.xlsx');
};

// NotePayable 상세 엑셀 다운로드
export const getONotePayableDetailsExcelDownloadAdApi = async (detailReq: ODetailReq) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/file/admin-exceldownload/admin/overseas/notepayalbe-details`,
    queryParams: new URLSearchParams({ ...detailReq }),
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    headers: {
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'Content-Type': 'application/json',
    },
    responseType: 'blob',
  };
  const response: CommonResponse<Blob> = await callApi(request);
  if (response?.data) downloadFile(response.data, detailReq.downloadName + '.xlsx');
};

// PaymentDueList 엑셀 다운로드
export const getOPaymentDueListExcelDownloadAdApi = async (params: PaymentSearchParams) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/file/admin-exceldownload/admin/overseas/paymentdue`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({ ...params }),
    headers: {
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'Content-Type': 'application/json',
    },
    responseType: 'blob',
  };
  const response: CommonResponse<Blob> = await callApi(request);
  if (response?.data) downloadFile(response.data, params.downloadName + '.xlsx');
};

export const downloadFile = (data: Blob, filename: string) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
