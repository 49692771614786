import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { FaqListRes } from 'pages/vars/faq/FaqListPage';
import { FaqDetailRes, FaqEdit, FaqRegist } from 'pages/vars/faq/FaqRegistPage';
import { callApi } from 'utils/ApiUtil';

// Faq 리스트 불러오기(관리자)
export const getAdminFaqListApi = async () => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/bbs/faqs`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
  };
  const response: CommonResponse<FaqListRes[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : []) as FaqListRes[];
};

// Faq 게시글 상세조회(관리자)
export const getAdminFaqDetailApi = async (faqNo: number) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/bbs/faq/${faqNo}`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
  };
  const response: CommonResponse<FaqDetailRes> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : []) as FaqDetailRes;
};

// Faq 게시글 등록하기(관리자)
export const postAdminFaqContentApi = async (searchParams: FaqRegist) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/bbs/faq`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    bodyParams: searchParams,
  };
  const response: CommonResponse = await callApi(request);
  return response.successOrNot === 'Y' ? true : false;
};

// Faq 게시글 수정하기(관리자)
export const editAdminFaqContentApi = async (faqEdit: FaqEdit) => {
  const request: CommonRequest = {
    method: Method.PUT,
    url: `/v1/bbs/faq/${faqEdit.faqNo}`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    bodyParams: faqEdit,
  };
  const response: CommonResponse = await callApi(request);
  return response.successOrNot === 'Y' ? true : false;
};

// Faq 게시글 삭제(관리자)
export const deleteAdminFaqListApi = async (faqNo: number) => {
  const request: CommonRequest = {
    method: Method.DELETE,
    url: `/v1/bbs/faq?faqNo=${faqNo}`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
  };
  const response: CommonResponse = await callApi(request);
  return response.successOrNot === 'Y' ? true : false;
};
