import styled from '@emotion/styled';
import { BorderColor, BgColor, FontColor } from 'ui/theme/Color';

export const ViewTable = styled.table`
  width: 100%;
  min-width: 500px;
  border: 1px solid ${BorderColor.Primary};
  table-layout: fixed;
  line-height: 1.4;
  margin-bottom: 10px;

  th,
  td {
    height: 38px;
    padding: 3px 8px;
    vertical-align: middle;
    font-size: 13px;
    border-right: 1px solid ${BorderColor.Primary};
    border-bottom: 1px solid ${BorderColor.Primary};

    &.borderR0 {
      border-right: 0;
    }
  }

  th {
    text-align: left;
    font-weight: 500;
    background-color: ${BgColor.White100};
  }

  thead th,
  .alignC {
    text-align: center;
  }
  .alignR {
    text-align: right;
  }

  td {
    color: ${FontColor.Default};
  }

  &.width300 {
    width: 300px;
  }

  .heightAuto {
    height: auto;
  }

  & + & {
    margin-top: 20px;
  }

  & + .contentEnd {
    margin-top: 10px;
  }

  .padding0 {
    padding: 0;
  }

  .margin0 {
    margin: 0;
  }

  .marginT15 {
    margin-top: 15px;
  }
`;

export const DetailContents = styled.div`
  padding: 30px 0;
  .contractContent {
    height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
  }
`;
