/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import useAuthStore from 'stores/useAuthStore';
import New from 'components/asset/images/Penw.svg';
import GButtonLayout from 'components/molecule/GbuttonLayout';
import FaqContentAccordion from 'components/vars/faq/FaqContentAccordion';
import { GButton } from 'components/atom/button';
import { useNavigate } from 'react-router-dom';
import { getAdminFaqListApi } from 'apis/vars/faq/FaqAdminApi';
import { useEffect, useState } from 'react';
import { getUserFaqListApi } from 'apis/vars/faq/FaqUserApi';
import { useTranslation } from 'react-i18next';
import useSessionStore from 'stores/useSessionStore';

export interface FaqListRes {
  faqNo: number; // faq 번호(original)
  faqTitNm: string; // faq 제목
  faqCtn: string; // faq 내용
  atchFileGrId: string; // 첨부파일
  atchFileCnt: number;
  dataInsUserId: string; // 게시자 id
  atchFileExist: string; // 첨부파일 여부
}

const FaqListPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [faqListData, setFaqListData] = useState<FaqListRes[]>([]);
  const { accessToken } = useAuthStore();
  const { roleCodes } = useSessionStore();

  const handleChangePage = () => {
    navigate('/faq/regist');
  };

  const getFaqListData = () => {
    if (accessToken) {
      getUserFaqListApi().then((res) => {
        setFaqListData(res);
      });
    } else {
      getAdminFaqListApi().then((res) => {
        setFaqListData(res);
      });
    }
  };

  useEffect(() => {
    getFaqListData();
  }, []);

  return (
    <>
      <Accordions>
        {faqListData.map((item) => (
          <FaqContentAccordion
            key={item.faqNo}
            faqNo={item.faqNo}
            title={item.faqTitNm}
            contents={item.faqCtn}
            atchFile={item.atchFileGrId}
            getFaqListData={getFaqListData}
            atchFileCnt={item.atchFileCnt}
            atchFileExist={item.atchFileExist}
          />
        ))}
      </Accordions>
      {!roleCodes.every((o) => o == 'PTN') && (
        <>
          {!accessToken && (
            <GButtonLayout paddingTop="0px">
              <GButton
                chkImg={New}
                txt={t('com.label.new', '__신규')}
                sizes="large"
                btnstyled="contained"
                btnColor="primary"
                hasImg={true}
                hasTxt={true}
                onClick={handleChangePage}
              ></GButton>
            </GButtonLayout>
          )}
        </>
      )}
    </>
  );
};

const Accordions = styled.div`
  margin-bottom: 4px;
  border-bottom: 1px solid #dde0df;
`;

export default FaqListPage;
