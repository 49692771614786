import styled from '@emotion/styled';
import { GlobalTokenColor } from 'ui/theme/Color';
// import styled from 'styled-components';

export const PrivacyAgreeZhc = () => {
  return (
    <>
      <PrivacyDiv>
        <p>
          LG Energy Solution, Ltd.(以下简称“公司”、或“我们”)关注您的隐私。
          <br />
          本隐私政策将解释当您使用我们的“网站”服务时，(以下简称“服务”)，我们是如何使用从您那里收集的个人数据的。我们会根据相关数据保护法规处理您的个人数据，这对我们很重要。
        </p>
        <PrivacyTable>
          <thead>
            <tr>
              <th>类别</th>
              <th>用途</th>
              <th colSpan={2}>收集项目</th>
              <th>保留和使用期限</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>会员管理</td>
              <td>系统会员注册与注销</td>
              <th>所需信息</th>
              <td>
                密码、地址 (如果是公司,则为公司地址)。 公司所在国家。
                <br />
                <br />□ 业务负责人: 姓名,邮箱,公司名称
              </td>
              <td rowSpan={2} style={{ color: 'blue', fontWeight: 'bold' }}>
                持续至业务关系结束或提出特殊的删除请求
              </td>
            </tr>
            <tr>
              <td>个人供应商</td>
              <td>账户认证</td>
              <th>所需信息</th>
              <td>银行名称 账户名称 开户日期 账号</td>
            </tr>
          </tbody>
        </PrivacyTable>
      </PrivacyDiv>
    </>
  );
};
const PrivacyDiv = styled.div`
  box-sizing: border-box;
  // border: 1px solid #ebeeed;
  border: 1px solid ${GlobalTokenColor.ESGrey300};
  width: 100%;
  height: 270px;
  overflow-y: auto;
  color: #1f1f1f;
  font-size: 13px;
  line-height: 20px;
  padding: 15px;
`;

const PrivacyTable = styled.table`
  margin: 10px;

  th,
  td {
    padding: 8px;
    border: 1px solid #ebeeed;
  }

  th {
    background: #f1f4f3;
    font-weight: 600;
  }

  td {
    vertical-align: top;
  }
`;
export default PrivacyAgreeZhc;
