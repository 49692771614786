import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { callJwtApi } from 'utils/JwtApiUtil';

const PREFIX_URI = '/api/sec/account/history';

// 국내원화계좌 등록이력 조회
export const getDmstKrwAccnHistory = async () => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `${PREFIX_URI}/domestic-krw`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
  };
  const response: CommonResponse<any> = await callJwtApi(request);
  return response.successOrNot === 'Y' ? (response.data as any) : null;
};

// 국내외화계좌 등록이력 조회
export const getDmstFrgAccnHistory = async () => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `${PREFIX_URI}/domestic-foreign`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
  };
  const response: CommonResponse<any> = await callJwtApi(request);
  return response.successOrNot === 'Y' ? (response.data as any) : null;
};

// 해외원화계좌 등록이력 조회
export const getOvKrwAccnHistory = async () => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `${PREFIX_URI}/overseas-krw`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
  };
  const response: CommonResponse<any> = await callJwtApi(request);
  return response.successOrNot === 'Y' ? (response.data as any) : null;
};

// 해외외화계좌 등록이력 조회
export const getOvFrgAccnHistory = async () => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `${PREFIX_URI}/overseas-foreign`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
  };
  const response: CommonResponse<any> = await callJwtApi(request);
  return response.successOrNot === 'Y' ? (response.data as any) : null;
};
