import React, { useEffect, useState, useRef } from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { di } from 'components/layouts/Dialog';
import { SearchBox, SearchButtons, SearchRows } from 'components/layouts/SearchBox';
import { useTranslation } from 'react-i18next';
import { CustomInputText } from 'components/inputs/CustomInput';
import { ContentSection, GridTop } from 'components/layouts/ContentSection';
import { AgGridReact } from 'ag-grid-react';
import { getCommonCodesForPopup } from 'apis/admin/CommonCode';
import { GButton } from 'components/buttons/GButton';
import SearchIcon from '@mui/icons-material/Search';
import { CommonCode } from 'models/admin/CommonCode';
import { useColumnDefForCommonCodeList } from './ColumnDef';
import { GLabel, GPopupTitle, GTitleBox } from 'components/layouts/GLayoutBox';
import { GContentGrid } from 'components/layouts/GContentGrid';
import { useCommonModal } from 'hooks/useCommonModal';
import { BootstrapDialog, DialogTitleItem } from 'components/layouts/GModalPopup';
import { useInfiniteDataSource } from 'hooks/useInfiniteDataSource';
import { GridSizeChangedEvent } from 'ag-grid-community';
import { useMessageBar } from 'components/process/MessageBar';
import { GSearchContainer } from 'components/layouts/GSearchContainer';
import { useLoading } from 'components/process/Loading';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';

type SearchParam = {
  cmnGrCd?: string;
  cmnCd?: string;
  cmnCdNm?: string;
  optValCtn1?: string;
};

type Props = {
  param?: SearchParam;
  open: boolean;
  singleSelection?: boolean;
  close: () => void;
  ok: (cmnCd: CommonCode) => void;
};

const CommonCodeModal = ({ param, open, singleSelection, close, ok }: Props) => {
  const { t } = useTranslation();
  const { openLoading } = useLoading();
  const { openMessageBar } = useMessageBar();
  const { defaultColDef, cmnCdcolDefs } = useColumnDefForCommonCodeList();
  const [cmnCdList, setCmnCdList] = useState<any>([]);
  const gridApiRef = useRef<any>(null);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [searchParam, setSearchParam] = useState<SearchParam>({
    cmnGrCd: param?.cmnGrCd || '',
    cmnCd: param?.cmnCd || '',
    cmnCdNm: param?.cmnCdNm || '',
    optValCtn1: param?.optValCtn1 || '',
  });
  const { dataSource, pageSize } = useInfiniteDataSource(
    searchParam,
    getCommonCodesForPopup,
    setTotalCount
  );

  const handleClose = () => {
    close();
  };

  const handleSearch = async () => {
    gridApiRef?.current?.setDatasource(dataSource);
  };

  const handleResetSearchParams = () => {
    resetSearchParams(['coCd', 'bplcCd']);
  };

  const resetSearchParams = (listId: string[]) => {
    const newParams: SearchParam = { ...searchParam };
    listId.forEach((id) => (newParams[id] = ''));
    setSearchParam({ ...newParams });
  };

  const handleOnGridReady = (params) => {
    gridApiRef.current = params.api;
    gridApiRef.current.setDatasource(dataSource);
  };

  const handleChangeSearchParam = (e) => {
    setSearchParam({ ...searchParam, [e.target.id]: e.target.value });
  };

  const handleSave = () => {
    const rows = gridApiRef.current.getSelectedRows();

    if (rows.length > 0 || rows == null) {
      ok(rows);
      handleClose();
    } else {
      handleErrorMessage(t('msg.com.항목 선택이 필요합니다.', '__항목 선택이 필요합니다.'));
    }
  };

  const handleErrorMessage = (message: string) => {
    openMessageBar('error', message);
    openLoading(false);
  };

  const renderSearchBox = () => {
    const placeholderText = t('msg.com.입력하세요', '__입력하세요');

    return (
      <GSearchContainer onSearch={handleSearch} onResetSearchParams={handleResetSearchParams}>
        <tr>
          <th style={{ width: '5%' }}>
            <GLabel>{t('com.label.cdNm', '__코드명') || ''}</GLabel>
          </th>
          <td style={{ width: '15%' }}>
            <CustomInputText
              id="cmnCdNm"
              name="cmnCdNm"
              value={searchParam.cmnCdNm}
              onChange={handleChangeSearchParam}
              placeholder={placeholderText}
            />
          </td>
          <td style={{ width: '40%' }}></td>
        </tr>
      </GSearchContainer>
    );
  };

  const renderGrid = () => {
    return (
      <ContentSection style={{ width: '100%' }}>
        <GTitleBox>
          <span className="title"> {t('com.label.grCd', '__그룹코드')}</span>
          <span className="commentSub">{t('com.label.tot', '__총')}</span>
          <span className="comment">{totalCount}</span>
          <span className="commentSub">{t('com.label.case', '__건')}</span>
        </GTitleBox>
        <GContentGrid type="check" className="ag-theme-alpine" style={{ height: '372px' }}>
          <AgGridReact
            rowData={cmnCdList}
            defaultColDef={defaultColDef}
            columnDefs={cmnCdcolDefs}
            suppressRowClickSelection
            rowSelection={singleSelection ? 'single' : 'multiple'}
            rowModelType={'infinite'}
            cacheBlockSize={pageSize}
            cacheOverflowSize={2}
            maxConcurrentDatasourceRequests={1}
            infiniteInitialRowCount={1}
            maxBlocksInCache={2}
            onGridReady={handleOnGridReady}
          />
        </GContentGrid>
      </ContentSection>
    );
  };

  return (
    <BootstrapDialog
      // style={{ height: '1000px' }}
      open={open}
      onClose={handleClose}
      css={di.dialog}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitleItem id="customized-dialog-title" onClose={handleClose}>
        <GPopupTitle title1="공통코드 목록" />
      </DialogTitleItem>
      <div style={{ overflow: 'auto' }}>
        <DialogContent className="popupContent">
          {renderSearchBox()}
          {renderGrid()}
        </DialogContent>
        <DialogActions className="popupBottom">
          <GButton onClick={handleClose} color="secondary" variant="outlined" size="medium">
            <span>{t('btn.com.취소', '__취소')}</span>
          </GButton>
          <GButton onClick={handleSave} color="primary" variant="contained" size="medium">
            <p className="stroke"></p>
            <span>{t('btn.com.확인', '__확인')}</span>
          </GButton>
        </DialogActions>
      </div>
    </BootstrapDialog>
  );
};
export default CommonCodeModal;
