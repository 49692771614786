import styled from '@emotion/styled';

// checkbox
import UnChecked from 'components/asset/UnChecked.svg';
import Checked from 'components/asset/CheckedBox.svg';
import CheckedRow from 'components/asset/CheckedRow.svg';

// radio button
import RadioCheck from 'components/asset/RadioCheck.svg';
import RadioChecked from 'components/asset/RadioChecked.svg';
import RadioColumn from 'components/asset/RadioColumn.svg';

// sort icon
import DefaultSortIcon from 'components/asset/DefaultSortIcon.svg';
import DescSortIcon from 'components/asset/DescIcon.svg';
import AscSortIcon from 'components/asset/AscIcon.svg';

import Chevron2 from 'components/asset/Chevron2.svg';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { GlobalTokenColor, Color, FontColor, BgColor } from 'ui/theme/Color';
import { TextSize, TextLineHeight } from 'ui/theme/CustomTypography';

// empty images
import EmptyRow from 'components/asset/EmptyGrid.svg';

// check: check box
// radio: radio button
// none: none check
// white: 줄무늬 스타일이 아닌 그리드
interface GridProps {
  type?: 'check' | 'radio' | 'none' | 'white';
  rowHeight?: string | undefined; // row height 지정, 기본값 24px
  headerHeight?: string | undefined; // header height 지정, 기본값 24px
  gridStyle?: string; // white인 경우에만
  isPagination?: boolean;
  scrollHeight?: string; // 스크롤 길이가 다른 경우가 있어서 추가한 props
  isSortable?: boolean; // 정렬이 없는 그리드일 경우, 정렬 아이콘을 안 보이게 하기 위한 props
  isNotHeader?: boolean;
  isBorad?: boolean; // 게시판 그리드인지 아닌지 유무 : 다른 그리드랑 높이 다름
}

export const GContentGridQna = styled.div<GridProps>`
  width: 100%;

  /* 페이지내이션이 있을 경우 pagination까지 border로 감싸기 */
  ${(props) => !props.isPagination && 'border: 1px solid #dde0df;'}

  /* 기본으로 보이는 정렬 아이콘 */
  .ag-sort-indicator-container::before {
    background: ${(props) =>
      props.isSortable === undefined || props.isSortable === true
        ? `#f1f4f3 url(${DefaultSortIcon}) center center no-repeat`
        : 'none'};
  }

  --ag-header-background-color: ${({ theme }) =>
    theme.palette.mode == 'dark' ? GlobalTokenColor.ESGrey800 : GlobalTokenColor.ESGrey100};

  --ag-background-color: ${({ theme }) =>
    theme.palette.mode == 'dark' ? Color.DarkGrey100 : GlobalTokenColor.ESGreyWHite};

  --ag-odd-row-background-color: ${({ theme }) =>
    theme.palette.mode == 'dark' ? Color.DarkGrey400 : GlobalTokenColor.ESGrey50};

  --ag-data-color: ${({ theme }) =>
    theme.palette.mode == 'dark' ? GlobalTokenColor.ESGreyWHite : GlobalTokenColor.ESGreyBlack};

  --ag-borders: solid 1px;
  --ag-borders-critical: solid 1px;
  --ag-border-color: ${GlobalTokenColor.ESGrey300};
  --ag-font-size: 13px;
  --ag-font-family: 'Spoqa Han Sans Neo', '맑은 고딕', 'malgun gothic', 'Noto Sans CJK KR',
    'Apple SD', '산돌고딕 Neo', sans-serif;
  --ag-font-weight: 400;
  --ag-font-color: ${GlobalTokenColor.ESGrey900};
  --ag-header-height: ${(props) => (props.headerHeight ? `${props.headerHeight}px` : '24px')};
  --ag-row-height: ${(props) => (props.rowHeight ? `${props.rowHeight}px` : '24px')};
  --ag-row-border-width: 1px;
  --ag-row-border-color: ${GlobalTokenColor.ESGrey100};
  --ag-row-border-style: solid;
  --ag-row-hover-color: ${GlobalTokenColor.ESGrey100};
  /* --ag-row-hover-color: var(--ag-selected-row-background-color); */
  --ag-selected-row-background-color: ${GlobalTokenColor.Secondary50} !important;
  --ag-icon-size: 16px;
  --ag-input-focus-box-shadow: 0;
  --ag-input-focus-border-color: ${GlobalTokenColor.ESGrey300};
  --ag-checkbox-background-color: transparent;
  --ag-checkbox-checked-color: ${GlobalTokenColor.Secondary500};
  --ag-alpine-active-color: ${GlobalTokenColor.ESGrey700};
  --ag-checkbox-unchecked-color: ${GlobalTokenColor.ESGrey400};
  --ag-checkbox-unchecked-border-width: 1px;

  /* 그리드 데이터 유무에 따른 제어 */
  .ag-overlay .ag-react-container {
    width: 100%;
    height: 100%;
  }

  .ag-tooltip {
    background-color: #3c3e3d !important;
    color: #ffffff !important;
    font-size: 13px;
    font-weight: 400;
    padding: 4px 8px;
    border-radius: 2px;
  }

  .ag-overlay {
    position: ${(props) => (props.isNotHeader ? `absolute` : 'relative')};
  }

  .ag-overlay-no-rows-wrapper {
    padding-top: 0px !important;
  }

  .ag-center-cols-container {
    min-height: 1px;
  }

  .ag-layout-auto-height .ag-center-cols-viewport {
    min-height: 1px;
  }

  //ag-number-field-input 상태일 때 우측 정렬
  input[class^='ag-'][type='number']:not(.ag-number-field-input-stepper) {
    text-align: right;
  }

  /* 스크롤바 */
  /* -24만큼 땡겼기 때문에 height calc연산자로 땡긴만큼 길이 늘려줘야 합니다. */
  /* 스크롤 길이가 다른 경우가 있어서 props로 받게 했습니다. */
  /* 기본값 : -24px */
  /* height: calc(100% + top의 크기)) */
  .ag-body-vertical-scroll-viewport {
    width: 17px !important;
    max-width: 17px !important;
    min-width: 17px !important;
    position: absolute;
    top: ${(props) => (props.scrollHeight ? '-' + props.scrollHeight : '-24px')};
    left: 0px;
    height: calc(100% + ${(props) => props.scrollHeight || '24px'});
  }

  /* 하얀색 테이블일 경우 */
  .ag-row-odd {
    ${(props) =>
      props.gridStyle === 'white' && `background-color: ${GlobalTokenColor.ESGreyWHite}`};
  }

  .ag-selection-checkbox {
    right: 0;
  }

  .ag-checkbox-input-wrapper {
    flex: 1;
    margin-left: 4.5px;
  }

  /* 체크된 행이 있을 경우 check header 아이콘 모양 - */
  .ag-checkbox-input-wrapper.ag-indeterminate::after {
    ${(props) => props.type === 'check' && `content: url(${CheckedRow})`};
    ${(props) => props.type === 'radio' && `content: url(${RadioColumn})`};
    background-color: ${GlobalTokenColor.Secondary};
    border-radius: 2px;
    width: 16px;
  }

  .ag-header-row {
    .ag-checkbox-input-wrapper::after,
    .ag-checkbox-input-wrapper.ag-checked::after {
      top: 0;
    }
  }

  /* 체크 타입 */
  .ag-checkbox-input-wrapper::after {
    ${(props) => props.type === 'radio' && `content: url(${RadioCheck})`};
    ${(props) => props.type === 'check' && `content: url(${UnChecked})`};
    ${(props) => props.type === 'radio' && `top: -2px`};
    ${(props) => props.type === 'check' && `top: -2px`};
    left: 5px;
  }

  /* 체크 후 */
  .ag-checkbox-input-wrapper.ag-checked::after {
    ${(props) => props.type === 'radio' && `content: url(${RadioChecked})`};
    ${(props) => props.type === 'check' && `content: url(${Checked})`};
    border-radius: 50%;
    font-size: ${TextSize.H3};
    background-color: ${GlobalTokenColor.ESGreyWHite};
    left: 5px;
    top: -1px;
  }

  /* 첫행 체크타입 (첫행 border-top none 때문에 1px 올라가는 현상) */
  .ag-row:first-child {
    /* 체크 타입 */
    .ag-checkbox-input-wrapper::after {
      ${(props) => props.type === 'radio' && `top: -1px`};
      ${(props) => props.type === 'check' && `top: -1px`};
    }
  }

  /* ------------------------------ */

  /* ag-grid sort (정렬) 아이콘 */
  .ag-cell-label-container {
    position: relative;
    padding: 5px 6px;
  }

  /* 기본 정렬 */
  .ag-sort-indicator-container {
    position: absolute;
    right: 0;
    width: 14px;
    height: 14px;
  }

  /* 내림차순 */
  .ag-icon-asc::before {
    background: #f1f4f3 url(${AscSortIcon}) center center no-repeat;
  }
  /* 오름차순 */
  .ag-icon-desc::before {
    background: #f1f4f3 url(${DescSortIcon}) center center no-repeat;
  }
  /* 내림차, 오름차, 기본 정렬 아이콘 위치 공통화 */
  .ag-icon-asc::before,
  .ag-icon-desc::before,
  .ag-sort-indicator-container::before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
    width: 100%;
    height: 100%;
  }

  .ag-root {
    &:not(:has(.ag-body-vertical-scroll-container)) {
      .ag-header-cell:last-child,
      .ag-row .ag-cell:last-child {
        border-right: none;
      }
    }
  }

  .ag-header-cell-text {
    padding-right: ${(props) =>
      props.isSortable === true || props.isSortable === undefined ? '14px' : '0px'};
  }

  .ag-header-cell-comp-wrapper {
    justify-content: center;
    /* border-right: 1px solid ${GlobalTokenColor.ESGrey300}; */
  }

  .ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
    border: 1px solid ${GlobalTokenColor.Secondary300} !important;
  }

  .ag-cell-edit-wrapper {
    .ag-picker-field-wrapper {
      top: 1px;
      border: 1px solid ${GlobalTokenColor.ESGrey700} !important;
      border-radius: 0px !important;
    }
    .ag-input-field-input {
      border: 1px solid ${GlobalTokenColor.ESGrey700} !important;
      border-radius: 0px !important;
    }
  }

  // ----------------------------------

  .ag-root-wrapper.ag-layout-normal {
    border: none;
  }

  .ag-root-wrapper.ag-layout-auto-height {
    border: none;
  }

  --ag-header-column-separator-display: block;
  --ag-header-column-separator-height: 100%;
  --ag-header-column-separator-width: 1px;
  --ag-header-column-separator-color: ${GlobalTokenColor.ESGrey300};

  --ag-header-column-resize-handle-display: block;
  --ag-header-column-resize-handle-height: 25%;
  --ag-header-column-resize-handle-width: 1px;
  --ag-header-column-resize-handle-color: ${GlobalTokenColor.ESGrey300};

  /* .ag-header-cell-resize,
  .ag-header-cell-resize::after {
    display: none !important;
  } */

  .ag-ltr .ag-header-cell::after,
  .ag-ltr .ag-header-group-cell::after {
    display: none;
  }

  .ag-header-cell-resize::after {
    display: none;
    background-color: ${GlobalTokenColor.ESGrey300};
    width: 1px;
  }

  .ag-header-cell.ag-header-span-height::after,
  .ag-header-cell.ag-header-span-height .ag-header-cell-resize::after {
    height: 100% !important;
  }

  // .ag-header-group-cell {
  //   border-right: 1px solid ${GlobalTokenColor.ESGrey300};
  //   border-bottom: 1px solid ${GlobalTokenColor.ESGrey300};
  //   border: none;
  // }

  /* 퍼블에서는 체크/라디오 컬럼이 first-child로 나오는데
  개발에서는 마지막 자식으로 나옴 */
  /* .ag-header-container .ag-header-row-column:nth-child(2) {
    > div:last-child {
      border-right: 1px solid ${GlobalTokenColor.ESGrey300};
    }

    > div:nth-last-child(2) {
      border: none;
    }
  } */

  .ag-header-cell {
    border-right: 1px solid ${GlobalTokenColor.ESGrey300};
  }

  // ---------------------------------------

  & + div,
  & + form {
    // margin-top: 20px;
  }

  .ag-root-wrapper {
    border-top: 1px solid ${GlobalTokenColor.ESGrey300};
    border-bottom: 1px solid ${GlobalTokenColor.ESGrey300};
  }

  .ag-cell-wrapper {
    margin: 0 !important;
    padding: 0 !important;
    // height: fit-content; // 100% -> fit-content
    ${(props) => (props.isBorad ? '' : 'height: fit-content')};
  }
  .ag-cell-wrapper > *:not(.ag-cell-value):not(.ag-group-value) {
    margin-left: -3px;
  }
  .ag-header-row {
    background: ${({ theme }) =>
      theme.palette.mode == 'dark' ? GlobalTokenColor.ESGrey700 : GlobalTokenColor.ESGrey100};
    border: none;
  }
  .ag-header-group-cell-with-group {
    justify-content: center;
    font-size: 13px;
    font-weight: 400;
    border-right: 1px solid #dde0df;
  }

  .ag-header-cell {
    padding: 0 !important;
  }

  .ag-header-cell-label {
    justify-content: center;
  }

  .ag-header-group-text {
    font-weight: 700;
    color: #1f1f1f;
  }

  .ag-header-cell-text {
    font-weight: 700;
    color: #1f1f1f;
  }

  .ag-cell-value {
    padding: 0px !important;
    left: 0px;
    top: 0px;
    ${(props) => (props.isBorad ? '' : 'height: 100%')};
    line-height: ${(props) =>
      props.rowHeight ? `${parseInt(props.rowHeight, 10) - 4}px` : '1.4rem'}; // 1.6rem -> 1.4rem
  }

  .ag-cell {
    font-size: ${TextSize.Basic};
    height: 24px;
    font-weight: 400;
    color: #1f1f1f;
    padding: 1px 6px !important;

    &:has(.Mui-focused) {
      border: 1px solid #5b5c5d !important;
    }
  }

  .ag-header-cell-text {
    color: ${({ theme }) =>
      theme.palette.mode == 'dark' ? GlobalTokenColor.ESGrey100 : GlobalTokenColor.ESGrey900};
    font-size: ${TextSize.Basic};
  }

  .ag-cell.heightUnset .ag-cell-wrapper {
    height: fit-content;
  }

  .ag-cell-inline-editing {
    padding: 0 !important;
    border-radius: 2px;
    border: 0 !important;
    background-color: ${GlobalTokenColor.ESGreyWHite};
    font-size: ${TextSize.H3};
    // font-family: 'Spoqa Han Sans Neo';
    color: ${FontColor.Gray400};
    box-shadow: none;

    input {
      height: 25px;
    }
  }

  .checkList .ag-ltr .ag-cell {
    line-height: ${TextLineHeight.Large};
  }

  .ag-row {
    border-bottom: none;
  }

  .ag-row:first-child > div {
    border-top: none;
  }

  .ag-row:last-child > div {
    border-bottom: 0.5px solid ${GlobalTokenColor.ESGrey100};
  }

  .ag-row:not(:last-child) > div {
    border-bottom: none;
  }

  .ag-row:not(:last-child) > div,
  .show-cell:not([style*='height: 24px']) {
    border-bottom: 1px solid #f1f4f3;
  }

  .ag-cell {
    border-right: 1px solid ${GlobalTokenColor.ESGrey100};
    border-bottom: 1px solid ${GlobalTokenColor.ESGrey100};
    height: ${(props) => props.rowHeight + 'px' || '24px'};
  }

  .cell-span {
    display: flex;
    align-items: center;
    background-color: ${GlobalTokenColor.ESGreyWHite};
  }

  /* rowspan style */
  .ag-row-selected::before {
    background-color: var(--ag-selected-row-background-color) !important;
  }
  /* rowspan이 적용되지 않은 셀 transparent */
  .show-cell:not([style*='height: 24px']) {
    // background: transparent !important;
    // top: -1px;
    // border: 1px solid #dde0df;
  }
  /* 48px을 넘어가는 경우 */
  /* .ag-cell[style*="height: 48px"],
  .ag-cell[style*="height: 70px"] {
    border: 1px solid red !important;
  } */

  &.marginB20 {
    margin-bottom: 20px;
  }

  .ag-cell-inline-editing input {
    height: 24px !important;
    font-size: ${TextSize.Basic};
    color: ${GlobalTokenColor.ESGrey900};
    background: ${GlobalTokenColor.ESGreyWHite};
    border: 1px solid ${GlobalTokenColor.ESGrey700};
  }

  .ag-text-field-input {
    font-size: ${TextSize.Basic};
  }

  .ag-root-wrapper .btn.btnSecondaryOutlinedSmall {
    top: 0;
  }

  .btn.btnSecondaryOutlinedSmall {
    min-width: 0px;
    box-sizing: border-box;
    color: #5b5c5b;
    background-color: #ffffff;
    border: 1px solid #dde0df;
    font-size: 12px;
    padding: 4px 6px;
    margin-bottom: 3px;
    height: 20px !important;
    border-radius: 2px;
  }

  .ag-header-cell.fixed {
    .ag-sort-indicator-container {
      &::before {
        visibility: hidden;
      }
    }

    .ag-header-cell-text {
      padding-right: 0px;
    }
  }

  .ag-header-cell.required {
    .ag-header-cell-text {
      &::before {
        content: '';
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: ${GlobalTokenColor.StatusError500};
        position: absolute;
        left: 3px;
        top: 4px;
      }
    }
  }

  .ag-header-cell.fixed-required {
    .ag-sort-indicator-container {
      &::before {
        visibility: hidden;
      }
    }

    .ag-header-cell-text {
      padding-right: 0px;
    }

    .ag-header-cell-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: center;

      &::before {
        content: '';
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: ${GlobalTokenColor.StatusError500};
        position: absolute;
        left: 3px;
        top: 4px;
      }
    }
  }

  .ag-header-group-cell.required-group {
    &::before {
      content: '';
      display: inline-block;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: ${GlobalTokenColor.StatusError500};
      position: absolute;
      left: 3px;
      top: 4px;
    }

    .ag-header-cell-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: center;
    }
  }

  .ag-header-cell.required-center {
    .ag-header-cell-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: center;

      &::before {
        content: '';
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: ${GlobalTokenColor.StatusError500};
        position: absolute;
        left: 3px;
        top: 4px;
      }
      white-space: nowrap;
      text-align: center;
    }
  }

  .ag-header-cell.center {
    .ag-header-cell-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: center;
    }

    .ag-header-cell-text {
      padding-right: 24px;
    }
  }

  .ag-header-cell.center-fixed {
    .ag-header-cell-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: center;
    }
    .ag-sort-indicator-container {
      &::before {
        visibility: hidden;
      }
    }
    .ag-header-cell-text {
      padding-right: 0px;
      // white-space: pre-line;
      text-align: center;
    }
  }

  .ag-header-cell.border-top {
    border-top: 1px solid #dde0df;
  }

  .ag-popup > .ag-select-list {
    max-height: 120px !important;
  }

  .ag-cell-wrapper {
    .ag-icon {
      &::before {
        background: url(${Chevron2}) center center no-repeat;
        background-size: 5px 2.92px;
      }
    }
  }

  .ag-list-item.ag-active-item {
    background-color: ${BgColor.Secondary50};
  }
`;
