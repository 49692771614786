import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import { Box, useTheme } from '@mui/material';
import { ReactComponent as ClockIcon } from 'components/asset/images/Clock.svg';
import { ReactComponent as ErrorIcon } from 'components/asset/images/error.svg';
import { ReactComponent as WarningIcon } from 'components/asset/images/warning.svg';
import { ReactComponent as SuccessIcon } from 'components/asset/images/confirmed.svg';
import { ReactComponent as ChevronUpIcon } from 'components/asset/images/upList.svg';
import { ReactComponent as ChevronDownIcon } from 'components/asset/images/downList.svg';
import IconButton from '@mui/material/IconButton';
import dayjs from 'dayjs';
import { DEFAULT_TIME_INTERVAL } from './constants';
import { isFirstArrayElement, isLastArrayElement } from './utils';
import { GreyLineButton } from 'components/buttons/CustomButton';

interface Props {
  options?: string[];
  optionsToDisable?: string[];
  disabled?: boolean;
  readOnly?: boolean;
  error?: boolean;
  warning?: boolean;
  confirmed?: boolean;
  defaultValue?: string;
  timeInterval?: number;
  selectValue?: string;
  onInputChange?: (value: string) => void;
  helperText?: string;
  changeValueWhenDefaultValueIsChanged?: boolean;
  setMinutesToZero?: boolean;
  width?: string;
  height?: string;
  defaultStatus?: boolean;
}

enum TimeInputMouseActions {
  UP = 'UP',
  DOWN = 'DOWN',
}

export default function Time({
  options,
  optionsToDisable,
  disabled = false,
  readOnly = false,
  error = false,
  warning = false,
  confirmed = false,
  defaultValue = '',
  timeInterval = DEFAULT_TIME_INTERVAL,
  selectValue,
  onInputChange,
  helperText,
  changeValueWhenDefaultValueIsChanged = false,
  setMinutesToZero,
  width = '100%',
  height = '32px',
  defaultStatus = false,
}: Props) {
  const { palette, typography } = useTheme();
  const [value, setValue] = useState<string>(defaultValue);
  const [optionsValue, setOptionsValue] = useState<string[]>([]);

  // useEffect(() => {
  //   if (onInputChange) {
  //     onInputChange(value);
  //   }
  // }, [value]);

  useEffect(() => {
    if (selectValue !== undefined) {
      setValue(selectValue);
    }
  }, [selectValue]);

  useEffect(() => {
    if (!options) {
      setOptionsValue(generateTimeArray(timeInterval));
    } else {
      setOptionsValue(options);
    }
  }, [options]);

  const generateTimeArray = (timeInterval: number) => {
    const times: string[] = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += timeInterval) {
        const formattedHour = hour.toString().padStart(2, '0');
        const formattedMinute = minute.toString().padStart(2, '0');
        times.push(`${formattedHour}${formattedMinute}`);
      }
    }
    return times;
  };

  useEffect(() => {
    if (changeValueWhenDefaultValueIsChanged) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  const handleClickIcon = (action: TimeInputMouseActions) => {
    if (disabled || readOnly) return;
    const isUnableToMove =
      action === TimeInputMouseActions.UP
        ? isLastArrayElement(value, optionsValue)
        : isFirstArrayElement(value, optionsValue);
    const currentIndex = optionsValue.findIndex((option) => option === value);
    const getValue = isUnableToMove
      ? value
      : optionsValue[action === TimeInputMouseActions.UP ? currentIndex + 1 : currentIndex - 1];
    setValue(getValue);
  };

  const renderStatus = () => {
    if (error) {
      return {
        icon: <ErrorIcon fill={palette.error[400]} />,
        color: palette.error[400],
      };
    }
    if (warning) {
      return { icon: <WarningIcon />, color: palette.warning[500] as string };
    }
    if (confirmed) {
      return { icon: <SuccessIcon />, color: palette.success[500] as string };
    }
    return { icon: null, color: '' };
  };

  const handleKeyDown = (event) => {
    if (
      !(event.key >= 0 && event.key <= 9) &&
      event.key !== 'Enter' &&
      event.key !== 'Backspace' &&
      event.key !== 'F5'
    ) {
      event.preventDefault();
    }
  };
  return (
    <Container
      width={width}
      height={height}
      defaultStatus={defaultStatus}
      error={error}
      warning={warning}
      confirmed={confirmed}
    >
      <Autocomplete
        autoSelect
        autoHighlight
        sx={{
          '& .MuiAutocomplete-clearIndicator': {
            display: 'none',
          },
          '& .MuiInputAdornment-root': {
            left: 'auto',
            marginRight: '0',
          },
          '& .MuiInputAdornment-positionStart': {
            left: '8px',
          },
          '& .MuiInputAdornment-positionEnd': {
            right: '6px',
          },
          '& .MuiAutocomplete-popupIndicator': {
            pointerEvents: 'none',
            userSelect: 'none',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: renderStatus().color,
          },
        }}
        freeSolo={false}
        disableClearable={true}
        options={optionsValue}
        getOptionDisabled={(option) => {
          if (optionsToDisable) {
            return optionsToDisable.includes(option.replace(':', ''));
          }
          return false;
        }}
        disabled={disabled}
        readOnly={readOnly}
        value={value}
        onChange={(e, newValue) => {
          if (setMinutesToZero) {
            const hour = newValue.substring(0, 2) + '00';
            setValue(hour);
            onInputChange && onInputChange(hour);
          } else {
            setValue(newValue);
            onInputChange && onInputChange(newValue);
          }
        }}
        getOptionLabel={(option) => {
          return option ? dayjs(`19700101${option}`).format('HH:mm') : option;
        }}
        renderInput={(params) => (
          <TextField
            helperText={helperText}
            placeholder="HH:MM"
            sx={{
              '& .MuiInputBase-root': {
                backgroundColor: disabled || readOnly ? palette.grey[100] : '',
              },
              '& .MuiInputBase-input': {
                paddingLeft: '0 !important',
                '&::placeholder': {
                  color: '#979998',
                  opacity: 1,
                  fontSize: '13px',
                  fontWeight: '300',
                },
              },
              '& .MuiFormHelperText-root': {
                padding: 0,
                margin: 0,
                width: '120px',
                color: error ? palette.error[400] : palette.grey[500],
              },
            }}
            {...params}
            onKeyDown={handleKeyDown}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <ClockIcon />
                </InputAdornment>
              ),
              endAdornment: <InputAdornment position="end">{renderStatus().icon}</InputAdornment>,
            }}
          />
        )}
      />
    </Container>
  );
}

const Container = styled.div<Props>`
  width: ${(props) => props.width};

  .MuiAutocomplete-root {
    width: 120px;
  }

  .MuiAutocomplete-root .MuiOutlinedInput-notchedOutline {
    height: 32px;
    border-radius: 2px;
  }

  .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 0 4px 1px 5px;
  }

  /* HH:MM 상하 패딩 삭제 */
  .MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 0 9px 0 9px;
  }

  /* up down 버튼 밑으로 내려가는 문제 */
  .MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    min-width: 0;
  }

  /* up down 버튼 */
  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-root .MuiOutlinedInput-root,
  .MuiAutocomplete-hasClearIcon.MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 0 8px;
    display: flex;
    gap: 4px;
  }

  /* border색 바꾸기 */
  .MuiOutlinedInput-notchedOutline {
    top: 0;
    width: 100%;
    border-radius: 2px;
    ${(props) => (props.defaultStatus ? 'border: 1px solid #b9bcbb;' : '')}
    ${(props) => (props.error ? 'border: 1px solid #FDA293;' : '')}
    ${(props) => (props.warning ? 'border: 1px solid #FFCB7A;' : '')}
    ${(props) => (props.confirmed ? 'border: 1px solid #56D8AA;' : '')}
  }

  .MuiOutlinedInput-root:not(.Mui-focused):hover .MuiOutlinedInput-notchedOutline {
    border-color: #b9bcbb !important;
  }

  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid #5b5c5b !important;
  }

  .MuiOutlinedInput-notchedOutline {
    top: 0;
    height: 32px;
  }

  .MuiOutlinedInput-root {
    height: ${(props) => props.height};
  }
`;
