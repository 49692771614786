import { useEffect, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { GContentGrid } from 'components/layouts/GContentGrid';
import { useColumnDefsForApprovalList } from './ColumnDefs';
import { useLoading } from 'components/process/Loading';
import { useMessageBar } from 'components/process/MessageBar';
import { SampleResponse } from 'models/sample/Sample';
import { getSamples } from 'apis/sample/SampleApi';
import SubTitleBox from 'components/molecule/SubTitleBox';
import { SubTitleBtnArea } from 'components/organism/SubTitleBtnArea';
import { TitleBtnBox } from 'components/molecule/ButtonArea';
import { GButton } from 'components/atom/button';
import GButtonLayout from 'components/molecule/GbuttonLayout';
import SearchBox from 'components/organism/SearchBox';

import { useNavigate } from 'react-router-dom';
import useAuthStore from 'stores/useAuthStore';

type SearchParams = {
  coCd: string;
  onduRegnCd?: string;
  jtiCd?: string;
  aprReqProgStatCd?: string;
  aprReqDtmSt?: string;
  aprReqDtmEd?: string;
  aprReqUserId?: string;
  aprReqUserNm?: string;
  stmtRegStat?: string;
  eaprDocNo?: string;
  aprReqTitNm?: string;
};

const SampleType1 = () => {
  // get grid data, defaultColDef
  const [sampleList, setSampleList] = useState<SampleResponse[]>([]);
  const gridApiRef = useRef<any>(null);
  const { defaultColDef, colDefs } = useColumnDefsForApprovalList();
  const { openLoading } = useLoading();
  const { openMessageBar } = useMessageBar();

  const [searchParams, setSearchParams] = useState<SearchParams>({
    coCd: '',
    onduRegnCd: '',
    jtiCd: '',
    aprReqProgStatCd: '',
    aprReqUserId: '',
    aprReqTitNm: '',
    eaprDocNo: '',
    stmtRegStat: '',
  });

  useEffect(() => {
    loadSampleList();
  }, []);

  const makeSearchParams = () => {
    return {
      ...searchParams,
      start: '0',
      pageSize: 30,
    };
  };

  const handleError = (e: Error) => {
    openLoading(false);
    openMessageBar('error', e.message);
  };

  const loadSampleList = async () => {
    getSamples(makeSearchParams())
      .then((data) => {
        setSampleList(data.list);
      })
      .catch(handleError);
  };

  const { accessToken } = useAuthStore();
  const navigate = useNavigate();

  const handleModalBtnClick = () => {
    navigate('/sample/modal', { replace: true });
  };

  const handleBtnClick = () => {
    navigate('/sample/type90', { replace: true });
  };
  const handleBtnClick2 = () => {
    navigate('/publ/domestic', { replace: true });
  };
  const handleBtnClick3 = () => {
    navigate('/publ/overseas', { replace: true });
  };
  const handleBtnClick4 = () => {
    navigate('/publ/mypage', { replace: true });
  };
  const handleBtnClick5 = () => {
    navigate('/publ/memberdetail', { replace: true });
  };
  const handleBtnClick6 = () => {
    navigate('/publ/memberlist', { replace: true });
  };
  const handleBtnClick7 = () => {
    navigate('/publ/signup', { replace: true });
  };
  const handleBtnClick8 = () => {
    navigate('/publ/qnadetail', { replace: true });
  };
  const handleBtnClick9 = () => {
    navigate('/publ/qnawrite', { replace: true });
  };
  const handleBtnClick10 = () => {
    navigate('/publ/qnalist', { replace: true });
  };
  const handleBtnClick11 = () => {
    navigate('/publ/abnormal', { replace: true });
  };
  const handleBtnClick12 = () => {
    navigate('/publ/login', { replace: true });
  };
  const handleBtnClick13 = () => {
    navigate('/publ/faqlist', { replace: true });
  };
  const handleBtnClick14 = () => {
    navigate('/publ/faqwrite', { replace: true });
  };
  const handleBtnClick15 = () => {
    navigate('/publ/payment', { replace: true });
  };
  const handleBtnClick16 = () => {
    navigate('/print-sample', { replace: true });
  };

  return (
    <>
      {/* SearchBox / RegistBox 에 따라서 직접 organism폴더의 파일에 가서 복붙 해오기 */}
      <SearchBox />

      {/* 서브 타이틀 */}
      <SubTitleBtnArea>
        <SubTitleBox
          content="서브 타이틀"
          isVisibleComment={false}
          isVisibleInfo={false}
          isVisbleSum={true}
          showSubTitle={true}
          commentCount={2}
        />
        <TitleBtnBox>
          <GButton
            txt="다운로드"
            btnstyled="outline"
            btnColor="normal"
            sizes="small"
            hasTxt={true}
          />
        </TitleBtnBox>
      </SubTitleBtnArea>

      {/* 그리드 - fix */}
      <GContentGrid type="none" className="ag-theme-alpine" style={{ height: '400px' }}>
        <AgGridReact
          defaultColDef={defaultColDef}
          columnDefs={colDefs}
          rowData={sampleList}
          rowSelection={'single'}
          //suppressRowClickSelection
          //suppressMovableColumns
          enableCellTextSelection
          onGridReady={(params) => (gridApiRef.current = params.api)}
          tooltipShowDelay={0}
          //tooltipHideDelay={2000}
        ></AgGridReact>
      </GContentGrid>

      {/* 버튼 (버튼이 여러개라면 <GButtonBox>를 감싸기, 가이드 참고하여 진행할 것!! ) */}
      <GButtonLayout marginBottom="8px">
        <GButton
          txt="신규"
          sizes="medium"
          btnstyled="outline"
          btnColor="normal"
          hasImg={false}
          hasTxt={true}
        ></GButton>
      </GButtonLayout>

      <div>{accessToken}</div>
      <br />
      <button onClick={handleModalBtnClick}>modal</button>
      <br />
      <button onClick={handleBtnClick}>test90</button>
      <br />
      <button onClick={handleBtnClick2}>Domestic</button>
      <br />
      <button onClick={handleBtnClick3}>Overseas</button>
      <br />
      <button onClick={handleBtnClick4}>mypage</button>
      <br />
      <button onClick={handleBtnClick5}>memberdetail</button>
      <br />
      <button onClick={handleBtnClick6}>memberlist</button>
      <br />
      <button onClick={handleBtnClick7}>signup</button>
      <br />
      <button onClick={handleBtnClick8}>qnadetail</button>
      <br />
      <button onClick={handleBtnClick9}>qnawrite</button>
      <br />
      <button onClick={handleBtnClick10}>qnalist</button>
      <br />
      <button onClick={handleBtnClick11}>annormal</button>
      <br />
      <button onClick={handleBtnClick12}>login</button>
      <br />
      <button onClick={handleBtnClick13}>faq</button>
      <br />
      <button onClick={handleBtnClick14}>faqwrite</button>
      <br />
      <button onClick={handleBtnClick15}>payment</button>
      <br />
      <button onClick={handleBtnClick16}>print_test</button>
      <br />
    </>
  );
};

export default SampleType1;
