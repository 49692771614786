import moment from 'moment-timezone';

export const replaceWithTimezone = (strDate: any, format) => {
  format = format ?? 'YYYY-MM-DD HH:mm:ss';
  const utcTime = moment.utc(strDate, 'YYYYMMDDHHmmss').local();
  const result = utcTime.format(format);
  return result;
};

export const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;
