import React, { useState, useEffect, useContext } from 'react';
import styled from '@emotion/styled';
import BookMarkOFF from 'components/asset/images/Bookmark.svg';
import BookMarkOn from 'components/asset/images/BookmarkOn.svg';
import BookMarkOnBlack from 'components/asset/images/BlackBookmark.svg';
import useSessionStore from 'stores/useSessionStore';
import { MenuContext } from 'App';
import { MenuContextType } from 'models/admin/Menu';
import { registBookMarkMenu, deleteBookMarkMenu, getBookMarkMenu } from 'apis/cims/common/Menu';

interface Props {
  defaultState?: boolean;
  size?: string;
  isPadding?: boolean;
  isOnImgBlack?: boolean;
  bmkId?: number;
  mnuId?: string;
}

const ButtonContainer = styled.button<{ isPadding: boolean }>`
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding : ${(props) => (props.isPadding ? '2px;' : '0;')}
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img<{ size: string }>`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
`;

const BookMark = ({
  defaultState = false,
  size = '20px',
  isPadding = true,
  isOnImgBlack = false,
  bmkId,
  mnuId,
}: Props) => {
  const bookmarkOff = `${BookMarkOFF}`;
  const bookmarkOn = isOnImgBlack ? `${BookMarkOnBlack}` : `${BookMarkOn}`;
  const menuContext = useContext<MenuContextType>(MenuContext);

  const { bmkMenus, menus, setBmkMenus } = useSessionStore();

  // const [currentImage, setCurrentImage] = useState(bookmarkOff);
  const [currentImage, setCurrentImage] = useState(defaultState ? bookmarkOn : bookmarkOff);
  useEffect(() => {
    if (mnuId) {
      if (bmkMenus?.filter((item) => item.mnuId === mnuId).length > 0) {
        setCurrentImage(bookmarkOn);
      } else {
        setCurrentImage(bookmarkOff);
      }
    } else {
      const currentMenuId = menuContext.currentMenu.mnuId;
      const isBmk = bmkMenus?.filter((item) => item.mnuId === currentMenuId).length > 0;

      if (isBmk) {
        setCurrentImage(bookmarkOn);
      } else {
        setCurrentImage(bookmarkOff);
      }
    }
    if (isOnImgBlack) {
      setCurrentImage(bookmarkOn);
    }
  }, [bmkMenus, menuContext.currentMenu.mnuId]);

  const handleClick = () => {
    if (currentImage === bookmarkOff) {
      const addBmkId = mnuId ? mnuId : menuContext.currentMenu.mnuId;

      registBookMarkMenu({ mnuId: addBmkId }).then((res) => {
        if (res.statusCode === 'SUCCESS') {
          getBookMarkMenu().then((res) => {
            setBmkMenus(res);
            changeBookMark();
          });
        }
      });
    } else {
      const delBmkId =
        isOnImgBlack || mnuId
          ? bmkId
          : bmkMenus.find((item) => item.mnuId === menuContext.currentMenu.mnuId)?.bmkId;

      deleteBookMarkMenu(delBmkId).then((res) => {
        if (res.statusCode === 'SUCCESS') {
          getBookMarkMenu().then((res) => {
            setBmkMenus(res);
            changeBookMark();
          });
        }
      });
    }
  };

  const changeBookMark = () => {
    // 이미지가 클릭될 때마다 변경
    setCurrentImage((prevImage) => (prevImage === bookmarkOff ? bookmarkOn : bookmarkOff));
  };

  return (
    <ButtonContainer isPadding={isPadding} onClick={handleClick}>
      <Image size={size} src={currentImage} alt="Button Image" />
    </ButtonContainer>
  );
};

export default BookMark;
