import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { FaqListRes } from 'pages/vars/faq/FaqListPage';
import { callJwtApi } from 'utils/JwtApiUtil';

// Faq 리스트 불러오기(USER)
export const getUserFaqListApi = async () => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/api/sec/bbs/faqs`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
  };
  const response: CommonResponse<FaqListRes[]> = await callJwtApi(request);
  console.log(response);
  return (response.successOrNot === 'Y' ? response.data : []) as FaqListRes[];
};
