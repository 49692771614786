/** @jsxImportSource @emotion/react */
import { useCallback, useEffect } from 'react';
import styled from '@emotion/styled';

import { GlobalTokenColor, FontColor } from 'ui/theme/Color';

import 'components/modals/common/CommonModal.css';
import { Undefinedable } from 'models/common/FalsyGeneric';
import { useCommonModal } from 'hooks/useCommonModal';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { css } from '@emotion/react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
} from '@mui/material';

import { GButton } from 'components/atom/button';
import { DialogTitleItem } from 'components/layouts/GModalPopup';
import { GButtonBox, GPopupTitle } from 'components/layouts/GLayoutBox';
import ModalLayout from 'components/layouts/ModalLayout';

export const UnfilledButton = styled(GButton)`
  padding: 10px;
  height: 36px;
  min-width: 56px;
  margin: 0 3px;
  color: ${GlobalTokenColor.Primary};
  background-color: ${GlobalTokenColor.ESGreyWHite};
  font-size: 13px;
  line-height: 15px;
  border: 0;
  border-radius: 3px;

  &:disabled {
    opacity: 0.5;
  }
`;

export const UnfilledPriButton = styled(GButton)`
  padding: 10px;
  height: 36px;
  min-width: 56px;
  margin: 0 3px;
  font-size: 13px;
  line-height: 15px;
  border: 0;
  border-radius: 3px;
  background-color: ${GlobalTokenColor.ESGreyWHite};

  &:disabled {
    opacity: 0.5;
  }
`;

export const CommonModal = () => {
  const { t } = useTranslation();
  const {
    commonModalState: {
      modalType,
      animation,
      isOpen,
      title,
      content,
      yesCallback,
      noCallback,
      showCallbackResult,
    },
    openCommonModal,
    closeCommonModal,
  } = useCommonModal();

  useEffect(() => {
    if (isOpen) {
      document.body.style.top = `-${window.pageYOffset}px`;
      // common modal 발생 시 최상단으로 스크롤 업됨 우선 주석처리.
      // document.body.style.position = 'fixed';
      document.body.style.overflow = 'hidden';
      document.body.style.width = '100%';
    }
    return () => {
      const heightScroll = parseInt(document.body.style.top || '0', 10) * -1;
      document.body.style.removeProperty('position');
      document.body.style.removeProperty('overflow');
      document.body.style.removeProperty('width');
      document.body.style.removeProperty('top');
      // common modal 닫을 시 최상단으로 스크롤 업됨 우선 주석처리.
      // window.scrollTo(0, heightScroll);
    };
  }, [isOpen]);

  const handleCallbackResult = useCallback(
    async (callback: Undefinedable<() => any>, showCallbackResult = false) => {
      setTimeout(async () => {
        if (callback) {
          const callbackResult = await callback();
          if (showCallbackResult) {
            openCommonModal({
              content: callbackResult,
            });
          }
        }
      }, 300);
    },
    [openCommonModal]
  );

  const handleNoButtonClick = useCallback(async () => {
    closeCommonModal();
    await handleCallbackResult(noCallback, showCallbackResult);
  }, [closeCommonModal, noCallback, showCallbackResult, handleCallbackResult]);

  const handleYesButtonClick = useCallback(async () => {
    closeCommonModal();
    await handleCallbackResult(yesCallback, showCallbackResult);
  }, [closeCommonModal, yesCallback, showCallbackResult, handleCallbackResult]);

  return (
    <>
      {isOpen && t && (
        <ModalLayout open={isOpen} onClose={handleNoButtonClick} mMinWidth={400} mMaxWidth={600}>
          <DialogTitleItem id="customized-dialog-title" onClose={handleNoButtonClick}>
            <GPopupTitle
              title2={
                title
                  ? title
                  : modalType === 'alert'
                  ? t(`com.label.ntc`, `알림`)
                  : t(`com.label.chk`, `확인`)
              }
            />
          </DialogTitleItem>
          <DialogContent className="popupContent">{content}</DialogContent>
          <DialogActions className="popupBottom" style={{ marginTop: '8px' }}>
            <GButtonBox>
              {modalType === 'confirm' && (
                <GButton
                  onClick={handleNoButtonClick}
                  sizes="large"
                  btnColor="normal"
                  btnstyled="unfilled"
                  hasImg={false}
                  hasTxt={true}
                  txt={t('com.btn.cancel', '__취소') as string}
                />
              )}
              {(modalType === 'confirm' || modalType === 'alert' || !modalType) && (
                <GButton
                  onClick={handleYesButtonClick}
                  sizes="popup"
                  btnstyled="unfilled"
                  btnColor="primary"
                  hasImg={false}
                  hasTxt={true}
                  txt={t('com.btn.ok', '__확인') as string}
                />
              )}
              {modalType === 'yesno' && (
                <>
                  <GButton onClick={handleYesButtonClick}>{t('com.btn.yes', '__예')}</GButton>
                  <GButton onClick={handleNoButtonClick}>`{t('com.btn.no', '__아니오')}`</GButton>
                </>
              )}
            </GButtonBox>
          </DialogActions>
        </ModalLayout>
      )}
    </>
  );
};

const DialogItem = styled(Dialog)`
  .MuiDialog-paper {
    min-width: 400px;
    box-sizing: content-box;
    box-shadow: none;
    border-radius: 3px;
    color: ${FontColor.Default};
    word-break: keep-all;
  }

  .popupTitle {
    font-family: 'Spoqa Han Sans Neo';
    position: relative;
    padding: 0;
    font-size: 18px;
    font-weight: bold;
    color: ${FontColor.Primary700};
    &:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      margin-right: 8px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: ${FontColor.Primary};
    }
  }
  .popupContent {
    font-family: 'Spoqa Han Sans Neo' !important;
    padding: 0 24px 8px 24px;
    font-size: 13px;
    line-height: 19px;

    .searchBox {
      margin-bottom: 40px;
    }
  }

  .MuiDialogTitle-root + .MuiDialogContent-root {
    padding-top: 20px;
  }

  .buttonClose {
    position: absolute;
    top: 5px;
    right: 0;
    width: 22px;
    height: 22px;
    min-width: 20px;
    svg {
      fill: ${FontColor.Gray400};
      font-size: 1.5rem;
    }
  }

  .buttonsTop {
    text-align: right;
    & + .section {
      margin-top: 20px;
    }
  }

  .popupBottom {
    padding: 12px 24px 24px;
  }
`;
