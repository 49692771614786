/** @jsxImportSource @emotion/react */
import { GreyLineButton } from 'components/buttons/CustomButton';
import { ContentSection } from 'components/layouts/ContentSection';
import { css } from '@emotion/react';

export const UnderConstructionPage = () => {
  return (
    <div css={st.error}>
      <img src="/assets/under-construction.jpg" />
    </div>
  );
};

const st = {
  error: css`
    height: 100vh;
    background-color: white;
    position: absolute;
    z-index: 9999999999;
    top: -52px;
    width: 100%;
    left: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
};

export default UnderConstructionPage;
