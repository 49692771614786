import styled from '@emotion/styled';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { BgColor, BorderColor, FontColor, BasicColor, GlobalTokenColor } from 'ui/theme/Color';

export const ContentGrid = styled.div`
  width: 100%;
  position: relative;
  --ag-header-background-color: ${GlobalTokenColor.ESGrey100};
  --ag-borders: solid 1px;
  --ag-borders-critical: solid 1px;
  --ag-border-color: ${BorderColor.Primary};
  --ag-font-size: 13px;
  --ag-font-family: 'Spoqa Han Sans Neo', '맑은 고딕', 'malgun gothic', 'AppleGothicNeoSD',
    'Apple SD 산돌고딕 Neo', 'Microsoft NeoGothic', 'Droid sans', sans-serif;
  --ag-header-height: 40px;
  --ag-row-height: 40px;

  --ag-row-border-width: 1px;
  --ag-row-border-color: ${BorderColor.Primary};
  --ag-row-border-style: solid;
  --ag-row-hover-color: ${BgColor.White50};
  --ag-selected-row-background-color: ${BgColor.White50};
  --ag-icon-size: 16px;
  --ag-input-focus-box-shadow: 0;
  --ag-input-focus-border-color: ${BorderColor.Primary};
  --ag-checkbox-background-color: transparent;
  --ag-checkbox-checked-color: ${BasicColor.Secondary};
  --ag-range-selection-border-color: ${BasicColor.Primary};

  & + div,
  & + form,
  & + input {
    margin-top: 10px;
  }

  &.pagination {
    .ag-root-wrapper-body {
      height: auto;
    }
    .ag-overlay {
      height: 140%;
    }
    .ag-body {
      min-height: 50px;
    }
  }
  &.min150 {
    .ag-body {
      min-height: 107px;
    }
    .ag-overlay {
      height: 140%;
    }
  }

  .ag-header-cell {
    border-right: 1px solid ${GlobalTokenColor.ESGrey300};
  }

  .ag-header-cell:last-child {
    border-right: 0px !important;
  }

  .ag-header-cell-label {
    justify-content: center;
  }

  .ag-header-group-cell {
    border-right: 1px solid ${BorderColor.Primary};
  }

  .ag-header-group-cell-label {
    justify-content: center;
  }

  .ag-header-cell-text {
    font-weight: 400;
    white-space: pre-wrap;
    text-align: center;
  }

  .ag-cell {
    line-height: 38px;
    font-size: 13px !important;
  }

  .ag-ltr .ag-header-viewport .ag-header-cell-resize::after {
    background-color: transparent;
  }
  .ag-cell-inline-editing {
    border-radius: 3px;
    border: 0 !important;
    background-color: ${BgColor.White};
    font-size: 16px;
    font-family: 'Spoqa Han Sans Neo';
    color: ${FontColor.Gray400};
    box-shadow: none;

    input {
      height: 38px;
    }
  }

  .checkList .ag-ltr .ag-cell {
    line-height: 1.7;
  }

  .cell-span {
    display: flex;
    align-items: center;
    background-color: ${BgColor.White};
    border-right: solid 0.5px ${BorderColor.Primary};
    border-left: solid 0.5px ${BorderColor.Primary};
    border-bottom: solid 0.5px ${BorderColor.Primary};
  }

  &.marginB20 {
    margin-bottom: 20px;
  }
`;
