import React, { ReactNode, useMemo, useState } from 'react';
import { GButtonBox, GDivider, GSearchBox, GSearchTable } from 'components/layouts/GLayoutBox';
import { GIconButton } from 'components/buttons/GIconButton';
import { GButton } from 'components/buttons/GButton';
import { useTranslation } from 'react-i18next';
import { FormControlProvider } from 'components/form/FormControlProvider';

export type GSearchContainerProps = {
  children: ReactNode[] | ReactNode;
  onSearch?: () => void;
  onResetSearchParams?: () => void;
  customButtonRenderer?: () => any;
  onInitialized?: () => void;
};

type SearchToggleState = 'searchClose' | 'searchOpen';
const MIN_WIDTH = 1000;

export const GSearchContainer = ({
  children,
  onSearch,
  onResetSearchParams,
  customButtonRenderer,
  onInitialized,
}: GSearchContainerProps) => {
  const { t } = useTranslation();
  const [toggleState, setToggleState] = useState<SearchToggleState>('searchClose');

  const childrenNodes = useMemo(
    () => (Array.isArray(children) ? children : [children]),
    [children]
  );

  const rowClassName = useMemo(() => {
    let className: string;
    switch (childrenNodes.length) {
      case 1:
        className = 'oneRow';
        break;
      case 2:
        className = 'twoRow';
        break;
      default:
        className = 'multiRow';
    }
    return className;
  }, [childrenNodes]);

  const handleSearch = () => {
    onSearch?.();
  };

  const handleResetSearchParams = () => {
    onResetSearchParams?.();
  };

  const handleToggle = () => {
    setToggleState(toggleState === 'searchClose' ? 'searchOpen' : 'searchClose');
  };

  const renderSearchTable = () => {
    const rows1 = childrenNodes.length > 2 ? [childrenNodes[0], childrenNodes[1]] : childrenNodes;
    const rows2 = childrenNodes.length > 2 ? childrenNodes.slice(2) : [];
    return (
      <>
        <GSearchTable style={{ minWidth: `${MIN_WIDTH}px` }}>
          <tbody>{rows1}</tbody>
        </GSearchTable>
        <GSearchTable style={{ minWidth: `${MIN_WIDTH}px` }}>
          <tbody>{rows2}</tbody>
        </GSearchTable>
      </>
    );
  };

  const renderDivider = () => {
    return <>{childrenNodes.length > 2 && <GDivider />}</>;
  };

  const renderSearchButtons = () => {
    return (
      <GButtonBox className="rightBottom">
        {childrenNodes.length > 1 && (
          <GIconButton
            color="secondary"
            variant="outlined"
            size="medium"
            onClick={handleResetSearchParams}
          >
            <p className="refresh"></p>
          </GIconButton>
        )}

        {customButtonRenderer ? (
          customButtonRenderer()
        ) : (
          <GButton color="secondary" variant="contained" size="medium" onClick={handleSearch}>
            <p className="search"></p>
            <span>{t('com.label.00698', '__조회')}</span>
          </GButton>
        )}

        {childrenNodes.length > 2 && (
          <GIconButton color="secondary" variant="contained" size="medium" onClick={handleToggle}>
            <p className="down"></p>
          </GIconButton>
        )}
      </GButtonBox>
    );
  };

  return (
    <>
      <GSearchBox
        className={`${rowClassName} ${childrenNodes.length > 2 ? toggleState : undefined}`}
      >
        <FormControlProvider onInitialized={onInitialized}>
          {renderSearchTable()}
        </FormControlProvider>
        {renderDivider()}
        {renderSearchButtons()}
      </GSearchBox>
    </>
  );
};
