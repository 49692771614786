export enum GlobalGap {
  Px2 = '2px',
  Px4 = '4px',
  Px8 = '8px',
  Px12 = '12px',
  Px16 = '16px',
  Px20 = '20px',
  Px24 = '24px',
  Px28 = '28px',
  Px32 = '32px',
  Px36 = '36px',
  Px40 = '40px',
  Px44 = '44px',
  Px48 = '48px',
  Px52 = '52px',
  Px56 = '56px',
}
