import { IconButton } from '@mui/material';
import React, { DetailedHTMLProps, InputHTMLAttributes, MouseEvent } from 'react';
import { CustomInputWrap, CustomInputText } from './CustomInput';

interface PropsType extends InputHTMLAttributes<HTMLInputElement> {
  searchButton?: boolean;
  deleteButton?: boolean;
  searchButtonDisabled?: boolean;
  deleteButtonDisabled?: boolean;
  onSearchClick?: () => void;
  onDeleteClick?: () => void;
}
const CustomInputWithSearch = (props: PropsType) => {
  const {
    searchButton = true,
    deleteButton = true,
    onSearchClick,
    onDeleteClick,
    searchButtonDisabled = false,
    deleteButtonDisabled = false,
    ...inputProps
  } = props;
  return (
    <CustomInputWrap className={inputProps.className ?? 'fullWidth'}>
      {searchButton && (
        <IconButton className="buttonFind" onClick={onSearchClick} disabled={searchButtonDisabled}>
          <img src="/assets/icon/ic-search-ipt.svg" alt="검색" />
        </IconButton>
      )}
      <CustomInputText {...inputProps} />
      {deleteButton && (
        <IconButton className="buttonDel" onClick={onDeleteClick} disabled={deleteButtonDisabled}>
          <img src="/assets/icon/ic-delete-ipt.svg" alt="삭제" />
        </IconButton>
      )}
    </CustomInputWrap>
  );
};
export default CustomInputWithSearch;
