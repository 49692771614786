import { ColDef } from 'ag-grid-community';
import { DefaultCellTooltip } from 'components/grids/tooltip/DefaultCellTooltip';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import radioIcon from 'components/asset/RadioColumn.svg';

export const useFrgnCurrencyColumnDef = () => {
  const { t } = useTranslation();
  const defaultColDef = useMemo((): ColDef => {
    return {
      sortable: false,
      resizable: true,
      cellStyle: { textAlign: 'left' },
      tooltipComponent: DefaultCellTooltip,
    };
  }, []);

  const colDefs: any = [
    {
      headerName: 'radio',
      headerComponentParams: {
        template: `<img src=${radioIcon} alt=''/>`,
      },
      width: '28px !important',
      height: '24px !important',
      padding: '2px 6px',
      resizable: false,
      headerCheckboxSelection: true,
      showDisabledCheckboxes: true,
      checkboxSelection: true,
      flex: 0,
      cellStyle: {
        display: 'flex',
        justifyContent: 'center',
        width: '28px !important',
        height: '24px',
      },
    },
    {
      headerName: t('com.label.00106', '__국적'),
      headerTooltip: t('com.label.00106', '__국적'),
      field: 'landx',
      tooltipField: 'landx',
      flex: 1,
      minWidth: 175,
    },
    // 0318 양지원 은행키 숨김
    // {
    //   headerName: t('com.label.00107', '__은행키'),
    //   headerTooltip: t('com.label.00107', '__은행키'),
    //   field: 'bankl',
    //   tooltipField: 'bankl',
    //   flex: 1,
    //   minWidth: 175,
    // },
    {
      headerName: t('com.label.00065', '__은행'),
      headerTooltip: t('com.label.00065', '__은행'),
      field: 'banka',
      tooltipField: 'banka',
      flex: 1,
      minWidth: 175,
      valueGetter: (params) => {
        return params.data.banka === 'null' ? '' : params.data.banka;
      },
    },
    {
      headerName: t('com.label.00108', '__은행 지점'),
      headerTooltip: t('com.label.00108', '__은행 지점'),
      field: 'brnch',
      tooltipField: 'brnch',
      flex: 1,
      minWidth: 175,
      valueGetter: (params) => {
        return params.data.brnch === 'null' ? '' : params.data.brnch;
      },
    },
    {
      headerName: t('SWIFT/BIC', 'SWIFT/BIC'),
      headerTooltip: t('SWIFT/BIC', 'SWIFT/BIC'),
      field: 'swift',
      tooltipField: 'swift',
      flex: 1,
      minWidth: 175,
      valueGetter: (params) => {
        return params.data.swift === 'null' ? '' : params.data.swift;
      },
    },
  ];

  const handleAprvDetailBtn = (data) => {
    console.log('화면 연결이 필요합니다.');
  };

  return { defaultColDef, colDefs };
};
