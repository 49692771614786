import Box from '@mui/material/Box';
import { AgGridReact } from 'ag-grid-react';
import { getCommonCodes } from 'apis/admin/CommonCode';
import { GContentGrid } from 'components/layouts/GContentGrid';
import { GGroupBoxTop, GLabel, GTitleBox } from 'components/layouts/GLayoutBox';
import { GPagination, PaginationRef } from 'components/layouts/GPagination';
import { GPageSizeSelect } from 'components/selects/GPageSizeSelect';
import dayjs from 'dayjs';
import { CommonCode } from 'models/admin/CommonCode';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useColumnDefsForApprovalList } from './ColumnDefs';
import { useLoading } from 'components/process/Loading';
import { useMessageBar } from 'components/process/MessageBar';
import { SampleResponse } from 'models/sample/Sample';
import { getSamples } from 'apis/sample/SampleApi';
import { getJwtCommonCodes } from 'apis/vars/common/Common';
import RegistBox from 'components/organism/RegistBox';
import SubTitle from 'components/atom/subTitle';
import SubTitleBox from 'components/molecule/SubTitleBox';

import useAuthStore from 'stores/useAuthStore';

type SearchParams = {
  coCd: string;
  onduRegnCd?: string;
  jtiCd?: string;
  aprReqProgStatCd?: string;
  aprReqDtmSt?: string;
  aprReqDtmEd?: string;
  aprReqUserId?: string;
  aprReqUserNm?: string;
  stmtRegStat?: string;
  eaprDocNo?: string;
  aprReqTitNm?: string;
};

const SampleType90 = () => {
  const { t } = useTranslation();
  const t2 = t as (msgId: string, defVal: string) => string;
  const [coCdList, setCoCdList] = useState<CommonCode[]>([]);
  const [aprReqProgStatCdList, setAprReqProgStatCdList] = useState<CommonCode[]>([]);
  const [onduRegnCdList, setOnduRegnCdList] = useState<CommonCode[]>([]);
  const [jtiCdList, setJtiCdList] = useState<CommonCode[]>([]);
  const { accessToken } = useAuthStore();

  const [searchParams, setSearchParams] = useState<SearchParams>({
    coCd: '',
    onduRegnCd: '',
    jtiCd: '',
    aprReqProgStatCd: '',
    aprReqDtmSt: dayjs().add(-30, 'd').format('YYYYMMDD'),
    aprReqDtmEd: dayjs().format('YYYYMMDD'),
    aprReqUserId: '',
    aprReqTitNm: '',
    eaprDocNo: '',
    stmtRegStat: '',
  });
  const [sampleList, setSampleList] = useState<SampleResponse[]>([]);

  const gridApiRef = useRef<any>(null);
  const { defaultColDef, colDefs } = useColumnDefsForApprovalList();
  const [totalCount, setTotalCount] = useState<number>(0);
  const paginationRef = useRef<PaginationRef>(null);
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageNo, setPageNo] = useState<number>(1);
  const { openMessageBar } = useMessageBar();

  const { openLoading } = useLoading();

  useEffect(() => {
    // loadSampleList();
  }, [pageNo, pageSize]);

  const fnSearchCoCd = useCallback(() => {
    if (accessToken) {
      getJwtCommonCodes('COP_CD').then((result: CommonCode[]) => {
        setCoCdList(result);
      });
    } else {
      getCommonCodes('COP_CD').then((result: CommonCode[]) => {
        setCoCdList(result);
      });
    }
  }, []);

  const fnSearchAprReqProgStatCd = useCallback(() => {
    if (accessToken) {
      getJwtCommonCodes('APR_REQ_PROG_STAT_CD').then((result: CommonCode[]) => {
        setCoCdList(result);
      });
    } else {
      getCommonCodes('APR_REQ_PROG_STAT_CD').then((result: CommonCode[]) => {
        setAprReqProgStatCdList(result);
      });
    }
  }, []);

  const fnSearchOnduRegnCd = useCallback(() => {
    if (accessToken) {
      getJwtCommonCodes('ONDU_REGN_CD').then((result: CommonCode[]) => {
        setCoCdList(result);
      });
    } else {
      getCommonCodes('ONDU_REGN_CD').then((result: CommonCode[]) => {
        setOnduRegnCdList(result);
      });
    }
  }, []);

  const fnSearchJtiCd = useCallback(() => {
    if (accessToken) {
      getJwtCommonCodes('JTI_CD').then((result: CommonCode[]) => {
        setCoCdList(result);
      });
    } else {
      getCommonCodes('JTI_CD').then((result: CommonCode[]) => {
        setJtiCdList(result);
      });
    }
  }, []);

  useEffect(() => {
    fnSearchCoCd();
    fnSearchAprReqProgStatCd();
    fnSearchOnduRegnCd();
    fnSearchJtiCd();
  }, []);

  const makeSearchParams = () => {
    return {
      ...searchParams,
      start: String(pageSize * (pageNo - 1)),
      pageSize: String(pageSize),
    };
  };

  const handleError = (e: Error) => {
    openLoading(false);
    openMessageBar('error', e.message);
  };

  const loadSampleList = async () => {
    getSamples(makeSearchParams())
      .then((data) => {
        setSampleList(data.list);
        setTotalCount(data?.totalCount || 0);
      })
      .catch(handleError);
  };

  const handlePageSizeChange = (size) => {
    paginationRef.current?.setPageSize(size);
    setPageNo(1);
    setPageSize(size);
  };

  const handlePageNoClick = (no: number) => {
    setPageNo(no);
  };

  const renderSearchConditions = () => {
    return <RegistBox />;
  };

  const renderGrid = () => {
    return (
      <GContentGrid className="ag-theme-alpine" style={{ height: '355px' }}>
        <AgGridReact
          defaultColDef={defaultColDef}
          columnDefs={colDefs}
          rowData={sampleList}
          rowSelection={'multiple'}
          suppressRowClickSelection
          suppressMovableColumns
          enableCellTextSelection
          onGridReady={(params) => (gridApiRef.current = params.api)}
          tooltipShowDelay={0}
          tooltipHideDelay={2000}
        ></AgGridReact>
      </GContentGrid>
    );
  };

  const renderPagination = () => {
    return (
      <GPagination
        ref={paginationRef}
        totalCount={totalCount}
        defaultPageNo={pageNo}
        defaultPageSize={pageSize}
        onChangePageSize={handlePageSizeChange}
        onClickPageNo={handlePageNoClick}
      />
    );
  };

  const renderSubTitle = () => {
    return <SubTitleBox content={'목록'} isVisibleComment={false} isVisibleInfo={false} />;
  };

  return (
    <>
      {renderSearchConditions()}
      {renderSubTitle()}
      {renderGrid()}
      {renderPagination()}
    </>
  );
};

export default SampleType90;
