import { FormControlLabel, RadioGroup, SelectChangeEvent } from '@mui/material';
import { GRadio } from 'components/atom/radio';
import { GInputLabel } from 'components/inputs/GInput';
import styled from '@emotion/styled';

interface Props {
  option: any[];
  value: string;
  row?: boolean;
  valueKey: string;
  labelKey: string;
  height?: string;
  id: string;
  onChange?: (event: SelectChangeEvent) => void;
  defaultValue?: string;
  disabled?: boolean;
}

const renderRadio = (valueKey: string, labelKey: string, listData: any[], disabled?: boolean) =>
  listData?.map((item, index) => (
    <FormControlLabelWrap
      disabled={disabled}
      key={index}
      value={item[valueKey]}
      control={<GRadio />}
      label={<GInputLabel>{item[labelKey]}</GInputLabel>}
    />
  ));

const GridRadioVars2 = (props: Props) => {
  return (
    <RadioGroup
      id={props.id}
      row={props.row == undefined || props.row ? true : false}
      sx={{ gap: '12px', height: `${props.height}` }}
      style={{ marginTop: '2px' }}
      value={props.value}
      aria-labelledby="demo-customized-radios"
      name="primary-radios"
      onChange={props.onChange}
    >
      {renderRadio(props.valueKey, props.labelKey, props.option, props.disabled)}
      {/* {props.option.map((item, index) => {
        return (
          <FormControlLabel
            key={index}
            value={item[valueKey]}
            control={<GRadio />}
            label={<GInputLabel>{item}</GInputLabel>}
          />
        );
      })} */}
    </RadioGroup>
  );
};

export const RadioWrap = styled.div`
  margin-top: 4px;
  display: flex;
  justify-content: center;
  height: 28px;
`;

const FormControlLabelWrap = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin-right: 0;
    margin-left: 0;
  }
  display: flex;
  justify-content: center;
  height: 28px;
`;

export default GridRadioVars2;
