import { useCommonModal } from 'hooks/useCommonModal';
import { Department } from 'models/admin/Department';
import { useRef, useState } from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { GPopupTitle } from 'components/layouts/GLayoutBox';
import { DialogTitleItem } from 'components/layouts/GModalPopup';
import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import { GButton } from 'components/atom/button';
import Save from 'components/asset/images/Confirm.svg';
import { GLabelAtom } from 'components/atom/label';
import FileUploadZone from 'components/molecule/FileUpload';
import { GbasicTable, GbasicTableWrap } from 'components/tables/GTable';
import ModalLayout from 'components/layouts/ModalLayout';
import { GButtonBox } from 'components/layouts/GLayoutBox';

type DepartmentModalProps = {
  open: boolean;
  singleSelect?: boolean;
  close: () => void;
  save: () => void;
};

const AttachingFile = (props: DepartmentModalProps) => {
  const { t } = useTranslation();
  const gridRef = useRef<AgGridReact<Department>>(null);
  const { openCommonModal } = useCommonModal();

  const handleClose = () => {
    props.close();
    setcertifiedButton(true);
  };

  const handleOnSave = () => {
    const selectedRows = gridRef.current?.api.getSelectedRows() || [];
    if (selectedRows.length === 0) {
      openCommonModal({
        modalType: 'alert',
        content: t(
          'department-choice-popup.alert.한 부서 이상 선택해야 합니다.',
          '__선택된 행이 없습니다.'
        ),
      });
      return;
    }
    props.close();
  };

  const [certifiedButton, setcertifiedButton] = useState(true);

  const handlecertifiedButtonClick = () => {
    setcertifiedButton(!certifiedButton); // showBButton 상태를 토글
  };

  return (
    <ModalLayout open={props.open} onClose={handleClose} mMinWidth={800} mMaxWidth={800}>
      <DialogTitleItem id="customized-dialog-title" onClose={handleClose}>
        <GPopupTitle title2="파일첨부" />
      </DialogTitleItem>
      <DialogContent className="popupContent">
        <GbasicTableWrap>
          <GbasicTable>
            <col style={{ width: '160px' }} />
            <col style={{ width: 'auto' }} />
            <tbody>
              <tr>
                <th>
                  <GLabelAtom align="left" isRequired={true}>
                    사업자등록증
                  </GLabelAtom>
                </th>
                <td>
                  <FileUploadZone
                    ref={FileUploadZone}
                    atchFileGrIdInput="9cb7a782-f893-4e6b-b58c-20794684bbc6"
                    isMultipleFile={true}
                    allowFileTypes={['txt', 'jpg', 'png', 'xlsx']}
                    width="100%"
                  />
                </td>
              </tr>
              <tr>
                <th>
                  <GLabelAtom align="left" isRequired={true}>
                    통장사본
                  </GLabelAtom>
                </th>
                <td>
                  <FileUploadZone
                    ref={FileUploadZone}
                    atchFileGrIdInput="9cb7a782-f893-4e6b-b58c-20794684bbc6"
                    isMultipleFile={true}
                    allowFileTypes={['txt', 'jpg', 'png', 'xlsx']}
                    width="100%"
                  />
                </td>
              </tr>
              <tr>
                <th>
                  <GLabelAtom align="left" isRequired={true}>
                    어음약정확인서
                  </GLabelAtom>
                </th>
                <td>
                  <FileUploadZone
                    ref={FileUploadZone}
                    atchFileGrIdInput="9cb7a782-f893-4e6b-b58c-20794684bbc6"
                    isMultipleFile={true}
                    allowFileTypes={['txt', 'jpg', 'png', 'xlsx']}
                    width="100%"
                  />
                </td>
              </tr>
              {/* <tr>
                <th>
                  <GLabelAtom align="left">기타1</GLabelAtom>
                </th>
                <td>
                  <FileUploadZone
                    ref={FileUploadZone}
                    atchFileGrIdInput="9cb7a782-f893-4e6b-b58c-20794684bbc6"
                    isMultipleFile={true}
                    allowFileTypes={['txt', 'jpg', 'png', 'xlsx']}
                    width="100%"
                  />
                </td>
              </tr> */}
              <tr>
                <th>
                  <GLabelAtom align="left">기타</GLabelAtom>
                </th>
                <td>
                  <FileUploadZone
                    ref={FileUploadZone}
                    atchFileGrIdInput="9cb7a782-f893-4e6b-b58c-20794684bbc6"
                    isMultipleFile={true}
                    allowFileTypes={['txt', 'jpg', 'png', 'xlsx']}
                    width="100%"
                  />
                </td>
              </tr>
            </tbody>
          </GbasicTable>
        </GbasicTableWrap>
      </DialogContent>
      <DialogActions className="popupBottom" style={{ padding: '12px 24px 24px' }}>
        <GButtonBox>
          <GButton
            txt="취소"
            sizes="medium"
            onClick={handleClose}
            btnstyled="outline"
            btnColor="normal"
            hasImg={false}
            hasTxt={true}
          />
          <GButton
            txt="전자인증"
            sizes="medium"
            btnstyled="outline"
            btnColor="normal"
            hasImg={false}
            hasTxt={true}
            onClick={handlecertifiedButtonClick}
          />

          {certifiedButton && (
            <GButton
              txt="저장"
              sizes="medium"
              onClick={handleOnSave}
              chkImg={Save}
              btnstyled="contained"
              btnColor="primary"
              hasImg={true}
              hasTxt={true}
              disabled
            />
          )}

          {!certifiedButton && (
            <GButton
              txt="저장"
              sizes="medium"
              onClick={handleOnSave}
              chkImg={Save}
              btnstyled="contained"
              btnColor="primary"
              hasImg={true}
              hasTxt={true}
            />
          )}
        </GButtonBox>
      </DialogActions>
    </ModalLayout>
  );
};

export default AttachingFile;
