import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { AccountFileInfo } from 'models/vars/account/AccountFileInfo';
import { AccountMgtVO } from 'models/vars/account/AccountMgtVO';
import { callApi } from 'utils/ApiUtil';
import { callJwtApi } from 'utils/JwtApiUtil';

const PREFIX_URI = '/api/sec/common';

/**
 * 계좌정보 조회
 * @param userId
 * @returns
 */
export const getAccounts = async (userId: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `${PREFIX_URI}/account/${userId}`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
  };
  const response: CommonResponse<AccountMgtVO[]> = await callJwtApi(request);
  return response.successOrNot === 'Y' ? (response.data as AccountMgtVO[]) : null;
};

/**
 * SAP 기준정보 샘플
 * I_REQ_DIVS 파라미터 설명
 * CTR : 국가키
 * BC : 은행코드(국내)
 * BK : 은행키코드(외화)
 * CUR : 통화
 * BUK : 회사코드
 * LIF : 구매처 -- 미개발
 * LIFB : 구매처 은행계좌 -- 미개발
 * @returns
 */
export const getSapIfStdInfo = async (param) => {
  const request: CommonRequest = {
    method: Method.GET,
    // url: `/api/sap-if/ws-sample-test`,
    url: `${PREFIX_URI}/std-info0237`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams(param),
  };
  const response: CommonResponse<any> = await callJwtApi(request);
  return response.successOrNot === 'Y' ? response.data : null;
};

export const getSapIfStdInfoG = async (param) => {
  const request: CommonRequest = {
    method: Method.GET,
    // url: `/api/sap-if/ws-sample-test`,
    url: `${PREFIX_URI}/std-infoG0237`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams(param),
  };
  const response: CommonResponse<any> = await callJwtApi(request);
  return response.successOrNot === 'Y' ? response.data : null;
};

/**
 * 회사코드
 * 통화키
 * 계정번호
 * 사원번호
 * 수취인은행키
 * 수취인은행계정번호
 * 주민번호
 * 수취인명
 * @param params
 * @returns
 */
export const getAccnValidInfo = async (params) => {
  const request: CommonRequest = {
    method: Method.GET,
    // url: `/api/sap-if/ws-sample-test`,
    url: `${PREFIX_URI}/accn-valid`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({ ...params }),
  };
  const response: CommonResponse<any> = await callJwtApi(request);
  return response.successOrNot === 'Y' ? response.data : null;
};

/**
 * 벤더 정보 조회
 * @param userId
 * @returns
 */
export const getMemberVendor = async (userId: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `${PREFIX_URI}/vendor/${userId}`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
  };
  const response: CommonResponse<any> = await callJwtApi(request);
  return response.successOrNot === 'Y' ? (response.data as any) : null;
};

export const findAccountFiles = async (atchFileGrId: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `${PREFIX_URI}/files`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    headers: {},
    queryParams: new URLSearchParams({ atchFileGrId: atchFileGrId }),
  };
  const response: CommonResponse<AccountFileInfo[]> = await callJwtApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as AccountFileInfo[];
};

export const uploadAccountFiles = async (formData: FormData) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `${PREFIX_URI}/file/upload`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    bodyParams: formData,
  };

  const response: CommonResponse<any> = await callJwtApi(request);

  return response.successOrNot === 'Y' ? response.data : null;
};

export const modifyAccountFiles = async (files: AccountFileInfo[]) => {
  const request: CommonRequest = {
    method: Method.PUT,
    url: `${PREFIX_URI}/files`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    bodyParams: files,
  };

  const response: CommonResponse<any> = await callJwtApi(request);

  return response.successOrNot === 'Y' ? response.data : null;
};

export const getAccnValid0237 = async (params) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `${PREFIX_URI}/accn-valid-fi0237`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({ ...params }),
  };
  const response: CommonResponse<any> = await callJwtApi(request);
  return response.successOrNot === 'Y' ? response.data : null;
};

export const getAccnValidG0237 = async (params) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `${PREFIX_URI}/accn-valid-gfi0237`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({ ...params }),
  };
  const response: CommonResponse<any> = await callJwtApi(request);
  return response.successOrNot === 'Y' ? response.data : null;
};

export const getVendorStatus = async (bzno) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `${PREFIX_URI}/vendor-status`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({ bzno }),
  };
  const response: CommonResponse<any> = await callJwtApi(request);
  return response.successOrNot === 'Y' ? response.data : null;
};
