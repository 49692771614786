import { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import DialogContent from '@mui/material/DialogContent';
import { GButtonBox, GPopupTitle } from 'components/layouts/GLayoutBox';
import { DialogTitleItem } from 'components/layouts/GModalPopup';
import { AgGridReact } from 'ag-grid-react';
import { GButton } from 'components/atom/button';
import Search from 'components/asset/images/Search.svg';
import Img from 'components/asset/images/Confirm.svg';
import SearchMolecule, {
  GButtonBoxCum,
  SearchItemWrap,
  SearchWrap,
  UnHiddenSearchWrap,
} from 'components/molecule/SearchMolecule';
import { useColumnDefsForApprovalList } from './gridDef/DomesticDef';
import GButtonLayout from 'components/molecule/GbuttonLayout';
import { GContentGrid } from 'components/layouts/GContentGrid';
import { useMessageBar } from 'components/process/MessageBar';
import { useLoading } from 'components/process/Loading';
import { getSamples } from 'apis/sample/SampleApi';
import { SampleResponse } from 'models/sample/Sample';
import ModalLayout from 'components/layouts/ModalLayout';
import { DialogActions } from '@mui/material';
import { useCommonModal } from 'hooks/useCommonModal';
import { useTranslation } from 'react-i18next';
import { Department } from 'models/admin/Department';

type DepartmentModalProps = {
  open: boolean;
  singleSelect?: boolean;
  close: () => void;
  save: () => void;
};
const DomesticBankNumber = (props: DepartmentModalProps) => {
  const gridApiRef = useRef<any>(null);
  const { t } = useTranslation();
  const [sampleList, setSampleList] = useState<SampleResponse[]>([]);
  const { defaultColDef, colDefs } = useColumnDefsForApprovalList();
  const { openMessageBar } = useMessageBar();
  const { openLoading } = useLoading();
  const gridRef = useRef<AgGridReact<Department>>(null);
  const { openCommonModal } = useCommonModal();

  const handleClose = () => {
    props.close();
  };
  // Alert(001-01, 1-2)
  const handleOnSave = () => {
    const selectedRows = gridRef.current?.api.getSelectedRows() || [];
    if (selectedRows.length === 0) {
      openCommonModal({
        modalType: 'alert',
        content: t(
          'department-choice-popup.alert.한 부서 이상 선택해야 합니다.',
          '__선택된 행이 없습니다.'
        ),
      });
      return;
    }
    props.close();
  };
  const handleError = (e: Error) => {
    openLoading(false);
    openMessageBar('error', e.message);
  };

  useEffect(() => {
    loadSampleList();
  }, []);

  const loadSampleList = async () => {
    getSamples(makeSearchParams())
      .then((data) => {
        setSampleList(data.list);
      })
      .catch(handleError);
  };

  const makeSearchParams = () => {
    return {
      start: '0',
      pageSize: 30,
    };
  };

  return (
    <ModalLayout open={props.open} onClose={handleClose} mMinWidth={1000} mMaxWidth={1000}>
      <DialogTitleItem id="customized-dialog-title" onClose={handleClose}>
        <GPopupTitle title2="은행번호" />
      </DialogTitleItem>
      <DialogContent className="popupContent">
        <SearchWrap>
          <UnHiddenSearchWrap>
            <ItemWrap>
              <SearchMolecule
                type="input"
                labelTitle="은행이름"
                labelWidth="100px"
                placeholder="입력하세요"
                searchWidth="calc(50% - 12px)"
                searchValueWidth="100%"
              />
              <SearchMolecule
                type="input"
                labelTitle="개설은행(Code)"
                labelWidth="100px"
                placeholder="입력하세요"
                searchWidth="calc(50% - 12px)"
                searchValueWidth="100%"
              />
            </ItemWrap>
            <GButtonLayout paddingTop="0px" marginBottom="0px">
              <GButtonBoxCum>
                <GButton
                  chkImg={Search}
                  txt="조회"
                  sizes="medium"
                  btnstyled="contained"
                  btnColor="normal"
                  hasImg={true}
                  hasTxt={true}
                ></GButton>
              </GButtonBoxCum>
            </GButtonLayout>
          </UnHiddenSearchWrap>
        </SearchWrap>
        <GContentGrid
          type="radio"
          className="ag-theme-alpine"
          isSortable={false}
          style={{ height: '336px' }}
        >
          <AgGridReact
            defaultColDef={defaultColDef}
            columnDefs={colDefs}
            rowData={sampleList}
            rowSelection={'single'}
            suppressMovableColumns
            enableCellTextSelection
            onGridReady={(params) => (gridApiRef.current = params.api)}
            tooltipShowDelay={0}
            tooltipHideDelay={2000}
          />
        </GContentGrid>
      </DialogContent>
      <DialogActions className="popupBottom">
        <GButtonBox>
          <GButton
            txt="취소"
            sizes="medium"
            btnstyled="outline"
            onClick={handleClose}
            btnColor="normal"
            hasImg={false}
            hasTxt={true}
          ></GButton>
          <GButton
            txt="선택"
            chkImg={Img}
            sizes="medium"
            onClick={handleOnSave}
            btnstyled="contained"
            btnColor="primary"
            hasImg={true}
            hasTxt={true}
          ></GButton>
        </GButtonBox>
      </DialogActions>
    </ModalLayout>
  );
};

export default DomesticBankNumber;

const ItemWrap = styled(SearchItemWrap)`
  display: flex;
  gap: 24px;
`;
