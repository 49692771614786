import useSessionStore from 'stores/useSessionStore';
import { useContext, useEffect } from 'react';
import { MenuContextType } from 'models/admin/Menu';
import { MenuContext } from 'App';
import { FontColor, GlobalTokenColor } from 'ui/theme/Color';
import { Link, useNavigate } from 'react-router-dom';
import { Menu } from 'models/admin/Menu';
import { menuType } from './SideMenuBar';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useCommonModal } from 'hooks/useCommonModal';

const nest = (menuData: Menu[], mnuId = '000000', link = 'upprMnuId') =>
  menuData
    .filter((item) => item[link] === mnuId)
    .map((item) => ({ ...item, children: nest(menuData, item.mnuId) }));

const HeadLinkList = () => {
  const { headerMenus } = useSessionStore();
  const menuContext = useContext<MenuContextType>(MenuContext);
  const navigate = useNavigate();
  const { menus } = useSessionStore();
  const { t } = useTranslation();
  const { openCommonModal } = useCommonModal();

  const handleMenu = () => {
    // [Dev] depth1에 하위메뉴 없이 바로 url에 연결되는 경우의 코드가 없음
    let url = '';
    if (menus && menuContext.selectedHeaderMenu) {
      const depth1Menus: Menu[] = menus
        .filter(
          (item) => item.upprMnuId === menuContext.selectedHeaderMenu && item.mnuOptValCtn5 != 'Y'
        )
        .sort((a, b) => (a['sortOrd'] ?? 0) - (b['sortOrd'] ?? 0));

      const newSideMenuList: menuType[] = [];
      depth1Menus.forEach((item) => {
        const tree = nest(menus, item.mnuId);
        newSideMenuList.push({
          menuInfo: item,
          children: tree,
        });
      });

      // url = newSideMenuList[0].menuInfo.mnuUrl
      //   ? newSideMenuList[0].menuInfo.mnuUrl
      //   : newSideMenuList[0].children.length > 0
      //   ? '/' + newSideMenuList[0]?.children[0]?.mnuUrl
      //   : '/' + newSideMenuList[0].menuInfo.mnuUrl;

      // 위 소스는 헤더 메뉴 클릭시 SideMenu의 메뉴들 중 맨 위 메뉴의 url이 있다면 그 페이지를 보여주는 내용
      // 아래는 메뉴들 중 맨 위의 자식 메뉴를 보여주는 내용임
      if (newSideMenuList.length > 0) {
        url =
          newSideMenuList[0].children.length > 0
            ? '/' + newSideMenuList[0]?.children[0]?.mnuUrl
            : '/' + newSideMenuList[0].menuInfo.mnuUrl;
      } else {
        url = menus.filter((o) => o.mnuId == menuContext.selectedHeaderMenu)[0].mnuUrl ?? '*';
      }
    }

    return url;
  };
  useEffect(() => {
    if (menuContext.clickedByHeaderMenu) {
      navigate(handleMenu());
    }
  }, [menuContext.selectedHeaderMenu]);

  return (
    <LinkWrap>
      {headerMenus &&
        headerMenus.map((menu) => (
          <HeadLink
            onClick={() =>
              menuContext.handleMenuChange({
                ...menuContext,
                selectedHeaderMenu: menu.mnuId,
                clickedByHeaderMenu: true,
              })
            }
            key={menu.mnuId}
            to={handleMenu()}
          >
            <Typography isClicked={menu.mnuId === menuContext.selectedHeaderMenu}>
              {t(`${menu.msgCtn}`, `${menu.mnuNm}`)}
            </Typography>
          </HeadLink>
        ))}
    </LinkWrap>
  );
};

const LinkWrap = styled.div`
  height: 100%;
  display: flex;
  align-items: center;

  gap: 50px;
  @media (max-width: 1600px) {
    gap: 40px;
  }
  @media (max-width: 1400px) {
    gap: 30px;
  }
`;

const HeadLink = styled(Link)`
  width: fit-content;
  height: 100%;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`;

const Typography = styled.p<{ isClicked: boolean }>`
  font-size: 14px;
  font-weight: 700;
  color: ${(props) => (props.isClicked ? `${FontColor.Default}` : `${FontColor.Gray200}`)};

  &:hover {
    color: ${FontColor.Default};
  }

  &:before {
    content: '';
    position: absolute;
    display: ${(props) => (props.isClicked ? 'block' : 'none')};

    width: calc(100% + 40px);
    height: 3px;

    bottom: 0px;
    left: 50%;

    transform: translateX(-50%);
    background-color: ${GlobalTokenColor.Primary};
  }
`;

export default HeadLinkList;
