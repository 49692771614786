/* eslint-disable */
import { useCallback } from 'react';

type UseAccountRegistProps = {
  callback?: () => void;
};

export const useAccountRegist = (props: UseAccountRegistProps) => {
  // {
  // | 'completion' // 긍정/완료
  // | 'ongoing' // 진행중
  // | 'waiting' // 대기
  // | 'waitingCompletion' // 완료후 대기중
  // | 'emergency' // 부정/긴급
  // | 'end' // 종료/만료/비활성
  // | 'possibility'; // 가능/Task명
  // }
  const getTagStatus = useCallback((sttsCd: string) => {
    switch (sttsCd) {
      case 'P': //진행중
        return 'ongoing';
      case 'A': //승인
        return 'completion';
      case 'R': //반려
        return 'emergency';
      case 'D': //요청취소
        return 'end';
      case 'T': //임시저장
        return 'waiting';
      default:
        return 'completion';
    }
  }, []);

  const callBackAccountsRetrieve = () => {
    props.callback?.();
  };

  return {
    getTagStatus,
    callBackAccountsRetrieve,
  };
};
