export const File_Format = {
  DEFAULT_FILE_FORMAT: [
    'pdf',
    'xls',
    'xlsx',
    'ppt',
    'pptx',
    'hwp',
    'hwpx',
    'doc',
    'docx',
    'png',
    'jpeg',
    'jpg',
    'txt',
    'gif',
    'bmp',
    'avi',
    'mp4',
    'wmv',
  ],
};
