import { ICellRendererParams } from 'ag-grid-community';
import { FormControlLabel, RadioGroup } from '@mui/material';
import { GInputLabel, GSRadio } from 'components/inputs/GInput';
import { GRadio } from 'components/atom/radio';

export interface IRadioCellRendererVarsParams extends ICellRendererParams {
  disabled?: boolean;
  valueKey: string;
  labelKey: string;
  listData: any[];
}

const renderRadio = (listData: any[], valueKey: string, labelKey: string, disabled?: boolean) =>
  listData?.map((data, index) => (
    <FormControlLabel
      style={{ marginLeft: '0px', marginRight: '0px' }}
      disabled={disabled}
      key={index}
      value={data[valueKey]}
      control={<GRadio color="secondary" />}
      label={''}
    />
  ));

export const RadioCellRendererVars = ({
  disabled,
  valueKey,
  labelKey,
  listData,
  ...params
}: IRadioCellRendererVarsParams) => {
  const { setValue } = params;

  const onChange = (e) => {
    setValue && setValue(e.target.value);
  };

  return (
    <RadioGroup
      row
      aria-labelledby="demo-customized-radios"
      name="primary-radios"
      style={{
        // margin: 'auto',
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        textAlign: 'center',
        gap: '10px',
        padding: '2px 6px',
        flex: 0,
      }}
      value={params.value || ''}
      //   onChange={onChange}
    >
      {renderRadio(listData, valueKey, labelKey, disabled)}
    </RadioGroup>
  );
};
